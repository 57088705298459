/* eslint-disable */

/* MODULES ONLY */
import attributes from './attributes'
import bi from './bi'
import catalogue from './catalogue'
import categories from './categories'
import clientTracking from './client-tracking'
import countries from './countries'
import exports from './exports'
import inventory from './inventory'
import keywords from './keywords'
import login from './login'
import logistics from './logistics'
import marketing from './marketing'
import monitoring from './monitoring'
import onboarding from './onboarding'
import pricing from './pricing'
import product from './product'
import settings from './settings'
import ticketing from './ticketing'
import dashboards from './dashboards'

/* ACTIONS RELATED STRINGS */

import actions from './actions'

/* LOADERS RELATED STRINGS */

import filters from './filters'

/* LOADERS RELATED STRINGS */

import loaders from './loaders'

/* GLOBAL AND CROSS KEYWORDS/STRINGS */

import misc from './misc'

/* ERRORS AND EMPTY DATA RELATED STRINGS */

import placeholders from './placeholders'

/* COLUMNS ENTRIES - FILTERS AND GROUPING RELATED */

import visualization from './visualization'

// prettier-ignore
export default {
  attributes,
  bi,
  catalogue,
  categories,
  clientTracking,
  countries,
  exports,
  filters,
  inventory,
  keywords,
  login,
  logistics,
  marketing,
  monitoring,
  onboarding,
  pricing,
  product,
  settings,
  ticketing,
  dashboards,
  actions,
  loaders,
  misc,
  placeholders,
  visualization,
  all: 'All',
  averageRank: 'Average Rank',
  averageRankTooltip: 'The average rank of a search term on the date range',
  brandAwareness: 'Brand Awareness',
  brandAwarenessText: 'Understand & monitor the brand popularity in comparaison with your competitors',
  brandPopularity: 'Track your brand popularity and compare it with some competitors',
  brandPopularityAddBrandName: 'Add Brand name...',
  brandPopularityAddGenericKeyword: 'Add generic keyword',
  brandPopularityDescription: 'Track & compare brand popularity with the key competitors and then, add generic keywords to analyze the search term trend.\nUsing this feature, you can monitor awareness of your brand and on a specific category at daily level',
  brandPopularityVisibility: 'Brand Popularity & Visibility',
  brandPopularityVisibilityText: 'Understanding what search terms your customers are using and monitoring popularity of your competitors',
  brandVisibility: 'Brand Visibility',
  brandVisibilityDescription: 'What % of a Brands will appear in the title of the top 3 ASINS returned by amazon when a user entered one of the top  keywords ?\n\nKeyword research plays a pivotal role in determining your product sales on Amazon. You may have a great product, but if customers can’t find it,\nyou won’t be making any sales. This is why effective keyword research is now synonymous with success on Amazon. Product visibility drives sales',
  buybox: 'Buy-Box',
  category: 'Category',
  clickHere: "Click here",
  content: 'Content',
  creator: 'Creator',
  dataFreshness: 'Data freshness',
  description: 'Description',
  dynamicTagPresetAlreadyUsedError: 'This dynamic tag is already active',
  dynamicTagAlreadyUsedError: 'This tag is already used as a standard tag, change its name in settings to solve this issue. ',
  dynamicTagAdd: 'Add Dynamic Tag',
  dynamicTagSuccessMsg: 'The dynamic tag has been created',
  dynamicTags: 'Dynamic Tags',
  dynamicTagsSubtitle: 'Dynamic Tags',
  export: 'Export',
  highestRank: 'Highest Rank',
  highestRankTooltip: 'Best rank of this search term and its date',
  imageNotAvailable: 'Image not available',
  inventory: 'Inventory',
  keyFeatures: 'Key Features',
  keywordRecommendations: 'Keyword Recommendations',
  keywordRecommendationsDescription: 'Now you can get a list of suggested of top-ranked keywords and bid recommendations to improve the visibility of your ASINs.\nAfter selecting ASINs from the catalog list view, you will receive few minutes after by email a list of relevant keywords with bidding recommandations.',
  keywordRecommendationsLimit: 'Number of keywords ',
  keywordRecommendationsText: 'Get keyword recommendations with their bid suggestions for a specific list of products',
  keywordRecommendationsTitle: 'Get keywords recommendations based on specific ASINS',
  keywordResearch: 'Keyword Research',
  keywordResearchGlobalDescription: 'Discover the top shopper searches per category and make a deep dive on a specific search term to find top ASINs related & identify keywords opportunities',
  keywordResearchGlobalTitle: 'Search terms rank used by customers to find products on Amazon',
  keywordResearchText: 'Discover the top shopper searches & better understanding your target market',
  keywordResearchTop10: 'Top 10 most clicked items for the date range for "{{searchTerm}}"',
  keywordResearchTopAsinsPerDay: 'Top 3 most clicked items per day for “{{searchTerm}}”',
  lastUpdate: 'Last Update',
  lowestRank: 'Lowest Rank',
  lowestRankTooltip: 'Lowest rank of this search term and its date',
  myPreviousFilters: 'My previous filters',
  name: 'Name',
  nbProducts: '#Products',
  noData: 'No Data',
  noSearchTerm: 'No search term',
  none: 'None',
  nothingSaved: 'Nothing saved',
  others: 'Others',
  performance: 'Performance',
  per: 'per',
  productCategorization: 'Product Categorization',
  productCategorizationText: 'Create smart product categorization strategy to increase sales.',
  profitability: 'Profitability',
  profitabilityChartTitle: 'Performance Matrix per Category',
  profitabilityDescription: 'Dive into exploring the most profitable product groups to make the most efficient business decisions.\nThis chart helps you to visualise your profitability at group and ASINs levels in order to adapt your Retail & Ad strategies.\nSave your charts configuration and later retrieve it with ease.',
  profitabilityHideTable: 'Hide aditional information about the data',
  profitabilityShowTable: 'See aditional information about the data',
  profitabilityTitle: 'Product performance & profitability',
  relatedSearchTerms: 'Related Search Terms',
  revenue: 'Revenue',
  reviewRating: 'Review / Rating',
  searchTermAnalysisTitle: 'Deep dive analysis for “{{searchTerm}}”',
  searchTermHistory: 'Search term history',
  searchTermInsight: 'Search Term Insight',
  searchTermInsightSubtitle: 'Get insights to build up your search visibility on Amazon',
  searchTermTooltip: 'Exact search term used by customers',
  seeAfter: 'See After',
  seeBefore: 'See Before',
  selectAsins : 'Select ASINs ',
  selectProducts: 'Select one or more products',
  trendTooltip: 'Search term rank progression based on the last 7 days',
  unknownAsin: 'ASIN not from your product catalog',
}
