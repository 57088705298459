import React from 'react'

import {Button, Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'
import PoppersPopup from './PoppersPopup'

const StyledPopup = styled(PoppersPopup)`
  &&& {
    padding: 5px 8px;
    font-size: 12px;
  }
`

const StyledSimpleTool = styled(Button)`
  &&&&&&& {
    color: ${props => (props.active ? colors.primary.rgba : colors.darkLight.rgba)} !important;
    background: none !important;
    padding: 0.5em 0.75em;
    :hover {
      color: ${colors.primary.rgba} !important;
      background: ${colors.backgroundTab.rgba} !important;
    }
  }
`

interface SimpleToolProps {
  disabled?: boolean
  content: string
  icon: string
  onClick: () => void
}

const SimpleTool = ({content, icon, onClick, disabled = true}: SimpleToolProps) => {
  return (
    <StyledPopup
      position="top left"
      basic
      content={content}
      trigger={
        <div>
          <StyledSimpleTool disabled={disabled} icon={<Icon className={icon} />} onClick={onClick} />
        </div>
      }
    />
  )
}

export default SimpleTool
