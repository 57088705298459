import React, {useEffect, useRef, useState} from 'react'

import {Icon, Loader} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../../../../colors'

const TriggerButton = styled.div<{readonly triggered: boolean}>`
  &&& {
    display: flex;
    align-items: center;
    border-radius: 4px;
    min-width: 0;
    padding: ${props => props.theme.button.trigger.padding};
    background-color: ${props =>
      props.triggered
        ? props.theme.button.trigger.hoverBackgroundColor
        : props.theme.button.trigger.backgroundColor} !important;
    border: ${props => props.theme.button.trigger.border};
    border-color: ${props =>
      props.triggered ? props.theme.button.trigger.hoverBorderColor : props.theme.button.trigger.borderColor};
    color: ${props => props.theme.button.trigger.color};
    transition: border 0.1s ease, color 0.1s ease, box-shadow 0.1s ease;
    height: 38px;
    :hover {
      background-color: ${props => props.theme.button.trigger.hoverBackgroundColor};
      border-color: ${props => props.theme.button.trigger.hoverBorderColor};
      color: ${props => props.theme.button.trigger.hoverColor};
      box-shadow: 0px 0px 4px ${colors.shadow.rgba};
    }
  }
`

const InlineIcon = styled(Icon)`
  &&& {
    height: auto;
    margin: ${props => props.theme.button.inlineIcon.margin};
    opacity: ${props => props.theme.button.inlineIcon.opacity};
    color: ${colors.darkLight.rgba};
    ${TriggerButton}:hover &&& {
      color: ${props => props.theme.button.trigger.hoverColor};
    }
  }
`

const InlineIconRight = styled(Icon)`
  &&& {
    height: auto;
    margin: ${props => props.theme.button.inlineIcon.margin};
    opacity: ${props => props.theme.button.inlineIcon.opacity};
    color: ${colors.darkLight.rgba};
  }
`

const HeaderText = styled('span')`
  &&& {
    white-space: nowrap;
    font-weight: 500;
    color: ${colors.darkLight.rgba};
    ${TriggerButton}:hover &&& {
      color: ${props => props.theme.button.trigger.hoverColor};
    }
  }
`

const ValueText = styled('span')`
  &&& {
    font-weight: 500;
    color: ${colors.primary.rgba};
    min-width: 0
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const PlaceholderText = styled('span')`
  &&& {
    color: ${colors.darkLight.rgba};
    flex: 1;
  }
`

const StyledContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

interface SeelkDropdownButtonProps {
  disabled?: boolean
  header?: string | React.ReactNode
  iconLeft?: string | null
  iconRight?: string | null
  loading?: boolean
  onClick?: (() => void) | null
  onlyIcon?: boolean
  placeholder?: string | null
  value?: string | React.ReactNode | null
  fluid?: boolean
}

const SeelkDropdownButton = ({
  header = '',
  iconLeft = null,
  iconRight = null,
  loading = false,
  onlyIcon = false,
  onClick = null,
  placeholder = null,
  value = null,
  ...otherProps
}: SeelkDropdownButtonProps) => {
  const [triggered, setTriggered] = useState(false)
  const buttonRef = useRef(null)

  const useOutsideAlerter = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) setTriggered(false)
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const handleOnClick = () => onClick && onClick()
  useOutsideAlerter(buttonRef)

  if (onlyIcon && (iconLeft || iconRight)) {
    return (
      <TriggerButton
        {...otherProps}
        onClick={() => {
          handleOnClick()
          setTriggered(!triggered)
        }}
        ref={buttonRef}
        triggered={triggered}
      >
        <InlineIcon name={iconLeft || iconRight} />
      </TriggerButton>
    )
  } else if (iconLeft && iconRight) {
    return (
      <TriggerButton
        {...otherProps}
        onClick={() => {
          handleOnClick()
          setTriggered(!triggered)
        }}
        ref={buttonRef}
        triggered={triggered}
      >
        <InlineIcon name={iconLeft} />
        {value ? (
          <React.Fragment>
            <HeaderText style={{marginLeft: 6}}>{header}</HeaderText>
            <StyledContent>
              {typeof value === 'string' ? (
                <ValueText style={{marginLeft: 6, marginRight: 6}}>{value}</ValueText>
              ) : (
                value
              )}
              {loading ? <Loader active inline /> : <InlineIconRight name={iconRight} />}
            </StyledContent>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <StyledContent>
              <PlaceholderText style={{marginLeft: 6}}>{placeholder}</PlaceholderText>
              {loading ? <Loader active inline /> : <InlineIconRight name={iconRight} />}
            </StyledContent>
          </React.Fragment>
        )}
      </TriggerButton>
    )
  } else if (iconLeft) {
    return (
      <TriggerButton
        {...otherProps}
        onClick={() => {
          handleOnClick()
          setTriggered(!triggered)
        }}
        ref={buttonRef}
        triggered={triggered}
      >
        <InlineIcon name={iconLeft} />
        {value ? (
          <React.Fragment>
            <HeaderText style={{marginLeft: 6}}>{header}</HeaderText>
            <StyledContent>
              {typeof value === 'string' ? <ValueText style={{marginLeft: 6}}>{value}</ValueText> : value}
              {loading && <Loader active inline />}
            </StyledContent>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <StyledContent>
              <PlaceholderText style={{marginLeft: 6}}>{placeholder}</PlaceholderText>
              {loading && <Loader active inline />}
            </StyledContent>
          </React.Fragment>
        )}
      </TriggerButton>
    )
  } else if (iconRight) {
    return (
      <TriggerButton
        {...otherProps}
        onClick={() => {
          handleOnClick()
          setTriggered(!triggered)
        }}
        ref={buttonRef}
        triggered={triggered}
      >
        {value ? (
          <React.Fragment>
            <HeaderText>{header}</HeaderText>
            <StyledContent>
              {typeof value === 'string' ? (
                <ValueText style={{marginLeft: 6, marginRight: 6}}>{value}</ValueText>
              ) : (
                value
              )}
              {loading ? <Loader active inline /> : <InlineIconRight name={iconRight} />}
            </StyledContent>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <StyledContent>
              <PlaceholderText style={{marginLeft: 6}}>{placeholder}</PlaceholderText>
              {loading ? <Loader active inline /> : <InlineIconRight name={iconRight} />}
            </StyledContent>
          </React.Fragment>
        )}
      </TriggerButton>
    )
  }
  return (
    <TriggerButton
      {...otherProps}
      onClick={() => {
        handleOnClick()
        setTriggered(!triggered)
      }}
      ref={buttonRef}
      triggered={triggered}
    >
      {value ? (
        <React.Fragment>
          <HeaderText>{header}</HeaderText>
          <StyledContent>
            {typeof value === 'string' ? <ValueText style={{marginLeft: 6}}>{value}</ValueText> : value}
          </StyledContent>
        </React.Fragment>
      ) : (
        <PlaceholderText style={{marginLeft: 6}}>{placeholder}</PlaceholderText>
      )}
    </TriggerButton>
  )
}

export default SeelkDropdownButton
