import React from 'react'

import PropTypes from 'prop-types'

import colors from '../../colors'
import Chart from './Chart'

const SpiderChart = ({series, yMax, xCategories, ...props}) => {
  return (
    <Chart
      {...props}
      tooltip
      polar
      xAllowDecimals={false}
      xType="category"
      xCategories={xCategories}
      series={series}
      colors={[colors.primary.rgba, colors.secondary.rgba]}
      yMax={yMax}
    />
  )
}

SpiderChart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  xCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  yMax: PropTypes.number,
}

SpiderChart.defaultProps = {
  yMax: null,
}

export default SpiderChart
