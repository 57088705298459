import React from 'react'

import styled from 'styled-components'

const StyledOperator = styled.i`
  display: flex;
  margin-right: 10px;
`

interface InNotInProps {
  operator: 'in' | 'not_in'
  style?: {[key: string]: string}
}

function InNotIn({operator, style = {}}: InNotInProps) {
  if (operator === 'in') return <StyledOperator style={{...style}} className="fas fa-equals" />
  else if (operator === 'not_in') return <StyledOperator style={{...style}} className="fas fa-not-equal" />
  else return null
}

export default InNotIn
