import BaseSeries from './BaseSeries'
import ExtraDimensionSeries from './ExtraDimensionSeries'

export default class ClientTrackingSeries extends ExtraDimensionSeries(BaseSeries) {
  createDimensions() {
    super.createDimensions()
    this.cf.dimensions.client = this.cf.data.dimension(v => v.client)
    this.cf.dimensions.categoriesNumber = this.cf.data.dimension(v => v.categoriesNumber)
    this.cf.dimensions.keywordsNumber = this.cf.data.dimension(v => v.keywordsNumber)
    this.cf.dimensions.productsNumber = this.cf.data.dimension(v => v.productsNumber)
    this.cf.dimensions.accountTypes = this.cf.data.dimension(v => v.accountTypes)
  }

  updateFilters(values = {}, lockId = 'default') {
    super.updateFilters(values, lockId)
    const {categoriesNumber = {}, keywordsNumber = {}, productsNumber = {}, accountTypes = null} = this.filters[lockId]
    this.filterDimension(
      this.cf.dimensions.categoriesNumber,
      categoriesNumber.minValue || null,
      categoriesNumber.maxValue || null
    )
    this.filterDimension(
      this.cf.dimensions.keywordsNumber,
      keywordsNumber.minValue || null,
      keywordsNumber.maxValue || null
    )
    this.filterDimension(
      this.cf.dimensions.productsNumber,
      productsNumber.minValue || null,
      productsNumber.maxValue || null
    )
    this.filterDimension(this.cf.dimensions.accountTypes, v => {
      if (!accountTypes || !accountTypes.length) return true
      return v.some(elem => accountTypes.includes(elem))
    })
  }
}
