export default {
  actions: {
    revokePipelines: {
      one: 'Revoke pipeline',
      other: 'Revoke pipelines',
    },
    confirmRevokeText: {
      one: 'Are you sure you want to revoke this pipeline ?',
      other: 'Are you sure you want to revoke those pipelines ?',
    },
    confirmRevokeAction: 'Revoke',
  },
  activeTask: {
    id: 'Task ID',
    infos: 'Task information',
    level: 'Task level',
    name: 'Task name',
    progress: 'Progress',
    status: 'Task status',
    title: 'Task details',
  },
  goBack: 'Go back',
}
