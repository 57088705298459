export default {
  columns: {
    BSR: 'BSR',
    aPlus: 'A+',
    absDiff: 'Diff.',
    accepted: 'Accepted',
    acceptedUnits: 'Accepted Units',
    accountName: 'Account Name',
    accountType: 'Account Type',
    acos: 'ACoS',
    acpc: 'ACpC',
    active: 'Active',
    adGroupId: 'Ad Group ID',
    adGroups: 'Ad Groups',
    adGroupsNumber: '# Ad Groups',
    ad_group_sb: 'Ad group SB',
    ad_group_sb_performance: 'Ad group SB performance',
    ad_group_sp: 'Ad group SP',
    ad_group_sp_performance: 'Ad group SP performance',
    addOn: 'Add-On',
    adsPage1: 'ADS. PG. 1',
    advertisedAsin: 'Advertised ASIN',
    advertisedSku: 'Advertised SKU',
    advertiserNumber: '# Advertiser',
    advertiser_id: 'Advertiser Id',
    advertising: 'Advertising',
    advertisingDependency: 'Advertising Dependency',
    aged_inventory: 'Aged Inventory',
    amazonId: 'Amazon ID',
    amount: 'Amount',
    amsSpSales14D: 'Sales (Ads)',
    amsSpSpend: 'Spends (Ads)',
    ar: 'AR (%)',
    asin: 'ASIN',
    asinCount: 'ASIN Count',
    asin_performance: 'Asin performance',
    attribute: 'Attribute',
    avg: 'AVG',
    backend: 'Backend',
    bid_sb: 'Bid SB',
    bid_sb_performance: 'Bid SB performance',
    bid_sp: 'Bid SP',
    bid_sp_performance: 'Bid SP performance',
    bp: 'BP',
    bps: 'BPS',
    brand: 'Brand',
    breakdown: 'Breakdown',
    bulletPoints: 'Bullet Points',
    bullets: 'Bullets',
    buyBox: 'Buy Box',
    buyBoxOwner: 'Buy Box Owner',
    buyBoxPrice: 'Buy Box Price',
    campaign: 'Campaign',
    campaignId: 'Campaign ID',
    campaignState: 'Campaign State',
    campaignType: 'Campaign Type',
    campaign_negative_keyword_sp: 'Campaign negative keyword',
    campaign_sb: 'Campaign SB',
    campaign_sb_performance: 'Campaign SB performance',
    campaign_sp: 'Campaign SP',
    campaign_sp_performance: 'Campaign SP performance',
    campaigns: 'Campaigns',
    campaignsNumber: '# Campaigns',
    catalogue: 'Products Catalog',
    categoriesNumber: '# Categories',
    category: 'Category',
    clicks: 'Clicks',
    client: 'Client',
    collection: 'Collection',
    color: 'Color',
    comment: 'Comment',
    competitors: 'Competitors',
    complianceScore: 'Compliance Score',
    conflict: 'Conflict',
    content: 'Content',
    countries: 'Countries',
    country: 'Country',
    cpc: 'CPC',
    createdBy: 'Created by',
    creationDate: 'Creation Date',
    creativeNumber: '# Creative',
    creative_id: 'Creative Id',
    creditUsed: 'Credit used',
    ctg: 'CTG',
    ctgPercent: 'CTG (%)',
    ctgPts: 'CTG (pts)',
    ctr: 'CTR',
    currentStock: 'Current stock',
    currentYear: 'Current Year',
    custom: 'Custom',
    customAttribute: 'Custom attribute',
    date: 'Date',
    dateFrom: 'From Date',
    dateTo: 'To Date',
    defaultBid: 'Default Bid',
    delta: '+/-',
    desc: 'Desc.',
    descLength: 'Desc. length',
    descStatus: 'Desc. status',
    description: 'Description',
    diff: 'Diff.(%)',
    distribution: 'Distribution',
    dynamic: 'Dynamic',
    elapsedTime: 'Elapsed Time',
    email: 'Email',
    entry: 'Entry',
    error: 'Error',
    errorsRetry: 'with {{errors}} errors... Retry ?',
    estimatedPrice: 'Estimated Price',
    export: 'Export',
    failed: 'Failed',
    fastTrack: 'Fast Track',
    fbaSellers: 'FBA Sellers',
    fir: 'FIR (%)',
    fresh: 'Fresh',
    group: 'Group',
    groupsNumber: '# Groups',
    hasStock: 'In Stock',
    hsaCompetitors: 'Sponsored Brands Competitors',
    id: 'ID',
    imageCheckFrequency: 'Image check frequency',
    imageWithIndex: 'Image {{index}}',
    images: 'Images',
    imagesNumber: '# Images',
    impressions: 'Impressions',
    inContent: 'In Content',
    inProgress: 'In Progress',
    inactive: 'Inactive',
    indexed: 'Indexed',
    inventory: 'Inventory',
    inventoryHistory: 'Inventory history',
    isDisplayed: 'Is Displayed',
    isPrime: 'Prime',
    keyword: 'Keyword',
    keywordRankings: 'Keyword rankings',
    keywordsNumber: '# Keywords',
    lastLogin: 'Last Login',
    lastModified: 'Last Modified',
    lastScrapingDate: 'Last Scraping Date',
    lastSeen: 'Last Seen',
    lastUpdatedDate: 'Last Updated Date',
    lastWeek: 'Last Week',
    lastYear: 'Last Year',
    level: 'Level',
    lineItemNumber: '# Line item',
    line_item_id: 'Line Item Id',
    listOfAttributes: 'List of Attributes',
    lowestPrice: 'Lowest Price',
    marketplace: 'Marketplace',
    match: 'Match',
    matchType: 'Match Type',
    metrics: 'Metrics',
    mfnSellers: 'MFN Sellers',
    missed: 'Refused',
    name: 'Name',
    nature: 'Nature',
    nbAdGroup: '# Ad Groups',
    nbCampaign: '# Campaigns',
    nbOffer: '# Products',
    nbResults: 'Nb Results',
    negative_keyword_sp: 'Negative keyword',
    newNumber: '# New',
    newRating: 'New Rating',
    noBuyBox: 'No buy box',
    noContent: 'No content',
    noRetailOffer: 'No retail offer',
    nodeId: 'Node ID',
    number: 'Nb',
    numberOfAttributes: '# Values',
    numberOfConnectedAsins: '# Connected Asins',
    offers: 'Offers',
    offersCount: 'Offers count',
    orderNumber: '# Order',
    order_id: 'Order Id',
    ordered: 'Ordered',
    orderedUnits: 'Ordered Units',
    orders: 'Sales & Products Orders',
    organicCompetitors: 'Organic Competitors',
    other: 'Other',
    otherAsin: 'Other ASIN',
    overall: 'Overall',
    page: 'Page',
    pageRankings: 'Page rankings',
    pantry: 'Pantry',
    parentAsin: 'Parent ASIN',
    pcogs: 'PCoGS',
    pcogsManufacturingValue: 'PCOGs Manufacturing',
    pcogsSourcingValue: 'PCOGs Sourcing',
    pending: 'Pending',
    performanceReports: 'Performance Reports',
    positionRankings: 'Position rankings',
    poUnits: 'PO Units',
    poValue: 'PO Value',
    poValueClosed: 'Sell-In Sales',
    portfolio: 'Portfolio',
    prevNumber: '# Prev.',
    prevRating: 'Prev. Rating',
    prevYear: 'Prev. Year',
    price: 'Price',
    prime: 'Prime',
    primeOnly: 'Prime only',
    product: 'Product',
    productName: 'Product Name',
    product_ad_sp: 'Product ad',
    product_ad_sp_performance: 'Product ad SP performance',
    products: 'Products',
    productsMissingValues: '# Products missing Values',
    productsNumber: '# Products',
    profile: 'Profile',
    profileId: 'Profile ID',
    progress: 'Progress',
    qty: 'Quantity',
    rank: 'Rank',
    ranking: 'Ranking',
    rankingHistory: 'Ranking history',
    rankings: 'Rankings',
    rating: 'Rating',
    ratingDiff: 'Rating Diff.',
    received: 'Received',
    receivedUnits: 'Received Units',
    recommendation: 'Recommendation',
    relevance: 'Relevance',
    retailOffer: 'Retail Offer',
    retailPrice: 'Retail Price',
    returns: 'Returns',
    reviews: 'Reviews',
    roas: 'ROAS',
    role: 'Role',
    sales: 'Sales',
    salesHistory: 'Sales history',
    salesLastWeek: 'Sales Last Week',
    salesRank: 'Sales Rank',
    salesUnits: 'Sales/Units',
    salesWeekAvg: 'Sales Week AVG',
    sbCompetitors: 'Sponsored Brands Competitors',
    schedule: 'Schedule',
    score: 'Score',
    scrapeNow: 'Scrape Now',
    scrapingFrequency: 'Scraping frequency',
    searchRank: 'Search Rank',
    searchTerms: 'Search Terms',
    searchVolume: 'Search volume',
    section: 'Section',
    select: 'Select',
    sellIn: 'Sell-In',
    sellInSales: 'Sell-In Sales',
    sellInUnits: 'Sell-In Units',
    sellOutSales: 'Sell-Out Sales',
    sellOutUnits: 'Sell-Out Units',
    sellerSalesTtc: 'Sales (Seller)',
    setStatus: 'Set Status',
    settlements: 'Settlements',
    shareOfMarketingSpends: 'Share of Marketing Spends',
    sku: 'SKU',
    slug: 'Slug',
    sns: 'SNS',
    sov: 'SOV (%)',
    spCompetitors: 'Sponsored Products Competitors',
    spends: 'Spends',
    startDate: 'Start Date',
    status: 'Status',
    store: 'Store',
    subCategoriesNumber: '# SubCategories',
    subject: 'Subject',
    tagName: 'Tag Name',
    target_sp: 'Target SP',
    target_sp_performance: 'Target SP Performance',
    ticketId: 'Ticket ID',
    timeline: 'Search Rank Timeline',
    title: 'Title',
    titleLength: 'Title length',
    titleStatus: 'Title status',
    topNumber: '# Top 100',
    total: 'Total',
    totalBudget: 'Total Budget',
    totalNumber: '# Total',
    totalPrice: 'Total Sales',
    totalReviews: 'Total Reviews',
    totalUnits: 'Total Units',
    tracking: 'Tracking',
    type: 'Type',
    unitsLastWeek: 'Units Last Week',
    unitsWeekAvg: 'Units Week AVG',
    updateFrequency: 'Update Frequency',
    user: 'User',
    value: 'Value',
    variationColorNumber: '# Color Variations',
    variationSizeNumber: '# Size Variations',
    variationStyleNumber: '# Style Variations',
    vendorOffer: 'Vendor Offer',
    vendorPrice: 'Vendor Price',
    videos: 'Videos',
    videosNumber: '# Videos',
    viewKeywords: 'View Keywords',
    visibilityPercent: 'Visibility %',
    visibilityPercentOrganic: 'Organic V. %',
    visibilityPercentPaid: 'Paid V. %',
    vlt: 'VLT (days)',
    week: 'Week',
    weekAvg: 'Week AVG',
    weeksRemaining: 'Weeks remaining',
    yoy: 'YoY (%)',
    yoyDiff: 'YoY (Diff.)',
    yoyPercent: 'YoY (%)',
    yoyPts: 'YoY (pts)',
    ytd: 'YTD',
  },
  currency: {
    AUD: '$',
    CAD: '$',
    EUR: '€',
    GBP: '£',
    USD: '$',
  },
  global: {
    hidden: 'Hidden Columns',
    nbProducts: '# Products',
    overallScore: 'Overall Score',
    tableColumns: 'Table Columns',
    visibleAndNotSticky: 'Visible Columns',
    visibleAndSticky: 'Visible & Sticky Columns',
  },
  grouping: {
    active: 'Active',
    adGroups: 'Ad Groups',
    addAsFilter: 'Add as Filter',
    advertiserCountry: 'Advertiser Country',
    advertiserName: 'Advertiser Name',
    all: 'All',
    allCount: 'All ({{count}})',
    amazonAccounts: 'Amazon Accounts',
    amazonBrands: 'Amazon Brands',
    amazonCategories: 'Amazon Categories',
    amazonSections: 'Amazon Sections',
    archived: 'Archived',
    asc: 'Ascending',
    asin: 'ASIN',
    asins: 'ASINs',
    asins_marketplaces: 'ASINs',
    atLeast: 'At Least',
    atMost: 'At Most',
    average: 'Average',
    bar: 'Bar',
    between: 'Between',
    bottom: 'Bottom',
    brand: 'Brand',
    brands: 'Brands',
    breakdown: 'Breakdown',
    campaignTypes: 'Campaign Types',
    campaignhsa: 'Sponsored Brands',
    campaignpda: 'Product Display',
    campaigns: 'Campaigns',
    campaignsb: 'Sponsored Brands',
    campaignsp: 'Sponsored Products',
    categories: 'Categories',
    column: 'Column',
    compare: 'Compare',
    complianceScore: 'Compliance Score',
    condition: 'Condition',
    contentScore: 'Content score',
    countries: 'Countries',
    creativeName: 'Creative Name',
    creativeSize: 'Creative Size',
    creativeType: 'Creative Type',
    ctg: 'CTG',
    currency: 'Currency',
    currentPeriod: 'Current Period',
    curve: 'Curve',
    customAttributes: 'Custom Attributes',
    daily: 'Daily',
    day: 'Day',
    days: 'Days',
    deepDiveBy: 'Deep Dive by',
    desc: 'Descending',
    detailed: 'Detailed',
    dspProfile: 'DSP Profile',
    endDate: 'End Date',
    equalTo: 'Equal To',
    filter: 'Filter',
    frequencies: {
      0: 'Never',
      3600: 'Hourly',
      14400: 'Every 4 Hours',
      86400: 'Daily',
      172800: '2 Days',
      604800: 'Weekly',
      2592000: 'Monthly',
      '-': '-',
      86400000000: 'Once',
      custom: 'Custom',
      null: 'Never',
    },
    fulfillment_channels: 'Fulfillment Types',
    fullYear: 'FY',
    graphBy: 'Graph type',
    greaterThan: 'Greater Than',
    groupBy: 'Group by',
    groupingCount: '{{grouping}} ({{count}})',
    groups: 'Groups',
    growth: 'Growth',
    images: 'Images',
    inTheLast: 'In The Last',
    keyword: 'Keyword',
    keywords: 'Keywords',
    last30days: 'Last 30 days',
    last3months: 'Last 3 months',
    last4weeks: 'Last 4 weeks',
    last90days: 'Last 90 days',
    lastMonth: 'Last month',
    lastPeriod: 'Last Period',
    lastWeek: 'Last week',
    left: 'Left',
    line: 'Line',
    lineItemName: 'Line Item Name',
    lowerThan: 'Lower Than',
    marketing: 'Marketing',
    marketplace: 'Marketplace',
    marketplaces: 'Marketplaces',
    metric: 'Metric',
    month: 'Month',
    monthly: 'Monthly',
    months: 'Months',
    mtd: 'Mtd',
    never: 'Never',
    none: 'None',
    notFound: 'Not found',
    offer: 'Offers',
    offers: 'Offers',
    offersScore: 'Offers score',
    on: 'On',
    once: 'Once',
    orderedBy: 'Ordered by',
    orderName: 'Order Name',
    organic: 'Organic visibility',
    performanceMetric: 'Performance Metric',
    period: 'Period',
    problem: 'Problem',
    processing: 'Processing',
    product: 'Product',
    products: 'Products',
    productAds: 'Product Ads',
    productTitle: 'Product Title',
    profiles: 'Profiles',
    qtd: 'Qtd',
    quarter: 'Quarter',
    quarters: 'Quarters',
    rawSales: 'Sales',
    revenue: 'Revenue',
    reviewsScore: 'Review score',
    right: 'Right',
    rows: 'Rows',
    sales: 'Sales',
    score: 'Score',
    scores: 'Scores',
    scrapings: 'Scrapings',
    searchTerm: 'Search Terms',
    sections: {
      COMP: 'Competitors',
      DETA: 'Detached',
      EXTR: 'Secondary',
      MAIN: 'Core',
    },
    seeBy: 'See by',
    selectedCount: 'Selected ({{count}})',
    sellIn: 'SellIn',
    sellOut: 'SellOut',
    share: 'Share',
    since: 'Since',
    sku: 'SKU',
    skus: 'SKUs',
    splitBy: 'Split by',
    sponsoredProducts: 'Sponsored Products',
    startDate: 'Start Date',
    strings: {
      mtd: 'Month to Date',
      qtd: 'Quarter to Date',
      yoy: 'Year over Year',
      ytd: 'Year to Date',
    },
    tags: 'Tags',
    time: 'Time',
    top: 'Top',
    totalPrice: 'Total sales',
    totalUnits: 'Total units',
    units: 'Units',
    unsorted: 'Unsorted',
    value: 'Values',
    vendors: 'Vendors',
    view: 'View',
    viewBy: 'View By',
    volume: 'Volume',
    week: 'Week',
    weekly: 'Weekly',
    weeks: 'Weeks',
    y: '1y',
    year: 'Year',
    yearToDate: 'Year to date',
    years: 'Years',
    yesterday: 'Yesterday',
    yoy: 'YoY',
    ytd: 'Ytd',
  },
}
