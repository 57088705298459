import React from 'react'

import {Box, Col, Row} from 'jsxstyle'
import _ from 'lodash'
import {Icon, Input, InputOnChangeData, Label} from 'semantic-ui-react'
import styled from 'styled-components'

import {clamp} from '../../../../..'
import colors from '../../../../../colors'
import i18n from '../../../../../i18n'
import theme from '../../../../../theme'
import {FilterButton, OperatorInNotIn, PoppersPopup} from '../../../..'
import InNotIn from '../common/InNotIn'
import FilterBarItem from './FilterBarItem'

const UIWrapper = styled.div`
  width: 287px;
  padding-top: 10px;
`

const StyledRow = styled(Row)`
  padding: 10px;
`

const StyledCol = styled(Col)`
  padding: 0 10px;
`

const StyledDebouncedInput = styled(Input)`
  &&&&& {
    width: 100%;
  }
`

const StyledApplyRow = styled(Row)`
  color: ${colors.white.rgba};
  background-color: ${colors.primary.rgba};
  border-radius: 0 0 3px 3px;
  font-weight: 500;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${colors.primaryDark.rgba};
  }
  margin: 0;
  position: relative;
  left: -1px;
  bottom: -1px;
  width: calc(100% + 2px);
`

const StyledWrapper = styled.div`
  display: inline;
  margin: 0 2px;
`

interface SearchTermsProps {
  data: string[]
  icon?: string | null
  onChange: (items: string[]) => void
  onRemove: () => void
  onApply: () => void
  title: string
  updateOperator?: (operator: 'in' | 'not_in') => void
  operator?: 'in' | 'not_in'
}

interface SearchTermsState {
  name: string
}

class SearchTerms extends React.Component<SearchTermsProps, SearchTermsState> {
  count = (data: string[]) => data?.length ?? 0

  constructor(props: SearchTermsProps) {
    super(props)
    this.state = {
      name: '',
    }
  }

  removeSearchTerm = (e: React.MouseEvent<HTMLElement>, searchTerm: string) => {
    const {data} = this.props
    this.props.onChange(data.filter(t => t !== searchTerm))
    setTimeout(this.props.onApply, 1)
    e.preventDefault()
    e.stopPropagation()
  }

  render() {
    const {
      data,
      onChange,
      onRemove,
      onApply,
      icon = null,
      operator = 'in',
      title,
      updateOperator,
      ...barItemProps
    } = this.props

    const trigger = (
      <FilterButton title={!data.length ? title : ''} icon={icon} onRemove={onRemove} onApply={onApply}>
        <Row alignItems="center" marginLeft="5px">
          {data?.length && updateOperator ? <InNotIn operator={operator} style={{marginRight: '5px'}} /> : null}
          {(data ?? []).map(searchTerm => (
            <Box key={searchTerm}>
              <PoppersPopup
                style={{padding: '5px 8px', fontSize: '12px'}}
                hoverable
                key={searchTerm}
                position="top center"
                disabled={searchTerm.length <= 18}
                trigger={
                  <StyledWrapper>
                    <Label
                      style={{
                        backgroundColor: colors.primary.rgba,
                        color: 'white',
                        padding: '4px 6px',
                      }}
                    >
                      {clamp(searchTerm, 18)}
                      <Icon
                        name="delete"
                        onClick={(e: React.MouseEvent<HTMLElement>) => this.removeSearchTerm(e, searchTerm)}
                      />
                    </Label>
                  </StyledWrapper>
                }
                content={searchTerm}
              />
            </Box>
          ))}
        </Row>
      </FilterButton>
    )

    return (
      <FilterBarItem
        {...barItemProps}
        data={data}
        onApply={onApply}
        onChange={onChange}
        trigger={trigger}
        noPadding
        wrapped={false}
      >
        <Col
          justifyContent="center"
          className={theme.using(theme.bordered, theme.shadowed)}
          marginTop="8px"
          backgroundColor={colors.white.rgba}
        >
          <UIWrapper>
            {updateOperator ? (
              <StyledCol>
                <OperatorInNotIn updateOperator={updateOperator} operator={operator} />
              </StyledCol>
            ) : null}
            <StyledRow>
              <StyledDebouncedInput
                fluid
                placeholder={i18n.t('actions.search.tags')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, {value}: InputOnChangeData) =>
                  this.setState({name: value})
                }
                onKeyDown={(event: KeyboardEvent) => {
                  if (event.key === 'Enter' && this.state.name?.length > 0) {
                    onChange(_.uniq([...data, _.trim(this.state.name.toLowerCase())]))
                    this.setState({name: ''})
                  }
                }}
                value={this.state.name}
              />
              {/* <Button
                style={{backgroundColor: colors.primary.rgba, color: colors.white.rgba, marginLeft: 5}}
                onClick={() => {
                  if (this.state.name?.length > 0) {
                    onChange(_.uniq([...data, _.trim(this.state.name.toLowerCase())]))
                    this.setState({name: ''})
                  }
                }}
                icon="add"
              /> */}
            </StyledRow>
          </UIWrapper>
          <StyledApplyRow
            props={{
              onClick: () => onApply(),
            }}
          >
            <Box>{i18n.t('actions.global.apply')}</Box>
          </StyledApplyRow>
        </Col>
      </FilterBarItem>
    )
  }
}

export default SearchTerms
