import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/browser'
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {toast, ToastContainer, Zoom} from 'react-toastify'
import {ThemeProvider} from 'styled-components'
import './modules/utils/extensions'

import AccountSettings from './modules/account-settings'
import Advertisers from './modules/advertisers'
import AppLayout, {NotFound} from './modules/app-layout'
import CampaignBuilder from './modules/campaign-builder'
// import CategoryManagement from './modules/category-management'
import ClientPermissions from './modules/client-permissions'
import ClientTracker from './modules/client-tracker'
import DataManagement from './modules/data-management'
import ForgotPassword from './modules/forgot-password'
import Login from './modules/login'
import Maintenance from './modules/maintenance'
// import Monitoring, {PipelineDetails} from './modules/monitoring'
import SearchTermsAudit from './modules/search-terms-audit'
import {mainTheme} from './modules/utils/common-ui'
import {RequestingProvider} from './modules/utils/requesting'
import config from './config'
import {unregister} from './registerServiceWorker'
import setup from './setup'
import store, {history} from './store'

import 'antd/dist/antd.css'
import 'react-toastify/dist/ReactToastify.css'
import 'semantic-ui-css/semantic.min.css'

if (config.environment !== 'dev') {
  Sentry.init({
    dsn: 'https://65026c4bf7274018968302ea09aa9690@sentry.io/1530370',
    environment: config.environment,
    // eslint-disable-next-line no-unused-vars
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'console' && breadcrumb.level === 'log') {
        return null
      }
      return breadcrumb
    },
  })
}

setup(store)

// eslint-disable-next-line react/no-multi-comp
class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <RequestingProvider>
            <React.Fragment>
              <ThemeProvider theme={mainTheme}>
                <Maintenance>
                  <Switch path={history.location.pathname}>
                    <Route path="/login" component={Login} />
                    <Route path="/forgot_password" component={ForgotPassword} />
                    <Route
                      path="/"
                      // eslint-disable-next-line react/no-children-prop
                      children={({location}) => (
                        <AppLayout location={location}>
                          <Switch>
                            <Route path="/" render={() => <Redirect to="/search-terms-audit" />} exact />
                            {/* <Route path="/monitoring" component={Monitoring} exact />
                            <Route path="/monitoring/:id" component={PipelineDetails} /> */}
                            {/* <Route path="/category-management" component={CategoryManagement} /> */}
                            <Route path="/client-tracker" component={ClientTracker} />
                            <Route path="/client-permissions" component={ClientPermissions} />
                            <Route path="/advertisers" component={Advertisers} />
                            <Route path="/data-management" component={DataManagement} />
                            <Route path="/account-settings" component={AccountSettings} />
                            <Route path="/campaign-builder" component={CampaignBuilder} />
                            <Route path="/search-terms-audit" component={SearchTermsAudit} />
                          </Switch>
                        </AppLayout>
                      )}
                    />
                    <Route component={NotFound} />
                  </Switch>
                </Maintenance>
              </ThemeProvider>
              <ToastContainer
                transition={Zoom}
                position={toast.POSITION.BOTTOM_RIGHT}
                closeOnClick
                hideProgressBar
                pauseOnFocusLoss={false}
                pauseOnHover
              />
            </React.Fragment>
          </RequestingProvider>
        </ConnectedRouter>
      </Provider>
    )
  }
}

// eslint-disable-next-line react/no-render-return-value
global.reactInstance = ReactDOM.render(<App />, document.getElementById('root'))

unregister()
