import React from 'react'

import {Col, Row} from 'jsxstyle'
import styled from 'styled-components'

import {getDateFromCron} from '../..'
import colors from '../../colors'
import {PoppersPopup} from '../../common-ui'
import {AVAILABLE_SCHEDULE_MONTHLY_DELIVERY_DATE, AVAILABLE_SCHEDULE_WEEKLY_DELIVERY_DATE} from '../../constants'
import i18n from '../../i18n'

const StyledCell = styled(Col)`
  &&&&& {
    flex: 1;
    height: 100%;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: start;
    justify-content: center;
  }
`

const StyledRow = styled(Row)`
  &&&&& {
    gap: 5px;
  }
`

const StyledSquare = styled.span<{readonly active: boolean}>`
  background-color: ${props => (props.active ? colors.primary.rgba : colors.white.rgba)};
  border: solid ${colors.dark.rgba} 1px;
  border-radius: 5px;
  height: 10px;
  width: 10px;
`

type ItemType = {
  [key: string]: {
    value: number | null
    display: string
  }
}

interface SchedulerCellProps {
  item: ItemType
  schemeKey: string
}

const FREQUENCY_DELIVERY = {
  quarterly: 4,
  monthly: 2,
  weekly: 7,
}

const Square = ({active}: {active: boolean}) => <StyledSquare active={active} />

const SchedulerCell = (props: SchedulerCellProps) => {
  const {schemeKey, item} = props

  const {deliveryDate, frequencyDelivery} = getDateFromCron(item[schemeKey])

  const availableSchedule =
    frequencyDelivery === 'monthly' ? AVAILABLE_SCHEDULE_MONTHLY_DELIVERY_DATE : AVAILABLE_SCHEDULE_WEEKLY_DELIVERY_DATE

  const deliveryDateIndex = availableSchedule.findIndex(({value}) => value === deliveryDate)

  const element: React.ReactNode[] = []

  if (frequencyDelivery)
    for (let index = 0; index < FREQUENCY_DELIVERY[frequencyDelivery as keyof typeof FREQUENCY_DELIVERY]; index += 1) {
      element.push(<Square key={`${deliveryDateIndex}-${index}`} active={index === deliveryDateIndex} />)
    }

  return (
    <StyledCell>
      {frequencyDelivery === null ? (
        i18n.t('placeholders.empty.notAvailable')
      ) : (
        <PoppersPopup
          content={i18n.t(frequencyDelivery === 'monthly' ? 'exports.scheduledMonthly' : 'exports.scheduledWeekly', {
            deliveryDate: availableSchedule[deliveryDateIndex].text,
          })}
          trigger={
            <div>
              {i18n.t(`visualization.grouping.${frequencyDelivery}`)}
              <StyledRow>{element}</StyledRow>
            </div>
          }
        />
      )}
    </StyledCell>
  )
}

export default SchedulerCell
