import AlertsReviewsSeries from './AlertsReviewsSeries'
import CatalogManagementSeries from './CatalogManagementSeries'
import CatalogueChangesSeries from './CatalogueChangesSeries'
import CategoryManagementSeries from './CategoryManagementSeries'
import ClientTrackingSeries from './ClientTrackingSeries'
import KeywordSettingsSeries from './KeywordSettingsSeries'
import MonitoringSeries from './MonitoringSeries'
import ScrapingsSeries from './ScrapingsSeries'
import SoVRankingSeries from './SoVRankingSeries'

const MAPPING = {
  AlertsReviewsSeries,
  CatalogManagementSeries,
  CatalogueChangesSeries,
  CategoryManagementSeries,
  ClientTrackingSeries,
  KeywordSettingsSeries,
  MonitoringSeries,
  ScrapingsSeries,
  SoVRankingSeries,
}

export default function dataSeriesFactory(name, data) {
  return new MAPPING[name](data)
}

export {
  AlertsReviewsSeries,
  CatalogManagementSeries,
  CatalogueChangesSeries,
  CategoryManagementSeries,
  ClientTrackingSeries,
  KeywordSettingsSeries,
  MonitoringSeries,
  ScrapingsSeries,
  SoVRankingSeries,
}
