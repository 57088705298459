import React from 'react'

import Fuse from 'fuse.js'
import {Box, Col, Row} from 'jsxstyle'
import _ from 'lodash'
import PropTypes from 'prop-types'
import RelieverRegistry, {connect} from 'react-redux-reliever'
import {timer} from 'rxjs'
import {debounce, map, skip, tap} from 'rxjs/operators'
import {Grid, Image, Input, Loader, Ref, Sticky, Tab} from 'semantic-ui-react'
import styled from 'styled-components'
import Cookies from 'universal-cookie'

import KaioApiClient from '../../utils/apis/KaioApiClient'
import colors from '../../utils/colors'
import {SeelkBadge} from '../../utils/common-ui'
import {variable} from '../../utils/extensions/rx'
import i18n from '../../utils/i18n'
import theme from '../../utils/theme'
import rocket from '../images/rocket.png'

const UIName = styled.p`
  color: ${colors.primaryDark.rgba};
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin: 0;
  :hover {
    color: ${colors.primary.rgba};
    text-decoration: underline;
  }
`

const Component = React.mixins(React.Component).addUnmountedSubject().build()

class AccountSelect extends Component {
  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object,
  }

  static defaultProps = {
    accounts: null,
    user: null,
  }

  constructor() {
    super()
    this.state = {
      isLoadingApp: false,
      search: '',
    }
    this.searchTerm$ = variable('')
  }

  componentDidMount() {
    this.searchTerm$
      .asObservable()
      .pipe(
        skip(1),
        debounce(() => timer(100)),
        map(search => ({search})),
        tap(payload => this.setState(payload))
      )

      .subscribe()
  }

  setAccount = value => async () => {
    this.setState({isLoadingApp: true}, async () => {
      new Cookies().set('account', value, {path: '/'})

      await new KaioApiClient().refreshToken(value)
    })
  }

  renderDefaultPane(accountType) {
    const {accounts} = this.props

    if (accounts === null) return null

    const filteredAccounts =
      accountType === 'all' ? accounts : accounts.filter(account => account.account_type.includes(accountType))
    const fuseSearch = new Fuse(accounts, {
      shouldSort: true,
      threshold: 0.25,
      distance: 400,
      location: 0,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['name'],
    })
    const searchedAccounts = this.state.search
      ? fuseSearch.search(this.state.search)
      : filteredAccounts.sort((lhs, rhs) => lhs.name.localeCompare(rhs.name))
    const splittedAccounts = _(searchedAccounts).chunk(6).value()

    return (
      <Grid columns={6} centered stackable style={{maxWidth: 1000, width: 1000, margin: 0, marginTop: 20}}>
        {(() => {
          return splittedAccounts.map((row, rowIndex) => (
            <Grid.Row key={`row-${rowIndex + 1}`} stretched style={{padding: 0}}>
              {row.map(account => {
                const allBadges = account.account_type.map((type, i) => (
                  <SeelkBadge
                    absolutePosition
                    type={type}
                    key={account.client + type}
                    position={{
                      bottom: '0',
                      right: `${(i * 30).toString()}px`,
                    }}
                  />
                ))

                return (
                  <Grid.Column
                    key={account.name}
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 1000 / 6,
                      height: 1000 / 6,
                    }}
                  >
                    <Col
                      alignItems="center"
                      justifyContent="space-between"
                      maxHeight={150}
                      height={150}
                      maxWidth={150}
                      width={150}
                      backgroundColor={colors.lightAccent.rgba}
                      padding={15}
                      props={{onClick: this.setAccount(account.client)}}
                      className={theme.primaryButton}
                    >
                      <img
                        style={{
                          maxWidth: 80,
                          width: 'auto',
                          height: 'auto',
                          maxHeight: 80,
                        }}
                        src={account.logo ? account.logo : 'https://static.leboncoin.fr/img/no-picture.png'}
                        alt={account.client}
                      />
                      <UIName>{account.name}</UIName>
                      {allBadges}
                    </Col>
                  </Grid.Column>
                )
              })}
            </Grid.Row>
          ))
        })()}
      </Grid>
    )
  }

  renderPanes() {
    return [
      {
        menuItem: {
          key: 'all',
          content: i18n.t('visualization.grouping.all'),
        },
        render: () => this.renderDefaultPane('all'),
      },
      {
        menuItem: {
          key: 'saas',
          content: i18n.t('onboarding.index.saas'),
        },
        render: () => this.renderDefaultPane('saas'),
      },
      {
        menuItem: {
          key: 'agency',
          content: i18n.t('onboarding.index.agency'),
        },
        render: () => this.renderDefaultPane('agency'),
      },
      {
        menuItem: {
          key: 'prospect',
          content: i18n.t('onboarding.index.prospect'),
        },
        render: () => this.renderDefaultPane('prospect'),
      },
    ]
  }

  render() {
    const {user} = this.props
    return (
      <Row flex="1" height="100vh">
        {this.state.isLoadingApp && (
          <Box
            position="absolute"
            top="0px"
            left="0px"
            width="100vw"
            height="100vh"
            backgroundColor={colors.white.alpha(0.3).rgba}
          >
            <Col flex="1" height="100%" justifyContent="center">
              <Row flex="1" alignItems="center" justifyContent="center">
                <Loader size="large" inline active />
              </Row>
            </Col>
          </Box>
        )}
        <Ref innerRef={this.contextRef}>
          <Col
            flex="1"
            padding="0"
            filter={this.state.isLoadingApp ? 'blur(5px)' : 'none'}
            overflowX="hidden"
            overflowY="scroll"
            alignItems="center"
          >
            <Col
              justifyContent="center"
              alignItems="center"
              width="100vw"
              style={{height: 300, backgroundColor: colors.primary.rgba, padding: 50}}
            >
              <Image src={rocket} centered height={125} />
              <h2 style={{color: colors.white.rgba, textAlign: 'center'}}>
                {i18n.t('placeholders.global.welcome')}
                {user && user.name && user.name.split(' ')[0] ? `, ${user.name.split(' ')[0]} !` : ''}
              </h2>
              <p style={{color: colors.white.alpha(0.75).rgba, textAlign: 'center', fontSize: 14}}>
                {i18n.t('actions.select.account')}
              </p>
            </Col>
            <Sticky
              context={this.contextRef}
              offset={20}
              style={{marginTop: 20, marginBottom: 20, width: '100vw', zIndex: 1001}}
            >
              <Row justifyContent="center" alignItems="center">
                <Input
                  style={{width: 500}}
                  icon="search"
                  iconPosition="left"
                  placeholder={i18n.t('actions.search.search')}
                  onChange={(x, event) => {
                    this.searchTerm$.value = event.value
                  }}
                />
              </Row>
            </Sticky>
            <Row minWidth="100%" maxWidth={1000} justifyContent="center">
              <Tab
                renderActiveOnly
                menu={{
                  compact: true,
                  pointing: true,
                  secondary: true,
                  attached: false,
                }}
                panes={this.renderPanes()}
                grid={null}
              />
            </Row>
          </Col>
        </Ref>
      </Row>
    )
  }
}
export default connect({
  props: (state, ownProps) => ({
    user:
      RelieverRegistry.moduleState('app', state).user &&
      RelieverRegistry.moduleState('app', state).user.asMutable({deep: true}),
    ...ownProps,
  }),
})(AccountSelect)
