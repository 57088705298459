export default {
  index: {
    acos: 'ACoS',
    acpc: 'ACpC',
    adGroups: 'Ad Groups',
    adGroupsNumber: {
      one: 'One Ad Group',
      other: '{{count}} Ad Groups',
      zero: 'No Ad Group',
    },
    allBrands: 'All Brands',
    allCampaigns: 'All Campaigns',
    allAdGroups: 'All Ad Groups',
    annualSpent: 'Annual Spent',
    annualSales: 'Annual Sales',
    budget: 'Budget',
    campaignhsa: 'Sponsored Brands',
    campaignpda: 'Sponsored Display',
    campaigns: 'Campaigns',
    campaignsb: 'Sponsored Brands',
    campaignsd: 'Sponsored Display',
    campaignsp: 'Sponsored Products',
    campaignsNumber: {
      one: 'One Campaign',
      other: '{{count}} Campaigns',
      zero: 'No Campaign',
    },
    clicks: 'Clicks',
    countries: 'Countries',
    ctr: 'CTR',
    hsa: 'HSA',
    impressions: 'Impressions',
    keywords: 'Keywords',
    offers: 'Offers',
    overspend: 'Overspend',
    overview: 'Overview',
    pda: 'PDA',
    periodElapsed: 'Period elapsed',
    planned: 'Planned',
    products: 'Products',
    profiles: 'Profiles',
    qtdBudget: 'Qtd Budget',
    qtdRoas: 'Qtd ROAS',
    remaining: 'Remaining',
    roas: 'ROAS',
    roasByCampaigns: 'ROAS By Campaigns',
    sb: 'SB',
    sd: 'SD',
    sp: 'SP',
    spends: 'Spends',
    sales: 'Sales',
    salesByCampaigns: 'Sales by Campaigns',
    spendingAndSales: 'Spending & Sales',
    spending: 'Spending',
    spendsSales: 'Spends / Sales',
    spent: 'Spent',
    spendsByCampaigns: 'Spends by Campaigns',
    totalBudget: 'Total Budget',
    totalSales: 'Total Sales',
    totalSpent: 'Total Spent',
    underspend: 'Underspend',
    ytdBudget: 'Ytd Budget',
    ytdRoas: 'Ytd ROAS',
  },
  advertising: {
    adGroupsDataFile: 'Ad-Groups-Data',
    analysisDashboards: 'My Analysis Dashboards',
    campaignsDataFile: 'Campaigns-Data',
    marketplaceMetrics: 'Marketplace Metrics',
    reportsDashboards: 'My Reports Dashboards',
    selectYourMarketplace: 'Select your marketplace',
  },
  overview: {
    advertisedAsin: 'ASIN being advertised',
    advertisedSku: 'SKU being advertised',
    marketingDataFile: 'Marketing-Data',
    otherAsin: 'Other ASIN',
    remainingYtd: 'Remaining > YTD',
    roasYtd: 'ROAS > YTD',
    spendingYtd: 'Spending > YTD',
    salesYtd: 'Sales > YTD',
  },
  budgeting: {
    attributedBudget: 'Attributed Budget',
    budget: 'Budget',
    budgetDataFile: 'Budget-Data',
    budgetOverview: 'Budget Overview',
    budgetManagement: 'Budget Management',
    budgetBreakdown: 'Budget Breakdown',
    remainingAmount: 'Remaining Amount ({{code}})',
    overallBudget: 'Overall Budget',
    remaining: 'REMAINING',
    remainingBudget: 'Remaining Budget',
    spent: 'SPENT',
    spentPercentage: 'SPENT %',
    startSelectMarketplace: 'Start by selecting a marketplace',
    startDefineBudget: 'Start by defining your total budget !',
    totalAmount: 'Total Amount ({{code}})',
    total: 'TOTAL',
  },
  units: {
    acos: 'Advertising Cost of Sales (ACoS)',
    acpc: 'Average Cost per Clicks (ACpC)',
    clicks: 'Clicks • #',
    ctr: 'Click Through Rate • %',
    impressions: 'Impressions • #',
    roas: 'Return On Ad Spend',
    sales: 'Sales',
    spends: 'Spends',
  },
}
