import React, {useState} from 'react'

import {Col, Row} from 'jsxstyle'
import {List as VirtualList, ListRowProps} from 'react-virtualized'
import {InputOnChangeData, List, ListItem} from 'semantic-ui-react'
import styled from 'styled-components'

import {fuzzysearch} from '../../..'
import colors from '../../../colors'
import i18n from '../../../i18n'
import SelectAll from '../Filters/FiltersBar/common/SelectAll'
import FilterBarItem from '../Filters/FiltersBar/items/FilterBarItem'
import OperatorInNotIn from '../OperatorInNotIn'
import PoppersPopup from '../PoppersPopup'
import SeelkCheckbox from '../SeelkUIKit/SeelkCheckbox'
import DebouncedInput from './DebouncedInput'

const UIWrapper = styled.div`
  width: 350px;
`

const UIContentPane = styled.div`
  display: flex;
  height: calc(100% - 30px);
`

const StyledRow = styled(Row)`
  padding: 10px 10px 0 10px;
`

const StyledCol = styled(Col)`
  padding: 10px 10px 0 10px;
`

const StyledList = styled(List)`
  &&&&& {
    height: 270px;
    width: 100%;
    overflow: scroll;
  }
`
const StyledListItem = styled(ListItem)`
  &&&&& {
    overflow-x: hidden;
    height: 30px;
    font-weight: ${props => (props.selected ? '500' : props.color)};
    > div > label {
      color: ${props => (props.selected ? colors.primary.rgba : props.color)};
      :focus {
        color: ${props => (props.selected ? colors.primary.rgba : props.color)};
      }
      &::after {
        color: ${props => (props.selected ? colors.primary.rgba : props.color)};
        :focus {
          color: ${props => (props.selected ? colors.primary.rgba : props.color)};
        }
      }
    }
  }
`

const StyledDebouncedInput = styled(DebouncedInput)`
  &&&&& {
    width: 100%;
  }
`

type DataType = {
  value: string
  key: string | number
}

interface VirtualFilterSearchObjectsProps {
  choices: DataType[]
  onChange: (changes: (string | number)[]) => void
  trigger: React.ReactNode
  value: (string | number)[]
  disabled?: boolean
  operator?: 'in' | 'not_in'
  placeholder?: string
  selectAll?: (choices: (string | number)[]) => void
  updateOperator?: ((operator: 'in' | 'not_in') => void) | null
  [key: string]: any
}

const VirtualFilterSearchObjects = ({
  choices,
  onChange,
  selectAll,
  trigger,
  value,
  disabled = false,
  operator = 'in',
  placeholder = i18n.t('filters.byAsins'),
  updateOperator = null,
  ...barItemProps
}: VirtualFilterSearchObjectsProps) => {
  const [name, setName] = useState('')
  const filteredChoices = choices.filter(item => fuzzysearch(name, item?.value?.toString() ?? ''))

  const isSelected = (choice: DataType['key']) => value.indexOf(choice) > -1

  const handleUpdate = (values: (string | number)[], newValue: string | number) => {
    return values.includes(newValue)
      ? onChange([...Array.from(new Set(values.filter(id => id !== newValue)))])
      : onChange([...Array.from(new Set([...values, newValue]))])
  }

  const rowRenderer = ({key, index, style}: ListRowProps) => {
    const choice = filteredChoices[index].value
    const choiceId = filteredChoices[index].key
    return (
      <StyledListItem key={key} selected={isSelected(choiceId)} style={style}>
        <SeelkCheckbox
          label={
            <PoppersPopup
              position="bottom center"
              trigger={
                <label style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{choice}</label>
              }
              content={choice}
            />
          }
          onClick={() => handleUpdate(value, choiceId)}
          checked={isSelected(choiceId)}
        />
      </StyledListItem>
    )
  }

  return (
    <FilterBarItem {...barItemProps} onChange={onChange} data={value} disabled={disabled} trigger={trigger} noPadding>
      <UIWrapper>
        <StyledRow>
          <StyledDebouncedInput
            fluid
            icon="search"
            disabled={disabled}
            iconPosition="left"
            transparent={false}
            placeholder={placeholder}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, item: InputOnChangeData) => setName(item.value)}
            value={name}
          />
        </StyledRow>
        {updateOperator ? (
          <StyledCol>
            <OperatorInNotIn updateOperator={updateOperator} operator={operator} />
          </StyledCol>
        ) : null}
        {selectAll ? (
          <StyledCol>
            <SelectAll choices={filteredChoices.map(({key}) => key)} data={value} bulkChange={selectAll} />
          </StyledCol>
        ) : null}
        <StyledRow alignItems="center">
          <StyledList>
            <UIContentPane>
              <VirtualList
                width={350}
                height={265}
                rowCount={filteredChoices.length}
                rowHeight={30}
                rowRenderer={rowRenderer}
              />
            </UIContentPane>
          </StyledList>
        </StyledRow>
      </UIWrapper>
    </FilterBarItem>
  )
}

export default VirtualFilterSearchObjects
