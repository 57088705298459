export default {
  advancedSettings: 'Advanced settings',
  advancedSettingsPlaceholder: 'Click Advanced Settings to add custom tag, groups and customise your catalog section.',
  numberProducts: {
    one: '1 product',
    other: '{{count}} products',
    zero: 'no product',
  },
  productView: {
    description: 'Description',
    newProduct: {
      one: 'One new product at {{currency}} {{minPrice}}',
      other: '{{count}} new products from {{currency}} {{minPrice}} to {{currency}} {{maxPrice}}',
      zero: 'No new products in sale',
    },
    noStock: 'Not available',
    price: 'Price',
    reviews: {
      one: '1 customer review',
      other: '{{count}} customer reviews',
      zero: 'No customer review',
    },
    stock: 'In stock',
  },
  productGraph: {
    buyBoxPrice: 'Buy Box Price',
    buyBoxWinner: 'Buy Box Winner',
    lastDays: 'Last 7 days',
    lastMonth: 'Last month',
    lastMonths: 'Last 3 months',
    lastYear: 'Last year',
    maxPrice: 'Max. price',
    minPrice: 'Min. price',
    noData: 'No data to show',
    noOfferListingData: 'Sorry, no data has been fetched for the offers.',
    offersList: 'Offers List',
    pricingHistory: 'Pricing History',
    vendorPrice: 'Vendor price',
  },
  store: {
    nothingSelected: 'No product has been selected.',
  },
}
