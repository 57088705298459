import React from 'react'

import styled from 'styled-components'

import color from '../../../colors'

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px 30px;
`

const StyledIcon = styled.i<{readonly iconColor: string | null}>`
  font-size: 6em;
  color: ${props => (props.iconColor ? props.iconColor : color.primary.rgba)};
`

const StyledTitle = styled.div`
  font-size: 1.2em;
  font-weight: 500;
  margin: 10px 0;
  color: ${color.dark.rgba};
`

const StyledContent = styled.p`
  font-size: 1.1em;
  color: ${color.darkLight.rgba};
`

interface SeelkHeaderProps {
  content?: React.ReactNode | string | null
  icon?: string
  iconColor?: string | null
  title?: React.ReactNode | string | null
  style?: Record<string, string | number>
}

const SeelkHeader = ({content = null, icon = '', iconColor = null, title = null, style = {}}: SeelkHeaderProps) => {
  return (
    <StyledHeader key={icon} style={{...style}}>
      <StyledIcon className={icon} iconColor={iconColor} />
      <StyledTitle>{title}</StyledTitle>
      <StyledContent>{content}</StyledContent>
    </StyledHeader>
  )
}

export default SeelkHeader
