import React from 'react'
import ReactDOM from 'react-dom'

import {Box} from 'jsxstyle'
import {Button} from 'semantic-ui-react'

import colors from '../../colors'
import OutsideClickHandler from '../../OutsideClickHandler'
import OutsideClickHandlerForPortal from '../../OutsideClickHandlerForPortal'
import theme from '../../theme'

interface PopOverProps {
  children?: React.ReactNode
  className?: string
  clickToClose?: boolean
  containerProps?: Record<string, any>
  containsPortal?: boolean
  dimBackground?: boolean
  disabled?: boolean
  height?: string
  isOpened?: boolean
  marginRight?: string | number
  minHeight?: string
  onClose?: () => void
  onOpen?: () => void
  outsideWrapper?: Record<string, string> | null
  popOverPosition?: 'left' | 'right'
  showCloseButton?: boolean
  trigger?: React.ReactNode
  triggered?: boolean | null
}

interface PopOverState {
  triggered: boolean
}

class PopOver extends React.Component<PopOverProps, PopOverState> {
  constructor(props: PopOverProps) {
    super(props)
    this.state = {
      triggered: false,
    }
  }

  triggered = () => {
    const {triggered = null} = this.props
    if (triggered !== null) return triggered
    return this.state.triggered
  }

  toggleTriggered = () => {
    this.setState(state => ({
      triggered: !state.triggered,
    }))
  }

  open = () => {
    if (!this.triggered()) {
      this.setState({triggered: true})
      if (this.props.onOpen) this.props.onOpen()
    }
  }

  close = () => {
    if (this.triggered()) {
      this.setState({triggered: false})
      if (this.props.onClose) this.props.onClose()
    }
  }

  render() {
    const {
      children = null,
      clickToClose = true,
      containerProps = {},
      containsPortal = false,
      dimBackground = false,
      disabled = false,
      onClose,
      onOpen,
      popOverPosition = 'left',
      showCloseButton = false,
      trigger = null,
      ...additionalProps
    } = this.props

    const UsedOutsideClickHandler = containsPortal ? OutsideClickHandlerForPortal : OutsideClickHandler
    return (
      <UsedOutsideClickHandler onOutsideClick={this.close}>
        <Box position="relative">
          {dimBackground &&
            this.triggered() &&
            ReactDOM.createPortal(
              <Box
                position="absolute"
                top={0}
                left={0}
                bottom={0}
                right={0}
                backgroundColor={colors.black.alpha(0.8)}
              />,
              document.body
            )}
          <Box
            style={
              dimBackground && this.triggered()
                ? {
                    cursor: 'pointer',
                    zIndex: this.triggered() ? 15 : 'initial',
                    position: 'relative',
                    background: colors.white.rgba,
                    padding: 4,
                    borderRadius: 4,
                  }
                : {cursor: 'pointer', padding: dimBackground ? 4 : 0}
            }
            props={{onClick: disabled || (this.triggered() && clickToClose) ? this.close : this.open}}
            {...additionalProps}
          >
            {trigger}
          </Box>
          {children && (
            <Box
              zIndex={15}
              position="absolute"
              left={popOverPosition === 'left' ? 0 : undefined}
              right={popOverPosition === 'right' ? 0 : undefined}
              {...containerProps}
              display={this.triggered() ? 'block' : 'none'}
            >
              {showCloseButton && (
                <Button className={theme.unshadowed} basic icon="close" floated="right" onClick={this.close} />
              )}
              {children}
            </Box>
          )}
        </Box>
      </UsedOutsideClickHandler>
    )
  }
}

export default PopOver
