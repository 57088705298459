import React from 'react'

import {Row} from 'jsxstyle'
import moment, {Moment} from 'moment'
import styled from 'styled-components'

import colors from '../../../../colors'
import i18n from '../../../../i18n'
import PopOver from '../../PopOver'
import SeelkButton from '../../SeelkUIKit/SeelkButton'
import {FormatType} from './constants'
import DateRangeInput from './DateRangeInput'
import DateRangePickerPanel from './DateRangePickerPanel'
import {modeIsLowerThan} from './utils'

interface DateRangePickerProps {
  className: string
  prefixCls?: string
  dateRender?: (...arg: any) => any
  disabledDate?: (parsed: Moment, value?: Moment) => boolean
  showWeekNumber?: boolean
  startDate?: Moment
  endDate?: Moment
  onChange: (value: Moment[]) => void
  mode?: FormatType
  selectMode?: 'start' | 'end'
  disabled?: boolean
}

interface DateRangePickerState {
  startDate: Moment
  endDate: Moment
  mode: FormatType
  selectMode: 'start' | 'end'
  open: boolean
}

class DateRangePicker extends React.Component<DateRangePickerProps, DateRangePickerState> {
  static defaultProps = {
    prefixCls: 'datePicker',
    dateRender: undefined,
    disabledDate: undefined,
    showWeekNumber: true,
    startDate: moment.utc(),
    endDate: moment.utc(),
    mode: 'day',
    selectMode: 'start',
    disabled: false,
  }

  constructor(props: DateRangePickerProps) {
    super(props)
    this.state = {
      mode: props.mode || 'day',
      selectMode: props.selectMode || 'start',
      startDate: props.startDate || moment.utc(),
      endDate: props.endDate || moment.utc(),
      open: false,
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({mode: this.props.mode || 'day', selectMode: this.props.selectMode || 'start'})
  }

  UNSAFE_componentWillReceiveProps(nextProps: DateRangePickerProps) {
    if (this.props.mode !== nextProps.mode) this.setState({mode: nextProps.mode || 'day'})
    if (this.props.selectMode !== nextProps.selectMode) this.setState({selectMode: nextProps.selectMode || 'start'})
  }

  handleApplyClick = () => {
    this.props.onChange([this.state.startDate, this.state.endDate])
    this.setState({open: false})
  }

  handleChange = (switchSelectMode: boolean) => ([startDate, endDate]: Moment[]) => {
    if (startDate.isBefore(endDate)) {
      if (switchSelectMode)
        this.setState({
          startDate,
          endDate,
          selectMode: this.state.selectMode === 'start' ? 'end' : 'start',
        })
      else this.setState({startDate, endDate})
    } else {
      this.setState({
        startDate: endDate.startOf(this.state.mode),
        endDate: startDate.endOf(this.state.mode),
      })
    }
  }

  handleChangeMode = (mode: FormatType) => {
    if (modeIsLowerThan(this.props.mode || 'day', mode)) this.setState({mode})
  }

  handleChangeSelectMode = (selectMode: 'start' | 'end') => {
    this.setState({selectMode})
  }

  render() {
    return (
      <div className={`${this.props.className} ${this.state.open ? 'opened' : 'closed'}`}>
        <PopOver
          disabled={this.props.disabled}
          clickToClose={false}
          popOverPosition="right"
          onOpen={() => this.setState({open: true})}
          onClose={() => this.setState({open: false})}
          triggered={this.state.open}
          className={`${this.props.prefixCls}TriggerBox`}
          trigger={
            <div className={`${this.props.prefixCls}InputContainer`}>
              <DateRangeInput
                prefixCls={this.props.prefixCls}
                disabledDate={this.props.disabledDate}
                onChange={this.handleChange(false)}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                mode={this.state.mode}
                selectMode={this.state.selectMode}
                onChangeMode={this.handleChangeMode}
                onChangeSelectMode={this.handleChangeSelectMode}
                disabled={this.props.disabled}
              />
            </div>
          }
        >
          <div className={`${this.props.prefixCls}Box`}>
            <DateRangePickerPanel
              prefixCls={this.props.prefixCls}
              dateRender={this.props.dateRender}
              disabledDate={this.props.disabledDate}
              showWeekNumber={this.props.showWeekNumber}
              onChange={this.handleChange(true)}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              mode={this.state.mode}
              selectMode={this.state.selectMode}
            />
            <Row justifyContent="flex-end" alignItems="center" padding={8}>
              <SeelkButton type="confirm" content={i18n.t('actions.global.apply')} onClick={this.handleApplyClick} />
            </Row>
          </div>
        </PopOver>
      </div>
    )
  }
}

export default styled(DateRangePicker)`
/* prettier-ignore */
  
&& .${props => props.prefixCls || 'datePicker'}TriggerBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-weight: 500;
}

&& .${props => props.prefixCls || 'datePicker'}Box {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${colors.white.rgba};
  border: 1px solid ${colors.border.rgba};
  border-radius: 4px;
  margin-top: 12px;
}

&& .${props => props.prefixCls || 'datePicker'}InputContainer {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  hover-box-shadow: 0px 0px 4px ${colors.shadow.rgba};
  hover-color: colors.periodAAccent.rgba;
  padding: 8px;
  transition: border 0.1s ease, color 0.1s ease, box-shadow 0.1s ease;
  background-color: ${colors.white.rgba};
}

&& .${props => props.prefixCls || 'datePicker'}InputContainer {
  border: solid 1px ${colors.periodAAccent.rgba};
  hover-border: solid 1px ${colors.periodAAccent.rgba};
}
  
&& .${props => props.prefixCls || 'datePicker'}Label {
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 6px;
}   
 
&& .${props => props.prefixCls || 'datePicker'}Label {
  background-color: ${colors.periodABackground.rgba};
  color: ${colors.periodAAccent.rgba};
}

&& .${props => props.prefixCls || 'datePicker'}Input > input {
  background: none;
  border: none;
  width: 90px;
  height: 20px;
  padding: 0px;
  margin: 0;
  text-align: center;
}

&& .${props => props.prefixCls || 'datePicker'}InputStart {
  &::after {
    content: "—";
    margin: 0 5px;
  }
}

&&.opened .${props => props.prefixCls || 'datePicker'}InputSelected input {
  background-color: ${colors.periodA.rgba};
  color: ${colors.white.rgba};
}

& .${props => props.prefixCls || 'datePicker'}Widgets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  margin-top: 10px;
  margin-right: 12px;
}

& .${props => props.prefixCls || 'datePicker'}Widgets > * {
  flex: 0;
}

& .${props => props.prefixCls || 'datePicker'}ModeSwitcher > a.item > span::after {
  content: "s";
}

& .${props => props.prefixCls || 'datePicker'}Board {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 10px;
  margin: 8px;
  border-radius: 4px;
  border: 1px solid ${colors.border.rgba};
}

& .${props => props.prefixCls || 'datePicker'}Board {
  border-top: 2px solid ${colors.periodAAccent.rgba};
}

& .${props => props.prefixCls || 'datePicker'}Presets {
  position: relative;
  flex: 1 1 300px;
  min-width: 120px;
}

& .${props => props.prefixCls || 'datePicker'}PresetsList {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  margin: 0;
  flex: 1 1 300px;
  & > .item.item.item {
    color: ${colors.dark.rgba};
  }
}

& .${props => props.prefixCls || 'datePicker'}Spacer {
  border: 1px solid ${colors.border.rgba};
  margin: 0 5px;
}
  
& .${props => props.prefixCls || 'datePicker'}Range {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
}
    
& .${props => props.prefixCls || 'datePicker'}Panel {
  position: relative;
  padding: 8px;
}

& .${props => props.prefixCls || 'datePicker'}Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

& .${props => props.prefixCls || 'datePicker'}PrevButton,
& .${props => props.prefixCls || 'datePicker'}NextButton {
  outline: none;
  border: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 26px;
  &:hover {
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.65);
  }
}

& .${props => props.prefixCls || 'datePicker'}PrevButton {
  left: 29px;
  &::before {
    content: "‹";
  }
}

& .${props => props.prefixCls || 'datePicker'}NextButton {
  right: 29px;
  &::before {
    content: "›";
  }
}

& .${props => props.prefixCls || 'datePicker'}YearWidget,
& .${props => props.prefixCls || 'datePicker'}MonthWidget {
  display: inline-block;
  padding: 0 2px;
  outline: none;
  border: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  line-height: 29px;
  &:hover {
    cursor: pointer;
    color: ${colors.primary.rgba};
  }
}

& .${props => props.prefixCls || 'datePicker'}Table {
  width: fit-content;
}

& .${props => props.prefixCls || 'datePicker'}Head {
  border-top: 1px solid #e8e8e8;
  padding: 8px 12px;
}

& .${props => props.prefixCls || 'datePicker'}Body {
  padding: 8px 12px;
}

& .${props => props.prefixCls || 'datePicker'}ColumnHeader {
  cursor: default;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
  color: ${colors.darkLight.rgba};
}

& .${props => props.prefixCls || 'datePicker'}ColumnHeaderInner {
  display: block;
  font-weight: normal;
}

& .dayTable .${props => props.prefixCls || 'datePicker'}Cell {
  &.weekCell > div {
    color: ${colors.dark.rgba};
    font-style: italic;
    font-size: 13px;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}

& .weekTable .${props => props.prefixCls || 'datePicker'}Cell {
  &.dayCell {
    color: ${colors.dark.rgba};
    &.isNow > div {
      border: none;
    }
    &.isHover,
    &.isSelected,
    &.isExtremity {
      color: ${colors.white.rgba};
    }
    &.isInHoverRange.isInHoverRange {
      color: ${colors.dark.rgba};
    }
    &.isOutside {
      opacity: 0.5;
    }
  }

  &.weekCell {
    font-weight: 500;
    font-size: 16px;
  }
}

& .${props => props.prefixCls || 'datePicker'}Cell {
  &:hover {
    cursor: pointer;
  }
  
  & > div {
    text-align: center;
  }
  
  &.yearCell {
    & > div {
      width: 50px;
      height: 40px;
      line-height: 40px;
    }
  }
  
  &.monthCell {
    & > div {
      width: 50px;
      height: 40px;
      line-height: 40px;
    }
  }
  
  &.weekCell {
    & > div {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }
  
  &.dayCell {
    & > div {
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
  }
  
  &.isNow {
    & > div {
      border: solid 1px ${colors.primaryAccent.rgba};
    }
    color: ${colors.primaryAccent.rgba};
  }
  
  &.isDisabled {
    color: ${colors.black.alpha(0.25).rgba}
    background: ${colors.lightDark.rgba};
    border: 1px solid transparent;
    cursor: not-allowed;
  }

  &.isOutside {
    opacity: 0.3;
  }

  &.isExtremity,
  &.isSelected {
    opacity: 1;
    font-weight: 500;
    color: ${colors.white.rgba};
  }

  &.isInRange {
    color: ${colors.primaryDark.rgba}
  }

  &.isHover {
    opacity: 0.7;
    color: ${colors.white.rgba};
  }

  &.isInHoverRange.isInHoverRange {
    color: ${colors.primaryDark.rgba}
  }
}

& .${props => props.prefixCls || 'datePicker'}Cell {
  &.isExtremity,
  &.isSelected {
    background-color: ${colors.periodAAccent.rgba};
  }

  &.isInRange {
    background-color: ${colors.periodABackground.rgba};
  }

  &.isHover {
    background-color: ${colors.periodAAccent.rgba};
  }

  &.isInHoverRange.isInHoverRange {
    background-color: ${colors.periodAAccent.alpha(0.1).rgba};
  }
}

`
