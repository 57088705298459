export default {
  index: {
    chooseSellerType: 'Choisissez votre type de compte seller:',
    descriptions: {
      headLine: "Nous vous aiderons à croître vos ventes sur Amazon en un clin d'oeil :)",
      subLine:
        "Pour tirer parti de cette application, vous devez d'abord configurer votre compte. Cela ne prendra que quelques minutes.",
    },
    mainTitle: 'Bienvenue sur le Studio Seelk !',
    sellers: {
      seller: {
        authToken: 'Auth Token',
        availableRegions: 'Régions disponibles: ',
        button: 'Continuer en tant que SELLER',
        defaultRegion: 'Sélectionnez votre région par défaut',
        firstLine: 'Je vends sur Amazon en tant que',
        formTitle: 'Intégration Seller',
        loginToCredientals: 'Connectez-vous à votre compte Seller et trouvez vos identifiants',
        secondLine: 'SELLER',
        sellerId: 'Seller Id',
        step: {
          credentialsSetUp: 'Identifiants {{accountType}}',
          selectYourZone: 'Sélectionnez votre zone',
          startedProcessingPipeline: 'Inspection du catalogue lancée',
        },
        zone: 'Zone',
      },
      vendor: {
        button: 'Continer en tant que VENDOR',
        firstLine: 'Je vends sur Amazon en tant que',
        secondLine: 'VENDOR',
      },
    },
  },
  processing: {
    clickToSeeWorkStation: 'Cliquez sur le lien ci-dessous si vous souhaitez voir une prévisualisation:',
    description:
      "Nous sommes actuellement en train de lier votre compte Amazon {{accountType}} au  Studio Seelk, d'analyser vos données et de construire votre tableau de bord.",
    emailAnnouncement:
      'Une fois que nous aurons analysé votre compte, vous recevrez un email avec un lien vers votre Station de travail sur le Studio Seelk.',
    header: 'La machine est lancée !',
    tasks: {
      createProducts: 'Création des produits',
      createOffers: 'Création des offres',
      createCatalogue: 'Création du catalogue',
      importOrders: 'Import des commandes',
      importReports: 'Import des rapports',
      buildInventory: "Construction de l'Inventaire",
    },
  },
  seller: {
    loaded: {
      allYourReferencesWillBeHere: "Toutes vos références seront présentes d'ici demain.",
      firstLine: 'Nous avons déjà traité une portion conséquente de votre catalogue.',
      letsGoButton: "C'est parti !",
      ready: 'Prêt ?',
      secondLine:
        'Nous traitons encore le reste de vos données, mais nous pouvons déjà commencer à travailler ensemble sur la base de produits que nous avons ;-).',
      title: 'Fini !',
    },
    loading: {
      firstLine:
        'Un e-mail va vous être envoyé. Nous sommes actuellement en train de chercher et traiter vos produits.',
      secondLine:
        'En fonction de la taille de votre catalogue, cette étape peut prendre plus ou moins de temps. Vous recevrez un e-mail une fois ce traitement accompli.',
      title: 'Super !',
    },
  },
  tooltips: {
    menu: {
      competition: {
        title: 'Compétition',
        text: 'Nous scrutons les concurrents directs par défaut. Vous pouvez ainsi surveiller leurs performances et les comparer avec celles de vos produits.',
      },
    },
  },
  vendor: {
    havingTroublesText: 'Des difficultés à configurer votre compte ? Notre Support Client sera ravi de vous aider.',
    mainDescription:
      'Vous êtes donc un Vendor sur Amazon. Téléchargez les templates ci-dessous pour ajouter vos produits.',
  },
  zones: {
    eu: 'EU',
    us: 'US',
  },
}
