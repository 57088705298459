export default {
  account: {
    addAccount: 'Add an Account',
    administrator: 'Admin',
    editImage: 'Edit account image',
    editProfilPicture: 'Edit profile picture',
    editor: 'Editor',
    email: "Member's email",
    firstName: 'First Name',
    imageUploadMessage1: 'Drag and drop a picture or ',
    imageUploadMessage2: ' one from your computer',
    lastName: 'Last Name',
    members: {
      one: 'One member',
      other: '{{count}} members',
      zero: 'No member',
    },
    membersHeader: {
      one: 'Member (one)',
      other: 'Members ({{count}})',
      zero: 'No member yet, invite one !',
    },
    memberDetails: "Member's details",
    myAccount: 'My Amazon Accounts',
    salesFunnel: {
      bestSales: 'Best Sales',
      brandAllowed: 'You are allowed to select {{limit}} more brands',
      brandLimit: 'You have reached the limit of 10 brands',
      broad: 'Broad',
      chooseAnotherOne: 'Choose another one',
      competitor: {
        zero: 'competitors',
        one: 'competitor',
        other: 'competitors',
      },
      confirmation: 'You will receive an email once your brands are setup !',
      getBrandSuggestions: 'Get brand suggestions',
      noSuggestedBrands: 'No suggested brands',
      prospect: 'prospect',
      searchBrands: 'Search brands',
      selectBrands: 'Select the brands you are interested in ',
      selectCategory: 'Select a category to get recommended brands',
      selectMarketplace: 'Select a marketplace to see its categories',
    },
    type: 'Account Type',
    useAccountCurrency: 'Use Account’s Currency',
    useAccountDateFormat: 'Use Account’s Date Format',
  },
  attributes: {
    attributeDetails: 'Attribute details',
    attributeName: 'Attribute name',
    create: 'Create Attributes',
    deleteAttributes: 'Delete Attributes(s)',
    deleteAttributesDisabled: 'Select an attribute in order to activate delete',
    finalise: 'Finalise',
    introHelp: 'Read more about Attributes',
    introText: 'There are few steps to take in order to create an attribute and its subsequent groups',
    introTitle: 'Three quick steps to add an attribute!',
    new: 'New Attribute',
    success: 'Your attribute {{name}} has been successfully created!',
  },
  collections: {
    addTagsAndGroups: 'Tags & Groups',
    create: 'Create Attribute',
    options: 'Tags & Attributes',
    empty: {
      title: 'You currently have no Attributes',
      subtitle:
        'Seelk Studio’s Attributes gives you the power to organise your products in exclusive groups and compare their performance across all sectors of your Amazon business. Read your Help Center articles on Attributes to ',
      link: 'learn more.',
    },
    walkthrough: {
      step1: {
        title: 'What are Attributes?',
        subtitle: 'Create your Attributes and start grouping products!',
        text: [
          'Attributes are at the core of Seelk Studio and allow you to organise your products in exclusive groups. You can create as many Attributes as you like! For each attribute you can create one or more groups of products.',
        ],
      },
      step2: {
        title: 'What are ‘exclusive’ groups?',
        subtitle: 'Each product can only be classified in one group per Attribute',
        text: [
          'After you’ve created your first Attribute and begin creating your groups you will want to start linking products to those groups. Always remember that each product can exist in one group per Attribute. This enables you to make powerful performance comparisons.',
          'Learn more about how to use Attributes and why they different from Tags.',
        ],
        link: 'Learn More',
      },
      step3: {
        title: 'Attributes & groups are easy!',
        subtitle: 'Create a Attribute, add groups, link products to groups!',
        text: [
          'Attributes are a powerful way to analyse your Amazon business once you understand them. Don’t forget to learn more about how to use Attributes and why they different from Tags.',
        ],
        link: 'Learn More',
      },
    },
  },
  delete: {
    header: 'Delete {{value}}',
    title: "You're about to delete",
    warning: {
      one: 'Are you sure you want to delete this {{value}} ?',
      other: 'Are you sure you want to delete these {{value}} ?',
    },
  },
  general: {
    feedback: 'Feedback',
    giveFeedback:
      "You saw a bug, have a suggestion or simply want to say something ?\nWe'd like to hear your feedback !",
  },
  groups: {
    groups: 'Groups',
    add: 'Add Group',
    addAdGroups: 'Add Ad Groups to Group',
    addCampaigns: 'Add Campaigns to Group',
    addEdit: 'Add/Edit Groups',
    addGrouping: 'Add grouping',
    addItemToGroup: 'Add {{item}} to Group',
    addProducts: 'Add Products to Group',
    addProductsByGroups: 'Add Products by Group',
    addTo: 'Add Groups to {{name}}',
    deleteGroups: 'Delete Group(s)',
    deleteGroupsDisabled: 'Select a group in order to activate delete',
    edit: 'Edit group {{name}}',
    toGroup: "You're about to group ",
    useTemplate: 'Use template for bulk groupings. ',
    warning: 'Selecting a new group will override pre-existing groupings inside the same Attribute. ',
  },
  help: {
    amazonLexicon: 'Metric Lexicon',
    askUs: 'Ask us anything!',
    helpCenter: 'Help Center',
    leaveUsFeedback: 'Leave Us Feedback',
    needHelp: 'Need Help?',
    registrationSPAPI: 'SPAPI Registration',
  },
  keywords: {
    addExcel: 'Add keywords by uploading an excel',
    addManually: 'Add keywords manually',
    advancedSettingsPlaceholder:
      'Click Advanced Settings to add custom tag or customise your keyword tracking frequency.',
    dropzone: {
      howTo: 'Uploading via excel allows you to add keywords more easily in bulk.',
      help: 'Download the Excel template by clicking below. If you need help, check our Help Center to ',
    },
    keywordsToAdd: 'Keywords to add',
    successMsg: 'Your keywords should be available within the next 24 hours.',
    successTitle: 'Your new keywords are being updated!',
    title: 'How do you want to add keywords ?',
  },
  management: {
    advertisers: {
      add: 'Add Advertiser',
      advertisers: {zero: 'Advertisers', one: 'Advertiser', other: 'Advertisers'},
      delete: 'Delete Advertiser(s)',
      deleteDisabled: 'Select an advertiser in order to activate delete',
      edit: 'Edit {{name}}',
      errorId: 'This advertiser ID already exists',
      id: 'Advertiser ID',
      placeholderId: 'Advertiser ID ...',
      placeholderName: 'Advertiser name ...',
    },
    products: {
      loading: 'Fetching products and their settings',
      productState: 'State of product',
      trackingFrequency: 'Tracking frequency',
      section: 'Section',
    },
    keywords: {
      loading: 'Fetching keywords and their settings',
      trackingFrequency: 'Tracking frequency',
    },
    categories: {
      loading: 'Fetching categories and their settings',
      trackingFrequency: 'Tracking frequency',
    },
  },
  onboarding: {
    advertising: {
      accountName: 'Amazon Advertising ({{name}})',
      accountType: 'Advertising',
      amazonAdvertising: 'Amazon Advertising',
      finalButton: 'See my advertising profiles',
      finalText:
        'We are currently syncing all your Amazon Marketing data. You will receive a confirmation email in the next two hours — thereafter you can have access to your Advertising data.',
      finalTitle: 'Your advertising account is currently being connected!',
      profiles: '({{nb}} profiles)',
      title: 'Connect your Amazon Advertising',
    },
    deactivateAccount: 'Deactivate account',
    createAccount: {
      accountInformation: 'Account Information',
      addLogo: 'Click to upload',
      title: 'Create a Seelk Studio account',
    },
    confirmation: {
      seller: 'Amazon MWS',
      title: 'Your account was successfully connected!',
      textSeller:
        'We are currently syncing all your Merchant Web Services (MWS). You will receive a confirmation email in the next two hours — thereafter you can log into your account.',
      textVendor:
        'We are currently syncing all your Vendor account. You will receive a confirmation email in the next two hours — thereafter you can log into your account.',
      track: 'Track my account setup progress',
      deactivateAccount: 'Are you sure you want to deactivate the account',
      startOnboarding: 'Are you sure you want to start onboarding the account',
    },
    regionSeller: {
      subtitle: 'Click your region and select the marketplaces to connect',
    },
    marketplacesSeller: {
      subtitle: 'Select your marketplace in ',
    },
    countriesVendor: {
      subtitle: 'Select the country you wish to connect below. (N.B. You can only connect one country at a time!)',
    },
    continents: {
      europe: 'Europe',
      northAmerica: 'North America',
      australia: 'Australia',
    },
    credentialsSeller: {
      link: ' ‘How to Setup your Seller Account’ ',
      subtitle: 'Click below login to Seller Central',
      text: 'You can remove the marketplaces you previously selected or simply continue by clicking "Login to Seller Central". Be sure to follow the steps to finalise your Seller Central account connection.',
    },
    credentialsVendor: {
      subtitle: 'Please, give us your Amazon Vendor credentials for {{country}}',
    },
    tunnel: {
      accountVerification: 'Account Verification',
      countrySelect: 'Markeplace(s)',
      developerAccess: 'Developer Access',
      finalisation: 'Confirmation',
      marketplace: 'Marketplace(s)',
      selectAccount: 'Account Type',
      selectRegion: 'Region',
      userAccess: 'User Access',
    },
    initial: {
      title: 'You currently have no Amazon Accounts connected',
      subtitle: 'Seelk Studio connects and syncs with your Seller, Vendor & Amazon Advertising accounts',
      addAmazonAccount: 'Add Amazon Account',
      selectorTypeSubtitle: 'Select the type of Amazon account you want to connect',
      typeSeller: 'Amazon Seller',
      typeVendor: 'Amazon Vendor',
      typeAdvertising: 'Amazon Advertising',
    },
    loginAms: {
      title: 'Verify your Amazon Advertising account',
      text: 'In order for Seelk Studio to identify which countries you are currently Advertising, you need to login to your Amazon Marketing using your administrator account.',
    },
    typeTitle: {
      null: 'Connect an Amazon account',
      seller: 'Connect your Amazon Seller account',
      vendor: 'Connect your Amazon Vendor account',
      advertising: 'Connect your Amazon Advertising',
    },
    seller: {
      accountName: 'Amazon Seller ({{name}})',
      accountType: 'Seller',
    },
    startOnboarding: 'Start Onboarding',
    vendor: {
      accountName: 'Amazon Vendor ({{name}})',
      accountType: 'Vendor',
    },
    vendorSpapi: {
      accountName: 'Amazon Vendor SPAPI ({{name}})',
      accountType: 'Vendor SPAPI',
    },
    redirectionFailed: {
      content: 'We were unable to connect your Amazon account. Try again',
      title: 'Oops!',
    },
    redirectionSuccess: {
      content: 'Your Amazon Seller account has been successfully connected!',
    },
  },
  preferences: {
    displayedCatalogColumns: {
      selectColumns: 'Select catalog columns to hide and display',
      lhs: 'Displayed columns',
      rhs: 'Hidden columns',
    },
  },
  menus: {
    currency: 'Currency',
    dateFormat: 'Date Format',
    email: 'Email address',
    name: 'Name & Last Name',
    password: 'Password',
    picture: 'Profile picture',
    profile: 'Profile',
    security: 'Security',
  },
  labels: {
    currentEmailAddress: 'Current email address',
    newEmailAddress: 'New email address',
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    currentDate: 'Current date & time format',
    selectCurrency: 'Select a currency',
    recommendedSize: '[recommended dimensions 500px x 500px]',
  },
  headers: {
    updateEmail: 'Update Email',
    editPassword: 'Edit Password',
    dateFormat: 'Date Format',
    accountCurrency: 'My Account Currency',
  },
  placeholders: {
    dateFormat: 'Date Format',
    selectCurrency: 'Select a currency',
  },
  menu: {
    accountSettings: 'Account Settings',
    adGroups: 'Ad Groups',
    advertising: 'Advertising',
    alerts: 'Alerts',
    alertsAndNotifications: 'Alerts & Notifications',
    amazonAccounts: 'Amazon Accounts',
    attributes: 'Attributes',
    campaigns: 'Campaigns',
    // currencyAndDate: 'Currency & Date Format',
    currencyAndDate: 'Currency',
    dspCampaigns: 'DSP Campaigns',
    dsp: 'DSP',
    dspAdvertiser: 'DSP Advertiser',
    dspOrder: 'DSP Order',
    dspLineItem: 'DSP Line Item',
    dspCreative: 'DSP Creative',
    keywords: 'Keywords',
    myProfile: 'My Profile',
    mySettings: 'My Settings',
    navigation: 'Navigation',
    notifications: 'Notifications',
    products: 'Products',
    tags: 'Tags',
    teammates: 'Teammates',
  },
  navigation: {
    applications: 'Applications',
    availableItems: 'Available Menu Items',
    dashboards: 'Dashboards',
    homepage: 'Homepage',
    menuStructure: 'Menu Structure',
    navigationSaved: 'Your changes have been saved',
    placeholder: {
      title: 'Your navigation is empty',
      resetDefault: 'Reset default navigation',
    },
    remove: 'Remove from menu',
    resetDefault: 'Reset default',
    setHomepage: 'Set as homepage',
  },
  notifications: {
    title: 'Notifications',
    noNotifications: 'No current Notifications',
    seeAllNotifications: 'See all notifications',
    successDelete: 'Notification successfully archived!',
    all: 'All ({{count}})',
    archive: 'Archived ({{count}})',
    empty: 'You have no new notifications',
    product: {
      one: 'One Product',
      other: '{{count}} Products',
      zero: 'No Product',
    },
    typeMessage: {
      'Buy Box Loss': 'lost the Buy Box',
      'Buy Box Won': 'won the Buy Box',
      'Out of Stock': 'are out of stock',
      'Buy Box Inactive': 'have inactive Buy Box',
      'Buy Box Active': 'have active Buy Box',
    },
    menu: {
      all: 'All',
      archive: 'Archive',
    },
    frequency: {
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
    },
  },
  alerts: {
    alert: 'Alert',
    allowNotif: 'Allow Notifications',
    addAlert: 'Add Alert',
    addAsins: 'Add Asins',
    addElements: 'Add Elements',
    alertName: 'Name of alert',
    alertType: 'Type of alert',
    alertFrequency: 'Alert Frequency',
    alertInApp: 'In-app',
    alertEmail: 'Email',
    alertSettings: 'Alert Settings',
    and: 'and',
    channels: {
      email: 'Email',
      inapp: 'In-App',
      true: '(On)',
      false: '(Off)',
    },
    createAlert: 'Create Alert',
    frequency: {
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
    },
    labels: {
      scenario: {
        receive: 'I want to receive a',
        track: 'alert to track my',
        activity: 'activity',
        alertedOn: 'I want to be alerted on',
      },
    },
    myAlerts: 'My Alerts ({{count}})',
    noAlerts: 'You currently have no Alerts setup',
    noAlertsSub: 'Need help understanding how alerts work?',
    noProducts: 'No product has been selected in the list',
    noKeywords: 'No keyword has been selected in the list',
    learnMore: 'Learn More',
    placeholders: {
      alertName: 'Name your alert...',
      alertType: 'Select an alert type...',
      alertFrequency: 'Select frequency...',
      alertAsins: 'Select your ASINs',
    },
    selectProducts: 'Select Products',
    selects: {
      products: 'Products',
      tags: 'Tags',
    },
    table: {
      alert: 'Alert',
      frequency: 'Frequency',
      products: '# Products',
      type: 'Type',
      tags: '# Tags',
      groups: '# Groups',
    },
    totalSelectedAsins: 'You have selected a total of <b> ({{count}}) </b> products for this alert',
    track: 'I want to track',
    trackingFrequency: 'Tracking frequency',
    tunnel: {
      settings: 'Settings',
      tracking: 'Tracking',
    },
    type: {
      'buybox won': 'Buy Box Won',
      'buybox loss': 'Buy Box Lost',
      'buybox active': 'Buy Box Active',
      'buybox inactive': 'Buy Box Inactive',
      'out of stock': 'Out of Stock',
    },
  },
  tags: {
    adGroupTags: 'Ad Group Tags',
    addNewTag: 'Add New Tag',
    addProductsByTags: 'Add products by tags',
    addTag: 'Add Tag',
    addTags: 'Add Tags',
    advertiserTags: 'Advertiser Tags',
    and: 'and',
    campaignTags: 'Campaign Tags',
    creativeTags: 'Creative Tags',
    currentlyNoTagsAdGroup: 'There are currently no tags for these ad group(s)',
    currentlyNoTagsCampaign: 'There are currently no tags for these campaign(s)',
    currentlyNoTagsKeyword: 'There are currently no tags for these keyword(s)',
    currentlyNoTagsProduct: 'There are currently no tags for these product(s)',
    currentlyUsed: {
      one: 'Current tag ({{count}})',
      other: 'Current tags ({{count}})',
      zero: 'No Tag',
    },
    deleteTags: 'Delete Tag(s)',
    deleteTagsDisabled: 'Select a tag in order to activate delete',
    describeTag: 'Describe your tag...',
    editAdd: 'Edit / Add New Tag',
    editTags: 'Edit tags',
    empty: 'You currently have no Tags',
    errorEmpty: 'Your tag must contain at least one letter or number',
    errorExist: '{{name}} tag already exists or a similar one! Be more specific or use the existing',
    errorDynamicTag: '{{name}} name is reserved for Dynamic tags',
    errorTooLong: 'Your tag is way too long (> 255 characters)',
    giveYourTag: 'Give your tag a name',
    lineItemTags: 'Line Item Tags',
    matchingType: 'Matching Type',
    moreTags: 'More ({{count}})',
    noMoreTags: 'You have no more tags',
    noTags: 'No tags',
    offerTags: 'Product Tags',
    orderTags: 'Order Tags',
    recentTags: 'Recent Tags',
    saveTag: 'Save Tag',
    saveTags: 'Save Tags',
    seeAll: 'See all ({{count}})',
    selectOrAdd: 'Search, select & add new tags',
    tagPreview: 'Tag Preview',
    tagPreviewPlaceholder: 'Give your tag a name and a color to see a preview',
    toTag: 'You are tagging ',
    warningDeleteCampaignTags: 'Are you sure? Tags will be removed for all selected campaigns!',
    warningDeleteKeywordTags: 'Are you sure? Tags will be removed for all selected keywords!',
    warningDeleteProductTags: 'Are you sure? Tags will be removed for all selected products!',
    wrongMetric: 'This condition have the wrong metric !',
  },
  toolbar: {
    copy: 'Copy selected {{items}}',
    copyAsins: 'Copy selected ASINs',
    copyKeywords: 'Copy selected Keywords',
    deleteItems: 'Delete {{items}}',
    deleteKeywords: 'Delete keywords',
    inactivateItems: 'Inactivate {{items}}',
    section: 'Section',
    selectItemsToDelete: 'Select {{items}} to delete {{items}}',
    selectItemsToInactivate: 'Select {{items}} to inactivate {{items}}',
    selectKeywordsToCopy: 'Select Keywords to copy',
    selectKeywordsToDelete: 'Select keywords to delete',
    selectKeywordsToSetTrackingFrequency: 'Select Keywords to set Tracking Frequency',
    selectProductsToCopy: 'Select products to copy ASINs',
    selectProductsToSetTrackingFrequency: 'Select products to set Tracking Frequency',
    selectToCopy: 'Select {{items}} to copy',
    selectToSetSection: 'Select products to change sections',
    selectToSetStatus: 'Select products to activate or archive',
    selectToTagOrGroup: 'Select {{item}} to tag or group',
    setSection: 'Change products section',
    setStatus: 'Activate or archive products',
    setTrackingFrequency: 'Set Tracking Frequency',
    status: 'Status',
    tagOrGroup: 'Tag or group {{items}}',
    tagsAndGroups: 'Tags & Groups	',
    trackingFrequency: 'Tracking Frequency',
  },
}
