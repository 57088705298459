export default {
  BISellerDashboard: {
    step1: {
      title: 'Welcome to Seelk Studio! ',
      text1:
        'Our Business Intelligence Dashboard provides Amazon Sellers with their main sales KPIs in a simple dashboard. You can group your ASINs by country, category, brand and even your own custom attributes.',
      text2: 'Click next to get a walkthrough of the main functionality.',
    },
    step2: {
      title: 'Date picker',
      text1:
        'All of the data in the interface is either aggregated or visualised according to the beginning and end period you set with the date picker.',
    },
    step3: {
      title: 'Metrics Panels',
      text1: 'There are four main metrics panels:',
      bullets: [
        {id: 1, content: 'Sales'},
        {id: 2, content: 'Shipping Costs'},
        {id: 3, content: 'Total Sales'},
        {id: 4, content: 'Units'},
      ],
      text2: 'They give you aggregated data according to the time period set in the filter bar.',
      text3: 'The other metrics are orders, average units per order and average basket.',
    },
    step4: {
      title: 'Graph',
      text1: 'The provide a visual breakdown on a timeline for the following metrics:',
      bullets: [
        {id: 1, content: 'Shipping Costs'},
        {id: 2, content: 'Sales'},
        {id: 3, content: 'Units'},
      ],
      text2: 'The graph is controlled by the both the date picker and the "Split By" dropdown.',
    },
    step5: {
      title: 'Data Table',
      text1: 'The data table has the following internal controls:',
      bullets: [
        {id: 1, content: 'Metric'},
        {id: 2, content: 'Countries'},
      ],
      text2: 'What you see in th table is also controlled by "Split by" and the date picker in the filter bar.',
      text3:
        'For example, if you split by "Weeks" you will see the columns "Weekly AVG." and "Last Week" appear in the table. If you select "Months" it will be "Monthly AVG.", etc.',
      text4: 'The "Total" column provides aggregated data according to the period set in the date picker.',
      text5:
        'You can click on "Group by", select any grouping (custom or otherwise) as well being able to view by ASIN.',
    },
    step6: {
      title: 'Split by',
      text1: 'You have three selections:',
      bullets: [
        {id: 1, content: 'Days'},
        {id: 2, content: 'Weeks'},
        {id: 3, content: 'Months'},
      ],
      text2: 'Which ever period type you select will update the metrics panels, table and graph.',
    },
    step7: {
      title: 'Thanks for taking a quick tour of the Business Intelligence Seller app!',
    },
  },
  BIVendor: {
    step1: {
      title: 'Welcome to Seelk Studio! ',
      text1:
        'Our Business Intelligence app provides Amazon Vendors with their main sales KPIs in a simple dashboard. You can group your ASINs by country, category, brand and even your own custom attributes.',
      text2: 'Click next to get a walkthrough of the main functionality.',
    },
    step2: {
      title: 'Date picker',
      text: 'All of the data in the interface is either aggregated or visualised according to the beginning and end period you set with the date picker.',
    },
    step3: {
      title: 'Metrics Panels',
      text1: 'There are four main metrics panels:',
      bullets: [
        {id: 1, content: 'Sell-In (Units)'},
        {id: 2, content: 'Sell-In (Sales)'},
        {id: 3, content: 'Sell-Out (Units)'},
        {id: 4, content: 'PCGOS (Sell-out sales)'},
      ],
      text2: 'They give you aggregated data according to the time period set in the filter bar.',
    },
    step4: {
      title: 'Graph',
      text1: 'The provide a visual breakdown on a timeline for the following metrics:',
      bullets: [
        {id: 1, content: 'Sell (Sales)'},
        {id: 2, content: 'PCOGs'},
        {id: 3, content: 'Sell-In (Units)'},
        {id: 4, content: 'Sell-Out (Units)'},
      ],
      text2: 'The graph is controlled by the both the date picker and the "Split By" dropdown.',
    },
    step5: {
      title: 'Data Table',
      text1: 'The data table has the following internal controls:',
      bullets: [
        {id: 1, content: 'Metric'},
        {id: 2, content: 'Countries'},
      ],
      text2: 'What you see in th table is also controlled by "Split by" and the date picker in the filter bar.',
      text3:
        'For example, if you split by "Weeks" you will see the columns "Weekly AVG." and "Last Week" appear in the table. If you select "Months" it will be "Monthly AVG.", etc.',
      text4: 'The "Total" column provides aggregated data according to the period set in the date picker.',
      text5:
        'You can click on "Group by", select any grouping (custom or otherwise) as well being able to view by ASIN.',
    },
    step6: {
      title: 'Split by',
      text1: 'You have three selections:',
      bullets: [
        {id: 1, content: 'Days'},
        {id: 2, content: 'Weeks'},
        {id: 3, content: 'Months'},
      ],
      text2: 'Which ever period type you select will update the metrics panels, table and graph.',
    },
    step7: {
      title: 'Thanks for taking a quick tour of the Business Intelligence Vendor app!',
    },
  },
  BIVendorAnalysis: {
    step1: {
      title: 'Welcome to Business Intelligence Analysis',
      text1:
        'Seelk Studio Analysis BI allows you to easily compare two different time periods in order to analyse the contribution to growth (CTG) and difference (%) that a particular group or ASIN has had on your sales as a whole.',
      text2: 'Click next to learn more about the basic functionality of Analysis.',
    },
    step2: {
      title: 'Date Picker',
      text: 'The date picker allows you to set two date ranges by using the "vs" mode. The dates you choose will update the metrics panels, graphs and data table.',
    },
    step3: {
      title: 'Group By',
      text1:
        'Use this selector to choose by which groups (or at ASIN-level) you wish to see the data. Selecting a different grouping or ASIN will update the graphs and the data table accordingly.',
      text2:
        'You can then select two date ranges to compare you will see which subsets of your groups are contributing most or least to your overall growth.',
    },
  },
  BIV2: {
    step1: {
      title: "Welcome to Seelk Studio's Business Intelligence Dashboard",
      text: "Seelk Studio's BI Dashboard empowers users with actionable insights on the Amazon sales.  By calculating the contribution of growth for marketplaces, custom groups and individual products users can better understand their sales velocity by comparing two periods or analysing year over year.",
    },
    step2: {
      title: 'Selecting your metric and grouping',
      text: "Use the 'Metric' dropdown to select which metric you want to analyse in the panels, graph and data table. The 'Group by' dropdown allows you to select which dimension you wish to analyse. Seelk Studio provides four groups out of the box: Countries, Amazon Brands, Amazon Categories and ASINs.",
    },
    step3: {
      title: 'Year over Year (YOY)',
      text: "By default Year over Year is switched off. Click the toggle to turn it on and you will see that all elements in the interface now contain data from the previous year according to the date range you've set in the date picker",
    },
    step4: {
      title: 'Date picker',
      text: 'The date picker compares two periods: "A" and "B". "A" by default when you arrive on the BI Dashboard is set to Amazon\'s Year to Date (i.e. starting on the Sunday of the first week of the year). You can select date ranges by either day, week or month and easily compare two periods by either clicking on "B" or clicking the "vs" toggle located in the header bar.',
    },
    step5: {
      title: 'Metrics Panels',
      text: "Metrics panels are the aggregated data of the chosen date range for the metric you have selected. By default, Period A shows data from Amazon's Year to Date. If you activate Year over Year, you'll see comparative data inside the same metric panel such as % of change compared to the previous period and the previous periods aggregated data. If you compare Period \"A\" with a Period \"B\" a Period B panel will appear, thus replaceing the 'Year to Date', 'Last 90 Days' and 'Last 30 days' panels.",
    },
    step6: {
      title: 'Metric Graph',
      text: 'The Metric Graph plots the selected metric over the current date range. Period "A" is highlighted in light blue by default. Comparing two periods (i.e. Period A vs. Period B) or switching to Year over Year will also affect what is displayed on the graph.',
    },
    step7: {
      title: 'Visualization toggles',
      text1: 'There are four  visualizations toggles:',
      bullets: [
        {
          id: 1,
          content:
            'Show groups - if your selected grouping has under 7 values you can see them all plotted on the graph as either lines or bars',
        },
        {id: 2, icon: 'chart line', content: ' Toggle between line chart or bar chart'},
        {id: 3, icon: 'eye slash', content: ' Toggle to show metric data labels on the graph '},
        {id: 4, icon: 'compress', content: ' Maximum or minimize between full frame and normal graph views'},
      ],
      text2: 'You can also switch the granularity of the of the x-axis from Days (only in Seller), Weeks or Months. ',
    },
    step8: {
      title: 'Data table',
      text1:
        "The basics of the data table are that the columns are based on the metric you have selected in the 'Metric' dropdown and the rows are the values of whichever group you choose in the 'Group by' dropdown.",
      text2:
        "When you switch to 'Year over Year' mode or compare two date ranges, the table will update accordingly with the difference in percentage between the two periods and the contribution to growth (CTG %). You can sort in ascending or descending orders (numerically or alphabetically) by clicking on the header of whichever column you wish to sort.",
    },
  },
  BIV2Introduction: {
    step1: {
      title: "Welcome to Seelk Studio's new Business Intelligence Dashboard",
      text1:
        'We developed the new Business Intelligence (BI) Dashboard with the aim of providing our customers with real actionable insights to empower decision-making that truly impacts their Amazon business. ',
      text2: 'Follow the next steps to learn more or book a webinar to ',
      text3: ' with our customer experience team. ',
      link: 'organise a live training',
      href: 'https://help.seelk.io/en',
    },
    step2: {
      title: "What is 'contribution to growth' (CTG) ?",
      text: "The strategy teams at Seelk systematically track our client's CTG in order to understand which marketplaces, groups of products and/or individual products on Amazon are most contributing or substracting from your overall sales growth. The new BI Dashboard allows you to easily compare two periods (as well as the previous year) and thus clearly understand which products have performed the best or worst between those two date ranges. ",
      link: 'Click here to read our more about CTG.',
      href: 'https://help.seelk.io/en/articles/3118904-a-guide-to-the-business-intelligence-dashboard',
    },
    step3: {
      title: "How does 'Year over Year' (YOY) work?",
      text: "We've incorporated into the new BI Dashboard a 'Year over Year' mode which allows users to compare any metric from a date range of current year against the exact same period of the previous year in just a few clicks. This provides a clear visual and data comparison of your products' CTG on an annual basis. You can now easily compare seasonality, stock management and overall performance. ",
      link: 'Click here to read our more about YOY.',
      href: 'https://help.seelk.io/en/articles/3118904-a-guide-to-the-business-intelligence-dashboard',
    },
    step4: {
      title: 'Need help or are you ready to get started?',
      text: "Are you ready to jump right into the Seelk Studio's new Business Intelligence Dashboard? We've added a bunch of neat visualisations and export features to provide you with all the tools you need to empower your business analysis. If you still want to learn more, click below to request a 'live training' with one of our staff. ",
      link1: 'Request a live training',
      popup1: 'Click & Opens the intercom chat support',
      href2: 'https://help.seelk.io/en',
    },
  },
  catalog: {
    step1: {
      title: "Welcome to Seelk Studio's Catalog app",
      text1:
        'The Catalog provides you with ultimate store check of your Amazon products. It contains three main scoring segments:',
      bullets: [
        {id: 1, content: 'Content'},
        {id: 2, content: 'Offers'},
        {id: 3, content: 'Reviews'},
      ],
      text2:
        'Each segment\'s score is based on specific recommendations provided by Amazon. Using Seelk Studio\'s "grouping" system you can quickly analyse which areas of your catalog need the most improvement',
    },
    step2: {
      title: 'Group By',
      text1:
        'You can select which groups you wish to analyse. By default you will be provided with Countries, Amazon Brands and Amazon Categories.',
      text2: 'For whichever grouping you select, you will see the its sub values.',
    },
    step3: {
      title: 'Content',
      text1:
        "The 'Content score' is quantitative calculation of your Amazon product offer pages based on weighted ratio of the following page elements:",
      bullets: [
        {id: 1, content: '🖼  Images (the page should have at least 5 images) [40% of score]'},
        {id: 2, content: '✒️  Title length (the page title should be ~70 characters) [20% of score]'},
        {id: 3, content: '📌  Bullet Points (the page should have at least 5 bullet points) [20% of score]'},
        {id: 4, content: '📄  Description (the page description should be ~1500 characters) [20% of score]'},
        {id: 5, content: "✅  A+ content (any A+ content is considered a bonus to the 'Content score')"},
      ],
    },
    step4: {
      title: 'Offers',
      text1: 'The \'Offer Health Score\' evaluates how "buyable" the product is. ',
      text2: '❌ If the Buy Box is not active, score is 0.',
      text3:
        '✅ If the Buy Box is active, base score is 100 and is impacted by the combination of positive and negative elements as follows.',
      text4: 'Positive Elements bringing a bonus to the Offer Score:',
      bullets1: [
        {id: 1, content: "Subscribe & Save' is activated (medium positive impact)"},
        {id: 2, content: "The offer is labeled as 'Prime' (medium positive impact)"},
      ],
      text5: 'Elements impacting the Offer Score negatively:',
      bullets2: [
        {id: 1, content: 'Product is Out of Stock (strong impact)'},
        {id: 2, content: 'Product is Add-On Item (medium negative impact)'},
        {id: 3, content: 'Product is Prime Only (medium negative impact)'},
      ],
    },
    step5: {
      title: 'Reviews',
      text1: "The 'Review Score' is measured based on ratio between the following parameters:",
      bullets: [
        {id: 1, content: 'The number of reviews on the Product Page (your product page should at least20 reviews)'},
        {id: 2, content: 'Average review score (the average review score of the product offer as seen on Amazon)'},
      ],
    },
    step6: {
      title: 'Average or Detailed',
      text1:
        'You can switch between average or detailed views in order to either see the global score (Average) or see the distribution of your products scores according to the following scoring segments:',
      bullets: [
        {id: 1, content: '0-20%'},
        {id: 2, content: '20-40%'},
        {id: 3, content: '40-60%'},
        {id: 4, content: '60-80%'},
        {id: 5, content: '80-100%'},
      ],
    },
    step7: {
      title: 'Thanks for taking the quick tour of the Catalog app - your ultimate Amazon store check!',
    },
  },
  catalogReviews: {
    step1: {
      title: 'Welcome to Reviews!',
      text1:
        "Seelk Studio's 'Reviews' feature allows you track your new product reviews and gain insight into not only the amount but whether or not they have positively or negatively impacted your overall rating. ",
      text2: 'Take a quick tour to learn more!',
    },
    step2: {
      title: 'See by Groups or by ASIN',
      text: "Select the Grouping dropdown to see your new reviews and rating at by ASIN, Country, Amazon Category, Amazon Brand or according to the custom attributes you've setup on your account.",
    },
    step3: {
      title: 'Comparison Period',
      text: 'You can select the comparison period to see new reviews since:',
      bullets: [
        {id: 1, content: 'Yesterday'},
        {id: 2, content: '7 days'},
        {id: 3, content: '30 days'},
      ],
    },
    step4: {
      title: 'Enjoy tracking your new reviews!',
      href: 'https://seelk-studio.typeform.com/to/nKFmSO',
      link: 'feedback or request a feature',
      text1:
        "Tracking reviews can be painful - we hope our 'Reviews' feature can give you peace of mind. Do not hestitate to give us any ",
      text2: ". We're always aiming to better understand your needs and help you win the battle on Amazon.",
    },
  },
  index: {
    agency: 'Agency',
    chooseSellerType: 'Choose your seller type:',
    descriptions: {
      headLine: "We'll help you grow your sales on Amazon in no time :)",
      subLine:
        'To get the most out of this software, your need to setup your account first. This will only take a couple of minutes.',
    },
    mainTitle: 'Welcome to Seelk Studio!',
    other: 'Other',
    prospect: 'Prospect',
    saas: 'SaaS',
    sellers: {
      seller: {
        authToken: 'Auth Token',
        availableRegions: 'Available regions: ',
        button: 'continue as SELLER',
        defaultRegion: 'Select your default region',
        firstLine: "I'm selling on Amazon as",
        formTitle: 'Seller Onboarding',
        loginToCredientals: 'Login to your Seller account and find your credentials',
        secondLine: 'SELLER',
        sellerId: 'Seller Id',
        step: {
          credentialsSetUp: '{{accountType}} credentials',
          selectYourZone: 'Select your zone',
          startedProcessingPipeline: 'Catalogue Inspection started',
        },
        zone: 'Zone',
      },
      vendor: {
        firstLine: "I'm selling on Amazon as",
        secondLine: 'VENDOR',
        button: 'continue as VENDOR',
      },
    },
  },
  logistics: {
    step1: {
      title: 'Welcome to Logistics!',
      text1:
        'Logistics is the one-stop-shop for all your Amazon Vendor Operations. We’ve centralised your Purchase Order data into a single dashboard for you to be able to analyse when and where you’ve had a drop in your acceptance rate or refused PO.',
      text2: 'Click Next to continue using the walkthrough, otherwise to learn more, read our Guide to Logistics ',
      link: 'Guide to Logistics',
      href: 'https://help.seelk.io/en/',
    },
    step2: {
      title: 'Purchase Orders',
      text: 'The Logistics app is based on your Amazon Purchase Orders. You can control which types of purchase orders you see in the dashboard by selected either closed or open, or both.',
    },
    step3: {
      title: 'Date Picker',
      text: 'Use the Date Picker to select the time range you wish to analyse. You can switch to vs mode in order to compare with another time range.',
    },
    step4: {
      title: 'Logistics scorecards',
      text1:
        'You can use the scorecards to get a quick overall glance at the state of operations based on Units, Sell-In Sales, Acceptance Rate and Fill-In Rate.',
      text2:
        'The Acceptance Rate and Fill-In Rate scorecards show how many ASINs are performing between 0-20, 20-40, 40-60 or 80-100 (%). ',
    },
    step5: {
      title: 'Logistics timeline',
      text: 'Using the Logistics timeline graph you can switch between Sell-In (Units) and Sell-In (Sales) to analyse your Logistics KPIs. Selecting the Sell-In (Sales) tab allows you to see your Refused PO Value and compare that with your Acceptance Rate.',
    },
    step6: {
      title: 'Logistics Data Table',
      text: 'The data table give you a sortable spreadsheet view of all your logistics data aggregate according to the selected group. Use the Group by dropdown you can select how your level of analysis based on Amazon Section, Custom Attributes or ASINs.',
    },
  },
  processing: {
    clickToSeeWorkStation: 'Click the link below if you wish to see a preview:',
    description:
      'We are currently linking your Amazon {{accountType}} account with Seelk Studio, analyzing your data and building your dashboard.',
    emailAnnouncement:
      "Once we'll have analyzed your account, you'll receive an emil with a link to your account's Workspace inside Seelk Studio.",
    header: 'You are successfuly connected!',
    tasks: {
      createProducts: 'Create products',
      createOffers: 'Create offers',
      createCatalogue: 'Create catalogue',
      importOrders: 'Import Orders',
      importReports: 'Import Reports',
      buildInventory: 'Build Inventory',
    },
  },
  seller: {
    connectToCentral: {
      connectSteps: {
        agreeToTerms:
          "Agree to Amazon's terms and conditions and allow Sellics to connect with your Seller Central account",
        clickButton: 'Click on the button below to open Amazon Seller Central',
        loginToAccount: 'Login to your Seller Central account',
      },
      havingTroubleConnecting: 'Having trouble connecting? Our Customer Support is happy to help!',
      openCentralButton: 'Open Amazon Seller Central',
      perks: {
        importProducts: 'Automatically import your products into Sellics',
        monitorSales: 'Monitor your sales and profit in real time',
        optimizeCampaigns: 'Analyze and optimise your PPC campaigns',
      },
      sellerCentralConnect: 'Connect to Seller Central',
      weWontShareData: "We won't share any of your data and you can revoke access rights at any time.",
    },
    loaded: {
      allYourReferencesWillBeHere: 'All your references will be here by tomorrow anyway.',
      firstLine: "We've already fetched a fair proportion of your catalog.",
      letsGoButton: "Let's go!",
      ready: 'Ready?',
      secondLine: "We're still fetching the rest, but we can already start to work together on those products ;-).",
      title: 'Done!',
    },
    loading: {
      firstLine: "An email will be spent to you right now. We're fetching and processing your products.",
      secondLine:
        "Depending on your catalog size, this step might take more or less time. You'll receive an email once it is finished.",
      title: 'Awesome!',
    },
  },
  tooltips: {
    back: 'back',
    close: 'close',
    next: 'next',
    menu: {
      competition: {
        text: 'We scan for relevant competitors automatically. You can spy on their performances, and compare with your products.',
        title: 'Competition',
      },
    },
  },
  vendor: {
    havingTroublesText: 'Having troubles setting up? Our Customer Support is happy to help.',
    mainDescription: "So you're a vendor on Amazon. Download the templates below to add your products.",
  },
  welcome: {
    introduction: "Hi {{name}} – you've been invited to join {{client}} on Seelk Studio.",
    subIntroduction: 'Please confirm your account below by creating your own password.',
    subtitle: 'Amazon analytics at your fingertips',
    title: 'Welcome to Seelk Studio',
  },
  zones: {
    eu: 'EU',
    us: 'US',
  },
}
