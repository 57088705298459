import React from 'react'

// import deepEqual from 'deep-equal'
import fuzzysort from 'fuzzysort'
import {Col, Row} from 'jsxstyle'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {connect} from 'react-redux-reliever'
import {timer} from 'rxjs'
import {debounce, map, skip, tap} from 'rxjs/operators'
import {Input} from 'semantic-ui-react'

import {validNumber} from '../../utils'
import colors from '../../utils/colors'
import {SeelkRadio, SeelkTabs} from '../../utils/common-ui'
import {SCRAPING_PRICES, SECONDS_IN_YEAR} from '../../utils/constants'
import {variable} from '../../utils/extensions/rx'
import i18n from '../../utils/i18n'
import LoaderWrapper from '../../utils/requesting/containers/LoaderWrapper'
import {requestWrapperBuilder, withRequests} from '../../utils/requesting/RequestWrapper'
import theme from '../../utils/theme'
import {crossFilterClientTrackingInformationsSelector, editTableSelector, filtersSelector} from '../selectors'
import ClientTrackerGrid from './ClientTrackerGrid'
import Filters from './Filters'

class ClientTrackerTable extends React.Component {
  static propTypes = {
    clientTrackingSerie: PropTypes.object,
    fetchFrequenciesInformations: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
  }

  static defaultProps = {
    clientTrackingSerie: null,
  }

  constructor() {
    super()

    this.state = {
      data: [],
      search: '',
      selectedPane: '',
      editable: false,
      editedEstimatedTotalCosts: '',
    }

    this.searchTerm$ = variable('')
  }

  UNSAFE_componentWillMount() {
    this.props.fetchFrequenciesInformations.fetch({}, {}, () => this.generateClientsData('all'))
    this.setState({
      selectedPane: 'all',
    })
  }

  columnsProvider = i18n.createProvider('visualization.columns')

  componentDidMount() {
    this.searchTerm$
      .asObservable()
      .pipe(
        skip(1),
        debounce(() => timer(100)),
        map(search => ({search})),
        tap(payload => this.setState(payload))
      )
      .subscribe()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.clientTrackingSerie !== nextProps.clientTrackingSerie || this.props.filters !== nextProps.filters)
      this.generateClientsData(this.state.selectedPane)
  }

  generateClientsData(selectedPane) {
    const data = this.props.clientTrackingSerie.getOrderedData('client')

    if (!data.length || !Object.keys(data).length) this.setState({data: []})
    else {
      const getSumForType = (clientData, keys, type) => {
        return keys.reduce((parent, key) => {
          const frequency = parseFloat(key.split(type)[1])

          if (frequency === 0) return parent
          // eslint-disable-next-line no-param-reassign
          else if (frequency === 86400000000) parent += clientData[key]
          else {
            const multiplier = validNumber(SECONDS_IN_YEAR / frequency)
            parent += multiplier * clientData[key] // eslint-disable-line no-param-reassign
          }

          return parent
        }, 0)
      }

      const formattedData = data.map(clientData => {
        const categoriesKeys = Object.keys(clientData).filter(
          item => item.startsWith('categories') && !item.endsWith('Number') && !item.endsWith('MaxValue')
        )
        const keywordsKeys = Object.keys(clientData).filter(
          item => item.startsWith('keywords') && !item.endsWith('Number') && !item.endsWith('MaxValue')
        )
        const productsKeys = Object.keys(clientData).filter(
          item => item.startsWith('products') && !item.endsWith('Number') && !item.endsWith('MaxValue')
        )

        const getKeys = value => {
          if (value === 'categories') return categoriesKeys
          if (value === 'keywords') return keywordsKeys
          if (value === 'products') return productsKeys
          return null
        }

        const estimatedPrice =
          selectedPane === 'all'
            ? getSumForType(clientData, categoriesKeys, 'categories') * SCRAPING_PRICES.categories +
              getSumForType(clientData, keywordsKeys, 'keywords') * SCRAPING_PRICES.keywords +
              getSumForType(clientData, productsKeys, 'products') * SCRAPING_PRICES.products
            : getSumForType(clientData, getKeys(selectedPane), selectedPane) * SCRAPING_PRICES[selectedPane]

        let categoriesCustom = null
        let keywordsCustom = null
        let productsCustom = null

        categoriesKeys.forEach(key => {
          const frequency = parseFloat(key.split('categories')[1])

          if (![0, 3600, 86400, 604800, 2592000, 86400000000].includes(frequency))
            categoriesCustom = categoriesCustom === null ? clientData[key] : categoriesCustom + clientData[key]
        })

        keywordsKeys.forEach(key => {
          const frequency = parseFloat(key.split('keywords')[1])

          if (![0, 3600, 86400, 604800, 2592000, 86400000000].includes(frequency))
            keywordsCustom = keywordsCustom === null ? clientData[key] : keywordsCustom + clientData[key]
        })

        productsKeys.forEach(key => {
          const frequency = parseFloat(key.split('products')[1])

          if (![0, 3600, 86400, 604800, 2592000, 86400000000].includes(frequency))
            productsCustom = productsCustom === null ? clientData[key] : productsCustom + clientData[key]
        })

        return {
          ...clientData,
          categoriesCustom,
          keywordsCustom,
          productsCustom,
          accountTypes: clientData.accountTypes.map(type => _.upperFirst(type)).join(', '),
          estimatedPrice: {
            value: estimatedPrice,
            display: i18n.toCurrency(estimatedPrice, {code: 'EUR'}),
          },
        }
      })

      this.setState({data: formattedData})
    }
  }

  handleChange = pane => {
    this.setState({
      selectedPane: pane,
    })
    this.generateClientsData(pane)
  }

  onClick = () => this.setState({editable: !this.state.editable})

  setEstimatedTotalCosts = results => {
    this.setState({
      editedEstimatedTotalCosts: i18n.toCurrency(
        _.sumBy(results, result => result.estimatedPrice.value),
        {
          code: 'EUR',
        }
      ),
    })
  }

  render() {
    const results = this.state.search
      ? fuzzysort.go(this.state.search, this.state.data, {
          keys: ['client'],
          threshold: 0,
        })
      : this.state.data.sort((lhs, rhs) => lhs.client.localeCompare(rhs.client))

    const flattenedResults = this.state.search ? results.map(result => result.obj) : results

    const estimatedTotalCosts = i18n.toCurrency(
      _.sumBy(flattenedResults, result => result.estimatedPrice.value),
      {
        code: 'EUR',
      }
    )

    const panes = [
      {
        menuItem: {
          key: 'all',
          content: i18n.t('clientTracking.table.all'),
        },
      },
      {
        menuItem: {
          key: 'products',
          content: i18n.t('clientTracking.table.products'),
        },
      },
      {
        menuItem: {
          key: 'keywords',
          content: i18n.t('clientTracking.table.keywords'),
        },
      },
      {
        menuItem: {
          key: 'categories',
          content: i18n.t('clientTracking.table.categories'),
        },
      },
    ]

    return (
      <LoaderWrapper requesting="client-tracker.getFrequenciesInformations" blur container>
        <Filters />
        <Col flex={1} backgroundColor={colors.white.rgba} margin={12} padding={12} className={theme.bordered}>
          <Row justifyContent="space-between" alignItems="center" height={50}>
            <p style={{fontWeight: 500, fontSize: 18}}>{i18n.t('clientTracking.index.trackingActivity')}</p>
            <Row alignItems="center">
              <p style={{fontWeight: 400, fontSize: 20, margin: 0, marginRight: 12}}>
                {i18n.t('clientTracking.index.estimatedTotalCosts')}
              </p>
              <p style={{fontWeight: 500, fontSize: 24, color: colors.primary.rgba}}>
                {this.state.editable ? this.state.editedEstimatedTotalCosts : estimatedTotalCosts}
              </p>
            </Row>
            <Input
              icon="search"
              iconPosition="left"
              placeholder={i18n.t('actions.search.search')}
              onChange={(x, event) => {
                this.searchTerm$.value = event.value
              }}
            />
          </Row>
          <Col
            marginTop={12}
            maxHeight="calc(100vh - 50px - 72px - 3.929em)"
            height={this.state.data.length * 50 + 120}
          >
            <Row style={{alignItems: 'baseline'}}>
              <SeelkTabs style={{marginRight: 20}} onChange={this.handleChange} type="outer" panes={panes} />
              <SeelkRadio
                toggle
                onChange={this.onClick}
                option={{
                  value: 'editable',
                  label: i18n.t('misc.subModules.expanseCalculator'),
                }}
                value={this.state.editable ? 'editable' : null}
                style={{marginBottom: '15px'}}
              />
            </Row>
            <ClientTrackerGrid
              editable={this.state.editable}
              flattenedResults={this.state.data}
              selectedPane={this.state.selectedPane}
              setEstimatedTotalCosts={this.setEstimatedTotalCosts}
            />
          </Col>
        </Col>
      </LoaderWrapper>
    )
  }
}

export default withRequests({
  fetchFrequenciesInformations: requestWrapperBuilder({
    fetchAction: 'CLIENT-TRACKER_GET_FREQUENCIES_INFORMATIONS',
    requestingPath: 'client-tracker.getFrequenciesInformations',
    resourcePath: 'client-tracker.offers',
  }),
})(
  connect({
    props: (state, ownProps) => ({
      clientTrackingSerie: crossFilterClientTrackingInformationsSelector(state),
      filters: filtersSelector(state),
      editTable: editTableSelector(state),
      ...ownProps,
    }),
  })(ClientTrackerTable)
)
