import {routerMiddleware} from 'connected-react-router'
import {createBrowserHistory} from 'history'
import {batch} from 'react-redux'

import {debounce} from '../modules/utils'
import ReduxRelieverRegistry from './registry'

/**
 * Groups actions to be dispatched in a batch resulting in less useless renders.
 * Actions with {_ASAP: true} are dispatched normally.
 * Note that you must add _ASAP to actions that should have instant user feedback.
 */
// eslint-disable-next-line no-unused-vars
const batchRendererMiddleware = store => next => {
  const queuedActions = []
  const batchDispatch = debounce(() => {
    if (!queuedActions.length) return
    batch(() => {
      while (queuedActions.length) {
        next(queuedActions.shift())
      }
    })
  }, 200)
  return action => {
    if (action._ASAP || (action.type && action.type.startsWith('@@router'))) {
      batch(() => {
        next(action)
        while (queuedActions.length) {
          next(queuedActions.shift())
        }
      })
    } else {
      queuedActions.push(action)
      batchDispatch()
    }
    return action
  }
}

const history = createBrowserHistory()
const middlewares = ReduxRelieverRegistry.middlewares()
middlewares.push(routerMiddleware(history))
middlewares.unshift(batchRendererMiddleware)

export default middlewares
export {history}
