import React from 'react'

import {Dropdown, DropdownProps} from 'semantic-ui-react'
import styled from 'styled-components'

import SeelkDropdownButton from './SeelkUIKit/SeelkDropdown/TriggerButton/SeelkDropdownButton'

const StyledDropdown = styled(Dropdown)`
  &&&&& {
    min-width: 0;
    & > div:nth-child(2) {
      margin-top: 6px !important;
    }
  }
`

const StyledHeader = styled.span`
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
`

type OptionType = {
  text?: string | React.ReactNode
  key: string
  header?: boolean
  value: string
}

interface DropdownSelectorProps {
  onChange: (value: any) => void
  options: OptionType[]
  disabled?: boolean
  icon?: string | null
  loading?: boolean
  onlyIcon?: boolean
  placeholder?: string | null
  pointing?: boolean | string
  style?: Record<string, string | number>
  title?: string
  value?: string | null
  whiteTheme?: boolean
}

function DropdownSelector({
  disabled = false,
  icon = null,
  loading = false,
  onChange,
  onlyIcon = false,
  options,
  placeholder = null,
  pointing = true,
  title = '',
  value = null,
  whiteTheme = false,
  ...extraProps
}: DropdownSelectorProps) {
  const optionSelected = options.find(option => option.value === value)?.text
  const trigger = (
    <SeelkDropdownButton
      header={title && `${title}:`}
      iconLeft={icon}
      iconRight="fas fa-caret-down"
      placeholder={placeholder}
      onlyIcon={onlyIcon}
      value={optionSelected}
      disabled={disabled}
      {...extraProps}
    />
  )

  const arrangedOptions = options.map(({header, text, ...option}) => {
    if (header) return {...option, children: <StyledHeader>{text}</StyledHeader>}
    return {...option, text}
  })

  return (
    <StyledDropdown
      loading={loading}
      onChange={(e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) =>
        value !== data.value && onChange(data.value as string)
      }
      options={arrangedOptions}
      pointing={pointing}
      scrolling
      trigger={trigger}
      // upward={false}
      value={value}
      icon={null}
      disabled={disabled}
    />
  )
}

export default DropdownSelector
