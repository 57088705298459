import {defer} from 'rxjs'

import BulmaApiClient from './BulmaApiClient'

export default class BulmaApiClientTestOrNot {
  constructor() {
    return new BulmaApiClient()
  }
}

export const BulmaApi = (method, ...args) => defer(async () => new BulmaApiClientTestOrNot()[method](...args))
