import React, {useEffect, useState} from 'react'

import {Box, Col} from 'jsxstyle'
import {InputOnChangeData, Menu} from 'semantic-ui-react'
import styled from 'styled-components'

import {fuzzysearch} from '../../../..'
import colors from '../../../../colors'
import i18n from '../../../../i18n'
import SeelkElevatedPanel from '../../SeelkUIKit/SeelkElevatedPanel'
import SeelkInput from '../../SeelkUIKit/SeelkInput'
import FiltersMetricSelector from './FiltersMetricSelector'

const MENU_CATEGORY_PRODUCT = 'Product'
const MENU_CATEGORY_ADS = 'Ads'
const MENU_CATEGORY_DSP = 'DSP'
const MENU_CATEGORY_SEARCH_TERMS = 'Search Terms'
const MENU_CATEGORY_METRICS = 'Metrics'
const MENU_CATEGORIES = [
  MENU_CATEGORY_PRODUCT,
  MENU_CATEGORY_ADS,
  MENU_CATEGORY_DSP,
  MENU_CATEGORY_SEARCH_TERMS,
  MENU_CATEGORY_METRICS,
]

const StyledMenuCategoryTabs = styled(Menu)`
  &&&&& {
    margin-top: 0;
  }
`

const StyledMenuFilters = styled(Menu)`
  &&&&& {
    overflow-y: scroll;
    border: none;
    max-height: 300px;
    margintop: 0;
  }
`

const StyledMenuFiltersItem = styled(Menu.Item)`
  &&&&& {
    color: ${colors.dark.rgba};
    > i {
      color: ${props => (props.disabled ? '' : colors.darkLight.rgba)};
    }
  }
`

type FilterType = {
  category: string[]
  disabled: boolean
  title: string
  icon: string
  filter: any
}

type GroupedFiltersType = {
  [category: string]: FilterType[]
}

interface FiltersSelectByCategoryProps {
  availableCategories: string[] | null
  availableFilters: FilterType[]
  availableMetrics: any[] | null
  onClick: (item: any, metric?: any) => void
  open: boolean
}

const FiltersSelectByCategory = ({
  availableCategories,
  availableFilters,
  availableMetrics,
  onClick,
  open,
}: FiltersSelectByCategoryProps) => {
  const [filterCategory, setFilterCategory] = useState(MENU_CATEGORY_PRODUCT)
  const [filterSearch, setFilterSearch] = useState('')
  const [groupedFilters, setGroupedFilters] = useState<GroupedFiltersType | null>(null)

  useEffect(() => {
    if (!open) {
      const category =
        availableCategories?.length && MENU_CATEGORIES.includes(availableCategories[0])
          ? availableCategories[0]
          : MENU_CATEGORY_PRODUCT
      setFilterCategory(category)
      setFilterSearch('')
    }
  }, [open, availableCategories])

  useEffect(() => {
    if (availableCategories?.length && MENU_CATEGORIES.includes(availableCategories[0])) {
      setFilterCategory(availableCategories[0])
    }
  }, [availableCategories])

  useEffect(() => {
    setGroupedFilters({
      ...availableFilters.reduce((agg, categoryFilter) => {
        // eslint-disable-next-line no-unused-expressions
        categoryFilter.category?.forEach(category => {
          if (fuzzysearch(filterSearch, categoryFilter.title || ''))
            // eslint-disable-next-line no-param-reassign
            agg[category] = [...(agg[category] ?? []), categoryFilter]
        })
        return agg
      }, {} as GroupedFiltersType),
    })
  }, [availableFilters, filterSearch])

  const renderCategory = () => {
    if (filterCategory === MENU_CATEGORY_METRICS)
      return (
        <FiltersMetricSelector
          metricsList={availableMetrics?.filter(({name}) => fuzzysearch(filterSearch, name || '')) ?? []}
          updateFilterMetric={metric => onClick('metric', metric)}
        />
      )
    return groupedFilters ? (
      <StyledMenuFilters borderless fluid vertical className="bar-menu">
        {groupedFilters[filterCategory]?.map(categoryFilter => {
          const {disabled: disabledItem, title, icon, filter} = categoryFilter
          return (
            <StyledMenuFiltersItem
              key={filter}
              name={title}
              icon={icon}
              disabled={disabledItem}
              onClick={() => onClick(filter)}
            />
          )
        })}
      </StyledMenuFilters>
    ) : null
  }

  return (
    <SeelkElevatedPanel style={{width: '500px'}}>
      <Box>
        <Col>
          <Box padding="10px 10px 0 10px">
            <SeelkInput
              fluid
              icon="search"
              iconPosition="left"
              placeholder={i18n.t('actions.search.search')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>, {value}: InputOnChangeData) => setFilterSearch(value)}
              value={filterSearch}
              errorMessage={undefined}
              colorPicker={undefined}
            />
          </Box>
          <StyledMenuCategoryTabs pointing secondary className="submenu">
            {MENU_CATEGORIES.filter(
              category =>
                (availableCategories === null || availableCategories.includes(category)) &&
                (groupedFilters?.[category] || (category === MENU_CATEGORY_METRICS && availableMetrics?.length))
            ).map(category => (
              <Menu.Item
                key={category}
                className="submenuItem"
                value={category}
                onClick={(e, {value}) => setFilterCategory(value)}
                content={category}
                active={category === filterCategory}
              />
            ))}
          </StyledMenuCategoryTabs>
        </Col>
      </Box>
      <Box>{renderCategory()}</Box>
    </SeelkElevatedPanel>
  )
}

export default FiltersSelectByCategory
