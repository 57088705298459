import track from '../track'

export function trackLogout() {
  track('Logout')
}

function trackLogin() {}

export default function (tracker) {
  trackLogin(tracker)
}
