import {jsonFetch} from 'easyfetch'

import config from '../../../../config'
import KaioApiClient from '../KaioApiClient/KaioApiClient'

const ALL = 9999999999
const BOO = `https://${config.booDomain}`
const BOO_API = `https://${config.booDomain}/api`

export default class BooApiClient {
  constructor() {
    if (!BooApiClient.instance) {
      BooApiClient.instance = this
    }
    return BooApiClient.instance
  }

  async request(rType, url) {
    const req = rType(`${BOO_API}/${url}`)
    const token = await new KaioApiClient().getToken()
    req.setHeaders({Authorization: `Bearer ${token}`})
    return req
  }

  async checkLive() {
    try {
      await jsonFetch(`${BOO}/health/`).get()
      return true
    } catch (error) {
      if (error.code === 502) return false
      return true
    }
  }

  async getProductScraping(archived = false, section = 'main') {
    return (await this.request(jsonFetch, 'processed_snapshot/')).setQueryParams({archived, section}).get()
  }

  async getCatalogueReferenceContent() {
    return (await (await this.request(jsonFetch, 'reference_offer/')).setQueryParams({limit: ALL}).get()).results
  }

  async getReferenceChanges() {
    return (await this.request(jsonFetch, 'reference_offer_change/')).get()
  }

  async uploadMasterData(masterContent) {
    return (await this.request(jsonFetch, 'reference_offer/bulk_update/'))
      .setQueryParams({delete: true})
      .post(masterContent)
  }

  async updateKeywords(payload) {
    return (await this.request(jsonFetch, 'keyword_settings/bulk_update/')).post(payload)
  }

  async getKeywordSettings() {
    return (await (await this.request(jsonFetch, 'keyword_settings/')).setQueryParams({limit: ALL}).get()).results
  }

  async getCategorySettings() {
    return (await (await this.request(jsonFetch, 'category_settings/')).setQueryParams({limit: ALL}).get()).results
  }

  async scrapeOffers(offers) {
    return (await this.request(jsonFetch, 'offer/scrape/')).setQueryParams({create: true}).post(offers)
  }

  async updateKeywordFrequencies(keywords) {
    return (await this.request(jsonFetch, 'keyword_settings/bulk_update/')).post(keywords)
  }

  async updateCategoryFrequencies(categories) {
    return (await this.request(jsonFetch, 'category_settings/bulk_update/')).post(categories)
  }

  async scrapeCategories({categories, scrapeNow}) {
    return (await this.request(jsonFetch, 'category_settings/bulk_update/'))
      .setQueryParams({
        scrape_now: scrapeNow,
      })
      .post(categories)
  }

  async fetchReviewsChanges(delta) {
    const queryParams = {limit: ALL}
    if (delta) queryParams.delta = delta
    return (await this.request(jsonFetch, 'periodic_offer_change/get_reviews_changes/'))
      .setQueryParams(queryParams)
      .get()
  }

  async fetchCustomerReviews(data, maxDate) {
    if (maxDate)
      return (await this.request(jsonFetch, `reviews/generate_reviews_report/?max_date=${maxDate}&scrape=1`)).post(data)
    return (await this.request(jsonFetch, `reviews/generate_reviews_report/?scrape=1`)).post(data)
  }

  async startOfferScrapePipeline(offers) {
    return (await this.request(jsonFetch, 'offer/scrape/')).post(offers)
  }

  async launchCategoryBSScraping(categories) {
    return (await this.request(jsonFetch, 'category/scrape/')).post(categories)
  }

  async launchCategoryKWScraping({keywords, queryParams}) {
    return (await this.request(jsonFetch, 'keyword/scrape/')).setQueryParams(queryParams).post(keywords)
  }

  async fetchCategoriesTrackingInformations() {
    return (await this.request(jsonFetch, 'category_settings/frequencies_for_all_accounts/')).get()
  }

  async fetchKeywordsTrackingInformations() {
    return (await this.request(jsonFetch, 'keyword_settings/frequencies_for_all_accounts/')).get()
  }

  async fetchOffersTrackingInformations() {
    return (await this.request(jsonFetch, 'offer_settings/frequencies_for_all_accounts/')).get()
  }

  async getBrandWithCategoryId({marketplace, categoryId, search = 'best_sales', limit = 100}) {
    const body = {
      limit,
      search,
      filters: {
        category: categoryId,
      },
      marketplace,
    }
    return (await this.request(jsonFetch, 'keyword/get_brands/')).setQueryParams().post(body)
  }

  async populateBrands({marketplace, account, brands}) {
    return (await this.request(jsonFetch, 'keyword/populate_account_with_brands/')).post({
      account,
      marketplace,
      brands,
    })
  }
}
