import {defer} from 'rxjs'

import BooApiClient from './BooApiClient'

export default class BooApiClientTestOrNot {
  constructor() {
    return new BooApiClient()
  }
}

export const BooApi = (method, ...args) => defer(async () => new BooApiClientTestOrNot()[method](...args))
