import React from 'react'

import {Col} from 'jsxstyle'

import {FilterButton, OperatorInNotIn} from '../../../..'
import FilterBarItem from './FilterBarItem'

type RenderItemProps<T> = {
  choices: T[]
  data: T[]
  formatter: ((text: string) => string) | null
  item?: T
  index?: number
  onChange: (item: T) => void
  onClose: () => void
}

interface VerticalListProps<T> {
  choices: T[]
  data: T[]
  disabled?: boolean
  formatter?: ((text: string) => string) | null
  icon?: string | null
  onApply: () => void
  onChange: (filters: any) => void
  onClose: () => void
  onRemove: () => void
  operator?: 'in' | 'not_in'
  renderItem: (props: RenderItemProps<T>) => React.ReactNode
  selectAll?: React.ReactNode | null
  title?: string | null
  updateOperator?: ((operator: 'in' | 'not_in') => void) | null
}

const VerticalList = <T extends unknown>({
  data,
  formatter = null,
  onChange,
  onRemove,
  onClose,
  onApply,
  renderItem,
  title = null,
  choices,
  icon = null,
  disabled = false,
  operator = 'in',
  updateOperator = null,
  selectAll = null,
  ...barItemProps
}: VerticalListProps<T>) => {
  const trigger = (
    <FilterButton
      title={title}
      icon={icon}
      disabled={disabled}
      onRemove={onRemove}
      onApply={onApply}
      dropdown={undefined}
    >
      {renderItem({choices, data, formatter, onChange, onClose})}
    </FilterButton>
  )

  return (
    <FilterBarItem
      {...barItemProps}
      data={data}
      onChange={onChange}
      onClose={onClose}
      onApply={onApply}
      disabled={disabled}
      trigger={trigger}
    >
      <Col style={{minWidth: '200px', maxHeight: '50vh'}}>
        {updateOperator ? (
          <Col marginBottom={5}>
            <OperatorInNotIn updateOperator={updateOperator} operator={operator} />
          </Col>
        ) : null}
        {selectAll || null}
        <Col style={{overflowY: 'scroll'}}>
          {choices.map((item, index) => renderItem({choices, data, formatter, index, item, onChange, onClose}))}
        </Col>
      </Col>
    </FilterBarItem>
  )
}

export default VerticalList
