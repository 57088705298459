import React, {Component} from 'react'

import {Row} from 'jsxstyle'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import i18n from '../../../../../i18n'
import PermissionControl from '../../../../../PermissionControl'
import SeelkElevatedPanel from '../../SeelkElevatedPanel'

const StyledRow = styled(Row)`
  &&& {
    align-items: ${props => props.theme.menuPanel.rows.alignItems};
    background-color: ${props => props.theme.menuPanel.rows.backgroundColor};
    color: ${props => props.theme.menuPanel.rows.color};
    min-height: ${props => props.theme.menuPanel.rows.height};
    justify-content: ${props => props.theme.menuPanel.rows.justifyContent};
    padding-left: ${props => props.theme.menuPanel.rows.paddingLeft};
    padding-right: ${props => props.theme.menuPanel.rows.paddingRight};
    min-width: ${props => props.theme.menuPanel.rows.minWidth};

    /* This property is necessary to allow the row highlight not to break the border-radius of the background */
    border-radius: ${props => {
      if (props.position === 0) {
        return props.theme.menuPanel.rows.firstRowBorderRadius
      } else if (props.position === -1) {
        return props.theme.menuPanel.rows.lastRowBorderRadius
      } else {
        return props.theme.menuPanel.rows.borderRadius
      }
    }};

    i {
      color: ${props => props.theme.menuPanel.icons.color};
      margin-left: ${props => props.theme.menuPanel.icons.marginLeft};
      text-decoration: ${props => props.theme.menuPanel.icons.hoverTextDecoration};
    }
    :hover i {
      color: ${props => props.theme.menuPanel.icons.hoverColor};
    }
    :hover {
      background-color: ${props => props.theme.menuPanel.rows.hoverBackgroundColor};
      color: ${props => props.theme.menuPanel.rows.hoverColor};
      cursor: ${props => props.theme.menuPanel.rows.hoverCursor};
    }
  }
`

type OptionType = {
  key: string
  value: string
  text: string
  onClick: (value: string) => void
  permissions?: Record<string, string[]>
  icon?: string
}

interface SeelkMenuPanelProps {
  options: OptionType[]
  onSelect: () => void
}

export default class SeelkMenuPanel extends Component<SeelkMenuPanelProps> {
  renderOption = (option: OptionType, index: number) => {
    const {options, onSelect} = this.props
    return (
      <StyledRow
        key={option.key}
        props={{
          onClick: () => {
            option.onClick(option.value)
            onSelect()
          },
        }}
        position={(() => {
          if (options.length - 1 === index) return -1
          return index
        })()}
      >
        <span>{option.text}</span>
        {option.icon && <Icon className={option.icon} />}
      </StyledRow>
    )
  }

  render() {
    const {options} = this.props
    return (
      <SeelkElevatedPanel style={{marginTop: '0.714em', maxHeight: '300px'}}>
        {options.length ? (
          options.map((option, index) =>
            option.permissions ? (
              <PermissionControl key={option.key} {...option.permissions}>
                {this.renderOption(option, index)}
              </PermissionControl>
            ) : (
              this.renderOption(option, index)
            )
          )
        ) : (
          <StyledRow>{i18n.t('placeholders.empty.data')}</StyledRow>
        )}
      </SeelkElevatedPanel>
    )
  }
}
