export default {
  ageGraph: {
    feesToCome: 'Fees to come',
    ltsf6: 'LTSF 6 Months',
    ltsf12: 'LTSF 12 Months',
  },
  alerts: {
    restockSimulation: 'Restock simulation',
    worstSellers: 'Worst sellers',
  },
  inventory: {
    inventoryAge: 'Inventory Age',
    unsellables: 'Unsellables',
  },
  topGraph: {
    inventoryHistory: 'Inventory history',
  },
}
