import React from 'react'

import {Row} from 'jsxstyle'
import _ from 'lodash'
import {Checkbox, Flag, FlagNameValues} from 'semantic-ui-react'

import {countrySort} from '../../../../..'
import colors from '../../../../../colors'
import i18n from '../../../../../i18n'
import InNotIn from '../common/InNotIn'
import SelectAll from '../common/SelectAll'
import VerticalList from './VerticalList'

interface CountriesProps {
  data: string[]
  choices: string[]
  onChange: (item: string) => void
  onRemove: () => void
  bulkChange?: (choices: string[]) => void
  operator?: 'in' | 'not_in'
  updateOperator?: ((operator: 'in' | 'not_in') => void) | null
  disabled?: boolean
  onApply: () => void
  onClose: () => void
}

const Countries = ({data, choices, disabled = false, bulkChange, operator = 'in', ...props}: CountriesProps) => {
  return (
    <VerticalList<string>
      {...props}
      data={data}
      choices={choices?.sort(countrySort)}
      title={data.length ? '' : i18n.t('filters.countriesTitle')}
      selectAll={bulkChange ? <SelectAll choices={choices} data={data} bulkChange={bulkChange} /> : null}
      renderItem={({data, item, onChange}) => {
        if (!item) {
          return (
            <Row alignItems="center" marginLeft={8}>
              {data.length > 0 && props.updateOperator ? <InNotIn operator={operator} /> : null}
              {data.length > 0 &&
                data.map(country => <Flag key={country} name={country.toLowerCase() as FlagNameValues} />)}
            </Row>
          )
        }
        return (
          <Checkbox
            key={item}
            disabled={disabled}
            onClick={event => onChange(item)}
            checked={data && data.map(_.toLower).includes(_.toLower(item))}
            label={{
              children: (
                <p>
                  <Flag name={item.toLowerCase() as FlagNameValues} />
                  <span style={{color: colors.primary.rgba}}>{i18n.t(`countries.${item.toLowerCase()}.name`)}</span>
                </p>
              ),
              className: 'checkbox-element-label',
            }}
            className="checkbox-element"
          />
        )
      }}
    />
  )
}

export default Countries
