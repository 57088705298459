import {Checkbox} from 'semantic-ui-react'
import styled from 'styled-components'

const StyledCheckbox = styled(Checkbox)`
  &&& {
    width: 100%;
    background-color: ${props => props.theme.checkbox.backgroundColor};
    cursor: ${props => props.theme.checkbox.cursor};
    :hover,
    :hover > label {
      color: ${props => props.theme.checkbox.hoverColor};
    }
    label::before,
    :hover label::before,
    input:focus + label::before {
      border-color: ${props => props.theme.checkbox.checkboxBorderColor};
      border-radius: ${props => props.theme.checkbox.checkboxBorderRadius};
    }
    && label::after {
      color: ${props => props.theme.checkbox.selectedCheckColor};
    }
    input:checked + label {
      color: ${props => props.theme.checkbox.selectedColor};
      font-weight: ${props => props.theme.checkbox.selectedFontWeight};
    }
  }
`

export default StyledCheckbox
