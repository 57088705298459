import React from 'react'

import {css, StyleSheet} from 'aphrodite'

import colors from '../colors'

export const createClassName = (name, props) =>
  css(
    StyleSheet.create({
      [name]: props,
    })[name]
  )

export const using = (...themes) => themes.join(' ')

export const themedComponent =
  (...themes) =>
  WrappedComponent =>
  props =>
    <WrappedComponent className={using(themes)} {...props} />

/* containers */

export const bordered = createClassName('bordered', {
  border: `solid 1px ${colors.border.rgba}`,
  borderRadius: '4px',
})

export const shadowed = createClassName('shadowed', {
  boxShadow: `0px 0px 4px ${colors.shadow}`,
})

export const unbordered = createClassName('unbordered', {
  border: 'none !important',
})

export const unshadowed = createClassName('unshadowed', {
  boxShadow: 'none !important',
})

export const interactiveBorder = createClassName('interactiveBorder', {
  border: `solid 1px ${colors.border.rgba}`,
  transition: 'border 0.3s ease',
  ':hover': {
    border: `solid 1px ${colors.primaryAccent.rgba}`,
  },
})

export const interactiveShadow = createClassName('interactiveShadow', {
  transition: 'box-shadow 0.3s ease',
  ':hover': {
    boxShadow: `0px 0px 4px ${colors.shadow.rgba}`,
  },
})

export const compactMessage = createClassName('compactMessage', {
  margin: 0,
  borderRadius: 0,
  boxShadow: 'none',
  borderBottom: `solid 1px ${colors.border.rgba}`,
})

export const container = using(
  createClassName('container', {
    backgroundColor: colors.white.rgba,
    padding: '16px',
  }),
  bordered
)

export const mainContainer = createClassName('mainContainer', {
  flex: 1,
  paddingLeft: 20,
  paddingRight: 70,
  paddingTop: 20,
  paddingBottom: 15,
  overflowY: 'scroll',
})

export const mainGridContainer = createClassName('mainGridContainer', {
  flex: 1,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 20,
  paddingBottom: 15,
  overflowY: 'scroll',
})

/* effects */

export const growing = createClassName('growing', {
  transition: 'transform 0.3s ease',
  ':hover': {
    transform: 'scale(1.0075)',
  },
})

export const moveUp = createClassName('moveUp', {
  transition: 'transform 0.3s ease',
  ':hover': {
    transform: 'translateY(-1px)',
  },
})

export const rotate = createClassName('rotate', {
  transition: 'transform 0.3s ease',
  ':hover': {
    transform: 'rotateZ(180deg)',
  },
})

export const interactiveUnderline = createClassName('interactiveUnderline', {
  ':hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: colors.primary.rgba,
  },
})

/* plain buttons */

export const primaryButton = using(
  bordered,
  interactiveShadow,
  interactiveBorder,
  createClassName('primaryButton', {
    backgroundColor: colors.white.rgba,
    color: colors.darkLight.rgba,
    cursor: 'pointer',
    userSelect: 'none',
    ':hover': {
      backgroundColor: colors.primaryLight.rgba,
      color: colors.primary.rgba,
    },
  })
)

export const inactivePrimaryButton = using(
  bordered,
  createClassName('inactivePrimaryButton', {
    backgroundColor: colors.white.rgba,
    color: colors.darkLight.rgba,
    cursor: 'inherit',
    userSelect: 'none',
    opacity: 0.45,
    ':hover': {
      backgroundColor: colors.white.rgba,
      color: colors.darkLight.rgba,
    },
  })
)

export const accountSelect = using(
  primaryButton,
  createClassName('accountSelect', {
    ':hover': {
      backgroundColor: colors.white.rgba,
    },
    ':hover p': {color: colors.primary.rgba},
  })
)

export const fullPrimaryButton = using(
  bordered,
  interactiveShadow,
  interactiveBorder,
  createClassName('fullPrimaryButton', {
    backgroundColor: colors.primary.rgba,
    color: colors.white.rgba,
    cursor: 'pointer',
    userSelect: 'none',
    ':hover': {
      backgroundColor: colors.primaryLight.rgba,
      color: colors.primary.rgba,
    },
  })
)

export const fullNegativeButton = using(
  bordered,
  interactiveShadow,
  interactiveBorder,
  createClassName('fullPrimaryButton', {
    backgroundColor: colors.negative.rgba,
    color: colors.white.rgba,
    cursor: 'pointer',
    userSelect: 'none',
    ':hover': {
      backgroundColor: colors.negative.alpha(0.8).rgba,
      color: colors.white.rgba,
    },
  })
)

export const secondaryButton = createClassName('secondaryButton', {
  cursor: 'pointer',
  padding: '8px',
  borderRadius: '4px',
  backgroundColor: colors.secondary.rgba,
  color: colors.white.rgba,
  fontWeight: '500',
  transition: 'background-color 0.3s ease',
  userSelect: 'none',
  ':hover': {
    backgroundColor: colors.secondaryAccent.rgba,
  },
})

/* download buttons */

export const downloadButton = createClassName('downloadButton', {
  backgroundColor: colors.primary.rgba,
  color: colors.white.rgba,
  cursor: 'pointer',
  userSelect: 'none',
  ':hover': {
    backgroundColor: colors.primaryDark.rgba,
    boxShadow: `0px 0px 4px ${colors.shadow}`,
  },
})

/* simple buttons */

export const simplePrimaryButton = createClassName('simplePrimaryButton', {
  color: colors.primary.rgba,
  cursor: 'pointer',
  transition: 'color 0.3s ease',
  fontWeight: '500',
  userSelect: 'none',
  ':hover': {
    color: colors.primaryAccent.rgba,
  },
})

export const simplePrimaryDarkButton = createClassName('simplePrimaryDarkButton', {
  color: colors.primaryDark.rgba,
  cursor: 'pointer',
  transition: 'color 0.3s ease',
  fontWeight: '500',
  userSelect: 'none',
  ':hover': {
    color: colors.primary.rgba,
  },
})

export const simpleGreyButton = createClassName('simpleGreyButton', {
  color: colors.darkLight.rgba,
  cursor: 'pointer',
  transition: 'color 0.3s ease',
  fontWeight: '500',
  userSelect: 'none',
  ':hover': {
    color: colors.primary.rgba,
  },
})

export const simpleDarkButton = createClassName('simpleDarkButton', {
  color: colors.dark.rgba,
  cursor: 'pointer',
  transition: 'color 0.3s ease',
  fontWeight: '500',
  userSelect: 'none',
  ':hover': {
    color: colors.primary.rgba,
  },
})

export const triggerPrimaryButton = createClassName('triggerPrimaryButton', {
  color: colors.lightDark.rgba,
  cursor: 'pointer',
  backgroundColor: 'transparent',
  transition: 'color 0.3s ease',
  fontWeight: '500',
  userSelect: 'none',
  margin: '0 5px',
  ':hover': {
    color: colors.primary.rgba,
    backgroundColor: colors.primaryLightAlpha.rgba,
  },
})

export const activeTriggerPrimaryButton = using(
  triggerPrimaryButton,
  createClassName('activeTriggerPrimaryButton', {
    color: colors.primary.rgba,
  })
)

export const inactiveTriggerPrimaryButton = using(
  triggerPrimaryButton,
  createClassName('inactiveTriggerPrimaryButton', {
    cursor: 'inherit',
    ':hover': {
      color: colors.lightDark.rgba,
      backgroundColor: 'transparent',
    },
  })
)

export const toolPickerButton = createClassName('toolPickerButton', {
  cursor: 'pointer',
  transition: 'color 0.3s ease',
  borderRadius: 20,
  width: 45,
  height: 45,
  padding: 15,
  fontWeight: '500',
  fontSize: '1.2em',
  userSelect: 'none',
  backgroundColor: 'transparent !important',
  color: colors.darkAccent.rgba,
  ':hover': {
    // backgroundImage: `radial-gradient(${colors.primaryLight} 60%, ${colors.white} 61%);`,
    color: `${colors.primary.rgba} !important`,
  },
})

export const toolPickerButtonActive = using(
  toolPickerButton,
  createClassName('active', {
    color: `${colors.primary.rgba} !important`,
  })
)

export const errorLabel = createClassName('errorLabel', {
  color: colors.negative.rgba,
  borderRadius: 4,
  border: `1px solid ${colors.negative.alpha(0.5).rgba}`,
  backgroundColor: colors.white.rgba,
  fontWeight: '500',
  userSelect: 'none',
})

export const errorModal = createClassName('errorModal', {
  color: colors.negative.rgba,
  paddingTop: 10,
  paddingLeft: 10,
  fontWeight: '500',
  userSelect: 'none',
})

export const opacityChild = 'opacity-child'

export const selectorContainer = createClassName('selectorContainer', {
  flex: 1,
  width: 'auto',
  paddingLeft: 250,
  paddingRight: 250,
  paddingTop: 20,
  paddingBottom: 15,
  overflowY: 'scroll',
})

/* headers */

export const titleHeader = createClassName('titleHeader', {
  color: colors.primaryDark.rgba,
  padding: 10,
  fontWeight: 500,
  fontSize: '1.2em',
  backgroundColor: 'transparent',
  alignSelf: 'center',
})
