/* eslint-disable */
import track from '../track'
import {filter} from 'rxjs/operators'

export default function (tracker, tenant) {
  tracker
    .actionStream()
    .pipe(filter(payload => payload && payload.tracking))
    .subscribe(event => {
      const [module, view, action, value] = event.tracking.split('/')
      const {action_type} = event // eslint-disable-line camelcase
      track(module, {view, action, value, eventName: event.tracking, action_type, tenant})
    })
}
