import moment from 'moment'
import {combineLatest} from 'rxjs'
import {distinctUntilChanged, map} from 'rxjs/operators'

import {DEFAULT_DATE_FORMAT} from '../constants'
import {observeState} from './rx'

moment.prototype.brokenDiff = moment.prototype.diff

function diff(rhs, unit, truncate = true) {
  const lhs = this
  return Math.round(lhs.brokenDiff(rhs, unit, truncate))
}

moment.prototype.diff = diff

moment.defaultFormatUtc = DEFAULT_DATE_FORMAT

moment.forceUpdateInstanceOnUserDateFormatChange = () =>
  combineLatest([observeState('settings'), observeState('app')])
    .pipe(
      map(([settingsState, accountState]) => {
        const userPreference = settingsState.getIn(['preferences', 'date'])
        const accountPreference = accountState.getIn(['accountSettings', 'dateFormat'])
        if (userPreference) return userPreference
        else if (accountPreference) return accountPreference
        return 'DD/MM/YYYY'
      }),
      distinctUntilChanged()
    )

    .subscribe(date => {
      if (date) {
        moment.defaultFormatUtc = date
      }
    })

window.moment = moment
