import React from 'react'

import {AutoSizer, GridCellProps, MultiGrid, ScrollParams} from 'react-virtualized'

import {capitalize, get} from '../../..'
import colors from '../../../colors'
import ErrorBoundary from '../../../ErrorBoundary'
import BasicCell from '../../cells/BasicCell'
import GridHeader from './GridHeader'
import MetricHeader from './MetricHeader'
import {SchemeType} from './Types'

import './grid.css'

interface ScrollableGridProps {
  cellSize: number
  items: any[]
  headerSize: number
  scheme: SchemeType[]
  aggregationScheme?: boolean
  aggregationSize?: number
  autoSize?: boolean
  cellStyle?: React.CSSProperties
  defaultSort?: string | null
  defaultSortDir?: 'asc' | 'desc'
  fixedColumnCount?: number
  fixedRowCount?: number
  height?: number
  highlight?: boolean
  highlightedRow?: {key: string; scheme: 'metric'} | null
  onCellClick?: (cell: {event: React.MouseEvent<HTMLDivElement, MouseEvent>; index: number; cellData: any}) => void
  orientation?: 'horizontal' | 'vertical'
  overscanColumnCount?: number
  overscanRowCount?: number
  placehold?: boolean
  selectedItemKey?: string
  selectedItems?: Record<string, any>
  updateSelectedItems?: (items: Record<string, any>) => void
  width?: number
}

interface ScrollableGridState {
  hoveredIndex: number | null
  items: any[]
  sort: {
    key: string | null
    dir: 'asc' | 'desc'
  }
}

export default class ScrollableGrid extends React.Component<ScrollableGridProps, ScrollableGridState> {
  _previousWidth: number

  cunt: number

  delta: number

  grid: MultiGrid | null

  lastPos: number | null

  timer: ReturnType<typeof setTimeout> | null

  constructor(props: ScrollableGridProps) {
    super(props)

    const {items, defaultSort = null, defaultSortDir = 'asc'} = props

    this.lastPos = null
    this.delta = 0
    this.timer = null
    this.cunt = 0
    this._previousWidth = 0
    this.grid = null

    this.state = {
      hoveredIndex: null,
      items,
      sort: {key: defaultSort, dir: defaultSortDir},
    }
  }

  UNSAFE_componentWillMount() {
    this.setState(this.refreshList(this.props))
  }

  UNSAFE_componentWillReceiveProps(nextProps: ScrollableGridProps) {
    const {defaultSort = null, defaultSortDir = 'asc'} = nextProps
    this.setState(
      this.refreshList(nextProps, {
        ...this.state,
        sort:
          defaultSort && defaultSort !== this.state.sort.key
            ? {key: defaultSort, dir: defaultSortDir}
            : this.state.sort,
      })
    )
    if (this.grid) this.grid.recomputeGridSize()
  }

  onSort = (sortBy: string) => (sortDirection: 'asc' | 'desc') => {
    this.setState({
      ...this.refreshList(this.props, {
        ...this.state,
        sort: {
          key: sortBy,
          dir: sortDirection,
        },
      }),
    })
  }

  onScroll = ({scrollWidth, scrollLeft}: ScrollParams) => {
    if (this.lastPos !== null && scrollLeft < scrollWidth) {
      this.delta = Math.abs(scrollLeft - this.lastPos)
    }
    this.lastPos = scrollLeft
    if (this.timer) clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.lastPos = null
      this.delta = 0
    }, 100)
  }

  onCellClick = ({
    event,
    index,
    cellData,
  }: {
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
    index: number
    cellData: any
  }) => {
    if (this.props.onCellClick) this.props.onCellClick({event, index, cellData})
  }

  refreshList(props: ScrollableGridProps, state: ScrollableGridState = this.state) {
    let {items} = props
    const {dir, key} = state.sort

    if (key && props.scheme.find(c => c.key === key)) {
      let sortFunc = props.scheme.find(c => c.key === key)?.sort

      if (!sortFunc || typeof sortFunc === 'boolean') {
        sortFunc = (direction, k = key) => (a, b) => {
          const aValue = get(a, k, 0)
          const bValue = get(b, k, 0)
          if (direction === 'asc') return aValue >= bValue ? 1 : -1
          return aValue <= bValue ? 1 : -1
        }
      }
      items = items.sort(sortFunc(dir, key))
    }

    return {items, sort: state.sort}
  }

  toggleAll = () => {
    const {selectedItems = {}, selectedItemKey = 'id', updateSelectedItems = null} = this.props
    if (updateSelectedItems)
      if (Object.keys(selectedItems).length) {
        updateSelectedItems({})
      } else {
        const all = this.state.items.reduce((acc, i) => {
          if (i.children) acc.push(...i.children)
          else acc.push(i)
          return acc
        }, [])
        updateSelectedItems(
          all.reduce((acc: Record<string, any>, item: any) => {
            acc[item[selectedItemKey]] = item // eslint-disable-line no-param-reassign
            return acc
          }, {})
        )
      }
  }

  selectItem = ({item, e}: {e: React.MouseEvent<HTMLInputElement>; item: any}) => {
    e.preventDefault()
    e.stopPropagation()
    const {selectedItems = {}, selectedItemKey = 'id', updateSelectedItems} = this.props
    if (updateSelectedItems) {
      let children = null
      if (item.children && item.children.length > 0) {
        children = item.children.filter((i: any) => selectedItems[i[selectedItemKey]])
        children = children.length === 0 ? item.children : children
      }
      const newItems = (children || [item]).reduce(
        (acc: Record<string, any>, i: any) => ({...acc, [i[selectedItemKey]]: i}),
        {}
      )
      const allItems = {...selectedItems, ...newItems}
      const filteredItems = Object.keys(allItems).reduce((acc: Record<string, any>, key) => {
        if (selectedItems[key] && newItems[key]) return acc
        acc[key] = allItems[key]
        return acc
      }, {})
      updateSelectedItems(filteredItems)
    }
  }

  getScheme() {
    return this.props.scheme
  }

  isChecked = (item: any) => {
    const {selectedItems = {}, selectedItemKey = 'id'} = this.props
    return !!selectedItems[item[selectedItemKey]]
  }

  // isChecked = items => {
  //   const {selectedItemKey, selectedItems} = this.props
  //   return items.map(i => {
  //     if (i.children && i.children.length) {
  //       return {...i, checked: !!i.children.find(child => selectedItems[child[selectedItemKey]])}
  //     }
  //     return {...i, checked: !!selectedItems[i[selectedItemKey]]}
  //   })
  // }

  getColumnWidth = (extraWidth: number) => (data: {index: number}) => {
    const {orientation = 'vertical'} = this.props
    const {index} = data

    if (orientation === 'horizontal')
      return this.getScheme()[index].size + (this.getScheme()[index].canGrow ? extraWidth : 0)
    else if (index === 0) return this.props.headerSize
    return this.props.cellSize
  }

  getRowHeight = ({index}: {index: number}) => {
    const {aggregationScheme = false, aggregationSize = 0, orientation = 'vertical'} = this.props
    if (orientation === 'vertical') return this.getScheme()[index].size
    else if (index === 0) return this.props.headerSize
    else if (index === 1 && aggregationScheme) return aggregationSize
    return this.props.cellSize
  }

  cellRenderer = (data: GridCellProps) => {
    const {aggregationScheme = false, highlight = false, orientation = 'vertical'} = this.props
    const {columnIndex, rowIndex, key, style} = data
    const schemeIndex = orientation === 'horizontal' ? columnIndex : rowIndex
    const itemIndex = (orientation === 'horizontal' ? rowIndex : columnIndex) - 1 - (aggregationScheme ? 1 : 0)
    const item = itemIndex >= 0 ? this.props.items[itemIndex] : {}
    const scheme = this.getScheme()[schemeIndex]

    const cellStyle = {
      ...(style || {}),
      ...(this.props.cellStyle || {}),
      ...(scheme.style || {}),
      textAlign: 'unset',
      display: 'flex',
      alignItems: 'center',
    } as React.CSSProperties

    const getStyle = () => {
      if (itemIndex === -2 || (itemIndex === -1 && !aggregationScheme)) {
        if (scheme.headerStyle) return {...cellStyle, ...scheme.headerStyle}
      }
      return cellStyle
    }

    return (
      <div
        key={key}
        style={getStyle()}
        onMouseEnter={
          itemIndex >= 0 && highlight
            ? () => this.setState({hoveredIndex: orientation === 'horizontal' ? rowIndex : columnIndex})
            : undefined
        }
        onMouseLeave={itemIndex >= 0 && highlight ? () => this.setState({hoveredIndex: null}) : undefined}
        className={
          // eslint-disable-next-line no-nested-ternary
          itemIndex >= 0 && highlight
            ? (orientation === 'horizontal' ? rowIndex : columnIndex) === this.state.hoveredIndex
              ? 'ReactVirtualized__Grid__scheme__hoverable'
              : ''
            : ''
        }
        onClick={itemIndex >= 0 ? event => this.onCellClick({event, index: itemIndex, cellData: item}) : undefined}
      >
        {(() => {
          if (itemIndex === -2 || (itemIndex === -1 && !aggregationScheme)) return this.renderHeader(scheme, scheme.key)
          else if (itemIndex === -1) return this.renderAggregationCell(scheme)
          return this.renderCell(item, scheme, itemIndex) || ''
        })()}
      </div>
    )
  }

  cellDataGetter = (dataKey: string, rowData: any) => get(rowData, dataKey)

  renderHeader = (scheme: SchemeType, dataKey: string) => {
    const {selectedItems = {}} = this.props
    if (scheme.hidden) return '...'
    if (scheme.headerMetric)
      return (
        <MetricHeader
          {...scheme.headerMetric}
          sort={scheme.sort ? this.onSort(dataKey) : null}
          sortActive={this.state.sort.key === dataKey}
          sortDirection={this.state.sort.dir}
        />
      )
    if (scheme.headerComponent)
      return (
        <ErrorBoundary>
          <scheme.headerComponent
            label={capitalize(scheme.label)}
            sort={scheme.sort && this.onSort(dataKey)}
            sortActive={this.state.sort.key === dataKey}
            sortDirection={this.state.sort.dir}
            schemeData={{
              ...scheme.props,
              ...(dataKey === 'checked'
                ? {
                    toggle: this.selectItem,
                    toggleAll: this.toggleAll,
                    checked: Object.keys(selectedItems).length === this.state.items.length,
                  }
                : {}),
            }}
            className="ReactVirtualized__Grid__headerTruncatedText"
          />
          {/* scheme.subHeaderComponent ? (
            <scheme.subHeaderComponent
              label={capitalize(scheme.label)}
              schemeData={scheme.props}
              className="ReactVirtualized__Grid__headerTruncatedText"
            />
          ) : null */}
        </ErrorBoundary>
      )
    return (
      <GridHeader
        label={capitalize(scheme.label)}
        sort={scheme.sort ? this.onSort(dataKey) : null}
        sortActive={this.state.sort.key === dataKey}
        sortDirection={this.state.sort.dir}
        schemeData={scheme.props}
        // className="ReactVirtualized__Grid__headerTruncatedText"
      />
    )
  }

  renderAggregationCell = (scheme: SchemeType) => {
    if (scheme.aggregationComponent)
      return (
        <ErrorBoundary>
          <scheme.aggregationComponent
            label={capitalize(scheme.label)}
            schemeData={scheme.props}
            className="ReactVirtualized__Grid__headerTruncatedText"
          />
        </ErrorBoundary>
      )
    return scheme.props ? scheme.props.value : ''
  }

  renderCell = (item: any, scheme: SchemeType, index: number) => {
    const {highlightedRow = null, placehold = false, selectedItems = {}} = this.props
    if (scheme.hidden) return null
    if (scheme.component)
      return (
        <ErrorBoundary>
          <div
            className="ReactVirtualized__Grid__cell"
            style={{
              textAlign: 'unset',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              ...(this.isChecked(item) && {backgroundColor: colors.primaryLightAlpha.rgba}),
              ...(highlightedRow &&
                highlightedRow?.key === item.key && {
                  backgroundColor: colors.primaryLight.rgba,
                  fontWeight: 500,
                }),
            }}
          >
            <scheme.component
              {...scheme.props}
              value={scheme.key === 'checked' ? this.isChecked(item) : this.cellDataGetter(scheme.key, item)}
              schemeKey={scheme.key}
              item={item}
              placehold={placehold && this.delta > 20}
              index={index}
              extra={{
                ...scheme.props,
                ...(scheme.key === 'checked'
                  ? {
                      toggle: this.selectItem,
                      toggleAll: this.toggleAll,
                      checked: Object.keys(selectedItems).length === this.state.items.length,
                    }
                  : {}),
              }}
              style={{
                ...scheme.style,
                ...(highlightedRow?.scheme === scheme.key &&
                  highlightedRow?.key === item.key && {
                    color: colors.primary.rgba,
                    fontWeight: 500,
                  }),
              }}
            />
          </div>
        </ErrorBoundary>
      )
    return (
      <div>
        <BasicCell value={this.cellDataGetter(scheme.key, item)} extra={scheme.props} />
      </div>
    )
  }

  _setRef(ref: MultiGrid | null) {
    this.grid = ref
  }

  render() {
    const {
      autoSize = false,
      aggregationScheme = false,
      fixedColumnCount = 1,
      fixedRowCount = 0,
      height: heightProps = 0,
      orientation = 'vertical',
      overscanColumnCount = 0,
      overscanRowCount = 0,
      width: widthProps = 0,
    } = this.props
    const {items} = this.state

    const scheme = this.getScheme()
    const columns = orientation === 'horizontal' ? scheme : items
    const rows = orientation === 'horizontal' ? items : scheme

    return (
      <div style={{flex: '1 1 auto', alignSelf: 'stretch'}}>
        <AutoSizer disableHeight={autoSize && !!heightProps} disableWidth={!!widthProps}>
          {({height, width}) => {
            if (this._previousWidth !== width && !widthProps) {
              this._previousWidth = width
              this.cunt += 1
            }

            const scrollbarSize = height <= items.length * this.props.cellSize + this.props.headerSize ? 8 : 0

            const minWidth =
              scheme.reduce((parent, child) => {
                parent += child.size // eslint-disable-line no-param-reassign
                return parent
              }, 0) + scrollbarSize

            const extraWidth =
              orientation === 'horizontal' && width - minWidth > 0
                ? (width - minWidth) / scheme.filter(s => s.canGrow).length
                : 0

            return (
              <MultiGrid
                key={this.cunt}
                ref={ref => this._setRef(ref)}
                cellRenderer={this.cellRenderer}
                columnCount={
                  columns.length +
                  (orientation === 'vertical' ? 1 : 0) +
                  (aggregationScheme && orientation === 'vertical' ? 1 : 0)
                }
                columnWidth={this.getColumnWidth(extraWidth)}
                enableFixedColumnScroll
                fixedColumnCount={fixedColumnCount + (orientation === 'vertical' && aggregationScheme ? 1 : 0)}
                fixedRowCount={fixedRowCount + (orientation === 'horizontal' && aggregationScheme ? 1 : 0)}
                height={autoSize ? height : heightProps || height}
                onScroll={this.onScroll}
                overscanColumnCount={overscanColumnCount}
                overscanRowCount={overscanRowCount}
                rowCount={
                  rows.length +
                  (orientation === 'horizontal' ? 1 : 0) +
                  (aggregationScheme && orientation === 'horizontal' ? 1 : 0)
                }
                rowHeight={this.getRowHeight}
                styleBottomLeftGrid={{
                  borderRight: '2px solid #EEE',
                }}
                styleTopLeftGrid={{
                  borderRight: '2px solid #EEE',
                  borderBottom: '2px solid #EEE',
                }}
                styleTopRightGrid={{
                  borderBottom: '2px solid #EEE',
                }}
                classNameBottomLeftGrid="ReactVirtualized__Grid__bottomLeft"
                classNameTopLeftGrid="ReactVirtualized__Grid__topLeft"
                width={widthProps || width}
              />
            )
          }}
        </AutoSizer>
      </div>
    )
  }
}
