import React from 'react'

import {Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'
import ErrorFormatter from '../../ErrorFormatter'
import i18n from '../../i18n'

const UIToast = styled(Row)`
  color: ${colors.dark.rgba};
  background: ${colors.white.rgba};
  align-items: center;
  padding: 10px;
  margin-bottom: 8px;
  h4 {
    line-height: 2em;
    padding: 0;
    margin: 0;
    color: ${colors.dark.rgba};
    font-weight: 500;
  }
`

const UILink = styled.a`
  && {
    color: ${colors.negative.rgba};
    margin-right: 15px;
    text-decoration: underline;
    font-weight: 500;
    i {
      margin-left: 4px;
      text-decoration: none;
    }
    :hover {
      color: darkRed;
      text-decoration: underline;
      i {
        text-decoration: none;
      }
    }
  }
`

const ErrorRequesting = ({error, title}) => {
  return (
    <UIToast>
      {error instanceof Error ? (
        <Col>
          <h4>{title || i18n.t('placeholders.errors.errorOccured')}</h4>
          <p>{i18n.t('placeholders.global.supportMessage')}</p>
          <Row>
            <UILink onClick={() => window.Intercom('showMessages')}>
              {i18n.t('placeholders.global.liveTechSupport')}
              <Icon name="long arrow alternate right" />
            </UILink>
            <UILink onClick={() => global.open('https://seelk-studio.typeform.com/to/nKFmSO')}>
              {i18n.t('placeholders.global.feedbackForm')}
              <Icon name="long arrow alternate right" />
            </UILink>
          </Row>
        </Col>
      ) : (
        <Col>
          <h4>{title || i18n.t('placeholders.errors.unexpectedError')}</h4>
          <ErrorFormatter errors={error} />
        </Col>
      )}
    </UIToast>
  )
}

ErrorRequesting.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  title: PropTypes.string,
}

ErrorRequesting.defaultProps = {
  error: null,
  title: null,
}

export default ErrorRequesting
