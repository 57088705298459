import {createSelectorCreator} from 'reselect'

/* eslint-disable */
function lastResultMemoize(func) {
  let lastArgs = null
  let lastResult = null
  return function () {
    if (!lastResult) {
      lastResult = func.apply(null, arguments)
    }
    lastArgs = arguments
    return lastResult
  }
}
/* eslint-enable */

export const createLastResultSelector = createSelectorCreator(lastResultMemoize)
