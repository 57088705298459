import React from 'react'

import {Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

const UIToast = styled(Row)`
  color: white;
  align-items: center;
  padding: 20px;
`

const UILink = styled.a`
  && {
    color: white;
    margin-right: 15px;
    text-decoration: underline;
    font-weight: 500;
    i {
      margin-left: 4px;
      text-decoration: none;
    }
  }
`

const InfoRequesting = ({message, links}) => {
  return (
    <UIToast>
      <Col>
        <p>{message}</p>
        {links && links.length && (
          <Row>
            {links.map(({value, onClick}) => (
              <UILink key={value} onClick={onClick}>
                {value}
                <Icon name="long arrow alternate right" />
              </UILink>
            ))}
          </Row>
        )}
      </Col>
    </UIToast>
  )
}

InfoRequesting.propTypes = {
  message: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
}

InfoRequesting.defaultProps = {
  links: null,
}

export default InfoRequesting
