import React from 'react'

import {Col, Row} from 'jsxstyle'
import _ from 'lodash'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'

const StyledHeaderDeltaCell = styled(Col)`
  &&&&& {
    flex: 1;
    background-color: inherit;
  }
`

const StyledDeltaCell = styled(Col)`
  &&&&& {
    flex: 1;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    height: 100%;
    background-color: ${props => props.backgroundColor};
  }
`

type ItemType = {
  [key: string]: {
    display: React.ReactNode
    delta?: number
    deltaDisplay?: string | number
    value?: number
  }
}

type ExtraType = {
  isDeltaPositive?: boolean
  showDeltaColor?: boolean
  isHeader?: boolean
  fontWeight?: number | string
}

interface DeltaCellProps {
  item: ItemType
  schemeKey: string
  extra?: ExtraType
}

const DeltaCell = ({item, schemeKey, extra}: DeltaCellProps) => {
  const {display, delta, deltaDisplay, value} = item[schemeKey] || {}
  const isDeltaPositive = extra?.isDeltaPositive ?? item.isDeltaPositive
  const showDeltaColor = extra?.showDeltaColor ?? item.showDeltaColor
  let color = null
  let backgroundColor = null
  let icon = null

  const getIcon = (value: number) => {
    if (value > 0) return 'triangle up'
    if (value < 0) return 'triangle down'
    return null
  }

  const getColor = (value: number, reverse: boolean) => {
    if (value > 0) return reverse ? colors.negative : colors.positive
    else if (value < 0) return reverse ? colors.positive : colors.negative
    return colors.lightDark
  }

  if (!_.isNil(delta)) {
    color = getColor(delta, !isDeltaPositive)
    icon = getIcon(delta)
    backgroundColor = showDeltaColor ? color?.alpha(0.2).rgba : null
  }

  const Container = extra?.isHeader ? StyledHeaderDeltaCell : StyledDeltaCell

  return (
    <Container backgroundColor={backgroundColor}>
      {!_.isNil(delta) && value !== null && (
        <Row fontSize={12} color={color?.rgba} justifyContent="flex-end">
          {icon && <Icon className={icon} />}
          {deltaDisplay}
        </Row>
      )}
      <Row fontWeight={extra?.fontWeight} flex={1} justifyContent="center" alignItems="center">
        {value === null ? '-' : display}
      </Row>
    </Container>
  )
}

export default DeltaCell
