import React from 'react'

import {Row} from 'jsxstyle'
import Rheostat, {PublicState} from 'rheostat'
import {Input, InputOnChangeData} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../../colors'

const StyledRow = styled(Row)`
  &&& {
    width: 400px;
    display: flex;
    align-items: center;
  }
`

const StyledInput = styled(Input)`
  &&& {
    height: 30px;
    input {
      width: 60px;
      padding: 0 5px;
      border: 1px solid ${colors.light.rgba};
    }
    .ui.label {
      padding: 9px !important;
      font-size: 1em;
      font-weight: 300 !important;
      background-color: ${colors.lightAccent.rgba};
      border: 1px solid ${colors.light.rgba};
    }
  }
`

const StyledRheostat = styled.div`
  width: 200px;
`

interface SeelkLimitsProps {
  max: number
  min: number
  onChange: (limit: Record<string, number>) => void
  error?: boolean
  values?: number[]
}

export default class SeelkLimits extends React.Component<SeelkLimitsProps> {
  inputChange = (limit: 'min' | 'max') => (e: React.ChangeEvent<HTMLInputElement>, {value}: InputOnChangeData) => {
    const {min, max, onChange} = this.props
    const formattedValue = parseInt(value, 10)
    const validValue = formattedValue > max ? max : formattedValue < min ? min : formattedValue
    onChange({[limit]: validValue})
  }

  rheostatChange = ({values}: PublicState) => {
    const [min, max] = values
    this.props.onChange({min, max})
  }

  render() {
    const {min, max, values = [0, 100], error = false} = this.props
    const [currentMin, currentMax] = values

    return (
      <StyledRow>
        <StyledInput
          type="number"
          label="%"
          labelPosition="left"
          value={`${Number.isNaN(currentMin) ? '' : currentMin}`}
          onChange={this.inputChange('min')}
          error={error}
        />
        <StyledRheostat>
          <Rheostat min={min} max={max} onValuesUpdated={this.rheostatChange} values={values} />
        </StyledRheostat>
        <StyledInput
          type="number"
          label="%"
          labelPosition="left"
          value={`${Number.isNaN(currentMax) ? '' : currentMax}`}
          onChange={this.inputChange('max')}
          error={error}
        />
      </StyledRow>
    )
  }
}
