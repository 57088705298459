import moment from 'moment'

import i18n from '../i18n'
import {get, validNumber} from '..'

/* CHART */

export {default as Chart} from './charts/Chart'
export {default as HighchartsHelper} from './charts/HighchartsHelper'
export {default as MultiHighchartsHelper} from './charts/MultiHighchartsHelper'
export {default as Sparkline} from './charts/Sparkline'
export {default as SpiderChart} from './charts/SpiderChart'

export {createXAxisDateCategories, dataLabelsFormatter, PATTERN} from './charts/HighchartsHelper'

/* TABLE / GRID */

export {default as DeepDivePopup} from './table/ScrollableGrid/DeepDivePopup'
export {default as GridHeader} from './table/ScrollableGrid/GridHeader'
export {default as GridTotalHeader} from './table/ScrollableGrid/GridTotalHeader'
export {default as IconHeader} from './table/ScrollableGrid/IconHeader'
export {default as WrappedGrid} from './table/ScrollableGrid/Wrapper'
export {default as ScrollableGrid} from './table/ScrollableGrid'
export {default as TotalHeader} from './table/VirtualTable/TotalHeader'
export {default as VirtualTable} from './table/VirtualTable'

/* CELLS */

export {default as AdvertisingCell} from './cells/AdvertisingCell'
export {default as BasicCell} from './cells/BasicCell'
export {default as CatalogNote} from './cells/CatalogNote'
export {default as CheckboxCell} from './cells/CheckboxCell'
export {default as ClassNamedCell} from './cells/ClassNamedCell'
export {default as ClickableCell} from './cells/ClickableCell'
export {default as ColorCell} from './cells/ColorCell'
export {default as CopyAsin} from './cells/CopyAsin'
export {default as Country} from './cells/Country'
export {default as CountryName} from './cells/CountryName'
export {default as DeltaCell} from './cells/DeltaCell'
export {default as DisplayFormatCell} from './cells/DisplayFormatCell'
export {default as DropdownActionsCell} from './cells/DropdownActionsCell'
export {default as EllipsisPopUpText} from './cells/EllipsisPopUpText'
export {default as Flag} from './cells/Flag'
export {default as FlagONeal} from './cells/FlagONeal'
export {default as Flags} from './cells/Flags'
export {default as FrequencyLabel} from './cells/FrequencyLabel'
export {default as HeaderCheckboxCell} from './cells/HeaderCheckboxCell'
export {default as FillablePercentage} from './cells/FillablePercentage'
export {default as FillableValue} from './cells/FillableValue'
export {default as Keyword} from './cells/Keyword'
export {default as LabelCell} from './cells/LabelCell'
export {default as MarketplaceCountryName} from './cells/MarketplaceCountryName'
export {default as MarketPlaceFlag} from './cells/MarketPlaceFlag'
export {default as Number} from './cells/Number'
export {default as ParentOrFlag} from './cells/ParentOrFlag'
export {default as Percent} from './cells/Percent'
export {default as Percentage} from './cells/Percentage'
export {default as ProgressBar} from './cells/ProgressBar'
export {default as PopUpLink} from './cells/PopUpLink'
export {default as PercentageTick} from './cells/PercentageTick'
export {default as PopUpTick} from './cells/PopUpTick'
export {default as Price} from './cells/Price'
export {default as Product} from './cells/Product'
export {default as ProductImage} from './cells/ProductImage'
export {default as ProductName} from './cells/ProductName'
export {default as RatingDiff} from './cells/RatingDiff'
export {default as RatingCell} from './cells/RatingCell'
export {default as SchedulerCell} from './cells/SchedulerCell'
export {default as SignedNumber} from './cells/SignedNumber'
export {default as SignedPercentage} from './cells/SignedPercentage'
export {default as StatusAndProgress} from './cells/StatusAndProgress'
export {default as TagsCell} from './cells/Tags'
export {default as Tick} from './cells/Tick'
export {default as WithPopup} from './cells/WithPopup'
export {default as LerpCell} from './cells/LerpCell'
export {default as SearchTermCell} from './cells/SearchTerm'

export const cellPercentageObject = (value, precision = 0) => ({
  display: i18n.toReducedPercentage(value * 100, {precision}),
  value,
})

export const cellNullObject = () => ({
  display: '-',
  value: null,
})

export const cellValueObject = (unit, value, currency, precision = 0, total = null) => ({
  display:
    unit === 'absolute'
      ? i18n.toNumber(value, {precision})
      : i18n.toCurrency(i18n.toNumber(value, {precision}), {
          code: currency,
          precision,
        }),
  value,
  share: total ? i18n.toPercentage(validNumber((value / total) * 100), {precision: 1}) : null,
})

export function sortGridObjects(dir, key) {
  return (lhs, rhs) => {
    const infinityValue = dir === 'asc' ? Infinity : -Infinity
    const lhsValue = get(lhs, key, {}).value
    const rhsValue = get(rhs, key, {}).value
    const sortableLhsValue = lhsValue !== null && typeof lhsValue === 'number' ? lhsValue : infinityValue
    const sortableRhsValue = rhsValue !== null && typeof rhsValue === 'number' ? rhsValue : infinityValue
    if (dir === 'asc') return sortableLhsValue >= sortableRhsValue ? 1 : -1
    return sortableLhsValue <= sortableRhsValue ? 1 : -1
  }
}

export function sortGridValues(dir, key) {
  return (lhs, rhs) => {
    const infinityValue = dir === 'asc' ? Infinity : -Infinity
    const lhsValue = get(lhs, key, 0)
    const rhsValue = get(rhs, key, 0)
    const sortableLhsValue = lhsValue !== null && typeof lhsValue === 'number' ? lhsValue : infinityValue
    const sortableRhsValue = rhsValue !== null && typeof rhsValue === 'number' ? rhsValue : infinityValue
    if (dir === 'asc') return sortableLhsValue >= sortableRhsValue ? 1 : -1
    return sortableLhsValue <= sortableRhsValue ? 1 : -1
  }
}

export function sortTableValues(dir, key) {
  return (lhs, rhs) => {
    const lhsValue = get(lhs, key, {}).value
    const rhsValue = get(rhs, key, {}).value
    if (dir === 'asc') return lhsValue >= rhsValue ? 1 : -1
    return lhsValue <= rhsValue ? 1 : -1
  }
}

const formatCountry = marketplace => {
  if (!marketplace) return ''
  else if (marketplace.startsWith('amazon_')) return marketplace.split('_')?.[1] ?? marketplace
  return marketplace
}

export function sortTableCountries(dir, key) {
  return (lhs, rhs) => {
    const lhsValue = formatCountry(get(lhs, key, {}))
    const rhsValue = formatCountry(get(rhs, key, {}))
    const lhsCountry = i18n.t(`countries.${lhsValue?.toLowerCase()}.name`) ?? ''
    const rhsCountry = i18n.t(`countries.${rhsValue?.toLowerCase()}.name`) ?? ''
    if (dir === 'asc') return lhsCountry >= rhsCountry ? 1 : -1
    return lhsCountry <= rhsCountry ? 1 : -1
  }
}

export function sortTableNa(dir, key) {
  return (lhs, rhs) => {
    const infinityValue = dir === 'asc' ? Infinity : -Infinity
    const lhsValue = get(lhs, key, null)
    const rhsValue = get(rhs, key, null)
    const sortableLhsValue = lhsValue !== null && typeof lhsValue === 'number' ? lhsValue : infinityValue
    const sortableRhsValue = rhsValue !== null && typeof rhsValue === 'number' ? rhsValue : infinityValue
    if (dir === 'asc') return sortableLhsValue >= sortableRhsValue ? 1 : -1
    return sortableLhsValue <= sortableRhsValue ? 1 : -1
  }
}

export const sortStrings = (a, b) => {
  const nameA = a.toUpperCase()
  const nameB = b.toUpperCase()
  if (nameA < nameB) return -1
  else if (nameA > nameB) return 1
  return 0
}

export function sortUnclassifiedStrings(dir, key) {
  return (lhs, rhs) => {
    const infinityValue = dir === 'asc' ? 'ZZZZZZZZZZZZ' : '000000000000'
    const lhsValue = get(lhs, key, null)
    const rhsValue = get(rhs, key, null)
    const sortableLhsValue =
      lhsValue === i18n.t('placeholders.empty.notAvailable') ? infinityValue : lhsValue.toUpperCase()
    const sortableRhsValue =
      rhsValue === i18n.t('placeholders.empty.notAvailable') ? infinityValue : rhsValue.toUpperCase()
    if (dir === 'asc') return sortableLhsValue >= sortableRhsValue ? 1 : -1
    return sortableLhsValue <= sortableRhsValue ? 1 : -1
  }
}

export function sortDateCells(dir, key) {
  return (lhs, rhs) => {
    const infinityValue = dir === 'asc' ? 8640000000000000 : 0
    const lhsValue = get(lhs, key, null)
    const rhsValue = get(rhs, key, null)
    const sortableLhsValue = moment.utc(lhsValue === null ? infinityValue : lhsValue)
    const sortableRhsValue = moment.utc(rhsValue === null ? infinityValue : rhsValue)

    if (dir === 'asc') return sortableLhsValue >= sortableRhsValue ? 1 : -1
    return sortableLhsValue <= sortableRhsValue ? 1 : -1
  }
}

const sortStringsCells = key => dir => {
  const infinityValue = dir === 'asc' ? 'ZZZZZZZZZZZZ' : '000000000000'
  return (a, b) => {
    const aCell = a[key] ? a[key].toUpperCase() : infinityValue
    const bCell = b[key] ? b[key].toUpperCase() : infinityValue
    return dir === 'asc' ? aCell.localeCompare(bCell) : bCell.localeCompare(aCell)
  }
}

export const sortTitle = sortStringsCells('title')

export const sortName = sortStringsCells('name')
