import React, {CSSProperties} from 'react'

import {Checkbox} from 'semantic-ui-react'

import colors from '../../colors'

type CheckboxStyleType = 'bi' | 'noPadding' | null

const switchStyle = (type: CheckboxStyleType): CSSProperties => {
  switch (type) {
    case 'bi':
      return {
        position: 'absolute',
        top: '22px',
        left: 0,
        width: '30px',
        height: 'calc(100% - 22px)',
        padding: '10px 0px 0px 10px',
        backgroundColor: colors.primaryLight.rgba,
      }
    case 'noPadding':
      return {
        padding: '0',
      }

    default:
      return {
        padding: '0 10px',
      }
  }
}

type DataType = {
  checkboxStyle?: CheckboxStyleType
  checked: boolean
  toggleAll: () => void
}

interface HeaderCheckboxCellProps {
  columnData?: DataType
  schemeData: DataType
}

const HeaderCheckboxCell = ({columnData, schemeData}: HeaderCheckboxCellProps) => {
  const data = columnData || schemeData
  const {checkboxStyle = null, checked, toggleAll} = data

  return (
    <div style={switchStyle(checkboxStyle)}>
      <Checkbox onClick={toggleAll} checked={checked} />
    </div>
  )
}

export default HeaderCheckboxCell
