import React from 'react'

import {Label} from 'semantic-ui-react'
import styled from 'styled-components'

import {unitSymbol} from '../..'
import colors from '../../colors'
import {OPERATORS} from '../../constants'
import i18n from '../../i18n'

const StyledFilterContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 3px;
  background-color: ${colors.light.rgba};
  margin: 3px;
  padding: 5px;
`

const StyledFilterName = styled.span`
  font-size: 12px;
  color: ${colors.darkLight.rgba};
`

const StyledMetric = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  color: ${colors.dark.rgba};
  i {
    display: flex;
    align-items: center;
    margin: auto 8px;
  }
  b {
    font-weight: 300;
  }
  span {
    margin: auto 5px;
  }
`

const StyledAggregate = styled(Label)`
  &&&&& {
    background-color: ${colors.primaryLight.rgba};
    color: ${colors.primary.rgba};
    box-shadow: 0px 0px 0px 1px ${colors.secondary.rgba};
    margin-left: 10px;
  }
`

interface MetricType {
  name: string
  aggregation: string
  operator: keyof typeof OPERATORS
  unit: string
  currency: string
  value: {a: number; b?: number}
}

interface StaticMetricFiltersProps {
  metric: MetricType
}

const StaticMetricFilters = ({metric}: StaticMetricFiltersProps) => {
  const {name, aggregation, operator, unit, currency, value} = metric
  return (
    <StyledFilterContainer>
      <StyledFilterName>{name}</StyledFilterName>
      <StyledMetric>
        <i className={OPERATORS?.[operator]?.icon} />
        <b>{`${value?.a ?? 0}${unitSymbol(unit, currency)}`}</b>
        {operator === 'bw' ? (
          <>
            <span>{i18n.t('filters.and')}</span>
            <b>{`${value?.b ?? 0}${unitSymbol(unit, currency)}`}</b>
          </>
        ) : null}
      </StyledMetric>
      {aggregation ? <StyledAggregate key={aggregation} size="mini" content={aggregation.toUpperCase()} /> : null}
    </StyledFilterContainer>
  )
}

export default StaticMetricFilters
