import React from 'react'

import {Box, Col} from 'jsxstyle'
import {Checkbox, Icon} from 'semantic-ui-react'

import colors from '../../../../../colors'
import i18n from '../../../../../i18n'
import {FilterButton} from '../../../..'
import FilterBarItem from './FilterBarItem'

interface MatchConflictNoContentProps {
  data?: 'MATCH' | 'CONFLICT' | 'NO_CONTENT'
  onChange: (changes: 'MATCH' | 'CONFLICT' | 'NO_CONTENT') => void
  onRemove: () => void
  onApply: () => void
  title: string
  icon: string | null
  disabled?: boolean
}

const MatchConflictNoContent = ({
  data,
  onChange,
  onRemove,
  onApply,
  title,
  icon = null,
  disabled = false,
  ...barItemProps
}: MatchConflictNoContentProps) => {
  let txt
  if (data === 'MATCH') {
    txt = i18n.t('misc.cross.match')
  } else if (data === 'CONFLICT') {
    txt = i18n.t('misc.cross.conflict')
  } else if (data === 'NO_CONTENT') txt = i18n.t('misc.cross.noContent')
  else txt = i18n.t('filters.all')

  const trigger = (
    <FilterButton
      title={title}
      icon={icon}
      disabled={disabled}
      onRemove={onRemove}
      onApply={onApply}
      dropdown={undefined}
    >
      <Box marginLeft="8px">{txt}</Box>
    </FilterButton>
  )

  return (
    <FilterBarItem
      {...barItemProps}
      data={data}
      onChange={onChange}
      onApply={onApply}
      disabled={disabled}
      trigger={trigger}
    >
      <Col width={150} cursor="default" gap="5px" fontWeight="500">
        <Checkbox
          onClick={() => onChange('MATCH')}
          checked={data === 'MATCH'}
          label={{
            children: (
              <Box>
                <Icon name="check circle" style={{color: colors.positive}} />
                <span>{i18n.t('misc.cross.match')}</span>
              </Box>
            ),
          }}
          className="checkbox-element"
        />
        <Checkbox
          onClick={() => onChange('CONFLICT')}
          checked={data === 'CONFLICT'}
          label={{
            children: (
              <Box>
                <Icon name="remove circle" style={{color: colors.negative}} />
                <span>{i18n.t('misc.cross.conflict')}</span>
              </Box>
            ),
          }}
          className="checkbox-element"
        />
        <Checkbox
          onClick={() => onChange('NO_CONTENT')}
          checked={data === 'NO_CONTENT'}
          label={{
            children: (
              <Box>
                <Icon name="minus circle" style={{color: colors.darkLight}} />
                <span>{i18n.t('misc.cross.noContent')}</span>
              </Box>
            ),
          }}
          className="checkbox-element"
        />
      </Col>
    </FilterBarItem>
  )
}

export default MatchConflictNoContent
