import React from 'react'

import {Box, Row} from 'jsxstyle'
import {Dropdown, Icon} from 'semantic-ui-react'

import colors from '../../colors'
import i18n from '../../i18n'
import theme from '../../theme'
import PoppersPopup from './PoppersPopup'

const disabledTheme = theme.createClassName('disabled', {
  fontWeight: 'bold',
  textTransform: 'uppercase',
  backgroundColor: '#f2f2f2',
})

const disabledOption = theme.createClassName('disabled', {
  backgroundColor: '#f2f2f2',
  fontWeight: '300',
})

const categoriesTheme = theme.createClassName('categories', {
  display: 'flex',
  justifyContent: 'space-between',
  ':hover': {
    color: colors.primary.rgba,
    backgroundColor: `${colors.primaryLight.rgba}`,
  },
})

const valueToIcon = new Map([
  ['amazonSections', 'amazon'],
  ['amazonBrands', 'amazon'],
  ['customAttributes', 'fas fa-layer-group'],
  ['asins', 'barcode'],
  ['asins_marketplaces', 'barcode'],
  ['countries', 'globe'],
  ['tags', 'tags'],
  ['fulfillment_channels', 'truck'],
  ['skus', 'barcode'],
])

interface Option {
  key: string
  text: string
  value: string
  disabled: boolean
}

interface GroupingDropdownProps {
  loading?: boolean
  onChange: (key: string) => void
  options: Option[]
  pointing?: string | boolean
  value: string
  disabledPopup?: string | null
}

function GroupingDropdown({
  loading = false,
  options,
  onChange,
  pointing = '',
  value,
  disabledPopup = null,
}: GroupingDropdownProps) {
  const trigger = (
    <Row padding="8px" height="35px" alignItems="center" className={theme.primaryButton}>
      <Box display="flex" alignItems="center">
        <Icon className="fas fa-layer-group" style={{transform: 'translateY(-1px)', marginRight: '5px'}} />
        {options.find(option => option.key.toLowerCase() === value.toLowerCase())
          ? options.find(option => option.key.toLowerCase() === value.toLowerCase())?.text
          : value}
      </Box>
      <Box marginLeft="6px" className={theme.growing}>
        <Icon name="dropdown" />
      </Box>
    </Row>
  )

  const content = disabledPopup ? (
    <p style={{lineHeight: 'normal'}}>
      {i18n.t('visualization.grouping.breakdown')}
      <br />
      <span style={{fontSize: '11px', color: 'grey'}}>
        <Icon name="exclamation circle" color="grey" />
        {disabledPopup}
      </span>
    </p>
  ) : (
    i18n.t('visualization.grouping.breakdown')
  )

  const getTheme = (disabled: boolean) => {
    if (disabled) {
      if (disabledPopup) {
        return disabledOption
      } else return disabledTheme
    }
    return categoriesTheme
  }

  return (
    <PoppersPopup
      position="top center"
      content={content}
      trigger={
        <div>
          <Dropdown trigger={trigger} icon={null} scrolling upward={false} value={value} loading={loading}>
            <Dropdown.Menu pointing={pointing} style={{marginTop: '8px'}}>
              {options.map((option, index) => (
                <Dropdown.Item
                  active={option.value === value}
                  className={getTheme(option.disabled)}
                  disabled={option.disabled}
                  key={option.value}
                  onClick={() => !option.disabled && onChange(options[index].key)}
                  value={option.value}
                >
                  {option.disabled && !disabledPopup && Array.from(valueToIcon.keys()).includes(option.value) && (
                    <Icon className={valueToIcon.get(option.value)} />
                  )}
                  <span>{option.text}</span>
                  {!option.disabled && Array.from(valueToIcon.keys()).includes(option.value) && (
                    <Icon color={option.value !== value ? 'grey' : 'black'} className={valueToIcon.get(option.value)} />
                  )}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      }
    />
  )
}

export default GroupingDropdown
