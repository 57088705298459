import React from 'react'

import {Switch} from 'antd'
import {Box, Row} from 'jsxstyle'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Divider, Icon} from 'semantic-ui-react'
import styled, {css, keyframes} from 'styled-components'

import colors from '../../../../colors'
import i18n from '../../../../i18n'
import PopOver from '../../PopOver'
import PoppersPopup from '../../PoppersPopup'
import SeelkButton from '../../SeelkUIKit/SeelkButton'
import Choose from '../Choose'
import {/* COMPARISON_PRESETS, DEFAULT_PRESETS, */ MODES} from './constants'
// import DatePresetsSelector from './DatePresetsSelector'
import DateRangeInput from './DateRangeInput'
import DateRangePickerPanel from './DateRangePickerPanel'

const scalingUp = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
`

const StyledSeelkButton = styled(SeelkButton)`
  animation: ${props =>
    props.disabled
      ? 'none'
      : css`
          ${scalingUp} 1s both infinite
        `};
`

const StyledRow = styled(Row)`
  align-items: center;
  height: 10px;
  position: absolute;
  background-color: ${colors.white.rgba};
  margin-left: 4px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 42;
`

const StyledP = styled.p`
  color: ${colors.negative.rgba};
  cursor: pointer;
  font-size: 10px;
  :hover {
    text-decoration: underline;
  }
`

export class ComparisonDatePicker extends React.Component {
  static propTypes = {
    A: PropTypes.shape({
      startDate: PropTypes.object,
      endDate: PropTypes.object,
      selectedPreset: PropTypes.string,
    }).isRequired,
    B: PropTypes.shape({
      startDate: PropTypes.object,
      endDate: PropTypes.object,
      selectedPreset: PropTypes.string,
    }).isRequired,
    availablePeriods: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string.isRequired,
    comparisonEnabled: PropTypes.bool,
    dateRender: PropTypes.func,
    disabled: PropTypes.bool,
    disabledDate: PropTypes.func,
    disabledPeriods: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    period: PropTypes.oneOf(MODES),
    popOverPosition: PropTypes.string,
    prefixCls: PropTypes.string,
  }

  static defaultProps = {
    availablePeriods: MODES,
    comparisonEnabled: true,
    dateRender: undefined,
    disabled: false,
    disabledDate: undefined,
    disabledPeriods: null,
    period: 'day',
    popOverPosition: 'right',
    prefixCls: 'datePicker',
  }

  constructor(props) {
    super(props)
    this.state = {
      A: {
        startDate: props.A.startDate,
        endDate: props.A.endDate,
        selectMode: 'start',
        selectedPreset: props.A.selectedPreset ?? 'custom',
      },
      B: {
        startDate: props.B.startDate,
        endDate: props.B.endDate,
        selectMode: 'start',
        selectedPreset: props.B.startDate || props.B.endDate ? 'custom' : 'prevPeriod',
      },
      mode: props.period,
      comparisonMode: !!(props.B.startDate || props.B.endDate),
      open: !!props.open,
    }
  }

  handleComparisonModeChange = () => {
    if (this.state.comparisonMode) {
      this.setState({
        comparisonMode: false,
        B: {startDate: null, endDate: null, selectedPreset: this.state.B.selectedPreset, selectMode: null},
      })
    } else {
      this.setState({comparisonMode: true})
    }
  }

  handleChangeSelectMode = key => selectMode => {
    this.setState({
      [key]: {...this.state[key], selectMode},
    })
  }

  handleSwitchPeriodsClick = () => {
    this.setState({
      A: {
        startDate: this.state.B.startDate,
        endDate: this.state.B.endDate,
        selectMode: 'start',
        selectedPreset: 'custom',
      },
      B: {
        startDate: this.state.A.startDate,
        endDate: this.state.A.endDate,
        selectMode: 'start',
        selectedPreset: 'custom',
      },
    })
  }

  handleApplyClick = () => {
    this.props.onChange({
      A: {
        startDate: this.state.A.startDate,
        endDate: this.state.A.endDate,
        preset: this.state.A.selectedPreset,
      },
      B: {
        startDate: this.state.B.startDate,
        endDate: this.state.B.endDate,
        preset: this.state.B.selectedPreset,
      },
      period: this.state.mode,
    })
    this.setState({open: false})
  }

  handleChangeMode = mode => {
    this.setState({
      mode,
      A: {
        startDate: this.state.A.startDate.clone().startOf(mode),
        endDate: this.state.A.endDate.clone().endOf(mode),
        selectedPreset: 'custom',
        selectMode: this.state.A.selectMode,
      },
      B: {
        startDate: this.state.B.startDate ? this.state.B.startDate.clone().startOf(mode) : this.state.B.startDate,
        endDate: this.state.B.endDate ? this.state.B.endDate.clone().endOf(mode) : this.state.B.endDate,
        selectedPreset: this.state.B.selectedPreset,
        selectMode: this.state.B.selectMode,
      },
    })
  }

  handlePresetChange =
    key =>
    (selectedPreset, [startDate, endDate]) => {
      this.setState({[key]: {startDate, endDate, selectedPreset, selectMode: 'start'}})
    }

  handleChange =
    (switchSelectMode, key) =>
    ([startDate, endDate]) => {
      if (startDate.isBefore(endDate)) {
        if (switchSelectMode)
          this.setState({
            [key]: {
              startDate,
              endDate,
              selectedPreset: 'custom',
              selectMode: this.state[key].selectMode === 'start' ? 'end' : 'start',
            },
          })
        else this.setState({[key]: {...this.state[key], startDate, endDate, selectedPreset: 'custom'}})
      } else {
        this.setState({
          [key]: {
            ...this.state[key],
            startDate: endDate.startOf(this.state.mode),
            endDate: startDate.endOf(this.state.mode),
            selectedPreset: 'custom',
          },
        })
      }
    }

  renderInput = key => (
    <div className={`${this.props.prefixCls}${key}InputContainer`} disabled={this.props.disabled}>
      {this.state.comparisonMode && <div className={`${this.props.prefixCls}${key}Label`}>{key}</div>}
      <DateRangeInput
        prefixCls={`${this.props.prefixCls}${key}`}
        disabled={this.props.disabled}
        disabledDate={this.props.disabledDate}
        startDate={this.state[key].startDate || moment.utc()}
        endDate={this.state[key].endDate || moment.utc()}
        selectMode={this.state[key].selectMode}
        mode={this.state.mode}
        onChange={this.handleChange(false, key)}
        onChangeMode={this.handleChangeMode}
        onChangeSelectMode={this.handleChangeSelectMode(key)}
      />
    </div>
  )

  render() {
    const {availablePeriods, comparisonEnabled, dateRender, disabledDate, disabledPeriods, prefixCls, popOverPosition} =
      this.props
    const {mode, comparisonMode, open} = this.state

    const withApplyButtonClickable =
      this.props.period !== this.state.mode ||
      !moment.utc(this.props.A.startDate).isSame(moment.utc(this.state.A.startDate), mode) ||
      !moment.utc(this.props.A.endDate).isSame(moment.utc(this.state.A.endDate), mode) ||
      ((!moment.utc(this.props.B.startDate).isSame(moment.utc(this.state.B.startDate), mode) ||
        !moment.utc(this.props.B.endDate).isSame(moment.utc(this.state.B.endDate), mode)) &&
        comparisonMode) ||
      ((!!this.props.B.startDate !== !!this.state.B.startDate || !!this.props.B.endDate !== !!this.state.B.endDate) &&
        !comparisonMode)

    return (
      <div className={`${this.props.className} ${this.state.open ? 'opened' : 'closed'}`}>
        <PopOver
          dimBackground
          disabled={this.props.disabled}
          clickToClose={false}
          onOpen={() => this.setState({open: true})}
          onClose={() => this.setState({open: false})}
          triggered={open}
          popOverPosition={popOverPosition}
          className={`${prefixCls}TriggerBox`}
          trigger={
            <React.Fragment>
              {this.renderInput('A')}
              {comparisonMode && (
                <React.Fragment>
                  <Box marginRight={10} marginLeft={10} color="black">
                    vs
                  </Box>
                  {this.renderInput('B')}
                </React.Fragment>
              )}
            </React.Fragment>
          }
        >
          <div className={`${prefixCls}Box`}>
            <div className={`${prefixCls}Widgets`}>
              {comparisonEnabled ? (
                <PoppersPopup
                  trigger={
                    <Switch
                      checked={comparisonMode}
                      checkedChildren={i18n.t('actions.global.vs')}
                      onChange={this.handleComparisonModeChange}
                      style={{
                        marginLeft: '12px',
                        marginTop: '10px',
                        backgroundColor: comparisonMode && colors.primary.rgba,
                      }}
                      unCheckedChildren={i18n.t('actions.global.vs')}
                    />
                  }
                  content={i18n.t('actions.global.compareVS')}
                  position="top center"
                />
              ) : null}
              <Choose
                className={`${prefixCls}ModeSwitcher`}
                comparisonEnabled={comparisonEnabled}
                availableChoices={availablePeriods}
                active={mode}
                disabledChoices={disabledPeriods}
                onChange={this.handleChangeMode}
                size="small"
              />
              <Row justifyContent="space-between" alignItems="center">
                {comparisonMode && (
                  <PoppersPopup
                    trigger={
                      <SeelkButton
                        type="icon"
                        icon="exchange"
                        onClick={this.handleSwitchPeriodsClick}
                        style={{marginRight: 10}}
                      />
                    }
                    content={i18n.t('actions.global.switchPeriods')}
                  />
                )}
                <StyledSeelkButton
                  content={i18n.t('actions.global.apply')}
                  disabled={!withApplyButtonClickable}
                  onClick={this.handleApplyClick}
                  type="confirm"
                />
              </Row>
            </div>
            <Divider className={`${prefixCls}ModeSelectorDivider`} style={{marginBottom: 0}} />
            <div className={`${prefixCls}ABoard`}>
              {/* <div className={`${prefixCls}APresets`}>
                <DatePresetsSelector
                  prefixCls={`${prefixCls}A`}
                  onChange={this.handlePresetChange('A')}
                  selectedPreset={this.state.A.selectedPreset}
                  presets={{custom: () => [this.state.A.startDate, this.state.A.endDate], ...DEFAULT_PRESETS[mode]}}
                />
              </div>
              <div className={`${prefixCls}ASpacer`} /> */}
              <DateRangePickerPanel
                prefixCls={`${prefixCls}A`}
                dateRender={dateRender}
                disabledDate={disabledDate}
                onChange={this.handleChange(true, 'A')}
                startDate={this.state.A.startDate || moment.utc()}
                endDate={this.state.A.endDate || moment.utc()}
                mode={mode}
                selectMode={this.state.A.selectMode}
              />
            </div>
            {comparisonMode && (
              <div className={`${prefixCls}BBoard`}>
                {/* <div className={`${prefixCls}BPresets`}>
                  <DatePresetsSelector
                    prefixCls={`${prefixCls}B`}
                    onChange={this.handlePresetChange('B')}
                    selectedPreset={this.state.B.selectedPreset}
                    relativeStartDate={this.state.A.startDate}
                    relativeEndDate={this.state.A.endDate}
                    mode={mode}
                    presets={{
                      custom: () => [this.state.B.startDate, this.state.B.endDate],
                      ...COMPARISON_PRESETS[mode],
                    }}
                  />
                </div>
                <div className={`${prefixCls}BSpacer`} /> */}
                <DateRangePickerPanel
                  prefixCls={`${prefixCls}B`}
                  dateRender={dateRender}
                  disabledDate={disabledDate}
                  onChange={this.handleChange(true, 'B')}
                  startDate={this.state.B.startDate || moment.utc()}
                  endDate={this.state.B.endDate || moment.utc()}
                  mode={mode}
                  selectMode={this.state.B.selectMode}
                />
              </div>
            )}
          </div>
        </PopOver>
        {!this.state.open && withApplyButtonClickable ? (
          <StyledRow props={{onClick: this.handleApplyClick}}>
            <Icon color="red" name="warning circle" size="small" />
            <StyledP>{i18n.t('placeholders.errors.dateRangeNotSet')}</StyledP>
          </StyledRow>
        ) : null}
      </div>
    )
  }
}

export default styled(ComparisonDatePicker)`
  /* prettier-ignore */

  && .${props => props.prefixCls || 'datePicker'}TriggerBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-weight: 500;
  }

  && .${props => props.prefixCls || 'datePicker'}Box {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${colors.white.rgba};
    border: 1px solid ${colors.border.rgba};
    border-radius: 4px;
    margin-top: 12px;
    min-width: 450px;
  }
  
  && .${props => props.prefixCls || 'datePicker'}AInputContainer,
  && .${props => props.prefixCls || 'datePicker'}BInputContainer {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    padding: 8px;
    transition: border 0.1s ease, color 0.1s ease, box-shadow 0.1s ease;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }

  && .${props => props.prefixCls || 'datePicker'}AInputContainer {
    border: ${props => `solid 1px ${props.disabled ? colors.border.rgba : colors.periodAAccent.rgba}`};
  }

  && .${props => props.prefixCls || 'datePicker'}BInputContainer {
    border: ${props => `solid 1px ${props.disabled ? colors.border.rgba : colors.periodBAccent.rgba}`};
  }
    
  && .${props => props.prefixCls || 'datePicker'}ALabel,
  && .${props => props.prefixCls || 'datePicker'}BLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding-left: 6px;
    padding-right: 6px;
  }   
   
  && .${props => props.prefixCls || 'datePicker'}ALabel {
    background-color: ${colors.periodABackground.rgba};
    color: ${colors.periodAAccent.rgba};
  }
  
  && .${props => props.prefixCls || 'datePicker'}BLabel {
    background-color: ${colors.periodBBackground.rgba};
    color: ${colors.periodBAccent.rgba};
  }
  
  && .${props => props.prefixCls || 'datePicker'}Input > input,
  && .${props => props.prefixCls || 'datePicker'}AInput > input,
  && .${props => props.prefixCls || 'datePicker'}BInput > input {
    background: none;
    border: none;
    width: 90px;
    height: 20px;
    padding: 0px;
    margin: 0 5px;
    text-align: center;
  }
  
  && .${props => props.prefixCls || 'datePicker'}InputStart,
  && .${props => props.prefixCls || 'datePicker'}AInputStart,
  && .${props => props.prefixCls || 'datePicker'}BInputStart {
    &::after {
      content: "—";
    }
  }


  &&.opened .${props => props.prefixCls || 'datePicker'}AInputSelected input {
    background-color: ${colors.periodA.rgba};
    color: ${colors.white.rgba};
  }

  &&.opened .${props => props.prefixCls || 'datePicker'}BInputSelected input {
    background-color: ${colors.periodB.rgba};
    color: ${colors.white.rgba};
  }
  

  & .${props => props.prefixCls || 'datePicker'}Widgets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    margin-top: 10px;
    margin-right: 12px;
  }
  
  & .${props => props.prefixCls || 'datePicker'}Widgets > * {
    flex: 0;
  }
  
  & .${props => props.prefixCls || 'datePicker'}ModeSwitcher {
    margin-left: ${props => (props.comparisonEnabled ? 0 : '12px')} !important;
  }

  & .${props => props.prefixCls || 'datePicker'}ModeSwitcher > a.item > span::after {
    content: "s";
  }

  & .${props => props.prefixCls || 'datePicker'}Board,
  & .${props => props.prefixCls || 'datePicker'}ABoard,
  & .${props => props.prefixCls || 'datePicker'}BBoard {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 10px;
    margin: 8px;
    border-radius: 4px;
    border: 1px solid ${colors.border.rgba};
  }
  
  & .${props => props.prefixCls || 'datePicker'}ABoard {
    border-top: 2px solid ${colors.periodAAccent.rgba};
  }
  
  & .${props => props.prefixCls || 'datePicker'}BBoard {
    border-top: 2px solid ${colors.periodBAccent.rgba};
    margin-top: 0;
  }
  
  & .${props => props.prefixCls || 'datePicker'}Presets,
  & .${props => props.prefixCls || 'datePicker'}APresets,
  & .${props => props.prefixCls || 'datePicker'}BPresets {
    position: relative;
    flex: 1 1 300px;
    min-width: 120px;
  }

  & .${props => props.prefixCls || 'datePicker'}PresetsList,
  & .${props => props.prefixCls || 'datePicker'}APresetsList,
  & .${props => props.prefixCls || 'datePicker'}BPresetsList {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
    margin: 0;
    flex: 1 1 300px;
    & > .item.item.item {
      color: ${colors.dark.rgba};
    }
  }

  & .${props => props.prefixCls || 'datePicker'}ASpacer,
  & .${props => props.prefixCls || 'datePicker'}BSpacer {
    border: 1px solid ${colors.border.rgba};
    margin: 0 5px;
  }
    
  & .${props => props.prefixCls || 'datePicker'}Range,
  & .${props => props.prefixCls || 'datePicker'}ARange,
  & .${props => props.prefixCls || 'datePicker'}BRange {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    justify-content: space-around;
  }
      
  & .${props => props.prefixCls || 'datePicker'}Panel,
  & .${props => props.prefixCls || 'datePicker'}APanel,
  & .${props => props.prefixCls || 'datePicker'}BPanel {
    position: relative;
    padding: 8px;
  }
  
  & .${props => props.prefixCls || 'datePicker'}Header,
  & .${props => props.prefixCls || 'datePicker'}AHeader,
  & .${props => props.prefixCls || 'datePicker'}BHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & .${props => props.prefixCls || 'datePicker'}PrevButton,
  & .${props => props.prefixCls || 'datePicker'}APrevButton,
  & .${props => props.prefixCls || 'datePicker'}BPrevButton,
  & .${props => props.prefixCls || 'datePicker'}NextButton,
  & .${props => props.prefixCls || 'datePicker'}ANextButton,
  & .${props => props.prefixCls || 'datePicker'}BNextButton {
    outline: none;
    border: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 26px;
    &:hover {
      cursor: pointer;
      border-color: rgba(0, 0, 0, 0.65);
    }
  }

  & .${props => props.prefixCls || 'datePicker'}PrevButton,
  & .${props => props.prefixCls || 'datePicker'}APrevButton,
  & .${props => props.prefixCls || 'datePicker'}BPrevButton {
    left: 29px;
    &::before {
      content: "‹";
    }
  }

  & .${props => props.prefixCls || 'datePicker'}NextButton,
  & .${props => props.prefixCls || 'datePicker'}ANextButton,
  & .${props => props.prefixCls || 'datePicker'}BNextButton {
    right: 29px;
    &::before {
      content: "›";
    }
  }
  
  & .${props => props.prefixCls || 'datePicker'}YearWidget,
  & .${props => props.prefixCls || 'datePicker'}AYearWidget,
  & .${props => props.prefixCls || 'datePicker'}BYearWidget,
  & .${props => props.prefixCls || 'datePicker'}MonthWidget,
  & .${props => props.prefixCls || 'datePicker'}AMonthWidget,
  & .${props => props.prefixCls || 'datePicker'}BMonthWidget {
    display: inline-block;
    padding: 0 2px;
    outline: none;
    border: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: 500;
    line-height: 29px;
    &:hover {
      cursor: pointer;
      color: ${colors.primary.rgba};
    }
  }
  
  & .${props => props.prefixCls || 'datePicker'}Table,
  & .${props => props.prefixCls || 'datePicker'}ATable,
  & .${props => props.prefixCls || 'datePicker'}BTable {
    width: fit-content;
  }

  & .${props => props.prefixCls || 'datePicker'}Head,
  & .${props => props.prefixCls || 'datePicker'}AHead,
  & .${props => props.prefixCls || 'datePicker'}BHead {
    border-top: 1px solid #e8e8e8;
    padding: 8px 12px;
  }
  
  & .${props => props.prefixCls || 'datePicker'}Body,
  & .${props => props.prefixCls || 'datePicker'}ABody,
  & .${props => props.prefixCls || 'datePicker'}BBody {
    padding: 8px 12px;
  }

  & .${props => props.prefixCls || 'datePicker'}ColumnHeader,
  & .${props => props.prefixCls || 'datePicker'}AColumnHeader,
  & .${props => props.prefixCls || 'datePicker'}BColumnHeader {
    cursor: default;
    line-height: 18px;
    text-align: center;
    padding-top: 10px;
    color: ${colors.darkLight.rgba};
  }
  
  & .${props => props.prefixCls || 'datePicker'}ColumnHeaderInner,
  & .${props => props.prefixCls || 'datePicker'}AColumnHeaderInner,
  & .${props => props.prefixCls || 'datePicker'}BColumnHeaderInner {
    display: block;
    font-weight: normal;
  }
  
  & .dayTable .${props => props.prefixCls || 'datePicker'}Cell,
  & .dayTable .${props => props.prefixCls || 'datePicker'}ACell,
  & .dayTable .${props => props.prefixCls || 'datePicker'}BCell {
    &.weekCell > div {
      color: ${colors.dark.rgba};
      font-style: italic;
      font-size: 13px;
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
  }
  
  & .weekTable .${props => props.prefixCls || 'datePicker'}Cell,
  & .weekTable .${props => props.prefixCls || 'datePicker'}ACell,
  & .weekTable .${props => props.prefixCls || 'datePicker'}BCell {
    &.dayCell {
      color: ${colors.dark.rgba};
      &.isNow > div {
        border: none;
      }
      &.isHover,
      &.isSelected,
      &.isExtremity {
        color: ${colors.white.rgba};
      }
      &.isInHoverRange.isInHoverRange {
        color: ${colors.dark.rgba};
      }
      &.isOutside {
        opacity: 0.5;
      }
    }

    &.weekCell {
      font-weight: 500;
      font-size: 16px;
    }
  }

  & .${props => props.prefixCls || 'datePicker'}Cell,
  & .${props => props.prefixCls || 'datePicker'}ACell,
  & .${props => props.prefixCls || 'datePicker'}BCell {
    &:hover {
      cursor: pointer;
    }
    
    & > div {
      text-align: center;
    }
    
    &.yearCell {
      & > div {
        width: 50px;
        height: 40px;
        line-height: 40px;
      }
    }
    
    &.monthCell {
      & > div {
        width: 50px;
        height: 40px;
        line-height: 40px;
      }
    }
    
    &.weekCell {
      & > div {
        width: 30px;
        height: 30px;
        line-height: 30px;
      }
    }
    
    &.dayCell {
      & > div {
        width: 24px;
        height: 24px;
        line-height: 24px;
      }
    }
    
    &.isNow {
      & > div {
        border: solid 1px ${colors.primaryAccent.rgba};
      }
      color: ${colors.primaryAccent.rgba};
    }
    
    &.isDisabled {
      color: ${colors.black.alpha(0.25)}
      background: ${colors.lightDark.rgba};
      border: 1px solid transparent;
      cursor: not-allowed;
    }

    &.isOutside {
      opacity: 0.3;
    }

    &.isExtremity,
    &.isSelected {
      opacity: 1;
      font-weight: 500;
      color: ${colors.white.rgba};
    }

    &.isInRange {
      color: ${colors.primaryDark.rgba}
    }

    &.isHover {
      opacity: 0.7;
      color: ${colors.white.rgba};
    }

    &.isInHoverRange.isInHoverRange {
      color: ${colors.primaryDark.rgba}
    }
  }

  & .${props => props.prefixCls || 'datePicker'}ACell {
    &.isExtremity,
    &.isSelected {
      background-color: ${colors.periodAAccent.rgba};
    }

    &.isInRange {
      background-color: ${colors.periodABackground.rgba};
    }

    &.isHover {
      background-color: ${colors.periodAAccent.rgba};
    }

    &.isInHoverRange.isInHoverRange {
      background-color: ${colors.periodAAccent.alpha(0.1)};
    }
  }

  & .${props => props.prefixCls || 'datePicker'}BCell {
    &.isExtremity,
    &.isSelected {
      background-color: ${colors.periodBAccent.rgba};
    }

    &.isInRange {
      background-color: ${colors.periodBBackground.rgba};
    }

    &.isHover {
      background-color: ${colors.periodBAccent.rgba};
    }

    &.isInHoverRange.isInHoverRange {
      background-color: ${colors.periodBAccent.alpha(0.1)};
    }
  }
`
