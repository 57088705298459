import {easyFetch, jsonFetch} from 'easyfetch'
import JSONbig from 'json-bigint'
import Cookies from 'universal-cookie'

import config from '../../../../config'
import KaioApiClient from '../KaioApiClient/KaioApiClient'

const ALL = 9999999999
const SENZU = `https://${config.senzuDomain}`
const SENZU_API = `https://${config.senzuDomain}/api/v1`

const DIMENSION_URL = {
  COLLECTION: {
    OFFER: 'offer_collection',
    CAMPAIGN: 'campaign_collection',
    AD_GROUP: 'ad_group_collection',
    ADVERTISER: 'advertiser_collection',
    ORDER: 'order_collection',
    LINE_ITEM: 'line_item_collection',
    CREATIVE: 'creative_collection',
  },
  GROUP: {
    OFFER: 'offer_group',
    CAMPAIGN: 'campaign_group',
    AD_GROUP: 'ad_group_group',
    ADVERTISER: 'advertiser_group',
    ORDER: 'order_group',
    LINE_ITEM: 'line_item_group',
    CREATIVE: 'creative_group',
  },
  TAG: {
    OFFER: 'offer_tag',
    KEYWORD: 'keyword_tag',
    CAMPAIGN: 'campaign_tag',
    AD_GROUP: 'ad_group_tag',
    ADVERTISER: 'advertiser_tag',
    ORDER: 'order_tag',
    LINE_ITEM: 'line_item_tag',
    CREATIVE: 'creative_tag',
  },
}

const DIMENSION_PARAM = {
  INCLUDE_COUNT: {
    OFFER: 'include_offer_count',
    KEYWORD: 'include_keyword_count',
    CAMPAIGN: 'include_campaign_count',
    AD_GROUP: 'include_ad_group_count',
    ADVERTISER: 'include_advertiser_count',
    ORDER: 'include_order_count',
    LINE_ITEM: 'include_line_item_count',
    CREATIVE: 'include_creative_count',
  },
  ITEMS: {
    OFFER: 'offers',
    KEYWORD: 'keywords',
    CAMPAIGN: 'campaigns',
    AD_GROUP: 'ad_groups',
    ADVERTISER: 'advertisers',
    ORDER: 'orders',
    LINE_ITEM: 'line_items',
    CREATIVE: 'creatives',
  },
  ITEMS_DATA: {
    OFFER: 'offers_data',
    KEYWORD: 'keywords_data',
    CAMPAIGN: 'campaigns_data',
    AD_GROUP: 'ad_groups_data',
    ADVERTISER: 'advertisers_data',
    ORDER: 'orders_data',
    LINE_ITEM: 'line_items_data',
    CREATIVE: 'creatives_data',
  },
}

export default class SenzuApiClient {
  constructor() {
    if (!SenzuApiClient.instance) {
      SenzuApiClient.instance = this
    }
    return SenzuApiClient.instance
  }

  async request(rType, url, useTenant = true, json = false) {
    const token = await new KaioApiClient().getToken()
    const user = await new KaioApiClient().getUserFromToken()
    let tenant = user.client
    if (user.account.toLowerCase() === 'seelk') tenant = new Cookies().get('account')
    const req = rType(`${SENZU_API}/${url}`)
    const headers = {Authorization: `Bearer ${token}`, 'X-Seelk-Tenant': useTenant ? tenant : 'public'}
    if (json) headers['Content-Type'] = 'application/json'
    req.setHeaders(headers)
    return req
  }

  async JSONBigRequest(rType, url, useTenant = true) {
    const token = await new KaioApiClient().getToken()
    const user = await new KaioApiClient().getUserFromToken()
    let tenant = user.client
    if (user.account.toLowerCase() === 'seelk') tenant = new Cookies().get('account')
    const req = rType(`${SENZU_API}/${url}`)
    const headers = {
      Authorization: `Bearer ${token}`,
      'X-Seelk-Tenant': useTenant ? tenant : 'public',
      'Content-Type': 'application/json',
    }
    req.setHeaders(headers)
    return req
  }

  async checkLive() {
    try {
      await jsonFetch(`${SENZU}/health/`).get()
      return true
    } catch (error) {
      return error.code !== 502
    }
  }

  async getMarketplaces() {
    return (await this.request(jsonFetch, 'marketplace/', false)).get()
  }

  async getTenant() {
    const user = await new KaioApiClient().getUserFromToken()
    let tenant = user.client
    if (user.account.toLowerCase() === 'seelk') tenant = new Cookies().get('account') ?? 'seelk'
    return (await this.request(jsonFetch, `account/${tenant}/`, false)).get()
  }

  async setCurrencyTenant(currency) {
    const user = await new KaioApiClient().getUserFromToken()
    let tenant = user.client
    if (user.account.toLowerCase() === 'seelk') tenant = new Cookies().get('account') ?? 'seelk'
    return (await this.request(jsonFetch, `account/${tenant}/`, false)).patch(currency)
  }

  /* ********** OFFERS ********** */

  async getOffers(sections) {
    return (await this.request(jsonFetch, 'offer/'))
      .setQueryParams({
        section: sections,
        limit: ALL,
      })
      .get()
  }

  async getOfferSettings() {
    return (await this.request(jsonFetch, 'offer/'))
      .setQueryParams({include_tags: 1, include_groups: 1, limit: ALL})
      .get()
  }

  async updateOffersSettings(offers) {
    return (await this.request(jsonFetch, 'offer/bulk_update/')).post(offers)
  }

  /* ********** KEYWORDS ********** */

  async createKeyword(keyword) {
    return (await this.request(jsonFetch, 'keyword/')).post(keyword)
  }

  async updateKeywords(keywords) {
    return (await this.request(jsonFetch, 'keyword/bulk_update/')).post(keywords)
  }

  async deleteKeywords(keywords) {
    return (await this.request(jsonFetch, 'keyword/bulk_delete/')).delete(keywords)
  }

  async getKeywords() {
    return (await this.request(jsonFetch, 'keyword/'))
      .setQueryParams({include_tags: 1, include_groups: 1, limit: ALL})
      .get()
  }

  /* ********** KEYWORD TAGS ********** */

  async getKeywordTags(count = false) {
    return (await this.request(jsonFetch, `tagging/keyword_tag/`)).setQueryParams({include_keyword_count: count}).get()
  }

  async createKeywordTag(tag) {
    return (await this.request(jsonFetch, 'tagging/keyword_tag/')).post(tag)
  }

  async addKeywordTags({keywords, tags}) {
    return (await this.request(jsonFetch, 'tagging/keyword_tag/tag/')).post({keywords, tags})
  }

  async untagKeywords({keywords, tags}) {
    return (await this.request(jsonFetch, 'tagging/keyword_tag/untag/')).delete({keywords, tags})
  }

  /* ********** PROFILE  ********** */

  async getProfiles() {
    return (await this.request(easyFetch, 'ads/profile/'))
      .setQueryParams({limit: ALL})
      .get()
      .then(resp => resp.text())
      .then(body => JSONbig({storeAsString: true}).parse(body))
  }

  /* ********** CAMPAIGN  ********** */

  async getCampaigns() {
    return (await this.request(easyFetch, 'ads/campaign/'))
      .setQueryParams({limit: ALL})
      .get()
      .then(resp => resp.text())
      .then(body => JSONbig({storeAsString: true}).parse(body))
  }

  async createCampaign(campaign) {
    return (await this.request(jsonFetch, 'ads/campaign/')).post(campaign)
  }

  /* ********** AD GROUP  ********** */

  async getAdGroups() {
    return (await this.request(easyFetch, 'ads/ad_group/'))
      .setQueryParams({limit: ALL})
      .get()
      .then(resp => resp.text())
      .then(body => JSONbig({storeAsString: true}).parse(body))
  }

  async createAdGroup(adGroup) {
    return (await this.request(jsonFetch, 'ads/ad_group/')).post(adGroup)
  }

  /* ********** DSP ********** */

  async getAdvertisers() {
    return (await this.request(easyFetch, 'dsp/advertiser/'))
      .setQueryParams({limit: ALL})
      .get()
      .then(resp => resp.text())
      .then(body => JSONbig({storeAsString: true}).parse(body))
  }

  async getOrders() {
    return (await this.request(easyFetch, 'dsp/order/'))
      .setQueryParams({limit: ALL})
      .get()
      .then(resp => resp.text())
      .then(body => JSONbig({storeAsString: true}).parse(body))
  }

  async getLineItems() {
    return (await this.request(easyFetch, 'dsp/line_item/'))
      .setQueryParams({limit: ALL})
      .get()
      .then(resp => resp.text())
      .then(body => JSONbig({storeAsString: true}).parse(body))
  }

  async getCreatives() {
    return (await this.request(easyFetch, 'dsp/creative/'))
      .setQueryParams({limit: ALL})
      .get()
      .then(resp => resp.text())
      .then(body => JSONbig({storeAsString: true}).parse(body))
  }

  /* ********** TAGS ********** */

  async getTags(dimension, count = false) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.TAG[dimension]}/`))
      .setQueryParams({[DIMENSION_PARAM.INCLUDE_COUNT[dimension]]: count})
      .get()
  }

  async createTag(dimension, tag) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.TAG[dimension]}/`)).post(tag)
  }

  async updateTag({dimension, tag, slug}) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.TAG[dimension]}/${slug}/`)).patch(tag)
  }

  async deleteTags({dimension, tags}) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.TAG[dimension]}/bulk_delete/`)).delete({tags})
  }

  async getRecentlyUsedTags({dimension, maxTags = 5}) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.TAG[dimension]}/recent_tags/`))
      .setQueryParams({limit: maxTags})
      .get()
  }

  async untagItems({dimension, items, tags}) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.TAG[dimension]}/untag/`)).delete({
      [DIMENSION_PARAM.ITEMS[dimension]]: items,
      tags,
    })
  }

  async tagItems({dimension, items, tags}) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.TAG[dimension]}/tag/`)).post({
      [DIMENSION_PARAM.ITEMS[dimension]]: items,
      tags,
    })
  }

  async getTaggedList(dimension) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.TAG[dimension]}/list_pivots/`)).get()
  }

  /* ********** ATTRIBUTES ********** */

  async getCollections(dimension, count = false) {
    return (
      await this.request(
        jsonFetch,
        `tagging/${DIMENSION_URL.COLLECTION[dimension]}/?${DIMENSION_PARAM.INCLUDE_COUNT[dimension]}=${count}`
      )
    ).get()
  }

  async getGroups(dimension, count = false) {
    return (
      await this.request(
        jsonFetch,
        `tagging/${DIMENSION_URL.GROUP[dimension]}/?${DIMENSION_PARAM.INCLUDE_COUNT[dimension]}=${count}`
      )
    ).get()
  }

  async createCollection(dimension, collection) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.COLLECTION[dimension]}/`)).post(collection)
  }

  async addGroupToCollection({dimension, collection, value}) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.GROUP[dimension]}/`)).post({collection, value})
  }

  async assignItemsToGroup({dimension, groupId, itemIds}) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.GROUP[dimension]}/${groupId}/assign/`)).post({
      [DIMENSION_PARAM.ITEMS_DATA[dimension]]: itemIds,
    })
  }

  async downloadCollectionsTemplate({dimension, collection = []}) {
    return (await this.request(easyFetch, `tagging/${DIMENSION_URL.COLLECTION[dimension]}/download_template/`))
      .setQueryParams({collection})
      .get()
      .then(res => res.blob())
  }

  async updateCollection({dimension, name, description, oldSlug}) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.COLLECTION[dimension]}/${oldSlug}/`)).patch({
      name,
      description,
    })
  }

  async deleteCollections({dimension, slugs}) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.COLLECTION[dimension]}/bulk_delete/`)).delete({
      collection_slugs: slugs,
    })
  }

  async updateGroup({dimension, groupId, values}) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.GROUP[dimension]}/${groupId}/`)).patch(values)
  }

  async deleteGroups({dimension, ids}) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.GROUP[dimension]}/bulk_delete/`)).delete({
      group_ids: ids,
    })
  }

  async getAttributedList(dimension) {
    return (await this.request(jsonFetch, `tagging/${DIMENSION_URL.GROUP[dimension]}/list_pivots/`)).get()
  }

  async uploadCollectionsTemplate(dimension, template) {
    return (
      await this.JSONBigRequest(easyFetch, `tagging/${DIMENSION_URL.COLLECTION[dimension]}/load_template_data/`)
    ).post(JSONbig.stringify(template))
  }
}
