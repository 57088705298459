import RelieverRegistry from 'react-redux-reliever'
import {createSelector} from 'reselect'

export const accountSettingsModuleState = state => RelieverRegistry.moduleState('account-settings', state)

const isVendorSelector = state => accountSettingsModuleState(state).isVendor

export const currencySelector = state => accountSettingsModuleState(state).getIn(['tenant', 'currency'])

export const dateFormatSelector = state => accountSettingsModuleState(state).getIn(['account', 'dateFormat'])

export const rawAccountTypeSelector = state => accountSettingsModuleState(state).getIn(['account', 'accountType'])
export const accountTypeSelector = createSelector(rawAccountTypeSelector, isVendorSelector, (accountType, isVendor) =>
  // eslint-disable-next-line no-nested-ternary
  accountType !== undefined ? accountType : isVendor == null ? null : isVendor ? 'Vendor' : 'Seller'
)
