import React from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import colors from './colors'
import i18n from './i18n'
import {isPlainObject} from '.'

const UIObjectList = styled.dl`
  margin: 0;
`

const UIObjectListItem = styled.dd`
  margin: 0;
`

const UIList = styled.ul`
  margin: 0;
`

const UIListItem = styled.li`
  margin: 0;
`

const ErrorFormatter = ({errors}) => {
  if ((Array.isArray(errors) || isPlainObject(errors)) && _.flatMap(errors, v => v).length > 3) {
    let body
    if (Array.isArray(errors)) body = errors.join('\n')
    else
      body = _(errors)
        .flatMap((v, k) => (Array.isArray(v) ? v.map(dv => `${k}: ${dv}`) : `${k}: ${v}`))
        .flatten()
        .join('\n')
    const blob = new Blob([body], {type: 'text/plain'})
    const url = window.URL.createObjectURL(blob)
    return (
      <a href={url} download="errors.txt" style={{fontWeight: '500', textDecoration: 'underline'}}>
        {i18n.t('misc.cross.tooManyErrorsDownload')}
      </a>
    )
  } else if (Array.isArray(errors))
    return (
      <UIList>
        {errors.map(error => (
          <UIListItem key={error} style={{color: colors.darkLight.rgba}}>
            {error}
          </UIListItem>
        ))}
      </UIList>
    )
  else if (isPlainObject(errors))
    return (
      <UIObjectList>
        {Object.entries(errors).map(([key, value]) => (
          <React.Fragment key={key}>
            <dt>{key}</dt>
            {Array.isArray(value) ? (
              <React.Fragment>
                {value.map(v => (
                  <UIObjectListItem key={v}>{v}</UIObjectListItem>
                ))}
              </React.Fragment>
            ) : (
              <UIObjectListItem>{value}</UIObjectListItem>
            )}
          </React.Fragment>
        ))}
      </UIObjectList>
    )
  return errors
}

ErrorFormatter.propTypes = {
  errors: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default ErrorFormatter
