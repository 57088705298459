import React from 'react'

import {Row} from 'jsxstyle'

import i18n from '../../../../../i18n'
import {FilterButton, VirtualFilterSearchObjects} from '../../../..'
import InNotIn from '../common/InNotIn'

interface MappedListProps {
  bulkChange?: (choices: (number | string)[]) => void
  choices: (number | string)[]
  data: (number | string)[]
  disabled: boolean
  icon?: string | null
  idsMapping?: Record<string | number, string>
  onApply: () => void
  onChange: (changes: (string | number)[]) => void
  onRemove: () => void
  operator?: 'in' | 'not_in'
  placeholder: string
  title: string
  updateOperator?: (operator: 'in' | 'not_in') => void
}

const MappedList = ({
  bulkChange,
  choices,
  data,
  disabled = false,
  icon = null,
  idsMapping = {},
  onApply,
  onChange,
  onRemove,
  operator = 'in',
  placeholder,
  title,
  updateOperator,
  ...barItemProps
}: MappedListProps) => {
  const count = MappedList.count(data)
  const trigger = (
    <FilterButton
      title={title}
      icon={icon}
      disabled={disabled}
      onRemove={onRemove}
      onApply={onApply}
      dropdown={undefined}
    >
      <Row alignItems="center" marginLeft="8px">
        {count && updateOperator ? <InNotIn operator={operator} /> : null}
        {count ? `(${count})` : i18n.t('filters.all')}
      </Row>
    </FilterButton>
  )

  const mappedChoices = choices
    .map(elem => ({
      key: elem,
      value: idsMapping?.[elem] ?? elem,
    }))
    .filter(({value}) => value)
    .sort((lhs, rhs) => lhs.value.toString().localeCompare(rhs.value.toString()))

  return (
    <VirtualFilterSearchObjects
      disabled={disabled}
      onChange={onChange}
      value={data}
      choices={mappedChoices}
      placeholder={placeholder}
      trigger={trigger}
      onApply={onApply}
      operator={operator}
      updateOperator={updateOperator}
      selectAll={bulkChange}
      {...barItemProps}
    />
  )
}

MappedList.count = (data: (number | string)[]) => data.length

export default MappedList
