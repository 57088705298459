import {ofType} from 'redux-observable'
import {skip} from 'rxjs/operators'

/* eslint-disable */
export default function (tracker, tenant) {
  tracker
    .reduxActionStream()
    .pipe(ofType('@@router/LOCATION_CHANGE'), skip(1))
    .subscribe(action => {
      // track('Page Change', {pathname: action.payload.pathname, action_type: 'Navigate', tenant})
    })
}
