import {Reliever} from 'react-redux-reliever'
import {ofType} from 'redux-observable'
import {catchError, map, mapTo, mergeMap, tap} from 'rxjs/operators'

import {capitalize, downloadBlob, unbreakableV3SatanFormattedData} from '../utils'
import {BooApi, KaioApi, MrSatanApi} from '../utils/apis'
import {SCRAPING_FREQUENCY_VALUES} from '../utils/constants'
import {combineToObject, getState} from '../utils/extensions/rx'
import {
  clientsListSelector,
  keywordsSelector,
  mergedClientTrackingInformationsSelector,
  offersSelector,
} from './selectors'

// import {RequestWrapper} from 'requesting/RequestWrapper'

class ClientTrackerReliever extends Reliever {
  ACTION_PREFIX = 'CLIENT-TRACKER'

  getInitialState() {
    return {
      filters: {},
      categories: null,
      keywords: null,
      offers: null,
      editTable: false,
    }
  }

  getClientsTrackingInformationsEpic(action$) {
    return action$.pipe(
      ofType('CLIENT-TRACKER_GET_FREQUENCIES_INFORMATIONS'),
      mergeMap(action =>
        combineToObject({
          categories$: BooApi('fetchCategoriesTrackingInformations'),
          keywords$: BooApi('fetchKeywordsTrackingInformations'),
          offers$: BooApi('fetchOffersTrackingInformations'),
        }).pipe(
          map(({categories, keywords, offers}) => ({
            type: 'CLIENT-TRACKER_GET_FREQUENCIES_INFORMATIONS_SUCCESS',
            payload: {
              categories,
              keywords,
              offers,
            },
          })),
          tap(() => action._reqWrapper.end()),
          catchError(err => action._reqWrapper.fail({error: err}))
        )
      )
    )
  }

  getUsersNumberForAClient = (users, client) =>
    users.filter(({account, groups, email}) => {
      if (client === 'seelk' || client === 'onetoone') return account === 'seelk' || account === 'onetoone'
      return (
        account === client && !email.includes('@jellyfish') && !email.includes('@seelk') && !groups.includes('Seelk')
      )
    }).length

  exportMixpanelDataEpic(action$) {
    return action$.pipe(
      ofType('CLIENT-TRACKER_EXPORT_MIXPANEL_DATA'),
      mergeMap(action =>
        getState().pipe(
          mergeMap(state =>
            KaioApi('fetchActiveUsersList').pipe(
              mergeMap(users => {
                const keywords = keywordsSelector(state)
                const offers = offersSelector(state)
                const clients = clientsListSelector(state)

                const headers = ['tenant', 'UsersNumber', 'ProductsNumber', 'KeywordsNumber']

                const numberReducer = (acc, item) => acc + (item.scraping_frequency !== null ? item.total : 0)

                const formattedData = clients
                  .sort((lhs, rhs) => lhs.localeCompare(rhs))
                  .map(client => {
                    const usersNumber = this.getUsersNumberForAClient(users, client)
                    const productsNumber = offers?.[client]?.reduce(numberReducer, 0) ?? 0
                    const keywordsNumber = keywords?.[client]?.reduce(numberReducer, 0) ?? 0

                    return {
                      tenant: client,
                      UsersNumber: usersNumber,
                      ProductsNumber: productsNumber,
                      KeywordsNumber: keywordsNumber,
                    }
                  })

                return MrSatanApi('generateExcel', {
                  filename: 'Mixpanel Extract.xlsx',
                  sheets: [
                    {
                      name: 'Mixpanel Extract',
                      content: unbreakableV3SatanFormattedData(formattedData, headers),
                      headers,
                    },
                  ],
                }).pipe(
                  tap(blob => downloadBlob(blob, `MixpanelExtract.xlsx`)),
                  mapTo({type: 'CLIENT-TRACKER_EXPORT_MIXPANEL_DATA_SUCCESS'}),
                  tap(() => action._reqWrapper.end()),
                  catchError(err => action._reqWrapper.fail({error: err}))
                )
              })
            )
          )
        )
      )
    )
  }

  exportClientsDataEpic(action$) {
    return action$.pipe(
      ofType('CLIENT-TRACKER_EXPORT_CLIENTS_DATA'),
      mergeMap(action =>
        getState().pipe(
          mergeMap(state =>
            KaioApi('fetchActiveUsersList').pipe(
              mergeMap(users => {
                const mergedData = mergedClientTrackingInformationsSelector(state)

                const getHeadersForAType = type =>
                  Object.values(SCRAPING_FREQUENCY_VALUES).map(frequency => `${capitalize(type)}-${frequency}`)

                const headers = [
                  'Client',
                  'UsersNumber',
                  'ProductsTotal',
                  ...getHeadersForAType('products'),
                  'KeywordsTotal',
                  ...getHeadersForAType('keywords'),
                ]

                const frequencies = Object.keys(SCRAPING_FREQUENCY_VALUES)

                frequencies.pop()

                frequencies.push('0')

                const displayForAType = (data, type) =>
                  frequencies.reduce((acc, frequency) => {
                    acc[`${capitalize(type)}-${SCRAPING_FREQUENCY_VALUES[frequency === '0' ? null : frequency]}`] =
                      data?.[`${type}${frequency}`] ?? 0
                    return acc
                  }, {})

                const formattedData = mergedData.map(data => {
                  const {client} = data

                  const usersNumber = this.getUsersNumberForAClient(users, client)

                  return {
                    Client: client,
                    UsersNumber: usersNumber,
                    ProductsTotal: data?.productsNumber ?? 0,
                    ...displayForAType(data, 'products'),
                    KeywordsTotal: data?.keywordsNumber ?? 0,
                    ...displayForAType(data, 'keywords'),
                  }
                })

                return MrSatanApi('generateExcel', {
                  filename: 'ClientsExtract.xlsx',
                  sheets: [
                    {
                      name: 'Clients Extract',
                      content: unbreakableV3SatanFormattedData(formattedData, headers),
                      headers,
                    },
                  ],
                }).pipe(
                  tap(blob => downloadBlob(blob, `ClientsExtract.xlsx`)),
                  mapTo({type: 'CLIENT-TRACKER_EXPORT_CLIENTS_DATA_SUCCESS'}),
                  tap(() => action._reqWrapper.end()),
                  catchError(err => action._reqWrapper.fail({error: err}))
                )
              })
            )
          )
        )
      )
    )
  }
}

export default ClientTrackerReliever
