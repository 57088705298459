import React from 'react'

import _ from 'lodash'
import moment from 'moment'
import {toast} from 'react-toastify'

import countries from '../../config/countries.json'
import Color from './colors/Color'
import appColors from './colors'
import {AVAILABLE_SCRAPING_FREQUENCIES} from './constants'
import i18n from './i18n'
import MixinBuilder from './mixin-builder'

export function downloadBlob(blob, filename) {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  const url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}

export function countrySort(a, b) {
  const nameA = countries?.[a?.toUpperCase()] ?? ''
  const nameB = countries?.[b?.toUpperCase()] ?? ''
  if (nameA < nameB) return -1
  else if (nameA > nameB) return 1
  return 0
}

export function marketplaceSort(a, b) {
  const countryA = a?.split('_')?.[1]
  const countryB = b?.split('_')?.[1]

  return countrySort(countryA, countryB)
}

export function shallowEqual(a, b) {
  if (Object.keys(a).length !== Object.keys(b).length) return false
  return Object.keys(b).every(key => {
    if (typeof b[key] !== 'function' && typeof b[key] !== 'object' && a[key] !== b[key]) {
      return false
    }
    return true
  })
}

export function getFrequencyColor(frequency) {
  const colors = {
    [AVAILABLE_SCRAPING_FREQUENCIES.NEVER]: appColors.dark.rgba,
    [AVAILABLE_SCRAPING_FREQUENCIES.HOURLY]: appColors.primary.darken(0.3),
    [AVAILABLE_SCRAPING_FREQUENCIES.FOUR_HOURS]: appColors.accent.rgba,
    [AVAILABLE_SCRAPING_FREQUENCIES.DAILY]: appColors.negative.rgba,
    [AVAILABLE_SCRAPING_FREQUENCIES.TWO_DAYS]: appColors.primary.rgba,
    [AVAILABLE_SCRAPING_FREQUENCIES.WEEKLY]: appColors.secondary.rgba,
    [AVAILABLE_SCRAPING_FREQUENCIES.MONTHLY]: appColors.primary.darken(0.2).rgba,
    [AVAILABLE_SCRAPING_FREQUENCIES.ONCE]: appColors.darkLight.rgba,
  }
  return colors[frequency] || appColors.warning.rgba
}

export function getFrequencyTypeKey(frequencyType) {
  return {
    scrapings: 'scraping_frequency',
    images: 'image_check_frequency',
  }[frequencyType]
}

function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item) && item !== null
}

export function isPlainObject(item) {
  return item !== null && typeof item === 'object' && item.constructor === Object
}

export const validNumber = (number, defaultTo = 0) => {
  if (number === Infinity || number === -Infinity || global.isNaN(number)) return defaultTo
  return number
}

export function mergeDeep(target, source) {
  let merged = target
  if (isObject(merged) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isPlainObject(source[key])) {
        if (!merged[key]) merged = {...merged, [key]: {}}
        merged = {...merged, [key]: mergeDeep(merged[key], source[key])}
      } else {
        merged = {...merged, [key]: source[key]}
      }
    })
  } else if (Array.isArray(merged) && Array.isArray(source)) {
    merged = [...merged, ...source]
  }
  return merged
}

// c pa mwa
export function objectListToCsv(objects, keys = null) {
  let i
  let l
  const names = []
  let name
  let value
  let obj
  let row
  let output = ''
  let n
  let nl

  // Initialize default parameters.
  const sDelimiter = '"'
  const cDelimiter = ','

  // c pa mwa
  function toCsvValue(theValue) {
    const t = typeof theValue
    let csvValueOutput

    // eslint-disable-next-line eqeqeq
    if (t === 'undefined' || t === null || theValue == undefined) {
      csvValueOutput = ''
    } else if (t === 'string') {
      csvValueOutput = sDelimiter + theValue.replace(/"/g, '""') + sDelimiter
    } else {
      csvValueOutput = String(theValue)
    }

    return csvValueOutput
  }

  for (i = 0, l = objects.length; i < l; i += 1) {
    // Get the names of the properties.
    obj = objects[i]
    row = ''
    if (i === 0) {
      // Loop through the names
      // eslint-disable-next-line no-loop-func
      Object.keys(obj).forEach(header => {
        if ((keys && keys.includes(header)) || !keys) {
          names.push(header)
          row += header.concat(cDelimiter)
        }
      })
      row = row.substring(0, row.length - 1)
      output += row
    }

    output += '\n'
    row = ''
    for (n = 0, nl = names.length; n < nl; n += 1) {
      name = names[n]
      value = obj[name]
      if (n > 0) {
        row += cDelimiter
      }
      row += toCsvValue(value, cDelimiter)
    }
    output += row
  }

  return output
}

// c pa mwa
export function csvToJson(csv) {
  const arr = []
  let quote = false // true means we're inside a quoted field
  let row = 0
  let col = 0

  function findDelimiter() {
    const headers = csv.split('\n')[0]

    const definedDelimiters = ',;|\t'
    const score = definedDelimiters.split('').reduce((acc, key) => ({...acc, [key]: 0}), {})
    let delim = definedDelimiters[0]
    // eslint-disable-next-line no-restricted-syntax
    for (const char of headers) {
      if (score.hasOwnProperty(char)) score[char] += 1 // eslint-disable-line no-prototype-builtins
      if (score[char] > score[delim]) delim = char
    }
    return delim
  }

  const delimiter = findDelimiter()

  // eslint-disable-next-line no-param-reassign, no-control-regex
  csv = csv.replace(/^[\x00-\x20\x7F-\x9F]|ï»¿/u, '') // https://en.wikipedia.org/wiki/Byte_order_mark

  // iterate over each character, keep track of current row and column (of the returned array)
  for (let c = 0; c < csv.length; c += 1) {
    const cc = csv[c]
    const nc = csv[c + 1] // current character, next character
    arr[row] = arr[row] || [] // create a new row if necessary
    arr[row][col] = arr[row][col] || '' // create a new column (start with empty string) if necessary
    // If the current character is a quotation mark, and we're inside a
    // quoted field, and the next character is also a quotation mark,
    // add a quotation mark to the current column and skip the next character
    if (cc === '"' && quote && nc === '"') {
      arr[row][col] += cc
      c += 1
    } else if (cc === '"') {
      // If it's just one quotation mark, begin/end quoted field
      quote = !quote
    } else if (cc === delimiter && !quote) {
      // If it's a comma and we're not in a quoted field, move on to the next column
      col += 1
    } else if (cc === '\r' && nc === '\n' && !quote) {
      // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
      // and move on to the next row and move to column 0 of that new row
      row += 1
      col = 0
      c += 1
    } else if (cc === '\n' && !quote) {
      // If it's a newline (LF or CR) and we're not in a quoted field,
      // move on to the next row and move to column 0 of that new row
      row += 1
      col = 0
    } else if (cc === '\r' && !quote) {
      row += 1
      col = 0
    } else if (cc === '\0') {
      // Ignore \0 which breaks postgre
    } else {
      // Otherwise, append the current character to the current column
      arr[row][col] += cc
    }
  }

  if (csv.charAt(csv.length - 1) === ',') arr[arr.length - 1].push('')

  const headers = arr.shift()
  return arr.map(line => {
    const obj = {}
    headers.forEach((h, i) => {
      try {
        const field = JSON.parse(`${line[i]}`.toLowerCase())
        obj[h] = field // eslint-disable-line no-param-reassign
      } catch (err) {
        obj[h] = line[i] // eslint-disable-line no-param-reassign
      }
    })
    return obj
  })
}

export function lerp2Colors(a, b, amount) {
  /* eslint-disable no-bitwise */
  const am = Math.min(Math.max(amount, amount < 0 ? 0.0 : amount), amount > 1.0 ? 1.0 : amount)
  const ah = parseInt(a.replace(/#/g, ''), 16)
  const ar = ah >> 16
  const ag = (ah >> 8) & 0xff
  const ab = ah & 0xff
  const bh = parseInt(b.replace(/#/g, ''), 16)
  const br = bh >> 16
  const bg = (bh >> 8) & 0xff
  const bb = bh & 0xff
  const rr = ar + am * (br - ar)
  const rg = ag + am * (bg - ag)
  const rb = ab + am * (bb - ab)

  return `#${(((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1)}`
  /* eslint-enable no-bitwise */
}

export function lerp3Colors(a, b, c, amount, negatives = false) {
  if (negatives) return amount < 0 ? lerp2Colors(b, a, amount * -1) : lerp2Colors(b, c, amount)
  return amount < 0.5 ? lerp2Colors(a, b, amount * 2) : lerp2Colors(b, c, (amount - 0.5) * 2)
}

export function capitalize(string) {
  return (string || '').charAt(0).toUpperCase() + (string || '').slice(1)
}

export function uncapitalize(string) {
  return (string || '').charAt(0).toLowerCase() + (string || '').slice(1)
}

export function strictWordSearch(search, text) {
  return text.includes(search.replace(/^=+/, ''))
}

export function fuzzysearch(needle, haystack, caseSensitive = false) {
  const needleLowered = caseSensitive ? needle : needle.toLowerCase()
  const haystackLowered = caseSensitive ? haystack : haystack.toLowerCase()
  const hlen = haystackLowered.length
  const nlen = needleLowered.length
  if (nlen > hlen) {
    return false
  }
  if (nlen === hlen) {
    return needleLowered === haystackLowered
  }
  /* eslint-disable */
  outer: for (let i = 0, j = 0; i < nlen; i += 1) {
    const nch = needleLowered.charCodeAt(i)
    while (j < hlen) {
      if (haystackLowered.charCodeAt(j++) === nch) {
        continue outer
      }
    }
    return false
  }
  /* eslint-enable */
  return true
}

export function fuzzyWordsSearch(rawNeedle, haystack, caseSensitive = false) {
  if (rawNeedle?.startsWith('=')) return strictWordSearch(rawNeedle, haystack)
  const haystacks = haystack.split(/\s/)
  const excludeNeedles = rawNeedle
    .split(/\s/)
    .filter(n => n)
    .filter(n => n.startsWith('!'))
    .map(n => n.slice(1))
  const includeNeedles = rawNeedle
    .split(/\s/)
    .filter(n => n)
    .filter(n => !n.startsWith('!'))

  // eslint-disable-next-line no-restricted-syntax
  for (const needle of excludeNeedles) {
    if (new RegExp(needle, 'gi').test(haystack)) return false
  }

  const hlen = haystacks.length
  const nlen = includeNeedles.length
  if (nlen > hlen) {
    return false
  }
  /* eslint-disable */
  outer: for (let i = 0, j = 0; i < nlen; i += 1) {
    const n = includeNeedles[i]
    while (j < hlen) {
      if (fuzzysearch(n, haystacks[j++], caseSensitive)) {
        continue outer
      }
    }
    return false
  }
  /* eslint-enable */
  return true
}

export function betterAMZImage(url, factor = 3) {
  if (!url) return null
  const removeExp = /(?:_AC)?(_[^C]*_)(?:CR(?:,\d+){4}_)?/
  const captureExp = /_(?:(?:SR(\d+),(\d+))|(?:SX(\d+)_SY(\d+))|(?:(SS|SL|US|SY|SX)(\d+)))_/
  return url.replace(removeExp, '$1').replace(captureExp, (all, g1, g2, g3, g4, g5, g6) => {
    if (g1) return `_SR${g1 * factor},${g2 * factor}_`
    if (g3) return `_SX${g3 * factor}_SY${g4 * factor}_`
    return `_${g5}${g6 * factor}_`
  })
}

export const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export function debounce(func, threshold, execAsap) {
  let timeout

  return function timed() {
    const obj = this
    const args = arguments // eslint-disable-line prefer-rest-params
    function delayed() {
      if (!execAsap) func.apply(obj, args)
      timeout = null
    }

    if (timeout) clearTimeout(timeout)
    else if (execAsap) func.apply(obj, args)

    timeout = setTimeout(delayed, threshold || 100)
  }
}

export function decodeQueryParams(obj) {
  const searchString = (
    (obj && obj.props && obj.props.location && obj.props.location.search) ||
    obj.toString()
  ).substring(1)
  if (searchString) {
    return JSON.parse(
      `{"${decodeURIComponent(searchString)
        .replace(/"/g, '\\"')
        .replace(/\?/g, '","')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    )
  }
  return {}
}

export const fromStringToMoment = arg => {
  if (typeof arg === 'string' && moment.utc(arg).isValid()) return moment.utc(arg)
  else if (isPlainObject(arg))
    return Object.keys(arg).reduce((acc, key) => {
      acc[key] = fromStringToMoment(arg[key]) // eslint-disable-line no-param-reassign
      return acc
    }, {})
  else if (Array.isArray(arg)) return arg.map(a => fromStringToMoment(a))
  return arg
}

export const fromMomentToString = arg => {
  if (moment.isMoment(arg)) return arg.toISOString()
  else if (isPlainObject(arg))
    return Object.keys(arg).reduce((acc, key) => {
      acc[key] = fromMomentToString(arg[key]) // eslint-disable-line no-param-reassign
      return acc
    }, {})
  else if (Array.isArray(arg)) return arg.map(a => fromMomentToString(a))
  return arg
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function validateFirstName(name) {
  if (!name) return false
  if (name.length > 30) return false
  return true
}

const urlSafeBase64 = /^data:\w+\/[\w.-]+;base64/

export function validateBase64String(string) {
  return string && urlSafeBase64.test(string)
}

export function validateLastName(name) {
  if (!name) return false
  if (name.length > 150) return false
  return true
}

export function get(obj, key, dflt) {
  if (typeof key === 'string') {
    // eslint-disable-next-line no-param-reassign
    if (key.includes('.')) key = key.split('.')
    else key = [key] // eslint-disable-line no-param-reassign
  }
  return key.reduce((v, attr) => {
    try {
      return v[attr] === undefined ? dflt : v[attr]
    } catch (e) {
      return dflt
    }
  }, obj)
}

export function set(obj, key, value) {
  if (typeof key === 'string') {
    // eslint-disable-next-line no-param-reassign
    if (key.includes('.')) key = key.split('.')
    else key = [key] // eslint-disable-line no-param-reassign
  }
  const maxDepth = key.length
  let cursor = obj
  key.forEach((attr, index) => {
    if (index === maxDepth - 1) cursor[attr] = value
    else if (isPlainObject(cursor[attr])) cursor = cursor[attr]
    // eslint-disable-next-line eqeqeq
    else if (cursor[attr] == undefined) {
      cursor[attr] = {}
      cursor = cursor[attr]
    } else throw new Error("Can't set a property to something other than an object.")
  })
  return obj
}

export function camelCaseToSnakeCase(string) {
  return string.replace(/\.?([A-Z]|([0-9]+))/g, (x, y) => `_${y.toLowerCase()}`).replace(/^_/, '')
}

export function snakeCaseToCamelCase(string) {
  return string.replace(/_\w/g, x => x[1].toUpperCase())
}

export function titleCase(string = '') {
  return string.replace(/[\w]+[^\s-_']*/g, s => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase())
}

export function prettyErrors(message) {
  if (message === 'Failed to fetch') return i18n.t('placeholders.errors.connectivityIssue')
  else if (message instanceof Error) return message.message
  return message
}

export function randomBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function mixinBuilder(instance) {
  return new MixinBuilder(instance)
}

// c presk pa mwa
export function levenshteinMatchPercentage(lhs, rhs) {
  if (lhs?.length === 0 && rhs?.length === 0) return 100
  else if (!lhs || !lhs.length || !rhs || !rhs.length) return 0

  function editDistance(_lhs, _rhs) {
    const longestString = _lhs.toLowerCase()
    const shortestString = _rhs.toLowerCase()

    const costs = []
    for (let i = 0; i <= longestString.length; i += 1) {
      let lastValue = i
      for (let j = 0; j <= shortestString.length; j += 1) {
        if (i === 0) costs[j] = j
        else if (j > 0) {
          let newValue = costs[j - 1]
          if (longestString.charAt(i - 1) !== shortestString.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
          costs[j - 1] = lastValue
          lastValue = newValue
        }
      }
      if (i > 0) costs[shortestString.length] = lastValue
    }
    return costs[shortestString.length]
  }

  const longestString = lhs.length >= rhs.length ? lhs : rhs
  const shortestString = lhs.length < rhs.length ? lhs : rhs
  const score = longestString.length

  return Math.floor(((score - editDistance(longestString, shortestString)) / parseFloat(score)) * 100)
}

export function floorToTwo(value) {
  return +(Math.floor(value + 'e+2') + 'e-2') // eslint-disable-line prefer-template
}

export function roundToTwo(value) {
  return +(Math.round(value + 'e+2') + 'e-2') // eslint-disable-line prefer-template
}

export function signedNumber(number) {
  return `${number > 0 ? '+' : ''} ${i18n.toNumber(number, {precision: 0})}`
}

export function signedCurrency(number, currency) {
  return `${number > 0 ? '+' : ''} ${i18n.toCurrency(i18n.toNumber(number, {precision: 0}), {
    code: currency,
    precision: 0,
  })}`
}

export const getAverage = (value, count = undefined) => {
  const avg = _(value).reduce((acc, _value) => acc + _value, 0) / (count || Object.keys(value).length)

  return avg === Infinity || global.isNaN(avg) ? 0 : avg
}

export const getPercentageValue = (valueA, valueB, isPercentage = false) => {
  if (valueA === valueB) return 0
  else if (valueA === 0 && valueB !== 0) return i18n.t('placeholders.empty.notAvailable')
  return isPercentage ? validNumber(valueB - valueA) : validNumber((valueB - valueA) / valueA)
}

export const getPercentageValueFormatted = (percentage, signed = true) =>
  // eslint-disable-next-line no-nested-ternary
  percentage !== 0 && percentage !== i18n.t('placeholders.empty.notAvailable')
    ? `${percentage > 0 && signed ? '+' : ''}${i18n.toPercentage(percentage * 100, {precision: 0})}`
    : percentage === 0
    ? i18n.toPercentage(percentage, {precision: 0})
    : percentage

export const realIsEmpty = value =>
  _.isEmpty(value) || (isPlainObject(value) ? Object.values(value).every(vl => realIsEmpty(vl)) : false)

export const complexIsEqual = (a, b) =>
  // eslint-disable-next-line consistent-return
  _.isEqualWith(a, b, (lhs, rhs) => {
    if (_.isEmpty(lhs) || _.isEmpty(rhs)) return realIsEmpty(lhs) == realIsEmpty(rhs) // eslint-disable-line eqeqeq
    if (moment.isMoment(lhs) || moment.isMoment(rhs)) return lhs === rhs
  })

export const fillDateRange = (startDate, endDate, interval = 1, unit = 'days', format = 'YYYY-MM-DD') => {
  const now = moment.utc(startDate)
  const dates = []

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(format))
    now.add(interval, unit)
  }
  return dates
}

/* eslint-disable no-bitwise */
export const colorHashOld = str => {
  let hash = 0
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash) // eslint-disable-line no-bitwise
  }
  const hashString = (hash & 0x00ffffff).toString(16).toUpperCase() // eslint-disable-line no-bitwise
  return Color.hex(hashString.padStart(6, '0'), 1)
}

export const colorHash = str => {
  const n = appColors.variousColors.length
  const w = [...Array(n).keys()].reduce((s, v) => s + v + 1, 0) / n
  const bitMask = parseInt('1'.repeat(n), 2)
  let hash = 0
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  hash = (hash & bitMask) >>> 0
  // console.log(hash.toString(2).padStart(n, '0'))
  const bits = [...hash.toString(2)].reverse().map((x, idx) => parseInt(x, 10) * (idx + 1))
  const setBits = bits.filter(x => x).length
  const mean = bits.reduce((s, v) => s + v, 0) / w
  const part = hash / (bitMask / n)
  const idx = Math.min(n - 1, Math.max(0, Math.floor((n / 2 - mean) / 2 + (n / 2 - setBits) / 2 + part)))
  // console.log(mean, setBits, part)
  return appColors.variousColors[idx]
}
/* eslint-enable no-bitwise */

window.reactComponentFromDom = function reactComponentFromDom(dom) {
  const key = Object.keys(dom).find(k => k.startsWith('__reactInternalInstance$'))
  const internalInstance = dom[key]
  if (internalInstance == null) return null

  if (internalInstance.return) {
    // react 16+
    return internalInstance._debugOwner ? internalInstance._debugOwner.stateNode : internalInstance.return.stateNode
  }
  // react <16
  return internalInstance._currentElement._owner._instance
}

/* :maxraph: */
function rafAsync() {
  return new Promise(resolve => {
    setTimeout(resolve, 10)
  })
}

window.waitForElement = async (selector, timeout = 1000) => {
  const querySelector = document.querySelectorAll(selector)
  const startTime = new Date().valueOf()
  while (querySelector.length === 0 && new Date().valueOf() - startTime < timeout) {
    await rafAsync() /* eslint-disable-line no-await-in-loop */
  }
  if (querySelector.length === 0) throw new Error(`Couldn't find ${selector}`)
  return querySelector
}

export const requiredIf = (type, condition) => {
  // eslint-disable-next-line func-names
  return function (props, propName, componentName) {
    if (typeof type !== 'function') {
      return new Error(`Invalid required-if PropType supplied to ${componentName}. Validation failed.`)
    }

    if (typeof condition !== 'function') {
      return new Error(`Invalid required-if condition supplied to ${componentName}. Validation failed.`)
    }

    const test = condition(props) ? type.isRequired : type
    return test.apply(this, arguments) // eslint-disable-line prefer-rest-params
  }
}

export const forbidIf = (type, condition) => {
  // eslint-disable-next-line func-names
  return function (props, propName, componentName) {
    if (typeof type !== 'function') {
      return new Error(`Invalid forbid-if PropType supplied to ${componentName}. Validation failed.`)
    }

    if (typeof condition !== 'function') {
      return new Error(`Invalid forbid-if condition supplied to ${componentName}. Validation failed.`)
    }

    if (condition(props)) {
      return new Error(`Can not pass ${propName} to ${componentName} with the current props.`)
    }
    return type.apply(this, arguments) // eslint-disable-line prefer-rest-params
  }
}

export function argsAsArray(...args) {
  if (args.length === 1) {
    if (Array.isArray(args[0])) return args[0]
    else if (typeof args[0] === 'string') return args[0].split('.')
  }
  return args
}

export function searchInTable(item, grouping, search) {
  switch (grouping) {
    case 'asin':
    case 'asins':
    case 'asins_marketplaces':
    case 'asin_marketplace':
    case 'ASIN':
    case 'OFFER':
      return (
        fuzzyWordsSearch(
          search,
          item.value && item.value[0] ? item.value[0].title || '' : item.title ? item.title : '' // eslint-disable-line no-nested-ternary
        ) || fuzzyWordsSearch(search, item.key)
      )
    case 'countries':
      return (
        fuzzyWordsSearch(
          search,
          item.key === i18n.t('placeholders.empty.notAvailable')
            ? i18n.t('placeholders.empty.notAvailable')
            : i18n.t(`countries.${item.key.toLowerCase()}.name`)
        ) || fuzzyWordsSearch(search, item.key)
      )
    case 'MARKETPLACE':
      return fuzzyWordsSearch(
        search,
        item.key === i18n.t('placeholders.empty.notAvailable')
          ? i18n.t('placeholders.empty.notAvailable')
          : countries[item.key.split('_')[1].toUpperCase()]
      )
    case 'ad_group_id':
    case 'ad_group_id_marketplace':
    case 'campaign_id':
    case 'campaign_id_marketplace':
    case 'AD_GROUP':
    case 'CAMPAIGN':
    case 'ADVERTISER':
    case 'ORDER':
    case 'LINE_ITEM':
    case 'CREATIVE':
    case 'PROFILE':
    case 'tag':
      return fuzzyWordsSearch(search, item?.name ?? '')
    case 'title':
      return fuzzyWordsSearch(search, item?.title ?? '')
    default:
      return fuzzyWordsSearch(search, item.key)
  }
}

export class FakeProgress {
  constructor(cb, options) {
    const opts = {timeConstant: 5000, interval: 200, autoStart: false, ...options}

    this.cb = cb
    this.timeConstant = opts.timeConstant
    this.autoStart = opts.autoStart
    this._intervalFrequency = opts.interval
    this.progress = 0
    if (this.autoStart !== false) {
      this.start()
    }
  }

  start() {
    setTimeout(
      () => {
        this._time = 0
        this._intervalId = setInterval(this._onInterval.bind(this), this._intervalFrequency)
      },
      this.autoStart === true ? 0 : this.autoStart
    )
  }

  _onInterval() {
    this._time += this._intervalFrequency
    this.cb(Math.round((1 - Math.exp((-1 * this._time) / this.timeConstant)) * 100))
  }

  stop() {
    clearInterval(this._intervalId)
  }

  end() {
    this.stop()
    this.cb(100)
  }
}

export const cloneInstance = inst => Object.assign(Object.create(Object.getPrototypeOf(inst)), inst)

// because MrSatan doesn't like when a header doesn't match a data
export const unbreakableSatanFormattedData = (data, headers) =>
  data.map(oldData =>
    headers.reduce((newData, header) => {
      newData[header] = oldData[header] // eslint-disable-line no-param-reassign
      return newData
    }, {})
  )

export const unbreakableV3SatanFormattedData = (data, headers) =>
  headers.map(header =>
    data.reduce((newData, d) => {
      newData.push(d[header])
      return newData
    }, [])
  )

export const isJsonCompatible = (v, deep = false) => {
  if (typeof v === 'string' || typeof v === 'number' || typeof v === 'boolean' || v == null) return true
  if (typeof v === 'function') return false
  if (typeof v === 'object') {
    if (Array.isArray(v)) {
      if (deep) return v.every(_.partialRight(isJsonCompatible, true))
      return true
    }
    if (isPlainObject(v)) {
      if (deep) return Object.values(v).every(_.partialRight(isJsonCompatible, true))
      return true
    }
  }
  return false
}

export const slugify = value =>
  value
    .replace(/^-+/, '')
    .replace(/-+$/, '')
    .replace(/\s+/g, '_')
    .replace(/[ !"#$%&'()*+,-./:;<=>?@[\]^`{|}~]/g, '')
    .toLowerCase()

export const clamp = (text, length = 90) =>
  text && text.length > length ? `${text.substring(0, length - 5)}...` : text

export const genericDistribution = (data, split = 5, maxValue = 100, allowMoreThanMax = false) => {
  const divider = maxValue / (allowMoreThanMax ? split - 1 : split)
  const repartition = new Array(split).fill(0)
  let index = 1
  let i = 0
  while (i < data.length && index <= split) {
    if (data[i].value <= divider * index) {
      repartition[index - 1] += 1
      i += 1
      index = 1
    } else if (allowMoreThanMax && data[i].value >= maxValue) {
      repartition[split - 1] += 1
      i += 1
      index = 1
    } else index += 1
  }
  return repartition.map((r, idx) => ({
    x: idx,
    name: idx === split - 1 && allowMoreThanMax ? `${maxValue}+` : `${idx * divider}-${(idx + 1) * divider}`,
    y: r,
  }))
}

export const formatFieldMessageError = error => {
  return (
    Object.values(error)?.[0]?.reduce((parent, item) => {
      item.field.forEach(f => parent.push(`${capitalize(f)}: ${item.message}`))
      return parent
    }, []) || error
  )
}

export function getCatalogNoteDetails(nbr, bad = 2, ok = 4, extremeMin, extremeMax) {
  if (extremeMin && extremeMax) {
    if (nbr <= extremeMin)
      return {color: appColors.negative.rgba, icon: 'close', text: i18n.t('misc.cross.tooShort'), length: nbr}
    if (nbr >= extremeMax)
      return {color: appColors.negative.rgba, icon: 'close', text: i18n.t('misc.cross.tooLong'), length: nbr}
    if (nbr >= bad && nbr <= ok)
      return {color: appColors.positive.rgba, icon: 'check', text: i18n.t('misc.cross.good'), length: nbr}
    return {
      color: appColors.warning.rgba,
      icon: 'warning sign',
      text: i18n.t('misc.cross.needsImprovement'),
      length: nbr,
    }
  }
  if (nbr <= bad)
    return {color: appColors.negative.rgba, icon: 'close', text: i18n.t('misc.cross.insufficient'), length: nbr}
  if (nbr <= ok)
    return {
      color: appColors.warning.rgba,
      icon: 'warning sign',
      text: i18n.t('misc.cross.needsImprovement'),
      length: nbr,
    }
  return {color: appColors.positive.rgba, icon: 'check', text: i18n.t('misc.cross.good'), length: nbr}
}

export function copyItems({items, type = 'item'}) {
  const newline = String.fromCharCode(13, 10)
  const copyValue = _.uniq(items).join(newline)
  const el = document.createElement('textarea')
  el.value = copyValue
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  toast.success(i18n.t(`actions.select.copyItems`, {items: type}), {
    autoClose: 5000,
    style: {padding: '10px', borderLeft: `5px solid var(--toastify-icon-color-success)`},
  })
}

export const unitSymbol = (unit, currency) => {
  if (unit === 'percentage') return '%'
  else if (unit === 'currency' && currency) return i18n.t(`visualization.currency.${currency}`)
  return ''
}

export const convertToFloat = v => (global.isFinite(parseFloat(v)) ? parseFloat(v) : v)

// to refine later for bigger use cases
export const getCronFromDate = (frequencyDelivery, deliveryDate) => {
  if (frequencyDelivery === 'weekly') return `0 0 * * ${deliveryDate}`
  else if (frequencyDelivery === 'monthly') return `0 0 ${deliveryDate} * *`
  else return '0 0 * * *'
}

export const getDateFromCron = cron => {
  if (!cron)
    return {
      frequencyDelivery: null,
      deliveryDate: null,
    }
  else if (cron.endsWith('*'))
    return {
      frequencyDelivery: 'monthly',
      deliveryDate: cron.split(' ')[2],
    }
  return {
    frequencyDelivery: 'weekly',
    deliveryDate: cron.split(' ')[4],
  }
}
