import React from 'react'

import {Label} from 'semantic-ui-react'

import colors from '../../colors'
import i18n from '../../i18n'
import PoppersPopup from './PoppersPopup'
import SeelkTagLabel from './SeelkTagLabel'

interface TagType {
  slug: string
  color: string
  name: string
}

interface SeelkTagsProps {
  maxDisplay: number
  tags: TagType[]
}

class SeelkTags extends React.Component<SeelkTagsProps> {
  render() {
    const {maxDisplay = 0, tags = []} = this.props
    const tagSize = tags.length > 2 ? 12 : 16
    return [
      tags?.slice(0, maxDisplay || tags.length).map(({slug, ...tag}) => (
        <PoppersPopup
          hoverable
          key={slug}
          position="top center"
          trigger={
            <div>
              <SeelkTagLabel slug={slug} name={tag.name} color={tag.color} tagSize={tagSize} />
            </div>
          }
          content={<SeelkTagLabel slug={slug} name={tag.name} color={tag.color} />}
        />
      )),

      maxDisplay && tags.length > maxDisplay ? (
        <PoppersPopup
          hoverable
          key={i18n.t('settings.tags.moreTags', {count: tags.length - maxDisplay})}
          position="top center"
          trigger={
            <div>
              <Label
                value={i18n.t('settings.tags.moreTags', {count: tags.length - maxDisplay})}
                style={{
                  color: colors.darkLight.rgba,
                  padding: '4px 6px',
                  margin: '4px 0 4px 2px',
                  whiteSpace: 'nowrap',
                }}
              >
                {i18n.t('settings.tags.moreTags', {count: tags.length - maxDisplay})}
              </Label>
            </div>
          }
          content={tags
            ?.slice(maxDisplay, tags.length)
            .map(({slug, ...tag}) =>
              tag ? <SeelkTagLabel key={slug} slug={slug} name={tag.name} color={tag.color} /> : null
            )}
        />
      ) : null,
    ]
  }
}

export default SeelkTags
