import React from 'react'

import {Row} from 'jsxstyle'
import {Segment} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../../colors'

const StyledSegment = styled(Segment)`
  &&& {
    padding: 0;
    max-height: 60px;
    overflow: hidden;
    cursor: pointer;
    :hover {
      border-color: ${colors.secondary.rgba};
    }
  }
`

const StyledBox = styled(Row)`
  &&& {
    padding: 0;
  }
`

const StyledIcon = styled.span`
  align-items: center;
  background-color: ${colors.lightAccent.rgba};
  color: ${colors.darkLight.rgba};
  display: flex;
  font-size: 1.4em;
  height: 60px;
  justify-content: center;
  min-width: 60px;
  ${StyledSegment}:hover & {
    color: ${colors.primary.rgba};
    background-color: ${colors.lightBackground.rgba};
  }
`

const StyledName = styled.span`
  align-items: center;
  color: ${colors.dark.rgba};
  display: flex;
  font-size: 0.9em;
  font-weight: 500;
  height: 60px;
  line-height: 1.2em;
  margin: 0 10px 0 15px;
  ${StyledSegment}:hover & {
    color: ${colors.primary.rgba};
  }
`

interface SeelkDashboardCardProps {
  name: string
  icon: string
}

const SeelkDashboardCard = ({name, icon}: SeelkDashboardCardProps) => {
  return (
    <StyledSegment>
      <StyledBox>
        <StyledIcon>
          <i className={`fas fa-${icon}`} />
        </StyledIcon>
        <StyledName>{name}</StyledName>
      </StyledBox>
    </StyledSegment>
  )
}

export default SeelkDashboardCard
