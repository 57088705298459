import * as Sentry from '@sentry/browser'
import {push} from 'connected-react-router'
import {Reliever} from 'react-redux-reliever'
import {ofType} from 'redux-observable'
import {Observable} from 'rxjs'
import {catchError, map, mergeMap, tap} from 'rxjs/operators'

import COUNTRIES from '../../config/countries.json'
import track from '../metrics/track'
import {KaioApi} from '../utils/apis'
import {getState, toast} from '../utils/extensions/rx'

class AppReliever extends Reliever {
  ACTION_PREFIX = 'APP'

  getInitialState() {
    return {
      account: null,
      accounts: null,
      members: null,
      messages: [],
      token: null,
      user: null,
    }
  }

  receivedTokenEpic(action$) {
    return action$.pipe(
      ofType('APP_RECEIVED_TOKEN'),
      mergeMap(() =>
        KaioApi('getUserFromToken').pipe(
          mergeMap(user =>
            KaioApi('getUser', user.id).pipe(
              tap(({country, persona}) => {
                if (user.roles && user.roles.includes('Dev')) {
                  window.Observable = Observable
                }

                Sentry.configureScope(scope => {
                  scope.setUser({
                    id: user.id,
                    email: user.email,
                    username: user.name,
                  })
                  scope.setTag('client', user.client)
                  scope.setExtra('roles', user.roles)
                })
                const {mixpanel} = global
                if (mixpanel) {
                  mixpanel.identify(user.email)
                  mixpanel.people.set({
                    $email: user.email,
                    $name: user.name,
                    $client: user.client,
                    $persona: persona,
                    $location: COUNTRIES?.[country],
                  })
                }
                track('Login')
              }),
              map(({persona}) => ({type: 'APP_SET_USER', payload: {user: {...user, persona}}})),
              catchError(err => toast(err))
            )
          )
        )
      )
    )
  }

  refreshTokenFailedEpic(action$) {
    return action$.pipe(
      ofType('APP_REFRESH_TOKEN_FAIL'),
      mergeMap(() =>
        getState('router').pipe(
          map(state => state.location.pathname + state.location.search),
          map(path => push(`/login?next=${encodeURIComponent(path)}`))
        )
      )
    )
  }

  fetchAccountsEpic(action$) {
    return action$.pipe(
      ofType('APP_FETCH_ACCOUNTS'),
      mergeMap(action =>
        KaioApi('getAccounts').pipe(
          map(accounts => ({type: 'APP_FETCH_ACCOUNTS_SUCCESS', payload: {accounts}})),
          tap(() => action._reqWrapper.end()),
          catchError(err => action._reqWrapper.fail({error: err}))
        )
      )
    )
  }
}

export default AppReliever
