import RelieverRegistry from 'react-redux-reliever'

import AppLayout from './containers/AppLayout'
import AppReliever from './store'

RelieverRegistry.register(AppReliever, 'app')

export default AppLayout

export {default as NotFound} from './components/NotFound'
