import React from 'react'

import deepEqual from 'deep-equal'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import highchartDrilldown from 'highcharts/modules/drilldown'
import highchartsMap from 'highcharts/modules/map'
import highchartsNetworkGraph from 'highcharts/modules/networkgraph'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
import highchartsPattern from 'highcharts/modules/pattern-fill'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
// import theme from '../../../../config/theme.json'

highchartsMap(Highcharts)
highchartDrilldown(Highcharts)
highchartsNetworkGraph(Highcharts)
highchartsPattern(Highcharts)
highchartsMore(Highcharts)
NoDataToDisplay(Highcharts)

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

// ReactHighcharts.Highcharts.setOptions(theme)

// This is to remove the ugly view data table menu item created by HighchartsExportCsv
// ReactHighcharts.Highcharts.getOptions().exporting.buttons.contextButton.menuItems = ReactHighcharts.Highcharts.getOptions().exporting.buttons.contextButton.menuItems.filter(
//   v => v.textKey !== 'viewData'
// )

/**
 * Custom Axis extension to allow emulation of negative values on a logarithmic
 * Y axis. Note that the scale is not mathematically correct, as a true
 * logarithmic axis never reaches or crosses zero.
 */

/* eslint-disable */

function enableNegativeLogOnAxis(H) {
  // Pass error messages
  H.Axis.prototype.allowNegativeLog = true

  // Override conversions
  H.Axis.prototype.log2lin = function (num) {
    var isNegative = num < 0,
      adjustedNum = Math.abs(num),
      result
    if (adjustedNum < 10) {
      adjustedNum += (10 - adjustedNum) / 10
    }
    result = Math.log(adjustedNum) / Math.LN10
    return isNegative ? -result : result
  }
  H.Axis.prototype.lin2log = function (num) {
    var isNegative = num < 0,
      absNum = Math.abs(num),
      result = Math.pow(10, absNum)
    if (result < 10) {
      result = (10 * (result - 1)) / (10 - 1)
    }
    return isNegative ? -result : result
  }
}

enableNegativeLogOnAxis(Highcharts)

Highcharts.setOptions({
  lang: {
    drillUpText: '< Back',
  },
  noData: {
    style: {
      fontStyle: 'italic',
      fontWeight: 300,
      color: '#aaa',
    },
  },
})
class Chart extends React.Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    inverted: PropTypes.bool,
    type: PropTypes.oneOf([
      'area',
      'arearange',
      'areaspline',
      'areasplinerange',
      'bar',
      'boxplot',
      'bubble',
      'column',
      'columnrange',
      'errorbar',
      'funnel',
      'gauge',
      'heatmap',
      'line',
      'networkgraph',
      'pie',
      'polygon',
      'pyramid',
      'scatter',
      'series',
      'solidgauge',
      'spline',
      'treemap',
      'waterfall',
    ]),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    xLabel: PropTypes.string,
    yLabel: PropTypes.string,
    xType: PropTypes.oneOf(['linear', 'logarithmic', 'datetime', 'category']),
    yType: PropTypes.oneOf(['linear', 'logarithmic', 'datetime', 'category']),
    xIsVisible: PropTypes.bool,
    yIsVisible: PropTypes.bool,
    xCategories: PropTypes.arrayOf(PropTypes.string),
    yCategories: PropTypes.arrayOf(PropTypes.string),
    xAllowDecimals: PropTypes.bool,
    legend: PropTypes.bool,
    tooltip: PropTypes.bool,
    color: PropTypes.string,
    colors: PropTypes.arrayOf(PropTypes.string),
    series: PropTypes.arrayOf(PropTypes.object),
    drilldowns: PropTypes.object,
    data: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
      ])
    ),
    onLegendClick: PropTypes.func,
    extraConfig: PropTypes.object,
    style: PropTypes.object,
    polar: PropTypes.bool,
    constructorType: PropTypes.string,
    containerProps: PropTypes.object,
    zoomType: PropTypes.string,
  }

  static defaultProps = {
    constructorType: 'chart',
    colors: null,
    containerProps: {},
    extraConfig: {},
    title: '',
    style: {},
    polar: false,
  }

  constructor(props) {
    super(props)
    this.count = 0
  }

  UNSAFE_componentWillMount() {
    this.initComponent(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.initComponent(nextProps)
  }

  // We want to animate the graph when the data changed otherwise we simply update it
  shouldComponentUpdate(nextProps) {
    // Attributes like _colorIndex are added automatically so we need to remove them from the comparison
    // otherwise the data would be considered different; however, we clone the objects first because they are needed
    // Note that changes in series will only update when basic attributes change but not functions
    if (nextProps.series === undefined || this.props.series === undefined) {
      return true
    }
    const cpyCurrSeries = JSON.parse(JSON.stringify(this.props.series))
    const cpyNextSeries = JSON.parse(JSON.stringify(nextProps.series))
    cpyCurrSeries.forEach(v => {
      const serie = v
      Object.keys(serie).forEach(k => {
        if (k.startsWith('_')) {
          delete serie[k]
        }
      })
    })
    cpyNextSeries.forEach(v => {
      const serie = v
      Object.keys(serie).forEach(k => {
        if (k.startsWith('_')) {
          delete serie[k]
        }
      })
    })
    if (JSON.stringify(cpyCurrSeries) !== JSON.stringify(cpyNextSeries)) {
      this.count += 1 // this motherfucker saves the hell out of our ass, remove it and say bye to your graphs
      return true
    }

    const oldConfig = JSON.parse(JSON.stringify(this.props))
    const newConfig = JSON.parse(JSON.stringify(nextProps))
    delete oldConfig.series
    delete newConfig.series
    if (!deepEqual(oldConfig, newConfig)) {
      this.highChartsElement.update(this.highChartsConfig)
    }
    return false
  }

  setConfigProp(path, value) {
    if (value === undefined) {
      return
    }
    let schema = this.highChartsConfig
    const pList = path.split('.')
    const len = pList.length
    for (let i = 0; i < len - 1; i += 1) {
      const elem = pList[i]
      if (!schema[elem]) {
        schema[elem] = {}
      }
      schema = schema[elem]
    }
    schema[pList[len - 1]] = value
  }

  updateHighChartsConfig(props) {
    // We can't put extraConfig directly because it's declared in defaultProps which is static and every subsequent
    // Chart will get the same extraConfig instance and add more data to it or override the previous graphs attributes
    this.highChartsConfig = {...props.extraConfig}
    this.setConfigProp('chart.type', props.type)
    this.setConfigProp('chart.inverted', props.inverted)
    this.setConfigProp('chart.backgroundColor', null)
    this.setConfigProp('title.text', props.title)
    this.setConfigProp('subtitle.text', props.subtitle)
    this.setConfigProp('xAxis.title.text', props.xLabel)
    this.setConfigProp('xAxis.categories', props.xCategories)
    this.setConfigProp('xAxis.type', props.xType || (props.xCategories && 'category'))
    this.setConfigProp('xAxis.visible', props.xIsVisible)
    this.setConfigProp('xAxis.allowDecimals', props.xAllowDecimals)
    this.setConfigProp('yAxis.title.text', props.yLabel)
    this.setConfigProp('yAxis.categories', props.yCategories)
    this.setConfigProp('yAxis.type', props.yType)
    this.setConfigProp('yAxis.visible', props.yIsVisible)
    this.setConfigProp('yAxis.allowDecimals', props.yAllowDecimals)
    this.setConfigProp('yAxis.max', props.yMax || null)
    this.setConfigProp('legend.enabled', props.legend)
    this.setConfigProp('tooltip.enabled', props.tooltip)
    this.setConfigProp('plotOptions.series.events.legendItemClick', props.onLegendClick)
    this.setConfigProp('series', props.series || this.highChartsConfig.series || [])
    this.setConfigProp('drilldown.animation', false) // Rendering issues when this is true
    this.setConfigProp('drilldown', props.drilldowns)
    this.setConfigProp('credits.enabled', false)
    this.setConfigProp('colors', props.colors ? props.colors : props.color && [props.color])
    this.setConfigProp('tooltip.style.fontSize', 16)
    this.setConfigProp('chart.zoomType', props.zoomType)

    if (props.polar) {
      this.setConfigProp('chart.polar', true)
      this.setConfigProp('xAxis.lineWidth', props.lineWidth || 0)
      this.setConfigProp('xAxis.tickmarkPlacement', 'on')
      this.setConfigProp('yAxis.gridLineInterpolation', 'polygon')
    }

    if (props.data && this.highChartsConfig.series.length === 0) {
      this.highChartsConfig.series.push({name: '', data: props.data})
    }
  }

  initComponent(props) {
    this.updateHighChartsConfig(props)
  }

  render() {
    return (
      <div style={{flex: '1 1 auto', alignSelf: 'stretch', ...this.props.style}}>
        <AutoSizer disableHeight={!!this.props.height} disableWidth={!!this.props.width}>
          {({height, width}) => {
            const w = this.props.width || Math.max(width, 100)
            const h = this.props.height || Math.max(height, 100)
            this.setConfigProp('chart.width', w)
            this.setConfigProp('chart.height', h)
            this.setConfigProp('chart.overflow', 'visible')
            return (
              <HighchartsReact
                constructorType={this.props.constructorType}
                key={this.count}
                highcharts={Highcharts}
                containerProps={{
                  style: {height: h, width: w},
                  ...this.props.containerProps,
                }}
                options={{...this.highChartsConfig}}
                ref={el => {
                  this.highChartsElement = el && el.chart
                }}
              />
            )
          }}
        </AutoSizer>
      </div>
    )
  }
}

export default Chart
