import React from 'react'

import {Box, Col} from 'jsxstyle'
import {Checkbox, Icon} from 'semantic-ui-react'

import colors from '../../../../../colors'
import i18n from '../../../../../i18n'
import FilterButton from '../../../FilterButton'
import FilterBarItem from './FilterBarItem'

const crossProvider = i18n.createProvider('misc.cross')
const groupingProvider = i18n.createProvider('visualization.grouping')

interface BuyBoxProps {
  data: string
  onApply: () => void
  onChange: (changes: string | null) => void
  onRemove: () => void
  title: string
  disabled?: boolean
  icon?: string | null
}

const BuyBox = ({
  data,
  onApply,
  onChange,
  onRemove,
  title,
  disabled = false,
  icon = null,
  ...barItemProps
}: BuyBoxProps) => {
  const text = (() => {
    switch (data) {
      case 'is_prime_only':
        return crossProvider('primeOnly')
      case 'is_add_on_item':
        return crossProvider('addOn')
      case 'has_active_buy_box':
        return crossProvider('active')
      case 'inactive':
        return crossProvider('inactive')
      case 'is_pantry':
        return crossProvider('pantry')
      default:
        return groupingProvider('all')
    }
  })()

  const trigger = (
    <FilterButton
      title={title}
      icon={icon}
      disabled={disabled}
      onRemove={onRemove}
      onApply={onApply}
      dropdown={undefined}
    >
      <Box marginLeft="8px">{text}</Box>
    </FilterButton>
  )

  return (
    <FilterBarItem
      {...barItemProps}
      data={data}
      onChange={onChange}
      disabled={disabled}
      trigger={trigger}
      onApply={onApply}
    >
      <Col width="275px" cursor="default" padding="16px 0px 16px 0px">
        <Checkbox
          radio
          onClick={() => onChange(null)}
          checked={data == null}
          label={{
            children: (
              <p>
                <span style={{color: colors.primaryDark.rgba}}>{groupingProvider('all')}</span>
              </p>
            ),
            className: 'checkbox-element-label',
          }}
          className="checkbox-element"
        />
        <Checkbox
          radio
          onClick={() => onChange('is_prime_only')}
          checked={data === 'is_prime_only'}
          label={{
            children: (
              <p>
                <Icon name="cart" size="small" style={{color: colors.named('blue')}} />
                <span style={{color: colors.primaryDark.rgba}}>{crossProvider('primeOnly')}</span>
              </p>
            ),
            className: 'checkbox-element-label buyBox-checkbox-element-label',
          }}
          className="checkbox-element"
        />
        <Checkbox
          radio
          onClick={() => onChange('is_add_on_item')}
          checked={data === 'is_add_on_item'}
          label={{
            children: (
              <p>
                <Icon name="cart" size="small" style={{color: colors.named('purple')}} />
                <span style={{color: colors.primaryDark.rgba}}>{crossProvider('addOn')}</span>
              </p>
            ),
            className: 'checkbox-element-label buyBox-checkbox-element-label',
          }}
          className="checkbox-element"
        />
        <Checkbox
          radio
          onClick={() => onChange('has_active_buy_box')}
          checked={data === 'has_active_buy_box'}
          label={{
            children: (
              <p>
                <Icon name="cart" size="small" style={{color: colors.positive}} />
                <span style={{color: colors.primaryDark.rgba}}>{crossProvider('active')}</span>
              </p>
            ),
            className: 'checkbox-element-label buyBox-checkbox-element-label',
          }}
          className="checkbox-element"
        />
        <Checkbox
          radio
          onClick={() => onChange('inactive')}
          checked={data === 'inactive'}
          label={{
            children: (
              <p>
                <Icon name="cart" size="small" style={{color: colors.negative}} />
                <span style={{color: colors.primaryDark.rgba}}>{crossProvider('inactive')}</span>
              </p>
            ),
            className: 'checkbox-element-label buyBox-checkbox-element-label',
          }}
          className="checkbox-element"
        />
        <Checkbox
          radio
          onClick={() => onChange('is_pantry')}
          checked={data === 'is_pantry'}
          label={{
            children: (
              <p>
                <Icon name="cart" size="small" style={{color: colors.named('indianRed')}} />
                <span style={{color: colors.primaryDark.rgba}}>{crossProvider('pantry')}</span>
              </p>
            ),
            className: 'checkbox-element-label buyBox-checkbox-element-label',
          }}
          className="checkbox-element"
        />
      </Col>
    </FilterBarItem>
  )
}

export default BuyBox
