import React from 'react'

import PropTypes from 'prop-types'
import {Flag, FlagNameValues} from 'semantic-ui-react'

import COUNTRIES from '../../../../config/countries.json'

type ExtraType = {
  style?: Record<string, any>
}

interface MarketplaceCountryNameProps {
  value: string
  extra: ExtraType
}

const MarketplaceCountryName = ({value, extra}: MarketplaceCountryNameProps) => (
  <div>
    <Flag name={value.split('_')[1] as FlagNameValues} />
    <b style={extra.style && {...extra.style}}>
      {COUNTRIES[value.split('_')[1].toUpperCase() as keyof typeof COUNTRIES]}
    </b>
  </div>
)

MarketplaceCountryName.propTypes = {
  value: PropTypes.string.isRequired,
  extra: PropTypes.object,
}

MarketplaceCountryName.defaultProps = {
  extra: {},
}

export default MarketplaceCountryName
