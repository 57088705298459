import RelieverRegistry from 'react-redux-reliever'
import {createSelector} from 'reselect'

import dataSeriesFactory from '../utils/dataseries'

const appModuleState = state => RelieverRegistry.moduleState('app', state)

const rawRolesSelector = state => appModuleState(state).getIn(['user', 'roles'])
export const rolesSelector = createSelector(
  rawRolesSelector,
  roles => roles && roles.asMutable({deep: true}).map(r => r.toLowerCase())
)

const rawAccountsSelector = state => appModuleState(state).accounts
export const accountsSelector = createSelector(
  rawAccountsSelector,
  accounts => accounts && accounts.asMutable({deep: true})
)

export const userTenantSelector = state => appModuleState(state).getIn(['user', 'client'])

const rawAccountSelector = state => appModuleState(state).account
export const accountSelector = createSelector(rawAccountSelector, account => account && account.asMutable({deep: true}))

const rawMembersSelector = state => appModuleState(state).members
export const membersSelector = createSelector(rawMembersSelector, members => members && members.asMutable({deep: true}))

const rawUserSelector = state => appModuleState(state).user
export const userSelector = createSelector(rawUserSelector, user => user && user.asMutable({deep: true}))

export const userEmailSelector = state => appModuleState(state).getIn(['user', 'email'])

export const userIdSelector = state => appModuleState(state).getIn(['user', 'id'])

const rawBiDataSelector = state => appModuleState(state).getIn(['accountsBi', 'data'])
export const accountsSalesSelector = createSelector(rawBiDataSelector, data => data && data.asMutable({deep: true}))

export const accountsSalesCrossFilterSelector = createSelector(
  accountsSalesSelector,
  accountsSelector,
  (sales, accounts) => {
    if (!accounts || !sales) return null
    const _sales = []
    const groupedAccounts = accounts
      .lodash()
      .groupBy(x => x.client)
      .value()
    Object.keys(sales).forEach(client => {
      const account = groupedAccounts[client]
      if (!account) return
      sales[client][0].data.forEach(item => {
        _sales.push({
          client,
          ...item,
        })
      })
    })
    const series = dataSeriesFactory('AccountsSalesSeries', {data: _sales}).init({async: true})
    return series
  }
)

export const accountSalesCrossFilterSelector = account =>
  createSelector(accountsSalesCrossFilterSelector, series => {
    if (!series) return null
    series.updateFilters({client: account}, account)
    return series
  })
