import {Reliever} from 'react-redux-reliever'
import {ofType} from 'redux-observable'
import {catchError, map, mapTo, mergeMap, tap} from 'rxjs/operators'

import {downloadBlob, unbreakableV3SatanFormattedData} from '../utils'
import {KaioApi, MrSatanApi} from '../utils/apis'
import {getState} from '../utils/extensions/rx'
import {groupsSelector, usersSelector} from './selectors'

class ClientPermissionsReliever extends Reliever {
  ACTION_PREFIX = 'CLIENT-PERMISSIONS'

  getInitialState() {
    return {
      areActiveUsers: true,
      users: null,
    }
  }

  fetchGroupsEpic(action$) {
    return action$.pipe(
      ofType('CLIENT-PERMISSIONS_FETCH_GROUPS'),
      mergeMap(action =>
        KaioApi('fetchGroupsList').pipe(
          map(groups => {
            return {type: 'CLIENT-PERMISSIONS_FETCH_GROUPS_SUCCESS', payload: {groups}}
          }),
          tap(() => action._reqWrapper.end()),
          catchError(err => action._reqWrapper.fail({error: err}))
        )
      )
    )
  }

  fetchUsersEpic(action$) {
    return action$.pipe(
      ofType('CLIENT-PERMISSIONS_FETCH_USERS'),
      mergeMap(action =>
        KaioApi('fetchUsersList').pipe(
          map(users => {
            return {type: 'CLIENT-PERMISSIONS_FETCH_USERS_SUCCESS', payload: {users}}
          }),
          tap(() => action._reqWrapper.end()),
          catchError(err => action._reqWrapper.fail({error: err}))
        )
      )
    )
  }

  updateUserGroupsEpic(action$) {
    return action$.pipe(
      ofType('CLIENT-PERMISSIONS_UPDATE_USER_GROUPS'),
      mergeMap(action =>
        KaioApi('bulkSetGroups', action.users).pipe(
          mapTo({
            type: 'CLIENT-PERMISSIONS_UPDATE_USER_GROUPS_SUCCESS',
          }),
          tap(() => action._reqWrapper.end()),
          catchError(err => action._reqWrapper.fail({error: err}))
        )
      )
    )
  }

  exportUsersEpic(action$) {
    return action$.pipe(
      ofType('CLIENT-PERMISSIONS_EXPORT_USERS'),
      mergeMap(action => {
        return getState().pipe(
          mergeMap(state => {
            const groups = groupsSelector(state)
            const users = usersSelector(state)

            const headers = ['active', 'account', 'email', 'first_name', 'last_name', ...groups]

            const formattedData = Object.values(users || {}).map(user => {
              const premissions = user.groups.reduce((acc, group) => {
                acc[group] = true
                return acc
              }, {})
              return {
                active: user.active,
                account: user.account,
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                ...premissions,
              }
            })

            return MrSatanApi('generateExcel', {
              filename: 'UserPermissions.xlsx',
              sheets: [
                {
                  name: 'User Permissions',
                  content: unbreakableV3SatanFormattedData(formattedData, headers),
                  headers,
                },
              ],
            }).pipe(
              tap(blob => downloadBlob(blob, `UserPermisions.xlsx`)),
              mapTo({type: 'CLIENT-PERMISSIONS_EXPORT_USERS_SUCCESS'}),
              tap(() => action._reqWrapper.end()),
              catchError(err => action._reqWrapper.fail({error: err}))
            )
          })
        )
      })
    )
  }
}

export default ClientPermissionsReliever
