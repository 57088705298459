import React from 'react'

import styled from 'styled-components'

import colors from '../../colors'
import i18n from '../../i18n'

const StyledContainer = styled.div<{value: number; link: string | undefined}>`
  color: ${props => (props.value >= 0 ? colors.positive.rgba : colors.negative.rgba)};
  ${props =>
    props.link &&
    `:hover: {
    text-decoration: underline
  }`}
`

type ExtraType = {
  link?: string
}

type ItemType = {
  [key: string]: {}
}

interface RatingDiffProps {
  value: number
  extra?: ExtraType
  item: ItemType
}

export default function RatingDiff(key: string) {
  const _RatingDiff = ({value, extra, item}: RatingDiffProps) => {
    const signedDiff = value > 0 ? `+${value}` : `${value}`
    const signedRate = item[key] > 0 ? `(+${item[key]}%)` : `(${item[key]}%)`
    const handleClick = () => (extra?.link ? global.open(item[extra.link] as string) : null)
    if (!value && !item[key]) return <div>{i18n.t('placeholders.empty.notAvailable')}</div>
    return (
      <StyledContainer
        value={value}
        link={extra?.link}
        onClick={handleClick}
        style={{
          cursor: extra?.link ? 'pointer' : 'default',
        }}
      >
        {`${signedDiff} ${signedRate}`}
      </StyledContainer>
    )
  }

  return _RatingDiff
}
