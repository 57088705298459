import {Observable} from 'rxjs'

export default class Thread {
  constructor(fn) {
    this.fn = fn

    function worker(exec) {
      this.addEventListener('message', event => {
        const result = exec(event.data, data => {
          this.postMessage(data)
        })
        if (result != null) {
          this.postMessage(result)
        }
      })
    }

    const code = `(${worker.toString()})(${fn})`
    this.blob = new Blob([code], {type: 'application/javascript'})
    this.worker = new Worker(URL.createObjectURL(this.blob))
    this.results$ = Observable.create(observer => {
      this.worker.onmessage = ({data}) => observer.next(data)
      return () => {
        this.worker.onmessage = null
      }
    }).share()
  }

  run(arg) {
    this.worker.postMessage(arg)
    return this.results$
  }

  static use(fn) {
    return new Thread(fn)
  }
}
