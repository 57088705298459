import React from 'react'

import {Box, Row} from 'jsxstyle'
import {Icon, Label, Loader} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../../../../colors'
import i18n from '../../../../../i18n'
import LoaderWrapper from '../../../../../requesting/containers/LoaderWrapper'
import FilterButton from '../../../FilterButton'
import SearchableMultiSelect from '../../../inputs/SearchableMultiSelect'
import InNotIn from '../common/InNotIn'
import FilterBarItem from './FilterBarItem'

const StyledLabel = styled(Label)`
  &&&&& {
    display: flex;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px 8px;
  }
`

type ChoiceType = {
  name: string
  slug: string
}

interface AttributesProps {
  choices: Record<string, ChoiceType[]>
  data: Record<string, string[]>
  indexedCollectionsSlug: any // object.isRequired,
  onApply: () => void // func.isRequired,
  onChange: (changes: Record<string, string[]>) => void // func.isRequired,
  onClose: () => void // func.isRequired,
  onRemove: () => void // func.isRequired,
  title: string
  disabled?: boolean
  icon?: string | null
  operator?: 'in' | 'not_in'
  updateOperator?: ((operator: 'in' | 'not_in') => void) | null
}

interface AttributesState {
  currentFilters: any
}

class Attributes extends React.Component<AttributesProps, AttributesState> {
  constructor(props: AttributesProps) {
    super(props)
    this.state = {
      currentFilters: props.data,
    }
  }

  removeValues = (key: string, values: string[]) => {
    const newVal = this.props.data ? {...this.props.data} : {}
    newVal[key] = (newVal[key] || []).filter(inValue => !values.includes(inValue))
    this.props.onChange(newVal)
    setTimeout(this.props.onApply, 1)
    setTimeout(() => this.setState({currentFilters: this.props.data}), 2)
  }

  render() {
    const {
      choices,
      data,
      indexedCollectionsSlug,
      onApply,
      onChange,
      onClose,
      onRemove,
      title,
      disabled = false,
      icon = null,
      operator = 'in',
      updateOperator = null,
      ...barItemProps
    } = this.props
    const trigger = (
      <Row flexWrap="wrap" alignItems="center">
        {Object.values(data || {}).filter(v => v && v.length).length > 0 ? (
          Object.entries(data || {})
            .filter(([k, v]) => v && v.length > 0) // eslint-disable-line no-unused-vars
            .map(([k, values], i, arr) => (
              <FilterButton
                title={indexedCollectionsSlug[k]}
                icon={icon}
                onRemove={() => this.removeValues(k, values)}
                onApply={onApply}
                marginRight={arr.length - 1 === i ? 0 : 8}
                key={k}
                disabled={undefined}
                dropdown={undefined}
              >
                <Row alignItems="center" marginLeft="8px">
                  {values?.length && updateOperator ? <InNotIn operator={operator} /> : null}
                  {values.map((v, index) => (
                    <Row alignItems="center" marginLeft={index === 0 ? 0 : 5} key={v}>
                      <StyledLabel>
                        {
                          Object.values(choices)
                            ?.flat()
                            .find(value => value.slug === v)?.name
                        }
                        {!disabled && (
                          <Icon
                            name="delete"
                            onClick={(e: React.MouseEvent<HTMLElement>) => [
                              e.stopPropagation(),
                              this.removeValues(k, [v]),
                            ]}
                          />
                        )}
                      </StyledLabel>
                    </Row>
                  ))}
                </Row>
              </FilterButton>
            ))
        ) : (
          <FilterButton
            title={i18n.t('filters.attributesTitle')}
            icon={icon}
            disabled={disabled}
            onRemove={onRemove}
            onApply={onApply}
            dropdown={undefined}
          >
            <LoaderWrapper
              requesting={[
                'collections.offerGroups.fetch',
                'collections.offerCollections.fetch',
                'catalogue.offersData.fetch',
                'collections.offerGroupPivots.fetch',
              ]}
              render={(requesting: boolean) => (
                <Box marginLeft="8px" fontWeight={400} color={colors.lightDark}>
                  {requesting ? i18n.t('loaders.loadingAttributes') : null}
                  <Box marginLeft={4} position="relative" bottom={2} display="inline">
                    <Loader inline active={requesting} disabled={!requesting} size="mini" />
                  </Box>
                </Box>
              )}
            />
          </FilterButton>
        )}
      </Row>
    )

    return (
      <FilterBarItem
        {...barItemProps}
        containsPortal
        data={this.state.currentFilters}
        disabled={disabled}
        onApply={onApply}
        onChange={onChange}
        onClose={onClose}
        trigger={trigger}
        wrapped={false}
      >
        <LoaderWrapper
          proxy
          requesting={[
            'collections.offerGroups.fetch',
            'collections.offerCollections.fetch',
            'catalogue.offersData.fetch',
            'collections.offerGroupPivots.fetch',
          ]}
        >
          <SearchableMultiSelect
            title={title}
            placeholder={i18n.t('filters.byAttribute', {attribute: title})}
            icon={icon}
            onChange={this.props.onChange}
            onApply={() => [this.setState({currentFilters: data}), onApply()]}
            onClose={onClose}
            value={data}
            choices={choices}
            indexedCollectionsSlug={indexedCollectionsSlug}
            updateOperator={updateOperator}
            operator={operator}
          />
        </LoaderWrapper>
      </FilterBarItem>
    )
  }
}

export default Attributes
