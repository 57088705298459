import React from 'react'

import {Col, Row} from 'jsxstyle'
import {Label} from 'semantic-ui-react'
import styled from 'styled-components'

import {clamp} from '../..'
import colors from '../../colors'
import {PoppersPopup} from '../../common-ui'
import i18n from '../../i18n'

const StyledAdvertisingCell = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  :hover {
    text-decoration: underline;
    cursor: pointer;
    color: ${colors.primary.rgba};
  }
`

const StyledTrigger = styled.span`
  cursor: pointer;
  margin-left: 5px;
  color: ${colors.darkLight.rgba};
  font-size: 0.85em;
  :hover {
    text-decoration: underline;
  }
`

type TagType = {
  [slug: string]: {
    name: string
    color: string
  }
}

type EventClick = {
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
  [key: string]: any
}

type ItemType = {
  name?: string
  tags?: string[]
  key: string
}

type ExtraType = {
  onClick?: (props: EventClick) => void
  tagList?: TagType
  settings?: {
    showTags?: boolean
  }
  displayTagsModal?: (item: ItemType) => void | null
}

interface AdvertisingCellProps {
  extra?: ExtraType
  item: ItemType
  value?: string
}

const displayTags = (tags: string[], item: ItemType, extra: ExtraType) => {
  const {displayTagsModal = null, tagList = {}} = extra
  return (
    <Row alignItems="center">
      {tags.slice(0, 2).map(slug =>
        tagList[slug] ? (
          <Label
            key={slug}
            value={slug}
            style={{
              backgroundColor: tagList[slug] ? tagList[slug].color : '#aaa',
              color: 'white',
              padding: '4px 6px',
              margin: '4px 0 4px 2px',
            }}
          >
            {tagList[slug] ? clamp(tagList[slug].name, 12) : ''}
          </Label>
        ) : null
      )}
      {tags.length > 2 && displayTagsModal && (
        <StyledTrigger
          onClick={e => {
            e.stopPropagation()
            displayTagsModal(item)
          }}
        >
          {i18n.t('settings.tags.seeAll', {count: tags.length})}
        </StyledTrigger>
      )}
    </Row>
  )
}

const AdvertisingCell = ({item, extra = {}, value = '', ...rest}: AdvertisingCellProps) => {
  const tags = item.tags || []
  const {showTags = true} = extra?.settings ?? {}

  return (
    <Row maxWidth="100%" alignItems="center">
      <Col maxWidth="100%" paddingRight={10}>
        <PoppersPopup
          position="top center"
          content={item.name || i18n.t('placeholders.empty.notAvailable')}
          trigger={
            <StyledAdvertisingCell
              onClick={event => {
                if (extra.onClick) extra.onClick({event, value, item, key: item.key, ...rest})
              }}
            >
              {item.name || i18n.t('placeholders.empty.notAvailable')}
            </StyledAdvertisingCell>
          }
        />
        {showTags && <div>{tags.length > 0 && extra.tagList ? displayTags(tags, item, extra) : null}</div>}
      </Col>
    </Row>
  )
}

export default AdvertisingCell
