import React from 'react'

import {Box} from 'jsxstyle'

import i18n from '../../../../../i18n'
import FilterButton from '../../../FilterButton'
import VirtualFilterSearch from '../../../inputs/VirtualFilterSearch'

interface BrandsProps {
  choices: string[]
  data: string[]
  onApply: () => void
  onChange: () => void
  onRemove: () => void
  title: string
  disabled?: boolean
  icon?: string | null
}

const Brands = ({
  choices,
  data,
  onApply,
  onChange,
  onRemove,
  title,
  disabled = false,
  icon = null,
  ...barItemProps
}: BrandsProps) => {
  const brandsCount = Brands.count(data)
  const trigger = (
    <FilterButton
      title={data.length ? title : i18n.t('filters.brandsTitle')}
      icon={icon}
      disabled={disabled}
      onRemove={onRemove}
      onApply={onApply}
      dropdown={undefined}
    >
      <Box marginLeft="8px">{brandsCount ? `(${brandsCount})` : null}</Box>
    </FilterButton>
  )

  return (
    <VirtualFilterSearch
      data={data}
      onChange={onChange}
      onRemove={onRemove}
      title={title}
      choices={choices}
      disabled={disabled}
      onApply={onApply}
      placeholder={i18n.t('actions.search.brands')}
      trigger={trigger}
      {...barItemProps}
    />
  )
}

Brands.count = (data: string[]) => data.length

export default Brands
