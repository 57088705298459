import React from 'react'

import {Box, Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import {connect} from 'react-redux-reliever'
import {Image, Loader, Search, Tab} from 'semantic-ui-react'
import Cookies from 'universal-cookie'

import KaioApiClient from '../../../utils/apis/KaioApiClient'
import colors from '../../../utils/colors'
import {SeelkBadge} from '../../../utils/common-ui'
import {variable} from '../../../utils/extensions/rx'
import i18n from '../../../utils/i18n'
import theme from '../../../utils/theme'
import {accountsSelector, userSelector} from '../../selectors'

import './style.css'

const avatar = theme.createClassName('selectClientAvatar', {
  borderColor: colors.white.rgba,
  height: 38,
  width: 38,
  objectFit: 'contain',
  backgroundColor: colors.white.rgba,
  boxShadow: `0px 0px 2px ${colors.shadow.rgba}`,
  marginLeft: '15px',
})

const clientItem = theme.createClassName('selectClientItem', {
  ':hover': {
    backgroundColor: colors.primaryLight.rgba,
  },
})

const Component = React.mixins().addUnmountedSubject().addMonitoring().build()

export class ChooseClient extends Component {
  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/no-unused-prop-types
  }

  state = {
    filteredOptions: [],
  }

  text = variable(null)

  UNSAFE_componentWillMount() {
    this.initComponent(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.initComponent(nextProps)
  }

  state = {
    filteredOptions: [],
    loading: false,
  }

  initComponent(props) {
    let account = props.user.client
    let options = props.accounts.map(a => ({
      text: a.name,
      value: a.client,
      image: {avatar: true, src: a.logo},
      account_type: a.account_type,
    }))
    if (!account) {
      options = [
        {
          text: i18n.t('actions.select.shortAccount'),
          value: 'null',
          image: {avatar: true, src: 'https://static.leboncoin.fr/img/no-picture.png'},
        },
        ...options,
      ]
    }
    account = props.accounts.find(a => a.client === account)
    props.didResolveCurrentAccount(account)
    this.setState({account, filteredOptions: options})
  }

  handleChange = async (e, {value}) => {
    if (value !== 'null' && (!this.state.account || value !== this.state.account.client)) {
      this.setState({loading: true}, async () => {
        new Cookies().set('account', value, {path: '/'})

        await new KaioApiClient().refreshToken(value)

        window.location = '/'
        // if (/(competition)|(keywords)|(product)/.test(window.location)) {
        //   window.location = '/'
        // } else {
        //   window.location = window.location
        // }
      })
    }
  }

  updateText = (event, {value}) => {
    this.text.value = value
  }

  renderDefaultPane(accountType) {
    const selectedAccount = (this.state.account && this.state.account.client) || ''

    const accounts =
      accountType === 'all'
        ? this.state.filteredOptions
        : this.state.filteredOptions.filter(account => account.account_type.includes(accountType))

    return accounts
      .sort((lhs, rhs) => lhs.text.localeCompare(rhs.text))
      .map(account => {
        const allBadges = account.account_type.map((type, i) => (
          <SeelkBadge
            absolutePosition
            type={type}
            key={account.client + type}
            position={{
              bottom: '0',
              left: `calc(100% + ${(i * 30).toString()}px + 10px)`,
            }}
          />
        ))

        return (
          <Box key={account.value}>
            <Col
              props={{onClick: () => this.handleChange(null, account)}}
              padding="16px"
              cursor="pointer"
              className={selectedAccount === account.value ? null : clientItem}
              backgroundColor={selectedAccount === account.value ? colors.primary.rgba : 'transparent'}
            >
              <Row alignItems="center" fontWeight="500">
                <Image src={account.image.src} className={avatar} size="mini" circular />
                <Box
                  marginLeft="12px"
                  color={selectedAccount === account.value ? colors.white.rgba : colors.primaryDark.rgba}
                  position="relative"
                >
                  {account.text}
                  {allBadges}
                </Box>
              </Row>
            </Col>
          </Box>
        )
      })
  }

  renderPanes() {
    return [
      {
        menuItem: {
          key: 'all',
          content: i18n.t('visualization.grouping.all'),
        },
        render: () => this.renderDefaultPane('all'),
      },
      {
        menuItem: {
          key: 'saas',
          content: i18n.t('onboarding.index.saas'),
        },
        render: () => this.renderDefaultPane('saas'),
      },
      {
        menuItem: {
          key: 'agency',
          content: i18n.t('onboarding.index.agency'),
        },
        render: () => this.renderDefaultPane('agency'),
      },
      {
        menuItem: {
          key: 'prospect',
          content: i18n.t('onboarding.index.prospect'),
        },
        render: () => this.renderDefaultPane('prospect'),
      },
    ]
  }

  render() {
    return (
      <Col height="100%" width="100%" backgroundColor={colors.white.rgba}>
        <Box padding="8px" width="100%" borderBottom={`solid 1px ${colors.border.rgba}`}>
          <Search
            onSearchChange={this.updateText}
            showNoResults={false}
            className="client-search-input"
            placeholder={i18n.t('placeholders.global.searchClient')}
            fluid
          />
        </Box>
        <Col flex="1" overflowY="scroll">
          {this.state.loading && (
            <Box
              width="100vw"
              height="100vh"
              position="absolute"
              backgroundColor={colors.white.alpha(0.5).rgba}
              zIndex="9999"
              top="0px"
              left="0px"
            >
              <Loader active />
            </Box>
          )}
          <Row minWidth="100%" height="calc(100% - 55px)">
            <Tab
              renderActiveOnly
              as={Col}
              flex="1 0 auto"
              menu={{
                compact: true,
                pointing: true,
                secondary: true,
                attached: false,
              }}
              panes={this.renderPanes()}
              grid={null}
            />
          </Row>
        </Col>
      </Col>
    )
  }
}

export default connect({
  props: (state, ownProps) => ({
    accounts: accountsSelector(state),
    user: userSelector(state),
    ...ownProps,
  }),
})(ChooseClient)
