import React from 'react'

import {css, StyleSheet} from 'aphrodite'
import {Motion, spring} from 'react-motion'
import {Image} from 'semantic-ui-react'

import gokuRunning from './images/goku-running.gif'

const styles = StyleSheet.create({
  image: {
    height: 100,
    width: 'auto',
    display: 'initial',
  },
})

class RunningGoku extends React.Component {
  state = {
    direction: 'left',
  }

  UNSAFE_componentWillMount() {
    this.toggleRun()
  }

  toggleRun = () => {
    const rand = Math.random() * (10 * 60) + 2 * 60
    this.run = setTimeout(() => {
      this.setState({direction: this.state.direction === 'left' ? 'right' : 'left'})
      this.toggleRun()
    }, rand * 1000)
  }

  render() {
    const springStyle = {
      x: spring(this.state.direction === 'left' ? 140 : -40, {
        precision: 0.5,
        stiffness: 70,
        damping: 100,
      }),
    }
    return (
      <div style={{position: 'absolute', bottom: 0, width: '100%', pointerEvents: 'none'}}>
        <Motion style={springStyle}>
          {({x}) => (
            <Image
              style={{
                left: `${x}%`,
                transform: `scaleX(${this.state.direction === 'left' ? 1 : -1})`,
              }}
              className={css(styles.image)}
              src={gokuRunning}
            />
          )}
        </Motion>
      </div>
    )
  }

  componentWillUnmount() {
    clearTimeout(this.run)
  }
}

export default RunningGoku
