import _ from 'lodash'
import RelieverRegistry from 'react-redux-reliever'
import {createSelector} from 'reselect'

import dataSeriesFactory from '../utils/dataseries'
import {createLastResultSelector} from '../utils/reselect'

export const clientTrackerModuleState = state => {
  return RelieverRegistry.moduleState('client-tracker', state)
}

export const appModuleState = state => {
  return RelieverRegistry.moduleState('app', state)
}

const rawFiltersSelector = state => {
  return clientTrackerModuleState(state).filters
}
export const filtersSelector = createSelector(rawFiltersSelector, rawFilters => {
  if (!rawFilters) return null
  return rawFilters.asMutable({deep: true})
})

const rawCategoriesSelector = state => clientTrackerModuleState(state).categories
export const categoriesSelector = createSelector(
  rawCategoriesSelector,
  categories => categories && categories.asMutable({deep: true})
)

const rawKeywordsSelector = state => clientTrackerModuleState(state).keywords
export const keywordsSelector = createSelector(
  rawKeywordsSelector,
  keywords => keywords && keywords.asMutable({deep: true})
)

const rawOffersSelector = state => clientTrackerModuleState(state).offers
export const offersSelector = createSelector(rawOffersSelector, offers => offers && offers.asMutable({deep: true}))

const rawAccountsSelector = state => appModuleState(state).accounts
export const accountsSelector = createSelector(
  rawAccountsSelector,
  accounts => accounts && accounts.asMutable({deep: true})
)

const rawEditTableSelector = state => appModuleState(state).editTable
export const editTableSelector = createSelector(
  rawEditTableSelector,
  accounts => accounts && accounts.asMutable({deep: true})
)

export const clientsListSelector = createSelector(
  categoriesSelector,
  keywordsSelector,
  offersSelector,
  (categories, keywords, offers) => {
    const clientsList = new Set()

    if (categories && Object.keys(categories).length)
      Object.keys(categories).forEach(category => clientsList.add(category))
    if (keywords && Object.keys(keywords).length) Object.keys(keywords).forEach(keyword => clientsList.add(keyword))
    if (offers && Object.keys(offers).length) Object.keys(offers).forEach(offer => clientsList.add(offer))

    return Array.from(clientsList).filter(v => !!v)
  }
)

export const mergedClientTrackingInformationsSelector = createSelector(
  clientsListSelector,
  categoriesSelector,
  keywordsSelector,
  offersSelector,
  accountsSelector,
  (clients, categories, keywords, offers, accounts) => {
    if (!offers || !categories || !keywords || !accounts) return []

    const getCountForType = (type, data) => {
      if (data) {
        return Object.keys(data).reduce(
          (parent, key) => {
            const suffix =
              data[key].scraping_frequency === null || data[key].scraping_frequency === '0'
                ? '0'
                : data[key].scraping_frequency

            parent[`${type}${suffix}`] = data[key].total // eslint-disable-line no-param-reassign
            parent[`${type}Number`] += data[key].total // eslint-disable-line no-param-reassign
            // eslint-disable-next-line no-param-reassign
            parent[`${type}MaxValue`] =
              data[key].total > parent[`${type}MaxValue`] ? data[key].total : parent[`${type}MaxValue`]
            return parent
          },
          {
            [`${type}Number`]: 0,
            [`${type}MaxValue`]: 0,
          }
        )
      }
      return {[`${type}Number`]: 0}
    }

    const mergedData = clients
      .sort((lhs, rhs) => lhs.localeCompare(rhs))
      .reduce((parent, client) => {
        const account = accounts.find(item => item.client === client)
        parent.push({
          client,
          accountTypes: account ? account.account_type : [],
          ...getCountForType('categories', categories[client]),
          ...getCountForType('keywords', keywords[client]),
          ...getCountForType('products', offers[client]),
        })

        return parent
      }, [])

    return mergedData
  }
)

const clientTrackingInformationsSelectorCreator = () =>
  createLastResultSelector(mergedClientTrackingInformationsSelector, mergedClientTrackingInformations => {
    if (!mergedClientTrackingInformations) return null
    return dataSeriesFactory('ClientTrackingSeries', {
      data: mergedClientTrackingInformations,
      extraDimensions: null,
    }).init()
  })

export const crossFilterClientTrackingInformationsSelector = createSelector(
  clientTrackingInformationsSelectorCreator(),
  mergedClientTrackingInformationsSelector,
  filtersSelector,
  (crossFilter, clientTrackingInformations, filters) => {
    if (!crossFilter || !clientTrackingInformations) return null
    if (clientTrackingInformations !== crossFilter.previousClientTrackingInformations) {
      crossFilter.reset(clientTrackingInformations)
      // eslint-disable-next-line no-param-reassign
      crossFilter.previousClientTrackingInformations = clientTrackingInformations
    }
    crossFilter.updateFilters(filters)
    return crossFilter
  }
)

export const limitsRangesFiltersSelector = createSelector(
  mergedClientTrackingInformationsSelector,
  clientTrackingInformations => {
    let minCategoriesNumber = 0
    let maxCategoriesNumber = 100
    let minKeywordsNumber = 0
    let maxKeywordsNumber = 100
    let minProductsNumber = 0
    let maxProductsNumber = 100

    if (clientTrackingInformations && clientTrackingInformations.length) {
      minCategoriesNumber = _.minBy(clientTrackingInformations, 'categoriesNumber').categoriesNumber
      maxCategoriesNumber = _.maxBy(clientTrackingInformations, 'categoriesNumber').categoriesNumber
      minKeywordsNumber = _.minBy(clientTrackingInformations, 'keywordsNumber').keywordsNumber
      maxKeywordsNumber = _.maxBy(clientTrackingInformations, 'keywordsNumber').keywordsNumber
      minProductsNumber = _.minBy(clientTrackingInformations, 'productsNumber').productsNumber
      maxProductsNumber = _.maxBy(clientTrackingInformations, 'productsNumber').productsNumber
    }

    return {
      minKeywordsNumber,
      maxKeywordsNumber,
      minProductsNumber,
      maxProductsNumber,
      minCategoriesNumber,
      maxCategoriesNumber,
    }
  }
)
