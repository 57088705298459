import {defer} from 'rxjs'

import SenzuApiClient from './SenzuApiClient'

export default class SenzuApiClientTestOrNot {
  constructor() {
    return new SenzuApiClient()
  }
}

export const SenzuApi = (method, ...args) => defer(async () => new SenzuApiClientTestOrNot()[method](...args))
