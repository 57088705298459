import ReduxRelieverRegistry, {plugins} from 'react-redux-reliever'
import {from, queueScheduler} from 'rxjs'

import config from '../../config'
import LoggerPlugin from './plugins/logger'

// rx
ReduxRelieverRegistry.use(plugins.RxRelieverPlugin, {
  input: action$ => {
    return from(action$, queueScheduler) // we do this to replace ActionsObservable with Observable so we can have our custom methods
  },
})

// logger
if (config.environment === 'dev') {
  ReduxRelieverRegistry.use(LoggerPlugin)
}

export default ReduxRelieverRegistry
