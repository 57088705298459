import React from 'react'

import {Box} from 'jsxstyle'
import _ from 'lodash'
import memoizeOne from 'memoize-one'
import {Dropdown, DropdownOnSearchChangeData, Flag, FlagNameValues} from 'semantic-ui-react'
import styled from 'styled-components'

import {betterAMZImage, fuzzyWordsSearch} from '../..'
import i18n from '../../i18n'
import {Flag as FlagCell, Product, VirtualTable} from '../../visualization'

const StyledDropdown = styled(Dropdown)`
  &&&&& > i {
    /* padding: 10px; */
  }
`

const StyledDropdownMenu = styled(Dropdown.Menu)`
  &&&&& {
    overflow-y: hidden;
    height: 300px;
  }
`

interface OfferType {
  id: string
  [key: string]: any
}

interface OfferSearchSelectProps {
  offers: OfferType | {}
  onRowClick: (id: string) => void
  selectedOffer?: string | null
  tagsList?: any
}

interface OfferSearchSelectState {
  nameSearch: string
  open: boolean
}

class OfferSearchSelect extends React.Component<OfferSearchSelectProps, OfferSearchSelectState> {
  constructor(props: OfferSearchSelectProps) {
    super(props)
    this.state = {
      nameSearch: '',
      open: false,
    }
  }

  componentWillUnmount() {
    this.onSearchDebounced.cancel()
  }

  scheme = {
    country: {
      label: '',
      key: 'country',
      width: 70,
      component: FlagCell,
      style: {
        justifyContent: 'center',
      },
    },
    product: {
      label: ``,
      key: 'key',
      width: 300,
      canGrow: true,
      component: Product,
      style: {fontWeight: 500},
      props: {},
    },
  }

  onSearch = (value: string) => {
    this.setState({nameSearch: value})
  }

  onSearchDebounced = _.debounce(this.onSearch, 300)

  resetDropdownState = (open = false) => {
    this.setState({open})
    this.onSearchDebounced('')
  }

  onRowClick = (rowData: OfferType) => [this.props.onRowClick(rowData.id), this.resetDropdownState(false)]

  getOfferItems = memoizeOne((offers: OfferType | {}, search: string) =>
    Object.entries(offers ?? {})
      .filter(
        ([, value]) =>
          fuzzyWordsSearch(search, value.title ?? '') ||
          fuzzyWordsSearch(search, value.offer?.asin ?? '') ||
          fuzzyWordsSearch(search, value.asin ?? '')
      )
      .map(([key, value]) => ({
        ...value,
        asin: key.substring(0, 10),
        country: key.slice(-2) ?? null,
        imageUrl: value.imageUrl ?? betterAMZImage(value.images_url?.[0], 5),
      }))
  )

  getSelectedOffer = () => {
    const {selectedOffer = null} = this.props
    if (!selectedOffer || this.state.open) return null

    const [asin, marketplace] = selectedOffer.split(':')
    const country = marketplace.slice(-2) ?? null

    return (
      <>
        <Flag name={country as FlagNameValues} />
        <span>{asin}</span>
      </>
    )
  }

  render() {
    const {offers = {}, selectedOffer = null, tagsList = {}} = this.props
    const offerItems = this.getOfferItems(offers, this.state.nameSearch)
    this.scheme.product.props = {
      tagList: tagsList,
    }
    return (
      <Box flex="1 0 auto">
        <StyledDropdown
          fluid
          closeOnChange
          className="selection"
          open={this.state.open}
          onClick={() => this.resetDropdownState(!this.state.open)}
          onBlur={() => this.resetDropdownState(false)}
          search
          text={this.getSelectedOffer()}
          value={selectedOffer}
          placeholder={i18n.t('actions.search.productTitleAsin')}
          onSearchChange={(e: React.SyntheticEvent<HTMLElement>, {searchQuery}: DropdownOnSearchChangeData) => [
            this.setState({open: true}),
            this.onSearchDebounced(searchQuery),
          ]}
        >
          <StyledDropdownMenu>
            {offers && (
              <VirtualTable
                // height={300}
                headerHeight={0}
                items={offerItems}
                columns={Object.values(this.scheme)}
                rowHeight={70}
                overflowX
                disableHeader
                shouldSplitColor={false}
                onRowClick={({rowData}: any) => [this.setState({open: false}), this.onRowClick(rowData)]}
              />
            )}
          </StyledDropdownMenu>
        </StyledDropdown>
      </Box>
    )
  }
}

export default OfferSearchSelect
