import React from 'react'

import {Label} from 'semantic-ui-react'

import {clamp} from '../..'
import i18n from '../../i18n'
import PoppersPopup from './PoppersPopup'

interface KeywordLabelProps {
  keyword: string
  keywordSize?: number | null
}

const KeywordLabel = ({keyword = '', keywordSize = null}: KeywordLabelProps) => (
  <Label
    key={keyword}
    value={keyword}
    style={{
      padding: '4px 6px',
      margin: '4px 0 4px 2px',
      whiteSpace: 'nowrap',
    }}
  >
    {keyword ? clamp(keyword, keywordSize ?? undefined) : ''}
  </Label>
)

interface KeywordsProps {
  id: string
  keyword: {
    keyword: string
    last_scraping_date: string // eslint-disable-line camelcase
    marketplace: string
  }
  marketplace: string
  scraping_frequency: number // eslint-disable-line camelcase
  tags: string[]
  text: string
}

interface SeelkKeywordsProps {
  maxDisplay: number
  keywords?: KeywordsProps[]
}

class SeelkKeywords extends React.Component<SeelkKeywordsProps> {
  render() {
    const {maxDisplay = 0, keywords = []} = this.props

    const keywordSize = keywords.length > 2 ? 12 : 16
    return [
      keywords?.slice(0, maxDisplay || keywords.length).map(kw => (
        <PoppersPopup
          hoverable
          key={kw.id}
          position="top center"
          trigger={
            <div>
              <KeywordLabel keyword={kw.text} keywordSize={keywordSize} />
            </div>
          }
          content={<KeywordLabel keyword={kw.text} />}
        />
      )),

      maxDisplay && keywords.length > maxDisplay ? (
        <PoppersPopup
          hoverable
          key={i18n.t('placeholders.global.more', {count: keywords.length - maxDisplay})}
          position="top center"
          trigger={
            <div>
              <KeywordLabel
                keyword={i18n.t('placeholders.global.more', {count: keywords.length - maxDisplay})}
                keywordSize={keywordSize}
              />
            </div>
          }
          content={keywords
            ?.slice(maxDisplay, keywords.length)
            .map(kw => (kw ? <KeywordLabel keyword={kw.text} /> : null))}
        />
      ) : null,
    ]
  }
}

export default SeelkKeywords
