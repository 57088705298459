import React from 'react'

import {Label} from 'semantic-ui-react'
import styled from 'styled-components'

import {capitalize, clamp} from '../..'
import colors from '../../colors'
import i18n from '../../i18n'
import PoppersPopup from './PoppersPopup'

const StyledFilterContainer = styled.div`
  display: inline-block;
  border-radius: 3px;
  background-color: ${colors.light.rgba};
  margin: 3px;
  padding: 5px;
`

const StyledFilterName = styled.span`
  font-size: 12px;
  color: ${colors.darkLight.rgba};
`

const StyledLabel = styled(Label)`
  &&&&& {
    padding: 3px 5px;
    background-color: ${props => (props.color ? props.color : colors.darkLight.rgba)};
    color: ${colors.white.rgba};
    span {
      font-size: 11px;
      font-weight: 300;
    }
    b {
      font-size: 11px;
      font-weight: 500;
    }
  }
`

const StyledPopup = styled(PoppersPopup)`
  &&& {
    padding: 5px 8px;
    font-size: 12px;
  }
`

const StyledTrigger = styled.div`
  display: inline;
  margin: 0 2px;
`

interface TagType {
  slug: string
  name: string
  color: string
}

interface StaticTagFiltersProps {
  tags: TagType[]
  type: string
}

const StaticTagFilters = ({tags = [], type}: StaticTagFiltersProps) => {
  if (!tags?.length) return null
  const tagSize = tags.length > 2 ? 12 : 16
  return (
    <StyledFilterContainer>
      <StyledFilterName>{`${i18n.t(`filters.${type}`) || capitalize(type)}: `}</StyledFilterName>
      {tags.map(({slug, name, color}) => (
        <StyledPopup
          hoverable
          key={slug}
          position="top center"
          trigger={
            <StyledTrigger>
              <StyledLabel color={color}>
                <b>{name ? clamp(name, tagSize) : ''}</b>
              </StyledLabel>
            </StyledTrigger>
          }
          content={name}
        />
      ))}
    </StyledFilterContainer>
  )
}

export default StaticTagFilters
