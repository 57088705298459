import {defer} from 'rxjs'

import BardockApiClient from './BardockApiClient'

export default class BardockApiClientTestOrNot {
  constructor() {
    return new BardockApiClient()
  }
}

export const BardockApi = (method, ...args) => defer(async () => new BardockApiClientTestOrNot()[method](...args))
