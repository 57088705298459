import _ from 'lodash'
import moment from 'moment'
import {Reliever} from 'react-redux-reliever'
import {ofType} from 'redux-observable'
import {throwError} from 'rxjs'
import {catchError, mapTo, mergeMap, tap} from 'rxjs/operators'

import {BulmaApi, MrSatanApi} from '../utils/apis'

class SearchTermsAuditReliever extends Reliever {
  ACTION_PREFIX = 'SEARCH_TERMS_AUDIT'

  getInitialState() {
    return {}
  }

  importAdsFileEpic(action$) {
    return action$.pipe(
      ofType('SEARCH_TERMS_AUDIT_IMPORT'),
      mergeMap(action =>
        MrSatanApi('fileToJson', action.file, {
          headers_line: 0,
          options: {slugify_headers: 'False'},
        }).pipe(
          mergeMap(fileData => {
            const {marketplace, reportDate, reportName, period} = action
            const {headers, sheet_data: sheetData} = fileData

            if (!['Generic', 'Excluded', 'Asins'].every(element => headers.indexOf(element) > -1))
              return throwError(`Your file must contain the columns "Generic", "Excluded" and "Asins"`)

            const brandedHeaders = _.without(headers, 'Generic', 'Excluded', 'Asins', 'None').reduce((acc, header) => {
              acc[header] = []
              return acc
            }, {})

            const data = sheetData.reduce(
              (acc, {Generic, Excluded, Asins, None, ...branded}) => {
                if (Generic) acc.keywords.generic.push(Generic)
                if (Excluded) acc.keywords.excluded.push(Excluded)
                if (Asins) acc.asins.push(Asins)
                Object.entries(branded).forEach(([key, value]) => {
                  if (value) acc.keywords.branded[key].push(value)
                })
                return acc
              },
              {keywords: {branded: brandedHeaders, generic: [], excluded: []}, asins: []}
            )

            const params = {
              frame_id: 'search_terms_audit_generate',
              parameters: {
                marketplace,
                report_date: moment(reportDate).startOf('month').format('YYYY-MM-DD'),
                report_name: reportName,
                period,
                ...data,
              },
            }

            return BulmaApi('userPipeline', params).pipe(
              mapTo({
                type: 'SEARCH_TERMS_AUDIT_IMPORT_SUCCESS',
              }),
              tap(() => action._reqWrapper.end()),
              catchError(err => action._reqWrapper.fail({error: err}))
            )
          }),
          catchError(err => action._reqWrapper.fail({error: err}))
        )
      )
    )
  }
}

export default SearchTermsAuditReliever
