import {Reliever} from 'react-redux-reliever'
import {ofType} from 'redux-observable'
import {catchError, mapTo, mergeMap, tap} from 'rxjs/operators'

import {KaioApi} from '../utils/apis'
import {mapAsync} from '../utils/extensions/rx'

class ForgotPasswordReliever extends Reliever {
  ACTION_PREFIX = 'FORGOT-PASSWORD'

  getInitialState() {
    return {}
  }

  sendResetPasswordMailEpic(action$) {
    return action$.pipe(
      ofType('FORGOT-PASSWORD_SEND_RESET_PASSWORD_MAIL'),
      mergeMap(action =>
        KaioApi('sendResetPasswordMail', action.email).pipe(
          mapTo({
            type: 'FORGOT-PASSWORD_SEND_RESET_PASSWORD_MAIL_SUCCESS',
          }),
          tap(() => action._reqWrapper.end()),
          catchError(err => {
            if (err.code === 400) {
              return mapAsync(err.response.json()).map(result => action._reqWrapper.fail(Object.values(result)[0][0]))
            }
            return action._reqWrapper.fail({error: err})
          })
        )
      )
    )
  }
}

export default ForgotPasswordReliever
