import React from 'react'

import {Box} from 'jsxstyle'
import _ from 'lodash'
import {Dropdown, DropdownOnSearchChangeData} from 'semantic-ui-react'
import styled from 'styled-components'

import {VirtualTable} from '../../visualization'

const StyledDropdown = styled(Dropdown)`
  &&&&& > i {
    padding: 10px;
  }
`

const StyledDropdownMenu = styled(Dropdown.Menu)`
  &&&&& {
    overflow-y: hidden;
  }
`

interface SchemeType {
  label: string
  key: string
  width: number
  component: React.ReactNode
  style: Record<string, any>
}

interface OptionType {
  id: string
  [key: string]: any
}

interface VirtualSearchSelectProps {
  options?: OptionType[]
  placeholder: string
  handleSearch: (value: string) => void
  onRowClick: (id: string) => void
  scheme: SchemeType[]
  selectedOptionTrigger?: React.ReactNode
  selectedOption?: string
}

interface VirtualSearchSelectState {
  open: boolean
}

class VirtualSearchSelect extends React.Component<VirtualSearchSelectProps, VirtualSearchSelectState> {
  constructor(props: VirtualSearchSelectProps) {
    super(props)
    this.state = {
      open: false,
    }
  }

  onSearch = (value: string) => {
    this.props.handleSearch(value)
  }

  onSearchDebounced = _.debounce(this.onSearch, 300)

  resetDropdownState = (open = false) => {
    this.setState({open})
    this.onSearch('')
  }

  onRowClick = (rowData: OptionType) => [this.props.onRowClick(rowData.id), this.resetDropdownState(false)]

  render() {
    const {options = [], scheme, selectedOptionTrigger = null, selectedOption = null, placeholder} = this.props
    return (
      <Box flex="1 0 auto">
        <StyledDropdown
          icon="search"
          fluid
          closeOnChange
          className="selection"
          open={this.state.open}
          onClick={() => this.resetDropdownState(!this.state.open)}
          onBlur={() => this.resetDropdownState(false)}
          search
          text={selectedOptionTrigger}
          value={selectedOption}
          placeholder={placeholder}
          onSearchChange={(e: React.SyntheticEvent<HTMLElement>, {searchQuery}: DropdownOnSearchChangeData) =>
            this.onSearchDebounced(searchQuery)
          }
        >
          <StyledDropdownMenu>
            {options && (
              <VirtualTable
                height={300}
                headerHeight={0}
                items={options}
                columns={scheme}
                rowHeight={70}
                overflowX
                disableHeader
                shouldSplitColor={false}
                onRowClick={({rowData}: {rowData: any}) => [this.setState({open: false}), this.onRowClick(rowData)]}
              />
            )}
          </StyledDropdownMenu>
        </StyledDropdown>
      </Box>
    )
  }
}

export default VirtualSearchSelect
