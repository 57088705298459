import ScrapingsSeries from './ScrapingsSeries'

class CatalogueChangesSeries extends ScrapingsSeries {
  createDimensions() {
    super.createDimensions()
    this.cf.dimensions.compliance = this.cf.data.dimension(v => v.changes.score || 0)
    this.cf.dimensions.imgCompliance = this.cf.data.dimension(v =>
      v.changes.image ? v.changes.image.matchPercentage : 0
    )
    this.cf.dimensions.isTitleMatched = this.cf.data.dimension(v =>
      v.changes.title ? (v.changes.title.checked ? 'MATCH' : 'CONFLICT') : 'NO_CONTENT'
    )
    this.cf.dimensions.isBulletPointsMatched = this.cf.data.dimension(v =>
      v.changes.bullet_points ? (v.changes.bullet_points.checked ? 'MATCH' : 'CONFLICT') : 'NO_CONTENT'
    )
    this.cf.dimensions.isDescriptionMatched = this.cf.data.dimension(v =>
      v.changes.description ? (v.changes.description.checked ? 'MATCH' : 'CONFLICT') : 'NO_CONTENT'
    )
    this.cf.dimensions.changesType = this.cf.data.dimension(v => {
      if (v.changes.image && Object.keys(v.changes).length > 2) return ['images', 'content']
      else if (v.changes.image) return ['images']
      else if (Object.keys(v.changes).length > 1) return ['content']
      else return []
    })
    this.cf.dimensions.scrapingFrequency = this.cf.data.dimension(v => v.scraping_frequency)
  }

  updateFilters(values = {}, lockId = 'default') {
    super.updateFilters(values, lockId)
    const {
      compliance = {},
      isTitleMatched = null,
      isDescriptionMatched = null,
      isBulletPointsMatched = null,
      changesType = null,
      scrapingFrequencies = null,
    } = this.filters[lockId]
    this.filterDimension(
      this.cf.dimensions.compliance,
      compliance && global.isFinite(compliance.from) && changesType === 'content' ? compliance.from : null,
      compliance && global.isFinite(compliance.to) && changesType === 'content' ? compliance.to : null
    )
    this.filterDimension(
      this.cf.dimensions.imgCompliance,
      compliance && global.isFinite(compliance.from) && changesType === 'images' ? compliance.from : null,
      compliance && global.isFinite(compliance.to) && changesType === 'images' ? compliance.to : null
    )
    this.filterDimension(this.cf.dimensions.isTitleMatched, isTitleMatched)
    this.filterDimension(this.cf.dimensions.isBulletPointsMatched, isBulletPointsMatched)
    this.filterDimension(this.cf.dimensions.isDescriptionMatched, isDescriptionMatched)
    this.filterDimension(this.cf.dimensions.changesType, changesType)
    this.filterDimension(this.cf.dimensions.scrapingFrequency, scrapingFrequencies)
  }
}

export default CatalogueChangesSeries
