import {defer} from 'rxjs'

import MrSatanApiClient from './MrSatanApiClient'

export default class MrSatanApiClientTestOrNot {
  constructor() {
    return new MrSatanApiClient()
  }
}

export const MrSatanApi = (method, ...params) => defer(async () => new MrSatanApiClientTestOrNot()[method](...params))
