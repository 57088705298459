import {ofType} from 'redux-observable'
import {filter} from 'rxjs/operators'

import track from '../track'

export default function (tracker, tenant) {
  const trackCampaignBuilder = action => value =>
    track('Campaign Builder', {action_type: 'Export', tenant, action, value})

  const createFiltersStream = (type, trackers) =>
    tracker
      .reduxActionStream()
      .pipe(
        ofType(type),
        filter(x => x)
      )

      .subscribe(() => trackers())

  createFiltersStream('CAMPAIGN-BUILDER_DOWNLOAD_TEMPLATE', trackCampaignBuilder('Download Template'))
}
