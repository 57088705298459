import React from 'react'

import {CircularProgressbar} from 'react-circular-progressbar'
import {Progress} from 'semantic-ui-react'
import styled from 'styled-components'

import {lerp3Colors} from '../../..'
import colors from '../../../colors'
import i18n from '../../../i18n'

const StyledProgress = styled(Progress)`
  &&&&& {
    width: ${props => (props.width ? props.width : props.theme.progress.linear.width)} !important;
    margin: 0;
    .bar {
      background-color: ${props => props.color} !important;
    }
  }
`

interface StyledCircularProgressProps {
  width: string
  color: string | null
}

const StyledCircularProgress = styled(CircularProgressbar)<StyledCircularProgressProps>`
  &&&&&& {
    width: ${props => (props.width ? props.width : props.theme.progress.circular.width)} !important;
    stroke: ${props => props.color};
    fill: ${props => props.color};
    stroke-linecap: ${props => props.theme.progress.circular.strokeLineCap};
    transition: ${props => props.theme.progress.circular.transition};
    text {
      dominant-baseline: ${props => props.theme.progress.circular.dominantBaseline};
      font-size: ${props => props.theme.progress.circular.fontSize};
      text-anchor: ${props => props.theme.progress.circular.textAnchor};
    }
  }
`

interface SeelkProgressProps {
  color?: string | null
  placehold?: boolean
  type?: 'circular' | 'linear'
  value: number
  width?: string
}

const SeelkProgress = ({
  color = null,
  placehold = false,
  type = 'linear',
  value,
  width = '100%',
}: SeelkProgressProps) => {
  const circularColor =
    color || lerp3Colors(`#${colors.negative.hex}`, `#${colors.warning.hex}`, `#${colors.positive.hex}`, value / 100)
  if (type === 'circular') {
    return placehold ? (
      <b style={{fontSize: '1.2em'}}>{value ? `${value}%` : i18n.t('placeholders.empty.notAvailable')}</b>
    ) : (
      <StyledCircularProgress value={value} text={`${value}%`} color={circularColor} width={width} />
    )
  }
  return (
    <StyledProgress
      progress
      indicating={!placehold}
      percent={Math.round(value || 0)}
      color={circularColor}
      width={width}
    />
  )
}

export default SeelkProgress
