import React from 'react'

import i18n from '../../i18n'

type ExtraType =
  | {
      detailed?: false
      custom?: never
    }
  | {
      detailed: true
      custom?: Record<string, string>
    }

interface BasicCellProps {
  value?: number | string | null
  extra?: ExtraType
}

const BasicCell = ({value = null, extra = {}}: BasicCellProps) => (
  <div style={extra.detailed ? {textAlign: 'center', ...extra.custom} : {}}>
    {value || (value === 0 ? 0 : i18n.t('placeholders.empty.notAvailable'))}
  </div>
)

export default BasicCell
