import React from 'react'

import {Col, InlineBlock, Row} from 'jsxstyle'

import colors from '../../../colors'
import {PoppersPopup, SortIcon} from '../../../common-ui'

interface GridHeaderProps {
  label: string
  sortActive: boolean
  sortDirection: 'asc' | 'desc'
  schemeData?: any
  sort?: ((dir: 'asc' | 'desc') => void) | null
}

const GridHeader = ({sortActive, sortDirection, label, sort = null, schemeData = {}}: GridHeaderProps) => {
  const {headerTooltip, subLabel} = schemeData

  const sortKind = (() => {
    switch (sortDirection) {
      case 'asc':
        return 'desc'
      default:
        return 'asc'
    }
  })()

  return schemeData.rotatedHeader ? (
    <div
      className={`ReactVirtualized__Table__headerTruncatedText ReactVirtualized__Table__headerRotated ${
        sort ? 'ReactVirtualized__Table__sort' : ''
      }`}
      title={label}
      style={{
        overflow: 'unset',
      }}
    >
      <Row
        textDecoration="underline"
        color={sortActive ? colors.primary.rgba : colors.darkLight.rgba}
        cursor={sort ? 'pointer' : 'inherit'}
        marginBottom={6}
        marginLeft={12}
        userSelect="none"
        props={{onClick: sort && (() => sort(sortKind))}}
      >
        {label}
        {sort && <SortIcon onSort={sort} status={sortActive ? sortDirection : undefined} />}
      </Row>
    </div>
  ) : (
    <PoppersPopup
      content={headerTooltip || label}
      // wide="very"
      // basic
      trigger={
        <div
          className="ReactVirtualized__Grid__headerTruncatedText"
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            height: schemeData.alignRotate ? 'auto' : '100%',
            width: '100%',
            fontWeight: 500,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Row
            textDecoration="underline"
            color={sortActive ? colors.primary.rgba : colors.darkLight.rgba}
            cursor={sort ? 'pointer' : 'inherit'}
            marginBottom="4px"
            marginLeft="0px"
            userSelect="none"
            props={{onClick: sort && (() => sort(sortKind))}}
            height="inherit"
            display="flex"
            alignItems="center"
            justifyContent={schemeData.centered ? 'center' : 'flex-start'}
          >
            {subLabel ? (
              <Col>
                <span
                  style={{
                    paddingTop: subLabel === 'TO_IGNORE' ? 9 : 0,
                    paddingBottom: subLabel === 'TO_IGNORE' ? 9 : 0,
                  }}
                >
                  <InlineBlock>{label}</InlineBlock>
                </span>
                <span>{subLabel === 'TO_IGNORE' ? '' : subLabel}</span>
              </Col>
            ) : (
              <InlineBlock
                maxHeight="100%"
                height="fit-content"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="pre-wrap"
                // flex={1}
              >
                {label}
              </InlineBlock>
            )}
            {sort && <SortIcon onSort={sort} status={sortActive ? sortDirection : undefined} />}
          </Row>
        </div>
      }
    />
  )
}

export default GridHeader
