import React from 'react'

import moment, {Moment} from 'moment'

import {CALENDAR_LAYOUT, DEFAULT_FORMATS, FormatType, MODES} from './constants'
import {deduceFirstValue, offsetValue} from './utils'

function getToday(value: Moment) {
  return moment.utc().locale(value.locale())
}

const DefaultDateRender = (current: Moment, mode: FormatType, cellState: any, ...arg: any) => (
  <div key={current.format('YYYY-MM-DD')}>{DEFAULT_FORMATS[mode](current)}</div>
)

type CellStateCheckerType = {
  isOutside: boolean
  isExtremity?: boolean
  isInRange?: boolean
  isInHoverRange?: boolean
  isHover?: boolean
  isNow?: boolean
  isSelected?: boolean
  isDisabled?: boolean
}

interface BodyWidgetProps {
  dateRender?: typeof DefaultDateRender
  disabledDate?: (current: Moment, value?: Moment) => boolean // PropTypes.func,
  showWeekNumber: boolean // PropTypes.bool,
  value: Moment // PropTypes.object.isRequired,
  startValue: Moment | null // PropTypes.object,
  endValue: Moment | null // PropTypes.object,
  hoverValue?: Moment | null
  offset?: number
  mode?: typeof MODES[number]
  selectMode?: 'start' | 'end' | 'single'
  onChange: (value: Moment) => void // PropTypes.func.isRequired,
  onHoverChange?: (current: Moment | null) => void
  prefixCls?: string
}

export default class BodyWidget extends React.Component<BodyWidgetProps> {
  state = {
    hoverValue: null,
  }

  UNSAFE_componentWillMount() {
    if (this.props.hoverValue !== undefined) this.setState({hoverValue: this.props.hoverValue})
  }

  UNSAFE_componentWillReceiveProps(nextProps: BodyWidgetProps) {
    if (this.props.hoverValue !== nextProps.hoverValue) this.setState({hoverValue: nextProps.hoverValue})
  }

  handleHover = (current: Moment | null) => {
    if (this.props.onHoverChange) this.props.onHoverChange(current)
    this.setState({hoverValue: current})
  }

  cellStateChecker = (current: Moment): CellStateCheckerType => {
    const {
      value,
      disabledDate = null,
      endValue = null,
      mode = 'day',
      offset = 0,
      selectMode = 'single',
      startValue = null,
    } = this.props
    return {
      isOutside:
        (mode === 'day' || mode === 'week') &&
        (current.isBefore(offsetValue(value, offset, mode).startOf('month')) ||
          current.isAfter(offsetValue(value, offset, mode).endOf('month'))),
      isExtremity: selectMode !== 'single' && (current.isSame(startValue, mode) || current.isSame(endValue, mode)),
      isInRange: selectMode !== 'single' && current.isAfter(startValue, mode) && current.isBefore(endValue, mode),
      isInHoverRange:
        (selectMode === 'start' && current.isAfter(this.state.hoverValue, mode) && current.isBefore(endValue, mode)) ||
        (selectMode === 'end' && current.isAfter(startValue, mode) && current.isBefore(this.state.hoverValue, mode)),
      isHover: current.isSame(this.state.hoverValue, mode),
      isNow: current.isSame(getToday(value), mode),
      isSelected: current.isSame(value, mode),
      isDisabled: !!(disabledDate && disabledDate(current, value)),
    }
  }

  classNamesFromCellState = (cellState: CellStateCheckerType) =>
    Object.entries(cellState)
      .filter(([k, v]) => v) // eslint-disable-line no-unused-vars
      .map(([k, v]) => k) // eslint-disable-line no-unused-vars
      .join(' ')

  renderHeader() {
    const {mode = 'day', showWeekNumber = false, prefixCls = 'datePicker', value} = this.props
    if (mode !== 'week' && mode !== 'day') return null

    const firstDayOfWeek = value.localeData().firstDayOfWeek()
    const weekdays = value.localeData().weekdays()
    const shortWeekdays = value.localeData().weekdaysMin()
    const weekDaysEls = []
    for (let i = 0; i < 7; i += 1) {
      const day = (firstDayOfWeek + i) % 7
      weekDaysEls.push(
        <th key={day} role="columnheader" title={weekdays[day]} className={`${prefixCls}ColumnHeader`}>
          <span className={`${prefixCls}ColumnHeaderInner`}>{shortWeekdays[day]}</span>
        </th>
      )
    }

    return (
      <tr role="row">
        {(mode === 'week' || (mode === 'day' && showWeekNumber)) && (
          <th role="columnheader" className={`${prefixCls}ColumnHeader ${prefixCls}WeekColumnHeader`} />
        )}
        {weekDaysEls}
      </tr>
    )
  }

  renderBody() {
    const {
      value,
      offset = 0,
      mode = 'day',
      dateRender = DefaultDateRender,
      selectMode = 'single',
      prefixCls = 'datePicker',
      showWeekNumber = false,
    } = this.props
    const offsetted = offsetValue(value, offset, mode)
    const firstValue = deduceFirstValue(offsetted, mode)

    const tableHtml = []
    let current = firstValue
    for (let i = 0; i < CALENDAR_LAYOUT[mode].rows; i += 1) {
      const dateCells = []
      let potentialLineSkip = false
      let cellState
      let changedValue
      for (let j = 0; j < CALENDAR_LAYOUT[mode].cols; j += 1) {
        current = firstValue.clone().add(i * CALENDAR_LAYOUT[mode].cols + j, mode !== 'week' ? mode : 'day')

        cellState = this.cellStateChecker(current)
        if (j === 0 && cellState.isOutside) potentialLineSkip = true
        const dateHtml = dateRender(current, mode, cellState, value)

        changedValue = selectMode === 'end' ? current.clone().endOf(mode) : current.clone().startOf(mode)

        dateCells.push(
          <td
            key={current.unix()}
            onClick={this.props.onChange.bind(null, changedValue)}
            onMouseEnter={this.handleHover.bind(this, current)}
            className={`${prefixCls}Cell ${mode !== 'week' ? mode : 'day'}Cell ${this.classNamesFromCellState(
              cellState
            )}`}
            role="gridcell"
            title={current.format('LL')}
          >
            {dateHtml}
          </td>
        )
      }
      let weekNumberCell = null
      if (mode === 'week' || (mode === 'day' && showWeekNumber)) {
        const weekDate = selectMode === 'end' ? current.clone().endOf('week') : current.clone().startOf('week')
        changedValue = selectMode === 'end' ? current.clone().endOf(mode) : current.clone().startOf(mode)
        weekNumberCell = (
          <td
            key={current.week()}
            onClick={this.props.onChange.bind(null, changedValue)}
            onMouseEnter={this.handleHover.bind(this, weekDate)}
            className={`${this.props.prefixCls}Cell weekCell ${
              mode === 'week' ? this.classNamesFromCellState({...cellState, isOutside: false}) : ''
            }`}
            role="gridcell"
            title={current.format('LL')}
          >
            <div>{weekDate.week()}</div>
          </td>
        )
      }
      if (!(potentialLineSkip && cellState?.isOutside))
        tableHtml.push(
          <tr key={current.unix()} role="row">
            {weekNumberCell}
            {dateCells}
          </tr>
        )
    }
    return tableHtml
  }

  render() {
    const {prefixCls = 'datePicker', mode = 'day'} = this.props
    return (
      <table className={`${prefixCls}Table ${mode}Table`} cellSpacing="0" role="grid">
        <thead className={`${prefixCls}Head`}>{this.renderHeader()}</thead>
        <tbody className={`${prefixCls}Body`} onMouseLeave={this.handleHover.bind(this, null)}>
          {this.renderBody()}
        </tbody>
      </table>
    )
  }
}
