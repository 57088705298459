// This file has been shamelessly stolen from react-dates/src/components/OutsideClickHandlerForPortal.jsx
import React from 'react'

import {addEventListener} from 'consolidated-events'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.node,
  onOutsideClick: PropTypes.func,
}

const defaultProps = {
  children: <span />,
  onOutsideClick() {},
}

export default class OutsideClickHandlerForPortal extends React.Component {
  constructor(...args) {
    super(...args)
    this.clickedInside = false
  }

  componentDidMount() {
    // `capture` flag is set to true so that a `stopPropagation` in the children
    // will not prevent all outside click handlers from firing - maja
    this.removeEventListener = addEventListener(document, 'click', this.onOutsideClick, {capture: true})
  }

  componentWillUnmount() {
    if (this.removeEventListener) {
      this.removeEventListener()
    }
  }

  onOutsideClick = e => {
    if (this.clickedInside) return
    setTimeout(() => {
      if (!this.clickedInside) {
        const {onOutsideClick} = this.props
        onOutsideClick(e)
      }
    }, 10)
  }

  onInsideClick = () => {
    this.clickedInside = true
    setTimeout(() => {
      this.clickedInside = false
    }, 10)
  }

  render() {
    return <div onClick={this.onInsideClick}>{this.props.children}</div>
  }
}

OutsideClickHandlerForPortal.propTypes = propTypes
OutsideClickHandlerForPortal.defaultProps = defaultProps
