import React from 'react'

import styled from 'styled-components'

import i18n from '../../i18n'

const StyledLink = styled.div<{readonly hoverStyle: Record<string, any>}>`
  :hover {
    ${props => props.hoverStyle}
  }
`
type ExtraType = {
  handleClick: (item: any) => void
  disabled?: ((item: any) => boolean) | null
  hoverStyle?: Record<string, any>
}

interface ClickableCellProps {
  value?: string | number | null
  extra: ExtraType
  item: any
}

const ClickableCell = ({value = null, extra, item}: ClickableCellProps) => {
  const {handleClick, disabled = null, hoverStyle = {}} = extra

  if (disabled && disabled(item)) return <b>{value || i18n.t('placeholders.empty.notAvailable')}</b>
  return (
    <StyledLink
      onClick={() => handleClick && handleClick(item)}
      style={{cursor: 'pointer'}}
      hoverStyle={hoverStyle || {}}
    >
      {value || i18n.t('placeholders.empty.notAvailable')}
    </StyledLink>
  )
}

export default ClickableCell
