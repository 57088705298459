import React from 'react'

import {Box} from 'jsxstyle'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'
import {PoppersPopup} from '../../common-ui'
import i18n from '../../i18n'

const StyledCenteredText = styled.p`
  text-align: center;
  font-weight: 500;
`

const displayValueOrNone = (value: string) =>
  /* eslint-disable-next-line */
  value || i18n.t('placeholders.empty.notAvailable')

type ValueType = {
  checked?: boolean
  matchPercentage?: number
  retrieved_value: string
  expected_value: string
}

interface PopUpTickProps {
  value?: ValueType | null
  placehold?: boolean
}

const PopUpTick = ({value = null, placehold = false}: PopUpTickProps) => {
  if (placehold)
    return (
      <div
        style={{
          width: 10,
          height: 10,
          borderRadius: 10,
          background: colors.lightShadow.rgba,
        }}
      />
    )
  if (value === null || value === undefined)
    return <Icon name="minus circle" style={{color: colors.darkLight, margin: 0}} />
  else if (value.checked)
    return (
      <PoppersPopup
        size="tiny"
        content={
          <Box>
            <p>
              <b>{i18n.t('misc.cross.value')}:</b>
            </p>
            <p>{value.retrieved_value.toString()}</p>
          </Box>
        }
        basic
        trigger={<Icon name="check circle" style={{color: colors.positive, margin: 0}} />}
      />
    )
  else if (value.matchPercentage)
    return (
      <PoppersPopup
        size="tiny"
        content={
          <Box>
            <p>
              <b>{i18n.t('catalogue.compliance.index.matchPercentage')}:</b>
            </p>
            <StyledCenteredText>{i18n.toPercentage(value.matchPercentage, {precision: 1})} </StyledCenteredText>
          </Box>
        }
        basic
        trigger={<Icon name="remove circle" style={{color: colors.negative, margin: 0}} />}
      />
    )
  return (
    <PoppersPopup
      size="tiny"
      content={
        <Box>
          <p>
            <b>{i18n.t('catalogue.compliance.index.retrievedValue')}</b>
          </p>
          <StyledCenteredText> {displayValueOrNone(value.retrieved_value)} </StyledCenteredText>
          <p>
            <b>{i18n.t('catalogue.compliance.index.expectedValue')}</b>
          </p>
          <StyledCenteredText> {displayValueOrNone(value.expected_value)} </StyledCenteredText>
        </Box>
      }
      basic
      trigger={<Icon name="remove circle" style={{color: colors.negative, margin: 0}} />}
    />
  )
}

export default PopUpTick
