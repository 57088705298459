import React from 'react'

import {css, StyleSheet} from 'aphrodite'
import {Box, Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import {NavLink} from 'react-router-dom'
import {Icon, Image} from 'semantic-ui-react'
import styled from 'styled-components'

import KaioApiClient from '../../../utils/apis/KaioApiClient'
import colors from '../../../utils/colors'
import {PoppersPopup, SeelkButton} from '../../../utils/common-ui'
import i18n from '../../../utils/i18n'
import PermissionControl from '../../../utils/PermissionControl'

const styles = StyleSheet.create({
  item: {
    color: colors.darkLight.rgba,
    padding: 15,
    cursor: 'pointer',
    ':hover': {
      transform: 'scale(1.01)',
      color: colors.primary.rgba,
    },
    transition: 'transform ease 0.1s, color ease 0.1s',
  },
  avatarImg: {
    borderColor: colors.white.rgba,
    zIndex: 5,
    height: 40,
    width: 40,
    objectFit: 'contain',
    backgroundColor: colors.white.rgba,
  },
})

const StyledMenuItem = styled(NavLink)`
  &&& {
    padding-right: 4px;
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    width: 100%;
  }
`

const StyledItem = styled(Box)`
  &&& {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 15px;
    color: ${colors.secondary.alpha(0.25).rgba};
    ${StyledMenuItem}:hover & {
      color: ${colors.secondary.rgba};
    }
    ${StyledMenuItem}.active & {
      background: ${colors.secondary.alpha(0.1).rgba};
      color: ${colors.secondary.rgba};
    }
  }
`

const StyledNew = styled(Box)`
  &&& {
    width: 10px;
    height: 10px;
    background-color: ${colors.positive.rgba};
    position: absolute;
    transform: translate(10px, -10px);
    border-radius: 50%;
  }
`

const MultiAccountIcon = styled(Icon)`
  &&& {
    position: absolute;
    top: 25px;
    left: 28px;
    font-size: 0.6em;
  }
`

const StyledLine = styled.span`
  border-top: 1px solid ${colors.secondary.alpha(0.15).rgba};
  width: 36px;
`

const moduleProvider = i18n.createProvider('misc.modules')
// const placeholdersProvider = i18n.createProvider('placeholders.global') // eslint-disable-line no-unused-vars
// const subModulesProvider = i18n.createProvider('misc.subModules')

const menuItems = [
  // {
  //   name: 'monitoring',
  //   icon: 'eye',
  //   displayName: moduleProvider('monitoring'),
  //   to: '/monitoring',
  //   permissions: {
  //     showIfOne: ['monitoring', 'seelk'],
  //   },
  // },
  // {
  //   name: 'category-management',
  //   icon: 'sitemap',
  //   displayName: moduleProvider('categoryManagement'),
  //   to: '/category-management',
  //   permissions: {
  //     showIfOne: ['categorymanagement', 'seelk'],
  //   },
  // },
  {
    name: 'client-permissions',
    icon: 'table',
    displayName: moduleProvider('clientPermissions'),
    to: '/client-permissions',
    permissions: {
      showIfOne: ['clientpermissions', 'dev'],
    },
  },
  {
    name: 'advertisers',
    icon: 'bullhorn',
    displayName: moduleProvider('advertisers'),
    to: '/advertisers',
    permissions: {
      showIfOne: ['advertisers', 'advertising', 'dev'],
    },
  },
  {
    name: 'dataManagement',
    icon: 'file code',
    displayName: moduleProvider('dataManagement'),
    to: '/data-management',
    permissions: {
      showIfOne: ['dev'],
    },
  },
  {
    name: 'accountSettings',
    icon: 'cog',
    displayName: moduleProvider('account'),
    to: '/account-settings',
    permissions: {
      showIfOne: ['dev'],
    },
  },
  {
    name: 'campaignBuilder',
    icon: 'far fa-ad',
    displayName: moduleProvider('campaignBuilder'),
    to: '/campaign-builder',
    multiAccount: true,
    permissions: {
      showIfOne: ['advertising', 'dev'],
    },
  },
  {
    name: 'client-tracker',
    icon: 'fire extinguisher',
    displayName: moduleProvider('clientTracker'),
    to: '/client-tracker',
    multiAccount: true,
    permissions: {
      showIfOne: ['clienttracker', 'dev'],
    },
  },
  {
    name: 'search-terms-audit',
    icon: 'fas fa-search',
    displayName: moduleProvider('searchTermsAudit'),
    to: '/search-terms-audit',
    multiAccount: true,
    permissions: {
      showIfOne: ['seelk'],
    },
  },
]

class MainMenu extends React.PureComponent {
  static propTypes = {
    account: PropTypes.string,
    accounts: PropTypes.arrayOf(PropTypes.object),
    avatar: PropTypes.string.isRequired,
    currentAccount: PropTypes.object,
    // This is so the component rerenders when the location changes
    location: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
    notifs: PropTypes.number.isRequired,
    toggleClientsSelector: PropTypes.func.isRequired,
    user: PropTypes.string.isRequired,
  }

  static defaultProps = {
    account: null,
    accounts: [],
    currentAccount: null,
  }

  logout = () => {
    new KaioApiClient().logout()
    window.location = '/login'
  }

  renderIcon = item =>
    item.multiAccount ? (
      <>
        <Icon name={item.icon} />
        <MultiAccountIcon name="users" />
      </>
    ) : (
      <Icon key={item.icon} name={item.icon} style={{margin: 0}} />
    )

  renderNavigation = item => (
    <PermissionControl key={item.name} {...(item.permissions || {hideIfOne: ['none']})}>
      <PoppersPopup
        position="right center"
        trigger={
          <StyledMenuItem id={item.name} exact={item.exact} to={item.to} name={item.name}>
            <StyledItem>
              {item.isNew ? <StyledNew /> : null}
              {this.renderIcon(item)}
            </StyledItem>
          </StyledMenuItem>
        }
        content={
          <Box fontWeight="500" color={colors.primaryDark.rgba}>
            {item.displayName}
          </Box>
        }
      />
    </PermissionControl>
  )

  render() {
    const {account, notifs, user, currentAccount} = this.props // eslint-disable-line no-unused-vars
    const singleAccountItems = menuItems.filter(({multiAccount}) => !multiAccount)
    const multiAccountItems = menuItems.filter(({multiAccount}) => multiAccount)
    return [
      <Col
        key="menu"
        flex="0 0 var(--menu-size-width)"
        maxWidth="var(--menu-size-width)"
        background={`linear-gradient(${colors.primaryDark.lighten(0.05).rgba}, ${colors.primaryDark.darken(0.3).rgba})`}
      >
        <Row
          justifyContent="center"
          marginTop="16px"
          height="var(--menu-size-width)"
          cursor="pointer"
          props={{
            onClick: this.props.toggleClientsSelector,
          }}
        >
          <Image
            src={currentAccount ? currentAccount.logo : this.props.avatar}
            className={css(styles.avatarImg)}
            bordered
            size="mini"
            circular
          />
        </Row>
        {singleAccountItems.map(item => this.renderNavigation(item))}
        <Box style={{display: 'flex', justifyContent: 'center', padding: '0 4px 0 0'}}>
          <StyledLine />
        </Box>
        {multiAccountItems.map(item => this.renderNavigation(item))}
        <Box flex="1" />
        {/* <PermissionControl showIfOne={['seelk']}>
          <PoppersPopup
            position="right center"
            on="click"
            style={{padding: 0}}
            trigger={
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="main-menu-item" target="_blank" rel="noopener noreferrer">
                <Col width="100%" height="100%" className="main-menu-icon" justifyContent="center" cursor="pointer">
                  <Icon size="large" name="rocket" />
                </Col>
              </a>
            }
            content={
              <Col>
                <Row
                  width="100%"
                  height="100%"
                  className={css(styles.item)}
                  props={{
                    onClick: () => global.open('https://seelk-studio.typeform.com/to/M1sR2j'),
                  }}
                >
                  <Icon name="plus" />
                  <p>{placeholdersProvider('newAccountSetup')}</p>
                </Row>
                <Row
                  width="100%"
                  height="100%"
                  className={css(styles.item)}
                  props={{
                    onClick: () => global.open('https://seelk-studio.typeform.com/to/FUAaDW'),
                  }}
                >
                  <Icon name="warning" />
                  <p>{placeholdersProvider('newIssueRequest')}</p>
                </Row>
              </Col>
            }
          />
        </PermissionControl>
        <PermissionControl showIfOne={['client', 'brandmanager']}>
          <PoppersPopup
            position="right center"
            on="click"
            style={{padding: 0}}
            trigger={
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="main-menu-item" target="_blank" rel="noopener noreferrer">
                <Col width="100%" height="100%" className="main-menu-icon" justifyContent="center" cursor="pointer">
                  <Icon size="large" name="help" />
                </Col>
              </a>
            }
            content={
              <Col>
                <Row
                  width="100%"
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                  padding={20}
                  borderBottom={`1px solid ${colors.border.rgba}`}
                >
                  <h4>{placeholdersProvider('howHelp')}</h4>
                </Row>
                <Row
                  width="100%"
                  height="100%"
                  className={css(styles.item)}
                  props={{onClick: () => window.Intercom('showMessages')}}
                >
                  <Icon name="chat" />
                  <p>{placeholdersProvider('liveTechSupport')}</p>
                </Row>
                <Row
                  width="100%"
                  height="100%"
                  className={css(styles.item)}
                  props={{onClick: () => global.open('https://help.seelk.io/')}}
                >
                  <Icon name="pencil alternate" />
                  <p>{placeholdersProvider('helpCenter')}</p>
                </Row>
                <Row
                  width="100%"
                  height="100%"
                  className={css(styles.item)}
                  props={{
                    onClick: () => global.open('https://seelk-studio.typeform.com/to/nKFmSO'),
                  }}
                >
                  <Icon name="wordpress forms" />
                  <p>{placeholdersProvider('feedbackForm')}</p>
                </Row>
              </Col>
            }
          />
        </PermissionControl> */}
        {/* <PermissionControl showIfOne={['client']}>
          <PoppersPopup
            position="right center"
            trigger={
              <a
                className="main-menu-item"
                target="_blank"
                rel="noopener noreferrer"
                href="https://seelk.slite.com/public/share/note/Akicm3cLMLukELErqts5yY"
              >
                <Col width="100%" height="100%" className="main-menu-icon" justifyContent="center">
                  <Icon size="large" name="lightbulb" />
                </Col>
              </a>
            }
            content={
              <Box fontWeight="500" color={colors.primaryDark.rgba}>
                {i18n.t('actions.global.knowledge')}
              </Box>
            }
          />
          </PermissionControl> */}
        {/* <PoppersPopup
          position="right center"
          trigger={
            <NavLink id="settings" to="/settings" name="settings" className="main-menu-item">
              <Col width="100%" height="100%" className="main-menu-icon" justifyContent="center">
                <Icon size="large" name="cogs" />
              </Col>
            </NavLink>
          }
          content={
            <Box fontWeight="500" color={colors.primaryDark.rgba}>
              {i18n.t('misc.modules.settings')}
            </Box>
          }
        /> */}
        {account === 'seelk' ? (
          <Col paddingLeft="4px" paddingRight="4px" marginBottom="8px">
            <Box textAlign="center" color={colors.white.rgba} fontSize="0.8em">
              {user}
            </Box>
            <SeelkButton type="icon" icon="sign out" onClick={this.logout} />
          </Col>
        ) : (
          <Col paddingLeft="4px" paddingRight="4px" marginBottom="8px">
            <Box textAlign="center" color={colors.white.rgba} fontSize="0.8em">
              {account}
            </Box>
            <Box textAlign="center" color={colors.white.rgba} fontSize="0.8em">
              {user}
            </Box>
            <SeelkButton type="icon" icon="sign out" onClick={this.logout} />
          </Col>
        )}
      </Col>,
    ]
  }
}

export default MainMenu
