import React, {useEffect, useState} from 'react'

import PropTypes from 'prop-types'

import {capitalize, fuzzysearch} from '../..'
import SeelkDropdown from './SeelkUIKit/SeelkDropdown'
import SeelkInput from './SeelkUIKit/SeelkInput'

const MenuTabsSelection = ({options, menuOption, selectedOption, handleOptionSelect, placeholder}) => {
  const [inputSearch, setInputSearch] = useState(selectedOption || '')
  const handleOptionClick = value => {
    setInputSearch(options.find(opt => opt.value === value).text || '')
    handleOptionSelect(value)
  }

  const handleInputSearch = value => {
    setInputSearch(value)
  }

  const resetInput = event => {
    event.preventDefault()
    setInputSearch('')
    handleOptionSelect(null)
  }

  useEffect(() => {
    setInputSearch(selectedOption || '')
  }, [selectedOption])

  const filteredOptions = options.filter(opt => fuzzysearch(inputSearch, opt.text || ''))

  const getIcon = value => {
    return value ? {name: 'close', link: true, onClick: e => resetInput(e)} : 'search'
  }

  const menus = [...new Set(filteredOptions.map(opt => opt.menu))]
  const menuItems = menuOption
    ? Object.keys(menuOption)
        .filter(m => menus.includes(m))
        .reduce((acc, m) => {
          acc[m] = menuOption?.[m] ?? capitalize(m)
          return acc
        }, {})
    : menus.reduce((acc, m) => {
        acc[m] = capitalize(m)
        return acc
      }, {})

  return (
    <SeelkDropdown
      panelProps={{
        options: filteredOptions,
        menuItems,
        selectedOption,
        onChange: value => handleOptionClick(value),
      }}
      panelType="menuTabs"
      marginBottom={0}
      alignItems="center"
      display="flex"
      containerProps={{width: '100%', position: 'absolute'}}
      outsideWrapper={{
        display: 'flex',
        flexDirection: 'row',
      }}
      trigger={
        <SeelkInput
          fluid
          style={{width: '100%'}}
          type="search"
          value={inputSearch}
          onChange={(e, {value}) => handleInputSearch(value)}
          placeholder={placeholder}
          iconPosition={inputSearch ? 'right' : 'left'}
          icon={getIcon(inputSearch)}
        />
      }
    />
  )
}

MenuTabsSelection.propTypes = {
  handleOptionSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  menuOption: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  selectedOption: PropTypes.string,
}

MenuTabsSelection.defaultProps = {
  options: [],
  menuOption: [],
  placeholder: null,
  selectedOption: null,
}

export default MenuTabsSelection
