import React, {useState} from 'react'

import {Box, Row} from 'jsxstyle'
import {Icon} from 'semantic-ui-react'

import colors from '../../colors'
import theme from '../../theme'

interface FilterButtonProps {
  children?: string | React.ReactNode | null
  disabled?: boolean
  dropdown?: boolean
  icon?: string | null
  marginRight?: number
  onApply?: () => void
  onRemove?: (() => void) | null
  title?: string | null
}

const FilterButton = ({
  title = null,
  onRemove = null,
  disabled = false,
  children = null,
  dropdown = true,
  icon = null,
  ...props
}: FilterButtonProps) => {
  const [cross, setCross] = useState(false)
  return (
    <Row
      cursor="pointer"
      backgroundColor={colors.white.rgba}
      padding="8px"
      height="auto"
      minHeight="40px"
      className={theme.primaryButton}
      color={colors.primary.rgba}
      alignItems="center"
      {...props}
      props={{
        onMouseEnter: () => dropdown && setCross(true),
        onMouseLeave: () => dropdown && setCross(false),
      }}
    >
      {icon && <Icon style={{transform: 'translateY(-1px)', marginRight: '8px'}} className={icon} />}
      {title}
      <Row fontWeight="400" color={colors.dark.rgba} flexWrap="wrap">
        {children}
      </Row>
      {onRemove ? (
        <Box marginLeft="6px" className={theme.growing}>
          <Icon
            name={dropdown && !cross ? 'dropdown' : 'close'}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.preventDefault()
              event.stopPropagation()
              // props.onApply()
              onRemove()
            }}
          />
        </Box>
      ) : (
        <Box marginLeft="6px" className={theme.growing}>
          <Icon name="dropdown" />
        </Box>
      )}
    </Row>
  )
}

export default FilterButton
