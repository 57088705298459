import {filter, map} from 'rxjs/operators'

import {reduxActionStream} from '../extensions/rx'

const utils = {
  appendCss: (css, attribute) => {
    const tag = document.createElement('style')
    tag.type = 'text/css'
    if (attribute) {
      tag.setAttribute(attribute, '')
    }
    tag.innerText = css
    document.head.append(tag)
  },
}

const doFilterActions = () => {
  const asBase64 = x => Buffer.from(x).toString('base64')

  const actions = {
    'dW5pY29ybg==': () =>
      utils.appendCss(
        '* { cursor: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxOCIgc3R5bGU9ImZvbnQtc2l6ZToxNHB4Ij48dGV4dCB5PSIxNiI+8J+mhDwvdGV4dD48L3N2Zz4=),auto!important; }'
      ),
  }

  reduxActionStream()
    .pipe(
      filter(
        action => action && action.type && action.type.includes('FILTER') && action.payload && action.payload.filters
      ),
      map(action => action.payload.filters),
      map((filters = {}) => {
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in filters) {
          const value = filters[key]
          const k = asBase64(`${value}`.toLowerCase())
          if (actions[k]) return actions[k]
        }
        return null
      }),
      filter(x => x)
    )

    .subscribe(action => {
      action()
    })
}

export function load(store) {
  const easterEggs = [
    /* rickRoll, doFilterActions, replaceBrandManagersPictures, displayMaxRaphPicture */
  ]
  // load easter eggs
  easterEggs.forEach(easterEgg => easterEgg(store))
}
