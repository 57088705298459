import React, {useEffect, useMemo, useState} from 'react'

import {Col, Row} from 'jsxstyle'
import _ from 'lodash'
import {List as VirtualList, ListRowProps} from 'react-virtualized'
import {Checkbox, InputOnChangeData, List, ListItem} from 'semantic-ui-react'
import styled from 'styled-components'

import {fuzzysearch} from '../../..'
import colors from '../../../colors'
import i18n from '../../../i18n'
import FilterBarItem from '../Filters/FiltersBar/items/FilterBarItem'
import OperatorInNotIn from '../OperatorInNotIn'
import DebouncedInput from './DebouncedInput'

const UIWrapper = styled.div`
  width: 250px;
`

const UIContentPane = styled.div`
  display: flex;
  height: calc(100% - 30px);
`

const StyledRow = styled(Row)`
  padding: 10px 10px 0 10px;
`

const StyledCol = styled(Col)`
  padding: 10px 10px 0 10px;
`

const StyledList = styled(List)`
  &&&&& {
    height: 300px;
    width: 100%;
    overflow: scroll;
  }
`

const StyledListItem = styled(ListItem)`
  &&&&& {
    height: 30px;
    font-weight: ${props => (props.selected ? '500' : props.color)};
    > div > label {
      color: ${props => (props.selected ? colors.primary.rgba : props.color)};
      :focus {
        color: ${props => (props.selected ? colors.primary.rgba : props.color)};
      }
      &::after {
        color: ${props => (props.selected ? colors.primary.rgba : props.color)};
        :focus {
          color: ${props => (props.selected ? colors.primary.rgba : props.color)};
        }
      }
    }
  }
`

const StyledDebouncedInput = styled(DebouncedInput)`
  &&&&& {
    width: 100%;
  }
`

interface VirtualFilterSearchProps {
  choices: string[]
  data: string[]
  onApply: () => void
  onChange: (choices: string[]) => void
  onRemove: () => void
  title: string
  trigger: React.ReactNode
  disabled?: boolean
  operator?: 'in' | 'not_in'
  placeholder?: string
  updateOperator?: ((operator: 'in' | 'not_in') => void) | null
}

function VirtualFilterSearch({
  choices,
  data,
  onApply,
  onChange,
  onRemove,
  title,
  trigger,
  disabled = false,
  operator = 'in',
  placeholder = '',
  updateOperator = null,
  ...barItemProps
}: VirtualFilterSearchProps) {
  const [name, setName] = useState('')
  const [filters, setFilters] = useState([] as string[])
  const [customChoices, setCustomChoices] = useState(data.filter(value => !choices.includes(value)))
  const [currentChoices, setCurrentChoices] = useState([] as string[])
  const displayName = name.replace(/\n/g, ' ')
  const allChoices = useMemo(() => [...choices, ...customChoices], [choices, customChoices])
  const filteredChoices = filters.length ? filters : allChoices.filter(item => fuzzysearch(name, item || ''))

  useEffect(() => {
    const regex = placeholder === i18n.t('actions.search.asin') ? /[,;|\s/]+/ : /[\n,;|/]+/

    const _value = name && _.uniq(name.split(regex).filter(v => v.length > 0))

    const filter = _value ? _value.filter(item => allChoices.includes(item)) : []

    setFilters([...Array.from(new Set(filter))])

    const currentValue = (_value || []).filter(v => !allChoices.includes(v))
    setCurrentChoices(currentValue)
  }, [name, allChoices, placeholder])

  const isSelected = (choice: string) => data.indexOf(choice) > -1

  const allSelected = () =>
    _.difference(filters.length ? filters : allChoices.filter(item => fuzzysearch(name, item || '')), data).length === 0

  const anySelected = () => data.length > 0 && data.length < allChoices.length

  const handleChange = (value: string) => {
    const valueChecked = data.indexOf(value) > -1
    onChange(valueChecked ? data.filter(item => item !== value) : [...data, value])
  }

  const handleCheckAll = () => {
    onChange(filters.length ? filters : allChoices.filter(item => fuzzysearch(name, item || '')))
  }

  const handleUncheckAll = () => {
    onChange(data.filter(value => allChoices.indexOf(value) === -1))
  }

  const handleGlobalCheck = () => {
    if (allSelected()) handleUncheckAll()
    else handleCheckAll()
  }

  const handleCustomChange = (value: string) => {
    handleChange(value)
    setCustomChoices(_.uniq([...customChoices, value]))
    setCurrentChoices(currentChoices.filter(c => value !== c))
  }

  const rowRenderer = ({key, index, style}: ListRowProps) => {
    const choice = filteredChoices[index]
    return (
      <StyledListItem key={key} selected={isSelected(choice)} style={style}>
        <Checkbox
          label={<label style={{whiteSpace: 'nowrap'}}>{choice}</label>}
          checked={isSelected(choice)}
          onChange={() => handleChange(choice)}
        />
      </StyledListItem>
    )
  }

  return (
    <FilterBarItem
      {...barItemProps}
      data={data}
      onApply={onApply}
      onChange={onChange}
      disabled={disabled}
      trigger={trigger}
      noPadding
    >
      <UIWrapper>
        <StyledRow>
          <StyledDebouncedInput
            fluid
            icon="search"
            disabled={disabled}
            iconPosition="left"
            transparent={false}
            placeholder={placeholder}
            resetOnPaste={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, {value}: InputOnChangeData) => {
              setName(value)
            }}
            onPaste={(value: string) => {
              setName(value)
            }}
            value={displayName}
          />
        </StyledRow>
        {updateOperator ? (
          <StyledCol>
            <OperatorInNotIn updateOperator={updateOperator} operator={operator} />
          </StyledCol>
        ) : null}
        <StyledRow alignItems="center">
          <StyledList>
            <StyledListItem selected={allSelected()} color={anySelected() ? colors.black.rgba : colors.lightDark.rgba}>
              <Checkbox
                style={{whiteSpace: 'nowrap'}}
                checked={allSelected()}
                indeterminate={anySelected()}
                onChange={() => handleGlobalCheck()}
                label={i18n.t('actions.select.all')}
              />
            </StyledListItem>
            {placeholder === i18n.t('actions.search.asin') &&
              currentChoices.map(custom => (
                <StyledListItem key={custom} selected={isSelected(custom)}>
                  <Checkbox
                    label={<label style={{whiteSpace: 'nowrap'}}>{custom}</label>}
                    checked={isSelected(custom)}
                    onChange={() => handleCustomChange(custom)}
                  />
                </StyledListItem>
              ))}
            <UIContentPane>
              <VirtualList
                width={250}
                height={265}
                rowCount={filteredChoices.length}
                rowHeight={30}
                rowRenderer={rowRenderer}
              />
            </UIContentPane>
          </StyledList>
        </StyledRow>
      </UIWrapper>
    </FilterBarItem>
  )
}

export default VirtualFilterSearch
