import {push} from 'connected-react-router'
import {jsonFetch} from 'easyfetch'
import jwtDecode from 'jwt-decode'
import Cookies from 'universal-cookie'

import config from '../../../../config/index'

const KAIO = `https://${config.kaioDomain}`
const KAIO_API = `https://${config.kaioDomain}/api/v1`

export default class KaioApiClient {
  constructor() {
    if (!KaioApiClient.instance) {
      // We put this here to avoid a circular dependency
      this.store = process.env.REACT_APP_BUILD_JSON ? {dispatch: () => {}} : require('../../../../store').default // eslint-disable-line global-require
      this.cookies = new Cookies()
      this.token = null
      KaioApiClient.instance = this
    }
    return KaioApiClient.instance
  }

  async request(rType, url) {
    const token = await this.getToken()
    const req = rType(`${KAIO_API}/${url}`)
    req.setHeaders({Authorization: `Bearer ${token}`})
    return req
  }

  loggedIn() {
    if (this.token === null) {
      this.token = this.cookies.get('token') || null
      if (this.cookies.get('token'))
        this.store.dispatch({
          type: 'APP_RECEIVED_TOKEN',
          payload: {token: this.token},
        })
    }
    return this.token !== null && jwtDecode(this.token).iss === 'kaio'
  }

  async checkLive() {
    try {
      await jsonFetch(`${KAIO}/health/`).get()
      return true
    } catch (error) {
      if (error.code === 502) return false
      return true
    }
  }

  async checkStatus() {
    return jsonFetch(`${KAIO_API}/status/aggregate/`).get()
  }

  tokenHasExpired() {
    if (this.token) {
      const decoded = jwtDecode(this.token)
      return decoded && decoded.exp < new Date().getTime() / 1000 - 60
    }
    return true
  }

  tokenWillExpire() {
    if (this.token) {
      const decoded = jwtDecode(this.token)
      return decoded && decoded.exp < new Date().getTime() / 1000 - 12 * 3600
    }
    return true
  }

  async requestToken(email, password) {
    this.store.dispatch({type: 'APP_REQUEST_TOKEN'})
    let data
    // eslint-disable-next-line no-useless-catch
    try {
      data = await jsonFetch(`${KAIO_API}/token/auth/`).post({email, password})
      this.token = data.token
      this.cookies.set('token', data.token, {path: '/'})
      this.store.dispatch({
        type: 'APP_RECEIVED_TOKEN',
        payload: {token: this.token},
      })
    } catch (error) {
      throw error
    }
    return data
  }

  async refreshToken(account = '') {
    this.store.dispatch({type: 'APP_REFRESH_TOKEN'})
    let data
    try {
      data = await jsonFetch(`${KAIO_API}/token/refresh/?client=${account}`).post({token: this.token})
    } catch (err) {
      // Continue regardless error
    }
    if (!data || !data.token) {
      this.store.dispatch({
        type: 'APP_REFRESH_TOKEN_FAIL',
      })
    } else {
      this.token = data.token
      this.cookies.set('token', data.token, {path: '/'})
      this.store.dispatch({
        type: 'APP_RECEIVED_TOKEN',
        payload: {token: this.token},
      })
    }
    return data
  }

  logout() {
    this.cookies.remove('token', {path: '/'})
    this.cookies.remove('account', {path: '/'})
    this.token = null
  }

  async fetchPreferences() {
    const token = await this.getToken()
    const response = await jsonFetch(`${KAIO_API}/preferences/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .get()

    const mappers = {
      displayedCatalogColumns: x => x && x.replace && JSON.parse(x.replace(/'/g, '"')),
    }

    const isValid = x => x !== undefined && x !== null
    const prefs = response.reduce((p, c) => {
      // eslint-disable-next-line no-param-reassign
      p[c.name] = isValid(c.preferences[c.name]) ? c.preferences[c.name] : c.preferences
      if (mappers[c.name]) {
        // eslint-disable-next-line no-param-reassign
        p[c.name] = mappers[c.name](p[c.name])
      }
      return p
    }, {})
    return prefs
  }

  async updatePreference({name, preferences}) {
    await (async () => {
      const token = await this.getToken()
      try {
        await jsonFetch(`${KAIO_API}/preferences/${name}/`)
          .setHeaders({Authorization: `Bearer ${token}`})
          .patch({name, preferences})
      } catch (err) {
        jsonFetch(`${KAIO_API}/preferences/`)
          .setHeaders({Authorization: `Bearer ${token}`})
          .post({name, preferences})
      }
    })()
    return {name, preferences}
  }

  async createPreferenceFilters({name, preferences}) {
    const token = await this.getToken()
    await jsonFetch(`${KAIO_API}/preferences/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .post({name, preferences})

    return {name, preferences}
  }

  async updatePreferenceFilters({name, preferences}) {
    const token = await this.getToken()
    await jsonFetch(`${KAIO_API}/preferences/${name}/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .patch({name, preferences})

    return {name, preferences}
  }

  async fetchSettings() {
    const token = await this.getToken()

    const decoded = jwtDecode(this.token)

    const response = await jsonFetch(`${KAIO_API}/account_preferences/${decoded.client}/list_free_value/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .get()

    return response
  }

  async updateSetting(settings) {
    const token = await this.getToken()

    const decoded = jwtDecode(this.token)

    const response = await jsonFetch(`${KAIO_API}/account_preferences/${decoded.client}/set_free_value/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .post(settings)
    return response
  }

  async updateUser({id, user}) {
    const token = await this.getToken()

    const response = await jsonFetch(`${KAIO_API}/user/${id}/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .patch(user)
    await this.refreshToken()

    return response
  }

  reloadGoku() {
    if (!this.token) return
    const decoded = jwtDecode(this.token)

    if (decoded.account.toLowerCase() !== 'seelk') return

    const cookieAccount = this.cookies.get('account')

    if (!cookieAccount) return

    if (decoded.client !== cookieAccount && decoded.client !== 'seelk')
      this.cookies.set('account', decoded.client, {path: '/'})
  }

  async getToken() {
    if (!this.loggedIn() || this.tokenHasExpired()) {
      this.store.dispatch(push('/login'))
      // throw new Error('Session expired')
    }
    if (this.tokenWillExpire()) await this.refreshToken()
    this.reloadGoku()
    return this.token
  }

  async sendResetPasswordMail(email) {
    await jsonFetch(`${KAIO_API}/user/send_reset_password_mail/`).post({
      email,
    })
  }

  async changePassword({id, oldPassword, password, email}) {
    const token = await this.getToken()
    await jsonFetch(`${KAIO_API}/user/${id}/change_password/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .put({
        old_password: oldPassword,
        password,
        email,
      })
  }

  async changeEmail({id, oldEmail, email}) {
    const token = await this.getToken()
    const json = await jsonFetch(`${KAIO_API}/user/${id}/change_email/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .put({
        old_email: oldEmail,
        email,
      })
    this.token = json.token
    await this.refreshToken()

    return json
  }

  async getUser(id) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/user/${id}/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .get()
  }

  async fetchUsersList() {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/user/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .get()
  }

  async fetchActiveUsersList() {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/user/`)
      .setQueryParams({active: true})
      .setHeaders({Authorization: `Bearer ${token}`})
      .get()
  }

  async getUserFromToken() {
    const token = await this.getToken()
    return jwtDecode(token)
  }

  async getAccounts(activeOnly = true) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/accounts/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .setQueryParams(activeOnly ? {active: true} : {})
      .get()
  }

  async getVendorCredentials(account) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/vendor/accounts/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .setQueryParams({account})
      .get()
  }

  async getMwsCredentials(account) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/credentials/${account}/get_mws_credentials/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .get()
  }

  async getAccount(tenant) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/accounts/${tenant}/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .get()
  }

  async getMembers(tenant) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/user`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .setQueryParams({account: tenant})
      .get()
  }

  async createUser({email, firstName, lastName, account}) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/user/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .post({
        email,
        first_name: firstName,
        last_name: lastName,
        account,
        persona: null,
      })
  }

  async getUserFromIdWithoutToken(id) {
    return jsonFetch(`${KAIO_API}/user/${id}/`).get()
  }

  async setPassword(id, code, password, email) {
    await jsonFetch(`${KAIO_API}/user/${id}/set_password/`).post({
      password,
      code,
      email,
    })
  }

  async deleteUser(id) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/user/${id}`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .delete()
  }

  async fetchGroupsList() {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/group/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .get()
  }

  async setGroups(id, groups) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/user/${id}/set_groups/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .post({groups})
  }

  async bulkSetGroups(usersUpdateList) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/user/bulk_set_groups/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .post({...usersUpdateList})
  }

  async checkServiceStatus() {
    return jsonFetch(`${KAIO_API}/status/service/aggregate/`).get()
  }

  async checkModuleStatus() {
    return (
      jsonFetch(`${KAIO_API}/status/module/aggregate`)
        // fixes a really weird issue where the request is not made (cancelled ?) and the whole receivedTokenEpic
        // is interrupted resulting in the user not being set and AppLayout not rendering the app.
        .setOptions({cache: 'no-store'})
        .get()
    )
  }

  async getMarketplaces(available) {
    const queryParams = available || {}
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/marketplace/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .setQueryParams(queryParams)
      .get()
  }

  async setMWSCredentials({accountId, authToken, region, tenant, zone}) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/credentials/${tenant}/set_mws_credentials/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .post({account_id: accountId, auth_token: authToken, region, zone})
  }

  async setVendorCredentials({name, account, marketplace, email, password, otpToken}) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/vendor/accounts/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .post({name, account, marketplace, email, password, otp_token: otpToken})
  }

  async deactivateVendorAccount({id}) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/vendor/accounts/${id}/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .delete()
  }

  async getMWSCredentials({tenant, zone}) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/credentials/${tenant}/get_mws_credentials/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .setQueryParams({zone})
      .get()
  }

  async getLiveOn({amazonType, account}) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/live_on/${amazonType}/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .setQueryParams({account})
      .get()
  }

  async setLiveOn({amazonType, account, country}) {
    const marketplace = `amazon_${country.toLowerCase()}`
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/live_on/${amazonType}/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .post({account, marketplace})
  }

  async getListCredentials({tenant}) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/credentials/${tenant}/list_credentials`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .get()
  }

  async createAccount({logo, name, accountTypes}) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/accounts/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .post({logo, name, account_type: accountTypes})
  }

  async updateAccountLogo({logo, client}) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/accounts/${client}`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .patch({logo})
  }

  async fetchLinkAMS({client}) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/advertising/onboarding_link/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .setQueryParams({client})
      .get()
  }

  async fetchLinkSeller({account, zone}) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/spapi/registration_link`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .setQueryParams({account, selling_region: zone})
      .get()
  }

  async getSpapiAccounts({account}) {
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/spapi/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .setQueryParams({account})
      .get()
  }

  /* eslint-disable camelcase */
  async fetchAdvertisingAccounts({client = null, amazon_user_id = null}) {
    const queryParams = {
      ...(amazon_user_id && {amazon_user_id}),
      ...(client && {client}),
    }
    const token = await this.getToken()
    return jsonFetch(`${KAIO_API}/advertising/accounts/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .setQueryParams(queryParams)
      .get()
  }
  /* eslint-enable camelcase */

  async fetchSessionFilters(sessionId) {
    const token = await this.getToken()

    const response = await jsonFetch(`${KAIO_API}/sharing/${sessionId}`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .get()

    return response
  }

  async postSessionFilters(params) {
    const token = await this.getToken()

    const response = await jsonFetch(`${KAIO_API}/sharing/`)
      .setHeaders({Authorization: `Bearer ${token}`})
      .post(params)

    return response
  }

  /* Dashboards */

  async fetchDashboards(lastUpdate, client = jwtDecode(this.token).client) {
    return (await this.request(jsonFetch, 'dashboard/'))
      .setQueryParams({client, ...(lastUpdate ? {last_update: lastUpdate} : {})})
      .get()
  }

  async fetchDashboardById({id, modified}) {
    return (await this.request(jsonFetch, `dashboard/${id}/`)).setQueryParams(modified ? {modified} : {}).get()
  }

  async createDashboard(dashboard, client = jwtDecode(this.token).client) {
    return (await this.request(jsonFetch, 'dashboard/')).setQueryParams({client}).post(dashboard)
  }

  async updateDashboard({id, ...rest}, client = jwtDecode(this.token).client) {
    return (await this.request(jsonFetch, `dashboard/${id}/`)).setQueryParams({client}).patch(rest)
  }

  async deleteDashboard(id) {
    return (await this.request(jsonFetch, `dashboard/${id}/`)).delete()
  }

  async bulkDeleteDashboards(ids) {
    return (await this.request(jsonFetch, `dashboard/delete_batch/`)).delete(ids)
  }

  async copyDashboardById(id, client = jwtDecode(this.token).client) {
    return (await this.request(jsonFetch, `dashboard/${id}/copy/`)).setQueryParams({client}).post()
  }

  async copyDashboardAsTemplateById(id) {
    return (await this.request(jsonFetch, `dashboard/${id}/copy_as_template/`)).post()
  }

  async setDashboardFavorite({id, is_favorite}) {
    return (await this.request(jsonFetch, `dashboard/${id}/favorite/`)).patch({is_favorite})
  }

  async leaveDashboardView(id) {
    return (await this.request(jsonFetch, `dashboard/${id}/leave/`)).post()
  }

  async getDashboardActiveUsers(client = jwtDecode(this.token).client) {
    return (await this.request(jsonFetch, `dashboard/list_active_users/`)).setQueryParams({client}).get()
  }

  async getDashboardsActiveUsersById(id) {
    return (await this.request(jsonFetch, `dashboard/${id}/active_users/`)).get()
  }

  /* Templates */

  async fetchTemplates(lastUpdate) {
    return (await this.request(jsonFetch, 'template/'))
      .setQueryParams(lastUpdate ? {last_update: lastUpdate} : {})
      .get()
  }

  async fetchTemplateById({id, modified}) {
    return (await this.request(jsonFetch, `template/${id}/`)).setQueryParams(modified ? {modified} : {}).get()
  }

  async createTemplate(template) {
    return (await this.request(jsonFetch, 'template/')).post(template)
  }

  async updateTemplate({id, ...rest}, client = jwtDecode(this.token).client) {
    return (await this.request(jsonFetch, `template/${id}/`)).setQueryParams({client}).patch(rest)
  }

  async deleteTemplate(id) {
    return (await this.request(jsonFetch, `template/${id}/`)).delete()
  }

  async copyTemplateById(id) {
    return (await this.request(jsonFetch, `template/${id}/copy/`)).post()
  }

  async copyTemplateAsDashboardById(id, client = jwtDecode(this.token).client) {
    return (await this.request(jsonFetch, `template/${id}/copy_as_dashboard/`)).setQueryParams({client}).post()
  }

  async leaveTemplateView(id) {
    return (await this.request(jsonFetch, `template/${id}/leave/`)).post()
  }

  async getTemplateActiveUsers(client = jwtDecode(this.token).client) {
    return (await this.request(jsonFetch, `template/list_active_users/`)).setQueryParams({client}).get()
  }

  async getTemplateActiveUsersById(id) {
    return (await this.request(jsonFetch, `template/${id}/active_users/`)).get()
  }

  async getSPAPIRegistrationLink(marketplace, accountType, client = jwtDecode(this.token).client) {
    return (await this.request(jsonFetch, 'spapi/registration_link/'))
      .setQueryParams({
        account: client,
        marketplace,
        spapi_account_type: accountType,
      })
      .get()
  }

  async getAdvertisers() {
    const {client} = jwtDecode(this.token)
    return (await this.request(jsonFetch, 'advertising/advertiser_settings/'))
      .setQueryParams({
        account: client,
      })
      .get()
  }

  async deleteAdvertiser({id}) {
    const {client} = jwtDecode(this.token)
    return (await this.request(jsonFetch, `advertising/advertiser_settings/${id}/`))
      .setQueryParams({
        account: client,
      })
      .delete()
  }

  async updateAdvertisers({advertisers}) {
    const {client} = jwtDecode(this.token)
    const body = advertisers.map(advertiser => ({account: client, ...advertiser}))
    return (await this.request(jsonFetch, `advertising/advertiser_settings/bulk_update/`)).post(body)
  }
}
