import React from 'react'

import {Box, Col, Row} from 'jsxstyle'
import _ from 'lodash'
import {List as VirtualList, ListRowProps} from 'react-virtualized'
import {Checkbox, Icon, InputOnChangeData, Label, List, ListItem, Loader, Tab, TabProps} from 'semantic-ui-react'
import styled from 'styled-components'

import {clamp, fuzzysearch} from '../../../../..'
import colors from '../../../../../colors'
import i18n from '../../../../../i18n'
import LoaderWrapper from '../../../../../requesting/containers/LoaderWrapper'
import theme from '../../../../../theme'
import {DebouncedInput, FilterButton, OperatorInNotIn, PoppersPopup} from '../../../..'
import InNotIn from '../common/InNotIn'
import FilterBarItem from './FilterBarItem'

const UIWrapper = styled.div`
  width: 287px;
`
const StyledRow = styled(Row)`
  padding: 10px;
`

const StyledCol = styled(Col)`
  padding: 0 10px;
`

const StyledListItem = styled(ListItem)`
  &&&&& {
    height: 30px;
    font-weight: ${props => (props.selected ? '500' : props.color)};
    > div > label {
      color: ${props => (props.selected ? colors.primary.rgba : props.color)};
      :focus {
        color: ${props => (props.selected ? colors.primary.rgba : props.color)};
      }
      &::after {
        color: ${props => (props.selected ? colors.primary.rgba : props.color)};
        :focus {
          color: ${props => (props.selected ? colors.primary.rgba : props.color)};
        }
      }
    }
  }
`

const StyledList = styled(List)`
  &&&&& {
    max-height: 300px;
    width: 100%;
    overflow: scroll;
  }
`

const StyledMatchingType = styled(Row)`
  &&&&& {
    color: ${colors.darkLight.rgba};
    background-color: ${colors.primaryLightAlpha.rgba};
    font-weight: 500;
    font-size: 0.9em;
    padding: 5px 10px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      margin-left: 3px;
    }
  }
`

const StyledDebouncedInput = styled(DebouncedInput)`
  &&&&& {
    width: 100%;
  }
`

const StyledTab = styled(Tab)`
  &&&& {
    .ui.secondary.menu .active.item {
      background-color: red;
    }
  }
`

const StyledApplyRow = styled(Row)`
  color: ${colors.white.rgba};
  background-color: ${colors.primary.rgba};
  border-radius: 0 0 3px 3px;
  font-weight: 500;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${colors.primaryDark.rgba};
  }
  margin: 0;
  position: relative;
  left: -1px;
  bottom: -1px;
  width: calc(100% + 2px);
`

const StyledWrapper = styled.div`
  display: inline;
  margin: 0 2px;
`

const StyledTagDescription = styled.span`
  display: block;
  margin-top: 5px;
  font-size: 0.9em;
  line-height: 1.4em;
`

type TagType = {
  [slug: string]: {slug: string; name: string; color: string; description: string | null; dynamic?: boolean}
}

type DataType = {slugs: string[]; matchingType: 'OR' | 'AND'}

interface TagsProps {
  choices: string[]
  data: DataType
  icon?: string | null
  onChange: (item: DataType) => void
  onRemove: () => void
  onApply: () => void
  onClose?: () => void
  tags: TagType
  title: string
  updateOperator?: (operator: 'in' | 'not_in') => void
  operator?: 'in' | 'not_in'
}

interface TagState {
  currentFilters: DataType
  name: string
}

class Tags extends React.Component<TagsProps, TagState> {
  count = (data: DataType) => data?.slugs?.length ?? 0

  constructor(props: TagsProps) {
    super(props)
    this.state = {
      currentFilters: props.data,
      name: '',
    }
  }

  handleChange = (tag: string) => {
    const {slugs = [], matchingType} = this.props.data
    const tagsChecked = slugs.indexOf(tag) > -1
    this.props.onChange({
      slugs: tagsChecked ? slugs.filter(t => t !== tag) : [...slugs, tag],
      matchingType,
    })
  }

  isSelected = (choice: string) => {
    const {slugs = []} = this.props.data
    return slugs.indexOf(choice) > -1
  }

  allSelected = () =>
    _.difference(
      this.props.choices.filter(item => fuzzysearch(this.state.name, this.props.tags?.[item]?.name || '')),
      this.props.data.slugs
    ).length === 0

  anySelected = () => this.count(this.props.data) > 0 && this.count(this.props.data) < this.props.choices.length

  handleCheckAll = () => {
    this.props.onChange({
      slugs: this.props.choices.filter(item => fuzzysearch(this.state.name, this.props.tags?.[item]?.name || '')),
      matchingType: this.props.data?.matchingType ?? 'OR',
    })
  }

  handleUnCheckAll = () => {
    this.props.onChange({slugs: [], matchingType: this.props.data?.matchingType ?? 'OR'})
  }

  handleGlobalCheck = () => {
    if (this.allSelected()) this.handleUnCheckAll()
    else this.handleCheckAll()
  }

  handleTabChange = (e: React.MouseEvent<HTMLDivElement>, {activeIndex}: TabProps) => {
    const {slugs = []} = this.props.data
    if (slugs?.length) this.props.onChange({slugs, matchingType: this.panes[activeIndex as number].menuItem})
  }

  removeTag = (e: React.MouseEvent<HTMLElement>, slug: string) => {
    const {slugs = [], matchingType = 'OR'} = this.props.data
    this.props.onChange({slugs: slugs.filter(t => t !== slug), matchingType})
    setTimeout(this.props.onApply, 1)
    setTimeout(() => this.setState({currentFilters: this.props.data}), 2)
    e.preventDefault()
    e.stopPropagation()
  }

  isUntagged = (choice: string, key: string) =>
    choice === 'untagged_keywords' || choice === 'untagged_offers' ? (
      <StyledListItem key={key} selected={this.isSelected(choice)}>
        <Checkbox
          checked={this.isSelected(choice)}
          onChange={() => this.handleChange(choice)}
          label={i18n.t(`filters.${choice}`)}
        />
      </StyledListItem>
    ) : null

  panes = [{menuItem: 'AND'}, {menuItem: 'OR'}] as const

  render() {
    const {
      data,
      onChange,
      onRemove,
      onApply,
      title,
      choices,
      icon = null,
      tags,
      operator = 'in',
      updateOperator,
      onClose = () => {},
      ...barItemProps
    } = this.props
    const filteredTags = choices
      .filter(item => fuzzysearch(this.state.name, tags?.[item]?.name || ''))
      .sort((a, b) => (tags?.[a]?.name?.toLowerCase() > tags?.[b]?.name?.toLowerCase() ? 1 : -1))

    const trigger = (
      <FilterButton
        title={!data.slugs || !data.slugs.length ? i18n.t('filters.tagsTitle') : ''}
        icon={icon}
        onRemove={onRemove}
        onApply={onApply}
        disabled={undefined}
        dropdown={undefined}
      >
        <Row alignItems="center" marginLeft="5px">
          {data?.slugs?.length && updateOperator ? <InNotIn operator={operator} style={{marginRight: '5px'}} /> : null}
          {(data?.slugs ?? []).map(slug => (
            <Box marginLeft={5} key={slug}>
              {slug === 'untagged_keywords' || slug === 'untagged_offers' ? (
                <Label
                  style={{
                    backgroundColor: colors.darkLight.rgba,
                    color: 'white',
                    padding: '4px 6px',
                  }}
                >
                  {i18n.t(`filters.${slug}`)}
                  <Icon name="delete" onClick={(e: React.MouseEvent<HTMLElement>) => this.removeTag(e, slug)} />
                </Label>
              ) : (
                <PoppersPopup
                  style={{padding: '5px 8px', fontSize: '12px'}}
                  hoverable
                  key={slug}
                  position="top center"
                  disabled={tags?.[slug]?.name?.length <= 18}
                  trigger={
                    <StyledWrapper>
                      <Label
                        style={{
                          backgroundColor: tags[slug] ? tags[slug].color : '#aaa',
                          color: 'white',
                          padding: '4px 6px',
                        }}
                      >
                        {tags[slug]?.dynamic && <Icon name="bolt" />}
                        {tags[slug] ? clamp(tags[slug].name, 18) : slug}
                        <Icon name="delete" onClick={(e: React.MouseEvent<HTMLElement>) => this.removeTag(e, slug)} />
                      </Label>
                    </StyledWrapper>
                  }
                  content={tags?.[slug]?.name}
                />
              )}
            </Box>
          ))}
        </Row>
        <LoaderWrapper
          requesting={['catalogue.offersData.fetch', 'collections.offerTags.assign']}
          render={(requesting: boolean) => {
            return requesting ? (
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: colors.lightDark,
                  fontWeight: 400,
                  margin: '0 10px',
                }}
              >
                <Box marginLeft={4} position="relative" display="inline">
                  <Loader inline active={requesting} disabled={!requesting} size="mini" />
                </Box>
              </Box>
            ) : (
              <Box marginLeft="8px">
                {data?.slugs?.length ? (
                  <Label
                    style={{
                      color: colors.primary.rgba,
                      backgroundColor: colors.primaryLight.rgba,
                      padding: '4px 6px',
                    }}
                    content={data.matchingType}
                    icon="magnet"
                  />
                ) : null}
              </Box>
            )
          }}
        />
      </FilterButton>
    )

    const rowRenderer = ({key, index, style}: ListRowProps) => {
      const choice = filteredTags[index]
      return tags[choice] ? (
        <StyledListItem key={key} selected={this.isSelected(choice)} style={style}>
          <Checkbox
            label={{
              children: (
                <PoppersPopup
                  position="bottom center"
                  trigger={
                    <span>
                      {tags[choice].dynamic ? (
                        <Icon
                          name="bolt"
                          style={{
                            color: tags?.[choice]?.color ?? '#aaa',
                          }}
                        />
                      ) : (
                        <Label
                          circular
                          empty
                          style={{
                            backgroundColor: tags[choice] ? tags[choice].color : '#aaa',
                            marginRight: '5px',
                          }}
                        />
                      )}
                      {tags[choice] ? clamp(tags[choice].name, 25) : choice}
                    </span>
                  }
                  content={
                    <div>
                      <b>{tags?.[choice]?.name ?? choice}</b>
                      {tags?.[choice]?.description ? (
                        <StyledTagDescription>{tags?.[choice]?.description}</StyledTagDescription>
                      ) : null}
                    </div>
                  }
                />
              ),
            }}
            checked={this.isSelected(choice)}
            onChange={() => this.handleChange(choice)}
          />
        </StyledListItem>
      ) : (
        this.isUntagged(choice, key)
      )
    }

    return (
      <FilterBarItem
        {...barItemProps}
        data={this.state.currentFilters}
        onApply={onApply}
        onChange={onChange}
        trigger={trigger}
        noPadding
        wrapped={false}
      >
        <Col
          justifyContent="center"
          className={theme.using(theme.bordered, theme.shadowed)}
          marginTop="8px"
          backgroundColor={colors.white.rgba}
        >
          <LoaderWrapper
            requesting={[
              'catalogue.offersData.fetch',
              'collections.offerTags.assign',
              'collections.offerTagPivots.fetch',
            ]}
          >
            <UIWrapper>
              <StyledRow>
                <StyledDebouncedInput
                  fluid
                  icon="search"
                  iconPosition="left"
                  transparent={false}
                  placeholder={i18n.t('actions.search.tags')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>, {value}: InputOnChangeData) =>
                    this.setState({name: value})
                  }
                  value={this.state.name}
                />
              </StyledRow>
              {updateOperator ? (
                <StyledCol>
                  <OperatorInNotIn
                    updateOperator={updateOperator}
                    operator={operator}
                    disabled={data.matchingType === 'AND'}
                  />
                </StyledCol>
              ) : null}
              <StyledRow alignItems="center">
                <StyledList>
                  <StyledListItem
                    selected={this.allSelected()}
                    color={this.anySelected() ? colors.black.rgba : colors.lightDark.rgba}
                  >
                    <Checkbox
                      checked={this.allSelected()}
                      indeterminate={this.anySelected()}
                      onChange={() => this.handleGlobalCheck()}
                      label={i18n.t('actions.select.all')}
                    />
                  </StyledListItem>
                  <VirtualList
                    width={260}
                    height={260}
                    rowCount={filteredTags.length}
                    rowHeight={30}
                    rowRenderer={rowRenderer}
                  />
                </StyledList>
              </StyledRow>
              <StyledMatchingType>
                <Row>
                  <span>{i18n.t('settings.tags.matchingType')}</span>
                </Row>
                <StyledTab
                  activeIndex={this.panes.findIndex(p => p.menuItem === data.matchingType)}
                  onTabChange={this.handleTabChange}
                  menu={{secondary: true}}
                  panes={this.panes}
                />
              </StyledMatchingType>
            </UIWrapper>
          </LoaderWrapper>
          <StyledApplyRow
            props={{
              onClick: () => [this.setState({currentFilters: data}), onApply(), onClose()],
            }}
          >
            <Box>{i18n.t('actions.global.apply')}</Box>
          </StyledApplyRow>
        </Col>
      </FilterBarItem>
    )
  }
}

export default Tags
