import React from 'react'

import {Col, Row} from 'jsxstyle'
import {Icon, Label, Popup} from 'semantic-ui-react'
import styled from 'styled-components'

import {clamp} from '../..'
import colors from '../../colors'
import {AMAZON_MARKETPLACES} from '../../constants'
import i18n from '../../i18n'

const StyledKeyword = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  :hover {
    text-decoration: underline;
    cursor: pointer;
    color: ${colors.primary.rgba};
  }
`

const UIValueContainer = styled.span`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const UIKeywordContainer = styled.div`
  display: flex;
`

const StyledTrigger = styled.span`
  cursor: pointer;
  margin-left: 5px;
  color: ${colors.darkLight.rgba};
  font-size: 0.85em;
  :hover {
    text-decoration: underline;
  }
`

const StyledIcon = styled(Icon)`
  visibility: hidden;
  cursor: pointer;
  color: ${colors.primary.rgba};
  ${UIKeywordContainer}:hover &&&&&& {
    visibility: visible;
  }
`

const UILinkContainer = styled.div`
  flex: 0 0 40px;
  display: flex;
  justify-content: center;
`

type TagType = {
  name: string
  slug: string
  color: string
}

type ItemType = {
  tags?: string[]
  marketplace?: string
  text?: string
}

type ExtraType = {
  onClick?: (item: ItemType) => void
  tagList?: {[slug: string]: TagType}
  displayTagsModal?: ((item: ItemType) => void) | null
}

const displayTags = (tags: string[], item: ItemType, extra: ExtraType) => {
  const {displayTagsModal = null, tagList = {}} = extra
  return (
    <Row alignItems="center">
      {tags.slice(0, 2).map(slug =>
        tagList[slug] ? (
          <Popup
            key={slug}
            trigger={
              <Label
                value={slug}
                style={{
                  backgroundColor: tagList[slug] ? tagList[slug].color : '#aaa',
                  color: 'white',
                  padding: '4px 6px',
                  margin: '4px 0 4px 2px',
                }}
              >
                {clamp(tagList[slug].name, 12)}
              </Label>
            }
          >
            {clamp(tagList[slug].name)}
          </Popup>
        ) : null
      )}
      {tags.length > 2 && displayTagsModal && (
        <StyledTrigger
          onClick={e => {
            e.stopPropagation()
            displayTagsModal(item)
          }}
        >
          {i18n.t('settings.tags.seeAll', {count: tags.length})}
        </StyledTrigger>
      )}
    </Row>
  )
}

const goToAmazon = ({marketplace, text}: {marketplace?: string; text?: string}) => {
  const domain = AMAZON_MARKETPLACES.find(m => m.name === marketplace)?.domain
  if (!marketplace || !text || !domain) return
  global.open(`https://${domain}/s/?field-keywords=${text}`)
}

interface KeywordProps {
  extra?: ExtraType
  item: ItemType
  value?: string | null
}

const Keyword = ({value = null, item, extra = {}}: KeywordProps) => {
  const tags = item.tags || []
  const handleClick = extra.onClick ?? goToAmazon
  return (
    <Row maxWidth="100%" alignItems="center" width="100%">
      <Col maxWidth="100%" paddingRight={10} width="100%">
        <UIKeywordContainer>
          <StyledKeyword onClick={() => handleClick(item)}>
            <UIValueContainer>{value || i18n.t('placeholders.empty.notAvailable')}</UIValueContainer>
          </StyledKeyword>
          {extra.onClick && (
            <UILinkContainer>
              <StyledIcon
                onClick={(e: React.MouseEvent<HTMLElement>) => [e.stopPropagation(), goToAmazon(item)]}
                name="external"
              />
            </UILinkContainer>
          )}
        </UIKeywordContainer>
        <div>{tags.length > 0 && extra.tagList ? displayTags(tags, item, extra) : null}</div>
      </Col>
    </Row>
  )
}

export default Keyword
