import React from 'react'

import {css, StyleSheet} from 'aphrodite'
import PropTypes from 'prop-types'
import {Input} from 'semantic-ui-react'

import {debounce} from '../../..'
import colors from '../../../colors'
import PoppersPopup from '../PoppersPopup'

const styles = StyleSheet.create({
  inputActive: {
    backgroundColor: colors.white.rgba,
    borderRadius: '0.3em',
    height: '2.6em',
    ':hover': {
      backgroundColor: colors.light.rgba,
    },
  },
  inputInactive: {
    backgroundColor: colors.white.rgba,
    borderRadius: '0.3em',
    height: '2.6em',
  },
  inputError: {
    backgroundColor: colors.negative.alpha(0.3).rgba,
    borderRadius: '0.3em',
    height: '2.6em',
  },
})

class DebouncedInput extends React.Component {
  static propTypes = {
    autoComplete: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    fluid: PropTypes.bool,
    icon: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onPaste: PropTypes.func,
    placeholder: PropTypes.string,
    resetOnPaste: PropTypes.bool,
    threshold: PropTypes.number,
    tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    tooltipped: PropTypes.bool,
    transparent: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  }

  static defaultProps = {
    autoComplete: null,
    className: null,
    disabled: false,
    error: false,
    fluid: true,
    icon: null,
    onPaste: null,
    placeholder: '',
    resetOnPaste: true,
    threshold: 300,
    tooltipContent: null,
    tooltipped: false,
    transparent: true,
    type: '',
    value: '',
  }

  constructor(props) {
    super(props)
    this.state = {
      text: '',
    }
    this.debouncedChanged = debounce(this.props.onChange, this.props.threshold)
  }

  UNSAFE_componentWillMount() {
    this.initComponent(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.initComponent(nextProps)
  }

  onFocus = () => {
    this.setState({focused: true})
  }

  onBlur = () => {
    this.setState({focused: false})
  }

  handleChange = (e, {value}) => {
    this.setState({text: value})
    this.debouncedChanged(e, {value})
  }

  initComponent(props) {
    this.setState({text: props.value})
  }

  handlePaste = e => {
    if (this.props.onPaste) {
      if (window.clipboardData && window.clipboardData.getData) {
        this.props.onPaste(window.clipboardData.getData('Text'))
      } else if (e.clipboardData && e.clipboardData.getData) {
        this.props.onPaste(e.clipboardData.getData('text/plain'))
      }
      if (this.props.resetOnPaste) {
        this.setState({text: ''})
        this.debouncedChanged(e, {value: ''})
      }
      e.preventDefault()
    }
    return false
  }

  render() {
    const className = (() => {
      if (this.props.error) return css(styles.inputError)
      if (this.props.className) return this.props.className
      return (this.state.text && this.state.text.length > 0) || this.state.focused
        ? css(styles.inputInactive)
        : css(styles.inputActive)
    })()

    const input = (
      <Input
        fluid={this.props.fluid}
        error={this.props.error}
        onChange={this.handleChange}
        value={this.state.text || ''}
        transparent={this.props.transparent}
        placeholder={this.props.placeholder}
        onFocus={this.onFocus}
        icon={this.props.icon}
        iconPosition={this.props.icon && 'left'}
        onBlur={this.onBlur}
        className={className}
        autoComplete={this.props.autoComplete}
        type={this.props.type}
        disabled={this.props.disabled}
        onPaste={this.handlePaste}
      />
    )

    if (this.props.tooltipped) return <PoppersPopup basic content={this.props.tooltipContent} trigger={input} />
    return input
  }
}

export default DebouncedInput
