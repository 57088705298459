// This file has been shamelessly stolen from react-dates/src/components/OutsideClickHandler.jsx
import React from 'react'

import {addEventListener} from 'consolidated-events'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.node,
  onOutsideClick: PropTypes.func,
  styleProps: PropTypes.object,
}

const defaultProps = {
  children: <span />,
  onOutsideClick() {},
  styleProps: null,
}

export default class OutsideClickHandler extends React.Component {
  constructor(...args) {
    super(...args)
    this.onOutsideClick = this.onOutsideClick.bind(this)
    this.setChildNodeRef = this.setChildNodeRef.bind(this)
  }

  componentDidMount() {
    // `capture` flag is set to true so that a `stopPropagation` in the children
    // will not prevent all outside click handlers from firing - maja
    this.removeEventListener = addEventListener(document, 'click', this.onOutsideClick, {capture: true})
  }

  componentWillUnmount() {
    if (this.removeEventListener) {
      this.removeEventListener()
    }
  }

  onOutsideClick(e) {
    const {onOutsideClick} = this.props
    const {childNode} = this
    const isDescendantOfRoot = childNode && childNode.contains(e.target)
    if (!isDescendantOfRoot) {
      onOutsideClick(e)
    }
  }

  setChildNodeRef(ref) {
    this.childNode = ref
  }

  render() {
    return (
      <div ref={this.setChildNodeRef} style={{...this.props.styleProps}}>
        {this.props.children}
      </div>
    )
  }
}

OutsideClickHandler.propTypes = propTypes
OutsideClickHandler.defaultProps = defaultProps
