export default {
  index: {
    addLater: 'Add attributes to products and filter on them later',
    attributes: {
      one: 'Value',
      other: 'Values',
      zero: 'Value',
    },
    bulkActions: {
      connect: 'Connect {{type}}',
      delete: 'Delete {{type}}',
      edit: 'Edit {{type}}',
    },
    connectedValues: 'Connected Values',
    descriptionPlaceholder: 'Ex: Colors segmentation.',
    definitions: {
      one: 'Attribute',
      other: 'Attributes',
      zero: 'Attribute',
    },
    explanation:
      'Attributes are used to help you group and organise your products. They are a type of collection with an unlimited number of sub-items.',
    editAttributes: {
      delete: 'You are about to delete a value linked to {{count}} products, are you sure of this?',
      deleteHeader: 'Deleting a value',
    },
    editDefinition: {
      definitionChoices: 'Choices',
      definitionPlaceholder: 'Attribute name',
      delete: 'You are about to delete an attribute that counts {{countAttributes}} values, are you sure of this?',
      deleteHeader: 'Deleting an attribute',
      formatPlaceholder: 'Format',
    },
    helper:
      'Attributes are used to help you group and organise your products. They are a type of collection with an unlimited number of sub-items.',
    howTo: 'How to link products to attributes',
    name: 'Attribute Name',
    namePlaceholder: 'Ex: Colors',
    notRequired: '(not required)',
    products: {
      one: 'Product',
      other: 'Products',
    },
    type: 'Type',
  },
  noContent: {
    content: {
      firstLine: 'Seelk Studio uses custom attributes to help you organise and filter products.',
      secondLine: 'Click below to create your first attribute, add values and learn how to link your products to them.',
    },
    header: 'How do attributes work?',
    firstStep: {
      header: 'What are Attributes?',
      content:
        "Attributes allow users to organise Amazon products into one or more sub-categories known as 'values’. These attributes act as groups for your products through Seelk Studio, thus enabling you to filter and group by attributes and their values.",
      cta: 'What are values?',
    },
    secondStep: {
      header: 'What are Values?',
      content:
        "Once you’ve created an attribute, its sub-elements are referred to as 'values'. For example, you are selling a towel that has several color variations. In this case, the attribute would be 'color' and the 'values' could be: blue, green and purple.",
      cta: 'Link attributes to products',
    },
    thirdStep: {
      header: 'Link attributes to products',
      content:
        'Once you’ve created your attributes with their values, you can link your products to individual attribute values. This allows you to better organise your products for filtering and grouping inside Seelk Studio.',
      cta: 'Next (values)',
    },
  },
  offers: {
    activableAttributes: 'Activable attributes',
    addOrSelectAttribute: 'Add or select value',
    connectedAttributes: 'Connected Values (Attributes)',
    groupAlreadyConnected: 'You already have a value from attribute {{definition}}',
    header: 'Link/Edit linked values',
    listOfAttributes: 'Connected values',
    manageOffers: 'Manage Offers',
    noAttributesLinked: 'No values linked to this product.',
    noValuesSelected: 'Currently no values selected',
    numberProductsBeingLinked: 'You are linking attributes to {{count}} products',
  },
}
