export default {
  applyFilters: 'Applying filters',
  archiving: 'Archiving...',
  attributesAndTags: 'Attributes & Tags',
  catalog: 'Catalog',
  catalogScores: 'Calculating Catalog Scores',
  complianceScores: 'Calculating Compliance Scores',
  customAttributes: 'Uploading your attributes',
  deleting: 'Deleting...',
  display: 'Display',
  downloadingRecommendedKeywords: 'Downloading your recommended keywords...',
  extraBiPerformancesLoading: ' - We are about to crunch {{datapoints}} data points, this may take a while',
  fetchingAdGroups: 'Fetching your Ad Groups',
  fetchingAdGroupsReports: 'Fetching your Performances Ad Groups Campaigns Reports',
  fetchingAsinsReports: 'Fetching your Performances Asins Campaigns Reports',
  fetchingBidsReports: 'Fetching your Performances Keywords Campaigns Reports',
  fetchingCampaignsReports: 'Fetching your Performances Campaigns Reports',
  fetchingCategorySettings: 'Loading your settings',
  fetchingCategoryTree: 'Loading your categories and keywords',
  fetchingFrequency: {
    offers: 'Fetching offers frequencies...',
    keywordSettings: 'Fetching keywords frequencies...',
    categorySettings: 'Fetching categories frequencies...',
  },
  fetchingMarketplaces: 'Fetching your marketplaces',
  fetchingMarketplacesBudgets: 'Fetching your Marketplaces Budgets',
  fetchingPDA: 'Fetching your Product Display Ads Campaigns',
  fetchingProductAdsReports: 'Fetching your Performances Product Ads Campaigns Reports',
  fetchingProfiles: 'Fetching your Profiles',
  fetchingProfilesBudgets: 'Fetching your Profiles Budgets',
  fetchingSB: 'Fetching your Sponsored Brands Ads Campaigns',
  fetchingSP: 'Fetching your Sponsored Products Campaigns',
  globalLoadWelcome: 'Welcome to Seelk Studio! We are loading your data...',
  imagesFrequencies: 'Updating image check frequencies',
  keywords: 'Keywords',
  loading: 'Loading',
  loadingAdvertising: 'Loading Advertising metrics & budgets',
  loadingAccounts: 'Loading Account details',
  loadingAmazonAccounts: 'Loading Amazon Accounts details',
  loadingProductData: 'Loading Product Data',
  loadingAttributes: 'Loading Attributes',
  loadingBiData: 'Loading Business Intelligence metrics...',
  loadingCampaigns: 'Loading your Campaigns metrics',
  loadingCompetition: 'Loading competitors Share of Voice metrics',
  loadingCompetitionKeywords: 'Loading Share of Voice keyword metrics',
  loadingCompetitionTimeline: 'Loading Share of Voice performance timeline',
  loadingCompetitionPieChart: 'Loading Share of Voice competitor brands metrics',
  loadingCustomAttributes: 'Loading Custom Attributes',
  loadingDashboards: 'Loading Dashboards',
  loadingKeywords: 'Loading Keywords metrics',
  loadingLogistics: 'Loading Logistics metrics',
  loadingPricing: 'Loading Buy Box metrics',
  loadingProducts: 'Loading Products metrics',
  loadingProductReviews: 'Loading Product reviews',
  loadingProductReviewsPeriodYesterday: 'Loading Product Reviews since yesterday',
  loadingProductReviewsPeriod7Days: 'Loading Product Reviews since the last 7 days',
  loadingProductReviewsPeriod30Days: 'Loading Product Reviews since the last 30 days',
  loadingSalesData: 'Loading Sales Data',
  loadingSalesDataGrouping: 'Loading Sales Data based on {{grouping}}',
  loadingSuggestedKeywords: 'Loading Suggested Keywords',
  loadingTemplates: 'Loading Dashboards',
  paidMedia: 'Paid Media',
  retail: 'Retail',
  retrievingProducts: 'Retrieving your Products',
  requesting: 'Requesting...',
  savingKeywords: 'Saving keywords...',
  settingNewDateRange: 'Setting new date range',
  scrapingsFrequencies: 'Updating scraping frequencies',
  updating: 'Updating',
  updatingBudget: 'Updating your budgets data...',
  updatingLanguage: 'Updating language to {{language}}',
  uploading: 'Uploading',
  fetchingSovTimeline: 'Fetching competition Share of Voice Timeline...',
  fetchingSovBrand: 'Fetching competition Share of Voice by Brand...',
  fetchingSovProduct: 'Fetching competition Share of Voice by Product...',
  fetchingSovKeyword: 'Fetching competition by Keywords...',
}
