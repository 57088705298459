import React from 'react'

import {Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import {connect} from 'react-redux-reliever'

import {FiltersBar, SeelkButton} from '../../utils/common-ui'
import i18n from '../../utils/i18n'
import LoaderWrapper from '../../utils/requesting/containers/LoaderWrapper'
import {requestWrapperBuilder, withRequests} from '../../utils/requesting/RequestWrapper'
import {filtersSelector, limitsRangesFiltersSelector} from '../selectors'

class Filters extends React.Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    limitsRangesFilters: PropTypes.object.isRequired,
    mixpanelExport: PropTypes.object.isRequired,
    resetFilters: PropTypes.func.isRequired,
    clientsExport: PropTypes.object.isRequired,
    updateFilters: PropTypes.func.isRequired,
  }

  updateFilters = (key, value) => {
    const {filters} = this.props
    this.props.updateFilters({...filters, [key]: value})
  }

  updateArrayFilter = (key, value) => {
    if (Array.isArray(value)) this.updateFilters(key, value)
    else {
      let values

      if (this.props.filters[key] != null) {
        values = [...this.props.filters[key]]
        if (values.includes(value)) {
          values.splice(values.indexOf(value), 1)
        } else {
          values.push(value)
        }
      } else {
        values = [value]
      }
      this.updateFilters(key, values)
    }
  }

  rangeLimitsScore = (score, min, max) => ({
    minValue: (score && score.minValue) || min,
    maxValue: (score && score.maxValue) || max,
  })

  render() {
    const {limitsRangesFilters, resetFilters} = this.props
    const {
      categoriesNumber: nbCategoriesFilter,
      keywordsNumber: nbKeywordsFilter,
      productsNumber: nbProductsFilter,
      accountTypes: accountTypesFilter,
    } = this.props.filters

    const [nbCategories, nbKeywords, nbProducts] = [
      this.rangeLimitsScore(
        nbCategoriesFilter,
        limitsRangesFilters.minCategoriesNumber,
        limitsRangesFilters.maxCategoriesNumber
      ),
      this.rangeLimitsScore(
        nbKeywordsFilter,
        limitsRangesFilters.minKeywordsNumber,
        limitsRangesFilters.maxKeywordsNumber
      ),
      this.rangeLimitsScore(
        nbProductsFilter,
        limitsRangesFilters.minProductsNumber,
        limitsRangesFilters.maxProductsNumber
      ),
    ]

    return (
      <Col height="3.929em">
        <FiltersBar
          onReset={resetFilters}
          keywordsNumber={{
            component: FiltersBar.items.ValueRange,
            data: nbKeywords,
            // disableIf: limitsRangesFilters.maxKeywordsNumber <= limitsRangesFilters.minKeywordsNumber,
            icon: 'sliders',
            isValid:
              limitsRangesFilters.maxKeywordsNumber > limitsRangesFilters.minKeywordsNumber &&
              (nbKeywords.maxValue < limitsRangesFilters.maxKeywordsNumber ||
                nbKeywords.minValue > limitsRangesFilters.minKeywordsNumber),
            max: limitsRangesFilters.maxKeywordsNumber,
            min: limitsRangesFilters.minKeywordsNumber,
            onChange: ({minValue, maxValue}) => this.updateFilters('keywordsNumber', {minValue, maxValue}),
            onRemove: () => this.updateFilters('keywordsNumber', {}),
            title: i18n.t('filters.keywordsNumber'),
          }}
          productsNumber={{
            component: FiltersBar.items.ValueRange,
            data: nbProducts,
            // disableIf: limitsRangesFilters.maxProductsNumber <= limitsRangesFilters.minProductsNumber,
            icon: 'sliders',
            isValid:
              limitsRangesFilters.maxProductsNumber > limitsRangesFilters.minProductsNumber &&
              (nbProducts.maxValue < limitsRangesFilters.maxProductsNumber ||
                nbProducts.minValue > limitsRangesFilters.minProductsNumber),
            max: limitsRangesFilters.maxProductsNumber,
            min: limitsRangesFilters.minProductsNumber,
            onChange: ({minValue, maxValue}) => this.updateFilters('productsNumber', {minValue, maxValue}),
            onRemove: () => this.updateFilters('productsNumber', {}),
            title: i18n.t('filters.productsNumber'),
          }}
          categoriesNumber={{
            component: FiltersBar.items.ValueRange,
            data: nbCategories,
            // disableIf: limitsRangesFilters.maxCategoriesNumber <= limitsRangesFilters.minCategoriesNumber,
            icon: 'sliders',
            max: limitsRangesFilters.maxCategoriesNumber,
            min: limitsRangesFilters.minCategoriesNumber,
            onChange: ({minValue, maxValue}) => this.updateFilters('categoriesNumber', {minValue, maxValue}),
            onRemove: () => this.updateFilters('categoriesNumber', {}),
            title: i18n.t('filters.categoriesNumber'),
          }}
          accountTypes={{
            component: FiltersBar.items.CheckboxVerticalList,
            data: accountTypesFilter || [],
            choices: ['saas', 'agency', 'prospect', 'other'],
            icon: 'tasks',
            onChange: accountType => this.updateArrayFilter('accountTypes', accountType),
            onRemove: () => this.updateFilters('accountTypes', []),
            title: i18n.t('filters.accountType'),
          }}
        >
          <Row gap={12}>
            <LoaderWrapper
              requesting="client-tracker.clientsExport"
              render={requesting => (
                <SeelkButton
                  onClick={() => this.props.clientsExport.fetch()}
                  type="normal"
                  icon="fas fa-file-excel"
                  iconPosition="left"
                  content={i18n.t('actions.download.clientsExport')}
                  loading={requesting}
                  disabled={requesting}
                />
              )}
            />
            <LoaderWrapper
              requesting="client-tracker.mixpanelExport"
              render={requesting => (
                <SeelkButton
                  onClick={() => this.props.mixpanelExport.fetch()}
                  type="normal"
                  icon="fas fa-file-excel"
                  iconPosition="left"
                  content={i18n.t('actions.download.mixpanelExport')}
                  loading={requesting}
                  disabled={requesting}
                />
              )}
            />
          </Row>
        </FiltersBar>
      </Col>
    )
  }
}

export default withRequests({
  mixpanelExport: requestWrapperBuilder({
    fetchAction: 'CLIENT-TRACKER_EXPORT_MIXPANEL_DATA',
    requestingPath: 'client-tracker.mixpanelExport',
    successMsg: 'The extract file for Mixpanel has been successfully downloaded.',
  }),
  clientsExport: requestWrapperBuilder({
    fetchAction: 'CLIENT-TRACKER_EXPORT_CLIENTS_DATA',
    requestingPath: 'client-tracker.clientsExport',
    successMsg: 'The clients extract file has been successfully downloaded.',
  }),
})(
  connect({
    props: (state, ownProps) => ({
      filters: filtersSelector(state),
      limitsRangesFilters: limitsRangesFiltersSelector(state),
      ...ownProps,
    }),
    functions: (ownProps, dispatch) => ({
      resetFilters: () => dispatch({type: 'CLIENT-TRACKER_RESET_FILTERS', payload: {filters: {__OVERWRITE__: true}}}),
      updateFilters: filters =>
        dispatch({type: 'CLIENT-TRACKER_UPDATE_FILTERS', payload: {filters: {...filters, __OVERWRITE__: true}}}),
    }),
  })(Filters)
)
