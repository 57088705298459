import {ofType} from 'redux-observable'
import {filter} from 'rxjs/operators'

import track from '../track'

export default function (tracker, tenant) {
  const trackAction = action => value => track('Ticketing', {action_type: 'Import', tenant, action, value})
  // const trackBI = action => value => track('BI', {action_type: 'Import', tenant, action, value})
  const trackCatalog = action => value => track('Catalogue', {action_type: 'Import', tenant, action, value})
  const trackCatalogKeywords = action => value => track('SOV', {action_type: 'Import', tenant, action, value})
  // const trackMarketing = action => value => track('Marketing', {action_type: 'Import', tenant, action, value})
  const trackSettings = action => value => track('Settings', {action_type: 'Import', tenant, action, value})

  const createFiltersStream = (type, trackers) =>
    tracker
      .reduxActionStream()
      .pipe(
        ofType(type),
        filter(x => x)
      )
      .subscribe(() => trackers)

  createFiltersStream('ACTION_IMPORT_ACTION', trackAction('Import Action'))
  createFiltersStream('ATTRIBUTES_IMPORT_ATTRIBUTES', trackSettings('Import Attributes'))
  createFiltersStream('CATALOGUE_IMPORT_MASTER_DATA', trackCatalog('Import Master Data'))
  createFiltersStream('CATALOGUE_IMPORT_CATALOGUE', trackCatalog('Import Catalogue'))
  createFiltersStream('CATALOGUE-KEYWORDS_IMPORT_KEYWORDS', trackCatalogKeywords('Import Keywords'))
}
