import {Moment} from 'moment'

import {CALENDAR_LAYOUT, FormatType, MODES} from './constants'

// eslint-disable-next-line consistent-return
export function offsetValue(value: Moment, offset: number, mode: FormatType) {
  switch (mode) {
    case 'year':
      return value
        .clone()
        .utc()
        .add(offset * CALENDAR_LAYOUT.year.rows * CALENDAR_LAYOUT.year.cols, 'year')
    case 'quarter':
    case 'month':
      return value
        .clone()
        .utc()
        .add(offset, 'year')
    case 'week':
    case 'day': {
      return value
        .clone()
        .utc()
        .add(offset, 'month')
    }
    // no default
  }
}

export function modeIsLowerThan(lhs: FormatType, rhs: FormatType) {
  return MODES.indexOf(lhs) < MODES.indexOf(rhs)
}

// eslint-disable-next-line consistent-return
export function deduceFirstValue(value: Moment, mode: FormatType) {
  switch (mode) {
    case 'year':
      return value.clone().subtract(value.year() % (CALENDAR_LAYOUT.year.rows * CALENDAR_LAYOUT.year.cols), 'year')
    case 'quarter':
      return value.clone().startOf('year')
    case 'month':
      return value.clone().startOf('year')
    case 'week':
    case 'day': {
      const startOfMonth = value.clone().date(1)
      const dayOfWeek = startOfMonth.day()
      const firstDayDiff = (dayOfWeek + 7 - value.localeData().firstDayOfWeek()) % 7
      return startOfMonth.add(0 - firstDayDiff, 'days')
    }
    // no default
  }
}
