import React from 'react'

import {Box} from 'jsxstyle'

import colors from '../../colors'

interface SpacerProps {
  size: string | number
  flex?: boolean
  visible?: boolean
  className?: string
}

export default class Spacer extends React.Component<SpacerProps> {
  render() {
    const {flex = true, visible = false, className = ''} = this.props
    const unit = this.props.size?.toString().replace(/[0-9]/g, '') || 'px'
    const size = typeof this.props.size === 'string' ? parseInt(this.props.size, 10) : this.props.size

    if (flex) {
      if (visible)
        return (
          <div className={className}>
            <Box flex={`0 0 ${size / 2}${unit}`} />
            <Box flex="0 0 1" border="1px solid" borderColor={colors.lightShadow} width="100%" height="100%" />
            <Box flex={`0 0 ${size / 2}${unit}`} />
          </div>
        )
      return <Box flex={`0 0 ${size}${unit}`} className={className} />
    }
    if (visible)
      return <Box margin={size / 2} border="1px solid" borderColor={colors.lightShadow} className={className} />
    return <Box margin={size / 2} className={className} />
  }
}
