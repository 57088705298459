export default {
  datePicker: {
    endDate: 'End Date',
    modes: {
      days: 'Days',
      months: 'Months',
      weeks: 'Weeks',
    },
    comparisonOn: 'Comparison (On)',
    comparisonDisabledWeeks: 'Comparison disabled for period over {{max}} weeks',
    periods: {
      all: 'All',
      currentMonth: 'This month',
      currentWeek: 'This week',
      custom: 'Select custom Range...',
      l30d: 'Last 30 days',
      l7d: 'Last 7 days',
      l90d: 'Last 90 days',
      last12Months: 'Last 12 months',
      last12Weeks: 'Last 12 weeks',
      last180Days: 'Last 180 days',
      last26Weeks: 'Last 26 weeks',
      last30Days: 'Last 30 days',
      last365Days: 'Last 365 days',
      last3Months: 'Last 3 months',
      last4Weeks: 'Last 4 weeks',
      last52Weeks: 'Last 52 weeks',
      last6Months: 'Last 6 months',
      last7Days: 'Last 7 days',
      last90Days: 'Last 90 days',
      lastMonth: 'Last month',
      lastWeek: 'Last week',
      latest: 'Latest',
      mtd: 'Month to date',
      nextMonth: 'Next month',
      nextPeriod: 'Next period',
      nextYear: 'Next year',
      now: 'Now',
      prevMonth: 'Prev. month',
      prevPeriod: 'Prev. period',
      prevYear: 'Prev. year',
      qtd: 'Quarter to date',
      yesterday: 'Yesterday',
      yoy: 'Year over Year',
      ytd: 'Year to date',
      ytdMonth: 'Year to date',
      ytdAmazonWeek: 'Year to date',
      wtd: 'Week to date',
    },
    startDate: 'Start Date',
  },
  empty: {
    account: "Don't have an account ?",
    actions: 'No action(s) taken',
    budget: 'You currently have no budget for the year {{year}} !',
    budgetMarketplace: 'You currently have no budgets for this marketplace !',
    collectData: "We'll collect more data tonight,\nPlease come back tomorrow 🙂",
    content: 'No Content',
    dailyPcogsUnavailable:
      'PCOGS revenue is unavailable in daily reports. Switch to weekly or monthly date ranges to see your PCOGS.',
    dailySellerSalesUnavailable:
      'Seller sales are unavailable in daily reports. Switch to weekly or monthly date ranges to see your sales.',
    data: 'No data to display',
    emptyField: 'This field is empty.',
    excelFile: "If you don't already have an Excel file",
    fileName: 'Import name (By default = File name)',
    imageData: 'No image compliance data',
    importTags: 'Please use only English TRUE/FALSE when filling the document. We do not support local languages.',
    moreOptions: 'No more options',
    noAccountType: 'Select an account type',
    noAddedKeywords: 'You have not added any keywords',
    noAsins: 'You have not added any ASINs !',
    noAttributes: 'You currently have no attributes !',
    noCategoryOnMarketplace: 'There are no available categories for this marketplace',
    noGroups: 'You currently have no groups — add a group above!',
    noKeywords:
      'Looks like you are not tracking any keywords, click on this button if you want to manage your keywords!',
    noMarketplace: 'Select a marketplace',
    noMarketplaceSelected: 'There is no selected marketplace, please select one !',
    noProducts: 'You have not added any products',
    noPipelines: 'There is no pipeline for the moment',
    noExports: 'You currently have no exports',
    noSavedFilters: "You don't have any saved filters yet",
    noSearchResult: 'No Results Found',
    noValuesForAttributes: 'You currently have no values for this attribute.',
    notAvailable: '–',
    pipelineName: 'Give your pipeline a name !',
    problemsSelected: 'No problem(s) selected',
    referenceContent: 'No Reference Content has been uploaded yet!',
    salesDataUnavailable: 'Sales data is unavailable',
    searchAttribute: 'Search or select an attribute',
    searchGroup: 'Search or select a group',
    searchTags: 'Search tags...',
    selectMetric: 'Select Metric...',
    stockRecommandationsEmpty: '"{{marketplace}}" stock recommandations is empty',
    unclassified: 'Unclassified',
    unknown: 'Unknown',
    unselected: 'Unselected',
    untitled: 'Untitled',
    vendorAccountName: 'Give your Vendor account a name',
    vendorEmail: 'Your Amazon Vendor account admin email',
    vendorOtpToken: 'Your One-Time-Password Token',
    vendorPassword: 'Choose a password',
  },
  errors: {
    404: '404 Not Found',
    adBlock:
      'Do you have an ad blocker enabled? Seelk Studio uses advertising data which conflicts with most ad blockers.',
    amountTooHigh: 'Your attributed budget is higher than your overall budget !',
    anErrorOccured: 'An error occured: ',
    asinsNotInList: 'These ASINS do not exist in your catalogue or are not part of the compliance issue list:',
    buyBoxData: 'No data available for this period',
    cannotBeEdited: 'Values from this attribute cannot be edited.',
    changeEmail: 'An error occured while changing your email.',
    changePassword: 'An error occured while changing your password.',
    collectionAlreadyExists: 'This attribute already exists.',
    collectionMustContain: 'This attribute name must contain at least one letter or number.',
    connectivityIssue: 'Hmm... There seems to be a problem with your connection 😯',
    dateRangeNotSet: 'You forgot to set a date range. Click to apply!',
    dateRangeTooLong: 'Selected date range is too long',
    definitionAlreadyExists: 'This attribute already exists.',
    duplicateItem: {
      one: 'is already added to the list',
      other: 'are already added to the list',
    },
    duplicatedValues: 'Duplicated values',
    duplicatedValuesImport:
      'There are some duplicated values in the file you uploaded, if you confirm the upload, only the last duplicated entries will be loaded.',
    enterValidUrl: 'Please enter a valid URL',
    error: 'Error',
    errorAdvertisingCallback: 'Your account verification did not work. ',
    errorCannotBeRetried: 'An error occured and a retry is not possible 😧 Please refresh',
    errorOccured: 'Oops, it looks like there was an error!',
    fileAsins: {
      one: 'ASIN is missing (or wrong) in the file you dropped at the line :',
      other: 'ASIN is missing (or wrong) in the file you dropped at the lines :',
    },
    fileColumn: {
      one: 'The following column is missing in the file you dropped: ',
      other: 'The following columns are missing in the file you dropped: ',
    },
    fileValue: {
      one: 'Value is wrong (not an expected value, or missing), in the file you uploaded :',
      other: 'Values are wrong (not an expected value, or missing), in the file you uploaded :',
    },
    filterError: "'{{name}}' is not allowed as a filters set name, choose another one please",
    formatError: 'Oops! We do not accept ~!@#$%^&*()+=[]\\;’,./{}|:”<>? characters for an account name.',
    genericError: 'Something went wrong.',
    groupAlreadyExists: 'You already added this one',
    groupMustContain: 'This group must contain at least one letter or number.',
    invalidCredentials: 'Invalid Credentials',
    invalidEmail: 'Invalid Email',
    keywords: 'No suggested keywords',
    linkNotFound: 'Link could not be found',
    newAccountError: 'This name is already taken.',
    notAnAsin: {
      one: 'is not an ASIN.',
      other: 'are not ASINs.',
    },
    notFound: {
      back: 'Back to Seelk Studio',
      message: 'We’re really sorry, but it looks like the link you clicked on is broken',
      oops: 'OOPS!',
      page: 'WE CAN’T FIND THAT PAGE',
    },
    passwordsMismatch: 'Passwords mismatched',
    priceReference: 'Price Reference file has not been uploaded or is not complete',
    report: 'Fill an error report',
    scrapeNowNotAvailable: 'Not available when adding more than 500 ASINs',
    sellerTokenError: 'Your Seller ID & Authentication Token did not work. Click here for help',
    unexpectedError: 'An unexpected error occured',
    unexpectedErrorDownload: 'An unexpected error happened while downloading your file',
    unexpectedErrorUpload: 'An unexpected error happened while uploading your file',
    upload: 'Something went wrong during the upload',
    uploadFileWithFields: 'Error in line {{line}} on the column {{field}} : {{error}}',
    uploadImages: 'These images could not be uploaded to the server, due to naming issues:',
    vendorEmailError: 'This email is already used',
    vendorNameError: '{{accountName}} is already used',
    vendorPrice: 'No vendor price has been retrieved for this period',
    wrongFile: 'Wrong file given: Unsupported format !',
  },
  global: {
    addKeywordsManually: 'Add keywords manually',
    barChart: 'Switch to bar chart',
    blog: 'Seelk Blog',
    button: 'Click Me!',
    cannotShowGroups: "Oops! You cannot 'Show Groups' for attributes with more than 7 values",
    changeEmail: 'Change account email',
    changePassword: 'Change account password',
    chartLegendOnOff: 'Legend On/Off',
    chartName: 'Give your custom graph a name...',
    checkBackMaintenance: 'Please check back in an hour!',
    checkHelpCenter: 'Why not check out our Help Center?',
    chooseMultipleMetrics: 'Choose multiple metrics',
    chooseYourFrequency: 'Choose your frequency',
    clearDateRange: 'Clear date range',
    columnsDisplay: 'Columns Display',
    confirmEmail: 'New email confirmation',
    confirmNewPassword: 'New password confirmation',
    confirmPassword: 'Confirm your password',
    connecting: 'Connecting ...',
    customDateRange: 'Custom date range',
    dataLabelOnOff: 'Data Labels On/Off',
    decreaseDataLabelsFontSize: 'Decrease data labels size',
    descriptionAttribute: "Describe your attribute's use case",
    displayedColumns: 'Displayed columns',
    dynamicTagForTheLast: 'For the last',
    dynamicTagPreviousProducts: 'and the previous period was',
    dynamicTagProducts: 'products',
    dynamicTagWant: 'I want my',
    dynamicTagWas: 'was',
    dynamicTagWhich: 'for which:',
    enterPassword: 'Enter your password',
    exportFileName: 'Name of your excel file for export...',
    feedbackForm: 'Feedback Form',
    filtersName: 'Filters Name',
    getInTouch: 'Need to get in touch? Do not hesitate to contact us on',
    getStarted: 'Get Started',
    globalSalesPlaceholder: 'Seelk cross-client data for sales and units sold',
    groupPlaceholder: 'Type and hit enter to add a new group',
    havingTrouble: 'Having trouble?',
    helpCenter: 'Help Center',
    hideDataLabels: 'Hide data labels',
    howHelp: 'How can we help?',
    imagesAccepted: '.jpg, .jpeg .png and .tiff images are formats accepted.',
    increaseDataLabelsFontSize: 'Increase data labels size',
    input: 'Type here...',
    learnMore: 'Learn more',
    lineChart: 'Switch to line chart',
    limitedTo: 'Limited to {{count}}',
    liveTechSupport: 'Chat support',
    maxBestSellers: 'Max Best Sellers',
    maxDays: 'Max. {{count}} days',
    maxKeywords: 'Max Keywords',
    maxPage: 'Max Pages',
    maximizeView: 'Maximize to fullframe view',
    minimizeView: 'Minimize to normal view',
    more: 'More ({{count}})',
    nameAttribute: 'Give your attribute a name...',
    namePlaceholder: 'Give your account a name...',
    needHelp: "Do you need help? Visit Seelk Studio's",
    newAccountSetup: 'New Account Setup',
    newEmail: 'New email',
    newIssueRequest: 'New Issue/Request',
    newPassword: 'New password',
    oldEmail: 'Old email',
    oldPassword: 'Old password',
    passwordConfirmation: 'Password confirmation',
    pipelineName: 'Pipeline Name',
    readMore: 'Read more',
    readMorePerformances: 'Read more about data performances inside Seelk Studio',
    removeDateRange: 'Remove date range',
    repartitionPrice: {
      one: 'One product with a price between {{score}}{{currency}}',
      other: '{{count}} products with a price between {{score}}{{currency}}',
    },
    repartitionScore: {
      one: 'One product with a rate of {{score}}',
      other: '{{count}} products with a rate of {{score}}',
    },
    sayian: 'Learn more about Saiyan Power',
    scoringExplanations: 'Need help understanding the scoring ?',
    scrapeCategoryRankings: 'Scrape Category Rankings',
    scrapeKeywordRankings: 'Scrape Keyword Rankings',
    scrapeSnapshots: 'Scrape Snapshots',
    searchClient: 'Search client',
    selectCategory: 'You need to select at least one category first!',
    selectDateRange: 'Select date range',
    selectMetricTheme: 'Select Metric Theme',
    selectPeriod: 'Select a previous period',
    selectedPeriod: 'Selected Period',
    showBothPurchaseOrders: 'Show all Purchase Orders',
    showClosedPurchaseOrders: 'Show closed Purchase Orders',
    showConfirmedPurchaseOrders: 'Show opened Purchase Orders',
    showDataLabels: 'Show data labels',
    showFullYear: 'Show last year tendency',
    showGroups: 'Show groups by selected attribute',
    showMissedSellIn: 'Show Refused PO',
    softwareStronger: 'We are making our software stronger !',
    supportMessage: 'Please check your internet connection and try again.',
    switchToChromeSubtitle:
      "Seelk Studio only works on Chrome and Safari right now. We're working to support other platforms. In the meantime, please use Chrome.",
    switchToChromeTitle: 'Can we switch to Chrome?',
    switchToDesktopSubtitle:
      'Seelk Studio is currently only available on desktop with a minimum recommended resolution of 1280x800 on Chrome and Safari browsers. Apologies for any inconveniences.',
    switchToDesktopTitle: "Oops! We don't support mobile yet!",
    takeAWhile: 'This may take a while',
    textArea: 'Type some text here (it can be on multiple lines)',
    textAreaAsinsPlaceholder: 'Type your ASINs here (use separate lines or a comma between each ASIN)',
    textAreaAttributesPlaceholder: 'Type your values here (separated by new lines)',
    textAreaKeywordsPlaceholder: 'Type your keywords here (separated by new lines)',
    underMaintenance: "We're down for scheduled maintenance...",
    updatingFrequency: 'Updating Frequency',
    welcome: 'Welcome to Seelk Studio',
  },
}
