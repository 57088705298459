import {ofType} from 'redux-observable'
import {ReplaySubject, Subject} from 'rxjs'
import {map, shareReplay} from 'rxjs/operators'

import {reduxActionStream} from '../utils/extensions/rx'

const applyPermission = observable$ => observable$

class MetricsTracker {
  constructor() {
    if (!MetricsTracker.instance) {
      this.started = false
      this.stream$ = new Subject()
      this.user$ = this.reduxActionStream().pipe(
        ofType('APP_SET_USER'),
        map(action => action.payload.user),
        shareReplay(1)
      )
      this.identified$ = new ReplaySubject()
      MetricsTracker.instance = this
    }
    return MetricsTracker.instance
  }

  get plugins() {
    return ['crud', 'export', 'import', 'page-change', 'session', 'tracking-events']
  }

  actionStream() {
    return applyPermission(this.stream$)
  }

  reduxActionStream() {
    return applyPermission(reduxActionStream())
  }

  start() {
    if (this.started) return
    this.user$.subscribe(user => {
      const tenant = user ? user.client : 'Undefined'
      this.plugins.forEach(plugin => require(`./plugins/${plugin}`).default(this, tenant)) // eslint-disable-line
      this.started = true
    })
  }
}

export const startTracking = () => new MetricsTracker().start()
export const push = payload => new MetricsTracker().stream$.next(payload)
