import RelieverRegistry from 'react-redux-reliever'
import {createSelector} from 'reselect'

export const advertisersModuleState = state => RelieverRegistry.moduleState('advertisers', state)

const rawAdvertisersSelector = state => advertisersModuleState(state).advertisersData
export const advertisersSelector = createSelector(
  rawAdvertisersSelector,
  advertisers => advertisers && advertisers.asMutable({deep: true})
)

export const indexedAdvertisersSelector = createSelector(advertisersSelector, advertisers => {
  if (!advertisers) return {}
  return advertisers.reduce((acc, a) => {
    acc[a.id] = a
    return acc
  }, {})
})
