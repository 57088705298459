import React from 'react'

import {Box, Row} from 'jsxstyle'
import _ from 'lodash'
import {
  AutoSizer,
  Column,
  HeaderMouseEventHandlerParams,
  RowMouseEventHandlerParams,
  ScrollEventData,
  Table,
  TableCellDataGetterParams,
  TableCellProps,
  TableHeaderProps,
} from 'react-virtualized'

import {capitalize, get} from '../../..'
import colors from '../../../colors'
import ErrorBoundary from '../../../ErrorBoundary'
import i18n from '../../../i18n'
import BasicCell from '../../cells/BasicCell'
import CheckboxCell from '../../cells/CheckboxCell'
import HeaderCheckboxCell from '../../cells/HeaderCheckboxCell'
import RotatingHeader from './RotatingHeader'

import 'react-virtualized/styles.css'

// Prevents minimum scrolling to toggle scrolling placeholder
const isRowOutOfView = (isScrolling: boolean, {rowIndex, parent}: {rowIndex: number; parent: any}) => {
  return isScrolling && !(rowIndex >= parent._rowStartIndex && rowIndex <= parent._rowStopIndex)
}

type ColumnType = {
  key: string
  canGrow?: boolean
  canShrink?: boolean
  className?: string
  component?: any
  headerClassName?: string
  headerComponent?: any
  headerStyle?: Record<string, string | number>
  label?: React.ReactNode | undefined
  maxWidth?: number | undefined
  minWidth?: number | undefined
  props?: any
  sort?: ((direction: 'asc' | 'desc', k?: string) => (a: string | number, b: string | number) => 1 | -1) | boolean
  style?: Record<string, string | number>
  width: number
}

interface VirtualTableProps {
  columns: ColumnType[]
  disableHeader: boolean
  headerHeight: number
  items: any[]
  rowHeight: number
  activeItem?: string
  activeRowStyle?: Record<string, any>
  autoExpandSingleChild?: boolean
  checkboxStyle?: string
  childRowStyle?: Record<string, any>
  className?: string
  defaultSort?: string
  defaultSortDir?: 'asc' | 'desc'
  expandable?: boolean
  headerStyle?: Record<string, any>
  height?: number
  highlightSelection?: boolean
  onHeaderClick?: (params: HeaderMouseEventHandlerParams) => void
  onRowClick?: (info: RowMouseEventHandlerParams) => void
  onRowMouseOver?: (name: string) => void
  onlyShowChildren?: boolean
  overflowX?: boolean
  overscanRowCount?: number
  parentRowStyle?: Record<string, any>
  placehold?: boolean
  rowClassName?: string
  rowStyle?: Record<string, any>
  selectItem?: (item: any) => void
  selectedItemKey?: string
  selectedItems?: Record<string, any>
  shouldSplitColor?: boolean
  shouldUpdateSelectedItems?: boolean
  toggleAll?: () => void
  updateSelectedItems?: (items: Record<string, any>) => void
  width?: number
}

interface VirtualTableState {
  sort: {
    key: string | null
    dir: 'asc' | 'desc'
  }
  expanded: number | null
  items: any[]
  addedRows: number
  scrollToIndex: number | undefined
  boxContainerWidth: number
}

class VirtualTable extends React.PureComponent<VirtualTableProps, VirtualTableState> {
  delta: number

  lastPos: number | null

  timer: ReturnType<typeof setTimeout> | null

  constructor(props: VirtualTableProps) {
    super(props)

    this.delta = 0
    this.lastPos = null
    this.timer = null

    const {defaultSort = null, defaultSortDir = 'asc'} = props
    this.state = {
      sort: {key: defaultSort, dir: defaultSortDir},
      expanded: null,
      items: props.items,
      addedRows: 0,
      scrollToIndex: undefined,
      boxContainerWidth: 0,
    }
  }

  UNSAFE_componentWillMount() {
    this.setState(this.refreshList(this.props))
  }

  UNSAFE_componentWillReceiveProps(nextProps: VirtualTableProps) {
    const {defaultSort = null, defaultSortDir = 'asc'} = nextProps
    this.setState(
      this.refreshList(nextProps, {
        ...this.state,
        sort:
          defaultSort && defaultSort !== this.state.sort.key
            ? {key: defaultSort, dir: defaultSortDir}
            : this.state.sort,
      })
    )
  }

  componentDidUpdate(prevProps: VirtualTableProps) {
    const {
      items,
      selectedItems = {},
      selectedItemKey = 'id',
      updateSelectedItems = null,
      shouldUpdateSelectedItems = true,
    } = this.props
    if (
      updateSelectedItems &&
      shouldUpdateSelectedItems &&
      Object.keys(selectedItems).length > 0 &&
      !_.isEqual(prevProps.items, items)
    ) {
      const groupedData = items
        .reduce((acc, i) => {
          if (i.children) acc.push(i.children)
          else acc.push(i)
          return acc.flat()
        }, [])
        .map((i: any) => i[selectedItemKey])
      Object.keys(selectedItems).forEach(key => {
        if (!groupedData.includes(key)) delete selectedItems[key]
      })
      updateSelectedItems(selectedItems)
    }
  }

  componentWillUnmount() {
    if (this.props.updateSelectedItems) this.props.updateSelectedItems({})
  }

  onSort = (sortBy: string) => (sortDirection: 'asc' | 'desc') => {
    this.setState({
      ...this.refreshList(this.props, {
        ...this.state,
        sort: {
          key: sortBy,
          dir: sortDirection,
        },
      }),
      expanded: null,
    })
  }

  onRowClick = ({event, index, rowData}: RowMouseEventHandlerParams) => {
    const {expandable = false, onRowClick} = this.props
    if (this.guessIsChild(index, !!rowData.children) && onRowClick) onRowClick({event, index, rowData})
    else if (expandable) this.toggleExpand({index, rowData})
    else if (onRowClick) onRowClick({event, index, rowData})
  }

  onScroll = ({scrollHeight, scrollTop}: ScrollEventData) => {
    if (this.lastPos !== null && scrollTop < scrollHeight) {
      this.delta = Math.abs(scrollTop - this.lastPos)
    }
    this.lastPos = scrollTop
    if (this.timer) clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.lastPos = null
      this.delta = 0
    }, 100)
  }

  getOnlyChildren(items: any[]) {
    return [].concat(...items.map(i => i.children))
  }

  updateItemsWithExpandedChildren(items: any[]) {
    const {onlyShowChildren = false} = this.props
    if (!onlyShowChildren && this.state.expanded !== null) {
      const parent = items[this.state.expanded]
      if (parent && parent.children && parent.children.length > 0) {
        const tmp = [...items]
        tmp.splice(this.state.expanded + 1, 0, ...parent.children)
        return tmp
      }
    }
    return items
  }

  guessIsChildUnderParent = (index: number) =>
    this.state.expanded !== null && // Parent is expanded
    index - this.state.expanded <= this.state.addedRows && // Child is not after parent children
    index - this.state.expanded > 0 // Child is not before parent (or is parent itself)

  guessIsChild = (index: number, hasChildren: any) => {
    const {autoExpandSingleChild = false, expandable = false} = this.props
    if (expandable) {
      return this.guessIsChildUnderParent(index) || (autoExpandSingleChild && !hasChildren) // We are autoexpanding single children and this is one
    }
    return false
  }

  updateItemsWithSingleChildren(items: any[]) {
    return items.map(n => {
      if (n.children.length === 1) {
        return n.children[0]
      }
      return n
    })
  }

  refreshList(props: VirtualTableProps, state: VirtualTableState = this.state) {
    const {activeItem = null, highlightSelection = false} = props
    let {items} = props
    const {onlyShowChildren = false, autoExpandSingleChild = false} = props
    const {dir, key} = state.sort

    let index: number | undefined

    if (onlyShowChildren) {
      items = this.getOnlyChildren(items)
    } else if (autoExpandSingleChild) {
      items = this.updateItemsWithSingleChildren(items)
    }

    if (key && props.columns.find(c => c.key === key)) {
      let sortFunc = props.columns.find(c => c.key === key)?.sort

      if (!sortFunc || typeof sortFunc === 'boolean') {
        sortFunc = (direction: 'asc' | 'desc', k = key) => (a, b) => {
          const aValue = get(a, k, 0)
          const bValue = get(b, k, 0)
          if (direction === 'asc') return aValue >= bValue ? 1 : -1
          return aValue <= bValue ? 1 : -1
        }
      }
      items = items.sort(sortFunc(dir, key))
    }

    if (activeItem && this.state.expanded) {
      index = this.updateItemsWithExpandedChildren(items).reduce(
        (parent, child, childIndex) => (child.id === activeItem ? childIndex : parent),
        undefined
      )
    } else if (activeItem && !this.state.expanded) {
      index = items.reduce((parent, child, childIndex) => (child.id === activeItem ? childIndex : parent), undefined)
    } else if (highlightSelection || props.items.some(element => element.checked)) {
      index = props.items.findIndex(element => element.checked)
    }
    return {items, sort: state.sort, scrollToIndex: index}
  }

  rowStyle = (index: number, rowData: any, shouldSplitColor = true) => {
    const {
      activeItem = null,
      activeRowStyle = {},
      childRowStyle = {},
      expandable = false,
      highlightSelection = false,
      onlyShowChildren = false,
      parentRowStyle = {},
      rowStyle = {},
    } = this.props
    if (rowData == null) return {}
    if (rowData.checked)
      return {
        backgroundColor: colors.primaryLight.rgba,
        ...rowStyle,
      }
    const _rowStyle = {
      backgroundColor: shouldSplitColor === false || index % 2 === 0 ? 'white' : 'whitesmoke',
      ...rowStyle,
    }
    if (this.guessIsChild(index, !!rowData.children)) {
      if (rowData.id === activeItem) return {..._rowStyle, ...activeRowStyle}
      else if (this.guessIsChildUnderParent(index) && !onlyShowChildren) return {..._rowStyle, ...childRowStyle}
      return _rowStyle
    }

    if (expandable) return {..._rowStyle, ...parentRowStyle}
    else if (rowData.id === activeItem || (!global.isNaN(rowData.id) && rowData.id.toString() === activeItem))
      return {..._rowStyle, ...activeRowStyle}
    else if (highlightSelection && rowData.checked) return {..._rowStyle, backgroundColor: colors.primaryLight.rgba}
    return _rowStyle
  }

  toggleExpand = ({index, rowData}: Pick<RowMouseEventHandlerParams, 'index' | 'rowData'>) => {
    const {expandable = false} = this.props
    // We check that we can expand and that this is a parent with children
    if (expandable && rowData.children && rowData.children.length > 0) {
      let i = index // We need to recalculate the index because expanded elements are messing with it
      if (this.state.expanded !== null && index > this.state.expanded) {
        // There is an active element so we remove its children from the index
        i -= this.state.addedRows
      }
      if (this.state.expanded === i) {
        // The clicked element is the active one
        this.setState({expanded: null, addedRows: 0}) // We deactivate it
      } else {
        // The clicked element is another element
        this.setState({expanded: i, addedRows: rowData.children.length})
      }
    }
  }

  cellRenderer = (column: ColumnType) => ({
    cellData,
    columnData,
    rowData,
    rowIndex,
    isScrolling,
    parent,
  }: TableCellProps) => {
    const {placehold = true} = this.props
    if (!column.key) return null
    else if (column.component) {
      const CellComponent = column.component
      return (
        <ErrorBoundary>
          <CellComponent
            value={cellData}
            extra={columnData}
            item={rowData}
            index={rowIndex}
            placehold={placehold && this.delta > 20}
            active={rowIndex === this.state.expanded}
            isScrolling={isRowOutOfView(isScrolling, {rowIndex, parent})}
          />
        </ErrorBoundary>
      )
    }
    return <BasicCell value={cellData} extra={columnData} />
  }

  headerRenderer = (column: ColumnType) => ({dataKey, label, columnData}: TableHeaderProps) => {
    if (column.headerComponent) {
      const HeaderComponent = column.headerComponent
      return (
        <ErrorBoundary>
          <HeaderComponent
            label={label}
            sort={column.sort && this.onSort(dataKey)}
            sortActive={this.state.sort.key === dataKey}
            sortDirection={this.state.sort.dir}
            columnData={columnData}
            className="ReactVirtualized__Table__headerTruncatedText"
          />
        </ErrorBoundary>
      )
    }
    return (
      <RotatingHeader
        label={capitalize(label)}
        sort={column.sort ? this.onSort(dataKey) : null}
        sortActive={this.state.sort.key === dataKey}
        sortDirection={this.state.sort.dir}
        columnData={columnData}
        // TO REMOVE
        // className="ReactVirtualized__Table__headerTruncatedText"
      />
    )
  }

  rowMouseOver = (index: number, {name}: any) => {
    if (typeof this.props.onRowMouseOver === 'function') {
      this.props.onRowMouseOver(name)
    }
  }

  cellDataGetter = ({dataKey, rowData}: TableCellDataGetterParams) => get(rowData, dataKey)

  calcWidth = () =>
    Math.max(
      this.state.boxContainerWidth,
      this.props.columns.reduce((sum, {width = 0}) => sum + width, 10)
    )

  toggleAll = () => {
    const {
      selectedItems = {},
      selectedItemKey = 'id',
      updateSelectedItems,
      shouldUpdateSelectedItems = true,
      toggleAll = () => {},
    } = this.props
    if (!shouldUpdateSelectedItems) toggleAll()
    else if (updateSelectedItems)
      if (Object.keys(selectedItems).length) {
        updateSelectedItems({})
      } else {
        const all = this.state.items.reduce((acc, i) => {
          if (i.children) acc.push(...i.children)
          else acc.push(i)
          return acc
        }, [])
        updateSelectedItems(
          all.reduce((acc: Record<string, any>, item: any) => {
            acc[item[selectedItemKey]] = item // eslint-disable-line no-param-reassign
            return acc
          }, {})
        )
      }
  }

  selectItem = ({item, e}: {e: React.MouseEvent<HTMLInputElement>; item: any}) => {
    e.preventDefault()
    e.stopPropagation()
    const {
      selectedItems = {},
      updateSelectedItems,
      selectedItemKey = 'id',
      shouldUpdateSelectedItems = true,
      selectItem = () => {},
    } = this.props
    if (!shouldUpdateSelectedItems) selectItem(item)
    else if (updateSelectedItems) {
      let children = null
      if (item.children && item.children.length > 0) {
        children = item.children.filter((i: any) => selectedItems[i[selectedItemKey]])
        children = children.length === 0 ? item.children : children
      }
      const newItems = (children || [item]).reduce(
        (acc: Record<string, any>, i: any) => ({...acc, [i[selectedItemKey]]: i}),
        {}
      )
      const allItems = {...selectedItems, ...newItems}
      const filteredItems = Object.keys(allItems).reduce((acc: Record<string, any>, key) => {
        if (selectedItems[key] && newItems[key]) return acc
        acc[key] = allItems[key]
        return acc
      }, {})
      updateSelectedItems(filteredItems)
    }
  }

  getColumns() {
    const checkboxColumn: ColumnType = {
      canGrow: false,
      canShrink: false,
      component: CheckboxCell,
      key: 'checked',
      label: '',
      headerComponent: HeaderCheckboxCell,
      props: {
        checkboxStyle: this.props.checkboxStyle || null,
        toggle: this.selectItem,
        toggleAll: this.toggleAll,
        checked: this.props.shouldUpdateSelectedItems
          ? Object.keys(this.props.selectedItems || {}).length === this.getOnlyChildren(this.state.items).length
          : this.state.items.every(item => Object.keys(this.props.selectedItems || {}).includes(item.id)),
      },
      width: 30,
    }
    if (this.props.updateSelectedItems) return [checkboxColumn, ...this.props.columns]
    return this.props.columns
  }

  isChecked = (items: any[]) => {
    const {selectedItemKey = 'id', selectedItems = {}} = this.props
    return items.map(i => {
      if (i.children && i.children.length) {
        return {...i, checked: !!i.children.find((child: any) => selectedItems[child[selectedItemKey]])}
      }
      return {...i, checked: !!selectedItems[i[selectedItemKey]]}
    })
  }

  render() {
    const {expandable = false, rowClassName = '', overflowX = false} = this.props
    let {items} = this.state
    if (expandable) {
      items = this.updateItemsWithExpandedChildren(items)
    }
    items = this.isChecked(items)
    return (
      <div style={{flex: '1 1 auto', alignSelf: 'stretch', height: '100%'}}>
        <AutoSizer disableHeight={!!this.props.height} disableWidth={!!this.props.width}>
          {({height, width}) => (
            <Box
              props={{
                ref: (boxContainer: HTMLDivElement) => {
                  if (boxContainer && boxContainer.clientWidth !== this.state.boxContainerWidth)
                    this.setState({boxContainerWidth: boxContainer.clientWidth})
                },
              }}
              width={this.props.width || width}
              height={(this.props.height || height) + 10}
              style={{overflowX: overflowX ? 'auto' : 'hidden', overflowY: 'hidden'}}
              className="ReactVirtualized__Table__Wrapper"
            >
              <Table
                className={this.props.className || ''}
                width={overflowX ? this.calcWidth() : this.props.width || width}
                height={this.props.height || height}
                disableHeader={this.props.disableHeader}
                headerHeight={this.props.headerHeight}
                onRowClick={this.onRowClick}
                onRowMouseOver={({index}) => this.rowMouseOver(index, items[index])}
                onHeaderClick={this.props.onHeaderClick}
                overscanRowCount={this.props.overscanRowCount}
                rowHeight={this.props.rowHeight}
                rowGetter={({index}) => items[index]}
                rowCount={items.length}
                rowClassName={rowClassName}
                rowStyle={({index}: {index: number}) => this.rowStyle(index, items[index], this.props.shouldSplitColor)}
                onScroll={this.onScroll}
                scrollToIndex={this.state.scrollToIndex}
                noRowsRenderer={() => (
                  <Row justifyContent="center" style={{marginTop: 50}}>
                    <h4>{i18n.t('placeholders.empty.data')}</h4>
                  </Row>
                )}
              >
                {this.getColumns().map(column => {
                  const {
                    key,
                    canGrow,
                    canShrink,
                    props,
                    headerStyle = {},
                    style = {},
                    sort,
                    width = 0,
                    ...rest
                  } = column
                  return (
                    <Column
                      key={key}
                      dataKey={key}
                      cellDataGetter={this.cellDataGetter}
                      cellRenderer={this.cellRenderer(column)}
                      headerRenderer={this.headerRenderer(column)}
                      flexGrow={canGrow ? 1 : 0}
                      flexShrink={canShrink ? 1 : 0}
                      columnData={props}
                      headerStyle={{
                        display: props && props.rotatedHeader ? 'block' : 'flex',
                        alignItems: 'flex-end',
                        height: props && props.rotatedHeader ? 'unset' : this.props.headerHeight || 30,
                        paddingLeft: props && props.rotatedHeader ? 15 : 10,
                        paddingRight: props && props.rotatedHeader ? 15 : 10,
                        marginLeft: 0,
                        marginRight: 0,
                        ...headerStyle,
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        paddingLeft: sort ? 15 : 10,
                        paddingRight: sort ? 15 : 10,
                        marginLeft: 0,
                        marginRight: 0,
                        ...style,
                      }}
                      width={width}
                      {...rest}
                    />
                  )
                })}
              </Table>
            </Box>
          )}
        </AutoSizer>
      </div>
    )
  }
}

export default VirtualTable
