import React, {useState} from 'react'

import {Grid, GridColumn, GridRow, Header, Icon, Modal} from 'semantic-ui-react'
import styled from 'styled-components'

import i18n from '../../i18n'
import SeelkButton from './SeelkUIKit/SeelkButton'

const StyledHeaderRow = styled(GridRow)`
  &&&&&& {
    justify-content: space-between;
  }
`

const StyledHeader = styled(Header)`
  &&&&&& {
    margin: 0;
  }
`

const StyledActionColumn = styled(GridColumn)`
  &&&&&& {
    padding: 0 5px;
  }
`

const StyledActionsContainerRow = styled(GridRow)`
  &&&&&& {
    display: flex;
    justify-content: flex-end;
  }
`

interface ConfirmActionProps {
  disabled?: boolean
  onConfirm: (event: React.MouseEvent<HTMLElement>) => void
  trigger: React.ReactNode
  title: string
  text: string
  open?: boolean
  onCancel?: (event: React.MouseEvent<HTMLElement>) => void
}

function ConfirmAction({
  disabled = false,
  title,
  text,
  trigger,
  onConfirm,
  open = false,
  onCancel,
}: ConfirmActionProps) {
  const [modalOpen, setModalOpen] = useState(open || false)

  const actionsProvider = i18n.createProvider('actions')

  const closeModal = (e: React.MouseEvent<HTMLElement>) => {
    setModalOpen(false)
    if (onCancel) onCancel(e)
  }
  const handleConfirm = (e: React.MouseEvent<HTMLElement>) => {
    onConfirm(e)
    closeModal(e)
  }

  return (
    <Modal
      open={modalOpen}
      size="small"
      onClose={e => closeModal(e)}
      trigger={trigger}
      onOpen={() => setModalOpen(true)}
    >
      <Modal.Content>
        <Grid padded="horizontally">
          <StyledHeaderRow>
            <StyledHeader as="h4">{title}</StyledHeader>
            <Icon name="close" onClick={closeModal} style={{cursor: 'pointer'}} />
          </StyledHeaderRow>
          <GridRow stretched>
            <p>{text}</p>
          </GridRow>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <StyledActionsContainerRow>
          <StyledActionColumn>
            <SeelkButton content={actionsProvider('global.cancel')} onClick={closeModal} type="cancel" />
          </StyledActionColumn>
          <StyledActionColumn>
            <SeelkButton
              disabled={disabled}
              content={actionsProvider('global.delete')}
              onClick={handleConfirm}
              type="delete"
              icon="trash alternate"
              iconPosition="left"
            />
          </StyledActionColumn>
        </StyledActionsContainerRow>
      </Modal.Actions>
    </Modal>
  )
}

export default ConfirmAction
