import React from 'react'

import {Col, Row} from 'jsxstyle'
import {toast} from 'react-toastify'
import {Icon, Label} from 'semantic-ui-react'
import styled from 'styled-components'

import {clamp} from '../..'
import colors from '../../colors'
import {PoppersPopup, SeelkSectionLabel} from '../../common-ui'
import i18n from '../../i18n'

const StyledImg = styled.img`
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 40px;
  margin-right: 16px;
  box-shadow: 0px 0px 5px ${colors.shadow.rgba};
`

const StyledImgPlaceholder = styled.div`
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 40px;
  margin-right: 16px;
  box-shadow: 0px 0px 5px ${colors.shadow.rgba};
  background-color: ${colors.light.rgba};
`

const StyledProductName = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.6em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  color: ${colors.dark.rgba};
  :hover {
    text-decoration: underline;
    cursor: pointer;
    color: ${colors.primary.rgba};
  }
`

const StyledProductNamePlaceholder = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${colors.darkAccent.rgba};
  font-style: italic;
`

const StyledAsin = styled.span`
  font-size: 11px;
  color: ${colors.darkLight.rgba};
  line-height: 1.6em;
  :hover {
    text-decoration: underline;
    cursor: copy;
  }
`

const StyledTrigger = styled.span`
  cursor: pointer;
  margin-left: 5px;
  color: ${colors.darkLight.rgba};
  font-size: 0.85em;
  :hover {
    text-decoration: underline;
  }
`

const StyledWrapper = styled.div`
  display: inline;
  margin: 0 2px;
`

type TagType = {
  [slug: string]: {
    slug: string
    name: string
    color: string
  }
}

type ItemType = {
  asin: string
  key: string
  imageUrl?: string
  pageUrl?: string
  page_url: string
  section?: 'MAIN' | 'EXTR' | 'DETA' | 'COMP'
  tags?: string[]
  title?: string
}

type ExtraType = {
  tagList?: TagType
  settings?: {
    showTags?: boolean
    showProductImage?: boolean
    showAsin?: boolean
    showSection?: boolean
    openPageUrl?: boolean
    searchTermOptionToHide?: boolean
  }
  displayTagsModal?: ((item: ItemType) => void) | null
  link?: (item: ItemType) => string
  openRma?: ({selectedOffer}: {selectedOffer: string}) => string
}

const copyAsin = (asin: string) => {
  const textField = document.createElement('textarea')
  textField.value = asin
  textField.style.height = '0'
  document.body.appendChild(textField)
  textField.select()
  try {
    const success = document.execCommand('copy')
    if (success) {
      textField.remove()
    }
  } catch (err) {
    textField.remove()
  }
}

const isClickable = (extra: ExtraType, item: ItemType, openPageUrl: boolean) => {
  if (extra.link) global.open(extra.link(item))
  else if (extra.openRma) extra.openRma({selectedOffer: item.key})
  else if (openPageUrl) global.open(item.pageUrl ?? item.page_url)
}

const displayTags = (tags: string[], item: ItemType, extra: ExtraType) => {
  const {displayTagsModal = null, tagList = {}} = extra
  const tagSize = tags.length > 2 ? 12 : 16

  return (
    <Row alignItems="center" width="max-content">
      {tags.slice(0, 2).map(slug =>
        tagList[slug] ? (
          <PoppersPopup
            style={{padding: '5px 8px', fontSize: '12px'}}
            hoverable
            key={slug}
            position="top center"
            disabled={tagList[slug]?.name?.length <= tagSize}
            trigger={
              <StyledWrapper>
                <Label
                  key={`${item.asin}${slug}`}
                  value={slug}
                  style={{
                    backgroundColor: tagList[slug] ? tagList[slug].color : '#aaa',
                    color: 'white',
                    padding: '4px 6px',
                    margin: '4px 0 4px 2px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {tagList[slug] ? clamp(tagList[slug].name, tagSize) : ''}
                </Label>
              </StyledWrapper>
            }
            content={tagList[slug].name}
          />
        ) : null
      )}
      {tags.length > 2 && displayTagsModal && (
        <StyledTrigger
          onClick={e => {
            e.stopPropagation()
            displayTagsModal(item)
          }}
        >
          {i18n.t('settings.tags.seeAll', {count: tags.length})}
        </StyledTrigger>
      )}
    </Row>
  )
}

const displaySection = (section: 'MAIN' | 'EXTR' | 'DETA' | 'COMP') => {
  return <SeelkSectionLabel key={section} section={section} />
}

const displayImage = (imageUrl?: string | null) => {
  if (imageUrl) return <StyledImg src={imageUrl} alt="" />
  return <StyledImgPlaceholder />
}

interface ProductProps {
  item: ItemType
  extra: ExtraType
}

const Product = ({item, extra}: ProductProps) => {
  const tags = item.tags || []
  const {
    showTags = true,
    showProductImage = true,
    showAsin = true,
    showSection = false,
    openPageUrl = false,
    searchTermOptionToHide = false,
  } = extra?.settings ?? {}

  return (
    <Row maxWidth="100%" alignItems="center">
      {/* {item.imageUrl && showProductImage && <StyledImg src={item.imageUrl} alt="" />} */}
      {showProductImage && displayImage(item.imageUrl)}
      <Col maxWidth={showProductImage ? 'calc(100% - 48px)' : '100%'} paddingRight={10}>
        {item.title ? (
          <PoppersPopup
            content={item.title}
            basic
            trigger={
              <StyledProductName onClick={() => isClickable(extra, item, openPageUrl)}>{item.title}</StyledProductName>
            }
          />
        ) : showSection ? null : (
          <StyledProductNamePlaceholder>
            {searchTermOptionToHide ? i18n.t('unknownAsin') : i18n.t('imageNotAvailable')}
          </StyledProductNamePlaceholder>
        )}
        {showAsin && (
          <Row alignItems="center">
            <StyledAsin
              onClick={event => {
                event.stopPropagation()
                copyAsin(item.asin)
                toast.success(i18n.t('actions.select.copyAsin', {asin: item.asin}), {
                  autoClose: 5000,
                  style: {padding: '10px', borderLeft: `5px solid var(--toastify-icon-color-success)`},
                })
              }}
            >
              {item.asin}
            </StyledAsin>
            {item.pageUrl && (
              <Icon
                name="external"
                size="small"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.stopPropagation()
                  global.open(item.pageUrl)
                }}
                style={{color: colors.primary.rgba, cursor: 'pointer', marginLeft: 4}}
              />
            )}
          </Row>
        )}
        {showSection && (
          <div>
            {item.section ? (
              displaySection(item.section)
            ) : (
              <span
                style={{fontSize: 12, color: colors.lightDark.rgba, whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
              >
                {i18n.t('catalogue.section.notInCatalog')}
              </span>
            )}
          </div>
        )}
        {showTags && <div>{tags.length > 0 && extra?.tagList ? displayTags(tags, item, extra) : null}</div>}
      </Col>
    </Row>
  )
}

export default Product
