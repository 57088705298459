import React from 'react'

import {Row} from 'jsxstyle'
import styled from 'styled-components'

import i18n from '../../i18n'

const StyledSpan = styled.span`
  height: 20px;
  width: 15px !important;
  margin-right: 10px;
`

interface CellFlagsProps {
  value: string[]
}

const CellFlags = ({value}: CellFlagsProps) => {
  if (value.length === 0) return <div>{i18n.t('placeholders.empty.notAvailable')}</div>
  return (
    <Row>
      {value.map(c => (
        <StyledSpan key={c} className={`flag-icon flag-icon-${c.toLowerCase() === 'uk' ? 'gb' : c}`} />
      ))}
    </Row>
  )
}

export default CellFlags
