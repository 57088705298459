export default {
  compliance: {
    index: {
      avgComplianceScore: 'Avg. compliance score',
      contentComplianceScoresFile: 'Content-Compliance-Scores-File',
      contentDifferencesFile: 'Content-Differences-File',
      currentMasterContentFile: 'Current-Master-Content',
      expectedValue: 'From Master Content:',
      complianceContent: 'Compliance Content',
      complianceImages: 'Compliance Images',
      imageComplianceScoresFile: 'Images-Compliance-Scores-File',
      imageDifferencesFile: 'Image-Differences-File',
      lastCompute: 'Last compute:',
      lastMasterContentUpload: 'Last master content upload: ',
      matchPercentage: 'Match Percentage',
      notComputed: 'Waiting for compute',
      numberOfProducts: '# Products',
      retrievedValue: 'From Amazon:',
    },
    downloadFilteredData: {
      contentDifferences: 'Content Differences',
      imageComplianceScores: 'Image Compliance Scores',
      imageDifferences: 'Image Differences',
    },
    downloadMasterContent: {
      currentMasterContentFile: 'Current-Master-Content',
      masterContentTemplateFile: 'master_content_template_file',
      masterContentTemplateTitle: 'master-content-template',
    },
    tooltips: {
      modalTitle: 'What is the Compliance Dashboard?',
      modalSubtitle:
        'The compliance dashboard allows you a quick glance to compare individual details of your products’ master content with the most recent data gathered from Amazon.',
      masterContent: 'Master Content',
      amazonContent: 'Content on Amazon',
      matchInformations: 'Your content is either matched, conflicted or no content is available.',
      uploadSubTitle:
        'Click here to upload your master content in order to update your product Compliance scores with .csv/.xls/.xlsx from Amazon',
      downloadTitle: 'Download the Master Content (.xlsx)',
      downloadSubTitle: 'Click on this button at anytime to download your master content',
      tableIconsTitle: 'Those icons! What do they mean?',
      tableIconsMatchContent: 'Data from Amazon and the Master file are the same',
      tableIconsConflictContent:
        'The data from Amazon and the Master File are inconsistent. You can compare the two by hovering over the icon or clicking on the row',
      tableIconsNoContentContent: 'No data has been collected or input for field',
      tableOrdersTitle: 'Order content',
      tableOrdersSubTitle:
        'Click on the button to toggle ascending or descending order of content for the following fields:',
      tableOrdersBrandContent: 'Alphabetical ordering by brand',
      tableOrdersTitleContent: 'Alphabetical ordering by product',
      tableOrdersFidelityContent: 'Percentage ordering per product',
      filtersCountries: 'Select your desired marketplace by country',
      filtersSearch: 'Search by Amazon Standard Identification Number (ASIN)',
      filtersFidelity: 'Choose a percentage range for products’ compliance scores',
      filtersAdvanced: 'Use advanced filters to filter attributes by either/or:',
    },
    uploadBatchImages: {
      namingConvention: 'Naming Convention',
      namingConventions: [
        'Uploaded images require specific file naming as follows:',
        'For your ‘main’ product image, use: ‘ASIN#.MAIN.jpg',
        'ex. B076T4MC7K.MAIN.jpg',
        'For the rest of your images, i.e images 2 to 7, name the files using ‘ASIN#.PTIMAGENUMBER.jpg’ or as follows:',
        'ex. B076T4MC7K.PT01.jpg, B076T4MC7K.PT02.jpg, B076T4MC7K.PT03.jpg, etc.',
      ],
      gotIt: 'Got it!',
      imagesToAdd: 'Images to add',
      placeholder: 'You have not added any images',
      title: 'Add Compliance Images',
    },
  },
  data: {
    noMatchingProducts: 'No products match filters',
    noProducts: 'You currently have no products',
    noProductsInstruction: 'You need to add products available on Amazon using their ASIN(s).',
  },
  overview: {
    productsScore: {
      one: '1 product with a score of {{score}}',
      other: '{{count}} products with a score of {{score}}',
    },
    amountDescriptor: '# of products within a category or brand',
    contentDescriptor: 'Score based on analysis of product page content',
    attributeDescriptor: 'Attribute',
    offersDescriptor: 'Score based on analysis of product page Buy Box',
    reviewsDescriptor: 'Score based on analysis of product reviews',
    scoreDescriptor: 'Complex score based on overall analysis',
  },
  products: {
    addProducts: {
      addAsins: 'Add ASINs',
      addByBrand: 'Add products by Brand',
      addExcel: 'Add products by uploading an Excel',
      addManually: 'Add product manually',
      dropzone: {
        howTo: 'Uploading via excel allows you to add products more easily in bulk.',
        help: 'Download the Excel template by clicking below. If you need help, check our Help Center to ',
      },
      selectCountries: {
        one: 'Select your country',
        other: 'Select your countries',
      },
      headings: {
        withKeyword: 'With Keyword',
        withUrl: 'With URL',
      },
      manually: {
        howTo:
          'Add products below by inputting the ASINs and linking them to a marketplace. You can also set the product tracking frequency and set whether your products are Core, Secondary or belong to a competitor.',
      },
      numberOfPages: 'Number of pages',
      numberOfAsinsFound: {
        one: '1 ASIN was found.',
        other: '{{count}} ASINs were found.',
        zero: 'No ASINs could be found.',
      },
      options: {
        manually: 'Manually',
        excel: 'Upload an Excel',
        byBrands: 'By Brands',
      },
      productsToAdd: 'Products to add',
      setOfKeywords: 'Enter a keyword and we will add to your catalog products appearing as results.',
      successMsg: 'Your products should be available within the next 24 hours.',
      successTitle: 'Your new products are being updated!',
      title: 'How do you want to add products to your Catalog?',
      url: 'Paste a link from amazon so we can find products.',
    },
    index: {
      comparisonPeriod: 'Comparison Period',
      contentScore: 'Content Score',
      downloadFilteredProductsPriceHistoryFile: 'Filtered-Products-Price-Histories',
      downloadRawData: 'Download the raw data of your catalog on the desired marketplaces',
      invalidProducts: 'Invalid Products',
      filteredDataFile: 'Catalog-Filtered-Data',
      offersScore: 'Offers Score',
      productPriceHistory: 'Product Price History',
      productPriceHistoryFile: 'Price-History-of-{{asin}}-from-{{marketplaceName}}',
      ratingScore: 'Rating score',
      reviewsScore: 'Reviews Score',
      variations: 'var.',
    },
    detailTable: {
      asinOverallRating: {
        one: 'ASIN Overall Rating: 1%',
        other: 'ASIN Overall Rating: {{count}}%',
        zero: 'ASIN Overall Rating: 0%',
      },
      productsNumber: {
        one: 'One Product',
        other: '{{count}} Products',
        zero: 'No Product',
      },
      aPlusDescriptor: 'Product has improved content or not',
      avgDescriptor: 'Average product customer rating',
      brandDescriptor: 'Product brand name',
      bulletsDescriptor: '# of bullet points on product page',
      buyBoxDescriptor: 'Product Buy Box is active or inactive',
      buyBoxOwnerDescriptor: 'Buy Box Owner',
      buyBoxPriceDescriptor: 'Buy Box Price as seen on Amazon',
      contentDescriptor: 'Score based on analysis of product page content',
      descLengthDescriptor: 'Length of the description on product page',
      descStatusDescriptor: 'Content quality of the description on product page',
      fastTrackDescriptor: 'Product is available in Fast Track or not',
      fbaSellersDescriptor: '# of fulfilment by Amazon sellers',
      freshDescriptor: 'Fresh',
      hasStockDescriptor: 'Product is in stock or not',
      imagesDescriptor: '# of images on product page',
      isPrimeDescriptor: 'Product is Prime Only or not',
      lowestPriceDescriptor: 'Lowest price of the product as seen on Amazon',
      mfnSellersDescriptor: '# of Merchant Fulfilment Network sellers',
      offersDescriptor: 'Score based on analysis of product page Buy Box',
      pantryDescriptor: 'Pantry',
      priceDescriptor: 'Price of the product as seen on Amazon',
      rankDescriptor: 'Ranking of product within its subcategory',
      rankingDescriptor: 'Subcategory Sales Ranking',
      ratingDescriptor: 'Average score of content, offers and reviews',
      reviewsDescriptor: 'Score based on analysis of product reviews',
      snsDescriptor: 'Subscribe and Save',
      titleDescriptor: 'Product title as seen on Amazon',
      titleLengthDescriptor: 'Length of the title on product page',
      titleStatusDescriptor: 'Content quality of the title on product page',
      vendorOfferDescriptor: 'Product has Vendor Offer or not',
      vendorPriceDescriptor: 'Vendor Price as seen on Amazon',
      videosDescriptor: '# of videos on product page',
    },
    details: {
      content: {
        bulletPointsValidation: 'Valid if there is 5 bullet points',
        descLength: 'Desc. Length',
        descriptionValidation:
          'Valid if the description length is more than 1000 characters and less than 2000 characters',
        imagesValidation: 'Valid if there is more than 5 images',
        titleLength: 'Title Length',
        titleValidation: 'Valid if the title length is more than 44 characters and less than 76 characters',
        videos: {
          one: '1 Video',
          other: '{{count}} Videos',
          zero: 'No Video',
        },
      },
      offer: {
        addOnItem: 'Add-On Item',
        FBASellers: {
          one: '1 FBA Seller',
          other: '{{count}} FBA Sellers',
          zero: 'No FBA Seller',
        },
        isPremiumBeauty: 'Premium Beauty',
        isPrestige: 'Prestige',
        isProfessionalBeauty: 'Professional Beauty',
        lowest: 'Lowest',
        MFNSellers: {
          one: '1 MFN Seller',
          other: '{{count}} MFN Sellers',
          zero: 'No MFN Seller',
        },
      },
      reviews: {
        ratingDistribution: 'Rating Distribution',
        reviews: {
          one: '1 review',
          other: '{{count}} reviews',
          zero: 'No review',
        },
      },
      warningFrequency:
        'Data from this ASIN is over {{count}} days old, please adjust scraping frequency or get in touch with your account manager',
    },
  },
  reviews: {
    action: {
      seeAllReviews: 'See all the reviews',
      seeNewReviews: 'See the new reviews',
    },
    modal: {
      step1: 'How many days would you like to gather your Customer Reviews?',
      step2: 'You will shortly receive an email with the Customer Reviews attached in an Excel spreadsheet.',
      details:
        'The reviews for the {{nbItems}} ASINs you selected are currently being compiled - thanks for your patience!',
    },
    notifications: {
      since: 'Since',
      day1: 'yesterday',
      day7: 'last 7 days',
      day30: 'last 30 days',
      all: 'all reviews',
      newReviews: {
        one: '1 new review',
        other: '{{count}} new reviews',
        zero: 'No new review',
      },
      asins: {
        one: '1 ASIN',
        other: '{{count}} ASINs',
        zero: 'No ASIN',
      },
    },
    title: 'Reviews',
  },
  scoringHistory: {
    overallScores: '{{section}} Scores • Overall',
    listTitle: '{{section}} • Overview • By {{groupedBy}}',
  },
  section: {
    comp: 'Competitors',
    competitor: 'Competitors',
    deta: 'Detached',
    detached: 'Detached',
    extr: 'Extra',
    extra: 'Extra',
    main: 'Main',
    notInCatalog: 'Not in catalog',
  },
  seo: {
    downloadAll: 'Download all data',
    indexScore: 'Index Score',
    keywordRankDist: 'Keyword Rank Distribution',
    title: 'Keywords',
    suggestedKeywords: 'Suggested keywords',
  },
  store: {
    catalogueTemplateFilename: 'catalogue_template',
    invalidMarketplace: 'No such marketplace: {{marketplace}}',
  },
  trackingFrequency: {
    creditUsed: 'Credit used',
    tooltip: {
      title: "You're about to change the tracking frequency!",
      description: {
        one: 'Are you sure you want to change one item to:',
        other: 'Are you sure you want to change {{count}} items to:',
      },
    },
    scrapingsFrequency: 'Regular Tracking',
    imagesFrequency: 'Image Check Frequency',
  },
  dropdownPeriod: {
    day1: 'yesterday',
    day7: 'last 7 days',
    day30: 'last 30 days',
  },
}
