export default {
  analysis: {
    acceptanceFillInRates: 'Acceptance & Fill-In Rates',
    filterOnGraph: 'Deep-dive on the products that have {{selectedMetric}} in {{selectedPeriod}}',
    filterOnGraphNotAvailable: "You can't deep-dive on your products while comparing 2 periods",
    logisticsCoreMetrics: 'Logistics Core Metrics',
    logisticsDataTable: 'Logistics Data Table',
    logisticsTimeline: 'Logistics Timeline',
    logisticsChart: 'Acceptance Rate, Total Cost & Contribution to Growth',
    repartition: {
      one: '1 product with a rate of {{score}}',
      other: '{{count}} products with a rate of {{score}}',
    },
  },
  index: {
    accepted: 'Accepted',
    acceptedPOs: 'Accepted POs',
    acceptanceRate: 'Acceptance Rate',
    acceptanceFillIn: 'Acceptance & Fill-In',
    closed: 'Closed',
    closedPOs: 'Closed POs',
    closedConfirmedPOs: 'Closed & Opened POs',
    confirmed: 'Opened',
    confirmedSales: 'Confirmed Sales',
    confirmedUnits: 'Confirmed Units',
    fillInRate: 'Fill-In Rate',
    missedSales: 'Refused PO Value',
    missedSellIn: 'Refused PO',
    opened: 'Opened',
    openedPOs: 'Opened POs',
    orderedPOs: 'Ordered POs',
    ordered: 'Ordered',
    pos: 'POs',
    posType: 'POs type',
    sellIn: 'Sell-In',
    received: 'Received',
    receivedPOs: 'Received POs',
    totalReceived: 'Total Received',
  },
  periodNotAvailable: 'Period not available',
}
