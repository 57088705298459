import React from 'react'

import moment, {Moment} from 'moment'

import {FormatType} from './constants'
import DatePickerPanel from './DatePickerPanel'
import {deduceFirstValue} from './utils'

interface DateRangePickerPanelProps {
  prefixCls?: string
  dateRender?: (...arg: any) => any
  disabledDate?: (current: Moment, value?: Moment) => boolean
  showWeekNumber?: boolean
  onChange: (value: Moment[]) => void
  startDate?: Moment
  endDate?: Moment
  mode?: FormatType
  selectMode?: 'start' | 'end'
}

export default class DateRangePickerPanel extends React.Component<DateRangePickerPanelProps> {
  static defaultProps = {
    prefixCls: 'datePicker',
    dateRender: undefined,
    disabledDate: undefined,
    showWeekNumber: true,
    startDate: moment.utc(),
    endDate: moment.utc(),
    mode: 'day',
    selectMode: 'start',
  }

  state = {
    hoverValue: null,
  }

  handleChange = (value: Moment) => {
    const {startDate = moment.utc(), endDate = moment.utc()} = this.props
    if (this.props.selectMode === 'start') this.props.onChange([value, endDate])
    else if (this.props.selectMode === 'end') this.props.onChange([startDate, value])
  }

  handleHoverValueChange = (hoverValue: Moment | null) => {
    this.setState({hoverValue})
  }

  shouldOffsetSecondPanel = () => {
    const {mode = 'day'} = this.props
    if (!this.props.startDate || !this.props.endDate) return false
    return deduceFirstValue(this.props.startDate, mode).isSame(
      deduceFirstValue(this.props.endDate, mode),
      this.props.mode
    )
  }

  render() {
    return (
      <div className={`${this.props.prefixCls}Range`}>
        <DatePickerPanel
          prefixCls={this.props.prefixCls}
          dateRender={this.props.dateRender}
          disabledDate={this.props.disabledDate}
          showWeekNumber={this.props.showWeekNumber}
          enablePrev
          enableNext
          onChange={this.handleChange}
          value={this.props.startDate}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          mode={this.props.mode}
          selectMode={this.props.selectMode}
          onHoverChange={this.handleHoverValueChange}
          hoverValue={this.state.hoverValue}
        />
        <DatePickerPanel
          prefixCls={this.props.prefixCls}
          dateRender={this.props.dateRender}
          disabledDate={this.props.disabledDate}
          showWeekNumber={this.props.showWeekNumber}
          enablePrev
          enableNext
          onChange={this.handleChange}
          value={this.props.endDate}
          offset={this.shouldOffsetSecondPanel() ? 1 : 0}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          mode={this.props.mode}
          selectMode={this.props.selectMode}
          onHoverChange={this.handleHoverValueChange}
          hoverValue={this.state.hoverValue}
        />
      </div>
    )
  }
}
