import React from 'react'

import {Box, Col, Row} from 'jsxstyle'
import {Dropdown, Icon, InputOnChangeData} from 'semantic-ui-react'
import styled from 'styled-components'

import {fuzzysearch} from '../../../..'
import colors from '../../../../colors'
import i18n from '../../../../i18n'
import OperatorInNotIn from '../../OperatorInNotIn'
import PoppersPopup from '../../PoppersPopup'
import SeelkCheckboxPanel from '../../SeelkUIKit/SeelkDropdown/Panels/SeelkCheckboxPanel'
import DebouncedInput from '../DebouncedInput'

import './override.css'

const StyledApplyRow = styled(Row)`
  color: ${colors.white.rgba};
  background-color: ${colors.primary.rgba};
  border-radius: 0 0 3px 3px;
  font-weight: 500;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${colors.primaryDark.rgba};
  }
  margin: ${props => (props.noPadding ? '0' : '8px -8px -8px -8px')};
  position: relative;
  left: -1px;
  bottom: -1px;
  width: ${props => `calc(100% + ${props.noPadding ? '2px' : '18px'})`};
`

type ChoiceType = {
  name: string
  slug: string
}

interface SearchableMultiSelectProps {
  choices: Record<string, ChoiceType[]>
  indexedCollectionsSlug: any
  onApply: () => void
  onChange: (changes: Record<string, string[]>) => void
  onClose: () => void
  value: Record<string, string[]>
  icon?: string | null
  loading?: boolean
  operator?: 'in' | 'not_in'
  placeholder?: string
  title?: string
  updateOperator?: ((operator: 'in' | 'not_in') => void) | null
}

interface SearchableMultiSelectState {
  search: string
}

class SearchableMultiSelect extends React.Component<SearchableMultiSelectProps, SearchableMultiSelectState> {
  constructor(props: SearchableMultiSelectProps) {
    super(props)
    this.state = {
      search: '',
    }
  }

  getOptionKeys = (optionsValues: Record<string, ChoiceType[]>): string[] =>
    Object.keys(optionsValues)
      .filter(k => optionsValues[k].length > 0)
      .sort()

  getOptionValues = (k: string): ChoiceType[] => {
    if (this.state.search === '') {
      return this.props.choices[k].sort((a, b) => a.name.localeCompare(b.name))
    }
    return this.props.choices[k]
      .filter(v => fuzzysearch(this.state.search, v.name))
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  isSelected = (k: string, v: string) => (this.props.value[k] || []).includes(v)

  toggleValue = (key: string, value: string) => {
    const newVal: Record<string, string[]> = {}
    Object.keys(this.props.choices).forEach(k => {
      newVal[k] = []
    })
    Object.entries(this.props.value).forEach(([k, v]) => {
      if (v) newVal[k] = newVal[k].concat(v)
    })
    if (this.isSelected(key, value)) newVal[key] = newVal[key].filter(inValue => inValue !== value)
    else newVal[key].push(value)

    this.props.onChange(newVal)
  }

  render() {
    const {
      icon = null,
      loading = false,
      placeholder = '',
      title = '',
      operator = 'in',
      updateOperator = null,
    } = this.props
    const optionsValues = Object.keys(this.props.choices).reduce((parent, optionKey) => {
      parent[optionKey] = this.getOptionValues(optionKey) // eslint-disable-line no-param-reassign
      return parent
    }, {} as Record<string, ChoiceType[]>)

    return (
      <Box className="ui dropdown visible floating multiSelect">
        <Dropdown.Menu className="visible" style={{minWidth: 300}}>
          <Dropdown.Header icon={icon} content={title} />
          <Dropdown.Divider />
          <DebouncedInput
            onChange={(e: React.ChangeEvent<HTMLInputElement>, {value}: InputOnChangeData) =>
              this.setState({search: value})
            }
            icon="search"
            iconPosition="left"
            className="search"
            placeholder={placeholder}
            loading={loading}
            transparent={false}
            value={this.state.search}
          />
          {updateOperator ? (
            <Col style={{padding: '0 10px'}}>
              <OperatorInNotIn updateOperator={updateOperator} operator={operator} />
            </Col>
          ) : null}
          <Dropdown.Menu className="visible menuOverride scrolling">
            {this.getOptionKeys(optionsValues).map(k => (
              <PoppersPopup
                style={{padding: 0}}
                key={k}
                position="right center"
                on={['hover']}
                hoverable
                trigger={
                  <div className="ui active visible fluid left pointing dropdown item">
                    {this.props.indexedCollectionsSlug[k]} <Icon name="caret right" />
                  </div>
                }
                content={
                  <Dropdown.Menu className="subMenuOverride scrolling">
                    <SeelkCheckboxPanel
                      choices={optionsValues[k].map(v => ({label: v.name, value: v.slug}))}
                      onItemClick={(value: string) => this.toggleValue(k, value)}
                      panel={false}
                      selectedItems={this.props.value[k] || []}
                    />
                  </Dropdown.Menu>
                }
              />
            ))}
          </Dropdown.Menu>
          <StyledApplyRow
            noPadding
            props={{
              onClick: () => {
                this.props.onApply()
                this.props.onClose()
              },
            }}
          >
            <Box>{i18n.t('actions.global.apply')}</Box>
          </StyledApplyRow>
        </Dropdown.Menu>
      </Box>
    )
  }
}

export default SearchableMultiSelect
