import React from 'react'

import {css, StyleSheet} from 'aphrodite'
import PropTypes from 'prop-types'
import Rheostat from 'rheostat'

import colors from '../../../../colors'

// import linear from 'rheostat/src/algorithms/linear'
// import log10 from 'rheostat/src/algorithms/log10'
import 'rheostat/css/slider.css'
import './rheostat.css'

const styles = StyleSheet.create({
  labelsContainer: {
    display: 'flex',
  },
  labelMin: {
    paddingLeft: 6,
    flex: '1 0 auto',
    alignSelf: 'flex-start',
    color: colors.primaryDark.rgba,
    fontWeight: '500',
  },
  labelMax: {
    flex: '0 0 auto',
    paddingRight: 8,
    alignSelf: 'flex-end',
    color: colors.primaryDark.rgba,
    fontWeight: '500',
  },
})

// have to define it because pipeline fail when it's imported
const linear = {
  getPosition(value, min, max) {
    return ((value - min) / (max - min)) * 100
  },

  getValue(pos, min, max) {
    const decimal = pos / 100

    if (pos === 0) {
      return min
    }

    if (pos === 100) {
      return max
    }

    return Math.round((max - min) * decimal + min)
  },
}

const linearFloat = {
  getPosition(value, min, max) {
    return ((value - min) / (max - min)) * 100
  },

  getValue(pos, min, max) {
    const decimal = pos / 100

    if (pos === 0) {
      return min
    }

    if (pos === 100) {
      return max
    }

    return Math.round(((max - min) * decimal + min) * 100) / 100
  },
}

class StyledRheostat extends React.Component {
  static propTypes = {
    // algorithm: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    isFloat: PropTypes.bool,
    noLabel: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    values: PropTypes.arrayOf(PropTypes.number).isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    // algorithm: 'linear',
    isFloat: false,
    min: 0,
    max: 100,
    noLabel: false,
    disabled: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      from: null,
      to: null,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.values[0] !== this.state.from || nextProps.values[1] !== this.state.to)
      this.setState({
        from: nextProps.values[0],
        to: nextProps.values[1],
      })
  }

  // handleChange = ({values}) => {
  //   this.props.onChange({from: values[0], to: values[1]})
  // }

  handleValuesUpdated = ({values}) =>
    this.setState({
      from: values[0],
      to: values[1],
    })

  handleDragEnd = () => {
    const {from, to} = this.state
    this.props.onChange({from, to})
  }

  render() {
    const {/* algorithm, */ min, max, values, noLabel, isFloat, disabled} = this.props

    return (
      <div>
        <Rheostat
          disabled={disabled}
          algorithm={isFloat ? linearFloat : linear}
          min={min}
          max={max}
          onValuesUpdated={this.handleValuesUpdated}
          values={values}
          onSliderDragEnd={this.handleDragEnd}
        />
        {!noLabel && (
          <div className={css(styles.labelsContainer)}>
            <div className={css(styles.labelMin)}>{this.state.from !== null ? this.state.from : values[0]}</div>
            <div className={css(styles.labelMax)}>{this.state.to !== null ? this.state.to : values[1]}</div>
          </div>
        )}
      </div>
    )
  }
}

export default StyledRheostat
