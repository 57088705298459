import React, {Component} from 'react'

import {Col} from 'jsxstyle'
import {connect} from 'react-redux-reliever'
import {Route, Switch} from 'react-router-dom'

import PermissionControl from '../../utils/PermissionControl'
import ClientTrackerTable from './ClientTrackerTable'

class Root extends Component {
  render() {
    return (
      <PermissionControl showIfOne={['clienttracker', 'dev']}>
        <Col flex="1" height="0">
          <Switch>
            <Route path="/client-tracker" component={ClientTrackerTable} strict />
          </Switch>
        </Col>
      </PermissionControl>
    )
  }
}

export default connect({
  props: (state, ownProps) => ({
    ...ownProps,
  }),
})(Root)
