import {Reliever} from 'react-redux-reliever'
import {ofType} from 'redux-observable'
import {combineLatest, throwError} from 'rxjs'
import {catchError, map, mapTo, mergeMap, tap} from 'rxjs/operators'

import {userTenantSelector} from '../app-layout/selectors'
import {downloadBlob, unbreakableV3SatanFormattedData} from '../utils'
import {BulmaApi, MrSatanApi} from '../utils/apis'
import {getState, mapAsync} from '../utils/extensions/rx'

const SP_COLUMNS = [
  'asin',
  'brand_code',
  'client',
  'country_code',
  'campaign_category',
  'campaign_text',
  'adgroup_category',
  'adgroup_text',
  'campaign_start_date',
  'campaign_end_date',
  'campaign_daily_budget',
  'bidding_strategy',
  'adgroup_max_bid',
]

const SB_COLUMNS = [
  'creative_asin',
  'brand_code',
  'country_code',
  'client',
  'campaign_category',
  'campaign_text',
  'campaign_start_date',
  'campaign_end_date',
  'campaign_daily_budget',
  'brandstore_url',
  'landing_page_asins',
  'landing_page_url',
  'brand_name',
  'brand_entity_id',
  'brand_logo_asset_id',
  'headline',
  'media_id',
  'automated_bidding',
  'bid_multiplier',
]

const SD_COLUMNS = [
  'asin',
  'brand_code',
  'client',
  'country_code',
  'campaign_category',
  'campaign_text',
  'adgroup_category',
  'adgroup_text',
  'campaign_start_date',
  'campaign_end_date',
  'campaign_budget',
  'budget_type',
  'adgroup_max_bid',
  'bid_optimization',
  'cost_type',
]

class CampaignBuilderReliever extends Reliever {
  ACTION_PREFIX = 'CAMPAIGN-BUILDER'

  getInitialState() {
    return {}
  }

  downloadAdsTemplateEpic(action$) {
    return action$.pipe(
      ofType('CAMPAIGN-BUILDER_DOWNLOAD_TEMPLATE'),
      mergeMap(action => {
        const {frameId} = action

        const headers = {
          ads_sp_automation: SP_COLUMNS,
          ads_sb_keyword_automation: SB_COLUMNS,
          ads_sd_automation: SD_COLUMNS,
        }

        const fileName = {
          ads_sp_automation: 'campaign_builder_sp',
          ads_sb_keyword_automation: 'campaign_builder_sb_keyword',
          ads_sd_automation: 'campaign_builder_sd',
        }

        return MrSatanApi('generateExcel', {
          filename: 'MyExport.xlsx',
          sheets: [
            {
              name: 'Campaign Builder',
              content: unbreakableV3SatanFormattedData([], headers[frameId]),
              headers: headers[frameId],
            },
          ],
        }).pipe(
          tap(blob => downloadBlob(blob, `${fileName[frameId]}.xlsx`)),
          map(() => ({
            type: 'CAMPAIGN-BUILDER_DOWNLOAD_TEMPLATE_SUCCESS',
          })),
          tap(() => action._reqWrapper.end()),
          catchError(err => action._reqWrapper.fail({error: err}))
        )
      })
    )
  }

  importAdsFileEpic(action$) {
    return action$.pipe(
      ofType('CAMPAIGN-BUILDER_IMPORT'),
      mergeMap(action =>
        combineLatest([
          getState(),
          MrSatanApi('fileToJson', action.file, {
            headers_line: 0,
            options: {slugify_headers: 'False'},
          }),
        ]).pipe(
          mergeMap(([state, fileData]) => {
            const {frameId, marketplace, keywordLimit = 100} = action
            const client = userTenantSelector(state)

            if (fileData.sheet_data.length > 500)
              return throwError({
                type: 'maxAsins',
                title: 'Maximum number of ASINs (500) exceeded.',
                message: 'Please split your file accordingly and proceed again.',
              })

            const params = {
              frame_id: frameId,
              parameters: {
                marketplace,
                client,
                keyword_limit: keywordLimit,
                rows: fileData.sheet_data,
              },
            }

            return BulmaApi('userPipeline', params).pipe(
              mapTo({
                type: 'CAMPAIGN-BUILDER_IMPORT_SUCCESS',
              }),
              tap(() => action._reqWrapper.end()),
              catchError(err => {
                if (err.code === 422) {
                  return mapAsync(err.response.json()).map(result => {
                    const messages = result.map(({loc, msg}) => `Line ${loc[1] + 2} | Column "${loc[2]}" -> ${msg}`)
                    return action._reqWrapper.fail({error: messages})
                  })
                } else return action._reqWrapper.fail({error: err})
              })
            )
          }),
          catchError(err => {
            if (err.type === 'maxAsins') return action._reqWrapper.fail({error: err.message, title: err.title})
            return action._reqWrapper.fail({error: err})
          })
        )
      )
    )
  }
}

export default CampaignBuilderReliever
