import React from 'react'

import {Box} from 'jsxstyle'
import _ from 'lodash'
import memoizeOne from 'memoize-one'
import {Divider, Dropdown, Icon, InputOnChangeData} from 'semantic-ui-react'
import styled from 'styled-components'

import {fuzzyWordsSearch} from '../..'
import colors from '../../colors'
import i18n from '../../i18n'
import DebouncedInput from './inputs/DebouncedInput'
import SeelkCheckboxPanel from './SeelkUIKit/SeelkDropdown/Panels/SeelkCheckboxPanel'

const StyledDropdown = styled(Dropdown)`
  &&&&& {
    overflow: visible;
    top: 0 !important;
    font-weight: 400;
    > i {
      padding: 10px;
    }
  }
`

const StyledDropdownMenu = styled(Dropdown.Menu)`
  &&&&&&&&& {
    top: 0 !important;
    overflow: visible;
    width: max-content;
    max-height: inherit;
    height: fit-content;
    border-radius: 0 0.28571429rem 0.28571429rem 0.28571429rem;
    border-top-width: 1px !important;
  }
`

const StyledInnerMenu = styled(Dropdown.Menu)`
  &&&&& {
    overflow: visible;
  }
`

const StyledDivider = styled(Divider)`
  &&&&& {
    margin: 0 !important;
  }
`

const StyledTriggerIcon = styled(Icon)`
  &&&&& {
    font-size: 0.9em;
    color: ${colors.darkAccent.rgba};
  }
`

const UITriggerText = styled.span`
  color: ${colors.dark.rgba};
`

const searchRegex = /[,;|\s/]+/

type OptionType = {
  value: string
  label?: string
}

interface MulitSelectProps {
  onChange: (value: string[], remove?: boolean) => void
  options?: Record<string, OptionType[]>
  selectedOptions?: string[]
  searchPlaceholder?: string
  triggerIcon?: string | null
  triggerText: string
}

interface MulitSelectState {
  search: string
}

class MultiSelect extends React.Component<MulitSelectProps, MulitSelectState> {
  constructor(props: MulitSelectProps) {
    super(props)
    this.state = {
      search: '',
    }
  }

  onSearch = (search: string) => {
    this.setState({search})
  }

  isOptionSelected = (value: string, selectedOptions: string[]) => {
    return selectedOptions.includes(value)
  }

  getOptionItems = memoizeOne((options: Record<string, OptionType[]>, selectedOptions: string[], search: string[]) =>
    Object.entries(options).reduce((agg, [key, groupValue]) => {
      const filteredGroup = groupValue.filter(
        item =>
          this.isOptionSelected(item.value, selectedOptions) ||
          _.some(search, searchItem => fuzzyWordsSearch(searchItem, item?.label ?? ''))
      )
      return filteredGroup.length
        ? {
            ...agg,
            [key]: filteredGroup,
          }
        : agg
    }, {})
  )

  getSearch = () => this.state.search.split(searchRegex)

  handleSelect = (value: string) => {
    const {selectedOptions = []} = this.props
    this.props.onChange([value], this.isOptionSelected(value, selectedOptions))
  }

  getSelectedItem = () => {
    const {triggerIcon = null, selectedOptions = [], triggerText} = this.props
    return (
      <>
        {triggerIcon && <StyledTriggerIcon name={triggerIcon} />}
        <UITriggerText>
          {triggerText} ({selectedOptions.length})
        </UITriggerText>
      </>
    )
  }

  render() {
    const {options = {}, selectedOptions = [], searchPlaceholder = i18n.t('actions.search.search')} = this.props
    const optionItems = this.getOptionItems(options, selectedOptions, this.getSearch())

    return (
      <Box flex="1 0 auto" className="ui dropdown visible floating multiSelect">
        <StyledDropdown closeOnBlur={false} pointing="left" className="selection" text={this.getSelectedItem()}>
          <StyledDropdownMenu onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}>
            <DebouncedInput
              fluid
              size="small"
              onChange={(event: React.ChangeEvent<HTMLInputElement>, {value}: InputOnChangeData) => {
                this.onSearch(value)
              }}
              transparent={false}
              icon="search"
              placeholder={searchPlaceholder}
              value={this.state.search}
            />
            <StyledDivider fitted />
            <StyledInnerMenu
              className="menuOverride scrolling"
              onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
            >
              {Object.entries(optionItems).map(([key, groupsValues]) => (
                <Dropdown fluid key={key} text={key} className="item" pointing="left">
                  <Dropdown.Menu
                    className="subMenuOverride scrolling"
                    onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
                  >
                    <SeelkCheckboxPanel
                      choices={groupsValues as any}
                      onItemClick={this.handleSelect}
                      selectedItems={selectedOptions}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              ))}
            </StyledInnerMenu>
          </StyledDropdownMenu>
        </StyledDropdown>
      </Box>
    )
  }
}

export default MultiSelect
