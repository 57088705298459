import React from 'react'

import {Label} from 'semantic-ui-react'
import styled, {css} from 'styled-components'

import {capitalize, colorHash} from '../..'
import colors from '../../colors'
import {PoppersPopup} from '../../common-ui'

const StyledDiv = styled.div<{readonly actionable: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: ${props => (props.actionable ? '500' : '400')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: ${props => (props.actionable ? 'pointer' : 'default')};
  ${props =>
    props.actionable &&
    css`
      :hover {
        cursor: pointer;
        text-decoration: underline;
        color: ${colors.primary.rgba};
      }
    `};
`

const StyledLabel = styled(Label)<{color: string}>`
  &&& {
    display: block;
    background-color: ${props => props.color};
    margin-right: 20px;
  }
`

type ItemType = {
  key: string
  [key: string]: any
}

type ExtraType = {
  actionable?: ((item: ItemType) => void) | boolean
  onClick?: ({
    event,
    value,
    key,
    item,
  }: {
    event: React.MouseEvent<HTMLElement>
    value: string
    key: string
    item: ItemType
  }) => void
  capitalize?: boolean
  showLabelColor?: boolean
  indexedValue?: Record<string, string>
}

interface EllipsisPopUpTextProps {
  item: ItemType
  value: string
  extra?: ExtraType
  style?: Record<string, any>
}

const EllipsisPopUpText = ({item, value = '', extra = {}, style = {}, ...rest}: EllipsisPopUpTextProps) => {
  const actionable =
    // eslint-disalble-next-line no-nested-ternary
    extra.actionable && typeof extra.actionable === 'boolean'
      ? extra.actionable
      : extra.actionable && typeof extra.actionable === 'function'
      ? extra.actionable(item)
      : false

  const {indexedValue} = extra
  return (
    <PoppersPopup
      content={value}
      basic
      trigger={
        <StyledDiv
          style={style}
          onClick={event => {
            if (extra.onClick && actionable) extra.onClick({event, value, key: item.key, item, ...rest})
          }}
          actionable={!!actionable}
        >
          {extra.showLabelColor ? <StyledLabel color={colorHash(item?.group ?? '').rgba} circular empty /> : null}
          {indexedValue && indexedValue[value] ? indexedValue[value] : extra.capitalize ? capitalize(value) : value}
        </StyledDiv>
      }
    />
  )
}

export default EllipsisPopUpText
