import React from 'react'

import {Button, ButtonProps, Icon} from 'semantic-ui-react'
import styled, {css} from 'styled-components'

const BaseButton = styled(Button)`
  &&& {
    border-radius: ${props => props.theme.button.base.borderRadius};
    font-weight: ${props => props.theme.button.base.fontWeight};
    height: ${props => props.theme.button.base.height};
    padding: ${props => props.theme.button.base.padding};
    padding-left: ${props => props.theme.button.base.paddingLeft};
    padding-right: ${props => props.theme.button.base.paddingRight};
    display: ${props => props.theme.button.base.display};
    justify-content: ${props => props.theme.button.base.justifyContent};
    align-items: ${props => props.theme.button.base.alignItems};
    & > i {
      align-items: ${props => props.theme.button.base.icon.alignItems};
      display: ${props => props.theme.button.base.icon.display};
    }
  }
`

const ActionButton = styled(BaseButton)`
  &&&& {
    background-color: ${props =>
      props.isinverted ? props.theme.button.action.color : props.theme.button.action.backgroundColor};
    border: ${props => props.theme.button.action.border};
    color: ${props => (props.isinverted ? props.theme.button.action.backgroundColor : props.theme.button.action.color)};
    ${props =>
      props.disabled
        ? css`
            opacity: ${props.theme.button.action.opacityDisabled};
            pointer-events: ${props.theme.button.action.pointerEventsDisabled};
          `
        : css`
            &:hover {
              background-color: ${props.isinverted
                ? props.theme.button.action.hoverColor
                : props.theme.button.action.hoverBackgroundColor};
            }
          `}
  }
`

const BackButton = styled(BaseButton)`
  &&&& {
    background-color: ${props => props.theme.button.back.backgroundColor};
    border: ${props => props.theme.button.back.border};
    color: ${props => props.theme.button.back.color};
    ${props =>
      props.disabled
        ? css`
            opacity: ${props.theme.button.back.opacityDisabled};
            pointer-events: ${props.theme.button.back.pointerEventsDisabled};
          `
        : css`
            &:hover {
              background-color: ${props.theme.button.back.hoverBackgroundColor};
            }
          `}
  }
`

const CancelButton = styled(BaseButton)`
  &&&& {
    background-color: ${props => props.theme.button.cancel.backgroundColor};
    border: ${props => props.theme.button.cancel.border};
    border-color: ${props => props.theme.button.cancel.borderColor};
    color: ${props => props.theme.button.cancel.color};
    ${props =>
      props.disabled
        ? css`
            opacity: ${props.theme.button.cancel.opacityDisabled};
            pointer-events: ${props.theme.button.cancel.pointerEventsDisabled};
          `
        : css`
            &:hover {
              background-color: ${props.theme.button.cancel.hoverBackgroundColor};
            }
          `}
  }
`

const PrimaryBordered = styled(BaseButton)`
  &&&& {
    background-color: ${props => props.theme.button.primaryBordered.backgroundColor};
    border: ${props => props.theme.button.primaryBordered.border};
    color: ${props => props.theme.button.primaryBordered.color};
    ${props =>
      props.disabled
        ? css`
            opacity: ${props.theme.button.primaryBordered.opacityDisabled};
            pointer-events: ${props.theme.button.primaryBordered.pointerEventsDisabled};
          `
        : css`
            &:hover {
              background-color: ${props.theme.button.primaryBordered.hoverBackgroundColor};
              border: ${props => props.theme.button.primaryBordered.hoverBorder};
              color: ${props.theme.button.primaryBordered.hoverColor};
            }
          `}
  }
`

const ConfirmButton = styled(BaseButton)`
  &&&& {
    background-color: ${props => props.theme.button.confirm.backgroundColor};
    border: ${props => props.theme.button.confirm.border};
    color: ${props => props.theme.button.confirm.color};
    ${props =>
      props.disabled
        ? css`
            opacity: ${props.theme.button.confirm.opacityDisabled};
            pointer-events: ${props.theme.button.confirm.pointerEventsDisabled};
          `
        : css`
            &:hover {
              background-color: ${props.theme.button.confirm.hoverBackgroundColor};
            }
          `}
  }
`

const IconButton = styled(BaseButton)`
  &&&& {
    background-color: ${props => props.theme.button.icon.backgroundColor};
    border: ${props => props.theme.button.icon.border};
    border-color: ${props => props.theme.button.icon.borderColor};
    color: ${props => props.theme.button.icon.color};
    height: ${props => props.theme.button.icon.height};
    padding-left: ${props => props.theme.button.icon.padding};
    padding-right: ${props => props.theme.button.icon.padding};
    ${props =>
      props.disabled
        ? css`
            opacity: ${props.theme.button.icon.opacityDisabled};
            pointer-events: ${props.theme.button.icon.pointerEventsDisabled};
          `
        : css`
            &:hover {
              background-color: ${props.theme.button.icon.hoverBackgroundColor};
              border-color: ${props.theme.button.icon.hoverBorderColor};
              color: ${props.theme.button.icon.hoverColor};
            }
          `}
  }
`

const InlineIcon = styled(Icon)`
  &&&& {
    line-height: ${props => props.theme.button.inlineIcon.lineHeight};
    margin: ${props => props.theme.button.inlineIcon.margin};
    padding: ${props => props.theme.button.inlineIcon.padding};
    opacity: ${props => props.theme.button.inlineIcon.opacity};
  }
`

const NormalButton = styled(BaseButton)`
  &&&& {
    background-color: ${props => props.theme.button.normal.backgroundColor};
    border: ${props => props.theme.button.normal.border};
    border-color: ${props => props.theme.button.normal.borderColor};
    color: ${props => props.theme.button.normal.color};
    ${props =>
      props.disabled
        ? css`
            opacity: ${props.theme.button.normal.opacityDisabled};
            pointer-events: ${props.theme.button.normal.pointerEventsDisabled};
          `
        : css`
            &:hover {
              background-color: ${props.theme.button.normal.hoverBackgroundColor};
              border-color: ${props.theme.button.normal.hoverBorderColor};
              color: ${props.theme.button.normal.hoverColor};
            }
          `}
  }
`

const DeleteButton = styled(BaseButton)`
  &&&& {
    background-color: ${props => props.theme.button.delete.backgroundColor};
    border: ${props => props.theme.button.delete.border};
    border-color: ${props => props.theme.button.delete.borderColor};
    color: ${props => props.theme.button.delete.color};
    ${props =>
      props.disabled
        ? css`
            opacity: ${props.theme.button.delete.opacityDisabled};
            pointer-events: ${props.theme.button.delete.pointerEventsDisabled};
          `
        : css`
            &:hover {
              background-color: ${props.theme.button.delete.hoverBackgroundColor};
              border-color: ${props.theme.button.delete.hoverBorderColor};
              color: ${props.theme.button.delete.hoverColor};
            }
          `}
  }
`

const BorderlessButton = styled(BaseButton)`
  &&&& {
    background-color: ${props => props.theme.button.borderless.backgroundColor};
    border: ${props => props.theme.button.borderless.border};
    color: ${props => props.theme.button.borderless.color};
    ${props =>
      props.disabled
        ? css`
            opacity: ${props.theme.button.borderless.opacityDisabled};
            pointer-events: ${props.theme.button.borderless.pointerEventsDisabled};
          `
        : css`
            &:hover {
              color: ${props.theme.button.borderless.hoverColor};
              background-color: ${props.theme.button.borderless.hoverBackgroundColor};
            }
          `}
  }
`

const ToolPickerButton = styled(Button)`
  &&&& {
    transition: ${props => props.theme.button.toolPicker.transition};
    width: ${props => props.theme.button.toolPicker.width};
    height: ${props => props.theme.button.toolPicker.height};
    padding: ${props => props.theme.button.toolPicker.padding};
    font-size: ${props => props.theme.button.toolPicker.fontSize};
    background-color: ${props => props.theme.button.toolPicker.backgroundColor};
    color: ${props => (props.active ? props.theme.button.toolPicker.activeColor : props.theme.button.toolPicker.color)};
    :hover {
      color: ${props => props.theme.button.toolPicker.hoverColor};
    }
  }
`

const SelectButton = styled(BaseButton)`
  &&&& {
    font-size: ${props => props.theme.button.select.fontSize};
    font-weight: ${props => props.theme.button.select.fontWeight};
    background-color: ${props => props.theme.button.select.backgroundColor};
    border: ${props => props.theme.button.select.border};
    color: ${props => props.theme.button.select.color};
    height: ${props => props.theme.button.select.height};
  }
`

const SelectIcon = styled(Icon)`
  &&&& {
    margin: ${props => props.theme.button.selectIcon.margin};
    opacity: ${props => props.theme.button.selectIcon.opacity};
    color: ${props => props.theme.button.selectIcon.color};
    font-weight: ${props => props.theme.button.selectIcon.fontWeight};
  }
`

const ToolButton = styled(BaseButton)`
  &&&& {
    background-color: ${props => props.theme.button.tool.backgroundColor};
    border: ${props => props.theme.button.tool.border};
    border-radius: ${props => props.theme.button.tool.borderRadius};
    height: ${props => props.theme.button.tool.height};
    padding: ${props => props.theme.button.tool.padding};
    padding-left: ${props => props.theme.button.tool.paddingLeft};
    padding-right: ${props => props.theme.button.tool.paddingRight};
    color: ${props => props.theme.button.tool.color};
    ${props =>
      props.disabled
        ? css`
            opacity: ${props.theme.button.tool.opacityDisabled};
            pointer-events: ${props.theme.button.tool.pointerEventsDisabled};
          `
        : css`
            &:hover {
              color: ${props.theme.button.tool.hoverColor};
              background-color: ${props.theme.button.tool.hoverBackgroundColor};
            }
          `}
  }
`

const BUTTON_TYPES = {
  action: ActionButton,
  back: BackButton,
  borderless: BorderlessButton,
  primaryBordered: PrimaryBordered,
  cancel: CancelButton,
  confirm: ConfirmButton,
  delete: DeleteButton,
  icon: IconButton,
  normal: NormalButton,
  toolPicker: ToolPickerButton,
  select: SelectButton,
  tool: ToolButton,
} as const

type ButtonTypes = keyof typeof BUTTON_TYPES

interface CommonProps {
  content?: string | React.ReactNode | null
  disabled?: boolean
  iconPosition?: 'left' | 'right'
  inverted?: boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => void
  loading?: boolean
}

type RequiredIfProps =
  | {type: Extract<ButtonTypes, 'tool'>; displaySelect: boolean; icon?: string | null}
  | {type: Extract<ButtonTypes, 'tool' | 'icon'>; displaySelect?: boolean; icon: string}
  | {type: ButtonTypes; displaySelect?: never; icon?: string | null}

type SeelkButtonProps = CommonProps & RequiredIfProps

const SeelkButton = ({
  content = null,
  disabled = false,
  displaySelect = false,
  icon = null,
  iconPosition = 'right',
  inverted = false,
  onClick = () => {},
  type = 'normal',
  ...otherProps
}: SeelkButtonProps) => {
  const ButtonToDisplay = BUTTON_TYPES[type]
  if (type === 'confirm') {
    if (icon) {
      return iconPosition === 'right' ? (
        <ButtonToDisplay {...otherProps} disabled={disabled} onClick={onClick}>
          <span style={{marginRight: content && icon ? 6 : 0}}>{content}</span>
          <InlineIcon name={icon} />
        </ButtonToDisplay>
      ) : (
        <ButtonToDisplay {...otherProps} disabled={disabled} onClick={onClick}>
          <InlineIcon name={icon} />
          <span style={{marginLeft: content && icon ? 6 : 0}}>{content}</span>
        </ButtonToDisplay>
      )
    } else {
      return <ButtonToDisplay {...otherProps} content={content} disabled={disabled} onClick={onClick} />
    }
  } else if (type === 'icon' && icon) {
    return <ButtonToDisplay {...otherProps} icon={icon} onClick={onClick} />
  } else if (type === 'toolPicker') {
    return <ButtonToDisplay {...otherProps} onClick={onClick} icon={icon} />
  } else if (type === 'select') {
    if (icon)
      return (
        <ButtonToDisplay {...otherProps} disabled={disabled} onClick={onClick}>
          <SelectIcon name={icon} />
          <span style={{marginLeft: icon && content ? 6 : 0, marginRight: 6}}>{content}</span>
          <SelectIcon name="triangle down" />
        </ButtonToDisplay>
      )
    else
      return (
        <ButtonToDisplay {...otherProps} disabled={disabled} onClick={onClick}>
          <span style={{marginLeft: 6, marginRight: 6}}>{content}</span>
          <SelectIcon name="triangle down" />
        </ButtonToDisplay>
      )
  } else if (type === 'tool') {
    if (displaySelect)
      return (
        <ButtonToDisplay {...otherProps} disabled={disabled} onClick={onClick}>
          <InlineIcon name={icon} style={{marginLeft: 0, marginRight: 6}} />
          <SelectIcon name="triangle down" />
        </ButtonToDisplay>
      )
    else
      return (
        <ButtonToDisplay {...otherProps} disabled={disabled} onClick={onClick}>
          <InlineIcon name={icon} />
        </ButtonToDisplay>
      )
  } else if (icon) {
    return iconPosition === 'right' ? (
      <ButtonToDisplay {...otherProps} disabled={disabled} isinverted={inverted ? 1 : 0} onClick={onClick}>
        <span style={{marginRight: icon && content ? 6 : 0}}>{content}</span>
        <InlineIcon name={icon} />
      </ButtonToDisplay>
    ) : (
      <ButtonToDisplay {...otherProps} disabled={disabled} isinverted={inverted ? 1 : 0} onClick={onClick}>
        <InlineIcon name={icon} />
        <span style={{marginLeft: icon && content ? 6 : 0}}>{content}</span>
      </ButtonToDisplay>
    )
  }

  return (
    <ButtonToDisplay
      {...otherProps}
      disabled={disabled}
      isinverted={inverted ? 1 : 0}
      onClick={onClick}
      content={content}
    />
  )
}

export default SeelkButton
