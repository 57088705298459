import React from 'react'

import {Box} from 'jsxstyle'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../../colors'

const StyledBox = styled(Box)`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  background-color: ${props => props.color};

  ${props => (props.absolutePosition ? 'position: absolute;' : '')}
  ${props => props.absolutePosition && props.position.top && `top: ${props.position.top};`}
  ${props => props.absolutePosition && props.position.bottom && `bottom: ${props.position.bottom};`}
  ${props => props.absolutePosition && props.position.left && `left: ${props.position.left};`}
  ${props => props.absolutePosition && props.position.right && `right: ${props.position.right};`}

  ${props => !props.absolutePosition && props.position.top && `margin-top: ${props.position.top};`}
  ${props => !props.absolutePosition && props.position.bottom && `margin-bottom: ${props.position.bottom};`}
  ${props => !props.absolutePosition && props.position.left && `margin-left: ${props.position.left};`}
  ${props => !props.absolutePosition && props.position.right && `margin-right: ${props.position.right};`}
`

export const BADGE_TYPE = {
  saas: {
    icon: 'bolt',
    color: colors.warning.rgba,
  },
  prospect: {
    icon: 'dollar',
    color: colors.positive.rgba,
  },
  agency: {
    icon: 'briefcase',
    color: colors.accent.rgba,
  },
  other: {
    icon: 'ellipsis horizontal',
    color: colors.secondaryDark.rgba,
  },
} as const

interface SeelkBadgeProps {
  type: keyof typeof BADGE_TYPE
  absolutePosition?: boolean
  position: {
    top: string
    right: string
    bottom: string
    left: string
  }
}

const SeelkBadge = ({type, absolutePosition = false, position}: SeelkBadgeProps) => {
  return (
    <StyledBox color={BADGE_TYPE[type].color} absolutePosition={absolutePosition} position={position}>
      <Icon
        name={BADGE_TYPE[type].icon}
        style={{
          color: colors.white.rgba,
          margin: 'auto',
          marginTop: '0.3em',
          padding: '0',
        }}
      />
    </StyledBox>
  )
}

export default SeelkBadge
