import {Subject} from 'rxjs'
import {startWith} from 'rxjs/operators'

export default class Variable {
  constructor(_value) {
    this._val = _value
    this._subject = new Subject()
  }

  set value(v) {
    this._val = v
    this._subject.next(v)
  }

  get value() {
    return this._val
  }

  setValue(v) {
    this.value = v
    return this
  }

  asObservable() {
    return this._subject.pipe(startWith(this._val))
  }
}
