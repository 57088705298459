import React from 'react'

import PropTypes from 'prop-types'
import {connect} from 'react-redux-reliever'

import {/* globalProgressSelector, */ globalRequestingSelector} from '../../selectors'

import './loader.css'

class GlobalLoader extends React.Component {
  static propTypes = {
    progress: PropTypes.number,
    disposition: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
    requesting: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    progress: null,
  }

  render() {
    const style = {}
    if (this.props.disposition === 'vertical') style.height = `${this.props.progress}%`
    else style.width = `${this.props.progress}%`
    return (
      <div className={`global-loader ${this.props.disposition}`}>
        {this.props.requesting && (
          <div className={this.props.progress ? 'determinate' : 'indeterminate'} style={style} />
        )}
      </div>
    )
  }
}

export default connect({
  props: (state, ownProps) => ({
    requesting: globalRequestingSelector(state),
    // progress: globalProgressSelector(state),
    ...ownProps,
  }),
})(GlobalLoader)
