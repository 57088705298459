import React from 'react'

import {Box, Col, Row} from 'jsxstyle'
import {Checkbox} from 'semantic-ui-react'

import {capitalize} from '../../../../..'
import i18n from '../../../../../i18n'
import InNotIn from '../common/InNotIn'
import SelectAll from '../common/SelectAll'
import VerticalList from './VerticalList'

const checkbox = (text: string) => (formatter: any) => (
  <Box key={text} marginLeft="8px" borderRadius="4px" padding="1px 3px 1px 3px" fontSize="14px">
    {formatter ? formatter(text) : capitalize(text)}
  </Box>
)

interface CheckboxVerticalListProps {
  choices: string[]
  data: string[]
  onChange: (item: string) => void
  onRemove: () => void
  title: string
  disabled?: boolean
  operator?: 'in' | 'not_in'
  updateOperator?: ((operator: 'in' | 'not_in') => void) | null
  bulkChange?: (choices: string[]) => void
  onApply: () => void
  onClose: () => void
}

const CheckboxVerticalList = ({
  choices,
  bulkChange,
  disabled = false,
  updateOperator = null,
  operator = 'in',
  ...props
}: CheckboxVerticalListProps) => {
  return (
    <VerticalList<string>
      choices={choices}
      disabled={disabled}
      updateOperator={updateOperator}
      operator={operator}
      {...props}
      selectAll={bulkChange ? <SelectAll choices={choices} data={props.data} bulkChange={bulkChange} /> : null}
      renderItem={({data, formatter, item, index, onChange}) => {
        if (item == null) {
          return (
            <Row alignItems="center">
              {data.length > 0 && updateOperator ? (
                <InNotIn operator={operator} style={{margin: 'auto 0 auto 8px'}} />
              ) : null}
              {data.length > 0 && data.map(value => checkbox(value)(formatter))}
              {data.length === 0 && <Box marginLeft="8px">{i18n.t('filters.all')}</Box>}
            </Row>
          )
        }
        return (
          <Col key={item} style={{minWidth: '120px'}}>
            <Row alignItems="center" marginTop={index || updateOperator ? 4 : 0}>
              <Checkbox
                disabled={disabled}
                checked={data && data.indexOf(item) !== -1}
                onChange={event => onChange(item)}
              />
              {checkbox(item)(formatter)}
            </Row>
          </Col>
        )
      }}
    />
  )
}

export default CheckboxVerticalList
