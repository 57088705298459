import {jsonFetch} from 'easyfetch'
import Cookies from 'universal-cookie'

import config from '../../../../config'
import KaioApiClient from '../KaioApiClient/KaioApiClient'

const TRUNKS = `https://${config.trunksDomain}`
const TRUNKS_API = `https://${config.trunksDomain}/api/v1`

export default class TrunksApiClient {
  constructor() {
    if (!TrunksApiClient.instance) {
      TrunksApiClient.instance = this
    }
    return TrunksApiClient.instance
  }

  async request(rType, url, useTenant = true) {
    const token = await new KaioApiClient().getToken()
    const user = await new KaioApiClient().getUserFromToken()
    let tenant = user.client
    if (user.account.toLowerCase() === 'seelk') tenant = new Cookies().get('account')
    const req = rType(`${TRUNKS_API}/${url}`)
    const headers = {Authorization: `Bearer ${token}`, 'X-Seelk-Tenant': useTenant ? tenant : 'public'}
    req.setHeaders(headers)
    return req
  }

  async checkLive() {
    try {
      await jsonFetch(`${TRUNKS}/health/`).get()
      return true
    } catch (error) {
      if (error.code === 502) return false
      return true
    }
  }

  async fetchNotifications() {
    return (await this.request(jsonFetch, 'notification/')).get()
  }

  async fetchArchivedNotifications() {
    return (await this.request(jsonFetch, 'notification/list_archived/')).get()
  }

  async setBulkNotificationsSeen(ids) {
    return (await this.request(jsonFetch, `notification/bulk_set_seen/`)).patch({ids})
  }

  async setNotificationSeenById(id, seen) {
    return (await this.request(jsonFetch, `notification/${id}/set_is_seen/`)).patch({is_seen: seen})
  }

  async setNotificationSeen(id, seen = true) {
    return Array.isArray(id) ? this.setBulkNotificationsSeen(id) : this.setNotificationSeenById(id, seen)
  }

  async setNotificationClickedById(id, archived, clicked = true) {
    return (await this.request(jsonFetch, `notification/${id}/set_is_clicked/`)).patch({
      is_clicked: clicked,
      is_archived: archived,
    })
  }

  async setBulkNotificationsClicked(ids) {
    return (await this.request(jsonFetch, `notification/bulk_set_clicked/`)).patch({ids})
  }

  async setNotificationClicked(id, archived = false) {
    return Array.isArray(id) ? this.setBulkNotificationsClicked(id) : this.setNotificationClickedById(id, archived)
  }

  async archiveNotificationById(id, archived) {
    return (await this.request(jsonFetch, `notification/${id}/set_is_archived/`)).patch({is_archived: archived})
  }

  async archiveBulkNotifications(ids) {
    return (await this.request(jsonFetch, `notification/bulk_set_archived/`)).patch({ids})
  }

  async archiveNotification(id, archived = true) {
    return Array.isArray(id) ? this.archiveBulkNotifications(id) : this.archiveNotificationById(id, archived)
  }

  async recomputeDynamicTags() {
    return (await this.request(jsonFetch, `generate_dynamic_tags_from_scenarios/`)).get()
  }

  async saveExport({export: _export}) {
    return (await this.request(jsonFetch, `report/`)).post(_export)
  }

  async getExports() {
    return (await this.request(jsonFetch, `report/`)).get()
  }

  async updateExport({id, export: _export}) {
    return (await this.request(jsonFetch, `report/${id}/`)).patch(_export)
  }

  async deleteExport({id}) {
    return (await this.request(jsonFetch, `report/${id}/`)).delete()
  }

  async getAlerts() {
    return (await this.request(jsonFetch, `alert/`)).get()
  }

  async createAlert(alert) {
    return (await this.request(jsonFetch, `alert/`)).post(alert)
  }

  async deleteAlert(id) {
    return (await this.request(jsonFetch, `alert/${id}/`)).delete()
  }
}
