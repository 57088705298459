import React, {Component} from 'react'

import {Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import {connect} from 'react-redux-reliever'
import {Dropdown} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../utils/colors'
import {CurrencySelector, DropdownSelector, SeelkButton} from '../../utils/common-ui'
import i18n from '../../utils/i18n'
import LoaderWrapper from '../../utils/requesting/containers/LoaderWrapper'
import {requestWrapperBuilder, withRequests} from '../../utils/requesting/RequestWrapper'
import {accountTypeSelector, currencySelector, dateFormatSelector} from '../selectors'

const StyledHeader = styled(Row)`
  &&& {
    border-bottom: 1px solid ${colors.border.rgba};
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    font-size: 16px;
    font-weight: 500;
    color: ${colors.dark.rgba};
  }
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px 30px;
`

const StyledContent = styled(Col)`
  &&& {
    margin-bottom: 20px;
    width: 200px;
  }
`

const StyledTitle = styled.span`
  margin-bottom: 5px;
  font-size: 11px;
  text-transform: uppercase;
  color: ${colors.darkLight.rgba};
`

const dateFormats = {
  'YYYY/MM/DD': 'US',
  'DD/MM/YYYY': 'EU',
}

class AccountSettings extends Component {
  static propTypes = {
    accountType: PropTypes.string,
    currency: PropTypes.string,
    dateFormat: PropTypes.string,
    fetchSettings: PropTypes.object.isRequired,
    updateSettings: PropTypes.object.isRequired,
  }

  static defaultProps = {
    accountType: 'Vendor',
    currency: 'EUR',
    dateFormat: 'DD/MM/YYYY',
  }

  constructor(props) {
    super(props)
    this.state = {
      accountType: null,
      currency: null,
      dateFormat: null,
    }
  }

  componentDidMount() {
    if (this.props.fetchSettings.canFetch()) this.props.fetchSettings.fetch()
  }

  updateSettings = () => {
    const {accountType, currency, dateFormat} = this.state
    const settings = {...(accountType ? {accountType} : {}), ...(dateFormat ? {dateFormat} : {})}
    if (
      currency !== this.props.currency ||
      dateFormat !== this.props.dateFormat ||
      accountType !== this.props.accountType
    )
      this.props.updateSettings.fetch({currency, settings})
  }

  setCurrency = currency => this.setState({currency})

  setDateFormat = dateFormat => this.setState({dateFormat})

  setAccountType = accountType => this.setState({accountType})

  render() {
    const currency = this.state?.currency ?? this.props.currency
    const dateFormat = this.state?.dateFormat ?? this.props.dateFormat
    const accountType = this.state?.accountType ?? this.props.accountType

    const disabled =
      this.props.accountType === accountType && this.props.currency === currency && this.props.dateFormat === dateFormat
    return (
      <Col>
        <StyledHeader>
          <span>{i18n.t('misc.subModules.account')}</span>
        </StyledHeader>
        <LoaderWrapper requesting="account-settings.account.fetch" blur container>
          <StyledContainer>
            <StyledContent>
              <StyledTitle>{i18n.t('settings.menus.currency')}</StyledTitle>
              <CurrencySelector currency={currency} updateCurrency={this.setCurrency} />
            </StyledContent>
            <StyledContent>
              <StyledTitle>{i18n.t('settings.menus.dateFormat')}</StyledTitle>
              <Dropdown
                placeholder={i18n.t('settings.placeholders.dateFormat')}
                selection
                onChange={(e, {value}) => this.setDateFormat(value)}
                value={dateFormat}
                options={Object.keys(dateFormats).map(d => ({
                  key: dateFormats[d],
                  value: d,
                  text: d,
                  flag: dateFormats[d].toLowerCase(),
                }))}
              />
            </StyledContent>
            <StyledContent>
              <StyledTitle>{i18n.t('settings.account.type')}</StyledTitle>
              <DropdownSelector
                options={['Vendor', 'Seller'].map(type => ({
                  key: type,
                  text: type,
                  value: type,
                }))}
                value={accountType}
                onChange={this.setAccountType}
                icon="amazon"
              />
            </StyledContent>
            <Row>
              <LoaderWrapper
                requesting="account-settings.account.update"
                render={requesting => (
                  <SeelkButton
                    disabled={disabled}
                    type="confirm"
                    content={i18n.t('actions.global.apply')}
                    onClick={this.updateSettings}
                    loading={requesting}
                  />
                )}
              />
            </Row>
          </StyledContainer>
        </LoaderWrapper>
      </Col>
    )
  }
}

export default withRequests({
  fetchSettings: requestWrapperBuilder({
    fetchAction: 'ACCOUNT-SETTINGS_FETCH_SETTINGS',
    resourcePath: 'account-settings.account',
    requestingPath: 'account-settings.account.fetch',
  }),
  updateSettings: requestWrapperBuilder({
    fetchAction: 'ACCOUNT-SETTINGS_UPDATE_SETTINGS',
    resourcePath: 'account-settings.account',
    requestingPath: 'account-settings.account.update',
    successMsg: 'Account settings are updated !',
  }),
})(
  connect({
    props: (state, ownProps) => ({
      accountType: accountTypeSelector(state),
      currency: currencySelector(state),
      dateFormat: dateFormatSelector(state),
      ...ownProps,
    }),
  })(AccountSettings)
)
