import React from 'react'

import PropTypes from 'prop-types'
import {Checkbox} from 'semantic-ui-react'

type ExtraType = {
  toggle: ({item, e}: {item: any; e: React.MouseEvent<HTMLInputElement>}) => void
}

interface CheckboxCellProps {
  item: any
  extra: ExtraType
  value?: boolean
}

const CheckboxCell = ({item, extra, value = false}: CheckboxCellProps) => (
  <Checkbox onClick={e => extra.toggle({item, e})} checked={value} />
)

CheckboxCell.propTypes = {
  item: PropTypes.object.isRequired,
  value: PropTypes.bool,
  extra: PropTypes.object,
}

CheckboxCell.defaultProps = {
  value: false,
  extra: {},
}

export default CheckboxCell
