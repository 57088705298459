import React from 'react'

import {css, StyleSheet} from 'aphrodite'
import PropTypes from 'prop-types'
import {Dropdown} from 'semantic-ui-react'

import {capitalize} from '../../..'
import colors from '../../../colors'
import i18n from '../../../i18n'

const styles = StyleSheet.create({
  dropDownUnactive: {
    backgroundColor: colors.white.rgba,
    boxShadow: `inset 0px 0px 0px 3px ${colors.white.rgba}`,
    ':hover': {
      backgroundColor: colors.white.alpha(0.95).rgba,
    },
  },
  dropDownActive: {
    backgroundColor: colors.white.rgba,
    boxShadow: `inset 0px 0px 0px 3px ${colors.white.rgba}`,
  },
})

class DefinedSearch extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    choices: PropTypes.object.isRequired,
    value: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    placeholder: i18n.t('filters.byCustomAttribute'),
    value: {},
    disabled: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      searchOpen: false,
    }
  }

  getSearchOptions = () => {
    const list = []
    Object.keys(this.props.choices)
      .sort()
      .forEach(k => {
        this.props.choices[k].forEach(v => {
          list.push({key: `${k}:${v}`, text: `${capitalize(k)}: ${v}`, value: `${k}:${v}`})
        })
      })
    return list
  }

  getSearchValue = () => {
    const values = []
    Object.keys(this.props.value).forEach(k => {
      if (this.props.choices[k] && this.props.value[k] && this.props.value[k].forEach) {
        this.props.value[k].forEach(v => {
          values.push(`${k}:${v}`)
        })
      }
    })
    return values
  }

  setClassname = () => {
    if (this.state.searchOpen) {
      return styles.dropDownActive
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const k of Object.keys(this.props.value)) {
      if (this.props.value[k] && this.props.value[k].length > 0) {
        return styles.dropDownActive
      }
    }
    return styles.dropDownUnactive
  }

  handleUpdate = (e, {value}) => {
    const newVal = {}
    Object.keys(this.props.choices).forEach(k => {
      newVal[k] = []
    })
    value.forEach(v => {
      const [key, val] = v.split(':')
      newVal[key].push(val)
    })
    this.props.onChange(newVal)
  }

  render() {
    const {placeholder, choices, value, onChange, disabled, ...props} = this.props
    return (
      <Dropdown
        selection
        onChange={this.handleUpdate}
        value={this.getSearchValue()}
        disabled={disabled}
        multiple
        search
        fluid
        placeholder={placeholder}
        open={this.state.searchOpen}
        onClick={() => this.setState({searchOpen: !this.state.searchOpen})}
        onClose={() => this.setState({searchOpen: false})}
        options={
          this.state.searchOpen
            ? this.getSearchOptions()
            : this.getSearchValue().map(v => ({
                key: v,
                value: v,
                text: `${capitalize(v.split(':')[0])}: ${v.split(':')[1]}`,
              }))
        }
        className={css(this.setClassname())}
        {...props}
      />
    )
  }
}

export default DefinedSearch
