import React from 'react'

import {Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import {connect} from 'react-redux-reliever'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../utils/colors'
import {DropdownSelector, SeelkButton, SeelkDropzone, SeelkInput, SeelkModal} from '../../utils/common-ui'
import {AVAILABLE_AMZ_COUNTRIES} from '../../utils/constants'
import i18n from '../../utils/i18n'
import LoaderWrapper from '../../utils/requesting/containers/LoaderWrapper'
import {requestWrapperBuilder, withRequests} from '../../utils/requesting/RequestWrapper'

const StyledHeader = styled(Row)`
  &&& {
    border-bottom: 1px solid ${colors.border.rgba};
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    font-size: 16px;
    font-weight: 500;
    color: ${colors.dark.rgba};
  }
`

const StyledContent = styled(Col)`
  &&& {
    justify-content: center;
    align-items: center;
    color: ${colors.dark.rgba};
    height: calc(100vh - 150px);
    & > i {
      font-size: 4em;
      color: ${colors.darkLight.rgba};
    }
    & > h3 {
      width: 390px;
      text-align: center;
    }
  }
`

const StyledModalContent = styled.div`
  flex: 1 0 auto;
  margin: 10px 0;
`

const StyledTitle = styled.span`
  margin-bottom: 5px;
  font-size: 11px;
  text-transform: uppercase;
  color: ${colors.darkLight.rgba};
`

const StyledRow = styled(Row)`
  &&& {
    margin: 15px auto;
  }
`
const StyledCol = styled(Col)`
  &&& {
    min-width: 150px;
    margin-right: 8px;
  }
`

const CAMPAIGN_BUILDER = {
  ads_sp_automation: 'sp',
  ads_sb_keyword_automation: 'sbKeyword',
  ads_sd_automation: 'sd',
}

class CampaignBuilder extends React.Component {
  static propTypes = {
    downloadTemplate: PropTypes.object.isRequired,
    importFile: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      file: null,
      open: false,
      frameId: 'ads_sp_automation',
      marketplace: null,
      keywordLimit: 50,
    }
  }

  onClose = () =>
    this.setState({
      file: null,
      open: false,
      marketplace: null,
      keywordLimit: 50,
    })

  onDrop = (acceptedFiles, rejectedFiles) => {
    const processFile = file => {
      const reader = new FileReader()
      reader.onload = () => {
        this.setState({
          file,
        })
      }
      reader.onabort = () => console.log('file reading was aborted') // eslint-disable-line no-console
      reader.onerror = () => console.log('file reading has failed') // eslint-disable-line no-console
      reader.readAsBinaryString(file)
    }

    acceptedFiles.forEach(file => {
      processFile(file)
    })

    rejectedFiles.forEach(file => {
      if (file.type === '' && new RegExp(/(.csv)|(.xls)/).test(file.name)) processFile(file)
      else window.alert(i18n.t('placeholders.errors.wrongFile')) // eslint-disable-line no-alert
    })
  }

  setFrameId = frameId => this.setState({frameId})

  setMarketplace = marketplace => this.setState({marketplace})

  setKeywordLimit = (e, {value}) => {
    const limit = parseInt(value, 10)
    if (global.isNaN(limit)) this.setState({keywordLimit: ''})
    else if (limit > 70) this.setState({keywordLimit: 70})
    else if (limit < 0) this.setState({keywordLimit: 0})
    else this.setState({keywordLimit: limit})
  }

  renderModal = () => {
    const {downloadTemplate, importFile} = this.props
    const {file, frameId, keywordLimit, marketplace} = this.state

    const isDisabled = !file || !frameId || !marketplace
    return (
      <>
        <SeelkModal
          trigger={
            <SeelkButton
              content={i18n.t('actions.upload.import')}
              icon="fas fa-file-upload"
              iconPosition="left"
              type="confirm"
            />
          }
          onOpen={() => this.setState({open: true})}
          open={this.state.open}
          onClose={this.onClose}
          header={{
            icon: 'fas fa-file-excel',
            content: i18n.t('misc.modules.campaignBuilder'),
          }}
          leftActions={[<SeelkButton content={i18n.t('actions.global.cancel')} type="cancel" onClick={this.onClose} />]}
          rightActions={[
            // eslint-disable-next-line react/jsx-indent
            <LoaderWrapper
              requesting={['campaign-builder.file.upload', 'campaign-builder.template.download']}
              render={requesting => (
                <SeelkButton
                  type="normal"
                  content={i18n.t('actions.download.template')}
                  icon="fas fa-download"
                  iconPosition="left"
                  loading={requesting}
                  disabled={requesting}
                  onClick={() => downloadTemplate.fetch({frameId})}
                />
              )}
            />,
            // eslint-disable-next-line react/jsx-indent
            <LoaderWrapper
              requesting={['campaign-builder.file.upload', 'campaign-builder.template.download']}
              render={requesting => (
                <SeelkButton
                  type="confirm"
                  content={i18n.t('actions.upload.import')}
                  loading={requesting}
                  disabled={requesting || isDisabled}
                  onClick={() => importFile.fetch({file, marketplace, frameId, keywordLimit}, {}, this.onClose)}
                />
              )}
            />,
          ]}
        >
          <StyledRow>
            <StyledCol>
              <StyledTitle>{i18n.t('visualization.columns.type')}</StyledTitle>
              <DropdownSelector
                options={Object.entries(CAMPAIGN_BUILDER).map(([key, value]) => ({
                  key,
                  text: i18n.t(`keywords.campaignBuilder.${value}`),
                  value: key,
                }))}
                value={this.state.frameId}
                onChange={this.setFrameId}
                icon="fas fa-badge-dollar"
              />
            </StyledCol>
            <StyledCol>
              <StyledTitle>{i18n.t('misc.cross.marketplaces', {count: 1})}</StyledTitle>
              <DropdownSelector
                options={AVAILABLE_AMZ_COUNTRIES.map(country => ({
                  key: `amazon_${country}`,
                  text: i18n.t(`countries.${country}.name`),
                  value: `amazon_${country}`,
                  flag: country.toLowerCase(),
                }))}
                value={marketplace}
                onChange={this.setMarketplace}
                icon="fas fa-globe"
                placeholder={i18n.t('placeholders.empty.noMarketplace')}
              />
            </StyledCol>
            <StyledCol>
              <StyledTitle>{i18n.t('keywords.campaignBuilder.keywordLimit')}</StyledTitle>
              <SeelkInput
                fluid
                type="number"
                value={`${keywordLimit}`}
                onChange={this.setKeywordLimit}
                placeholder={i18n.t('placeholders.global.limitedTo', {count: 70})}
                icon=""
              />
            </StyledCol>
          </StyledRow>
          <StyledModalContent>
            <SeelkDropzone
              height="250px"
              type="default"
              onDrop={this.onDrop}
              file={this.state.file}
              accept="text/csv, application/vnd.ms-excel, application/vnd.oasis.opendocument.spreadsheet, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/x-csv, text/plain"
            />
          </StyledModalContent>
        </SeelkModal>
      </>
    )
  }

  render() {
    const {downloadTemplate} = this.props
    return (
      <Col>
        <StyledHeader>
          <span>{i18n.t('misc.modules.campaignBuilder')}</span>
        </StyledHeader>
        <StyledContent>
          <Icon name="fas fa-file-upload" />
          <h3>{i18n.t('keywords.campaignBuilder.header')}</h3>
          <Row>
            <DropdownSelector
              options={Object.entries(CAMPAIGN_BUILDER).map(([key, value]) => ({
                key,
                text: i18n.t(`keywords.campaignBuilder.${value}`),
                value: key,
              }))}
              value={this.state.frameId}
              onChange={this.setFrameId}
              icon="fas fa-badge-dollar"
              style={{height: '36px', marginRight: '10px'}}
            />
            <SeelkButton
              content={i18n.t('actions.download.template')}
              icon="fas fa-download"
              iconPosition="left"
              onClick={() => downloadTemplate.fetch({frameId: this.state.frameId})}
              style={{marginRight: '10px'}}
              type="normal"
            />
            {this.renderModal()}
          </Row>
        </StyledContent>
      </Col>
    )
  }
}

export default withRequests({
  downloadTemplate: requestWrapperBuilder({
    fetchAction: 'CAMPAIGN-BUILDER_DOWNLOAD_TEMPLATE',
    requestingPath: 'campaign-builder.template.download',
    successMsg: 'The template has been downloaded !',
  }),
  importFile: requestWrapperBuilder({
    fetchAction: 'CAMPAIGN-BUILDER_IMPORT',
    requestingPath: 'campaign-builder.file.upload',
    successMsg: 'Your file has been uploaded, you will receive the result by email very soon !',
  }),
})(
  connect({
    props: (state, ownProps) => ({
      ...ownProps,
    }),
  })(CampaignBuilder)
)
