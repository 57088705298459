/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import {Box, Col, Row} from 'jsxstyle'
import _ from 'lodash'
import {Icon, Menu} from 'semantic-ui-react'

import colors from '../../../../colors'
import i18n from '../../../../i18n'
import theme from '../../../../theme'
import Tracking from '../../../../Tracking'
import PopOver from '../../PopOver'
import SeelkButton from '../../SeelkUIKit/SeelkButton'
import FiltersSelectByCategory from './FiltersSelectByCategory'
import * as filterItems from './items'

type MetricType = {
  id: string
} | null

interface FilterBarProps {
  alwaysForeground?: boolean
  availableCategories?: string[] | null
  availableMetrics?: any[] | null // TO DO
  byCategory?: boolean
  children?: React.ReactNode | null
  disabled?: boolean
  elementToScroll?: any
  extraActions?: boolean
  isApplied?: boolean // USELESS ???
  location: string
  noBackground?: boolean
  noBorder?: boolean
  onApply?: () => void
  onReset?: (() => void) | null
  padding?: string | null
  [filter: string]: any
}

interface FilterBarState {
  menuOpen: boolean
  opened: {[filter: string]: boolean}
  items: string[]
  availableItems: string[]
  selectedMetrics: {[key: string]: any}
}

class FiltersBar extends React.Component<FilterBarProps, FilterBarState> {
  static items: typeof filterItems

  titleProvider = i18n.createProvider('filters')

  triggerRef: React.RefObject<HTMLDivElement>

  constructor(props: FilterBarProps) {
    super(props)
    const {
      alwaysForeground = true,
      availableCategories = null,
      availableMetrics = null,
      byCategory = false,
      children = null,
      disabled = false,
      elementToScroll = null,
      extraActions = true,
      isApplied = true,
      location,
      noBackground = false,
      noBorder = false,
      onApply = null,
      onReset = null,
      padding = null,
      ...items
    } = props

    this.state = {
      menuOpen: false,
      opened: {},
      items: Object.keys(items).filter(k => items[k].isValid || items[k].isOpened),
      availableItems: [],
      selectedMetrics: {},
    }

    this.triggerRef = React.createRef()
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.initComponent(this.props)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: FilterBarProps) {
    this.initComponent(nextProps)
  }

  initComponent(newProps: FilterBarProps) {
    const {
      alwaysForeground = true,
      availableCategories = null,
      availableMetrics = null,
      byCategory = false,
      children = null,
      disabled = false,
      elementToScroll = null,
      extraActions = true,
      isApplied = true,
      location,
      noBackground = false,
      noBorder = false,
      onApply = null,
      onReset = null,
      padding = null,
      ...props
    } = newProps

    const availableItems = Object.keys(props).filter(x => {
      if (this.state.items.some(i => i === x)) return false
      if (props[x].invalidOnPath && props[x].invalidOnPath.test(location)) return false
      if (!props[x].validOnPath || props[x].validOnPath.test(location)) return true
      return false
    })

    const items = _.uniq([
      ...this.state.items.filter(x => {
        if (props[x]?.invalidOnPath && props[x]?.invalidOnPath.test(location)) return false
        if (!props[x]?.validOnPath || props[x]?.validOnPath.test(location)) return true
        return false
      }),
      ...availableItems.filter(item => props[item].isValid),
    ])

    this.setState({
      items,
      availableItems,
    })
  }

  handleFilterSelect = (item: any, metric: MetricType = null) => {
    const {items, selectedMetrics} = this.state
    if (item === 'metric' && metric !== null) {
      const {id} = metric
      if (!items.find(key => key === `metric:${id}`)) {
        items.push(`metric:${id}`)
        selectedMetrics[id] = metric
      }
      this.setState(state => ({
        ...state,
        items,
        menuOpen: false,
        selectedMetrics,
        opened: {
          [`metric:${id}`]: true,
        },
      }))
    } else {
      const availableItems = this.state.availableItems.filter(x => x !== item)
      items.push(item)
      this.setState({
        items,
        availableItems,
        menuOpen: false,
        opened: {
          [item]: true,
        },
      })
    }
  }

  classicFilter = ({key, item}: {key: string; item: any}) => {
    if (!item) return null
    const {onApply} = this.props
    const {component: Component, disabled, onRemove, title, isValid, ...properties} = item
    return (
      <Box key={key} maxHeight="auto" marginTop="8px">
        <Component
          {...properties}
          disabled={disabled}
          onApply={onApply}
          title={title || this.titleProvider(key)}
          triggered={this.state.opened[key] || false}
          onOpen={() => this.setState({opened: {[key]: true}})}
          onClose={() => this.setState({opened: {[key]: false}})}
          onRemove={() => {
            const {availableItems} = this.state
            const items = this.state.items.filter(x => x !== key)
            availableItems.push(key)
            this.setState({items, availableItems}, () => {
              if (onRemove) onRemove()
            })
          }}
        />
      </Box>
    )
  }

  metricFilter = ({key, item}: {key: string; item: any}) => {
    if (!item) return null
    const {disabled = false, onApply} = this.props
    const {selectedMetrics} = this.state
    const {component: Component, onRemove, title, isValid, data, ...properties} = item
    const metricKey = key?.split(':')?.[1] ?? key
    const {name = ''} = selectedMetrics?.[metricKey] ?? {}
    return (
      <Box key={key} maxHeight="auto" marginTop="8px">
        <Component
          {...properties}
          data={data || {key: metricKey, ...(selectedMetrics?.[metricKey] ?? {})}}
          disabled={disabled}
          onApply={onApply}
          title={title || name}
          triggered={this.state.opened[key] || false}
          onOpen={() => this.setState({opened: {[key]: true}})}
          onClose={() => this.setState({opened: {[key]: false}})}
          onRemove={() => {
            const items = this.state.items.filter(x => x !== key)
            this.setState({items}, () => {
              if (onRemove) onRemove()
            })
          }}
        />
      </Box>
    )
  }

  getPosition = () => {
    const {x = 0} = this.triggerRef?.current?.getBoundingClientRect() ?? {}
    const max = window?.innerWidth ?? 0
    const SIZE = 500
    return x + SIZE > max ? 'right' : 'left'
  }

  render() {
    const {
      alwaysForeground = true,
      availableCategories = null,
      availableMetrics = null,
      byCategory = false,
      children = null,
      disabled = false,
      elementToScroll = null,
      extraActions = true,
      isApplied = true,
      location,
      noBackground = false,
      noBorder = false,
      onApply = null,
      onReset = null,
      padding = null,
      ...props
    } = this.props

    return (
      <Col
        padding={padding || '8px'}
        minHeight="auto"
        backgroundColor={noBackground ? 'transparent' : colors.white.rgba}
        borderBottom={noBorder ? 'none' : `solid 1px ${colors.border.rgba}`}
        justifyContent="center"
        position="sticky"
        top="0"
        zIndex={alwaysForeground ? 1100 : 1}
      >
        <Row>
          {extraActions && !disabled && (
            <Row marginTop="-8px" marginRight="8px">
              <PopOver
                triggered={this.state.opened.importExportReset || false}
                onOpen={() => this.setState({opened: {importExportReset: true}})}
                onClose={() => this.setState({opened: {importExportReset: false}})}
                trigger={
                  <Col
                    cursor="pointer"
                    width="38px"
                    height="40px"
                    marginTop="8px"
                    // className={theme.primaryButton}
                    padding="6px"
                  >
                    <Icon name="filter" style={{marginLeft: '3px', color: colors.primary.rgba, marginTop: '4px'}} />
                  </Col>
                }
              >
                <Col
                  width="150px"
                  marginTop="8px"
                  className={theme.using(theme.shadowed)}
                  borderRadius="4px"
                  border={`solid 1px ${colors.primaryAccent.rgba}`}
                  backgroundColor={colors.white.rgba}
                >
                  {onReset && (
                    <Tracking
                      as={SeelkButton}
                      trackingEvent="Filters/Clear"
                      actionType="CRUD"
                      hookOn={['onClick']}
                      content={i18n.t('actions.global.clearFilters')}
                      icon="close"
                      iconPosition="left"
                      type="borderless"
                      onClick={() => {
                        this.setState({opened: {importExportReset: false}, items: []}, () => onReset())
                      }}
                    />
                  )}
                </Col>
              </PopOver>
            </Row>
          )}
          <Row flex="1" alignItems="center" flexWrap="wrap" marginTop="-8px">
            {this.state.items
              .map(k => {
                if (k.startsWith('metric:')) return this.metricFilter({key: k, item: props?.[k] ?? props.metric})
                else return this.classicFilter({key: k, item: props[k]})
              })
              .filter(filter => filter)}
            {this.state.availableItems.length > 0 && !disabled && (
              <PopOver
                popOverPosition={this.getPosition()}
                triggered={this.state.menuOpen}
                onOpen={() => {
                  this.setState({menuOpen: true}, () => {
                    if (elementToScroll !== null) {
                      elementToScroll.current.scrollTo(0, elementToScroll.current.scrollHeight)
                    }
                  })
                }}
                onClose={() => this.setState({menuOpen: false})}
                trigger={
                  <Col
                    props={{id: 'filters', ref: this.triggerRef}}
                    marginTop="8px"
                    height="40px"
                    width="200px"
                    className={theme.simplePrimaryButton}
                    justifyContent="center"
                  >
                    {i18n.t('actions.global.addFilter')}
                  </Col>
                }
              >
                {byCategory ? (
                  <FiltersSelectByCategory
                    availableCategories={availableCategories}
                    availableMetrics={availableMetrics}
                    availableFilters={this.state.availableItems
                      .filter(item => item !== 'metric')
                      .map(item => ({
                        ..._.pick(this.props[item], ['category', 'disabled', 'title', 'icon']),
                        filter: item,
                      }))}
                    onClick={this.handleFilterSelect}
                    open={this.state.menuOpen}
                  />
                ) : (
                  <Menu
                    vertical
                    className="bar-menu"
                    style={{
                      overflowY: 'scroll',
                      borderRadius: '4px',
                      boxShadow: `0px 0px 4px ${colors.shadow.rgba}`,
                      border: `solid 1px ${colors.primaryAccent.rgba}`,
                      maxHeight: '350px',
                    }}
                  >
                    {this.state.availableItems.map(item => {
                      // eslint-disable-next-line react/prop-types
                      const {disabled: disabledItem, title, icon, bigTextStyle} = this.props[item]
                      return (
                        <Menu.Item
                          key={item}
                          name={title || this.titleProvider(item)}
                          icon={icon}
                          disabled={disabledItem}
                          style={{
                            height: '44px',
                            minHeight: '44px',
                            color: colors.dark.rgba,
                            fontWeight: '500',
                            textTransform: 'none',
                            lineHeight: 1.5,
                            ...bigTextStyle,
                          }}
                          onClick={() => this.handleFilterSelect(item)}
                        />
                      )
                    })}
                  </Menu>
                )}
              </PopOver>
            )}
          </Row>
          {children && (
            <Row justifyContent="flex-end" alignItems="center">
              <Box>{children}</Box>
            </Row>
          )}
        </Row>
      </Col>
    )
  }
}

FiltersBar.items = filterItems

export default FiltersBar
