import React from 'react'

import {css, StyleSheet} from 'aphrodite'

import i18n from '../../utils/i18n'

const styles = StyleSheet.create({
  container: {
    flex: '1 0 auto',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

class NotFound extends React.PureComponent {
  render() {
    return <div className={css(styles.container)}>{i18n.t('placeholders.errors.404')}</div>
  }
}

export default NotFound
