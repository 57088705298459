export default {
  index: {
    avgBBActive: 'Avg. Active Buy Box',
    avgHasStock: 'Avg. Has Stock',
    avgBBOwnership: 'Avg. Buy Box Ownership',
    avgBBPrice: 'Avg. Buy Box Price',
    avgNbSellers: 'Avg. Nb. Sellers',
    BBActive: 'Active Buy Box',
    BBOwnership: 'Buy Box Ownership',
    BBPrice: 'Buy Box Price',
    coop: 'Coop',
    costPrice: 'Cost Price',
    hasStock: 'Has Stock',
    filters: {
      offerTags: 'Tags',
      offerCustomAttributes: 'Custom Attributes',
      asins: 'Asins',
      vendors: 'Vendors',
      offerSections: 'Sections',
      offerStatus: 'Status',
    },
    metricNotAvailablePeriod: 'Metric not available for the following period: Day',
    metricNotAvailableFilters: 'Metric not available for the following filter(s): {{filter}}',
    groupingNotAvailable: 'Group By Countries not available for the following filter(s): {{filter}}',
    netPpm: 'Net PPM',
    periodNotAvailable: 'Period not available for the following metrics: PPM',
    pcogs: 'PCoGS',
    pcogsManu: 'PCoGS Manu.',
    ppm: 'PPM',
    priceReference: 'Price Reference',
    priceTracker: 'Price Tracker',
    pricing: 'Pricing',
    promoPrice: 'Promo Price',
    rrp: 'RRP',
    sales: 'Sales',
    vendorPrice: '1P Price',
  },
}
