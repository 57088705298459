import React from 'react'

import {Box, Col} from 'jsxstyle'
import {Checkbox} from 'semantic-ui-react'

import colors from '../../../../../colors'
import i18n from '../../../../../i18n'
import {FilterButton} from '../../../..'
import FilterBarItem from './FilterBarItem'

type DataType = {
  value: null | number
  text: string
}

interface RadioProps {
  choices: DataType[]
  data: number | null
  disabled?: boolean
  icon?: string | null
  onApply: () => void
  onChange: (changes: null | number) => void
  onRemove: () => void
  title: string
}

const Radio = ({
  data,
  choices,
  onChange,
  onRemove,
  onApply,
  title,
  icon = null,
  disabled = false,
  ...barItemProps
}: RadioProps) => {
  const current = choices.find(({value}) => value === data)
  const trigger = (
    <FilterButton
      title={title}
      icon={icon}
      disabled={disabled}
      onRemove={onRemove}
      onApply={onApply}
      dropdown={undefined}
    >
      <Box marginLeft="8px">{current?.text ?? i18n.t('visualization.grouping.all')}</Box>
    </FilterButton>
  )

  return (
    <FilterBarItem
      {...barItemProps}
      data={data}
      onChange={onChange}
      onApply={onApply}
      disabled={disabled}
      trigger={trigger}
    >
      <Col width="150px" cursor="default">
        <Col padding="0px 8px 0px 8px" color={colors.dark.rgba} fontWeight="500">
          {choices.map(({value, text}) => (
            <Checkbox
              key={text}
              radio
              onClick={() => onChange(value)}
              checked={data === value}
              label={{
                children: <Box>{text}</Box>,
              }}
              className="checkbox-element"
            />
          ))}
        </Col>
      </Col>
    </FilterBarItem>
  )
}

export default Radio
