import React from 'react'

import PropTypes from 'prop-types'
import {connect} from 'react-redux-reliever'

import {rolesSelector, userEmailSelector} from '../app-layout/selectors'

const PROP_REQUIRED_MSG =
  "PermissionControl: one of ['showIfAll', 'showIfOne', 'hideIfAll', 'hideIfOne', 'showIfIs', 'hideIfIs'] props is required"
const TOO_MANY_PROPS_MSG =
  "PermissionControl can't handle multiple conditions.\nOnly provide one prop from ['showIfAll', 'showIfOne', 'hideIfAll', 'hideIfOne', 'showIfIs', 'hideIfIs']"

class PermissionControl extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    showIfIs: PropTypes.arrayOf(PropTypes.string),
    hideIfIs: PropTypes.arrayOf(PropTypes.string),
    showIfAll: PropTypes.arrayOf(PropTypes.string),
    showIfOne: PropTypes.arrayOf(PropTypes.string),
    hideIfAll: PropTypes.arrayOf(PropTypes.string),
    hideIfOne: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string),
    user: PropTypes.string,
  }

  static defaultProps = {
    disabled: false,
    showIfIs: [],
    hideIfIs: [],
    showIfAll: [],
    showIfOne: [],
    hideIfAll: [],
    hideIfOne: [],
    roles: null,
    user: null,
  }

  UNSAFE_componentWillMount() {
    const checkingArray = [
      this.props.showIfIs.length > 0,
      this.props.hideIfIs.length > 0,
      this.props.showIfAll.length > 0,
      this.props.showIfOne.length > 0,
      this.props.hideIfAll.length > 0,
      this.props.hideIfOne.length > 0,
    ]
    if (checkingArray.filter(c => c).length > 1) throw new Error(TOO_MANY_PROPS_MSG)
    if (!checkingArray.some(c => c)) throw new Error(PROP_REQUIRED_MSG)
  }

  showDisabled = () => {
    const {children, disabled} = this.props
    if (disabled) return React.cloneElement(children, {disabled: true})
    return null
  }

  render() {
    const {showIfIs, hideIfIs, showIfAll, showIfOne, hideIfAll, hideIfOne, roles, user, children} = this.props
    if (!roles || !user) return null
    if (showIfIs.length > 0) {
      return showIfIs.includes(user) ? children : this.showDisabled()
    }
    if (hideIfIs.length > 0) {
      return hideIfIs.includes(user) ? null : children
    }
    if (hideIfAll.length > 0 || hideIfOne.length > 0) {
      return hideIfOne.some(role => roles.includes(role)) ||
        (hideIfAll.length > 0 && hideIfAll.every(role => roles.includes(role)))
        ? null
        : children
    }
    if (showIfAll.length > 0 || showIfOne.length > 0) {
      return showIfOne.some(role => roles.includes(role)) ||
        (showIfAll.length > 0 && showIfAll.every(role => roles.includes(role)))
        ? children
        : this.showDisabled()
    }
    throw new Error(PROP_REQUIRED_MSG)
  }
}

export default connect({
  props: (state, ownProps) => ({
    roles: rolesSelector(state),
    user: userEmailSelector(state),
    ...ownProps,
  }),
})(PermissionControl)
