import React from 'react'

import {Form, TextArea, TextAreaProps} from 'semantic-ui-react'
import styled from 'styled-components'

import i18n from '../../../i18n'

const StyledTextArea = styled(TextArea)`
  &&& {
    border-color: ${props => props.theme.textArea.borderColor};
    color: ${props => props.theme.textArea.color};
    height: ${props => props.theme.textArea.height};
    resize: ${props => props.resize};
    width: ${props => props.theme.textArea.width};
    :focus {
      border-color: ${props => props.theme.textArea.borderColorFocused};
    }
  }
`

interface SeelkTextAreaProps {
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => void
  height?: string
  placeholder?: string
  resize?: 'both' | 'horizontal' | 'none' | 'vertical'
  value?: string
  width?: string
}

const SeelkTextArea = ({
  height = '100%',
  onChange,
  placeholder = i18n.t('placeholders.global.textArea'),
  resize = 'none',
  value = '',
  width = '100%',
}: SeelkTextAreaProps) => {
  /* 
    As said in the React Semantic-UI documentation (https://react.semantic-ui.com/addons/text-area/#types-text-area),
    we need to wrap the TextArea component inside a Form component if we want the Semantic-UI styles to be applied.
  */
  return (
    <Form>
      <StyledTextArea
        style={{width, height}}
        onChange={onChange}
        placeholder={placeholder}
        resize={resize}
        value={value}
      />
    </Form>
  )
}

export default SeelkTextArea
