import React from 'react'

import {Box, Row} from 'jsxstyle'
import {Flag, FlagNameValues} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'
import {PoppersPopup} from '../../common-ui'
import i18n from '../../i18n'
import theme from '../../theme'

const StyledText = styled.div`
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  :hover {
    cursor: pointer;
    text-decoration: underline;
    color: ${colors.primary.rgba};
  }
`

type ItemType = {
  key: string
}

type ExtraType = {
  onClick?: ({event, value, key}: {event: React.MouseEvent<HTMLElement>; value: string; key: string}) => void
}

interface CountryProps {
  extra: ExtraType
  item: ItemType
  value: string
}

const Country = ({value, item, extra = {}}: CountryProps) => (
  <PoppersPopup
    content={value}
    basic
    trigger={
      <Row alignItems="center">
        <Box>
          <Flag name={item.key.toLowerCase() as FlagNameValues} />
        </Box>
        <StyledText
          onClick={event => {
            if (extra.onClick) extra.onClick({event, value, key: item.key})
          }}
          className={theme.using(theme.interactiveUnderline)}
        >
          {item.key === i18n.t('placeholders.empty.notAvailable')
            ? i18n.t('placeholders.empty.notAvailable')
            : i18n.t(`countries.${item.key.toLowerCase()}.name`)}
        </StyledText>
      </Row>
    }
  />
)

export default Country
