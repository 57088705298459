import React from 'react'

import {Icon, Label} from 'semantic-ui-react'
import styled from 'styled-components'

import {clamp} from '../..'
import colors from '../../colors'
import i18n from '../../i18n'

const StyledName = styled.div<{detailed: boolean | undefined}>`
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px;
  color: ${colors.dark.rgba};
  ${props => props.detailed && `font-size: 0.85em`};
  :hover: {
    text-decoration: underline;
    cursor: pointer;
    color: ${colors.primary.rgba};
  }
`

const StyledDetail = styled.div`
  font-size: 10px;
  line-height: 1.2em;
`

const StyledProductLinkIcon = styled(Icon)`
  &&& {
    cursor: pointer;
    color: ${colors.primary.rgba};
  }
`

const StyledTrigger = styled.span`
  cursor: pointer;
  margin-left: 5px;
  color: ${colors.darkLight.rgba};
  font-size: 0.85em;
  :hover: {
    text-decoration: underline;
  }
`

type TagType = {
  [slug: string]: {
    slug: string
    name: string
    color: string
    dynamic?: boolean
  }
}

type ItemType = {
  tags?: string[]
  children?: ItemType
  title?: string
  asin: string
  offer: {asin: string}
  page_url?: string
}

type ExtraType = {
  tagList?: TagType
  displayTagsModal?: null | ((item: ItemType) => void)
  hideAsin?: boolean
  detailed?: boolean
  link?: (item: ItemType) => string
}

const isClickable = (extra: ExtraType, item: ItemType) => extra?.link && global.open(extra.link(item))

interface ProductNameProps {
  item: ItemType
  extra?: ExtraType
}

const ProductName = ({item, extra = {}}: ProductNameProps) => {
  const {tagList = {}, displayTagsModal = null, hideAsin = false} = extra
  const tags = item.tags || []
  return (
    <div>
      {item.children ? (
        <StyledName detailed={extra.detailed}>{item.title}</StyledName>
      ) : (
        <StyledName detailed={extra.detailed} onClick={() => isClickable(extra, item)}>
          {item.title}
        </StyledName>
      )}
      {hideAsin ? (
        <br />
      ) : (
        <StyledDetail>
          ASIN {item.asin || item.offer.asin}
          {!item.children && (
            <StyledProductLinkIcon
              name="external"
              size="small"
              onClick={() => global.open(item.page_url)}
              style={{color: colors.primary, marginLeft: 12}}
            />
          )}
        </StyledDetail>
      )}
      {tags.length > 0 &&
        Object.keys(tagList).length > 0 &&
        tags.slice(0, 2).map(slug =>
          tagList[slug] ? (
            <Label
              key={`${item.asin}${slug}`}
              value={slug}
              style={{
                backgroundColor: tagList[slug] ? tagList[slug].color : '#aaa',
                color: 'white',
                padding: '4px 6px',
                margin: '4px 0 4px 2px',
              }}
            >
              {tagList[slug].dynamic && <Icon name="bolt" />}
              {tagList[slug] ? clamp(tagList[slug].name, 15) : ''}
            </Label>
          ) : null
        )}
      {tags.length > 2 && displayTagsModal && (
        <StyledTrigger
          onClick={e => {
            e.stopPropagation()
            displayTagsModal(item)
          }}
        >
          {i18n.t('settings.tags.seeAll', {count: tags.length})}
        </StyledTrigger>
      )}
    </div>
  )
}

export default ProductName
