import {createLogger} from 'redux-logger'

class LoggerPlugin {
  setupStore() {}

  // eslint-disable-next-line no-unused-vars
  createMiddleware(reliever, options = {}, module) {
    return createLogger({
      predicate: (state, action) =>
        action.type && !action.type.startsWith('REQUESTING_') && action.type.startsWith(`${reliever.ACTION_PREFIX}_`),
      ...options,
    })
  }
}

export default LoggerPlugin
