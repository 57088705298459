import React from 'react'

import {css, StyleSheet} from 'aphrodite'
import {Box, Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'

import colors from '../../../colors'
import {animation} from '../../../css'

class ReactLoaderQuotes extends React.Component {
  static get propTypes() {
    return {
      quotes: PropTypes.arrayOf(PropTypes.string).isRequired,
      random: PropTypes.bool,
      speed: PropTypes.number,
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      currentQuotesIndex: 0,
    }

    this.styles = StyleSheet.create({
      loader: {
        height: 10,
        ...animation({
          keyframes: {
            from: {width: '0%'},
            to: {width: '100%'},
          },
          duration: `${this.props.speed}s`,
          curve: 'linear',
          count: '1',
        }),
        borderRadius: 10,
        background: `linear-gradient(to right, ${colors.primary.rgba}, rgba(255, 255, 255, 0.65))`,
      },
    })
    this.startQuotes()
  }

  componentWillUnmount() {
    clearInterval(this.quotesInterval)
  }

  getNextQuote(quotes) {
    // if at the end of our quotes, then go back to the first quote
    if (this.state.currentQuotesIndex === quotes.length - 1) {
      return 0
    }

    return this.state.currentQuotesIndex + 1
  }

  getRandomQuote(quotes) {
    return Math.floor(Math.random() * quotes.length)
  }

  startQuotes() {
    this.quotesInterval = setInterval(() => {
      this.setState({
        currentQuotesIndex: this.props.random
          ? this.getRandomQuote(this.props.quotes)
          : this.getNextQuote(this.props.quotes),
      })
    }, this.props.speed * 1000)
  }

  /* eslint-disable react/no-danger */
  render() {
    return (
      <Col flex={1} alignItems="center" style={{width: '50%'}}>
        <Box>
          <p
            dangerouslySetInnerHTML={{
              __html: this.props.quotes[this.state.currentQuotesIndex].replace(/\n/g, '<br />'),
            }}
            style={{textAlign: 'center', fontSize: 18, color: colors.black.rgba, marginBottom: 0}}
          />
          <Row style={{width: '100%', marginTop: 5}}>
            {/* key to prevent re-render which uglies the progress bar at the reload */}
            <Box key={this.state.currentQuotesIndex} className={css(this.styles.loader)} />
          </Row>
        </Box>
      </Col>
    )
  }
  /* eslint-enable react/no-danger */
}

ReactLoaderQuotes.defaultProps = {
  random: false,
  speed: 1.5,
}

export default ReactLoaderQuotes
