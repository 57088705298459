import React, {useState} from 'react'

import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import {fuzzyWordsSearch} from '../../..'
import colors from '../../../colors'
import i18n from '../../../i18n'
import PoppersPopup from '../PoppersPopup'
import DebouncedInput from './DebouncedInput'

const StyledPopup = styled(PoppersPopup)`
  height: 230px;
  padding: 11px !important;
`
const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 0px;
  align-items: flex-start;
  justify-content: start;
  max-height: 160px;
  min-width: 300px;
  overflow-y: scroll;
  margin-top: 10px;
`
const StyledIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 30px;
  width: 30px;
  padding: 20px;
  :hover {
    background-color: ${colors.light.rgba};
  }
`
const StyledIcon = styled(Icon)`
  align-items: center;
  color: ${colors.dark.rgba};
  display: flex !important;
  font-size: 22px !important;
  justify-content: center;
  margin: 0 !important;
  margin: 0;
  padding: 0 !important;
`

library.add(fas)

const fasIcons = Object.keys(library.definitions.fas).filter(fai => fai !== 'font-awesome-logo-full')

const getRandomNFilteredIcons = (search, n = 100) => {
  const randomizedIcons = fasIcons.filter(icon => fuzzyWordsSearch(search ?? '', icon))
  return randomizedIcons.slice(0, n)
}

const IconSelector = ({onChange, position, trigger}) => {
  const [search, setSearch] = useState('')

  return (
    <StyledPopup trigger={trigger} on="click" position={position}>
      <DebouncedInput
        fluid
        icon="search"
        iconPosition="left"
        onChange={(e, {value}) => setSearch(value)}
        placeholder={i18n.t('actions.search.search')}
        resetOnPaste={false}
        transparent={false}
        value={search}
      />
      <StyledGrid>
        {getRandomNFilteredIcons(search, 100).map(icon => (
          <StyledIconContainer key={icon} onClick={() => onChange(`fas fa-${icon}`)}>
            <StyledIcon name={`fas fa-${icon}`} />
          </StyledIconContainer>
        ))}
      </StyledGrid>
    </StyledPopup>
  )
}

IconSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  position: PropTypes.string,
  trigger: PropTypes.node.isRequired,
}

IconSelector.defaultProps = {
  position: 'bottom right',
}

export default IconSelector
