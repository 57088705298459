import React from 'react'

import _ from 'lodash'
import {Checkbox, ListItem} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../../../../colors'
import i18n from '../../../../../i18n'

const StyledListItem = styled(ListItem)`
  &&&&& {
    font-weight: ${props => (props.selected ? '500' : props.color)};
    > div > label {
      color: ${props => (props.selected ? colors.primary.rgba : props.color)};
      :focus {
        color: ${props => (props.selected ? colors.primary.rgba : props.color)};
      }
      &::after {
        color: ${props => (props.selected ? colors.primary.rgba : props.color)};
        :focus {
          color: ${props => (props.selected ? colors.primary.rgba : props.color)};
        }
      }
    }
  }
`

interface SelectAllProps<T> {
  bulkChange: (choices: T[]) => void
  choices: T[]
  data: T[]
}

const SelectAll = <T extends unknown>({choices, bulkChange, data}: SelectAllProps<T>) => {
  const allSelected = () => _.difference(choices, data).length === 0

  const anySelected = () => data?.length > 0 && !allSelected()

  const handleCheckAll = () => bulkChange(choices)

  const handleUnCheckAll = () => bulkChange([])

  const handleGlobalCheck = () => {
    if (allSelected()) handleUnCheckAll()
    else handleCheckAll()
  }

  return (
    <StyledListItem selected={allSelected()} color={anySelected() ? colors.black.rgba : colors.lightDark.rgba}>
      <Checkbox
        checked={allSelected()}
        indeterminate={anySelected()}
        onChange={() => handleGlobalCheck()}
        label={i18n.t('actions.select.all')}
      />
    </StyledListItem>
  )
}

export default SelectAll
