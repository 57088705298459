import React, {Component} from 'react'

import {Row} from 'jsxstyle'
import {List as VirtualList, ListRowProps} from 'react-virtualized'
import {Header, Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import SeelkCheckbox from '../../SeelkCheckbox'
import SeelkElevatedPanel from '../../SeelkElevatedPanel'

const StyledHeaderRow = styled(Row)`
  align-items: ${props => props.theme.checkboxPanel.header.alignItems};
  height: ${props => props.theme.checkboxPanel.header.height};
  padding-left: ${props => props.theme.checkboxPanel.header.paddingLeft};
  padding-right: ${props => props.theme.checkboxPanel.header.paddingRight};
  i {
    color: ${props => props.theme.checkboxPanel.header.iconColor};
    line-height: ${props => props.theme.checkboxPanel.header.iconLineHeight};
  }
  &&& > div {
    margin-top: ${props => props.theme.checkboxPanel.header.marginTop};
    margin-left: ${props => props.withIcon && props.theme.checkboxPanel.header.marginLeft};
  }
`

const StyledRow = styled(Row)`
  &&&&& {
    align-items: ${props => props.theme.checkboxPanel.alignItems};
    background-color: ${props => props.theme.checkboxPanel.backgroundColor};
    height: ${props => props.theme.checkboxPanel.height};
    padding-left: ${props => props.theme.checkboxPanel.paddingLeft};
    padding-right: ${props => props.theme.checkboxPanel.paddingRight};

    /* This property is necessary to allow the row highlight not to break the border-radius of the background */
    border-radius: ${props => {
      if (props.position === -1) {
        return props.theme.radioPanel.rows.lastRowBorderRadius
      } else {
        return props.theme.radioPanel.rows.borderRadius
      }
    }};

    :hover {
      background-color: ${props => props.theme.checkboxPanel.hoverBackgroundColor};
      cursor: ${props => props.theme.checkboxPanel.hoverCursor};
    }
    :hover,
    :hover label {
      color: ${props => props.theme.checkboxPanel.hoverColor};
    }
  }
`

type ChoiceType = {
  label: string
  icon?: string
  value: string
}

type HeaderType = {
  text: string
  icon?: string
}

interface SeelkCheckboxPanelProps {
  choices: ChoiceType[]
  onItemClick: (item: string) => void
  header?: HeaderType
  panel?: boolean
  selectedItems?: string[]
}

export default class SeelkCheckboxPanel extends Component<SeelkCheckboxPanelProps> {
  render() {
    const {choices, onItemClick, header = null, selectedItems = [], panel = false} = this.props

    const rowRenderer = ({key, index, style}: ListRowProps) => {
      const choice = choices[index]
      return (
        <StyledRow
          props={{onClick: () => onItemClick(choice.value)}}
          key={key}
          position={index === choices.length - 1 ? -1 : index}
          style={style}
        >
          <SeelkCheckbox
            label={
              <label style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                {choice.icon && <Icon className={choice.icon} />}
                {choice.label}
              </label>
            }
            onClick={() => onItemClick(choice.value)}
            checked={selectedItems.includes(choice.value)}
          />
        </StyledRow>
      )
    }

    const Wrapper = panel ? SeelkElevatedPanel : React.Fragment

    return (
      <Wrapper>
        {header && header.text && (
          <StyledHeaderRow withIcon={header.icon || false}>
            {header.icon && <Icon className={header.icon} />}
            <Header content={header.text} size="small" />
          </StyledHeaderRow>
        )}
        <VirtualList
          width={300}
          height={choices.length >= 8 ? 280 : 280 - (8 - choices.length) * 35}
          rowCount={choices.length}
          rowHeight={35}
          rowRenderer={rowRenderer}
        />
      </Wrapper>
    )
  }
}
