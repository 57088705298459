export default {
  accountType: 'Account Types',
  adGroups: 'Ad Groups',
  adGroupsAttributes: 'Ad Group Attributes',
  adGroupsTags: 'Ad Group Tags',
  addOn: 'Add On',
  advancedFilters: 'Advanced Filters',
  advertiserCountry: 'Advertiser Country',
  advertiserAttributes: 'Advertiser Attributes',
  advertiserName: 'Advertiser Name',
  advertiserTags: 'Advertiser Tags',
  amazonAccounts: 'Amazon Accounts',
  creativeAttributes: 'Creative Attributes',
  creativeName: 'Creative Name',
  creativeSize: 'Creative Size',
  creativeTags: 'Creative Tags',
  creativeType: 'Creative Type',
  dspProfile: 'DSP Profile',
  lineItemName: 'Line Item Name',
  orderName: 'Order Name',
  all: '(All)',
  allDimensions: 'All Dimensions',
  allMarketplaces: 'All Marketplaces',
  amazonBrands: 'Amazon Brands',
  amazonCategories: 'Amazon Categories',
  and: 'And',
  asins: 'ASIN',
  asinsTitle: 'Select ASINs...',
  attribute: 'Attribute: {{title}}',
  attributes: 'Attributes',
  attributesTitle: 'Select attributes...',
  averageRating: 'Average Rating',
  bbActive: 'Active Buy Box',
  bbOwnership: 'Buy Box Ownership',
  bbPricing: 'Buy Box Price',
  boolean: {
    all: 'Select all',
    active: 'Active',
    inactive: 'Inactive',
  },
  brands: 'Brands',
  brandsTitle: 'Select Amazon brands...',
  bulletPoints: 'Bullet Points',
  bulletPointsCompliance: 'Bullet Points Compliance',
  buyBox: 'Buy Box',
  buyBoxOwner: 'Buy Box Owner',
  buyBoxPrice: 'Buy Box Price',
  byAdGroups: 'Filter by Ad Groups',
  byAmazonBrands: 'Filter by Amazon Brands',
  byAsins: 'Filter by ASIN',
  byAttribute: 'Filter by {{attribute}}',
  byBrands: 'Filter by Brands',
  byCampaigns: 'Filter by Campaigns',
  byCategories: 'Filter by Categories',
  byCustomAttribute: 'Filter by custom attribute',
  byKeywords: 'Filter by Keywords',
  byProfiles: 'Filter by Profiles',
  bySkus: 'Filter by SKU',
  byTags: 'Filter by Tags',
  campaigns: 'Campaigns',
  campaignsAttributes: 'Campaign Attributes',
  campaignsTags: 'Campaign Tags',
  campaignStates: 'Campaign States',
  campaignTypes: 'Campaign Types',
  categoriesNumber: 'Number of categories',
  categoriesTitle: 'Select Amazon categories...',
  click: 'Click to select',
  client: 'Client',
  contentScore: 'Content score',
  countries: {
    one: '1 country',
    other: '{{count}} countries',
    zero: 'Countries',
  },
  countriesTitle: 'Select countries...',
  customAttributes: {
    one: '1 custom attribute',
    other: '{{count}} custom attributes',
    zero: 'No custom attributes',
  },
  department: 'Department',
  descriptionCompliance: 'Description Compliance',
  descLength: 'Description Length',
  fastTrack: 'Fast Track',
  fbaSellers: 'FBA Sellers',
  filterBy: 'Filter by {{items}}',
  frequencies: 'Frequencies',
  fulfillmentTypes: 'Fulfillment Types',
  hasAPlus: 'A Plus',
  hasStock: 'Has Stock',
  images: 'Images',
  invalidProducts: 'Invalid products',
  isPrime: 'Prime',
  isPantry: 'Pantry',
  keywordsNumber: 'Number of Keywords',
  lineItemAttributes: 'Line Item Attributes',
  lineItemTags: 'Line Item Tags',
  keywordsTitle: 'Select keywords...',
  marketplaces: 'Marketplaces',
  mfnSellers: 'MFN Sellers',
  name: 'Name',
  newRating: 'New Rating',
  newReviews: 'New Reviews',
  offersAttributes: 'Product Attributes',
  offersCount: 'Number of Products',
  offersNumber: 'Number of Products',
  offersScore: 'Products score',
  offersTags: 'Product Tags',
  operators: {
    bw: 'Between',
    eq: 'Equal',
    gt: 'Greater than',
    gte: 'Greater than or equal to',
    in: 'In',
    lt: 'Less than',
    lte: 'Less than or equal to',
    nbw: 'Not Between',
    neq: 'Does not Equal',
    notIn: 'Not in',
  },
  orderAttributes: 'Order Attributes',
  orderTags: 'Order Tags',
  prevRating: 'Prev. Rating',
  prevReviews: 'Prev. Reviews',
  prime: 'Prime',
  productsNumber: 'Number of Products',
  primeOnly: 'Prime Only',
  productsWithoutMasterContent: 'Products without Master Content',
  profiles: 'Profiles',
  ratingDiff: 'Rating diff.',
  ratingDiffScore: 'Rating diff. Score',
  ratingScore: 'Rating score',
  reset: 'Reset Filters',
  reviews: 'Reviews',
  reviewsScore: 'Reviews score',
  salesRank: 'Sales Rank',
  scrapingFrequency: 'Scraping frequency',
  search: 'Search',
  searchTerms: 'Search terms...',
  section: 'Section',
  sections: 'Sections',
  skus: 'SKU',
  sns: 'SNS',
  status: 'Status',
  subCategoriesNumber: 'Number of Subcategories',
  tags: 'Tags',
  tagsTitle: 'Select tags...',
  title: 'Title',
  titleLength: 'Title Length',
  titleCompliance: 'Title Compliance',
  totalReviews: 'Total Reviews',
  untagged_keywords: 'Untagged Keywords',
  untagged_offers: 'Untagged Products',
  user: 'User',
  vendorOffer: 'Vendor offer',
  vendorPrice: 'Vendor Price',
  vendors: 'Vendors',
  videos: 'Videos',
  with: 'With',
}
