import React, {useEffect, useState} from 'react'

import {Box, Col, Row} from 'jsxstyle'
import styled from 'styled-components'

import colors from '../../../../../colors'
import i18n from '../../../../../i18n'
import theme from '../../../../../theme'
import PopOver from '../../../PopOver'

const StyledApplyRow = styled(Row)`
  color: ${colors.white.rgba};
  background-color: ${colors.primary.rgba};
  border-radius: ${props => (props.asRow ? '3px' : '0 0 3px 3px')};
  font-weight: 500;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.canApply ? 'pointer' : 'initial')};
  :hover {
    background-color: ${colors.primaryDark.rgba};
  }
  margin: ${props => (props.noPadding || props.asRow ? '0' : '8px -8px -8px -8px')};
  opacity: ${props => (props.canApply ? 1 : 0.5)};
  position: relative;
  left: ${props => (props.asRow ? '0px' : '-1px')};
  bottom: ${props => (props.asRow ? '0px' : '-1px')};
  width: ${props => `calc(100% + ${props.noPadding ? '2px' : '18px'})`};
`

interface FilterBarItemProps {
  data: any
  trigger: React.ReactNode
  asRow?: boolean
  canApply?: boolean
  children?: React.ReactNode | null
  containsPortal?: boolean
  disabled?: boolean
  noPadding?: boolean
  onApply?: () => void
  onChange?: (filters: any) => void
  onClose?: () => void
  wrapped?: boolean
}

const FilterBarItem = ({
  data,
  trigger,
  asRow = false,
  canApply = true,
  children = null,
  disabled = false,
  noPadding = false,
  onApply,
  onChange,
  onClose,
  wrapped = true,
  ...additionalProps
}: FilterBarItemProps) => {
  const [activeFilters, setActiveFilters] = useState(data)

  // If the Filter is not wrapped, the parent is responsible of updating the activeFilters
  // Contained in this case by the data prop
  useEffect(() => {
    if (!wrapped) {
      setActiveFilters(data)
    }
  }, [wrapped, data])

  return (
    <PopOver
      {...additionalProps}
      height="auto"
      minHeight="40px"
      marginRight="8px"
      trigger={trigger}
      disabled={disabled}
      onClose={() => {
        if (onChange) onChange(activeFilters)
        if (onClose) onClose()
      }}
    >
      {wrapped
        ? children &&
          (asRow ? (
            <Row
              justifyContent="center"
              className={theme.using(theme.bordered, theme.shadowed)}
              marginTop="8px"
              padding={noPadding ? 0 : 8}
              backgroundColor={colors.white.rgba}
            >
              {children}
              <StyledApplyRow
                props={{
                  onClick:
                    canApply && onApply && onClose
                      ? () => {
                          onApply()
                          onClose()
                          setActiveFilters(data)
                        }
                      : () => {},
                }}
                noPadding={noPadding}
                canApply={canApply}
                asRow
              >
                <Box>{i18n.t('actions.global.apply')}</Box>
              </StyledApplyRow>
            </Row>
          ) : (
            <Col
              justifyContent="center"
              className={theme.using(theme.bordered, theme.shadowed)}
              marginTop="8px"
              padding={noPadding ? 0 : 8}
              backgroundColor={colors.white.rgba}
            >
              {children}
              <StyledApplyRow
                props={{
                  onClick:
                    canApply && onApply && onClose
                      ? () => {
                          onApply()
                          onClose()
                          setActiveFilters(data)
                        }
                      : () => {},
                }}
                canApply={canApply}
                noPadding={noPadding}
              >
                <Box>{i18n.t('actions.global.apply')}</Box>
              </StyledApplyRow>
            </Col>
          ))
        : children}
    </PopOver>
  )
}

export default FilterBarItem
