import React from 'react'

import {Moment} from 'moment'

import {MODES} from './constants'
import {modeIsLowerThan, offsetValue} from './utils'

interface HeaderWidgetProps {
  mode: typeof MODES[number]
  value: Moment
  enableNext?: boolean
  enablePrev?: boolean
  offset?: number
  onChangeMode?: ((mode: typeof MODES[number]) => void) | null
  onChangeOffset?: ((offset: number) => void) | null
  prefixCls?: string
}

export default class HeaderWidget extends React.Component<HeaderWidgetProps> {
  renderTitle = () => {
    const {value, mode, offset = 0, prefixCls = 'datePicker', onChangeMode = null} = this.props
    const offsetted = offsetValue(value, offset, mode)
    const yearWidget = modeIsLowerThan(mode, 'year') && (
      <button
        type="button"
        className={`${prefixCls}YearWidget${onChangeMode ? '' : 'Disabled'}`}
        onClick={() => onChangeMode && onChangeMode('year')}
      >
        {offsetted.format('YYYY')}
      </button>
    )
    const monthWidget = modeIsLowerThan(mode, 'month') && (
      <button
        type="button"
        className={`${prefixCls}MonthWidget${onChangeMode ? '' : 'Disabled'}`}
        onClick={() => onChangeMode && onChangeMode('month')}
      >
        {offsetted.format('MMM')}
      </button>
    )

    return (
      <span className={`${prefixCls}HeaderWidgets`}>
        {monthWidget}
        {yearWidget}
      </span>
    )
  }

  handleOffsetChange = (dir: number) => {
    const {offset = 0, onChangeOffset = null} = this.props
    if (onChangeOffset) onChangeOffset(offset + dir)
  }

  render() {
    const {onChangeOffset = null, enablePrev = true, enableNext = true, prefixCls = 'datePicker'} = this.props
    return (
      <div className={`${prefixCls}Header`}>
        {onChangeOffset && enablePrev && (
          <button type="button" className={`${prefixCls}PrevButton`} onClick={() => this.handleOffsetChange(-1)} />
        )}
        {this.renderTitle()}
        {onChangeOffset && enableNext && (
          <button type="button" className={`${prefixCls}NextButton`} onClick={() => this.handleOffsetChange(1)} />
        )}
      </div>
    )
  }
}
