import React, {useEffect, useRef, useState} from 'react'

import {Col, Row} from 'jsxstyle'
import _, {capitalize} from 'lodash'
import PropTypes from 'prop-types'
import {Icon} from 'semantic-ui-react'
import styled, {keyframes} from 'styled-components'

import colors from '../../../../colors'
import i18n from '../../../../i18n'
import {sortStrings} from '../../../../visualization'

const StyledThemes = styled(Col)`
  &&& {
    background-color: ${colors.lightAccent.rgba};
    flex-shrink: 0;
    width: 200px;
    border-radius: 5px;
    margin: 8px 12px 8px 8px;
    overflow-y: scroll;
  }
`

const StyledTheme = styled.span<{active: boolean}>`
  cursor: pointer;
  padding: 8px 10px;
  color: ${props => (props.active ? colors.primary.rgba : colors.darkLight.rgba)};
  background-color: ${props => (props.active ? colors.primaryLight.rgba : 'none')};
  :hover {
    background-color: ${colors.primaryLight.rgba} !important;
    color: ${colors.primary.rgba} !important;
  }
`

const StyledMetricList = styled.div`
  width: 100%;
  overflow-y: scroll;
  margin: 8px 0;
  padding-right: 5px;
`

const scrollAnimation = (x: number) => keyframes`
  0% {
    left: 0px;
  }
  100% {
    left: ${x}px;
  }
`

const StyledNameContainer = styled.div`
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledName = styled.span`
  display: block;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface MetricType {
  aggregates: string[]
  childrenMetric?: MetricType[]
  data_sources: string
  default_aggregation: string
  description: string
  documentation_page: string
  formula: string
  grade: number
  groupings: string[]
  higher_is_better: number
  id: string
  isBoolean: number
  is_boolean: number
  is_new: number
  key: string
  locked: boolean
  metricFieldName: string
  name: string
  parentMetric: string
  parent_metric: string
  periods: string[]
  related_metrics: string
  shortname: string
  text: string
  theme: string[]
  tied_to: string
  unit: string
  value: string
}

const StyledMetric = styled.div<{
  locked: number
  subMetric?: boolean
  second: number
  scrollx: number
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  color: ${props => (props.locked ? colors.lightDark.rgba : colors.dark.rgba)};
  cursor: ${props => (props.locked ? 'default' : 'pointer')};
  padding: ${props => (props.subMetric ? '5px 10px 5px 20px' : '8px 10px')};
  width: 100%;
  :hover {
    background-color: ${props => (props.locked ? 'transparent' : colors.primaryLight.rgba)};
    color: ${props => (props.locked ? colors.lightDark.rgba : colors.primary.rgba)};
    ${StyledName} {
      width: auto;
      overflow: visible;
      animation: ${props => (props.second ? `${props.second}s` : `1s`)} linear 250ms infinite alternate
        ${props => scrollAnimation(props.scrollx)};
    }
  }
`

const getPrefilter = (metric: string) => {
  const [, prefilter] = metric?.split('_') ?? []
  if (['closed', 'confirmed'].includes(prefilter)) return capitalize(prefilter)
  else if (['sb', 'sd', 'sp', 'spsd'].includes(prefilter))
    return prefilter === 'spsd' ? 'SP+SD' : prefilter.toUpperCase()
  return capitalize(i18n.t('dashboards.labels.all'))
}

interface SelectedMetric {
  aggregates: string[]
  aggregation: string
  groupings: string[]
  id: string
  name: string
  unit: string
}

interface MetricPropTypes {
  metric: MetricType
  updateMetric: (metric: SelectedMetric) => void
}

interface SelectedSubMetric {
  subId?: string | null
  subName?: string | null
}

const Metric = ({metric, updateMetric}: MetricPropTypes) => {
  const {aggregates, childrenMetric = [], default_aggregation, groupings, id, locked, name, unit} = metric
  const [unfold, triggerUnfold] = useState(false)
  const [offset, setOffset] = useState(0)
  const metricRef = useRef(null)

  const defaultAggregate = aggregates?.includes(default_aggregation)
    ? default_aggregation // eslint-disable-line camelcase
    : aggregates?.[0] ?? null

  const setMetric = ({subId = null, subName = null}: SelectedSubMetric) => {
    if (!locked)
      updateMetric({
        aggregates,
        aggregation: defaultAggregate,
        groupings,
        id: subId || id,
        name: subName || name,
        unit,
      })
  }

  const isEllipsisActive = () => {
    const {offsetWidth = 0, scrollWidth = 0} = metricRef?.current ?? {offsetWidth: 0, scrollWidth: 0}
    setOffset(offsetWidth < scrollWidth ? offsetWidth - scrollWidth - 10 : 0)
  }

  if (childrenMetric?.length)
    return (
      <>
        <StyledMetric
          locked={locked ? 1 : 0}
          key={name}
          onClick={() => triggerUnfold(!unfold)}
          scrollx={offset}
          second={offset ? (offset * -10) / 1000 : 1}
          onMouseOver={isEllipsisActive}
        >
          <StyledNameContainer>
            <StyledName>{name}</StyledName>
          </StyledNameContainer>
          {unfold ? <Icon name="caret up" /> : <Icon name="caret down" />}
        </StyledMetric>
        {unfold
          ? [metric, ...childrenMetric.sort((lhs, rhs) => sortStrings(lhs.id, rhs.id))].map(
              ({id: subId, name: subName, ...child}) => (
                <StyledMetric
                  locked={locked ? 1 : 0}
                  key={subId}
                  subMetric
                  onClick={() => setMetric({subId, subName})}
                  scrollx={0}
                  second={1}
                >
                  <StyledNameContainer>
                    <StyledName>{getPrefilter(subId)}</StyledName>
                  </StyledNameContainer>
                </StyledMetric>
              )
            )
          : null}
      </>
    )
  return (
    <StyledMetric
      key={name}
      locked={locked ? 1 : 0}
      onClick={() => setMetric({})}
      scrollx={offset}
      second={offset ? (offset * -10) / 1000 : 1}
      onMouseOver={isEllipsisActive}
    >
      <StyledNameContainer>
        <StyledName ref={metricRef}>{name}</StyledName>
      </StyledNameContainer>
    </StyledMetric>
  )
}

interface ThemesPropTypes {
  list: Record<string, MetricType[]>
  active: string
  updateTheme: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, value: {theme: string}) => void
  countAll: number
}

const Themes = ({list = {}, active = 'all', updateTheme, countAll}: ThemesPropTypes) => {
  const themes = Object.keys(list || {}).filter(t => t)
  return (
    <StyledThemes>
      <StyledTheme key="all" active={active === 'all'} onClick={e => updateTheme(e, {theme: 'all'})}>
        {`${i18n.t('visualization.grouping.allCount', {count: countAll})}`}
      </StyledTheme>
      {themes.map(theme => (
        <StyledTheme key={theme} active={theme === active} onClick={e => updateTheme(e, {theme})}>
          {`${theme} (${list?.[theme]?.length ?? 0})`}
        </StyledTheme>
      ))}
    </StyledThemes>
  )
}

Themes.propTypes = {
  active: PropTypes.string,
  countAll: PropTypes.number,
  list: PropTypes.object,
  updateTheme: PropTypes.func.isRequired,
}

Themes.defaultProps = {
  active: 'all',
  countAll: 0,
  list: {},
}

interface FiltersMetricsSelector {
  metricsList: MetricType[]
  updateFilterMetric: (metric: SelectedMetric) => void
}

const FiltersMetricSelector = ({metricsList, updateFilterMetric}: FiltersMetricsSelector) => {
  const [activeTheme, setActiveTheme] = useState('all')
  const [currentList, setCurrentList] = useState(metricsList)

  useEffect(() => {
    if (activeTheme === 'all') setCurrentList(metricsList)
    else setCurrentList(metricsList.filter(m => m.theme.includes(activeTheme)))
  }, [metricsList, activeTheme])

  const updateTheme = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, {theme}: {theme: string}) => {
    e.stopPropagation()
    setActiveTheme(theme)
  }

  return (
    <Row style={{width: '100%', maxHeight: '300px'}}>
      <Themes
        list={_.groupBy(metricsList, 'theme')}
        active={activeTheme}
        countAll={metricsList?.length ?? 0}
        updateTheme={updateTheme}
      />
      <StyledMetricList>
        {currentList.map(metric => (
          <Metric key={metric.id} metric={metric} updateMetric={updateFilterMetric} />
        ))}
      </StyledMetricList>
    </Row>
  )
}

FiltersMetricSelector.propTypes = {
  metricsList: PropTypes.arrayOf(PropTypes.object),
}

FiltersMetricSelector.defaultProps = {
  metricsList: [],
}

export default FiltersMetricSelector
