import React, {Component} from 'react'

import {Col} from 'jsxstyle'
import styled from 'styled-components'

import colors from '../../colors'
import i18n from '../../i18n'
import SeelkButton from './SeelkUIKit/SeelkButton'
import SeelkModal from './SeelkUIKit/SeelkModal'
import PoppersPopup from './PoppersPopup'

const StyledDeleteButton = styled(SeelkButton)`
  &&&& {
    :hover {
      background-color: ${colors.negative.alpha(0.1).rgba};
      border-color: ${colors.negative.rgba};
      color: ${colors.negative.rgba};
    }
  }
`

const UIMessage = styled(Col)`
  & {
    padding: 30px;
    text-align: center;
    align-items: center;
    justify-content: center;
    b {
      color: ${colors.primary.rgba};
      font-style: italic;
    }
    p {
      color: ${colors.darkLight.rgba};
    }
  }
`

interface DeleteItemsProps {
  count: number
  value: string
  confirm: () => void
  popupContent?: string | null
}

interface DeleteItemsState {
  open: boolean
}

class DeleteItems extends Component<DeleteItemsProps, DeleteItemsState> {
  constructor(props: DeleteItemsProps) {
    super(props)
    this.state = {
      open: false,
    }
  }

  render() {
    const {popupContent = null, confirm, count, value} = this.props
    const {open} = this.state
    return (
      <SeelkModal
        trigger={
          <PoppersPopup
            content={popupContent}
            position="bottom right"
            disabled={!popupContent}
            trigger={
              <div>
                <StyledDeleteButton
                  icon="trash alternate"
                  type="icon"
                  disabled={count === 0}
                  onClick={() => this.setState({open: true})}
                />
              </div>
            }
          />
        }
        open={open}
        onClose={() => this.setState({open: false})}
        header={{
          icon: 'trash',
          content: i18n.t('settings.delete.header', {value}),
        }}
        rightActions={[
          <SeelkButton
            content={i18n.t('actions.global.cancel')}
            type="cancel"
            onClick={() => this.setState({open: false})}
          />,
          <SeelkButton
            content={i18n.t('actions.global.delete')}
            icon="trash alternate"
            iconPosition="right"
            type="delete"
            onClick={() => {
              confirm()
              this.setState({open: false})
            }}
          />,
        ]}
      >
        <UIMessage>
          <h3>
            {i18n.t('settings.delete.title')}
            <b>{` ${count} ${value}`}</b>
          </h3>
          <p>{i18n.t('settings.delete.warning', {value, count})}</p>
        </UIMessage>
      </SeelkModal>
    )
  }
}

export default DeleteItems
