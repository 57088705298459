import React from 'react'

import styled from 'styled-components'

import COUNTRIES from '../../../../config/countries.json'
import {capitalize} from '../..'
import theme from '../../theme'

const StyledCountryName = styled.div`
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
`

type ItemType = {
  label: string
}

type ExtraType = {
  onClick?: ({event, value}: {event: React.MouseEvent<HTMLElement>; value: string}) => void
}

interface CountryNameProps {
  item: ItemType
  extra: ExtraType
}

const CountryName = ({item, extra = {}}: CountryNameProps) => (
  <StyledCountryName
    onClick={event => {
      if (extra.onClick) extra.onClick({event, value: item.label})
    }}
    className={theme.using(theme.interactiveUnderline)}
  >
    {capitalize(COUNTRIES[item.label.toUpperCase() as keyof typeof COUNTRIES])}
  </StyledCountryName>
)

export default CountryName
