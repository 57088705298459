import {easyFetch} from 'easyfetch'

import config from '../../../../config'
import KaioApiClient from '../KaioApiClient/KaioApiClient'

const MRSATAN_API_V3 = `https://${config.mrsatanDomain}/api/v3`

export default class MrSatanApiClient {
  constructor() {
    if (!MrSatanApiClient.instance) {
      MrSatanApiClient.instance = this
    }
    return MrSatanApiClient.instance
  }

  async request(rType, url) {
    const api = MRSATAN_API_V3
    const req = rType(`${api}/${url}`)
    const token = await new KaioApiClient().getToken()
    req.setHeaders({Authorization: `Bearer ${token}`})
    return req
  }

  async fileToJson(file, body = null) {
    const data = new FormData()
    data.append('file', file)
    if (body) data.append('body', JSON.stringify({...body}))
    const response = await (await this.request(easyFetch, 'flatfile/parser/file_to_json/'))
      .post(data)
      .then(resp => resp.json().then(respJson => respJson.data[0]))
    return response
  }

  async generateExcel(data) {
    const token = await new KaioApiClient().getToken()
    const response = await (
      await this.request(easyFetch, 'flatfile/generator/generate_excel/')
    )
      .setHeaders({Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'})
      .post(JSON.stringify(data))
      .then(resp => resp.blob())
    return response
  }
}
