import {fuzzyWordsSearch} from '..'
import BaseSeries from './BaseSeries'

class KeywordSettingsSeries extends BaseSeries {
  createDimensions() {
    super.createDimensions()
    this.cf.dimensions.keyword = this.cf.data.dimension(v => v.keyword.keyword)
    this.cf.dimensions.scrapingFrequencies = this.cf.data.dimension(v => v.scraping_frequency)
    this.cf.dimensions.countries = this.cf.data.dimension(v => v.keyword.marketplace.split('_')[1])
    this.cf.dimensions.tags = this.cf.data.dimension(v => v.tags)
  }

  updateFilters(values = {}, lockId = 'default') {
    super.updateFilters(values, lockId)
    const {
      title = null,
      keywords = null,
      countries = null,
      scrapingFrequencies = null,
      keywordTags = {slugs: []},
    } = this.filters[lockId]
    this.filterDimension(this.cf.dimensions.scrapingFrequencies, v => {
      if (!scrapingFrequencies) return true
      else if (scrapingFrequencies.includes('null') && (v === null || v === 'null')) return true
      else return scrapingFrequencies.includes(v)
    })
    this.filterDimension(this.cf.dimensions.countries, countries)
    this.filterDimension(
      this.cf.dimensions.keyword,
      title && typeof title === 'string' ? v => fuzzyWordsSearch(title, v) : null
    )
    this.filterDimension(this.cf.dimensions.keyword, keywords)
    this.filterDimension(this.cf.dimensions.tags, v => {
      if (keywordTags.slugs && !keywordTags.slugs.length) {
        return true
      } else if (keywordTags.matchingType === 'AND') {
        if (keywordTags.slugs.length === 1 && keywordTags.slugs[0] === 'untagged_keywords' && !v.length) return true
        return !keywordTags.slugs.some(t => !v.includes(t))
      } else if (keywordTags.matchingType === 'OR') {
        if (!v.length && keywordTags.slugs.includes('untagged_keywords')) return true
        return v.some(t => keywordTags.slugs.includes(t))
      }
      return false
    })
  }
}

export default KeywordSettingsSeries
