import {ofType} from 'redux-observable'
import {filter} from 'rxjs/operators'

import {get} from '../../utils'
import track from '../track'

export default function (tracker, tenant) {
  const customTracker = (module, action, params, payload, value) => {
    const extraParams = {}
    params.forEach(param => {
      const paramValue = get(payload, param, '')
      const paramName = param.split('.').pop()
      extraParams[paramName] = paramValue
    }) // eslint-disable-line no-return-assign
    if (value) extraParams.value = value
    track(module, {action_type: 'CRUD', tenant, action, ...extraParams})
  }

  const trackCampaignBuilder =
    (action, params = []) =>
    (payload, value = '') =>
      customTracker('Campaign Builder', action, params, payload, value)

  const trackSearchTermsAudit =
    (action, params = []) =>
    (payload, value = '') =>
      customTracker('Search Terms Audit', action, params, payload, value)

  const createFiltersStream = (type, trackers) =>
    tracker
      .reduxActionStream()
      .pipe(
        ofType(type),
        filter(x => x)
      )
      .subscribe(payload => trackers(payload))

  // const createFailSuccessFiltersStream = (type, trackers) =>
  //   tracker
  //     .reduxActionStream()
  //     .ofType(`${type}_SUCCESS`, `${type}_FAIL`)
  //     .filterIdentity()
  //     .subscribe(payload => trackers(payload, payload.type.split('_').pop()))

  createFiltersStream(
    'CAMPAIGN-BUILDER_IMPORT',
    trackCampaignBuilder('Import Campaign Builder', ['frameId', 'marketplace', 'keywordLimit'])
  )

  createFiltersStream(
    'SEARCH_TERMS_AUDIT_IMPORT',
    trackSearchTermsAudit('Import Search Terms Audit', ['marketplace', 'reportDate', 'reportName', 'period'])
  )
}
