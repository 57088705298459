import React from 'react'

import {Row} from 'jsxstyle'

import i18n from '../../../../../i18n'
import FilterButton from '../../../FilterButton'
import VirtualFilterSearch from '../../../inputs/VirtualFilterSearch'
import InNotIn from '../common/InNotIn'

interface AsinsProps {
  choices: string[]
  data: string[]
  onApply: () => void
  onChange: () => void
  onRemove: () => void
  title: string
  disabled?: boolean
  icon?: string | null
  operator?: 'in' | 'not_in'
  updateOperator?: (() => void) | null
}

const Asins = ({
  choices,
  data,
  onApply,
  onChange,
  onRemove,
  title,
  updateOperator,
  disabled = false,
  icon = null,
  operator = 'in',
  ...barItemProps
}: AsinsProps) => {
  const asinsCount = data.length
  const trigger = (
    <FilterButton
      title={asinsCount ? title : i18n.t('filters.asinsTitle')}
      icon={icon}
      disabled={disabled}
      onRemove={onRemove}
      onApply={onApply}
      dropdown={undefined}
    >
      <Row alignItems="center" marginLeft="8px">
        {asinsCount && updateOperator ? <InNotIn operator={operator} /> : null}
        {asinsCount ? `(${asinsCount})` : null}
      </Row>
    </FilterButton>
  )

  return (
    <VirtualFilterSearch
      data={data}
      onChange={onChange}
      onRemove={onRemove}
      title={title}
      choices={choices}
      disabled={disabled}
      onApply={onApply}
      placeholder={i18n.t('actions.search.asin')}
      trigger={trigger}
      operator={operator}
      updateOperator={updateOperator}
      {...barItemProps}
    />
  )
}

Asins.count = (data: string[]) => data.length

export default Asins
