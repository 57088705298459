import React from 'react'

import PropTypes from 'prop-types'
import {Form} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../utils/colors'
import {SeelkButton, SeelkModal} from '../../utils/common-ui'
import i18n from '../../utils/i18n'

// import DeleteItems from '../DeleteItems'

const UIContent = styled.div`
  flex: 1 0 auto;
  margin: 10px 0;
`

const StyledInput = styled(Form.Input)`
  &&&&& {
    width: 100%;
    > label {
      font-size: 12px;
      color: ${colors.darkLight.rgba};
      font-weight: 400;
    }
  }
`

class AdvertisersModal extends React.Component {
  static propTypes = {
    advertisers: PropTypes.object.isRequired,
    id: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    updateAdvertiser: PropTypes.func.isRequired,
  }

  static defaultProps = {
    id: null,
    open: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      advertiserName: '',
      advertiserId: '',
      error: null,
    }
  }

  onClose = () => {
    this.setState({advertiserName: '', advertiserId: '', error: null})
    this.props.onClose()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {id, advertisers} = nextProps
    if (nextProps.id !== this.props.id)
      this.setState({
        advertiserName: advertisers?.[id]?.advertiser_name ?? '',
        advertiserId: advertisers?.[id]?.advertiser_id ?? '',
      })
  }

  handleUpdate = () => {
    const {advertisers, id, updateAdvertiser} = this.props
    const {advertiserName, advertiserId} = this.state
    const listIds = Object.values(advertisers).map(({advertiser_id}) => advertiser_id) // eslint-disable-line camelcase
    if (!id && listIds.includes(advertiserId)) this.setState({error: i18n.t('settings.management.advertisers.errorId')})
    else {
      updateAdvertiser([
        {id, advertiser_name: advertiserName.length ? advertiserName : null, advertiser_id: advertiserId},
      ])
      this.onClose()
    }
  }

  render() {
    const {advertisers, id, open} = this.props
    const {error, advertiserName, advertiserId} = this.state
    return (
      <SeelkModal
        header={{
          icon: 'tags',
          content:
            id !== null
              ? i18n.t('settings.management.advertisers.edit', {name: advertisers?.[id]?.advertiser_id ?? ''})
              : i18n.t('settings.management.advertisers.add'),
        }}
        open={open}
        onClose={this.onClose}
        rightActions={[
          <SeelkButton
            content={i18n.t('actions.global.save')}
            icon="save"
            iconPosition="left"
            onClick={this.handleUpdate}
            type="action"
            disabled={!advertiserId?.length || error !== null}
          />,
        ]}
        size="small"
      >
        <UIContent>
          <Form>
            <StyledInput
              placeholder={i18n.t('settings.management.advertisers.placeholderName')}
              label={i18n.t('visualization.columns.name')}
              value={advertiserName}
              onChange={(e, {value}) => this.setState({advertiserName: value, error: null})}
            />
            {id === null ? (
              <StyledInput
                placeholder={i18n.t('settings.management.advertisers.placeholderId')}
                label={i18n.t('settings.management.advertisers.id')}
                value={advertiserId}
                onChange={(e, {value}) => this.setState({advertiserId: value, error: null})}
                error={this.state.error}
              />
            ) : null}
          </Form>
        </UIContent>
      </SeelkModal>
    )
  }
}

export default AdvertisersModal
