import {push} from 'connected-react-router'
import {Reliever} from 'react-redux-reliever'
import {ofType} from 'redux-observable'
import {catchError, map, mergeMap, tap} from 'rxjs/operators'

import {decodeQueryParams} from '../utils'
import {KaioApi} from '../utils/apis'
import {getState, mapAsync} from '../utils/extensions/rx'
import i18n from '../utils/i18n'

class LoginReliever extends Reliever {
  ACTION_PREFIX = 'LOGIN'

  getInitialState() {
    return {
      error: {
        non_field_errors: [],
        password: [],
        email: [],
      },
    }
  }

  loginRequestEpic(action$) {
    return action$.pipe(
      ofType('LOGIN_REQUEST'),
      mergeMap(action =>
        KaioApi('requestToken', action.email, action.password).pipe(
          map(() => ({type: 'LOGIN_SUCCESS', _reqWrapper: action._reqWrapper})),
          tap(() => action._reqWrapper.end()),
          catchError(err => {
            return mapAsync(err.response.json()).map(result => {
              const ERRORS = [
                'invalid_credentials',
                'user_account_disabled',
                'credentials_not_provided',
                'user_not_found',
                'password',
                'email',
              ]
              const keyError = Object.keys(result) && Object.keys(result)[0] ? Object.keys(result)[0] : ''
              if (ERRORS.includes(keyError)) {
                return action._reqWrapper.fail({error: i18n.t(`login.errors.${keyError}`)})
              } else {
                return action._reqWrapper.fail({error: err})
              }
            })
          })
        )
      )
    )
  }

  loginSuccessEpic(action$) {
    return action$.pipe(
      ofType('LOGIN_SUCCESS'),
      mergeMap(action =>
        getState().pipe(
          map(state => {
            if (state.router.location.search) {
              const params = decodeQueryParams(state.router.location.search)
              return params.next || '/'
            }
            if (state.router.location.pathname === '/login') {
              return '/'
            }
            return state.router.location.pathname
          }),
          map(next => push(next)),
          tap(() => action._reqWrapper.end())
        )
      )
    )
  }
}

export default LoginReliever
