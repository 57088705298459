import React from 'react'

import {Label} from 'semantic-ui-react'

import {clamp} from '../..'
import colors from '../../colors'

interface SeelkTagLabelProps {
  color?: string | null
  name: string
  slug: string
  tagSize?: number | null
  textColor?: string
}

const SeelkTagLabel = ({
  color = null,
  name,
  slug,
  tagSize = null,
  textColor = colors.white.rgba,
}: SeelkTagLabelProps) => {
  return (
    <Label
      key={slug}
      value={slug}
      style={{
        backgroundColor: color,
        color: textColor,
        padding: '4px 6px',
        margin: '4px 0 4px 2px',
        whiteSpace: 'nowrap',
      }}
    >
      {name ? clamp(name, tagSize ?? undefined) : ''}
    </Label>
  )
}

export default SeelkTagLabel
