import React from 'react'

import {Col, Row} from 'jsxstyle'
import styled from 'styled-components'

import colors from '../../colors'
import i18n from '../../i18n'

const StyledKeyword = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  color: ${colors.dark.rgba};
  i {
    margin-left: 10px;
    font-size: 12px;
    visibility: hidden;
  }
  :hover {
    text-decoration: underline;
    cursor: pointer;
    color: ${colors.primary.rgba};
    i {
      visibility: visible;
    }
  }
`

const UIValueContainer = styled.span`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const UIKeywordContainer = styled.div`
  display: flex;
`

type TagType = {
  name: string
  slug: string
  color: string
}

type ItemType = {
  tags?: string[]
  marketplace?: string
  text?: string
}

type ExtraType = {
  onClick?: (item: ItemType) => void
  tagList?: {[slug: string]: TagType}
  displayTagsModal?: ((item: ItemType) => void) | null
}

interface KeywordProps {
  extra?: ExtraType
  item: ItemType
  value?: string | null
}

const SearchTerm = ({value = null, item, extra = {}}: KeywordProps) => {
  const {onClick = null} = extra
  return (
    <Row maxWidth="100%" alignItems="center" width="100%">
      <Col maxWidth="100%" paddingRight={10} width="100%">
        <UIKeywordContainer>
          <StyledKeyword onClick={() => onClick && onClick(item)}>
            <UIValueContainer>
              {value || i18n.t('placeholders.empty.notAvailable')}
              <i className="fas fa-chevron-right" />
            </UIValueContainer>
          </StyledKeyword>
        </UIKeywordContainer>
      </Col>
    </Row>
  )
}

export default SearchTerm
