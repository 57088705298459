import React, {useEffect, useState} from 'react'

import _ from 'lodash'
import memoizeOne from 'memoize-one'
import {AutoSizer, List, ListRowProps} from 'react-virtualized'
import {Checkbox, Dropdown, DropdownOnSearchChangeData, Flag, FlagNameValues, Icon, Label} from 'semantic-ui-react'
import styled from 'styled-components'

import {betterAMZImage, fuzzyWordsSearch} from '../..'
import colors from '../../colors'
import i18n from '../../i18n'

const StyledDropdown = styled(Dropdown)`
  &&&& {
    display: flex;
    align-items: center;
    padding: 5px 15px;
  }
`

const StyledDropdownMenu = styled(Dropdown.Menu)`
  &&&&& {
    overflow-y: hidden;
    min-height: 300px;
  }
`

const StyledProductRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
  cursor: pointer;
  :hover {
    background-color: ${colors.greyBackground.rgba};
  }
`
const StyledProduct = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  gap: 5px;
`

const StyledTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100vw - 300px);
`

const StyledAsin = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.darkLight.rgba};
  font-size: 12px;
`

const StyledImage = styled.img`
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid ${colors.border.rgba};
`

const StyledImagePlaceholder = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 100%;
  margin-right: 10px;
  margin-left: 10px;
  background-color: ${colors.white.rgba};
  border: 1px solid ${colors.border.rgba};
`

const StyledIcon = styled(Icon)`
  &&& {
    cursor: pointer;
    margin: 0 0 0 10px;
  }
`

const StyledLabel = styled(Label)`
  &&&& {
    margin: 2px;
  }
`

interface OfferType {
  id: string
  [key: string]: any
}

interface OfferSearchSelectProps {
  offers: OfferType | {}
  onRowClick: (id: string) => void
  selectedOffers: string[]
}

const Offer = ({id, removeOffer}: {id: string; removeOffer: (id: string) => void}) => {
  const [asin, marketplace] = id.split(':')
  const country = marketplace.slice(-2)
  return (
    <StyledLabel>
      <Flag name={country as FlagNameValues} />
      {asin}
      <StyledIcon
        className="remove"
        onClick={(e: React.SyntheticEvent<HTMLElement>) => {
          e.preventDefault()
          e.stopPropagation()
          removeOffer(id)
        }}
      />
    </StyledLabel>
  )
}

const OfferSearchSelect = ({onRowClick, offers = {}, selectedOffers}: OfferSearchSelectProps) => {
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)

  const onSearchDebounced = _.debounce((value: string) => setSearch(value), 2000)

  useEffect(() => {
    return onSearchDebounced.cancel()
  }, [onSearchDebounced])

  const resetDropdownState = (open = false) => {
    setOpen(open)
    onSearchDebounced('')
  }

  const getOfferItems = memoizeOne((offers: OfferType | {}, search: string) => {
    return Object.entries(offers ?? {})
      .filter(
        ([, value]) =>
          fuzzyWordsSearch(search, value.title ?? '') ||
          fuzzyWordsSearch(search, value.offer?.asin ?? '') ||
          fuzzyWordsSearch(search, value.asin ?? '')
      )
      .map(([key, value]) => ({
        ...value,
        country: key.slice(-2) ?? null,
      }))
  })

  const getSelectedOffer = () => {
    if (!selectedOffers || open) return null
    return (
      <div>
        {selectedOffers.length
          ? selectedOffers.map((offer: string) => <Offer key={offer} id={offer} removeOffer={onRowClick} />)
          : i18n.t('selectProducts')}
      </div>
    )
  }

  const offerItems = getOfferItems(offers, search)

  const rowRenderer = ({key, index, style}: ListRowProps) => {
    const {id, asin, country, title, image} = offerItems[index]
    return (
      <StyledProductRow
        key={key}
        style={style}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          onRowClick(id)
        }}
      >
        <Checkbox
          checked={selectedOffers.includes(id)}
          onMouseDown={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        />
        {image?.[0] ? <StyledImage src={betterAMZImage(image?.[0])} alt={title} /> : <StyledImagePlaceholder />}

        <StyledProduct>
          <StyledAsin>
            <Flag name={country} />
            {asin}
          </StyledAsin>
          <StyledTitle>{title}</StyledTitle>
        </StyledProduct>
      </StyledProductRow>
    )
  }
  return (
    <StyledDropdown
      fluid
      closeOnChange
      className="selection"
      open={open}
      onClick={() => resetDropdownState(!open)}
      onBlur={() => resetDropdownState(false)}
      search
      text={getSelectedOffer()}
      placeholder={i18n.t('actions.search.productTitleAsin')}
      onSearchChange={(e: React.SyntheticEvent<HTMLElement>, {searchQuery}: DropdownOnSearchChangeData) => [
        setOpen(true),
        onSearchDebounced(searchQuery),
      ]}
    >
      <StyledDropdownMenu>
        <AutoSizer>
          {({height, width}) => (
            <List height={height} rowCount={offerItems.length} rowHeight={70} rowRenderer={rowRenderer} width={width} />
          )}
        </AutoSizer>
      </StyledDropdownMenu>
    </StyledDropdown>
  )
}

export default OfferSearchSelect
