import {ofType} from 'redux-observable'
import {of, race} from 'rxjs'
import {map, mergeMap, take, tap} from 'rxjs/operators'

import i18n from '../../i18n'
import {argsAsArray} from '../../index'
import {RequestWrapper} from '../../requesting/RequestWrapper'
import {getState, getStore, reduxActionStream} from './index'

const requireFetched = function requireFetched(requestWrapper, args = {}, returnValue = true) {
  return getStore().pipe(
    mergeMap(store => {
      if (!requestWrapper.store) requestWrapper.init(store)
      const successAction = `${requestWrapper.fetchAction}_SUCCESS`
      const errorAction = `${requestWrapper.fetchAction}_FAIL`
      const resourcePath = argsAsArray(requestWrapper.resourcePath)

      if (requestWrapper.dataFetched() && returnValue)
        return getState(resourcePath[0]).pipe(map(state => state.getIn(resourcePath.splice(1))))
      else if (requestWrapper.dataFetched()) return of()
      if (requestWrapper.hasFailed()) throw new Error(i18n.t('placeholders.errors.errorCannotBeRetried'))

      if (!requestWrapper.isRequesting()) {
        if (requestWrapper.usecase) requestWrapper.dispatchUsecase(...Object.values(args.payload ?? {}), true)
        else requestWrapper.fetch(args)
      }

      const success$ = reduxActionStream().pipe(
        ofType(successAction),
        take(1),
        mergeMap(() => getState(resourcePath[0]).pipe(map(state => state.getIn(resourcePath.splice(1)))))
      )

      const error$ = reduxActionStream().pipe(
        ofType(errorAction),
        take(1),
        tap(err => {
          throw new Error(err._reqWrapper.error)
        })
      )

      return race(success$, error$)
    })
  )
}

const products = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.products',
      requestingPath: 'catalogue.products.fetch',
      fetchAction: 'CATALOGUE_FETCH_PRODUCTS',
      showError: false,
    }),
    args,
    returnValue
  )

const marketplaces = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'marketplaces.marketplaces',
      requestingPath: 'marketplaces.marketplaces.fetch',
      fetchAction: 'MARKETPLACES_FETCH',
      showError: false,
    }),
    args,
    returnValue
  )

const scrapings = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.scrapingsData',
      requestingPath: 'catalogue.scrapingsData.fetch',
      fetchAction: 'CATALOGUE_FETCH_SCRAPINGS',
      showError: false,
    }),
    args,
    returnValue
  )

const extraScrapings = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.extraScrapingsData',
      requestingPath: 'catalogue.extraScrapingsData.fetch',
      fetchAction: 'CATALOGUE_FETCH_EXTRA_SCRAPINGS',
      showError: false,
    }),
    args,
    returnValue
  )

const detachedScrapings = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.detachedScrapingsData',
      requestingPath: 'catalogue.detachedScrapingsData.fetch',
      fetchAction: 'CATALOGUE_FETCH_DETACHED_SCRAPINGS',
      showError: false,
    }),
    args,
    returnValue
  )

const competitorsScrapings = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.competitorsScrapingsData',
      requestingPath: 'catalogue.competitorsScrapingsData.fetch',
      fetchAction: 'CATALOGUE_FETCH_COMPETITORS_SCRAPINGS',
      showError: false,
    }),
    args,
    returnValue
  )

const archivedScrapings = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.archivedScrapingsData',
      requestingPath: 'catalogue.archivedScrapingsData.fetch',
      fetchAction: 'CATALOGUE_FETCH_ARCHIVED_SCRAPINGS',
      showError: false,
    }),
    args,
    returnValue
  )

const archivedExtraScrapings = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.archivedExtraScrapingsData',
      requestingPath: 'catalogue.archivedExtraScrapingsData.fetch',
      fetchAction: 'CATALOGUE_FETCH_ARCHIVED_EXTRA_SCRAPINGS',
      showError: false,
    }),
    args,
    returnValue
  )

const archivedDetachedScrapings = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.archivedDetachedScrapingsData',
      requestingPath: 'catalogue.archivedDetachedScrapingsData.fetch',
      fetchAction: 'CATALOGUE_FETCH_ARCHIVED_DETACHED_SCRAPINGS',
      showError: false,
    }),
    args,
    returnValue
  )

const archivedCompetitorsScrapings = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.archivedCompetitorsScrapingsData',
      requestingPath: 'catalogue.archivedCompetitorsScrapingsData.fetch',
      fetchAction: 'CATALOGUE_FETCH_ARCHIVED_COMPETITORS_SCRAPINGS',
      showError: false,
    }),
    args,
    returnValue
  )

const referenceContent = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.referenceContentData',
      requestingPath: 'catalogue.referenceContentData.fetch',
      fetchAction: 'CATALOGUE_FETCH_REFERENCE_CONTENT',
      showError: false,
    }),
    args,
    returnValue
  )

const categorySettings = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogueCategories.categorySettings',
      requestingPath: 'catalogueCategories.categorySettings.fetch',
      fetchAction: 'CATALOGUE-CATEGORIES_FETCH_CATEGORY_SETTINGS',
      showError: false,
    }),
    args,
    returnValue
  )

const offers = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.offersData',
      requestingPath: 'catalogue.offersData.fetch',
      fetchAction: 'CATALOGUE_FETCH_SECTION_OFFERS',
      showError: false,
    }),
    args,
    returnValue
  )

const offerSettings = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.offerSettingsData',
      requestingPath: 'catalogue.offerSettingsData.fetch',
      fetchAction: 'CATALOGUE_FETCH_OFFER_SETTINGS',
      showError: false,
    }),
    args,
    returnValue
  )

const extraOffers = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.extraOffersData',
      requestingPath: 'catalogue.extraOffersData.fetch',
      fetchAction: 'CATALOGUE_FETCH_EXTRA_OFFERS',
      showError: false,
    }),
    args,
    returnValue
  )

const detachedOffers = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.detachedOffersData',
      requestingPath: 'catalogue.detachedOffersData.fetch',
      fetchAction: 'CATALOGUE_FETCH_DETACHED_OFFERS',
      showError: false,
    }),
    args,
    returnValue
  )

const competitorsOffers = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogue.competitorsOffersData',
      requestingPath: 'catalogue.competitorsOffersData.fetch',
      fetchAction: 'CATALOGUE_FETCH_COMPETITORS_OFFERS',
      showError: false,
    }),
    args,
    returnValue
  )

const keywordSettings = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogueKeywords.keywordSettings',
      requestingPath: 'catalogueKeywords.keywordSettings.fetch',
      fetchAction: 'CATALOGUE-KEYWORDS_FETCH_KEYWORD_SETTINGS',
      showError: false,
    }),
    args,
    returnValue
  )

const senzuKeywords = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'catalogueKeywords.senzuKeywords',
      requestingPath: 'catalogueKeywords.senzuKeywords.fetch',
      fetchAction: 'CATALOGUE-KEYWORDS_FETCH_KEYWORD_SETTINGS',
      showError: false,
    }),
    args,
    returnValue
  )

const attributesValues = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'attributes.values',
      requestingPath: 'attributes.values.fetch',
      fetchAction: 'ATTRIBUTES_FETCH_ATTRIBUTES_VALUES',
      showError: false,
    }),
    args,
    returnValue
  )

const attributesDefinition = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'attributes.definitions',
      requestingPath: 'attributes.definitions.fetch',
      fetchAction: 'ATTRIBUTES_FETCH_ATTRIBUTES_DEFINITION',
      showError: false,
    }),
    args,
    returnValue
  )

const preferences = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'settings.preferences',
      requestingPath: 'settings.preferences.fetch',
      fetchAction: 'SETTINGS_FETCH_PREFERENCES',
      showError: false,
    }),
    args,
    returnValue
  )

const accountSettings = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'settings.accountSettings',
      requestingPath: 'settings.accountSettings.fetch',
      fetchAction: 'SETTINGS_FETCH_SETTINGS',
      showError: false,
    }),
    args,
    returnValue
  )

const users = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'settings.users',
      requestingPath: 'settings.users.fetch',
      fetchAction: 'SETTINGS_FETCH_USERS',
      showError: false,
    }),
    args,
    returnValue
  )

const offerTags = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.offerTags',
      requestingPath: 'collections.offerTags.fetch',
      fetchAction: 'COLLECTIONS_FETCH_TAGS',
      showError: false,
    }),
    {...args, dimension: 'OFFER'},
    returnValue
  )

const taggedOffers = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.offerTagPivots',
      requestingPath: 'collections.offerTagPivots.fetch',
      fetchAction: 'COLLECTIONS_FETCH_TAGGED_ITEMS',
      showError: false,
    }),
    {...args, dimension: 'OFFER'},
    returnValue
  )

const offerCollections = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.offerCollections',
      requestingPath: 'collections.offerCollections.fetch',
      fetchAction: 'COLLECTIONS_FETCH_COLLECTIONS',
      showError: false,
    }),
    {...args, dimension: 'OFFER'},
    returnValue
  )

const offerGroups = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.offerGroups',
      requestingPath: 'collections.offerGroups.fetch',
      fetchAction: 'COLLECTIONS_FETCH_GROUPS',
      showError: false,
    }),
    {...args, dimension: 'OFFER'},
    returnValue
  )

const attributedOffers = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.offerGroupPivots',
      requestingPath: 'collections.offerGroupPivots.fetch',
      fetchAction: 'COLLECTIONS_FETCH_ATTRIBUTED_ITEMS',
      showError: false,
    }),
    {...args, dimension: 'OFFER'},
    returnValue
  )

const keywordTags = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.keywordTags',
      requestingPath: 'collections.keywordTags.fetch',
      fetchAction: 'COLLECTIONS_FETCH_KEYWORD_TAGS',
      showError: false,
    }),
    args,
    returnValue
  )

const alertScenarios = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'SETTINGS_FETCH_ALERT_SCENARIOS',
      resourcePath: 'settings.alertScenariosData',
      requestingPath: 'settings.alertScenariosData.fetch',
      showError: false,
    }),
    args,
    returnValue
  )

const profiles = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'advertising.profiles',
      requestingPath: 'advertising.profiles.fetch',
      fetchAction: 'ADVERTISING_FETCH_PROFILES',
      showError: false,
    }),
    args,
    returnValue
  )

const campaigns = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'advertising.campaigns',
      requestingPath: 'advertising.campaigns.fetch',
      fetchAction: 'ADVERTISING_FETCH_CAMPAIGNS',
      showError: false,
    }),
    args,
    returnValue
  )

const campaignTags = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_TAGS',
      resourcePath: 'collections.campaignTags',
      requestingPath: 'collections.campaignTags.fetch',
      showError: false,
    }),
    {...args, dimension: 'CAMPAIGN'},
    returnValue
  )

const taggedCampaigns = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.campaignTagPivots',
      requestingPath: 'collections.campaignTagPivots.fetch',
      fetchAction: 'COLLECTIONS_FETCH_TAGGED_ITEMS',
      showError: false,
    }),
    {...args, dimension: 'CAMPAIGN'},
    returnValue
  )

const campaignCollections = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_COLLECTIONS',
      resourcePath: 'collections.campaignCollections',
      requestingPath: 'collections.campaignCollections.fetch',
      showError: false,
    }),
    {...args, dimension: 'CAMPAIGN'},
    returnValue
  )

const campaignGroups = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_GROUPS',
      resourcePath: 'collections.campaignGroups',
      requestingPath: 'collections.campaignGroups.fetch',
      showError: false,
    }),
    {...args, dimension: 'CAMPAIGN'},
    returnValue
  )

const attributedCampaigns = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.campaignGroupPivots',
      requestingPath: 'collections.campaignGroupPivots.fetch',
      fetchAction: 'COLLECTIONS_FETCH_ATTRIBUTED_ITEMS',
      showError: false,
    }),
    {...args, dimension: 'CAMPAIGN'},
    returnValue
  )

const recentCampaignTags = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_RECENTLY_USED_TAGS',
      resourcePath: 'collections.recentlyUsedCampaignTags',
      requestingPath: 'collections.recentlyUsedCampaignTags.fetch',
      showError: false,
    }),
    {...args, dimension: 'CAMPAIGN'},
    returnValue
  )

const adGroups = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'advertising.adGroups',
      requestingPath: 'advertising.adGroups.fetch',
      fetchAction: 'ADVERTISING_FETCH_AD_GROUPS',
      showError: false,
    }),
    args,
    returnValue
  )

const adGroupTags = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_TAGS',
      resourcePath: 'collections.adGroupTags',
      requestingPath: 'collections.adGroupTags.fetch',
      showError: false,
    }),
    {...args, dimension: 'AD_GROUP'},
    returnValue
  )

const taggedAdGroups = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.adGroupTagPivots',
      requestingPath: 'collections.adGroupTagPivots.fetch',
      fetchAction: 'COLLECTIONS_FETCH_TAGGED_ITEMS',
      showError: false,
    }),
    {...args, dimension: 'AD_GROUP'},
    returnValue
  )

const adGroupCollections = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_COLLECTIONS',
      resourcePath: 'collections.adGroupCollections',
      requestingPath: 'collections.adGroupCollections.fetch',
      showError: false,
    }),
    {...args, dimension: 'AD_GROUP'},
    returnValue
  )

const adGroupGroups = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_GROUPS',
      resourcePath: 'collections.adGroupGroups',
      requestingPath: 'collections.adGroupGroups.fetch',
      showError: false,
    }),
    {...args, dimension: 'AD_GROUP'},
    returnValue
  )

const attributedAdGroups = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.adGroupGroupPivots',
      requestingPath: 'collections.adGroupGroupPivots.fetch',
      fetchAction: 'COLLECTIONS_FETCH_ATTRIBUTED_ITEMS',
      showError: false,
    }),
    {...args, dimension: 'AD_GROUP'},
    returnValue
  )

const recentAdGroupTags = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_RECENTLY_USED_TAGS',
      resourcePath: 'collections.recentlyUsedAdGroupTags',
      requestingPath: 'collections.recentlyUsedAdGroupTags.fetch',
      showError: false,
    }),
    {...args, dimension: 'AD_GROUP'},
    returnValue
  )

const tenant = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'SETTINGS_FETCH_TENANT',
      resourcePath: 'settings.tenant',
      requestingPath: 'settings.tenant.fetch',
      showError: false,
    }),
    args,
    returnValue
  )

const metrics = (args, reqWrapperArgs, returnValue = true) =>
  requireFetched(new RequestWrapper(reqWrapperArgs), args, returnValue)

const dashboards = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'DASHBOARDS_FETCH_DASHBOARDS',
      resourcePath: 'dashboards.dashboards',
      requestingPath: 'dashboards.dashboards.fetch',
      showError: false,
    }),
    args,
    returnValue
  )

const templates = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'DASHBOARDS_FETCH_TEMPLATES',
      resourcePath: 'dashboards.templates',
      requestingPath: 'dashboards.templates.fetch',
      showError: false,
    }),
    args,
    returnValue
  )

const dsp = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'ADVERTISING_FETCH_DSP',
      resourcePath: 'advertising.dspData',
      requestingPath: 'advertising.dspData.fetch',
      showError: false,
    }),
    args,
    returnValue
  )

const advertisers = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'advertising.advertisers',
      requestingPath: 'advertising.advertisers.fetch',
      fetchAction: 'ADVERTISING_FETCH_ADVERTISERS',
      showError: false,
    }),
    args,
    returnValue
  )

const advertiserTags = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_TAGS',
      resourcePath: 'collections.advertiserTags',
      requestingPath: 'collections.advertiserTags.fetch',
      showError: false,
    }),
    {...args, dimension: 'ADVERTISER'},
    returnValue
  )

const taggedAdvertisers = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.advertiserTagPivots',
      requestingPath: 'collections.advertiserTagPivots.fetch',
      fetchAction: 'COLLECTIONS_FETCH_TAGGED_ITEMS',
      showError: false,
    }),
    {...args, dimension: 'ADVERTISER'},
    returnValue
  )

const advertiserCollections = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_COLLECTIONS',
      resourcePath: 'collections.advertiserCollections',
      requestingPath: 'collections.advertiserCollections.fetch',
      showError: false,
    }),
    {...args, dimension: 'ADVERTISER'},
    returnValue
  )

const advertiserGroups = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_GROUPS',
      resourcePath: 'collections.advertiserGroups',
      requestingPath: 'collections.advertiserGroups.fetch',
      showError: false,
    }),
    {...args, dimension: 'ADVERTISER'},
    returnValue
  )

const attributedAdvertisers = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.advertiserGroupPivots',
      requestingPath: 'collections.advertiserGroupPivots.fetch',
      fetchAction: 'COLLECTIONS_FETCH_ATTRIBUTED_ITEMS',
      showError: false,
    }),
    {...args, dimension: 'ADVERTISER'},
    returnValue
  )

const orders = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'advertising.orders',
      requestingPath: 'advertising.orders.fetch',
      fetchAction: 'ADVERTISING_FETCH_ORDERS',
      showError: false,
    }),
    args,
    returnValue
  )

const orderTags = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_TAGS',
      resourcePath: 'collections.orderTags',
      requestingPath: 'collections.orderTags.fetch',
      showError: false,
    }),
    {...args, dimension: 'ORDER'},
    returnValue
  )

const taggedOrders = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.orderTagPivots',
      requestingPath: 'collections.orderTagPivots.fetch',
      fetchAction: 'COLLECTIONS_FETCH_TAGGED_ITEMS',
      showError: false,
    }),
    {...args, dimension: 'ORDER'},
    returnValue
  )

const orderCollections = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_COLLECTIONS',
      resourcePath: 'collections.orderCollections',
      requestingPath: 'collections.orderCollections.fetch',
      showError: false,
    }),
    {...args, dimension: 'ORDER'},
    returnValue
  )

const orderGroups = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_GROUPS',
      resourcePath: 'collections.orderGroups',
      requestingPath: 'collections.orderGroups.fetch',
      showError: false,
    }),
    {...args, dimension: 'ORDER'},
    returnValue
  )

const attributedOrders = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.orderGroupPivots',
      requestingPath: 'collections.orderGroupPivots.fetch',
      fetchAction: 'COLLECTIONS_FETCH_ATTRIBUTED_ITEMS',
      showError: false,
    }),
    {...args, dimension: 'ORDER'},
    returnValue
  )

const lineItems = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'advertising.lineItems',
      requestingPath: 'advertising.lineItems.fetch',
      fetchAction: 'ADVERTISING_FETCH_LINE_ITEMS',
      showError: false,
    }),
    args,
    returnValue
  )

const lineItemTags = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_TAGS',
      resourcePath: 'collections.lineItemTags',
      requestingPath: 'collections.lineItemTags.fetch',
      showError: false,
    }),
    {...args, dimension: 'LINE_ITEM'},
    returnValue
  )

const taggedLineItems = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.lineItemTagPivots',
      requestingPath: 'collections.lineItemTagPivots.fetch',
      fetchAction: 'COLLECTIONS_FETCH_TAGGED_ITEMS',
      showError: false,
    }),
    {...args, dimension: 'LINE_ITEM'},
    returnValue
  )

const lineItemCollections = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_COLLECTIONS',
      resourcePath: 'collections.lineItemCollections',
      requestingPath: 'collections.lineItemCollections.fetch',
      showError: false,
    }),
    {...args, dimension: 'LINE_ITEM'},
    returnValue
  )

const lineItemGroups = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_GROUPS',
      resourcePath: 'collections.lineItemGroups',
      requestingPath: 'collections.lineItemGroups.fetch',
      showError: false,
    }),
    {...args, dimension: 'LINE_ITEM'},
    returnValue
  )

const attributedLineItems = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.lineItemGroupPivots',
      requestingPath: 'collections.lineItemGroupPivots.fetch',
      fetchAction: 'COLLECTIONS_FETCH_ATTRIBUTED_ITEMS',
      showError: false,
    }),
    {...args, dimension: 'LINE_ITEM'},
    returnValue
  )

const creatives = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'advertising.creatives',
      requestingPath: 'advertising.creatives.fetch',
      fetchAction: 'ADVERTISING_FETCH_CREATIVES',
      showError: false,
    }),
    args,
    returnValue
  )

const creativeTags = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_TAGS',
      resourcePath: 'collections.creativeTags',
      requestingPath: 'collections.creativeTags.fetch',
      showError: false,
    }),
    {...args, dimension: 'CREATIVE'},
    returnValue
  )

const taggedCreatives = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.creativeTagPivots',
      requestingPath: 'collections.creativeTagPivots.fetch',
      fetchAction: 'COLLECTIONS_FETCH_TAGGED_ITEMS',
      showError: false,
    }),
    {...args, dimension: 'CREATIVE'},
    returnValue
  )

const creativeCollections = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_COLLECTIONS',
      resourcePath: 'collections.creativeCollections',
      requestingPath: 'collections.creativeCollections.fetch',
      showError: false,
    }),
    {...args, dimension: 'CREATIVE'},
    returnValue
  )

const creativeGroups = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      fetchAction: 'COLLECTIONS_FETCH_GROUPS',
      resourcePath: 'collections.creativeGroups',
      requestingPath: 'collections.creativeGroups.fetch',
      showError: false,
    }),
    {...args, dimension: 'CREATIVE'},
    returnValue
  )

const attributedCreatives = (args, returnValue = true) =>
  requireFetched(
    new RequestWrapper({
      resourcePath: 'collections.creativeGroupPivots',
      requestingPath: 'collections.creativeGroupPivots.fetch',
      fetchAction: 'COLLECTIONS_FETCH_ATTRIBUTED_ITEMS',
      showError: false,
    }),
    {...args, dimension: 'CREATIVE'},
    returnValue
  )

export default Object.assign(requireFetched, {
  products,
  marketplaces,
  scrapings,
  extraScrapings,
  detachedScrapings,
  competitorsScrapings,
  archivedScrapings,
  archivedExtraScrapings,
  archivedDetachedScrapings,
  archivedCompetitorsScrapings,
  referenceContent,
  offers,
  offerSettings,
  extraOffers,
  detachedOffers,
  competitorsOffers,
  categorySettings,
  keywordSettings,
  senzuKeywords,
  attributesValues,
  attributesDefinition,
  preferences,
  accountSettings,
  offerTags,
  taggedOffers,
  offerCollections,
  offerGroups,
  attributedOffers,
  keywordTags,
  alertScenarios,
  profiles,
  campaigns,
  campaignCollections,
  campaignGroups,
  attributedCampaigns,
  campaignTags,
  taggedCampaigns,
  recentCampaignTags,
  adGroups,
  adGroupCollections,
  adGroupGroups,
  attributedAdGroups,
  adGroupTags,
  taggedAdGroups,
  recentAdGroupTags,
  tenant,
  metrics,
  dashboards,
  templates,
  dsp,
  advertisers,
  advertiserTags,
  taggedAdvertisers,
  advertiserCollections,
  advertiserGroups,
  attributedAdvertisers,
  orders,
  orderTags,
  taggedOrders,
  orderCollections,
  orderGroups,
  attributedOrders,
  lineItems,
  lineItemTags,
  taggedLineItems,
  lineItemCollections,
  lineItemGroups,
  attributedLineItems,
  creatives,
  creativeTags,
  taggedCreatives,
  creativeCollections,
  creativeGroups,
  attributedCreatives,
  users,
})
