import React from 'react'

import {Row} from 'jsxstyle'
import {Icon, Label} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../../../../colors'
import SeelkElevatedPanel from '../../SeelkElevatedPanel'

const StyledSeelkElevatedPanel = styled(SeelkElevatedPanel)`
  &&&& {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`

const ColorCircle = styled(Label)`
  &&& {
    font-size: 11px;
    :hover {
      font-size: 12px;
    }
  }
`

const StyledRow = styled(Row)`
  &&&&& {
    align-items: ${props => props.theme.selectColorPanel.alignItems};
    background-color: ${props => props.theme.selectColorPanel.backgroundColor};
    padding: 0.3em;
    justify-content: center;
    height: 30px;
    width: 30px;
    :hover {
      cursor: ${props => props.theme.selectColorPanel.hoverCursor};
      &&&&& > button {
        font-size: 0.6em;
      }
    }
  }
`

const ColorIcon = styled(Icon)`
  &&&&&& {
    color: white;
  }
`

const ColorNone = styled(Icon)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.light.rgba};
    font-size: 22px;
    :hover {
      font-size: 24px;
      color: ${colors.lightDark.rgba};
    }
  }
`

interface SeelkColorPanelProps {
  onChange: (color: string | null) => void
  options: (string | null)[]
  inline?: boolean
  metricColor?: string | null
  onSelect?: (() => void) | null
}

const SeelkColorPanel = ({
  inline = false,
  options = [],
  metricColor = null,
  onChange,
  onSelect = null,
}: SeelkColorPanelProps) => {
  const handleClick = (value: string | null) => {
    if (value !== metricColor) onChange(value)
    if (onSelect) onSelect()
  }

  const renderColors = () => {
    return options?.map(option => (
      <StyledRow key={option}>
        {option === null ? (
          <ColorNone name="ban" onClick={() => handleClick(null)} />
        ) : (
          <ColorCircle
            circular
            style={{backgroundColor: option}}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              handleClick(option)
              e.stopPropagation()
            }}
          >
            <ColorIcon name="checkmark" style={{display: `${metricColor === option ? 'flex' : 'none'}`}} />
          </ColorCircle>
        )}
      </StyledRow>
    ))
  }

  return inline ? <>{renderColors()}</> : <StyledSeelkElevatedPanel>{renderColors()}</StyledSeelkElevatedPanel>
}

export default SeelkColorPanel
