import React from 'react'

import {Box} from 'jsxstyle'
import {Icon, Menu, Segment} from 'semantic-ui-react'

import colors from '../../../colors'
import {AbsolutePopup} from '../../../common-ui'
import i18n from '../../../i18n'

interface Position {
  x: number
  y: number
}

interface DeepDivePopupProps {
  availableGroupings?: any[]
  client: Position
  filter: string
  grouping: string
  onGroupClicked: (key: string, filter: string, dimension: string) => void
  updateVisible: (visible: boolean) => void
  visible?: boolean
}

const DeepDivePopup = ({
  client,
  grouping,
  onGroupClicked,
  updateVisible,
  filter,
  availableGroupings = [],
  visible = false,
}: DeepDivePopupProps) => {
  return (
    <AbsolutePopup client={client} handleOnOutsideClick={() => updateVisible(false)} visible={visible}>
      <Segment style={{padding: 0}}>
        <Menu compact text vertical>
          {(() => {
            const drilldowns = availableGroupings
            const filteredDrilldowns = drilldowns.filter(v => v.key !== grouping && v.key !== 'countries')
            if (filteredDrilldowns.length === 0) {
              return <Menu.Item>{i18n.t('placeholders.empty.moreOptions')}</Menu.Item>
            }
            return (
              <Box paddingRight={10} paddingLeft={10}>
                <Menu.Item
                  style={{
                    fontWeight: '500',
                    fontStyle: 'italic',
                    color: colors.primary.rgba,
                  }}
                >
                  <Icon name="sitemap" />
                  {i18n.t('visualization.grouping.deepDiveBy')}
                </Menu.Item>
                {filteredDrilldowns.map(v => (
                  <Menu.Item
                    key={v.key}
                    onClick={() => {
                      onGroupClicked(v.key, filter, v?.dimension ?? v.key)
                      updateVisible(false)
                    }}
                    disabled={v.disabled}
                  >
                    • {v.text}
                  </Menu.Item>
                ))}
              </Box>
            )
          })()}
        </Menu>
      </Segment>
    </AbsolutePopup>
  )
}

export default DeepDivePopup
