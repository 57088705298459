import colors from '../../colors'

const theme = {
  button: {
    action: {
      backgroundColor: colors.primary.rgba,
      border: 'none',
      color: colors.white.rgba,
      hoverBackgroundColor: colors.primaryHover.rgba,
      opacityDisabled: 0.75,
      pointerEventsDisabled: 'none',
    },
    back: {
      backgroundColor: colors.greyBackground.rgba,
      border: 'none',
      color: colors.darkLight.rgba,
      hoverBackgroundColor: colors.border.rgba,
      opacityDisabled: 0.75,
      pointerEventsDisabled: 'none',
    },
    base: {
      borderRadius: '0.286em',
      fontWeight: 500,
      height: '2.571em',
      display: 'flex',
      padding: 0,
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: '1.286em',
      paddingRight: '1.286em',
      icon: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    borderless: {
      backgroundColor: 'transparent',
      border: 'none',
      color: colors.primary.rgba,
      hoverBackgroundColor: colors.light.alpha(0.25).rgba,
      opacityDisabled: 0.75,
      pointerEventsDisabled: 'none',
    },
    primaryBordered: {
      backgroundColor: colors.white.rgba,
      border: `0.071em solid ${colors.primary.rgba} !important`,
      hoverBorder: `0.071em solid ${colors.secondary.rgba} !important`,
      color: colors.primary.rgba,
      hoverBackgroundColor: colors.primaryLight.rgba,
      hoverColor: colors.primary.rgba,
      opacityDisabled: 0.75,
      pointerEventsDisabled: 'none',
    },
    cancel: {
      backgroundColor: colors.white.rgba,
      border: '0.071em solid',
      borderColor: colors.border.rgba,
      color: colors.darkLight.rgba,
      hoverBackgroundColor: colors.greyBackground.rgba,
      opacityDisabled: 0.75,
      pointerEventsDisabled: 'none',
    },
    confirm: {
      backgroundColor: colors.primary.rgba,
      border: 'none',
      color: colors.white.rgba,
      hoverBackgroundColor: colors.primaryHover.rgba,
      opacityDisabled: 0.75,
      pointerEventsDisabled: 'none',
    },
    delete: {
      backgroundColor: colors.white.rgba,
      border: '0.071em solid',
      borderColor: colors.negative.rgba,
      color: colors.negative.rgba,
      hoverBackgroundColor: colors.negative.rgba,
      hoverBorder: 'none',
      hoverColor: colors.white.rgba,
      opacityDisabled: 0.75,
      pointerEventsDisabled: 'none',
    },
    icon: {
      backgroundColor: colors.white.rgba,
      border: '0.071em solid',
      borderColor: colors.border.rgba,
      color: colors.darkLight.rgba,
      height: '2.571em',
      hoverBackgroundColor: colors.primaryLight.rgba,
      hoverBorderColor: colors.secondary.rgba,
      hoverColor: colors.primary.rgba,
      padding: '0.714em',
      opacityDisabled: 0.75,
      pointerEventsDisabled: 'none',
    },
    inlineIcon: {
      margin: '0.000em !important',
      padding: '0.000em !important',
      opacity: '1 !important',
      lineHeight: 1.2,
    },
    normal: {
      backgroundColor: colors.white.rgba,
      border: '0.071em solid',
      borderColor: colors.border.rgba,
      color: colors.darkLight.rgba,
      hoverBackgroundColor: colors.primaryLight.rgba,
      hoverBorderColor: colors.secondary.rgba,
      hoverColor: colors.primary.rgba,
      opacityDisabled: 0.75,
      pointerEventsDisabled: 'none',
    },
    toolPicker: {
      transition: 'color 0.3s ease',
      width: 45,
      height: 45,
      padding: 15,
      fontSize: '1.2em',
      backgroundColor: 'transparent !important',
      activeColor: colors.primary.rgba,
      color: colors.darkAccent.rgba,
      hoverColor: `${colors.primary.rgba} !important`,
    },
    trigger: {
      padding: '10px',
      backgroundColor: colors.white.rgba,
      border: '0.071em solid',
      borderColor: colors.border.rgba,
      color: colors.darkLight.rgba,
      hoverBackgroundColor: colors.primaryLight.rgba,
      hoverBorderColor: colors.secondary.rgba,
      hoverColor: colors.primary.rgba,
    },
    select: {
      backgroundColor: 'transparent',
      border: 'none',
      color: colors.dark.rgba,
      fontWeight: 600,
      fontSize: 12,
      height: 'auto',
    },
    selectIcon: {
      color: colors.darkLight.rgba,
      fontWeight: 'normal',
      margin: '0.000em !important',
      padding: '0.000em !important',
      opacity: '1 !important',
    },
    tool: {
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: '4px',
      height: '28px',
      // normalWidth: '28px',
      // extendedWidth: '36px',
      padding: 0,
      paddingLeft: '4px',
      paddingRight: '4px',
      color: colors.darkLight.rgba,
      hoverBackgroundColor: colors.light.alpha(0.5).rgba,
      hoverColor: colors.primary.rgba,
      opacityDisabled: 0.75,
      pointerEventsDisabled: 'none',
    },
    sizerButton: {
      active: {
        backgroundColor: colors.primaryLight.rgba,
        border: '0.071em solid',
        borderColor: colors.border.rgba,
        color: colors.primary.rgba,
        fontSize: '10px', // This line is in pixel to avoid a bug where it was not working and was displaying nothing when in em
        fontWeight: 500,
        borderRight: '0em',
      },
      backgroundColor: colors.white.rgba,
      border: '0.071em solid',
      borderColor: colors.border.rgba,
      color: colors.darkLight.rgba,
      fontSize: '10px', // This line is in pixel to avoid a bug where it was not working and was displaying nothing when in em
      fontWeight: 400,
      hoverBackgroundColor: colors.primaryLight.alpha(0.5).rgba,
      hoverTextColor: colors.primary.rgba,
      hoverTextDecoration: 'none',
      borderRight: '0em',
    },
  },
  elevatedPanel: {
    backgroundColor: colors.white.rgba,
    border: '0.071em solid',
    borderColor: colors.border.rgba,
    borderRadius: '0.286em',
    boxShadow: `0em 0em 0.357em ${colors.black.alpha(0.15).rgba}`,
    hoverBorderColor: colors.secondary.rgba,
    overflowY: 'auto',
  },
  input: {
    borderColor: colors.border.rgba,
    borderLeft: 'none',
    borderRadius: '0.286em',
    color: colors.dark.rgba,
    opacity: 1,
    paddingLeft: '1em',
    focused: {
      boxShadow: `0px 0px 5px ${colors.primary.alpha(0.25).rgba}`,
      borderColor: colors.secondary.rgba,
      icon: {
        color: colors.primary.rgba,
      },
    },
    icon: {
      borderRight: '0.071em solid',
      color: colors.darkLight.rgba,
      margin: 0,
      opacity: 1,
      width: '14px',
    },
    header: {
      fontSize: '10px',
      fontWeight: '500',
      color: colors.darkLight.rgba,
      textTransform: 'uppercase',
      marginBottom: '0.1em',
    },
    placeholder: {
      color: colors.darkAccent.rgba,
      opacity: 1,
    },
    error: {
      borderColor: colors.negative.rgba,
      boxShadow: `0px 0px 5px ${colors.negative.alpha(0.15).rgba}`,
    },
  },
  radio: {
    backgroundColor: 'transparent',
    label: {
      afterCheckedBackgroundColor: colors.primary.rgba,
      beforeBorderColor: colors.darkAccent.rgba,
      beforeHoverBorderColor: colors.primaryAccent.rgba,
      checkedColor: colors.primary.rgba,
      checkedFontWeight: 500,
      color: colors.dark.rgba,
      hoverColor: colors.primary.rgba,
      hoverTextDecoration: 'underline',
    },
    toggle: {
      label: {
        backgroundColor: colors.lightDark.rgba,
        checkedBackgroundColor: colors.primary.rgba,
      },
    },
  },
  radioPanel: {
    rows: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      borderRadius: '0em',
      cursor: 'pointer',
      firstRowBorderRadius: '0.286em 0.286em 0em 0em',
      height: '2.643em',
      hoverBackgroundColor: colors.primaryLight.rgba,
      lastRowBorderRadius: '0em 0em 0.286em 0.286em',
      paddingLeft: '0.929em',
    },
  },
  menuPanel: {
    icons: {
      color: colors.darkLight.rgba,
      hoverColor: colors.primary.rgba,
      hoverTextDecoration: 'none',
      marginLeft: '1.429em',
    },
    rows: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      borderRadius: '0em',
      color: colors.dark.rgba,
      firstRowBorderRadius: '0.286em 0.286em 0em 0em',
      height: '2.571em',
      hoverBackgroundColor: colors.primaryLight.rgba,
      hoverColor: colors.primary.rgba,
      hoverCursor: 'pointer',
      hoverTextDecoration: 'underline',
      justifyContent: 'space-between',
      lastRowBorderRadius: '0em 0em 0.286em 0.286em',
      paddingLeft: '0.929em',
      paddingRight: '0.929em',
      minWidth: '16.071em',
    },
  },
  textArea: {
    borderColor: colors.border.rgba,
    borderColorFocused: colors.secondary.rgba,
    color: colors.darkLight.rgba,
    height: '16.000em',
    overflow: 'auto',
    placeholder: {
      color: colors.darkLight.rgba,
      opacity: 1,
    },
    width: '33.714em',
  },
  checkbox: {
    backgroundColor: 'transparent',
    checkboxBorderColor: colors.darkAccent.rgba,
    checkboxBorderRadius: '0.286em',
    color: colors.dark.rgba,
    cursor: 'pointer',
    hoverColor: colors.primary.rgba,
    hoverTextDecoration: 'underline',
    iconTextDecoration: 'none',
    selectedCheckColor: colors.primary.rgba,
    selectedColor: colors.primary.rgba,
    selectedFontWeight: 500,
  },
  checkboxPanel: {
    alignItems: 'center',
    backgroundColor: colors.white.rgba,
    firstRowBorderRadius: '0.286em 0.286em 0em 0em',
    header: {
      alignItems: 'center',
      height: '2.571em',
      marginTop: '0em',
      marginLeft: '0.357em',
      paddingLeft: '1em',
      paddingRight: '1em',
      iconColor: colors.darkLight.rgba,
      iconLineHeight: '1em',
    },
    height: '2.571em',
    hoverBackgroundColor: colors.primaryLight.rgba,
    hoverColor: colors.primary.rgba,
    hoverCursor: 'pointer',
    hoverTextDecoration: 'underline',
    iconTextDecoration: 'none',
    lastRowBorderRadius: '0em 0em 0.286em 0.286em',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  selectColorPanel: {
    alignItems: 'center',
    backgroundColor: colors.white.rgba,
    firstRowBorderRadius: '0.286em 0.286em 0em 0em',
    header: {
      alignItems: 'center',
      height: 'auto',
      marginTop: '0em',
      marginLeft: '0.357em',
      padding: '0.7em 0.3em',
      iconColor: colors.darkLight.rgba,
      iconLineHeight: '1em',
      borderBottom: `0.5px solid ${colors.lightDark.rgba}`,
      justifyContent: 'space-between',
      cursor: 'default',
    },
    search: {
      color: colors.lightDark.rgba,
      borderColor: colors.lightDark.rgba,
      focusBorder: colors.secondary.rgba,
      focusColor: colors.dark.rgba,
    },
    height: '2.571em',
    hoverBackgroundColor: colors.primaryLight.rgba,
    hoverColor: colors.primary.rgba,
    hoverCursor: 'pointer',
    hoverTextDecoration: 'underline',
    iconTextDecoration: 'none',
    lastRowBorderRadius: '0em 0em 0.286em 0.286em',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  progress: {
    linear: {
      width: '100%',
    },
    circular: {
      dominantBaseline: 'central',
      fontSize: '1.429em',
      strokeLineCap: 'round',
      textAnchor: 'middle',
      transition: 'stroke-dashoffset 0.5s ease 0s',
      width: '100%',
    },
  },
  tabs: {
    overflowY: 'scroll',
    tab: {
      color: colors.shadow.rgba,
      hover: {
        color: colors.primary.rgba,
        fontWeight: 'normal',
      },
      selected: {
        color: colors.primary.rgba,
        fontWeight: '500',
      },
    },
    outer: {
      tab: {
        active: {
          boxShadow: `0px 0px 0px 1px ${colors.secondary.rgba}`,
          backgroundColor: colors.primaryLight.rgba,
          hover: {
            boxShadow: `0px 0px 0px 1px ${colors.primary.rgba}`,
          },
        },
        hover: {
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
      },
      content: {
        borderRadius: '5px',
        borderColor: colors.secondary.rgba,
        backgroundColor: colors.white.rgba,
      },
      backgroundColor: colors.white.rgba,
      border: '0.071em solid',
      borderColor: colors.border.rgba,
      color: colors.darkLight.rgba,
      fontSize: '10px', // This line is in pixel to avoid a bug where it was not working and was displaying nothing when in em
      fontWeight: 400,
      hoverBackgroundColor: colors.primaryLight.alpha(0.5).rgba,
      hoverTextColor: colors.primary.rgba,
      hoverTextDecoration: 'none',
      borderRight: '0em',
    },
    inner: {
      tab: {
        backgroundColor: 'transparent',
        borderWidth: '1px',
        active: {
          borderColor: colors.primary.rgba,
          borderColorBottom: 'transparent',
          borderWidth: '3px',
        },
      },
      content: {
        border: 'none',
        backgroundColor: 'transparent',
        padding: '0',
      },
      backgroundColor: colors.white.rgba,
      border: '0.071em solid',
      borderColor: colors.border.rgba,
      color: colors.darkLight.rgba,
      fontSize: '14px', // This line is in pixel to avoid a bug where it was not working and was displaying nothing when in em
      fontWeight: 400,
      hoverBackgroundColor: colors.primaryLight.alpha(0.5).rgba,
      hoverTextColor: colors.primary.rgba,
      hoverTextDecoration: 'none',
      borderRight: '0em',
    },
    small: {
      tab: {
        borderRight: 'none',
        active: {
          backgroundColor: colors.primaryLight.rgba,
        },
        hover: {
          backgroundColor: colors.primaryLightAlpha.rgba,
        },
      },
      content: {
        border: 'none',
        borderColor: 'transparent',
        color: colors.white.rgba,
        fontSize: '16px', // This line is in pixel to avoid a bug where it was not working and was displaying nothing when in em
        fontWeight: 500,
        borderRight: '0em',
      },
      backgroundColor: colors.primaryLight.rgba,
      border: '0.071em solid',
      borderColor: colors.secondary.rgba,
      color: colors.primary.alpha(0.25).rgba,
      fontSize: '16px', // This line is in pixel to avoid a bug where it was not working and was displaying nothing when in em
      fontWeight: 400,
      hoverBackgroundColor: colors.primaryLight.rgba,
      hoverTextColor: colors.primary.rgba,
      hoverTextDecoration: 'underline',
      borderRight: '0em',
    },
  },
  metricChange: {
    small: {
      borderRadius: '0.286em',
      display: 'inline-flex',
      fontSize: '0.857em',
      fontWeight: '500',
      padding: '0 0.357em',
      typeIconFontSize: '10px', // This line is not in EM due to a bug where EM doesn't get applied
      typeIconMargin: '0em',
    },
    medium: {
      borderRadius: '0.286em',
      display: 'inline-flex',
      fontSize: '1em',
      fontWeight: '500',
      padding: '0.357em',
      typeIconFontSize: '12px', // This line is not in EM due to a bug where EM doesn't get applied
      typeIconMargin: '0em',
    },
    large: {
      borderRadius: '0.286em',
      display: 'inline-flex',
      fontSize: '1.286em',
      fontWeight: '500',
      padding: '0.357em',
      typeIconFontSize: '12px', // This line is not in EM due to a bug where EM doesn't get applied
      typeIconMargin: '0em',
    },
    colors: {
      neutral: {
        backgroundColor: colors.light.rgba,
        color: colors.darkLight.rgba,
      },
      positive: {
        backgroundColor: colors.positiveAlpha.rgba,
        color: colors.positive.rgba,
      },
      negative: {
        backgroundColor: colors.negativeAlpha.rgba,
        color: colors.negative.rgba,
      },
    },
    error: {
      paddingLeft: '0.5em',
      fontWeight: '400',
    },
  },
  popup: {
    header: {
      color: colors.dark.rgba,
      fontSize: '16px',
      fontWeight: '500',
      icon: {
        color: colors.darkLight.rgba,
      },
    },
  },
  typography: {
    h1: {
      fontSize: '1.25em',
      fontWeight: '500',
      color: colors.dark.rgba,
    },
    h2: {
      fontSize: '1.125em',
      fontWeight: '500',
      color: colors.dark.rgba,
    },
    h3: {
      fontSize: '1em',
      fontWeight: '500',
      color: colors.dark.rgba,
    },
    metricTitle: {
      fontSize: '0.9375em',
      fontWeight: '500',
      color: colors.dark.rgba,
    },
    metricNumber: {
      fontSize: '1.5em',
      fontWeight: '500',
      color: colors.primary.rgba,
      currency: {
        fontSize: '0.8em',
        color: colors.darkLight.rgba,
        fontWeight: '400',
      },
    },
    numberTitle: {
      fontSize: '0.875em',
      color: colors.dark.rgba,
      fontWeight: '500',
      hover: {
        cursor: 'pointer',
        color: colors.primary.rgba,
        textDecoration: 'underline',
      },
    },
    link: {
      fontSize: '0.625em',
      color: colors.primary.rgba,
      fontWeight: '400',
      textDecoration: 'underline',
      hover: {
        cursor: 'pointer',
      },
    },
  },
  dropzone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `dashed 1px ${colors.border.rgba}`,
    borderRadius: '5px',
    padding: '10px 15px',
    color: colors.light.rgba,
    backgroundColor: colors.lightAccent.rgba,
    cursor: 'copy',
    info: {
      color: colors.darkLight.rgba,
      fontSize: '0.9em',
    },
  },
  banner: {
    minHeight: '290px',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginBottom: '30px',
    position: 'relative',
    lineHeight: '400px',
    zIndex: '0',
    display: 'flex',
    alignItems: 'center',
    overlay: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '0',
      bottom: '0',
      zIndex: '-1',
    },
    title: {
      color: colors.white.rgba,
      fontSize: '45px',
      fontWeight: '500',
      margin: 'auto auto 10px 60px',
    },
    image: {
      margin: 'auto',
      height: '190px',
      zIndex: '999',
      position: 'absolute',
      right: '80px',
    },
  },
}

export default theme
