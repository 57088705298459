export default {
  index: {
    actionStatus: 'Action Status',
    awaitingResponse: 'Awaiting Response',
    new: 'New',
    pendingAction: 'Pending Action',
    problemType: 'Problem Type',
    resolved: 'Resolved',
    ticketId: 'Ticket ID',
    tickets: {
      one: 'Ticket (optional)',
      other: 'Tickets (optional)',
      zero: 'Ticket (optional)',
    },
  },
  tickets: {
    actionsTable: {
      deleteActionsTitle: {
        one: "You're about to delete the action on this product",
        other: "You're about to delete the actions on those products",
        zero: "You're about to delete nothing",
      },
    },
    updateAction: {
      description: 'Edit your action(s) : Add a comment - Add, update or remove a ticket - Update the action status',
      optionalComment: 'Comment (optional)',
      actionNumber: 'Action #{{key}} on ASIN {{asin}}',
      ticketNumber: 'Ticket #{{key}}',
    },
    index: {
      title: 'Actions list',
      subtitle: 'Review here all the actions',
      overallSummary: 'Overall summary:',
      monthlySummary: 'Monthly summary',
      actionsTaken: {
        one: '1 action taken',
        other: '{{count}} actions taken',
        zero: 'No action(s) taken',
      },
      actionsResolved: {
        one: '1 action resolved',
        other: '{{count}} actions resolved',
        zero: 'No action(s) resolved',
      },
    },
  },
  issues: {
    createAction: {
      firstDescription: 'First, copy paste this template on your Amazon ticket(s)',
      secondDescription: 'Write your comment and insert the ticket(s) you got from Amazon',
      optionalComment: 'Comment (optional)',
    },
    index: {
      title: 'Problems to solve',
      subtitle: "Review here the problem we've found on your catalog",
    },
  },
}
