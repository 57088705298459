import i18n from '../i18n'

const ExtraDimensionSeries = cls =>
  class extends cls {
    constructor(args = {}) {
      const {extraDimensions, data} = args
      super({data})
      this.extraDimensions = extraDimensions || {}
    }

    loadExtraDimensions(extraDimensions) {
      this.extraDimensions = extraDimensions || {}
      Object.keys(extraDimensions).forEach(attr => {
        if (!this.cf.dimensions[attr])
          this.cf.dimensions[attr] = this.cf.data.dimension(
            v => v.collections[attr] || i18n.t('placeholders.empty.unclassified')
          )
      })
    }

    createDimensions(data) {
      super.createDimensions(data)
      Object.keys(this.extraDimensions).forEach(attr => {
        this.cf.dimensions[attr] = this.cf.data.dimension(
          v => v.collections[attr] || i18n.t('placeholders.empty.unclassified')
        )
      })
    }

    updateFilters(values = {}, lockId = 'default') {
      super.updateFilters(values, lockId)
      Object.keys(this.extraDimensions).forEach(attr =>
        this.filterDimension(this.cf.dimensions[attr], values[attr] || null)
      )
    }

    getAvailableGroupings() {
      return [...super.getAvailableGroupings(), ...Object.keys(this.extraDimensions || {})]
    }
  }

export default ExtraDimensionSeries
