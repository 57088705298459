import React from 'react'

import {Box, Col} from 'jsxstyle'

import {FilterButton, Rheostat} from '../../../..'
import FilterBarItem from './FilterBarItem'

interface ValueRangeProps {
  algorithm?: string
  data: {
    minValue: number
    maxValue: number
  }
  icon?: string | null
  isFloat: boolean
  min?: number
  max?: number
  onChange: (value: {minValue: number; maxValue: number}) => void
  onRemove: () => void
  onApply: () => void
  title: string
  disabled?: boolean
}

const ValueRange = ({
  algorithm = 'linear',
  data,
  isFloat = false,
  min = 0,
  max = 100,
  onChange,
  onRemove,
  onApply,
  title,
  icon = null,
  disabled = false,
  ...barItemProps
}: ValueRangeProps) => {
  const {minValue, maxValue} = data

  const trigger = (
    <FilterButton
      title={title}
      icon={icon}
      disabled={disabled}
      onRemove={onRemove}
      onApply={onApply}
      dropdown={undefined}
    >
      <Box marginLeft="8px">{`${minValue} - ${maxValue}`}</Box>
    </FilterButton>
  )

  return (
    <FilterBarItem
      {...barItemProps}
      data={data}
      onChange={values => onChange({minValue: values?.minValue, maxValue: values?.maxValue})}
      onApply={onApply}
      disabled={disabled}
      trigger={trigger}
    >
      <Col width="200px" cursor="default">
        <Rheostat
          disabled={disabled}
          algorithm={algorithm}
          isFloat={isFloat}
          min={min}
          max={max}
          values={[minValue, maxValue]}
          onChange={({from, to}: {from: number; to: number}) => onChange({minValue: from, maxValue: to})}
        />
      </Col>
    </FilterBarItem>
  )
}

export default ValueRange
