import React from 'react'

import {Box} from 'jsxstyle'
import {Icon, Segment} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../../colors'

const StyledChoice = styled(Segment)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    padding: 20px;
    margin: 0 0 20px 0;
    cursor: pointer;
    :hover {
      background: ${colors.primaryLight.rgba};
      border-color: ${colors.secondary.rgba};
      box-shadow: 0 0 4px ${colors.shadow.rgba} !important;
    }
  }
`
const StyledIconChoice = styled(Icon)`
  & {
    color: ${colors.lightDark.rgba};
    ${StyledChoice}:hover & {
      text-decoration: none;
      color: ${colors.primary.rgba};
    }
  }
`
const StyledArrowIcon = styled(Icon)`
  display: none !important;
  color: ${colors.primary.rgba};
  ${StyledChoice}:hover & {
    display: inline-block !important;
  }
`
const StyledLink = styled.span`
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  ${StyledChoice}:hover & {
    color: ${colors.primary.rgba};
  }
`

interface SeelkSegmentProps {
  icon: string
  onClick: () => void
  value: string
  arrowIcon?: boolean
}

const SeelkSegment = ({icon, onClick, value, arrowIcon = false}: SeelkSegmentProps) => {
  return (
    <StyledChoice onClick={onClick}>
      <Box>
        <StyledIconChoice name={icon} size="large" />
        <StyledLink>{value}</StyledLink>
      </Box>
      {arrowIcon ? <StyledArrowIcon name="arrow right" /> : null}
    </StyledChoice>
  )
}
export default SeelkSegment
