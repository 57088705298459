import React from 'react'

import {Label} from 'semantic-ui-react'
import styled from 'styled-components'

import {capitalize} from '../..'
import colors from '../../colors'
import i18n from '../../i18n'

const StyledFilterContainer = styled.div`
  display: inline-block;
  border-radius: 3px;
  background-color: ${colors.light.rgba};
  margin: 3px;
  padding: 5px;
`

const StyledFilterName = styled.span`
  font-size: 12px;
  color: ${colors.darkLight.rgba};
`

const StyledLabel = styled(Label)`
  &&&&& {
    padding: 3px 5px;
    background-color: ${colors.darkLight.rgba};
    color: ${colors.white.rgba};
    span {
      font-size: 11px;
      font-weight: 300;
    }
    b {
      font-size: 11px;
      font-weight: 500;
    }
  }
`

interface GroupType {
  groups: string[]
  collection: string
}

interface AttributesType {
  [key: string]: GroupType
}

interface StaticAttributeFiltersProps {
  attributes: AttributesType
  type: string
}

const StaticAttributeFilters = ({attributes = {}, type}: StaticAttributeFiltersProps) => {
  if (!Object.keys(attributes || {}).length) return null
  return (
    <StyledFilterContainer>
      <StyledFilterName>{`${i18n.t(`filters.${type}`) || capitalize(type)}: `}</StyledFilterName>
      {Object.values(attributes).map(({collection, groups}) =>
        groups.map(group => (
          <StyledLabel key={group}>
            <span>{`${collection} > `}</span>
            <b>{group}</b>
          </StyledLabel>
        ))
      )}
    </StyledFilterContainer>
  )
}

export default StaticAttributeFilters
