import React from 'react'

import {CheckboxProps, Radio} from 'semantic-ui-react'
import styled from 'styled-components'

const StyledRadio = styled(Radio)`
  &&&&& {
    background-color: ${props => props.theme.radio.backgroundColor};
    label {
      color: ${props => props.theme.radio.label.color};
    }
    :hover label {
      color: ${props => props.theme.radio.label.hoverColor};
      text-decoration: ${props => props.theme.radio.label.hoverTextDecoration};
    }
    :hover label::before {
      border-color: ${props => props.theme.radio.label.beforeHoverBorderColor};
    }
    label::before {
      border-color: ${props => props.theme.radio.label.beforeBorderColor};
    }
    input:checked + label {
      color: ${props => props.theme.radio.label.checkedColor};
      font-weight: ${props => props.theme.radio.label.checkedFontWeight};
    }
    input:checked + label::after {
      background-color: ${props => props.theme.radio.label.afterCheckedBackgroundColor};
    }
  }
`

const StyledToggleRadio = styled(Radio)`
  &&&&& {
    :hover label::before,
    label::before {
      background-color: ${props => props.theme.radio.toggle.label.backgroundColor};
    }

    input:checked + label::before {
      background-color: ${props => props.theme.radio.toggle.label.checkedBackgroundColor};
    }
  }
`

interface SeelkRadioProps {
  disabled?: boolean
  onChange: (value?: number | string) => void
  option: {
    label: string
    value: number | string
  }
  toggle?: boolean
  value?: number | string
}

const SeelkRadio = ({onChange, option, value, disabled = false, toggle = false}: SeelkRadioProps) => {
  const handleChange = (e: React.FormEvent<HTMLInputElement>, {value}: CheckboxProps) => {
    onChange(value)
  }

  return toggle ? (
    <StyledToggleRadio
      disabled={disabled}
      checked={value === option.value}
      label={option.label}
      onChange={handleChange}
      value={option.value || ''}
      toggle
    />
  ) : (
    <StyledRadio
      disabled={disabled}
      checked={value === option.value}
      label={option.label}
      onChange={handleChange}
      value={option.value || ''}
    />
  )
}

export default SeelkRadio
