export default {
  management: {
    added: 'Added',
    buildTree: 'Build Tree',
    categoriesCount: {
      one: '1 category',
      other: '{{count}} categories',
      zero: 'No category',
    },
    keywordsCount: {
      one: '1 keyword',
      other: '{{count}} keywords',
      zero: 'No keyword',
    },
    keywordsList: 'List of Keywords to add',
    ignored: 'Ignored',
    launchBSScraping: {
      one: 'You are about to scrape Best Sellers for one category, are you?',
      other: 'You are about to scrape Best Sellers for {{count}} categories, are you?',
    },
    launchKWScraping: {
      one: 'You are about to scrape Keywords for one category, are you?',
      other: 'You are about to scrape Keywords for {{count}} categories, are you?',
    },
    pending: 'Pending',
    scrape: 'Scrape',
    trackingFrequencies: 'Tracking Frequencies',
    welcome: 'Welcome to Amazon Category Management !',
  },
  metrics: {
    ourMarketshare: 'Our Marketshare',
    totalAsins: 'TOTAL ASINs',
  },
  nav: {
    goBackToCountrySelect: 'Go back to country select',
    goBackToParentCategory: 'Back to parent category',
    searchCategory: 'Search category',
    selectCategory: 'Select a category',
    selectCountry: 'Select a country',
  },
  table: {
    title: 'Our Categories',
  },
  topProducts: {
    title: '# Top 100 Products by Category',
  },
  topSellers: {
    title: 'Our Top Sellers',
  },
}
