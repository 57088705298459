import React from 'react'

import {Grid} from 'jsxstyle'
import PropTypes from 'prop-types'
import {connect} from 'react-redux-reliever'

import LoaderWrapper from '../../utils/requesting/containers/LoaderWrapper'
import {requestWrapperBuilder, withRequests} from '../../utils/requesting/RequestWrapper'
import Table from '../components/PermissionsTable'
import {areActiveUsersSelector, groupsSelector, userSelector, usersSelector} from '../selectors'

export class ClientPermissionsTable extends React.Component {
  static propTypes = {
    areActiveUsers: PropTypes.bool.isRequired,
    bulkSetGroups: PropTypes.object.isRequired,
    exportUsers: PropTypes.object.isRequired,
    fetchGroups: PropTypes.object.isRequired,
    fetchUsers: PropTypes.object.isRequired,
    groups: PropTypes.arrayOf(PropTypes.string),
    user: PropTypes.object,
    users: PropTypes.arrayOf(PropTypes.object),
    updateAreActiveUsers: PropTypes.func.isRequired,
  }

  static defaultProps = {
    groups: [],
    users: [],
    user: null,
  }

  UNSAFE_componentWillMount() {
    if (this.props.fetchGroups.canFetch()) this.props.fetchGroups.fetch()
    if (this.props.fetchUsers.canFetch()) this.props.fetchUsers.fetch()
  }

  render() {
    const {areActiveUsers, groups, user, users} = this.props
    let filteredUsers

    if (users === null) return null

    if (user.client === 'onetoone') {
      filteredUsers = users.filter(u => u.account === 'seelk' && u.email.search('@seelk.co') !== -1)
    } else {
      filteredUsers = users.filter(u => u.account === user.client)
    }

    return (
      <Grid style={{overflowX: 'scroll'}}>
        <LoaderWrapper
          requesting={['client-permissions.users.fetch', 'client-permissions.groups.fetch']}
          blur
          container
        >
          <Table
            areActiveUsers={areActiveUsers}
            bulkSetGroups={this.props.bulkSetGroups}
            exportUsers={() => this.props.exportUsers.fetch()}
            fetchUsers={this.props.fetchUsers}
            orgGroups={groups}
            orgUsers={filteredUsers}
            updateAreActiveUsers={updatedAreActiveUsers => this.props.updateAreActiveUsers(updatedAreActiveUsers)}
          />
        </LoaderWrapper>
      </Grid>
    )
  }
}

export default withRequests({
  bulkSetGroups: requestWrapperBuilder({
    fetchAction: 'CLIENT-PERMISSIONS_UPDATE_USER_GROUPS',
    requestingPath: 'client-permissions.users.setGroups',
    successMsg: 'User(s) groups set!',
  }),
  exportUsers: requestWrapperBuilder({
    fetchAction: 'CLIENT-PERMISSIONS_EXPORT_USERS',
    requestingPath: 'client-permissions.users.export',
    successMsg: 'User permissions have been downloaded',
  }),
  fetchGroups: requestWrapperBuilder({
    fetchAction: 'CLIENT-PERMISSIONS_FETCH_GROUPS',
    requestingPath: 'client-permissions.groups.fetch',
    resourcePath: 'client-permissions.groups',
  }),
  fetchUsers: requestWrapperBuilder({
    fetchAction: 'CLIENT-PERMISSIONS_FETCH_USERS',
    requestingPath: 'client-permissions.users.fetch',
    resourcePath: 'client-permissions.users',
  }),
})(
  connect({
    functions: (ownProps, dispatch) => ({
      updateAreActiveUsers: areActiveUsers =>
        dispatch({
          type: 'CLIENT-PERMISSIONS_UPDATE_ACTIVE_USERS',
          payload: {areActiveUsers},
        }),
    }),
    props: (state, ownProps) => ({
      areActiveUsers: areActiveUsersSelector(state),
      groups: groupsSelector(state),
      users: usersSelector(state),
      user: userSelector(state),
      ...ownProps,
    }),
  })(ClientPermissionsTable)
)
