import React, {Component} from 'react'

import {Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import {connect} from 'react-redux-reliever'
import {Header, Icon, Label} from 'semantic-ui-react'

import colors from '../../utils/colors'
import {DeleteItems, SeelkButton} from '../../utils/common-ui'
import i18n from '../../utils/i18n'
import LoaderWrapper from '../../utils/requesting/containers/LoaderWrapper'
import {requestWrapperBuilder, withRequests} from '../../utils/requesting/RequestWrapper'
import theme from '../../utils/theme'
import AdvertisersModal from '../components/AdvertisersModal'
import AdvertisersTable from '../components/AdvertisersTable'
import {indexedAdvertisersSelector} from '../selectors'
// import Filters from 'filters/containers/Filters'

class AdvertisersManagement extends Component {
  static propTypes = {
    advertisers: PropTypes.object,
    deleteAdvertisers: PropTypes.object.isRequired,
    fetchAdvertisers: PropTypes.object.isRequired,
    updateAdvertisers: PropTypes.object.isRequired,
  }

  static defaultProps = {
    advertisers: {},
  }

  constructor(props) {
    super(props)

    this.state = {
      open: false,
      selectedAdvertisers: {},
      activeAdvertiser: null,
    }
  }

  componentDidMount() {
    if (this.props.fetchAdvertisers.canFetch()) this.props.fetchAdvertisers.fetch()
  }

  updateSelectedAdvertisers = selectedAdvertisers => this.setState({selectedAdvertisers})

  editAdvertiser = ({id}) => {
    this.setState({open: true, activeAdvertiser: id})
  }

  deleteAdvertisers = () => {
    const {deleteAdvertisers} = this.props
    const {selectedAdvertisers} = this.state
    deleteAdvertisers.fetch({ids: Object.values(selectedAdvertisers).map(({id}) => id)})
    this.setState({open: false, activeAdvertiser: null})
  }

  updateAdvertiser = advertisers => {
    const {updateAdvertisers} = this.props
    updateAdvertisers.fetch({advertisers})
    this.setState({open: false, activeAdvertiser: null})
  }

  render() {
    const {advertisers} = this.props
    const {open, selectedAdvertisers} = this.state

    const selectedLength = Object.keys(selectedAdvertisers).length

    const count = Object.keys(advertisers || {})?.length ?? 0

    return (
      <Col flex={1} height="0px" position="relative">
        <LoaderWrapper multi={{'advertisers.advertisersData.fetch': null}} blur>
          <Col position="relative" flex={1}>
            <Col className={theme.container} margin="20px 90px 15px 90px" flex={1}>
              <Row justifyContent="space-between" alignContent="baseline">
                <Row alignItems="baseline">
                  <Header>{i18n.t('settings.management.advertisers.advertisers')}</Header>
                  {count > 0 && (
                    <Label style={{backgroundColor: colors.primaryLight.rgba, marginLeft: '0.75em', fontSize: '1em'}}>
                      {count}
                    </Label>
                  )}
                  {selectedLength > 0 && (
                    <Label style={{backgroundColor: colors.primaryLight.rgba, marginLeft: '0.75em'}}>
                      {i18n.t('misc.cross.selectedEntities', {
                        count: selectedLength,
                        entity: i18n.t('settings.management.advertisers.advertisers', {count: selectedLength}),
                      })}
                      <Icon name="close" onClick={() => this.updateSelectedAdvertisers({})} />
                    </Label>
                  )}
                </Row>
                <Row>
                  <Row>
                    <DeleteItems
                      value={i18n.t('settings.management.advertisers.advertisers', {count: selectedLength})}
                      count={selectedLength}
                      confirm={this.deleteAdvertisers}
                      popupContent={
                        selectedLength > 0
                          ? i18n.t('settings.management.advertisers.delete')
                          : i18n.t('settings.management.advertisers.deleteDisabled')
                      }
                    />
                    <div style={{marginLeft: 12}} />
                    <SeelkButton
                      type="action"
                      content={i18n.t('settings.management.advertisers.add')}
                      icon="bullhorn"
                      iconPosition="left"
                      onClick={() => this.setState({open: true})}
                    />
                  </Row>
                </Row>
              </Row>
              <AdvertisersModal
                open={open}
                onClose={() => this.setState({open: false, activeAdvertiser: null})}
                updateAdvertiser={this.updateAdvertiser}
                id={this.state.activeAdvertiser}
                advertisers={advertisers}
              />
              {advertisers && (
                <AdvertisersTable
                  advertisers={Object.values(advertisers)}
                  selectedAdvertisers={selectedAdvertisers}
                  updateSelectedAdvertisers={this.updateSelectedAdvertisers}
                  editAdvertiser={this.editAdvertiser}
                />
              )}
            </Col>
          </Col>
        </LoaderWrapper>
      </Col>
    )
  }
}

export default withRequests({
  fetchAdvertisers: requestWrapperBuilder({
    fetchAction: 'ADVERTISERS_FETCH_ADVERTISERS',
    resourcePath: 'advertisers.advertisersData',
    requestingPath: 'advertisers.advertisersData.fetch',
  }),
  deleteAdvertisers: requestWrapperBuilder({
    fetchAction: 'ADVERTISERS_DELETE_ADVERTISERS',
    resourcePath: 'advertisers.advertisersData',
    requestingPath: 'advertisers.advertisersData.delete',
  }),
  updateAdvertisers: requestWrapperBuilder({
    fetchAction: 'ADVERTISERS_UPDATE_ADVERTISERS',
    resourcePath: 'advertisers.advertisersData',
    requestingPath: 'advertisers.advertisersData.update',
  }),
})(
  connect({
    props: (state, ownProps) => ({
      advertisers: indexedAdvertisersSelector(state),
      ...ownProps,
    }),
  })(AdvertisersManagement)
)
