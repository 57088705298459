import RelieverRegistry from 'react-redux-reliever'
import {createSelector} from 'reselect'

export const clientPermissionsModuleState = state => {
  return RelieverRegistry.moduleState('client-permissions', state)
}

const appModuleState = state => RelieverRegistry.moduleState('app', state)

export const areActiveUsersSelector = state => clientPermissionsModuleState(state).areActiveUsers

const rawGroupsSelector = state => clientPermissionsModuleState(state).groups

export const groupsSelector = createSelector(rawGroupsSelector, rawGroups => {
  if (!rawGroups) return null
  return rawGroups.asMutable({deep: true})
})

const rawUsersSelector = state => clientPermissionsModuleState(state).users

export const usersSelector = createSelector(rawUsersSelector, areActiveUsersSelector, (rawUsers, areActiveUsers) => {
  if (!rawUsers) return null

  const users = rawUsers.asMutable({deep: true})

  return areActiveUsers ? users?.filter(user => user.active) : users
})

const rawUserSelector = state => appModuleState(state).user
export const userSelector = createSelector(rawUserSelector, user => user && user.asMutable({deep: true}))
