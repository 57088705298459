export default {
  download: {
    onboardingTemplates: 'Télécharger les templates pour les marché Amazon sur lesquels vous êtes présents.',
  },
  global: {
    connect: 'Connexion',
    fillOutCsv: 'Remplissez les template .CSV  avec vos references',
    setCredentials: {
      sellerSubmitReady: 'Choisissez la voie du succès sur Amazon avec Seelk',
    },
  },
  login: {
    sellerCentral: 'Login to Seller Central',
  },
  navigation: {
    goToSettings: 'Voir les réglages',
    goToWorkstation: 'Voir la Station de travail',
  },
  upload: {
    dropOnboardingTemplates: 'Mettez vos templates remplis en ligne ici',
    products: 'Ajouter des produits',
  },
}
