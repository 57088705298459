import React from 'react'

import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'
import {PoppersPopup} from '../../common-ui'

const StyledIcon = styled(Icon)<{readonly $detailed: boolean}>`
  margin: ${props => (props.$detailed ? '0' : '0 auto')};
`

interface CatalogNoteProps {
  value: any
  placehold: boolean
  extra?: {
    detailed?: boolean
  }
}

const CatalogNote = ({value = null, placehold = false, extra = {}}: CatalogNoteProps) => {
  const {detailed = false} = extra
  if (placehold) return <div style={{width: 10, height: 10, borderRadius: 10, background: colors.lightShadow.rgba}} />
  if (value)
    return (
      <PoppersPopup
        basic
        content={`${value.text} (${value.length})`}
        trigger={
          <div>
            <StyledIcon $detailed={detailed} name={value.icon} style={{color: value.color}} />
          </div>
        }
      />
    )
}

export default CatalogNote
