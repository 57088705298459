import i18n from '../i18n'
import {fuzzyWordsSearch} from '..'
import BaseSeries from './BaseSeries'
import ExtraDimensionSeries from './ExtraDimensionSeries'

export default class MonitoringSeries extends ExtraDimensionSeries(BaseSeries) {
  createDimensions() {
    super.createDimensions()
    this.cf.dimensions.client = this.cf.data.dimension(v => v.client)
    this.cf.dimensions.id = this.cf.data.dimension(v => v.id)
    this.cf.dimensions.name = this.cf.data.dimension(v =>
      v.name ? v.name.toLowerCase() : i18n.t('placeholders.empty.untitled')
    )
    this.cf.dimensions.section = this.cf.data.dimension(v => v.section)
    this.cf.dimensions.status = this.cf.data.dimension(v => v.status)
    this.cf.dimensions.user = this.cf.data.dimension(v => v.user)
  }

  updateFilters(values = {}, lockId = 'default') {
    super.updateFilters(values, lockId)
    const {client = null, id = null, name = null, section = null, status = null, user = null} = this.filters[lockId]
    this.filterDimension(this.cf.dimensions.client, client)
    this.filterDimension(this.cf.dimensions.id, id)
    this.filterDimension(
      this.cf.dimensions.name,
      name && typeof name === 'string' ? v => fuzzyWordsSearch(name, v) : null
    )
    this.filterDimension(this.cf.dimensions.section, section)
    this.filterDimension(this.cf.dimensions.status, status)
    this.filterDimension(this.cf.dimensions.user, user)
  }
}
