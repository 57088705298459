import React from 'react'

import {Box, Col} from 'jsxstyle'
import {Checkbox} from 'semantic-ui-react'

import colors from '../../../../../colors'
import i18n from '../../../../../i18n'
import {FilterButton} from '../../../..'
import FilterBarItem from './FilterBarItem'

interface YesNoAllProps {
  data: boolean
  onChange: (value: boolean | undefined) => void
  onRemove: () => void
  onApply: () => void
  title: string
  icon?: string | null
  disabled?: boolean
}

const YesNoAll = ({
  data,
  onChange,
  onRemove,
  onApply,
  title,
  icon = null,
  disabled = false,
  ...barItemProps
}: YesNoAllProps) => {
  let txt
  if (data === true || data === false) {
    txt = data ? i18n.t('actions.global.yes') : i18n.t('actions.global.no')
  } else {
    txt = i18n.t('visualization.grouping.all')
  }

  const trigger = (
    <FilterButton
      title={title}
      icon={icon}
      disabled={disabled}
      onRemove={onRemove}
      onApply={onApply}
      dropdown={undefined}
    >
      <Box marginLeft="8px">{txt}</Box>
    </FilterButton>
  )

  return (
    <FilterBarItem
      {...barItemProps}
      data={data}
      onChange={onChange}
      onApply={onApply}
      disabled={disabled}
      trigger={trigger}
    >
      <Col width="150px" cursor="default" padding="16px 0px 16px 0px">
        <Col padding="0px 8px 0px 8px" color={colors.dark.rgba} fontWeight="500">
          <Checkbox
            radio
            onClick={() => onChange(true)}
            checked={data === true}
            label={{
              children: <Box>{i18n.t('actions.global.yes')}</Box>,
            }}
            className="checkbox-element"
          />
          <Checkbox
            radio
            onClick={() => onChange(false)}
            checked={data === false}
            label={{
              children: <Box>{i18n.t('actions.global.no')}</Box>,
            }}
            className="checkbox-element"
          />
          <Checkbox
            radio
            onClick={() => onChange(undefined)}
            checked={data === undefined}
            label={{
              children: <Box>{i18n.t('visualization.grouping.all')}</Box>,
            }}
            className="checkbox-element"
          />
        </Col>
      </Col>
    </FilterBarItem>
  )
}

export default YesNoAll
