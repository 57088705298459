import React from 'react'

import {Icon, Label, SemanticCOLORS} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'
import i18n from '../../i18n'

const StyledIcon = styled(Icon)`
  cursor: pointer;
`

const StyledFlag = styled.span.attrs<{country: string}>(({country}) => ({
  className: `flag-icon flag-icon-${country}`,
}))<{country: string}>`
  width: 18px;
  margin-right: 5px;
`

type ItemType = {
  children?: ItemType[]
  country: string
}

interface ParentOrFlagProps {
  item: ItemType
  active: boolean
  placehold: boolean
}

const ParentOrFlag = ({item, active, placehold}: ParentOrFlagProps) => {
  if (item.children)
    return (
      <div>
        <StyledIcon size="large" name={`caret ${active ? 'down' : 'right'}`} />
        <Label color={'primary' as SemanticCOLORS}>
          <StyledFlag
            country={item.children[0].country.toLowerCase() === 'uk' ? 'gb' : item.children[0].country.toLowerCase()}
          />
          {item.children.length} {i18n.t('catalogue.products.index.variations')}
        </Label>
      </div>
    )
  if (placehold)
    return (
      <div>
        <div
          style={{width: 21, height: 21, marginRight: 4, display: 'inline-block', background: colors.lightShadow.rgba}}
        />
        <div style={{width: 16, height: 11, display: 'inline-block', background: colors.lightShadow.rgba}} />
      </div>
    )
  return (
    <div style={{textAlign: 'center', width: '100%'}}>
      <StyledFlag country={item.country.toLowerCase() === 'uk' ? 'gb' : item.country.toLowerCase()} />
    </div>
  )
}

export default ParentOrFlag
