import React from 'react'

import {css, StyleSheet} from 'aphrodite'
import {Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import {connect} from 'react-redux-reliever'
import '../../utils/apis/MrSatanApiClient'

import colors from '../../utils/colors'
import {SeelkButton} from '../../utils/common-ui'
import ErrorBoundary from '../../utils/ErrorBoundary'
import i18n from '../../utils/i18n'
import LoaderWrapper from '../../utils/requesting/containers/LoaderWrapper'
import {requestWrapperBuilder, withRequests} from '../../utils/requesting/RequestWrapper'

const styles = StyleSheet.create({
  dropZone: {
    height: 100,
    width: '100%',
    border: 'dashed 1px rgba(34, 36, 38, 0.15)',
    borderRadius: 5,
    marginBottom: 10,
    padding: '11px 14px',
    color: colors.light.rgba,
    backgroundColor: colors.lightAccent.rgba,
    cursor: 'copy',
  },
  fileName: {
    color: colors.black.rgba,
  },
  placeholder: {
    color: colors.lightDark.rgba,
  },
})

class DataManagement extends React.Component {
  static propTypes = {
    uploadFile: PropTypes.object.isRequired,
  }

  uploadProvider = i18n.createProvider('actions.upload')

  downloadActionsProvider = i18n.createProvider('actions.download')

  constructor(props) {
    super(props)
    this.state = {
      fileV3: null,
    }
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const processFile = file => {
      const reader = new FileReader()
      reader.onload = () => {
        this.setState({
          fileV3: file,
        })
      }
      reader.onabort = () => console.log('file reading was aborted') // eslint-disable-line no-console
      reader.onerror = () => console.log('file reading has failed') // eslint-disable-line no-console
      reader.readAsBinaryString(file)
    }

    acceptedFiles.forEach(file => {
      processFile(file)
    })

    rejectedFiles.forEach(file => {
      if (file.type === '' && new RegExp(/(.csv)/).test(file.name)) processFile(file)
      else window.alert(i18n.t('placeholders.errors.wrongFile')) // eslint-disable-line no-alert
    })
  }

  handleUpload = file => {
    this.props.uploadFile.fetch({metricsFile: file}, {}, this.handleCancel)
  }

  render() {
    return (
      <Col flex={1} height="100%">
        <Row justifyContent="center" alignItems="center" flex={1} fontSize="72px" fontWeight="bold">
          BARDOCK V3
        </Row>
        <ErrorBoundary>
          <Dropzone onDrop={this.onDrop} multiple={false} accept="text/csv, text/x-csv">
            {({getRootProps, getInputProps}) => (
              <div className={css(styles.dropZone)}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {this.state.fileV3 == null ? (
                    <p className={css(styles.placeholder)}>{this.uploadProvider('dropCsvFile')}</p>
                  ) : (
                    <p className={css(styles.fileName)}>{this.state.fileV3?.name ?? ''}</p>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </ErrorBoundary>
        <LoaderWrapper
          requesting="dataManagement.metrics.upload"
          render={requesting => (
            <SeelkButton
              onClick={() => this.handleUpload(this.state.fileV3)}
              type="confirm"
              icon="checkmark"
              iconPosition="right"
              content={i18n.t('actions.global.confirm')}
              disabled={this.state.fileV3 == null || requesting}
              loading={requesting}
            />
          )}
        />
      </Col>
    )
  }
}

export default withRequests({
  uploadFile: requestWrapperBuilder({
    fetchAction: 'DATA-MANAGEMENT_IMPORT_BARDOCK_METRICS',
    requestingPath: 'dataManagement.metrics.upload',
    successMsg: 'The bardock metrics file has been successfully uploaded and updated',
  }),
})(
  connect({
    props: (state, ownProps) => ({
      ...ownProps,
    }),
  })(DataManagement)
)
