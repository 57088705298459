import React from 'react'

import {Col, Row} from 'jsxstyle'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import {connect} from 'react-redux-reliever'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../utils/colors'
import {DropdownSelector, SeelkButton, SeelkDropzone, SeelkInput, SeelkModal} from '../../utils/common-ui'
import {AVAILABLE_AMZ_COUNTRIES} from '../../utils/constants'
import i18n from '../../utils/i18n'
import LoaderWrapper from '../../utils/requesting/containers/LoaderWrapper'
import {requestWrapperBuilder, withRequests} from '../../utils/requesting/RequestWrapper'

const StyledHeader = styled(Row)`
  &&& {
    border-bottom: 1px solid ${colors.border.rgba};
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    font-size: 16px;
    font-weight: 500;
    color: ${colors.dark.rgba};
  }
`

const StyledContent = styled(Col)`
  &&& {
    justify-content: center;
    align-items: center;
    color: ${colors.dark.rgba};
    height: calc(100vh - 150px);
    & > i {
      font-size: 4em;
      color: ${colors.darkLight.rgba};
    }
    & > h3 {
      width: 390px;
      text-align: center;
    }
  }
`

const StyledModalContent = styled.div`
  flex: 1 0 auto;
  margin: 10px 0;
`

const StyledTitle = styled.span`
  margin-bottom: 5px;
  font-size: 11px;
  text-transform: uppercase;
  color: ${colors.darkLight.rgba};
`

const StyledRow = styled(Row)`
  &&& {
    margin: 15px auto;
  }
`

const StyledCol = styled(Col)`
  &&& {
    min-width: 150px;
    margin-right: 8px;
  }
`

const PERIOD = ['MONTH', 'QUARTER']

const QUARTER_START_DATE = '2021-07-05'

const MONTH_START_DATE = '2021-10-05'

const getDefaultYear = () => {
  // Check if we already have a report on the current year
  const latestQuarter = moment().startOf('quarter').add(4, 'days').subtract(1, 'quarter')
  const currentYear = moment().startOf('year')
  if (latestQuarter.isBefore(currentYear)) return moment().subtract(1, 'year').format('YYYY')
  return moment().format('YYYY')
}

const getAvailableYears = () => {
  const currentYear = moment().format('YYYY')
  const startYear = moment(MONTH_START_DATE).startOf('year')
  const years = []
  let year = startYear.clone()
  while (year.isSameOrBefore(currentYear)) {
    years.push(moment(year).format('YYYY'))
    year = year.add(1, 'years')
  }
  return years
}

const getLatestDate = (period, year) => {
  const currentYear = moment().format('YYYY')
  if (year === currentYear) return moment().startOf(period).add(4, 'days').subtract(1, period)
  return moment(year).endOf('year')
}

const getAvailableQuarters = year => {
  const oldestDate = moment(QUARTER_START_DATE)
  const firstQuarter = moment(year).isBefore(oldestDate) ? oldestDate : moment(year).startOf('year').add(4, 'days')

  const latestQuarter = getLatestDate('quarter', year)

  const quarters = []
  let quarter = firstQuarter.clone()
  while (quarter.isSameOrBefore(latestQuarter)) {
    quarters.push(moment(quarter).format('YYYY-MM-DD'))
    quarter = quarter.add(1, 'quarter')
  }
  return quarters
}

const getAvailableMonths = year => {
  const oldestDate = moment(MONTH_START_DATE)
  const firstMonth = moment(year).isBefore(oldestDate) ? oldestDate : moment(year).startOf('year').add(4, 'days')

  const latestMonth = getLatestDate('month', year)

  const months = []
  let month = firstMonth.clone()
  while (month.isSameOrBefore(latestMonth)) {
    months.push(moment(month).format('YYYY-MM-DD'))
    month = month.add(1, 'month')
  }
  return months
}

const availableQuartersCount = getAvailableQuarters(getDefaultYear()).length

class SearchTermsAudit extends React.Component {
  static propTypes = {
    importFile: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.initialState = {
      file: null,
      open: false,
      period: 'QUARTER',
      reportDate: getAvailableQuarters(getDefaultYear())?.[availableQuartersCount - 1] ?? null,
      reportName: '',
      marketplace: null,
      year: getDefaultYear(),
      availableMonths: getAvailableMonths(getDefaultYear()),
      availableQuarters: getAvailableQuarters(getDefaultYear()),
      availableYears: getAvailableYears(),
    }

    this.state = {
      ...this.initialState,
    }
  }

  onClose = () => this.setState(this.initialState)

  onDrop = (acceptedFiles, rejectedFiles) => {
    const processFile = file => {
      const reader = new FileReader()
      reader.onload = () => {
        this.setState({
          file,
        })
      }
      reader.onabort = () => console.log('file reading was aborted') // eslint-disable-line no-console
      reader.onerror = () => console.log('file reading has failed') // eslint-disable-line no-console
      reader.readAsBinaryString(file)
    }

    acceptedFiles.forEach(file => {
      processFile(file)
    })

    rejectedFiles.forEach(file => {
      if (file.type === '' && new RegExp(/(.csv)|(.xls)/).test(file.name)) processFile(file)
      else window.alert(i18n.t('placeholders.errors.wrongFile')) // eslint-disable-line no-alert
    })
  }

  setMarketplace = marketplace => this.setState({marketplace})

  setPeriod = period => {
    const availableMonths = getAvailableMonths(this.state.year)
    const availableQuarters = getAvailableQuarters(this.state.year)
    const reportDates = period === 'MONTH' ? availableMonths : availableQuarters
    const defaultDate = reportDates.length ? reportDates[reportDates.length - 1] : null
    this.setState({period, reportDate: defaultDate, availableMonths, availableQuarters})
  }

  setYear = year => {
    const {period} = this.state
    const availableMonths = getAvailableMonths(year)
    const availableQuarters = getAvailableQuarters(year)
    const reportDates = period === 'MONTH' ? availableMonths : availableQuarters
    const defaultDate = reportDates.length ? reportDates[reportDates.length - 1] : null
    this.setState({year, reportDate: defaultDate, availableMonths, availableQuarters})
  }

  setReportDate = reportDate => this.setState({reportDate})

  setReportName = (e, {value: reportName}) => this.setState({reportName})

  dateFormat = date => moment(date).format('YYYY-MM')

  renderModal = () => {
    const {importFile} = this.props
    const {availableMonths, availableQuarters, availableYears, file, marketplace, period, reportDate, reportName} =
      this.state

    const availabeDates = period === 'MONTH' ? availableMonths : availableQuarters

    const isDisabled = !file || !marketplace || !reportDate || !reportName?.length
    return (
      <>
        <SeelkModal
          trigger={
            <SeelkButton
              content={i18n.t('actions.upload.import')}
              icon="fas fa-file-upload"
              iconPosition="left"
              type="confirm"
            />
          }
          onOpen={() => this.setState({open: true})}
          open={this.state.open}
          onClose={this.onClose}
          header={{
            icon: 'fas fa-file-excel',
            content: i18n.t('misc.modules.searchTermsAudit'),
          }}
          leftActions={[<SeelkButton content={i18n.t('actions.global.cancel')} type="cancel" onClick={this.onClose} />]}
          rightActions={[
            // eslint-disable-next-line react/jsx-indent
            <LoaderWrapper
              requesting={['search-terms-audit.file.upload']}
              render={requesting => (
                <SeelkButton
                  type="confirm"
                  content={i18n.t('actions.upload.import')}
                  loading={requesting}
                  disabled={requesting || isDisabled}
                  onClick={() =>
                    importFile.fetch({file, marketplace, period, reportDate, reportName}, {}, this.onClose)
                  }
                />
              )}
            />,
          ]}
        >
          <StyledRow>
            <StyledCol>
              <StyledTitle>{i18n.t('misc.cross.marketplaces', {count: 1})}</StyledTitle>
              <DropdownSelector
                options={AVAILABLE_AMZ_COUNTRIES.filter(country => !['pl', 'cn'].includes(country)).map(country => ({
                  key: `amazon_${country}`,
                  text: i18n.t(`countries.${country}.name`),
                  value: `amazon_${country}`,
                  flag: country.toLowerCase(),
                }))}
                value={marketplace}
                onChange={this.setMarketplace}
                icon="fas fa-globe"
                placeholder={i18n.t('placeholders.empty.noMarketplace')}
              />
            </StyledCol>
            <StyledCol>
              <StyledTitle>{i18n.t('visualization.grouping.year')}</StyledTitle>
              <DropdownSelector
                options={availableYears.map(year => ({
                  key: year,
                  text: year,
                  value: year,
                }))}
                value={this.state.year}
                onChange={this.setYear}
              />
            </StyledCol>
            <StyledCol>
              <StyledTitle>{i18n.t('visualization.grouping.period')}</StyledTitle>
              <DropdownSelector
                options={PERIOD.map(key => ({
                  key,
                  text: _.capitalize(key),
                  value: key,
                }))}
                value={period}
                onChange={this.setPeriod}
              />
            </StyledCol>

            <StyledCol>
              <StyledTitle>{i18n.t('keywords.searchTermsAudit.reportDate')}</StyledTitle>
              <DropdownSelector
                options={availabeDates.map(key => ({
                  key,
                  text: this.dateFormat(key),
                  value: key,
                }))}
                disabled={!availabeDates.length}
                placeholder={availabeDates?.[0] ?? '-'}
                value={reportDate}
                onChange={this.setReportDate}
              />
            </StyledCol>
          </StyledRow>
          <StyledModalContent>
            <SeelkDropzone
              height="250px"
              type="default"
              onDrop={this.onDrop}
              file={this.state.file}
              accept="text/csv, application/vnd.ms-excel, application/vnd.oasis.opendocument.spreadsheet, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/x-csv, text/plain"
            />
          </StyledModalContent>
          <StyledRow>
            <StyledCol width="50%">
              <StyledTitle>{i18n.t('dashboards.labels.reportName')}</StyledTitle>
              <SeelkInput
                fluid
                type="text"
                value={reportName}
                onChange={this.setReportName}
                placeholder={i18n.t('dashboards.labels.reportName')}
              />
            </StyledCol>
          </StyledRow>
        </SeelkModal>
      </>
    )
  }

  render() {
    return (
      <Col>
        <StyledHeader>
          <span>{i18n.t('misc.modules.searchTermsAudit')}</span>
        </StyledHeader>
        <StyledContent>
          <Icon name="fas fa-search" />
          <h3>{i18n.t('misc.modules.searchTermsAudit')}</h3>
          <Row>{this.renderModal()}</Row>
        </StyledContent>
      </Col>
    )
  }
}

export default withRequests({
  importFile: requestWrapperBuilder({
    fetchAction: 'SEARCH_TERMS_AUDIT_IMPORT',
    requestingPath: 'search-terms-audit.file.upload',
    successMsg: 'Your file has been uploaded, you will receive the result by email very soon !',
  }),
})(
  connect({
    props: (state, ownProps) => ({
      ...ownProps,
    }),
  })(SearchTermsAudit)
)
