import React from 'react'

import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'

const StyledSortIcon = styled.button<{readonly active?: boolean}>`
  background: transparent;
  cursor: pointer;
  border: 0;
  outline: 0;
  color: ${colors.primary.rgba};
  opacity: ${props => (props.active ? 1 : 0.4)};
`

interface SortIconProps {
  onSort: (sort: 'desc' | 'asc') => void
  status?: 'desc' | 'asc' | null
}

const SortIcon = ({onSort, status = null}: SortIconProps) => {
  switch (status) {
    case 'asc':
      return (
        <StyledSortIcon type="button" onClick={() => onSort('desc')} active>
          <Icon name="chevron up" size="small" />
        </StyledSortIcon>
      )
    case 'desc':
      return (
        <StyledSortIcon type="button" onClick={() => onSort('asc')} active>
          <Icon name="chevron down" size="small" />
        </StyledSortIcon>
      )
    default:
      return (
        <StyledSortIcon type="button" onClick={() => onSort('asc')}>
          <Icon name="chevron up" size="small" />
        </StyledSortIcon>
      )
  }
}

export default SortIcon
