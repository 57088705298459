import React from 'react'

import {css, StyleSheet} from 'aphrodite'
import {Box} from 'jsxstyle'
import PropTypes from 'prop-types'
import {Button} from 'semantic-ui-react'

import colors from '../../../colors'

const styles = StyleSheet.create({
  switch: {
    background: colors.primaryLight.rgba,
    color: colors.primary.alpha(0.5).rgba,
    fontWeight: 'normal',
    ':hover': {
      color: colors.primary.rgba,
      textDecoration: 'underline',
    },
  },
  switchActive: {
    background: colors.primary.rgba,
    color: colors.lighterBackground.rgba,
    fontWeight: '500',
    ':hover': {
      color: colors.lighterBackground.rgba,
      textDecoration: 'none',
    },
  },
})

class Switch extends React.Component {
  static propTypes = {
    active: PropTypes.string,
    availableChoices: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
    customChoices: PropTypes.bool,
    formatString: PropTypes.func,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    active: null,
    customChoices: false,
    formatString: null,
  }

  handleItemClick = (e, {name}) => {
    this.props.onChange(name)
  }

  render() {
    const {active, availableChoices, customChoices, formatString} = this.props
    /* eslint-disable */

    return (
      <Box>
        <Button.Group>
          {customChoices
            ? availableChoices.map(item => (
                <Button
                  key={item.key}
                  className={`${css(styles.switch)} ${active === item.key ? css(styles.switchActive) : null}`}
                  active={active === item.key}
                  onClick={this.handleItemClick}
                  name={item.key}
                >
                  {item.value}
                </Button>
              ))
            : availableChoices.map(item => (
                <Button
                  key={item}
                  className={`${css(styles.switch)} ${active === item ? css(styles.switchActive) : null}`}
                  active={active === item}
                  onClick={this.handleItemClick}
                  name={item}
                >
                  {formatString(item)}
                </Button>
              ))}
        </Button.Group>
      </Box>
    )
    /* eslint-enable */
  }
}

export default Switch
