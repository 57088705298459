import BaseSeries from './BaseSeries'
import ExtraDimensionSeries from './ExtraDimensionSeries'

export default class CategoryManagementSeries extends ExtraDimensionSeries(BaseSeries) {
  createDimensions() {
    super.createDimensions()
    this.cf.dimensions.id = this.cf.data.dimension(v => v.id)
    this.cf.dimensions.keywordsNumber = this.cf.data.dimension(v => v.keywordsNumber)
    this.cf.dimensions.offersNumber = this.cf.data.dimension(v => v.offersNumber)
    this.cf.dimensions.scrapingFrequency = this.cf.data.dimension(v => v.scraping_frequency)
    this.cf.dimensions.subCategoriesNumber = this.cf.data.dimension(v => v.subCategoriesNumber)
  }

  updateFilters(values = {}, lockId = 'default') {
    super.updateFilters(values, lockId)
    const {
      id = null,
      keywordsNumber = {},
      offersNumber = {},
      scrapingFrequency = null,
      subCategoriesNumber = {},
    } = this.filters[lockId]
    this.filterDimension(this.cf.dimensions.id, id)
    this.filterDimension(
      this.cf.dimensions.keywordsNumber,
      keywordsNumber.minValue || null,
      keywordsNumber.maxValue || null
    )
    this.filterDimension(this.cf.dimensions.offersNumber, offersNumber.minValue || null, offersNumber.maxValue || null)
    this.filterDimension(this.cf.dimensions.scrapingFrequency, scrapingFrequency)
    this.filterDimension(
      this.cf.dimensions.subCategoriesNumber,
      subCategoriesNumber.minValue || null,
      subCategoriesNumber.maxValue || null
    )
  }
}
