import {BINARY_MASKS} from '../constants'
import i18n from '../i18n'
import {fuzzyWordsSearch} from '..'
import BaseSeries from './BaseSeries'
import ExtraDimensionSeries from './ExtraDimensionSeries'

class CatalogManagementSeries extends ExtraDimensionSeries(BaseSeries) {
  createDimensions() {
    super.createDimensions()
    this.cf.dimensions.marketplaces = this.cf.data.dimension(v => v.offer.marketplace.split('_')[0])
    this.cf.dimensions.countries = this.cf.data.dimension(v => {
      const country = v.offer.marketplace.split('_')[1]
      return country
    })
    this.cf.dimensions.asins = this.cf.data.dimension(v => v.offer.asin)
    this.cf.dimensions.tags = this.cf.data.dimension(v => v.tags || [])
    this.cf.dimensions.section = this.cf.data.dimension(v => v.section)
    this.cf.dimensions.amazonBrands = this.cf.data.dimension(v => v.brand || i18n.t('placeholders.empty.unclassified'))
    this.cf.dimensions.title = this.cf.data.dimension(v =>
      v.title ? v.title.toLowerCase() : i18n.t('placeholders.empty.untitled')
    )
    this.cf.dimensions.contentScore = this.cf.data.dimension(v => parseFloat(v.catalogue_health_score) || 0)
    this.cf.dimensions.offersScore = this.cf.data.dimension(v => parseFloat(v.buy_box_health_score) || 0)
    this.cf.dimensions.reviewsScore = this.cf.data.dimension(v => parseFloat(v.review_health_score) || 0)
    this.cf.dimensions.ratingScore = this.cf.data.dimension(
      v =>
        ((parseFloat(v.catalogue_health_score) || 0) +
          (parseFloat(v.buy_box_health_score) || 0) +
          (parseFloat(v.review_health_score) || 0)) /
        3
    )
    this.cf.dimensions.buyBox = this.cf.data.dimension(
      v =>
        // eslint-disable-next-line no-bitwise
        (v.has_active_buy_box ? BINARY_MASKS.ACTIVE : 0) |
        (v.is_prime_only ? BINARY_MASKS.PRIME_ONLY : 0) |
        (v.is_add_on_item ? BINARY_MASKS.ADD_ON : 0) |
        (v.is_pantry ? BINARY_MASKS.PANTRY : 0)
    )
    this.cf.dimensions.booleans = this.cf.data.dimension(
      v =>
        // eslint-disable-next-line no-bitwise
        (v.has_vendor_offer ? BINARY_MASKS.VENDOR_OFFER : 0) |
        (v.is_buy_box_owner ? BINARY_MASKS.BUY_BOX_OWNER : 0) |
        (v.is_404 ? BINARY_MASKS.INVALID_PRODUCT : 0) |
        (v.has_a_plus ? BINARY_MASKS.HAS_A_PLUS : 0) |
        (v.has_stock ? BINARY_MASKS.HAS_STOCK : 0)
    )
    this.cf.dimensions.status = this.cf.data.dimension(v => v.status)
    this.cf.dimensions.fbaSellers = this.cf.data.dimension(v => parseFloat(v.nb_fba_sellers) || 0)
    this.cf.dimensions.mfnSellers = this.cf.data.dimension(v => parseFloat(v.nb_mfn_sellers) || 0)
    this.cf.dimensions.bulletPoints = this.cf.data.dimension(v => (v.bullet_points ? v.bullet_points.length : 0))
    this.cf.dimensions.titleLength = this.cf.data.dimension(v => (v.title ? v.title.length : 0))
    this.cf.dimensions.descLength = this.cf.data.dimension(v => (v.description ? v.description.length : 0))
    this.cf.dimensions.images = this.cf.data.dimension(v => parseFloat(v.nb_images) || 0)
    this.cf.dimensions.videos = this.cf.data.dimension(v => parseFloat(v.nb_videos) || 0)
    this.cf.dimensions.reviews = this.cf.data.dimension(v => parseFloat(v.nb_reviews) || 0)
    this.cf.dimensions.averageRating = this.cf.data.dimension(v => parseFloat(v.weighted_average_rating) || 0)
    this.cf.dimensions.scrapingFrequency = this.cf.data.dimension(v => v.scraping_frequency)
  }

  updateFilters(values = {}, lockId = 'default') {
    super.updateFilters(values, lockId)
    const {
      marketplaces = null,
      countries = null,
      asins = null,
      amazonBrands = null,
      title = null,
      contentScore = {},
      offersScore = {},
      reviewsScore = {},
      ratingScore = {},
      buyBox = null,
      vendorOffer = null,
      buyBoxOwner = null,
      invalidProduct = null,
      hasAPlus = null,
      hasStockBool = null,
      status = null,
      fbaSellers = {},
      mfnSellers = {},
      bulletPoints = {},
      titleLength = {},
      descLength = {},
      images = {},
      videos = {},
      reviews = {},
      section = {},
      averageRating = {},
      scrapingFrequencies = null,
      offerTags = {slugs: []},
    } = this.filters[lockId]

    const buyBoxMask =
      // eslint-disable-next-line no-bitwise
      (buyBox === 'has_active_buy_box' ? BINARY_MASKS.ACTIVE : 0) |
      (buyBox === 'is_prime_only' ? BINARY_MASKS.PRIME_ONLY : 0) |
      (buyBox === 'is_add_on_item' ? BINARY_MASKS.ADD_ON : 0) |
      (buyBox === 'is_pantry' ? BINARY_MASKS.PANTRY : 0)

    const positiveBooleansMask =
      // eslint-disable-next-line no-bitwise
      (vendorOffer ? BINARY_MASKS.VENDOR_OFFER : 0) |
      (buyBoxOwner ? BINARY_MASKS.BUY_BOX_OWNER : 0) |
      (invalidProduct ? BINARY_MASKS.INVALID_PRODUCT : 0) |
      (hasAPlus ? BINARY_MASKS.HAS_A_PLUS : 0) |
      (hasStockBool ? BINARY_MASKS.HAS_STOCK : 0)

    const negativeBooleansMask =
      // eslint-disable-next-line no-bitwise
      (vendorOffer === false ? BINARY_MASKS.VENDOR_OFFER : 0) |
      (buyBoxOwner === false ? BINARY_MASKS.BUY_BOX_OWNER : 0) |
      (invalidProduct === false ? BINARY_MASKS.INVALID_PRODUCT : 0) |
      (hasAPlus === false ? BINARY_MASKS.HAS_A_PLUS : 0) |
      (hasStockBool === false ? BINARY_MASKS.HAS_STOCK : 0)

    this.filterDimension(this.cf.dimensions.marketplaces, marketplaces)
    this.filterDimension(this.cf.dimensions.countries, countries)
    this.filterDimension(this.cf.dimensions.asins, asins)
    this.filterDimension(this.cf.dimensions.amazonBrands, amazonBrands)
    this.filterDimension(
      this.cf.dimensions.title,
      title && typeof title === 'string' ? v => fuzzyWordsSearch(title, v) : null
    )
    this.filterDimension(
      this.cf.dimensions.contentScore,
      global.isFinite(contentScore.from) ? contentScore.from : null,
      global.isFinite(contentScore.to) ? contentScore.to : null
    )
    this.filterDimension(
      this.cf.dimensions.offersScore,
      global.isFinite(offersScore.from) ? offersScore.from : null,
      global.isFinite(offersScore.to) ? offersScore.to : null
    )
    this.filterDimension(
      this.cf.dimensions.reviewsScore,
      global.isFinite(reviewsScore.from) ? reviewsScore.from : null,
      global.isFinite(reviewsScore.to) ? reviewsScore.to : null
    )
    this.filterDimension(
      this.cf.dimensions.ratingScore,
      global.isFinite(ratingScore.from) ? ratingScore.from : null,
      global.isFinite(ratingScore.to) ? ratingScore.to : null
    )
    this.filterDimension(
      this.cf.dimensions.buyBox,
      // eslint-disable-next-line no-bitwise
      v => (buyBox === 'inactive' ? v === 0 : (buyBoxMask & v) === buyBoxMask)
    )
    this.filterDimension(
      this.cf.dimensions.booleans,
      v =>
        // eslint-disable-next-line no-bitwise
        (positiveBooleansMask & v) === positiveBooleansMask &&
        // eslint-disable-next-line no-bitwise
        ((v ^ negativeBooleansMask) & negativeBooleansMask) === negativeBooleansMask
    )
    this.filterDimension(this.cf.dimensions.status, status)
    this.filterDimension(
      this.cf.dimensions.section,
      !section || Object.values(section).every(s => s === false)
        ? ['MAIN', 'EXTR', 'DETA', 'COMP']
        : Object.entries(section)
            .filter(([k, v]) => v) // eslint-disable-line no-unused-vars
            .map(([k, v]) => k) // eslint-disable-line no-unused-vars
    )
    this.filterDimension(
      this.cf.dimensions.fbaSellers,
      fbaSellers && global.isFinite(fbaSellers.from) ? fbaSellers.from : null,
      fbaSellers && global.isFinite(fbaSellers.to) ? fbaSellers.to : null
    )
    this.filterDimension(
      this.cf.dimensions.mfnSellers,
      mfnSellers && global.isFinite(mfnSellers.from) ? mfnSellers.from : null,
      mfnSellers && global.isFinite(mfnSellers.to) ? mfnSellers.to : null
    )
    this.filterDimension(
      this.cf.dimensions.bulletPoints,
      bulletPoints && global.isFinite(bulletPoints.from) ? bulletPoints.from : null,
      bulletPoints && global.isFinite(bulletPoints.to) ? bulletPoints.to : null
    )
    this.filterDimension(
      this.cf.dimensions.titleLength,
      titleLength && global.isFinite(titleLength.from) ? titleLength.from : null,
      titleLength && global.isFinite(titleLength.to) ? titleLength.to : null
    )
    this.filterDimension(
      this.cf.dimensions.descLength,
      descLength && global.isFinite(descLength.from) ? descLength.from : null,
      descLength && global.isFinite(descLength.to) ? descLength.to : null
    )
    this.filterDimension(
      this.cf.dimensions.images,
      global.isFinite(images.from) ? images.from : null,
      global.isFinite(images.to) ? images.to : null
    )
    this.filterDimension(
      this.cf.dimensions.videos,
      global.isFinite(videos.from) ? videos.from : null,
      global.isFinite(videos.to) ? videos.to : null
    )
    this.filterDimension(
      this.cf.dimensions.reviews,
      global.isFinite(reviews.from) ? reviews.from : null,
      global.isFinite(reviews.to) ? reviews.to : null
    )
    this.filterDimension(
      this.cf.dimensions.averageRating,
      global.isFinite(averageRating.from) ? averageRating.from : null,
      global.isFinite(averageRating.to) ? averageRating.to : null
    )
    this.filterDimension(this.cf.dimensions.scrapingFrequency, scrapingFrequencies)

    this.filterDimension(this.cf.dimensions.tags, v => {
      if (!offerTags?.slugs?.length) {
        return true
      } else if (offerTags.matchingType === 'AND') {
        return !offerTags.slugs.some(t => !v.includes(t))
      } else if (offerTags.matchingType === 'OR') {
        return v.some(t => offerTags.slugs.includes(t))
      }
      return false
    })
  }
}

export default CatalogManagementSeries
