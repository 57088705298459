import React from 'react'

import {Box} from 'jsxstyle'
import {InputOnChangeData} from 'semantic-ui-react'

import colors from '../../../../../colors'
import i18n from '../../../../../i18n'
import theme from '../../../../../theme'
import {DebouncedInput, FilterButton} from '../../../..'
import FilterBarItem from './FilterBarItem'

const className = theme.createClassName('boldInput', {
  fontWeight: '500',
  color: colors.black.rgba,
})

interface TextProps {
  data: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>, obj: InputOnChangeData) => void
  onRemove: () => void
  title: string
  disabled?: boolean
  icon?: string | null
  placeholder?: string
  width?: number
}

const Text = ({
  data,
  onChange,
  onRemove,
  title,
  disabled = false,
  icon = null,
  placeholder = i18n.t('actions.search.text'),
  width = 100,
  ...barItemProps
}: TextProps) => {
  const trigger = (
    <FilterButton title={title} icon={icon} disabled={disabled} onRemove={onRemove} dropdown={false}>
      <Box width={`${width}px`} marginLeft="8px">
        <DebouncedInput
          value={data}
          className={className}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(e: React.ChangeEvent<HTMLInputElement>, obj: InputOnChangeData) => {
            onChange(e, obj)
          }}
        />
      </Box>
    </FilterButton>
  )

  return <FilterBarItem {...barItemProps} data={data} disabled={disabled} trigger={trigger} />
}

export default Text
