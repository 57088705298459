export default {
  cross: {
    active: 'Active',
    addOn: 'Add-On',
    addProducts: 'Add Products',
    asin: 'ASIN',
    asinWithValue: 'ASIN: {{asin}}',
    asinsEntered: {
      one: 'One ASIN entered',
      other: '{{count}} ASINS entered',
      zero: 'No ASIN entered',
    },
    averageValue: 'Average Value',
    bestSellers: 'Best sellers',
    boolean: 'True or False',
    brand: 'Brand',
    brands: 'Brands',
    breakdown: 'Breakdown',
    bulletPoints: 'Bullet Points',
    buyBox: 'Buy-box',
    buyBoxOwner: 'Buy Box Owner',
    categories: 'Categories',
    category: 'Category',
    categoryRank: 'Category Rank',
    changes: 'Changes',
    competitor: 'Competitor',
    conflict: 'Conflict',
    content: 'Content',
    copyAsins: 'Copy filtered ASINs',
    countries: {
      one: 'Country',
      other: 'Countries',
    },
    cpc: 'CPC',
    currency: 'Currency',
    currentPrice: 'Current Price',
    customAttribute: 'Custom Attribute',
    date: 'Date',
    description: 'Description',
    drilldown: 'Drill Down',
    ean: 'EAN',
    eanWithValue: 'EAN: {{ean}}',
    format: 'Format',
    frequencies: 'Frequencies',
    fresh: 'Fresh',
    getRecommendedKeywords: 'Get recommended keywords',
    goToAttributeManagement: 'Go to Attribute Management',
    goToCatalog: 'Go to Catalog',
    goToCatalogManagement: 'Go to Catalog Management',
    good: 'Good',
    images: 'Images',
    inactive: 'Inactive',
    inbound: 'Inbound',
    incomplete: 'Incomplete',
    insufficient: 'Insufficient',
    keywords: {
      one: 'Keyword',
      other: 'Keywords',
    },
    kind: 'Kind',
    lastUpdated: 'Last updated',
    limit: 'Limit',
    list: 'List',
    manually: 'Manually',
    marketplaces: {
      names: {
        amazon: 'Amazon',
      },
      one: 'Marketplace',
      other: 'Marketplaces',
    },
    match: 'Match',
    metric: 'Metric',
    metrics: 'Metrics',
    missed: 'Refused',
    myCatalog: 'My Catalog',
    nbOrders: 'Nb. Orders',
    needsImprovement: 'Needs improvement',
    noBuyBox: 'No buy box',
    noContent: 'No Content',
    noLimit: 'No Limit',
    noProductSelected: 'No product has been selected in the list',
    noRetailOffer: 'No Retail Offer',
    noStock: 'Out of Stock',
    offers: {
      one: 'Offer',
      other: 'Offers',
    },
    'offers-images': 'Offers (Images)',
    other: 'Other',
    others: 'Others',
    ourProducts: 'Our Products',
    outbound: 'Outbound',
    over: 'Over',
    pantry: 'Pantry',
    pipelines: {
      one: 'Pipeline',
      other: 'Pipelines',
    },
    prev: 'Prev.',
    price: 'Price',
    prime: 'Prime',
    primeOnly: 'Prime-Only',
    problem: 'Problem',
    products: {
      one: 'Product',
      other: 'Products',
    },
    purchaseOrders: 'Purchase Orders',
    quantity: 'Quantity',
    reviews: {
      one: 'Review',
      other: 'Reviews',
    },
    sales: 'Sales',
    salesRank: 'Sales Rank',
    saveFilters: "You're about to save the current filters you selected, give your filters set a name:",
    scores: 'Scores',
    scrapingDate: 'Last scraping date',
    scrapings: 'Scrapings',
    section: 'Section',
    selectedEntities: '{{count}} {{entity}} selected',
    selectItem: 'Select a {{item}} from the list to tag or group',
    selectToChangeFrequency: 'Select a product from the list to change the tracking frequency',
    selectToChangeSection: 'Select a product from the list to change the section',
    selectToChangeState: 'Select a product from the list to change the state of product',
    selectToSendRecommendedKeywords:
      'Select a or several products from the list to get the suggested keywords with bidding recommendations',
    selectToTagOrGroup: 'Select a product from the list to tag or group',
    seller: 'Seller',
    setLimit: 'Set a limit',
    since: 'Since',
    sku: 'SKU',
    sns: 'SNS',
    spends: 'Spends',
    status: 'Status',
    stock: 'In Stock',
    subject: 'Subject',
    text: 'Text',
    title: 'Title',
    titleTooltip: 'Exclude words (and numbers) by adding "!" before it.',
    today: 'Today',
    tooLong: 'Too Long',
    tooManyErrorsDownload: 'Too many errors to show. Click here to download the full list.',
    tooShort: 'Too Short',
    tools: 'Tools',
    tooltip: 'Tooltip',
    tooltips: 'tooltips',
    tooltipsPopupAnalysis: 'Having trouble? Learn from the Analysis Tooltips!',
    tooltipsPopupBI: 'Having trouble? Learn from the BI Tooltips!',
    tooltipsPopupCatalog: 'Having trouble? Learn from the Catalog Tooltips!',
    tooltipsPopupLogistics: 'Having trouble? Learn from the Logistics Tooltips!',
    tooltipsPopupMarketing: 'Having trouble? Learn from the Marketing Tooltips!',
    tooltipsPopupReviews: 'Having trouble? Learn from the Reviews Tooltips!',
    total: 'Total',
    units: 'Units',
    unknown: 'Unknown',
    value: 'Value',
    vendor: 'Vendor',
    vendorOffer: 'Vendor Offer',
    yesterday: 'Yesterday',
    small: 'Small',
    medium: 'Medium',
    large: 'Large',
    full: 'Full',
    warningFilters: '{{name}} already exists. Would you want to override it ? ',
    warningFiltersClick: "Click on 'Save' again to confirm",
  },
  global: {
    client: 'Client',
    comingSoon: 'Coming soon',
    dash: '—',
    dateRange: 'Date Range',
    email: 'EMAIL',
    emailSent: 'An email has been sent to {{email}} with instructions to reset your password. Check your inbox!',
    error: 'Error',
    general: 'General',
    global: 'Global',
    globalSales: 'Global sales',
    new: 'New',
    or: 'Or',
    password: 'PASSWORD',
    required: 'Required',
    with: 'With',
    without: 'Without',
  },
  modules: {
    account: 'Account Settings',
    campaignBuilder: 'Campaign Builder',
    advertisers: 'Advertisers',
    advertising: 'Advertising',
    bi: 'Business Intelligence',
    catalog: 'Catalog',
    categories: 'Categories',
    categoryManagement: 'Category Management',
    clientPermissions: 'Client Permissions',
    clientTracker: 'Client Tracker',
    competition: 'Competition',
    dashboards: 'Dashboards',
    dataManagement: 'Data Management',
    exports: 'Exports',
    inventory: 'Inventory',
    keywords: 'Competition',
    logistics: 'Logistics',
    marketing: 'Marketing',
    monitoring: 'Monitoring',
    myDashboards: 'My Dashboards',
    onboarding: 'Onboarding',
    pricing: 'Buy Box',
    rankings: 'Rankings',
    searchTermsAudit: 'Search Terms Audit',
    searchTermInsight: 'Search Term Insight',
    settings: 'Settings',
    ticketing: 'Ticketing',
  },
  subModules: {
    account: 'Account Settings',
    adGroup: {
      zero: 'Ad Groups',
      one: 'Ad Group',
      other: 'Ad Groups',
    },
    adgroups: 'Ad Groups',
    adGroupAttributes: {
      zero: 'Ad Group Attributes',
      one: 'Ad Group Attribute',
      other: 'Ad Group Attributes',
    },
    adGroupTags: {
      zero: 'Ad Group Tags',
      one: 'Ad Group Tag',
      other: 'Ad Group Tags',
    },
    advertisers: 'Advertisers',
    orders: 'Orders',
    lineitems: 'Line Items',
    creatives: 'Creatives',
    alerts: 'Alerts',
    amazonAccounts: 'Amazon Accounts',
    analysis: 'Analysis',
    analytics: 'Analytics',
    advertising: 'Advertising',
    attributes: {zero: 'Attributes', one: 'Attribute', other: 'Attributes'},
    budgeting: 'Budgeting',
    campaign: {
      zero: 'Campaigns',
      one: 'Campaign',
      other: 'Campaigns',
    },
    campaigns: 'Campaigns',
    campaignAttributes: {
      zero: 'Campaign Attributes',
      one: 'Campaign Attribute',
      other: 'Campaign Attributes',
    },
    campaignTags: {
      zero: 'Campaign Tags',
      one: 'Campaign Tag',
      other: 'Campaign Tags',
    },
    catalog: 'My Catalog',
    categories: 'Categories',
    collaborativeFilters: 'Collaborative Filters',
    collections: {zero: 'Collection', one: 'Collection', other: 'Collections'},
    compliance: 'Compliance',
    content: 'Content',
    demoMode: 'Demo Mode',
    details: 'Details',
    dynamicTags: 'Dynamic Tags',
    'dynamic-tags': 'Dynamic Tags',
    dsp: 'DSP',
    dspAdvertiser: 'DSP Advertiser',
    dspCreative: 'DSP Creative',
    dspLineItem: 'DSP Line Item',
    dspOrder: 'DSP Order',
    exports: 'Exports',
    expanseCalculator: 'Expanse calculator',
    filters: {zero: 'Filters', one: 'Filter', other: 'Filters'},
    // format: 'Currency & Date Format',
    format: 'Currency',
    frequency: 'Frequency',
    general: 'General',
    group: 'Group',
    groups: 'Groups',
    keywords: {
      zero: 'Keywords',
      one: 'Keyword',
      other: 'Keywords',
    },
    keywordTags: 'Keyword Tags',
    language: 'Language',
    logout: 'Logout',
    management: 'Management',
    marketplaces: 'Marketplaces',
    me: 'My Settings',
    members: 'Members',
    myAdGroups: 'My Ad Groups',
    myCampaigns: 'My Campaigns',
    myKeywords: 'My Keywords',
    myProfile: 'My Profile',
    navigation: 'Navigation',
    notifications: 'Notifications',
    offer: 'Offer',
    onboarding: 'Amazon Accounts',
    overview: 'Overview',
    owner: 'Owner',
    permissions: 'Permissions',
    pipelines: 'Pipelines',
    preferences: 'My Profile',
    pricing: 'Pricing & Profitability',
    products: 'Products',
    productTags: 'Product Tags',
    reviews: 'Reviews',
    scores: 'Score History',
    seller: 'Seller',
    vendor: 'Vendor',
    advertisingDep: 'Advertising Dependency',
    custom: 'Custom Dashboard',
    teammates: 'Teammates',
    tags: {zero: 'Tags', one: 'Tag', other: 'Tags'},
    yourFilters: 'Your Filters',
    usersPermissions: 'Users permissions',
  },
  clientPermissions: {
    email: 'Email',
    firstname: 'First name',
    lastname: 'Last name',
  },
}
