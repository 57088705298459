import React from 'react'

import {Box, Col, Row} from 'jsxstyle'
import {Checkbox} from 'semantic-ui-react'

import {getFrequencyColor} from '../../../../..'
import colors from '../../../../../colors'
import i18n from '../../../../../i18n'
import {SelectAll} from '../../../..'
import VerticalList from './VerticalList'

const frequenciesProvider = i18n.createProvider('visualization.grouping.frequencies')

const frequencyBox = (frequency: string | number) => (
  <Box
    key={frequency}
    marginLeft="8px"
    borderRadius="4px"
    padding="1px 3px 1px 3px"
    fontSize="14px"
    color={colors.white.rgba}
    backgroundColor={getFrequencyColor(frequency)}
  >
    {frequenciesProvider(frequency, {defaults: [{scope: 'visualization.grouping.frequencies.custom'}]})}
  </Box>
)

type ItemType = string | number

interface FrequenciesProps {
  data: ItemType[]
  choices: ItemType[]
  onChange: (item: ItemType) => void
  onRemove: () => void
  title: string
  disabled: boolean
  bulkChange?: (choices: ItemType[]) => void
  onApply: () => void
  onClose: () => void
}

// Frequencies.defaultProps = {
//   disabled: false,
// }

const Frequencies = ({disabled = false, ...props}: FrequenciesProps) => {
  return (
    <VerticalList
      {...props}
      disabled={disabled}
      selectAll={
        props.bulkChange ? (
          <SelectAll<ItemType> choices={props.choices} data={props.data} bulkChange={props.bulkChange} />
        ) : null
      }
      renderItem={({data, item, onChange}) => {
        if (!item) {
          return (
            <Row alignItems="center">
              {data.length > 0 && data.map(frequencyBox)}
              {data.length === 0 && <Box marginLeft="8px">{i18n.t('filters.all')}</Box>}
            </Row>
          )
        }
        return (
          <Col key={item} minWidth="120px">
            <Row alignItems="center" marginTop={5}>
              <Checkbox checked={data && data.indexOf(item) !== -1} onChange={e => onChange(item)} />
              {frequencyBox(item)}
            </Row>
          </Col>
        )
      }}
    />
  )
}

export default Frequencies
