import React, {Component} from 'react'

import PropTypes from 'prop-types'
import {Breadcrumb} from 'semantic-ui-react'

export default class BreadcrumbNavigator extends Component {
  static propTypes = {
    branch: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    tree: PropTypes.object.isRequired,
  }

  static defaultProps = {}

  handleSelect = (depth, value) => {
    const newBranch = [...this.props.branch.slice(0, depth), value]
    this.props.onChange(newBranch)
  }

  renderBranchlet = (subtree, branchlet, depth) => {
    const {children, component} = subtree
    const stem = branchlet.shift()
    const stemElem = children.find(c => c.id === stem)
    const node = (
      <React.Fragment>
        <Breadcrumb.Section active>
          {React.createElement(component, {
            selectedValue: stemElem,
            values: children,
            active: !branchlet.length,
            isLeaf: children.length === 0,
            onSelect: value => this.handleSelect(depth, value),
          })}
        </Breadcrumb.Section>
        {stemElem && stemElem.children.length > 0 && <Breadcrumb.Divider />}
      </React.Fragment>
    )
    let downNode = null
    if (children.length && stem) {
      downNode = this.renderBranchlet(stemElem, branchlet, depth + 1)
    }
    return (
      <React.Fragment>
        {node}
        {downNode}
      </React.Fragment>
    )
  }

  render() {
    return <Breadcrumb size="large">{this.renderBranchlet(this.props.tree, [...this.props.branch], 0)}</Breadcrumb>
  }
}
