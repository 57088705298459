import React from 'react'

import {Icon, Label} from 'semantic-ui-react'
import styled, {css} from 'styled-components'

import i18n from '../../../i18n'

export type SizeSeelkMetricChangeType = 'small' | 'medium' | 'large'
export type TypeSeelkMetricChangeType = 'pp' | 'percentage' | 'value' | 'currency' | 'error'

interface StyledLabelProps {
  theme: any
  intent: string
  size: string
}

const StyledLabelCSS = ({theme, intent, size}: StyledLabelProps) => css`
  color: red !important;
  background-color: ${theme.metricChange.colors[intent].backgroundColor} !important;
  color: ${theme.metricChange.colors[intent].color} !important;
  font-size: ${theme.metricChange[size].fontSize} !important;
  font-weight: ${theme.metricChange[size].fontWeight} !important;
  height: fit-content;
  align-self: baseline;
  flex: none;
`

const StyledLabel = styled(Label)`
  &&&&&&& {
    ${(props: StyledLabelProps) => StyledLabelCSS(props)}
  }
`

const StyledErrorMessage = styled.span`
  font-weight ${props => props.theme.metricChange.error.fontWeight};
  padding-left: ${props => props.theme.metricChange.error.paddingLeft};
`

export interface SeelkMetricChangeProps {
  currencyCode?: string | null
  error?: string
  precision?: number
  reverse?: boolean
  size?: SizeSeelkMetricChangeType
  type?: TypeSeelkMetricChangeType | null
  value?: number
}

const SeelkMetricChange = ({
  currencyCode = null,
  error = i18n.t('placeholders.errors.anErrorOccured'),
  precision = 0,
  reverse = false,
  size = 'medium',
  type = null,
  value = 0,
}: SeelkMetricChangeProps) => {
  const getIcon = () => {
    if (type === 'error') return 'exclamation'
    if (value > 0) return 'triangle up'
    if (value < 0) return 'triangle down'
    return null
  }

  const getIntent = () => {
    if (value > 0) return reverse ? 'negative' : 'positive'
    else if (value < 0 || type === 'error') return reverse ? 'positive' : 'negative'
    return 'neutral'
  }

  const getNormedValue = () => {
    switch (type) {
      case 'pp':
        return `${i18n.toNumber(value, {precision, strip_insignificant_zeros: true})}pp`
      case 'percentage':
        return i18n.toPercentage(value, {precision})
      case 'currency':
        return i18n.toCurrency(i18n.toNumber(value, {precision}), {precision, code: currencyCode})
      case 'error':
        return (
          <>
            {error}
            <StyledErrorMessage>{value}</StyledErrorMessage>
          </>
        )
      case 'value':
        return i18n.toNumber(value, {precision})
      default:
        return i18n.t('placeholders.empty.notAvailable')
    }
  }

  const icon = getIcon()
  const intent = getIntent()
  const normedValue = getNormedValue()

  return (
    <StyledLabel intent={intent} size={size}>
      {icon && <Icon name={icon} />}
      {normedValue}
    </StyledLabel>
  )
}

export default SeelkMetricChange
