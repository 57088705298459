import {Reliever} from 'react-redux-reliever'

class ProgressReliever extends Reliever {
  ACTION_PREFIX = 'REQUESTING'

  reducer(state, action) {
    switch (action.type) {
      case 'REQUESTING_START':
        return state.setIn([...action.setIn, 'requesting'], true).setIn([...action.setIn, 'progress'], 0)
      case 'REQUESTING_SUCCEEDED':
      case 'REQUESTING_FAILED':
        return state.setIn([...action.setIn, 'requesting'], false).setIn([...action.setIn, 'progress'], 100)
      case 'REQUESTING_UPDATE_PROGRESS':
        return state.setIn([...action.setIn, 'progress'], action.progress)
      default:
        return super.reducer(state, action)
    }
  }
}

export default ProgressReliever
