import React from 'react'

import {Row} from 'jsxstyle'
import {Flag, FlagNameValues, Label} from 'semantic-ui-react'
import styled from 'styled-components'

import i18n from '../../../../../i18n'
import FilterButton from '../../../FilterButton'
import SearchableSelect from '../../../inputs/SearchableSelect'
import FilterBarItem from './FilterBarItem'

const StyledLabel = styled(Label)`
  &&&&& {
    display: flex;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px 8px;
  }
`

type DepartmentType = {
  marketplace: string
  department: string
}

interface DepartmentsProps {
  choices: Record<string, string[]>
  data: DepartmentType | null
  onApply: () => void
  onChange: (changes: DepartmentType | null) => void
  onClose: () => void
  onRemove: () => void
  title: string
  disabled?: boolean
  icon?: string | null
  operator?: 'in' | 'not_in'
  updateOperator?: ((operator: 'in' | 'not_in') => void) | null
}

interface DepartmentsState {
  currentFilters: any
}

class Departments extends React.Component<DepartmentsProps, DepartmentsState> {
  constructor(props: DepartmentsProps) {
    super(props)
    this.state = {
      currentFilters: props.data,
    }
  }

  formatDepartment = ({department, marketplace}: {department: string; marketplace: string}) => {
    const [, country] = marketplace.split('_')
    return (
      <StyledLabel>
        <Flag name={country as FlagNameValues} /> {i18n.t(`countries.${country}.name`)}
        <span style={{margin: 'auto 6px'}}>{'>'}</span>
        {department}
      </StyledLabel>
    )
  }

  render() {
    const {
      choices,
      data,
      onApply,
      onChange,
      onClose,
      onRemove,
      title,
      disabled = false,
      icon = null,
      operator = 'in',
      updateOperator = null,
      ...barItemProps
    } = this.props
    const trigger = (
      <FilterButton title={title} icon={icon} onRemove={onRemove} onApply={onApply}>
        <Row alignItems="center" marginLeft="8px">
          <Row alignItems="center">{data ? this.formatDepartment(data) : null}</Row>
        </Row>
      </FilterButton>
    )

    return (
      <FilterBarItem
        {...barItemProps}
        containsPortal
        data={this.state.currentFilters}
        disabled={disabled}
        onApply={onApply}
        onChange={onChange}
        onClose={onClose}
        trigger={trigger}
        wrapped={false}
      >
        <SearchableSelect
          title={title}
          placeholder={i18n.t('filters.department')}
          icon={icon}
          onChange={({key, value}) => this.props.onChange({marketplace: key, department: value})}
          onApply={() => [this.setState({currentFilters: data}), onApply()]}
          onClose={onClose}
          choices={choices}
          updateOperator={updateOperator}
          operator={operator}
        />
      </FilterBarItem>
    )
  }
}

export default Departments
