import React from 'react'

import PropTypes from 'prop-types'
import {CircularProgressbar} from 'react-circular-progressbar'

import {lerp3Colors} from '../../..'
import colors from '../../../colors'
import {SeelkMetricChange} from '../../../common-ui'
import i18n from '../../../i18n'

// import {Percentage} from 'utils/visualization'
import './Percent.css'

const Percent = ({value, placehold, style, color, circular, extra, item}) => {
  if (value === null) return <b>{i18n.t('placeholders.empty.notAvailable')}</b>
  if (placehold)
    return <b style={{fontSize: '1.2em'}}>{value ? `${value}%` : i18n.t('placeholders.empty.notAvailable')}</b>
  const _color =
    color || lerp3Colors(`#${colors.negative.hex}`, `#${colors.warning.hex}`, `#${colors.positive.hex}`, value / 100)
  const diff = extra && extra.diff && item && item[extra.diff]
  return (
    <div style={style || {padding: 10}}>
      {circular ? (
        <CircularProgressbar
          styles={{
            path: {
              stroke: _color,
              fill: _color,
              strokeLinecap: 'round',
              transition: 'stroke-dashoffset 0.5s ease 0s',
            },
            text: {
              stroke: _color,
              fill: _color,
            },
          }}
          value={value}
          text={`${value}%`}
          initialAnimation
        />
      ) : (
        <div style={{color: _color, fontWeight: 500}}>{`${value} %`}</div>
      )}
      {diff != null && <SeelkMetricChange value={diff || 0} size="small" />}
    </div>
  )
}

Percent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  diff: PropTypes.number,
  placehold: PropTypes.bool,
  style: PropTypes.object,
  color: PropTypes.string,
  circular: PropTypes.bool,
  extra: PropTypes.object,
  item: PropTypes.object,
}

Percent.defaultProps = {
  value: null,
  diff: null,
  placehold: false,
  style: null,
  color: null,
  circular: true,
  extra: null,
  item: null,
}

export default Percent
