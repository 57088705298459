export default {
  cross: {
    marketplaces: {
      names: {
        amazon: 'amazon',
      },
      plural: 'Marketplaces',
      singular: 'Marketplace',
    },
  },
  modules: {
    attributes: 'Attributs',
  },
}
