import React from 'react'

import {Box, Col} from 'jsxstyle'
import {Checkbox} from 'semantic-ui-react'

import colors from '../../../../../colors'
import i18n from '../../../../../i18n'
import {FilterButton} from '../../../..'
import FilterBarItem from './FilterBarItem'

interface TogglerProps {
  data: boolean
  onApply: () => void
  onChange: (value: boolean) => void
  onRemove: () => void
  title: string
  toggled: string
  untoggled: string
  disabled?: boolean
  icon?: string | null
}

// Toggler.defaultProps = {
//   data: undefined,
//   icon: null,
//   disabled: false,
// }

const Toggler = ({
  data,
  onApply,
  onChange,
  onRemove,
  title,
  toggled,
  untoggled,
  disabled = false,
  icon = null,
  ...barItemProps
}: TogglerProps) => {
  const txt = data ? toggled : untoggled

  const trigger = (
    <FilterButton
      title={title}
      icon={icon}
      disabled={disabled}
      onRemove={onRemove}
      onApply={onApply}
      dropdown={undefined}
    >
      <Box marginLeft={8}>{txt}</Box>
    </FilterButton>
  )

  return (
    <FilterBarItem
      {...barItemProps}
      data={data}
      onChange={value => onChange(!value)}
      onApply={onApply}
      disabled={disabled}
      trigger={trigger}
    >
      <Col width={150} cursor="default" padding="16px 0px 16px 0px">
        <Col padding="0px 8px 0px 8px" color={colors.dark.rgba} fontWeight="500">
          <Checkbox
            disabled={disabled}
            onClick={() => onChange(data)}
            checked={data}
            label={{
              children: (
                <Box>
                  <span>{i18n.t('filters.productsWithoutMasterContent')}</span>
                </Box>
              ),
            }}
            className="checkbox-element"
          />
        </Col>
      </Col>
    </FilterBarItem>
  )
}

export default Toggler
