import {Subject} from 'rxjs'

export default class MixinBuilder {
  constructor(component) {
    this.mixinAdapters = []
    this.component = component
  }

  add(adapter) {
    this.mixinAdapters.push(adapter)
    return this
  }

  _addSubjectFor(method) {
    return this.add(instance => {
      const subjectKey = `${method}$`
      instance[subjectKey] = new Subject() // eslint-disable-line no-param-reassign
      instance[method] = instance[method] || (() => {}) // eslint-disable-line no-param-reassign
      const fn = instance[method].bind(instance)
      const patchd = (...args) => {
        try {
          instance[subjectKey].next(...args)
          return fn(...args)
        } catch (err) {
          instance[subjectKey].error(err)
          return null
        }
      }
      instance[method] = patchd // eslint-disable-line no-param-reassign
    })
  }

  assign(props) {
    return this.add(instance => Object.assign(instance, props))
  }

  addSubjectFor(...methods) {
    return methods.reduce((p, method) => p._addSubjectFor(method), this)
  }

  patch(methodName, patchdMethod) {
    return this.add(instance => {
      instance[methodName] = instance[methodName] || (() => {}) // eslint-disable-line no-param-reassign
      const fn = instance[methodName].bind(instance)
      // eslint-disable-next-line no-param-reassign
      instance[methodName] = (...args) => patchdMethod(instance, fn, ...args)
    })
  }

  build(statics = {}) {
    const {mixinAdapters, component} = this
    const Class = class extends component {
      constructor(props) {
        super(props)
        mixinAdapters.forEach(adapter => adapter(this))
      }
    }
    return Object.assign(Class, statics)
  }
}
