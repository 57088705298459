export default {
  menu: {
    general: 'General',
    marketplaces: 'Marketplaces',
    account: 'Compte',
    filters: 'Filtres',
    language: 'Langage',
    notifications: 'Notifications',
    logout: 'Se déconnecter',
  },
  general: {
    feedback: 'Feedback',
    title: "You saw a bug, have a suggestion or simply want to say something ?\nWe'd like to hear your feedback !",
    generalFeedback: 'Submit a general feedback',
    dataFeedback: 'Submit a data related feedback',
  },
  preferences: {
    updatingLanguage: 'Changement du langage vers {{language}}',
    filters: {
      countries: {
        defaultCountries: 'Select default countries',
      },
      displayedCatalogColumns: {
        selectColumns: 'Select catalog columns to hide and display',
        lhs: 'Displayed columns',
        rhs: 'Hidden columns',
        names: {
          buyBox: 'Buy box',
          buyBoxOwner: 'Buy box owner',
          sns: 'SNS',
          fastTrack: 'Fast track',
          primeOnly: 'Prime only',
          vendorOffer: 'Vendor offer',
          vendorPrice: 'Vendor price',
          buyBoxPrice: 'Buy box price',
          fbaSellers: 'FBA sellers',
          mfnSellers: 'MFN sellers',
          titleLength: 'Title length',
          bullets: 'Bullets',
          images: 'Images',
          videos: 'Videos',
          'a+': 'A+',
          '#': '#',
          avg: 'AVG',
          rank: 'Rank',
        },
      },
    },
  },
  languages: {
    chooseLanguage: 'Choisir la langue',
  },
}
