import React, {useState} from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import colors from '../../colors'
import i18n from '../../i18n'
import OutsideClickHandler from '../../OutsideClickHandler'

const StyledDropdown = styled.div`
  position: relative;
  z-index: 999;
  font-weight: 400;
  cursor: pointer;
`

interface StyledTriggerProps {
  readonly triggered: boolean
}

const StyledTrigger = styled.div<StyledTriggerProps>`
  display: flex;
  flex-direction: row;
  padding: 3px 5px;
  align-items: baseline;
  justify-content: space-between;
  border-radius: 4px;
  font-weight: 500;
  background-color: ${props =>
    props.triggered ? props.theme.button.trigger.hoverBackgroundColor : props.theme.button.trigger.backgroundColor};
  border: ${props => props.theme.button.trigger.border};
  border-color: ${props =>
    props.triggered ? props.theme.button.trigger.hoverBorderColor : props.theme.button.trigger.borderColor};
  color: ${colors.primary.rgba};
  transition: border 0.1s ease, color 0.1s ease, box-shadow 0.1s ease;
  :hover {
    background-color: ${props => props.theme.button.trigger.hoverBackgroundColor};
    border-color: ${props => props.theme.button.trigger.hoverBorderColor};
    color: ${props => props.theme.button.trigger.hoverColor};
    box-shadow: 0px 0px 4px ${colors.shadow.rgba};
  }
`

const StyledMenu = styled.div`
  position: absolute;
  width: 100%;
  top: 30px;
  overflow: hidden;
  box-shadow: 0 2px 3px 0 ${colors.lightShadow.rgba};
  border-radius: 4px;
  background-color: ${colors.white.rgba};
  border: ${props => props.theme.button.trigger.border};
  border-color: ${props => props.theme.button.trigger.borderColor};
  color: ${props => props.theme.button.trigger.color};
`

interface StyledItemProps {
  readonly active: boolean
}

const StyledItem = styled.div<StyledItemProps>`
  padding: 10px 15px;
  font-weight: ${props => (props.active ? '500' : '400')};
  color: ${colors.dark.rgba};
  background-color: ${props => (props.active ? colors.lightAccent.rgba : colors.white.rgba)};
  :hover {
    background-color: ${props => (props.active ? colors.lightAccent.rgba : colors.black.alpha(0.05).rgba)};
  }
`

const StyledIcon = styled.i`
  margin-right: 10px;
`
const StyledCaret = styled.i`
  margin-right: 3px;
`

const OPTIONS = [
  {key: 'in', value: 'in', text: i18n.t('filters.operators.in')},
  {key: 'not_in', value: 'not_in', text: i18n.t('filters.operators.notIn')},
] as const

interface OperatorInNotInProps {
  operator: typeof OPTIONS[number]['value']
  updateOperator: (operator: 'in' | 'not_in') => void
  disabled?: boolean
}

const OperatorInNotIn = ({operator = OPTIONS[0].value, updateOperator, disabled = false}: OperatorInNotInProps) => {
  const [open, setOpen] = useState(false)
  const textOperator = OPTIONS.find(({value}) => value === operator)?.text ?? OPTIONS[0].text
  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <StyledDropdown>
        <StyledTrigger onClick={() => setOpen(!open)} triggered={open}>
          <div>
            <StyledIcon className="fas fa-filter" />
            {textOperator}
          </div>
          <StyledCaret className="fas fa-caret-down" />
        </StyledTrigger>
        {open && !disabled ? (
          <StyledMenu>
            {OPTIONS.map(({key, value, text}) => (
              <StyledItem
                active={value === operator}
                key={key}
                onClick={() => {
                  updateOperator(value)
                  setOpen(false)
                }}
              >
                {text}
              </StyledItem>
            ))}
          </StyledMenu>
        ) : null}
      </StyledDropdown>
    </OutsideClickHandler>
  )
}

OperatorInNotIn.propTypes = {
  operator: PropTypes.string,
  updateOperator: PropTypes.func.isRequired,
}

OperatorInNotIn.defaultProps = {
  operator: null,
}

export default OperatorInNotIn
