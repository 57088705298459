import React from 'react'

import {Icon} from 'semantic-ui-react'

import colors from '../../colors'

const PopUpLink = ({value = null}: {value?: string | null}) => {
  if (value) return <Icon name="external" onClick={() => global.open(value)} style={{color: colors.primary}} />
  return null
}

export default PopUpLink
