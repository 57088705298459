export default {
  allowList: "Not getting our emails? Make sure that you've added studio@seelk.io to your allowlist.",
  deliveryDate: 'Delivery Date',
  frequencyDelivery: 'Frequency Delivery',
  noRecipients: 'Note that if no recipients are selected, only you will receive this export via email.',
  recipients: 'Recipients',
  selectDeliveryDate: 'Select a delivery date...',
  selectFrequencyDelivery: 'Select a frequency delivery...',
  selectRecipients: 'Select recipients...',
  scheduledMonthly: 'Monthly on the {{deliveryDate}}',
  scheduledWeekly: 'Weekly on {{deliveryDate}}s',
  shareViaEmail: 'Share via email',
}
