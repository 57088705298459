import React, {useCallback, useEffect, useState} from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import {Form} from 'semantic-ui-react'
import styled from 'styled-components'

import {get, validNumber} from '../../utils'
import colors from '../../utils/colors'
import {SeelkBadge} from '../../utils/common-ui'
import {SCRAPING_PRICES, SECONDS_IN_YEAR} from '../../utils/constants'
import i18n from '../../utils/i18n'
import {ScrollableGrid} from '../../utils/visualization'

const ClientCell = ({value}) => {
  return <span style={{fontWeight: 500}}>{value}</span>
}

ClientCell.propTypes = {
  value: PropTypes.string,
}

ClientCell.defaultProps = {
  value: '',
}

const separatorCellStyle = {
  borderLeft: `1px solid ${colors.lightDark.rgba}`,
  paddingLeft: '10px',
  paddingRight: '10px',
}

const headerStyle = {
  alignItems: 'flex-end',
  paddingBottom: '10px',
}

const sortValues = (dir, key) => {
  return (lhs, rhs) => {
    const lhsValue = get(lhs, key, {}).value
    const rhsValue = get(rhs, key, {}).value
    if (dir === 'asc') return lhsValue > rhsValue ? 1 : -1
    return lhsValue < rhsValue ? 1 : -1
  }
}

const StyledCountCellInput = styled(Form.Input)`
  &&&&& {
    opacity: 0.5;
    &:hover,
    &:focus-within,
    input:hover,
    div.disabled {
      opacity: 1 !important;
    }
    input {
      color: white;
      padding: 6px;
      background-color: ${props => props.color};
      transition: background-color 0.3s;
      text-align: center;
      ${props => (!props.disabled ? '' : 'border: none;')}
    }
    input:focus {
      box-shadow: 0em 0em 0.357em ${props => props.color};
    }
    input::placeholder {
      color: white;
    }
    input:focus::placeholder {
      color: transparent;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
`

const StyledForm = styled(Form)`
  &&&&& {
    padding: 0 !important;
    opacity: 1 !important;
    &:hover div,
    & div:hover {
      opacity: 1 !important;
    }
  }
`

const AccountTypesCell = ({value, item}) => {
  const displayFlexStyle = {display: 'flex'}
  const accountTypes = _.lowerCase(value).split(' ')
  const allBadges = accountTypes.map((type, i) =>
    type.length !== 0 ? (
      <SeelkBadge type={type} key={item.client + type} position={i === 0 ? {} : {left: '0.4em'}} />
    ) : null
  )

  return <div style={displayFlexStyle}>{allBadges}</div>
}

AccountTypesCell.propTypes = {
  value: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
}

const ClientTrackerGrid = ({flattenedResults, selectedPane, editable, setEstimatedTotalCosts}) => {
  const [results, setResults] = useState(flattenedResults)

  const columnsProvider = i18n.createProvider('visualization.columns')

  const generateClientsData = useCallback(
    calculatorData => {
      const data = [...calculatorData]

      if (!data.length || !Object.keys(data).length) return []
      else {
        const getSumForType = (clientData, keys, type) => {
          return keys.reduce((parent, key) => {
            const frequency = parseFloat(key.split(type)[1])

            if (frequency === 0) return parent
            // eslint-disable-next-line no-param-reassign
            else if (frequency === 86400000000) parent += clientData[key]
            else {
              const multiplier = validNumber(SECONDS_IN_YEAR / frequency)
              parent += multiplier * clientData[key] // eslint-disable-line no-param-reassign
            }

            return parent
          }, 0)
        }

        const formattedData = data.map(clientData => {
          const categoriesKeys = Object.keys(clientData).filter(
            item => item.startsWith('categories') && !item.endsWith('Number') && !item.endsWith('MaxValue')
          )
          const keywordsKeys = Object.keys(clientData).filter(
            item => item.startsWith('keywords') && !item.endsWith('Number') && !item.endsWith('MaxValue')
          )
          const productsKeys = Object.keys(clientData).filter(
            item => item.startsWith('products') && !item.endsWith('Number') && !item.endsWith('MaxValue')
          )

          const getKeys = value => {
            if (value === 'categories') return categoriesKeys
            if (value === 'keywords') return keywordsKeys
            if (value === 'products') return productsKeys
            return null
          }

          const estimatedPrice =
            selectedPane === 'all'
              ? getSumForType(clientData, categoriesKeys, 'categories') * SCRAPING_PRICES.categories +
                getSumForType(clientData, keywordsKeys, 'keywords') * SCRAPING_PRICES.keywords +
                getSumForType(clientData, productsKeys, 'products') * SCRAPING_PRICES.products
              : getSumForType(clientData, getKeys(selectedPane), selectedPane) * SCRAPING_PRICES[selectedPane]

          return {
            ...clientData,
            accountTypes: clientData.accountTypes
              .split(', ')
              .map(type => _.upperFirst(type))
              .join(', '),
            estimatedPrice: {
              value: estimatedPrice,
              display: i18n.toCurrency(estimatedPrice, {code: 'EUR'}),
            },
          }
        })

        return formattedData
      }
    },
    [selectedPane]
  )

  useEffect(() => {
    if (editable) setResults(r => generateClientsData(r))
  }, [editable, selectedPane, generateClientsData])

  const DisplayComponent = ({value}) => <div>{typeof value === 'number' ? value : value.display}</div>

  DisplayComponent.propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
  }

  const CountCell = ({item, frequency, schemeKey, value}) => {
    const [currentValue, setCurrentValue] = useState(value || i18n.t('placeholders.empty.notAvailable'))
    const isDisabled = () => {
      if (frequency === '0') return true
      if (schemeKey === 'productsCustom' || schemeKey === 'keywordsCustom' || schemeKey === 'categoriesCustom')
        return true
      return !editable
    }
    const newResults = results.map(e => {
      if (e.client === item.client) return {...e, [schemeKey]: parseInt(currentValue, 10)}
      return e
    })

    const handleOnChange = (e, data) =>
      setCurrentValue(data.value === '' ? i18n.t('placeholders.empty.notAvailable') : data.value)
    const handleFormSubmit = () => setResults(generateClientsData(newResults))
    const handleOnBlur = () => setResults(generateClientsData(newResults))

    const getColor = () => {
      if (frequency === '0') return colors.black.rgba
      if (currentValue > 400) return colors.negative.rgba
      if (currentValue > 300) return colors.accent.rgba
      if (currentValue > 200) return colors.warning.rgba
      if (currentValue > 0) return colors.positive.rgba
      return colors.darkAccent.rgba
    }

    useEffect(() => {
      setCurrentValue(value || i18n.t('placeholders.empty.notAvailable'))
    }, [value])

    return (
      <StyledForm onSubmit={handleFormSubmit} onBlur={handleOnBlur}>
        <StyledCountCellInput
          type="number"
          min={0}
          onChange={handleOnChange}
          disabled={isDisabled()}
          placeholder={currentValue}
          color={getColor()}
        />
      </StyledForm>
    )
  }

  CountCell.propTypes = {
    frequency: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    schemeKey: PropTypes.string.isRequired,
    value: PropTypes.number,
  }

  CountCell.defaultProps = {
    value: null,
  }

  const defaultSchemes = {
    client: {
      canGrow: false,
      canShrink: false,
      key: 'client',
      label: columnsProvider('client'),
      sort: true,
      size: 200,
      component: ClientCell,
      headerStyle,
      props: {
        alignRotate: true,
      },
    },
    clientType: {
      canGrow: false,
      canShrink: false,
      key: 'accountTypes',
      label: columnsProvider('accountType'),
      sort: true,
      size: 130,
      component: AccountTypesCell,
      headerStyle,
      props: {
        alignRotate: true,
      },
    },
    estimatedPrice: {
      canGrow: false,
      canShrink: false,
      key: 'estimatedPrice',
      label: columnsProvider('estimatedPrice'),
      sort: sortValues,
      size: 150,
      style: separatorCellStyle,
      component: DisplayComponent,
      headerStyle,
      props: {
        alignRotate: true,
      },
    },
  }

  const productsFrequenciesColumns = [
    'products3600',
    'products14400',
    'products86400',
    'products604800',
    'products2592000',
    'productsCustom',
    'products86400000000',
    'products0',
  ].reduce((parent, key) => {
    // eslint-disable-next-line no-param-reassign
    parent[key] = {
      canGrow: false,
      canShrink: false,
      key,
      label: i18n.t(`visualization.grouping.frequencies.${key.split('products')[1].toLowerCase()}`),
      sort: true,
      size: 75,
      component: CountCell,
      props: {
        rotatedHeader: true,
        type: 'products',
        frequency: key.split('products')[1],
        editable,
      },
      headerStyle,
      style: {
        paddingLeft: 6,
        paddingRight: 6,
      },
    }
    return parent
  }, {})
  const keywordsFrequenciesColumns = [
    'keywords3600',
    'keywords14400',
    'keywords86400',
    'keywords604800',
    'keywords2592000',
    'keywordsCustom',
    'keywords86400000000',
    'keywords0',
  ].reduce((parent, key) => {
    // eslint-disable-next-line no-param-reassign
    parent[key] = {
      canGrow: false,
      canShrink: false,
      key,
      label: i18n.t(`visualization.grouping.frequencies.${key.split('keywords')[1].toLowerCase()}`),
      sort: true,
      size: 75,
      component: CountCell,
      props: {
        rotatedHeader: true,
        type: 'keywords',
        frequency: key.split('keywords')[1],
        editable,
      },
      headerStyle,
      style: {
        paddingLeft: 6,
        paddingRight: 6,
      },
    }
    return parent
  }, {})
  const categoriesFrequenciesColumns = [
    'categories3600',
    'categories14400',
    'categories86400',
    'categories604800',
    'categories2592000',
    'categoriesCustom',
    'categories86400000000',
    'categories0',
  ].reduce((parent, key) => {
    // eslint-disable-next-line no-param-reassign
    parent[key] = {
      canGrow: false,
      canShrink: false,
      key,
      label: i18n.t(`visualization.grouping.frequencies.${key.split('categories')[1].toLowerCase()}`),
      sort: true,
      size: 75,
      component: CountCell,
      props: {
        rotatedHeader: true,
        type: 'categories',
        frequency: key.split('categories')[1],
        editable,
      },
      headerStyle,
      style: {
        paddingLeft: 6,
        paddingRight: 6,
      },
    }
    return parent
  }, {})

  const schemes = {
    products: {
      productsNumber: {
        canGrow: false,
        canShrink: false,
        key: 'productsNumber',
        label: columnsProvider('productsNumber'),
        sort: true,
        size: 140,
        style: separatorCellStyle,
        component: DisplayComponent,
        headerStyle,
        props: {
          alignRotate: true,
        },
      },
      ...productsFrequenciesColumns,
    },
    keywords: {
      keywordsNumber: {
        canGrow: false,
        canShrink: false,
        key: 'keywordsNumber',
        label: columnsProvider('keywordsNumber'),
        sort: true,
        size: 140,
        style: separatorCellStyle,
        component: DisplayComponent,
        headerStyle,
        props: {
          alignRotate: true,
        },
      },
      ...keywordsFrequenciesColumns,
    },
    categories: {
      categoriesNumber: {
        canGrow: false,
        canShrink: false,
        key: 'categoriesNumber',
        label: columnsProvider('categoriesNumber'),
        sort: true,
        size: 150,
        style: separatorCellStyle,
        component: DisplayComponent,
        headerStyle,
        props: {
          alignRotate: true,
        },
      },
      ...categoriesFrequenciesColumns,
    },
  }

  const scheme =
    selectedPane === 'all'
      ? Object.values({
          ...defaultSchemes,
          ...schemes.products,
          ...schemes.keywords,
          ...schemes.categories,
        })
      : Object.values({...defaultSchemes, ...schemes[selectedPane]})

  useEffect(() => {
    setEstimatedTotalCosts(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  return (
    <>
      <ScrollableGrid
        aggregationScheme
        aggregationSize={45}
        cellSize={40}
        cellStyle={{textAlign: 'center'}}
        headerSize={110}
        items={editable ? results : flattenedResults}
        fixedColumnCount={3}
        orientation="horizontal"
        placehold
        scheme={scheme}
      />
    </>
  )
}

ClientTrackerGrid.propTypes = {
  flattenedResults: PropTypes.arrayOf(PropTypes.object),
  editable: PropTypes.bool.isRequired,
  selectedPane: PropTypes.string.isRequired,
  setEstimatedTotalCosts: PropTypes.func.isRequired,
}

ClientTrackerGrid.defaultProps = {
  flattenedResults: [],
}

export default ClientTrackerGrid
