import React from 'react'

import {css, StyleSheet} from 'aphrodite'
import PropTypes from 'prop-types'
import {Image} from 'semantic-ui-react'

import goku from './images/goku-kameha.gif'
import kameha from './images/kameha.gif'
import kamehaTip from './images/kameha-tip.gif'

const styles = StyleSheet.create({
  container: {
    height: 100,
    flex: 1,
    maxWidth: '60%',
  },
  image: {
    height: 100,
    width: 'auto',
    display: 'initial',
  },
})

class GokuLoader extends React.Component {
  static propTypes = {
    progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }

  static defaultProps = {
    progress: 'infinite',
  }

  UNSAFE_componentWillMount() {
    this.setState({progress: this.props.progress === 'infinite' ? 0 : this.props.progress})
    if (this.props.progress === 'infinite')
      this.updateProgress = setInterval(() => this.setState({progress: (this.state.progress + 1) % 100}), 100)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.progress !== this.props.progress)
      this.setState({progress: nextProps.progress === 'infinite' ? 0 : nextProps.progress})
  }

  render() {
    /* eslint-disable react/no-array-index-key */
    return (
      <div className={css(styles.container)}>
        <Image className={css(styles.image)} src={goku} />
        {new Array(this.state.progress + 1).fill(0).map((_, i) => (
          <Image key={i} className={css(styles.image)} src={kameha} />
        ))}
        <Image className={css(styles.image)} src={kamehaTip} />
      </div>
    )
    /* eslint-enable react/no-array-index-key */
  }

  componentWillUnmount() {
    clearInterval(this.updateProgress)
  }
}

export default GokuLoader
