export const animation = ({keyframes, duration, curve, count, delay}) => {
  const payload = {
    animationName: keyframes,
    animationDuration: duration || '0.3s',
    animationTimingFunction: curve || 'ease',
    animationIterationCount: count || 1,
  }
  if (delay !== undefined && delay !== null) {
    payload.animationDelay = delay
  }
  return payload
}
