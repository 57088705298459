import RelieverRegistry from 'react-redux-reliever'
import {createSelector} from 'reselect'

import {argsAsArray, isPlainObject} from '..'

export const requestingModuleState = state => RelieverRegistry.moduleState('requesting', state)
export const isRequestingSelector = state => path =>
  requestingModuleState(state).getIn([...argsAsArray(path), 'requesting'], false)
export const getProgressSelector = state => path =>
  requestingModuleState(state).getIn([...argsAsArray(path), 'progress'], 0)

function evaluateRequestingState(score, state) {
  if (isPlainObject(state)) {
    Object.values(state).reduce(evaluateRequestingState, score)
    if (state.requesting !== undefined) {
      score.count += 1 // eslint-disable-line no-param-reassign
      if (state.requesting) {
        score.requesting += 1 // eslint-disable-line no-param-reassign
        score.progress += state.progress || 0 // eslint-disable-line no-param-reassign
      }
    }
  }
  return score
}

const infosAboutRequestsSelector = createSelector(requestingModuleState, state =>
  Object.values(state).reduce(evaluateRequestingState, {requesting: 0, progress: 0, count: 0})
)

export const globalProgressSelector = createSelector(infosAboutRequestsSelector, infos =>
  Math.round(infos.progress / (infos.requesting || 1))
)

export const globalRequestingSelector = createSelector(infosAboutRequestsSelector, infos => !!infos.requesting)
