import React, {SyntheticEvent} from 'react'

import {Row} from 'jsxstyle'
import {Header, Icon, Modal, StrictModalProps} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../../colors'

const StyledHeaderModal = styled(Header)`
  border-bottom: none !important;
  > i {
    color: ${colors.darkLight.rgba};
  }
`

const StyledClose = styled(Icon)`
  &&&& {
    position: absolute;
    right: 10px;
    top: 10px;
    color: ${colors.dark.rgba};
  }
`

const StyledAction = styled.span<{readonly marginSide: 'right' | 'left'}>`
  margin: ${props => (props.marginSide === 'right' ? 'auto 0 auto 10px' : 'auto 10px auto 0')};
`

interface SeelkModalProps {
  children: React.ReactNode
  header?: {icon: string; content: string | React.ReactNode} | null
  leftActions?: any[] | null
  open?: boolean
  onClose?: () => void
  rightActions?: any[] | null
  scrolling?: boolean
  size?: StrictModalProps['size']
  trigger?: React.ReactNode | null
  contentStyle?: Record<string, string>
}

interface SeelkModalState {
  open: boolean
}

export default class SeelkModal extends React.Component<SeelkModalProps, SeelkModalState> {
  constructor(props: SeelkModalProps) {
    super(props)
    this.state = {
      open: props.open || false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: SeelkModalProps) {
    const {open} = this.state
    if (nextProps.open !== open) this.setState({open: nextProps.open || false})
  }

  actionsRender = (actions: any[], marginSide: 'right' | 'left') => {
    return actions
      .filter(a => a)
      .map((a, i) => {
        const key = `${a.type.name}-${i}`
        return (
          <StyledAction marginSide={marginSide} key={key}>
            {a}
          </StyledAction>
        )
      })
  }

  handleClose = () => {
    const {onClose} = this.props
    this.setState({open: false}, () => onClose && onClose())
  }

  render() {
    const {
      children,
      contentStyle = {},
      header = null,
      leftActions = null,
      rightActions = null,
      scrolling = false,
      size = 'small',
      trigger = null,
      ...rest
    } = this.props
    const {open} = this.state

    return (
      <Modal
        closeIcon={false}
        open={open}
        onClose={this.handleClose}
        size={size}
        trigger={trigger}
        onClick={(e: SyntheticEvent) => e.stopPropagation()}
        {...rest}
      >
        <StyledClose name="close" onClick={this.handleClose} />
        {header && <StyledHeaderModal {...header} />}
        <Modal.Content style={contentStyle} scrolling={scrolling}>
          {children}
        </Modal.Content>
        {(leftActions || rightActions) && (
          <Modal.Actions>
            <Row justifyContent="space-between">
              {leftActions ? <Row>{this.actionsRender(leftActions, 'left')}</Row> : null}
              {rightActions ? <Row>{this.actionsRender(rightActions, 'right')}</Row> : null}
            </Row>
          </Modal.Actions>
        )}
      </Modal>
    )
  }
}
