import React, {useCallback, useState} from 'react'

import {Row} from 'jsxstyle'
import _ from 'lodash'
import {Input, InputOnChangeData} from 'semantic-ui-react'
import styled from 'styled-components'

import {capitalize, unitSymbol} from '../../../../..'
import colors from '../../../../../colors'
import {DIMENSION_OPTIONS, OPERATORS} from '../../../../../constants'
import i18n from '../../../../../i18n'
import {DropdownSelector, FilterButton} from '../../../..'
import FilterBarItem from './FilterBarItem'

const StyledInput = styled(Row)`
  &&& {
    align-items: center;
    margin-left: 8px;
  }
`

const StyledTrigger = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.dark.rgba};
  i {
    display: flex;
    align-items: center;
    margin: auto 8px;
  }
  b {
    font-weight: 300;
  }
  span {
    margin: auto 5px;
  }
`

const StyledAggregationContainer = styled(Row)`
  &&& {
    align-items: center;
    justify-content: center;
    margin: 0 8px 0 8px;
    padding: ${props => (props.agg ? '0 0 0 8px' : '8px')};
    border: 0.0071em ${colors.border.rgba} solid;
    border-radius: 4px;
  }
`

const StyledSpan = styled.span`
  font-weight: 400;
  color: ${colors.darkLight.rgba};
  margin: 0 4px 0 4px;
`

const useDebounce = <T extends (...args: any) => any>(fnToDebounce: T, durationInMs = 200) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(_.debounce(fnToDebounce, durationInMs), [fnToDebounce, durationInMs])
}

type OperatorType = keyof typeof OPERATORS
type OperatorValueType = {a: number; b?: number}

interface OperatrosProps {
  onChange: (value: {operator: OperatorType; value: OperatorValueType}) => void
  selected: {operator?: OperatorType; value?: OperatorValueType}
  unit: string
}
const Operators = ({onChange, selected, unit}: OperatrosProps) => {
  const {operator, value: prevValue} = selected
  const [values, setValues] = useState(prevValue || {a: 0, b: 0})
  const [error, setError] = useState(false)

  const updateChange = useDebounce(
    ({operator, value = {}}) => onChange({operator, value: {...prevValue, ...value}}),
    200
  )

  const validInput = (key: 'a' | 'b') => (e: React.ChangeEvent<HTMLInputElement>, {value}: InputOnChangeData) => {
    setValues({...values, [key]: value})
    setError(false)
    const int = parseInt(value, 10)
    if (Number.isNaN(int)) setError(true)
    else updateChange({operator, value: {...prevValue, [key]: int}})
  }

  const {a = 0, b = 0} = values
  const displayLabel = unit?.length ? {label: {basic: true, content: unit}} : {}
  const isBetween = (key: OperatorType) => key === 'bw'

  return (
    <Row>
      <DropdownSelector
        options={Object.values(OPERATORS).map(op => ({
          text: i18n.t(`filters.operators.${op.key}`),
          key: op.key,
          value: op.key,
          icon: op.icon,
        }))}
        icon={operator ? OPERATORS[operator]?.icon : null}
        onChange={(value: OperatorType) => updateChange({operator: value, value: values})}
        onlyIcon={!!operator}
        placeholder={i18n.t('dashboards.placeholders.operator')}
        value={operator}
        disabled={undefined}
        loading={undefined}
        pointing={undefined}
        title={undefined}
        whiteTheme={undefined}
      />
      {operator ? (
        <StyledInput>
          <div style={{width: 150}}>
            <Input onChange={validInput('a')} value={a} placeholder="0" error={error} fluid {...displayLabel} />
          </div>
          {isBetween(operator) ? (
            <>
              <StyledSpan>{i18n.t('filters.and')}</StyledSpan>
              <div style={{width: 150}}>
                <Input {...displayLabel} onChange={validInput('b')} value={b} placeholder="0" error={error} fluid />
              </div>
            </>
          ) : null}
        </StyledInput>
      ) : null}
    </Row>
  )
}

type DataType = {
  aggregates: string[]
  aggregation: string
  dimension: string
  groupings: string[]
  key: string
  name: string
  operator: OperatorType
  unit: string
  value: OperatorValueType
}

interface MetricProps {
  currency?: string
  data: DataType | null
  disabled?: boolean
  icon: string
  onApply: () => void
  onChange: (changes: Partial<DataType>) => void
  onClose: () => void
  onRemove: () => void
  title: string
}

const Metric = (props: MetricProps) => {
  const {
    currency = 'EUR',
    data,
    disabled = false,
    icon,
    onChange,
    onRemove,
    onApply,
    onClose,
    title,
    ...barItemProps
  } = props
  const {aggregates = [], aggregation = null, dimension = null, groupings, key, name, operator, unit, value} =
    data || {}
  const {a = 0, b = 0} = value || {}

  const trigger = (
    <FilterButton
      title={title}
      icon={icon}
      disabled={disabled}
      onRemove={onRemove}
      onApply={onApply}
      dropdown={undefined}
    >
      {operator ? (
        <StyledTrigger>
          <i className={OPERATORS[operator].icon} />
          <b>{`${a}${unitSymbol(unit, currency)}`}</b>
          {operator === 'bw' ? (
            <>
              <span>{i18n.t('filters.and')}</span>
              <b>{`${b}${unitSymbol(unit, currency)}`}</b>
            </>
          ) : null}
        </StyledTrigger>
      ) : null}
    </FilterButton>
  )

  const updateDimension = (dimension: string) => {
    onChange({...data, dimension})
  }

  const updateAggregation = (aggregation: string) => {
    onChange({...data, aggregation})
  }

  const updateComparator = ({operator, value}: {operator: OperatorType; value: OperatorValueType}) => {
    const {a, b} = value
    if (operator === 'bw') onChange({...data, key, operator, value: {a, b}, unit})
    else onChange({...data, key, operator, value: {a}, unit})
  }

  return (
    <FilterBarItem
      {...barItemProps}
      data={data}
      // onChange={onChange}
      asRow
      canApply={!!dimension && !!operator && !!value}
      disabled={disabled}
      onClose={onClose}
      onApply={onApply}
      trigger={trigger}
    >
      <Row minWidth="max-content" maxHeight="75vh" alignItems="center" marginRight={8}>
        <DropdownSelector
          options={Object.values(DIMENSION_OPTIONS).filter(dim => groupings?.includes(dim.key))}
          icon={DIMENSION_OPTIONS?.[dimension as keyof typeof DIMENSION_OPTIONS]?.icon}
          value={dimension}
          placeholder={i18n.t('dashboards.placeholders.dimensions')}
          onChange={(value: string) => updateDimension(value)}
          pointing="top left"
          disabled={undefined}
          loading={undefined}
          onlyIcon={undefined}
          title={undefined}
          whiteTheme={undefined}
        />
        {dimension && (
          <StyledSpan style={{margin: '0 0 0 8px', textTransform: 'lowercase'}}>{i18n.t('filters.with')}</StyledSpan>
        )}
        {dimension && (
          <StyledAggregationContainer agg={aggregates.length}>
            <StyledSpan>{name}</StyledSpan>
            {aggregates.length ? (
              <DropdownSelector
                options={aggregates.map(agg => ({
                  text: capitalize(agg),
                  key: agg,
                  value: agg,
                }))}
                placeholder="N/A"
                onChange={(value: string) => updateAggregation(value)}
                value={aggregation}
                style={{transform: 'scale(0.8)'}}
                disabled={undefined}
                icon={undefined}
                loading={undefined}
                onlyIcon={undefined}
                pointing={undefined}
                title={undefined}
                whiteTheme={undefined}
              />
            ) : null}
          </StyledAggregationContainer>
        )}
        {dimension && (aggregation || !aggregates.length) && (
          <Operators onChange={updateComparator} selected={{operator, value}} unit={unitSymbol(unit, currency)} />
        )}
      </Row>
    </FilterBarItem>
  )
}

export default Metric
