import React from 'react'

import moment, {Moment} from 'moment'
import {Input, InputOnChangeData} from 'semantic-ui-react'

import {format, FormatType, MODES, REGEXPS} from './constants'

interface DateInputProps {
  onChange: (value: Moment) => void
  disabled?: boolean
  disabledDate?: (parsed: Moment, value?: Moment) => boolean
  mode?: typeof MODES[number]
  onChangeMode?: (mode: FormatType) => void
  prefixCls?: string
  selectMode?: 'start' | 'end' | 'single'
  value?: Moment
}

export default class DateInput extends React.Component<DateInputProps> {
  state = {
    text: '',
    valid: false,
  }

  UNSAFE_componentWillMount() {
    const {value = moment.utc(), mode = 'day'} = this.props
    if (value) this.setState({text: this.displayValue(value, mode), valid: true})
  }

  UNSAFE_componentWillReceiveProps(nextProps: DateInputProps) {
    const {value = moment.utc(), mode = 'day'} = this.props
    if (value !== nextProps.value || mode !== nextProps.mode)
      this.setState({text: this.displayValue(nextProps.value, nextProps.mode), valid: true})
  }

  displayValue = (value: Moment = moment.utc(), mode: FormatType = 'day') => {
    if (!value) return ''
    return value.format(format(mode))
  }

  parseText = (value: string, mode: FormatType) => {
    const {selectMode = 'single', disabledDate, onChange} = this.props
    const parsed = moment.utc(value, format(mode))
    if (parsed.isValid() && (!disabledDate || !disabledDate(parsed, this.props.value || moment.utc()))) {
      onChange(selectMode === 'end' ? parsed.endOf(mode) : parsed.startOf(mode))
    } else this.setState({valid: false})
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, {value}: InputOnChangeData) => {
    const {mode = 'day', onChangeMode} = this.props
    this.setState({text: value})
    let matchMode: FormatType | null = null
    // eslint-disable-next-line no-restricted-syntax
    for (const regex in REGEXPS) {
      if (REGEXPS[regex as FormatType].test(value)) {
        matchMode = regex as FormatType
        break
      }
    }
    if (matchMode === null) {
      this.setState({valid: false})
      return
    }
    if (matchMode && matchMode !== mode && onChangeMode) onChangeMode(matchMode)
    this.parseText(value, matchMode)
  }

  render() {
    const {disabled = false, prefixCls = 'datePicker'} = this.props
    return (
      <Input
        disabled={disabled}
        className={`${prefixCls}Input`}
        value={this.state.text}
        onChange={this.handleInputChange}
        error={!this.state.valid}
      />
    )
  }
}
