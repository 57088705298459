import React from 'react'

import PropTypes from 'prop-types'
import RelieverRegistry from 'react-redux-reliever'
import {toast} from 'react-toastify'
import {of} from 'rxjs'
import {v4 as uuidv4} from 'uuid'

import i18n from '../i18n'
import {argsAsArray, cloneInstance, FakeProgress} from '..'
import ErrorRequesting from './components/ErrorRequesting'
import InfoRequesting from './components/InfoRequesting'

const RequestingContext = React.createContext()

export class RequestingProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      requestingCounter: {},
      notifyChange: this.notifyChange, // eslint-disable-line react/no-unused-state
      store: props.store, // eslint-disable-line react/no-unused-state
    }
  }

  notifyChange = id => {
    const oldCounter = this.state.requestingCounter
    oldCounter[id] = (oldCounter[id] || 0) + 1
    this.setState({requestingCounter: oldCounter})
  }

  render() {
    return <RequestingContext.Provider value={this.state}>{this.props.children}</RequestingContext.Provider>
  }
}

export function withRequests(builders) {
  return function inner(WrappedComponent) {
    return class Wrapper extends React.Component {
      static contextType = RequestingContext

      static displayName = `WithRequests(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

      static WrappedComponent = WrappedComponent

      constructor(props, context) {
        super(props, context)
        const store = context?.store ?? require('../../../store').default // eslint-disable-line global-require
        this.requests = Object.entries(builders).reduce((s, [name, builder]) => {
          s[name] = builder(store, context.notifyChange) // eslint-disable-line no-param-reassign
          return s
        }, {}) // eslint-disable-line react/prop-types
      }

      componentWillUnmount() {
        Object.values(this.requests).forEach(req => req.destroy())
      }

      render() {
        const refreshedRequests = Object.entries(this.requests).reduce((s, [name, wrapper]) => {
          s[name] = wrapper // eslint-disable-line no-param-reassign
          if (
            this.context.requestingCounter[wrapper.id] &&
            this.context.requestingCounter[wrapper.id] !== wrapper.refreshCount
          ) {
            s[name] = cloneInstance(wrapper) // eslint-disable-line no-param-reassign
            wrapper.refreshCount = this.context.requestingCounter[wrapper.id] // eslint-disable-line no-param-reassign
          }
          return s
        }, {})
        return <WrappedComponent {...refreshedRequests} {...this.props} />
      }
    }
  }
}

export class RequestWrapper {
  constructor({
    errorProps = {},
    resourcePath = null,
    requestingPath = null,
    fetchAction = null,
    startMsg = '',
    successMsg = '',
    showError = true,
    showProgress = false,
    maxRetries = 0,
    toastConfig = {},
    gateway = null,
    usecase = null,
  }) {
    this.errorProps = errorProps
    this.resourcePath = resourcePath
    this.requestingPath = requestingPath
    this.fetchAction = fetchAction
    this.startMsg = startMsg
    this.successMsg = successMsg
    this.showError = showError
    this.showProgress = showProgress
    this.maxRetries = maxRetries
    this.toastConfig = toastConfig
    this.tries = 0
    this.requesting = false
    this.done = false
    this.failed = false
    this.error = null
    this.fakeProgress = null
    this.callback = null
    this.store = null
    this.notifyChange = null
    this.id = null
    this.refreshCount = 0
    this.gateway = gateway
    this.usecase = usecase
  }

  init(store, notifyChange) {
    this.store = store
    this.id = uuidv4()
    this.notifyChange = notifyChange
  }

  destroy() {
    this.setClosed()
  }

  reset() {
    this.tries = 0
    this.requesting = false
    this.done = false
    this.failed = false
    this.error = null
    this.fakeProgress = null
    this.callback = null
    if (this.notifyChange) this.notifyChange(this.id)
  }

  dataFetched() {
    if (this.resourcePath && Array.isArray(this.resourcePath)) {
      return this.resourcePath.every(resourcePath => {
        const asArray = argsAsArray(resourcePath)
        return RelieverRegistry.moduleState(asArray[0], this.store.getState()).getIn(asArray.splice(1)) != null
      })
    } else if (this.resourcePath) {
      const asArray = argsAsArray(this.resourcePath)
      return RelieverRegistry.moduleState(asArray[0], this.store.getState()).getIn(asArray.splice(1)) != null
    }
    return true
  }

  isRequesting() {
    return RelieverRegistry.moduleState('requesting', this.store.getState()).getIn([
      ...argsAsArray(this.requestingPath),
      'requesting',
    ])
  }

  hasFailed() {
    return this.failed && this.tries > this.maxRetries
  }

  canFetch(ignoreFetchedData = false) {
    if (this.isRequesting()) return false
    if (this.hasFailed()) return false
    if (!this.dataFetched()) return true
    if (!ignoreFetchedData && this.done) return false
    return ignoreFetchedData || !this.dataFetched()
  }

  setClosed() {
    this.closed = true
    if (this.fakeProgress) this.fakeProgress.stop()
    toast.dismiss(this.id)
    if (this.notifyChange) this.notifyChange(this.id)
  }

  dispatch(args = {}, payload, callback) {
    this.callback = callback
    this.done = false
    this.failed = false
    this.store.dispatch({...args, type: this.fetchAction, payload, _reqWrapper: this})
    if (this.notifyChange) this.notifyChange(this.id)
  }

  async dispatchUsecase(...props) {
    this.done = false
    this.failed = false
    this.store.dispatch({type: this.fetchAction, _reqWrapper: this})
    this.start()
    if (this.notifyChange) this.notifyChange(this.id)
    return this.usecase(...props)(this)
  }

  start() {
    this.requesting = true
    if (this.startMsg) {
      if (toast.isActive(this.id))
        toast.update(this.id, {
          render: this.startMsg,
          type: 'info',
          autoClose: !this.showProgress,
          progress: 0,
          ...this.toastConfig,
        })
      else
        toast(this.startMsg, {
          type: 'info',
          toastId: this.id,
          isProgressDone: false,
          autoClose: !this.showProgress,
          progress: 0,
          onClose: this.setClosed.bind(this),
          ...this.toastConfig,
        })
    }
    this.store.dispatch({
      type: 'REQUESTING_START',
      setIn: argsAsArray(this.requestingPath),
      _ASAP: true,
      _reqWrapper: this,
    })
    this.tries += 1
    if (this.startMsg && this.showProgress === 'fake')
      this.fakeProgress = new FakeProgress(this.updateToastProgress.bind(this), {autoStart: 100, interval: 200})
    if (this.notifyChange) this.notifyChange(this.id)
  }

  fetch(args = {}, payload, callback) {
    this.dispatch(args, payload, callback)
    this.start()
  }

  updateToastProgress(progress) {
    if (this.closed) toast.dismiss(this.id)
    else if (toast.isActive(this.id)) toast.update(this.id, {progress: progress / 100})
    else
      toast(this.startMsg, {
        type: 'info',
        toastId: this.id,
        autoClose: !this.showProgress,
        progress: progress / 100,
        onClose: this.setClosed.bind(this),
      })
  }

  updateProgress(progress) {
    if (this.startMsg) this.updateToastProgress(progress)
    this.store.dispatch({
      type: 'REQUESTING_UPDATE_PROGRESS',
      setIn: argsAsArray(this.requestingPath),
      progress,
      _reqWrapper: this,
    })
  }

  cancel() {
    if (this.requesting) {
      console.error(`Too late to cancel ${this.fetchAction}.`, this) // eslint-disable-line no-console
      return
    }
    this.store.dispatch({type: `${this.fetchAction}_ABORT`, _reqWrapper: this})
  }

  info(message, links) {
    if (this.fakeProgress) {
      this.fakeProgress.end()
      this.fakeProgress = null
    }
    setTimeout(() => {
      this.requesting = false
      this.done = true
      if (message || this.successMsg) {
        if (toast.isActive(this.id))
          toast.update(this.id, {
            autoClose: true,
            progress: null,
            render: <InfoRequesting message={message} links={links} />,
            type: 'info',
            ...this.toastConfig,
          })
        else
          toast(<InfoRequesting message={message} links={links} />, {
            type: 'info',
            toastId: this.id,
            ...this.toastConfig,
          })
      }
      if (this.callback) this.callback(message)
      this.store.dispatch({
        type: 'REQUESTING_SUCCEEDED',
        setIn: argsAsArray(this.requestingPath),
        _reqWrapper: this,
      })
      if (this.notifyChange) this.notifyChange(this.id)
    }, 300)
  }

  fail({error, title}, silent = false) {
    if (this.fakeProgress) {
      this.fakeProgress.stop()
      this.fakeProgress = null
    }
    this.requesting = false
    this.failed = true
    this.error = error
    this.done = true
    if (!silent) {
      if (error instanceof Error) console.error(error) // eslint-disable-line no-console
      if (this.showError) {
        if (toast.isActive(this.id))
          toast.update(this.id, {
            render: <ErrorRequesting error={error} title={title} {...(this.errorProps[error.code] ?? {})} />,
            autoClose: false,
            style: {padding: '10px', borderLeft: `5px solid var(--toastify-icon-color-error)`},
            type: 'error',
            ...this.toastConfig,
          })
        else
          toast(<ErrorRequesting error={error} title={title} {...(this.errorProps[error.code] ?? {})} />, {
            autoClose: false,
            style: {padding: '10px', borderLeft: `5px solid var(--toastify-icon-color-error)`},
            toastId: this.id,
            type: 'error',
            ...this.toastConfig,
          })
      }
    }
    this.store.dispatch({
      type: 'REQUESTING_FAILED',
      setIn: argsAsArray(this.requestingPath),
      _reqWrapper: this,
    })
    if (this.notifyChange) this.notifyChange(this.id)
    return of()
  }

  end(message, partialSuccess) {
    if (this.fakeProgress) {
      this.fakeProgress.end()
      this.fakeProgress = null
    }
    setTimeout(() => {
      this.requesting = false
      this.done = true
      if (partialSuccess) {
        if (toast.isActive(this.id))
          toast.update(this.id, {
            autoClose: false,
            style: {padding: '10px', borderLeft: `5px solid var(--toastify-icon-color-error)`},
            render: <ErrorRequesting error={message} title={i18n.t('placeholders.errors.genericError')} />,
            type: 'error',
            ...this.toastConfig,
          })
        else
          toast(<ErrorRequesting error={message} title={i18n.t('placeholders.errors.genericError')} />, {
            autoClose: false,
            style: {padding: '10px', borderLeft: `5px solid var(--toastify-icon-color-error)`},
            toastId: this.id,
            type: 'error',
            ...this.toastConfig,
          })
      } else if (message || this.successMsg) {
        if (toast.isActive(this.id))
          toast.update(this.id, {
            autoClose: 5000,
            style: {padding: '10px', borderLeft: `5px solid var(--toastify-icon-color-success)`},
            render: message || this.successMsg,
            type: 'success',
          })
        else
          toast(message || this.successMsg, {
            autoClose: 5000,
            style: {padding: '10px', borderLeft: `5px solid var(--toastify-icon-color-success)`},
            toastId: this.id,
            type: 'success',
          })
      }
      if (this.callback) this.callback(message)
      this.store.dispatch({
        type: 'REQUESTING_SUCCEEDED',
        setIn: argsAsArray(this.requestingPath),
        _reqWrapper: this,
      })
      if (this.notifyChange) this.notifyChange(this.id)
    }, 300)
  }

  setFetchAction(action) {
    this.fetchAction = action
  }

  setGateway(gateway) {
    this.gateway = gateway
  }
}

export const requestWrapperBuilder = params => {
  return (store, notifyChange) => {
    const wrapper = new RequestWrapper(params)
    wrapper.init(store, notifyChange)
    return wrapper
  }
}
