import React from 'react'

import {Box, Col} from 'jsxstyle'
import PropTypes from 'prop-types'
import {List as VirtualList, ListRowProps} from 'react-virtualized'
import {Checkbox, Icon, InputOnChangeData, Label} from 'semantic-ui-react'
import styled from 'styled-components'

import {clamp, fuzzysearch} from '../..'
import colors from '../../colors'
import i18n from '../../i18n'
import DebouncedInput from './inputs/DebouncedInput'
import FilterButton from './FilterButton'
import PoppersPopup from './PoppersPopup'

const StyledLabel = styled(Label)`
  &&& {
    background-color: ${colors.light.rgba};
    color: ${colors.dark.rgba};
    margin: 4px 0 4px 2px;
    padding: 4px 6px;
  }
`

const StyledCheckbox = styled(Checkbox)`
  &&& {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 10px;
    font-weight: ${props => (props.checked ? '500' : '400')};
    :hover {
      background-color: ${colors.primaryLight.rgba};
      cursor: pointer;
      span {
        color: ${colors.primary.rgba} !important;
      }
    }
  }
`

const StyledHiddenItems = styled.span`
  display: flex;
  align-items: center;
  margin: 0 5px 0 10px;
`

const StyledDebouncedInput = styled(DebouncedInput)`
  &&&& {
    padding: 5px;
  }
`
interface Items {
  key: string
  text: string
}

interface LabelSelectorProps {
  availableItems?: Record<string, Items>
  icon: string
  placeholder: string
  position?:
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'top center'
    | 'bottom center'
  search?: boolean
  selectedItems?: string[]
  updateSelectedItems: (selectedItems: string[]) => void
}

interface LabelSelectorState {
  search: string
}

class LabelSelector extends React.Component<LabelSelectorProps, LabelSelectorState> {
  static propTypes = {
    availableItems: PropTypes.objectOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
    icon: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    position: PropTypes.string,
    search: PropTypes.bool,
    selectedItems: PropTypes.arrayOf(PropTypes.string),
    updateSelectedItems: PropTypes.func.isRequired,
  }

  static defaultProps = {
    availableItems: {},
    position: 'bottom right',
    search: false,
    selectedItems: [],
  }

  constructor(props: LabelSelectorProps) {
    super(props)

    this.state = {
      search: '',
    }
  }

  onChange = (key: string) => {
    const {selectedItems = [], updateSelectedItems} = this.props
    const index = selectedItems.findIndex(i => i === key)
    if (index !== -1) {
      selectedItems.splice(index, 1)
      updateSelectedItems(selectedItems)
    } else updateSelectedItems([...selectedItems, key])
  }

  onChangeGlobalCheck = () => {
    const {availableItems = {}, selectedItems = [], updateSelectedItems} = this.props
    if (!selectedItems || selectedItems?.length === 0)
      updateSelectedItems(Object.values(availableItems).map(({key}) => key))
    else updateSelectedItems([])
  }

  trigger = () => {
    const {availableItems = {}, selectedItems = [], placeholder, icon, updateSelectedItems} = this.props
    const visibleItems = selectedItems.slice(0, 2)
    const hiddenItems = selectedItems.length - visibleItems.length
    return (
      <div>
        <FilterButton
          title={selectedItems?.length === 0 ? placeholder : ''}
          icon={icon}
          onRemove={selectedItems?.length > 0 ? () => updateSelectedItems([]) : null}
        >
          {(visibleItems || []).map(item => (
            <Box marginLeft={5} key={item}>
              <StyledLabel>
                {clamp(availableItems?.[item]?.text ?? item, 18)}
                <Icon
                  name="delete"
                  onClick={(e: React.MouseEvent<HTMLElement>) => [this.onChange(item), e.stopPropagation()]}
                />
              </StyledLabel>
            </Box>
          ))}
          {hiddenItems ? <StyledHiddenItems>{`(+${hiddenItems})`}</StyledHiddenItems> : null}
        </FilterButton>
      </div>
    )
  }

  content = () => {
    const {availableItems = {}, search = false, selectedItems = []} = this.props
    const listItems = Object.keys(availableItems).filter(key =>
      fuzzysearch(this.state.search, availableItems?.[key]?.text ?? '')
    )

    const rowRenderer = ({key, index, style}: ListRowProps) => {
      const id = listItems?.[index]
      const {key: keyItem, text} = availableItems[id] ?? {}
      const checked = !!selectedItems.find(item => item === keyItem)
      return (
        <StyledCheckbox
          key={key}
          style={style}
          label={{
            children:
              text?.length > 25 ? (
                <PoppersPopup position="bottom center" trigger={<span>{clamp(text, 25)}</span>} content={text} />
              ) : (
                <span>{text}</span>
              ),
          }}
          checked={checked}
          onChange={() => this.onChange(keyItem)}
        />
      )
    }

    const isChecked = Object.keys(availableItems)?.length === selectedItems.length
    const isIntermediate = selectedItems.length > 0

    const height = listItems.length * 40 > 260 ? 260 : listItems.length * 40
    return (
      <Col>
        {search ? (
          <StyledDebouncedInput
            icon="search"
            iconPosition="left"
            onChange={(e: React.ChangeEvent<HTMLInputElement>, {value}: InputOnChangeData) =>
              this.setState({search: value})
            }
            placeholder={`${i18n.t('actions.search.search')}...`}
            transparent={false}
            value={this.state.search}
          />
        ) : null}
        <StyledCheckbox
          checked={isChecked}
          indeterminate={!isChecked && isIntermediate}
          onChange={this.onChangeGlobalCheck}
          label={i18n.t('actions.select.all')}
        />
        <VirtualList width={260} height={height} rowCount={listItems.length} rowHeight={40} rowRenderer={rowRenderer} />
      </Col>
    )
  }

  render() {
    const {position = 'bottom right'} = this.props
    return (
      <PoppersPopup
        style={{padding: 0}}
        basic
        on="click"
        position={position}
        trigger={this.trigger()}
        content={this.content()}
      />
    )
  }
}

export default LabelSelector
