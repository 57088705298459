import {defer} from 'rxjs'

import KaioApiClient from './KaioApiClient'

export default class KaioApiClientTestOrNot {
  constructor() {
    return new KaioApiClient()
  }
}

export const KaioApi = (method, ...params) => defer(async () => new KaioApiClientTestOrNot()[method](...params))
