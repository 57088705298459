import {Reliever} from 'react-redux-reliever'
import {ofType} from 'redux-observable'
import {combineLatest} from 'rxjs'
import {catchError, map, mergeMap, tap} from 'rxjs/operators'

import {KaioApi} from '../utils/apis'
import {getState} from '../utils/extensions/rx'

class AdvertisersReliever extends Reliever {
  ACTION_PREFIX = 'ADVERTISERS'

  getInitialState() {
    return {
      advertisersData: null,
    }
  }

  fetchAdvertisersEpic(action$) {
    return action$.pipe(
      ofType('ADVERTISERS_FETCH_ADVERTISERS'),
      mergeMap(action =>
        KaioApi('getAdvertisers').pipe(
          map(advertisersData => ({
            type: 'ADVERTISERS_FETCH_ADVERTISERS_SUCCESS',
            payload: {advertisersData},
          })),
          tap(() => action._reqWrapper.end()),
          catchError(err => action._reqWrapper.fail({error: err}))
        )
      )
    )
  }

  deleteAdvertisersEpic(action$) {
    return action$.pipe(
      ofType('ADVERTISERS_DELETE_ADVERTISERS'),
      mergeMap(action =>
        getState('advertisers').pipe(
          map(state => state.asMutable({deep: true})),
          mergeMap(({advertisersData}) => {
            const {ids} = action
            const bulkDelete = ids.map(id => KaioApi('deleteAdvertiser', {id}))
            const updatedAdvertisers = advertisersData.filter(({id}) => !ids.includes(id))
            return combineLatest([...bulkDelete]).pipe(
              map(() => ({
                type: 'ADVERTISERS_DELETE_ADVERTISERS_SUCCESS',
                payload: {advertisersData: [...updatedAdvertisers]},
              })),
              tap(() => action._reqWrapper.end()),
              catchError(err => action._reqWrapper.fail({error: err}))
            )
          })
        )
      )
    )
  }

  updateAdvertisersEpic(action$) {
    return action$.pipe(
      ofType('ADVERTISERS_UPDATE_ADVERTISERS'),
      mergeMap(action =>
        getState('advertisers').pipe(
          map(state => state.asMutable({deep: true})),
          mergeMap(({advertisersData}) => {
            const {advertisers} = action
            return KaioApi('updateAdvertisers', {advertisers}).pipe(
              map(newAdvertisers => ({
                type: 'ADVERTISERS_UPDATE_ADVERTISERS_SUCCESS',
                payload: {advertisersData: [...advertisersData, ...newAdvertisers]},
              })),
              tap(() => action._reqWrapper.end()),
              catchError(err => action._reqWrapper.fail({error: err}))
            )
          })
        )
      )
    )
  }
}

export default AdvertisersReliever
