import {Reliever} from 'react-redux-reliever'
import {ofType} from 'redux-observable'
import {catchError, mapTo, mergeMap, tap} from 'rxjs/operators'

import {BardockApi} from '../utils/apis'
// import {RequestWrapper} from 'requesting/RequestWrapper'

class DataManagementReliever extends Reliever {
  ACTION_PREFIX = 'DATA-MANAGEMENT'

  getInitialState() {
    return {}
  }

  uploadMetricsForBardockEpic(action$) {
    return action$.pipe(
      ofType('DATA-MANAGEMENT_IMPORT_BARDOCK_METRICS'),
      mergeMap(action =>
        BardockApi('uploadMetricsV3', action.metricsFile).pipe(
          mapTo({
            type: 'DATA-MANAGEMENT_IMPORT_BARDOCK_METRICS_SUCCESS',
          }),
          tap(() => action._reqWrapper.end()),
          catchError(err => action._reqWrapper.fail({error: err}))
        )
      )
    )
  }
}

export default DataManagementReliever
