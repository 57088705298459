import React from 'react'

import {push} from 'connected-react-router'
import {Box, Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import RelieverRegistry, {connect} from 'react-redux-reliever'
import {Message} from 'semantic-ui-react'
import Cookies from 'universal-cookie'

import KaioApiClient from '../../../utils/apis/KaioApiClient'
import RunningGoku from '../../../utils/easter-eggs/RunningGoku'
import PermissionControl from '../../../utils/PermissionControl'
import GlobalLoader from '../../../utils/requesting/containers/GlobalLoader'
import {requestWrapperBuilder, withRequests} from '../../../utils/requesting/RequestWrapper'
import theme from '../../../utils/theme'
import MainMenu from '../../components/MainMenu'
import AccountSelect from '../AccountSelect' // eslint-disable-line no-unused-vars
import ChooseClient from '../ChooseClient/index'

export class AppLayout extends React.Component {
  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line react/no-unused-prop-types
    children: PropTypes.node.isRequired,
    dismissMessage: PropTypes.func.isRequired,
    fetchAccounts: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    messages: PropTypes.arrayOf(PropTypes.object).isRequired,
    redirectLogin: PropTypes.func.isRequired,
    refreshToken: PropTypes.func.isRequired,
    user: PropTypes.object,
  }

  static defaultProps = {
    accounts: null,
    user: null,
  }

  constructor() {
    super()
    this.state = {
      isChooseClientToggled: false,
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.loggedIn) this.props.redirectLogin()
    else {
      this.props.refreshToken()
      if (this.props.fetchAccounts.canFetch()) this.props.fetchAccounts.fetch()
    }
  }

  changeAccount = currentAccount => {
    this.setState({currentAccount})
  }

  canShowClients() {
    if (!this.props.user || !this.props.user.roles) return false
    return (
      this.props.user.roles.map(x => x.toLowerCase()).includes('seelk') ||
      this.props.user.roles.map(x => x.toLowerCase()).includes('brandmanager')
    )
  }

  toggleClientsSelector = () => {
    if (this.canShowClients()) this.setState({isChooseClientToggled: !this.state.isChooseClientToggled})
  }

  render() {
    const canShowChildren =
      this.props.fetchAccounts.dataFetched() &&
      this.props.user &&
      (this.props.user.account !== 'seelk' || new Cookies().get('account'))

    // (this.props.user.account !== 'seelk' || new Cookies().get('account'))

    return [
      <Box key="selectaccount" width="326px" position="absolute" height="100vh" top="0px" left="4px" zIndex="0">
        {this.canShowClients() && this.props.fetchAccounts.dataFetched() && (
          <ChooseClient
            didResolveCurrentAccount={this.changeAccount}
            toggleClientsSelector={this.toggleClientsSelector}
          />
        )}
      </Box>,
      <Row key="content" height="100%">
        <GlobalLoader disposition="vertical" />
        <Row
          minHeight="100vh"
          minWidth="calc(100vw - 4px)"
          zIndex="1"
          background="rgba(249, 251, 253, 1)"
          transition="transform 0.4s ease"
          transform={`translateX(${this.state.isChooseClientToggled ? '326px' : '0px'})`}
        >
          {this.props.user && this.props.fetchAccounts.dataFetched() && canShowChildren && (
            <MainMenu
              notifs={0}
              location={this.props.location}
              avatar={this.props.user && this.props.user.picture ? this.props.user.picture : '/static/unknown.jpg'}
              user={this.props.user ? this.props.user.name : ''}
              account={this.props.user && this.props.user.account}
              toggleClientsSelector={this.toggleClientsSelector}
              currentAccount={this.state.currentAccount}
            />
          )}
          {(() => {
            if (!canShowChildren) {
              if (!new Cookies().get('account')) {
                return (
                  <PermissionControl showIfOne={['seelk', 'brandmanager']}>
                    <AccountSelect accounts={this.props.accounts} />
                  </PermissionControl>
                )
              }
              return null
            }
            return (
              <Col flex="1">
                {this.props.messages.map((message, i) => (
                  <Message
                    key={i} // eslint-disable-line react/no-array-index-key
                    className={theme.using(theme.compactMessage)}
                    color={message.color}
                    header={message.header}
                    content={message.content}
                    list={message.contents}
                    icon={message.icon}
                    onDismiss={() => this.props.dismissMessage(i)}
                  />
                ))}
                {this.props.user && this.props.children}
              </Col>
            )
          })()}
          <PermissionControl showIfOne={['dev', 'brandmanager']}>
            <RunningGoku />
          </PermissionControl>
        </Row>
      </Row>,
    ]
  }
}

export default withRequests({
  fetchAccounts: requestWrapperBuilder({
    fetchAction: 'APP_FETCH_ACCOUNTS',
    requestingPath: 'app.accounts.fetch',
    resourcePath: 'app.accounts',
  }),
})(
  connect({
    props: (state, ownProps) => ({
      accounts:
        RelieverRegistry.moduleState('app', state).accounts &&
        RelieverRegistry.moduleState('app', state).accounts.asMutable({deep: true}),
      loggedIn: new KaioApiClient().loggedIn(),
      messages: RelieverRegistry.moduleState('app', state).messages.asMutable({deep: true}),
      location: state.router.location,
      user:
        RelieverRegistry.moduleState('app', state).user &&
        RelieverRegistry.moduleState('app', state).user.asMutable({deep: true}),

      ...ownProps,
    }),
    functions: (ownProps, dispatch) => ({
      dismissMessage: i => {
        dispatch(RelieverRegistry.moduleActions('app').dismissMessage(i))
      },
      redirectLogin() {
        const {pathname, search} = ownProps.location
        if (search && search.startsWith('?next=')) dispatch(push(search))
        else if (pathname !== '/' && pathname !== '/login')
          dispatch(push(`/login?next=${encodeURIComponent(pathname + search)}`))
        else dispatch(push('/login'))
      },
      refreshToken() {
        new KaioApiClient().refreshToken()
      },
    }),
  })(AppLayout)
)
