import React from 'react'

import {Label} from 'semantic-ui-react'
import styled, {css} from 'styled-components'

import {capitalize} from '../..'
import colors from '../../colors'

const StyledLabel = styled(Label)`
  &&& {
    ${props =>
      props.colormapping &&
      css`
        background-color: ${props.colormapping[props.value]};
        color: ${colors.white.rgba};
      `}
  }
`

type ExtraType = {
  capitalize?: boolean
  colorMapping?: Record<string, string>
}

interface LabelCellProps {
  value: string
  extra: ExtraType
}

const LabelCell = ({value, extra}: LabelCellProps) => {
  return (
    <StyledLabel
      content={extra.capitalize ? capitalize(value) : value}
      colormapping={extra.colorMapping}
      value={value}
    />
  )
}

export default LabelCell
