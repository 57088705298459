import React from 'react'

import {Row} from 'jsxstyle'
import moment, {Moment} from 'moment'

import {FormatType} from './constants'
import DateInput from './DateInput'

interface DateRangeInputProps {
  prefixCls?: string
  disabledDate?: (parsed: Moment, value?: Moment) => boolean
  startDate?: Moment
  endDate?: Moment
  onChange: (value: Moment[]) => void
  mode?: FormatType
  onChangeMode?: (mode: FormatType) => void
  selectMode?: 'start' | 'end'
  onChangeSelectMode: (mode: 'start' | 'end') => void
  disabled?: boolean
}

interface DateRangeInputState {
  mode: FormatType
}

export default class DateRangeInput extends React.Component<DateRangeInputProps, DateRangeInputState> {
  static defaultProps = {
    prefixCls: 'datePicker',
    disabledDate: undefined,
    startDate: moment.utc(),
    endDate: moment.utc(),
    mode: 'day',
    onChangeMode: undefined,
    selectMode: 'start',
    disabled: false,
  }

  constructor(props: DateRangeInputProps) {
    super(props)
    this.state = {
      mode: props.mode || 'day',
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({mode: this.props.mode || 'day'})
  }

  UNSAFE_componentWillReceiveProps(nextProps: DateRangeInputProps) {
    if (this.props.mode !== nextProps.mode) this.setState({mode: nextProps.mode || 'day'})
  }

  handleChange = (selectMode: 'start' | 'end') => (value: Moment) => {
    const {startDate = moment.utc(), endDate = moment.utc()} = this.props
    if (selectMode === 'start') this.props.onChange([value, endDate])
    else if (selectMode === 'end') this.props.onChange([startDate, value])
  }

  render() {
    return (
      <Row>
        <div
          onClick={() => this.props.onChangeSelectMode('start')}
          className={
            this.props.selectMode === 'start'
              ? `${this.props.prefixCls}InputSelected ${this.props.prefixCls}InputStart`
              : `${this.props.prefixCls}InputStart`
          }
        >
          <DateInput
            disabled={this.props.disabled}
            prefixCls={this.props.prefixCls}
            disabledDate={this.props.disabledDate}
            onChange={this.handleChange('start')}
            value={this.props.startDate}
            mode={this.state.mode}
            onChangeMode={this.props.onChangeMode}
            selectMode="start"
          />
        </div>
        <div
          onClick={() => this.props.onChangeSelectMode('end')}
          className={
            this.props.selectMode === 'end'
              ? `${this.props.prefixCls}InputSelected ${this.props.prefixCls}InputEnd`
              : `${this.props.prefixCls}InputEnd`
          }
        >
          <DateInput
            disabled={this.props.disabled}
            prefixCls={this.props.prefixCls}
            disabledDate={this.props.disabledDate}
            onChange={this.handleChange('end')}
            value={this.props.endDate}
            mode={this.state.mode}
            onChangeMode={this.props.onChangeMode}
            selectMode="end"
          />
        </div>
      </Row>
    )
  }
}
