import React from 'react'

import {css, StyleSheet} from 'aphrodite'
import PropTypes from 'prop-types'
import Keypress from 'react-keypress'
import RelieverRegistry, {connect} from 'react-redux-reliever'
import {Link} from 'react-router-dom'
import {Button, Divider, Grid, Image, Input, Label, Message} from 'semantic-ui-react'

import colors from '../../utils/colors'
import logo from '../../utils/common-ui/images/logo.png'
import ErrorBoundary from '../../utils/ErrorBoundary'
import i18n from '../../utils/i18n'
import {requestWrapperBuilder, withRequests} from '../../utils/requesting/RequestWrapper'

const styles = StyleSheet.create({
  page: {
    background: `linear-gradient(${colors.primaryDark.lighten(0.05).rgba}, ${colors.primaryDark.darken(0.3).rgba})`,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '10vh',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    maxWidth: '400px',
  },
  title: {
    fontWeight: 200,
    color: colors.white.rgba,
    flex: 1,
    textAlign: 'center',
  },
  input: {
    flex: 1,
  },
  link: {
    color: colors.light.rgba,
  },
  messagesContainer: {
    marginRight: '-1em',
    marginLeft: '-1em',
    marginBottom: 10,
    alignSelf: 'stretch',
  },
  message: {
    marginTop: '5px !important',
    marginBottom: '5px !important',
  },
  vertical: {
    flexDirection: 'column !important',
  },
  forgotPasswordContainer: {
    flex: 1,
    textAlign: 'center',
  },
})

export class Login extends React.Component {
  static propTypes = {
    dismissMessage: PropTypes.func.isRequired,
    error: PropTypes.object.isRequired,
    logIn: PropTypes.object.isRequired,
    messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
    }
  }

  render() {
    return (
      <div className={css(styles.page)}>
        <div className={css(styles.container)}>
          <div className={css(styles.messagesContainer)}>
            {this.props.messages.map((message, i) => (
              <Message
                key={i} // eslint-disable-line react/no-array-index-key
                className={css(styles.message)}
                color={message.color}
                header={message.header}
                content={message.content}
                list={message.contents}
                icon={message.icon}
                onDismiss={() => this.props.dismissMessage(i)}
              />
            ))}
            {this.props.error.non_field_errors.length > 0 && (
              <Message className={css(styles.message)} negative list={this.props.error.non_field_errors} />
            )}
          </div>
          <Grid>
            <Grid.Row stretched>
              <Image src={logo} centered height={100} />
            </Grid.Row>
            <Grid.Row stretched textAlign="center">
              <h2 className={css(styles.title)}>
                SEELK <span style={{marginLeft: 5, fontWeight: 500}}>E-COMMERCE STUDIO</span>
              </h2>
            </Grid.Row>
            <ErrorBoundary>
              <Grid.Row stretched className={css(styles.vertical)}>
                <Input
                  icon="user"
                  placeholder={i18n.t('misc.global.email')}
                  className={css(styles.input)}
                  onKeyDown={Keypress('enter', () =>
                    this.props.logIn.fetch({email: this.state.email, password: this.state.password})
                  )}
                  error={this.props.error.email.length > 0}
                  value={this.state.email}
                  onChange={(_, i) => this.setState({email: i.value})}
                />
                {this.props.error.email.map(v => (
                  <Label key={v} basic negative pointing>
                    {v}
                  </Label>
                ))}
              </Grid.Row>
              <Grid.Row stretched className={css(styles.vertical)}>
                <Input
                  icon="lock"
                  placeholder={i18n.t('misc.global.password')}
                  type="password"
                  className={css(styles.input)}
                  onKeyDown={Keypress('enter', () =>
                    this.props.logIn.fetch({email: this.state.email, password: this.state.password})
                  )}
                  error={this.props.error.password.length > 0}
                  value={this.state.password}
                  onChange={(_, i) => this.setState({password: i.value})}
                />
                {this.props.error.password.map(v => (
                  <Label key={v} basic negative pointing>
                    {v}
                  </Label>
                ))}
              </Grid.Row>
              <Grid.Row stretched>
                <Button
                  fluid
                  onClick={() => this.props.logIn.fetch({email: this.state.email, password: this.state.password})}
                  color="primary"
                >
                  {i18n.t('login.logIn')}
                </Button>
              </Grid.Row>
            </ErrorBoundary>
            <Grid.Row stretched>
              <div className={css(styles.forgotPasswordContainer)}>
                <Link to="/forgot_password">
                  <span className={css(styles.link)}>{i18n.t('login.forgotPassword')}</span>
                </Link>
              </div>
            </Grid.Row>
            <Divider />
            <Grid.Row stretched>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span style={{marginRight: 20}} className={css(styles.link)}>
                  {i18n.t('placeholders.empty.account')}
                </span>
                <Link to="/signup">
                  <Button inverted>{i18n.t('login.signUp')}</Button>
                </Link>
              </div>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    )
  }
}

export default withRequests({
  logIn: requestWrapperBuilder({
    requestingPath: 'login.login',
    fetchAction: 'LOGIN_REQUEST',
  }),
})(
  connect({
    props: (state, ownProps) => ({
      ...RelieverRegistry.moduleState('login', state).asMutable({deep: true}),
      messages: RelieverRegistry.moduleState('app', state).messages.asMutable({deep: true}),
      ...ownProps,
    }),
    functions: (ownProps, dispatch) => ({
      dismissMessage: i => {
        dispatch(RelieverRegistry.moduleActions('app').dismissMessage(i))
      },
    }),
  })(Login)
)
