import React from 'react'

import {Box, Col, Row} from 'jsxstyle'
import {List, ListRowProps} from 'react-virtualized'
import {Dropdown, Flag, FlagNameValues, Icon, InputOnChangeData} from 'semantic-ui-react'
import styled from 'styled-components'

import {fuzzysearch} from '../../..'
import colors from '../../../colors'
import i18n from '../../../i18n'
import OperatorInNotIn from '../OperatorInNotIn'
import PoppersPopup from '../PoppersPopup'
import DebouncedInput from './DebouncedInput'

const StyledApplyRow = styled(Row)`
  color: ${colors.white.rgba};
  background-color: ${colors.primary.rgba};
  border-radius: 0 0 3px 3px;
  font-weight: 500;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${colors.primaryDark.rgba};
  }
  margin: ${props => (props.noPadding ? '0' : '8px -8px -8px -8px')};
  position: relative;
  left: -1px;
  bottom: -1px;
  width: ${props => `calc(100% + ${props.noPadding ? '2px' : '18px'})`};
`

const StyledListItem = styled(Row)`
  &&&&& {
    align-items: ${props => props.theme.checkboxPanel.alignItems};
    background-color: ${props => props.theme.checkboxPanel.backgroundColor};
    height: ${props => props.theme.checkboxPanel.height};
    padding-left: ${props => props.theme.checkboxPanel.paddingLeft};
    padding-right: ${props => props.theme.checkboxPanel.paddingRight};

    /* This property is necessary to allow the row highlight not to break the border-radius of the background */
    border-radius: ${props => {
      if (props.position === -1) {
        return props.theme.radioPanel.rows.lastRowBorderRadius
      } else {
        return props.theme.radioPanel.rows.borderRadius
      }
    }};

    :hover {
      background-color: ${props => props.theme.checkboxPanel.hoverBackgroundColor};
      cursor: ${props => props.theme.checkboxPanel.hoverCursor};
    }
  }
`

interface SearchableSelectProps {
  choices: Record<string, string[]>
  onApply: () => void
  onChange: (changes: {key: string; value: string}) => void
  onClose: () => void
  icon?: string | null
  loading?: boolean
  operator?: 'in' | 'not_in'
  placeholder?: string
  title?: string
  updateOperator?: ((operator: 'in' | 'not_in') => void) | null
}

interface SearchableSelectState {
  search: string
}

const VirtualList = ({choices, onItemClick}: {choices: string[]; onItemClick: (choice: string) => void}) => {
  const rowRenderer = ({key, index, style}: ListRowProps) => {
    const choice = choices[index]
    return (
      <StyledListItem key={key} props={{onClick: () => onItemClick(choice)}} style={style}>
        <span style={{whiteSpace: 'nowrap'}}>{choice}</span>
      </StyledListItem>
    )
  }
  return (
    <List
      width={300}
      height={choices.length >= 8 ? 280 : 280 - (8 - choices.length) * 35}
      rowCount={choices.length}
      rowHeight={35}
      rowRenderer={rowRenderer}
    />
  )
}

class SearchableSelect extends React.Component<SearchableSelectProps, SearchableSelectState> {
  constructor(props: SearchableSelectProps) {
    super(props)
    this.state = {
      search: '',
    }
  }

  getOptionKeys = (optionsValues: Record<string, string[]>): {key: string; country: FlagNameValues; name: string}[] =>
    Object.keys(optionsValues)
      .filter(k => optionsValues[k].length > 0)
      .map(key => {
        const [, country] = key.split('_')
        return {key, country: country as FlagNameValues, name: i18n.t(`countries.${country}.name`)}
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        return 1
      })

  getOptionValues = (k: string): string[] => {
    if (this.state.search === '') {
      return this.props.choices[k].sort((a, b) => a.localeCompare(b))
    }
    return this.props.choices[k].filter(v => fuzzysearch(this.state.search, v)).sort((a, b) => a.localeCompare(b))
  }

  render() {
    const {
      icon = null,
      loading = false,
      placeholder = '',
      title = '',
      operator = 'in',
      updateOperator = null,
    } = this.props
    const optionsValues = Object.keys(this.props.choices).reduce((parent, optionKey) => {
      parent[optionKey] = this.getOptionValues(optionKey) // eslint-disable-line no-param-reassign
      return parent
    }, {} as Record<string, string[]>)

    return (
      <Box className="ui dropdown visible floating multiSelect">
        <Dropdown.Menu className="visible" style={{minWidth: 300}}>
          <Dropdown.Header icon={icon} content={title} />
          <Dropdown.Divider />
          <DebouncedInput
            onChange={(e: React.ChangeEvent<HTMLInputElement>, {value}: InputOnChangeData) =>
              this.setState({search: value})
            }
            icon="search"
            iconPosition="left"
            className="search"
            placeholder={placeholder}
            loading={loading}
            transparent={false}
            value={this.state.search}
          />
          {updateOperator ? (
            <Col style={{padding: '0 10px'}}>
              <OperatorInNotIn updateOperator={updateOperator} operator={operator} />
            </Col>
          ) : null}
          <Dropdown.Menu className="visible menuOverride scrolling">
            {this.getOptionKeys(optionsValues).map(({key, country, name}) => (
              <PoppersPopup
                style={{padding: 0}}
                key={key}
                position="right center"
                on={['hover']}
                hoverable
                trigger={
                  <div className="ui active visible fluid left pointing dropdown item">
                    <span>
                      <Flag name={country} /> {name}
                    </span>
                    <Icon name="caret right" />
                  </div>
                }
                content={
                  <Dropdown.Menu className="subMenuOverride scrolling">
                    <VirtualList
                      choices={optionsValues[key]}
                      onItemClick={(value: string) => this.props.onChange({key, value})}
                    />
                  </Dropdown.Menu>
                }
              />
            ))}
          </Dropdown.Menu>
          <StyledApplyRow
            noPadding
            props={{
              onClick: () => {
                this.props.onApply()
                this.props.onClose()
              },
            }}
          >
            <Box>{i18n.t('actions.global.apply')}</Box>
          </StyledApplyRow>
        </Dropdown.Menu>
      </Box>
    )
  }
}

export default SearchableSelect
