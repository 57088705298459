import React from 'react'

import {Box, Col} from 'jsxstyle'
import {Checkbox} from 'semantic-ui-react'

import i18n from '../../../../../i18n'
import {FilterButton} from '../../../..'
import FilterBarItem from './FilterBarItem'

const statusProvider = i18n.createProvider('visualization.grouping')

interface StatusProps {
  choices: string[]
  icon: string
  onApply: () => void
  onChange: (item: string) => void
  onRemove: () => void
  title: string
  data?: string | null
  disabled?: boolean
}

const Status = ({
  choices,
  icon,
  onApply,
  onChange,
  onRemove,
  title,
  data = null,
  disabled = false,
  ...barItemProps
}: StatusProps) => {
  const trigger = (
    <FilterButton
      title={title}
      disabled={disabled}
      icon={icon}
      onRemove={onRemove}
      onApply={onApply}
      dropdown={undefined}
    >
      <Box marginLeft="8px">{data ? statusProvider(data) : i18n.t('filters.all')}</Box>
    </FilterButton>
  )

  return (
    <FilterBarItem
      {...barItemProps}
      data={data}
      onChange={onChange}
      onApply={onApply}
      disabled={disabled}
      trigger={trigger}
    >
      <Col width={150} cursor="default">
        <Col padding="0px 8px 0px 8px" fontWeight="500">
          {choices.map(c => (
            <Checkbox
              radio
              disabled={disabled}
              key={c}
              onClick={() => onChange(c)}
              checked={data === c}
              label={{
                children: (
                  <Box>
                    <span>{statusProvider(c)}</span>
                  </Box>
                ),
              }}
              className="checkbox-element"
            />
          ))}
        </Col>
      </Col>
    </FilterBarItem>
  )
}

export default Status
