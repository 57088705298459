import React, {useEffect, useState} from 'react'

import {Tab, TabProps} from 'semantic-ui-react'
import styled, {css} from 'styled-components'

interface StyledTabProps extends TabProps {
  theme: any
  width: 'full' | 'min'
  alignment: 'left' | 'center' | 'right'
  overflowY: string
}

const StyledTabCSS = ({theme, width, alignment, overflowY}: StyledTabProps) => css`
  width: ${width === 'full' ? '100%' : 'fit-content'};
  overflow-y: ${overflowY} !important;
  .ui.menu {
    ${alignment === 'left' ? 'justify-content: flex-start !important;' : ''}
    ${alignment === 'center' ? 'justify-content: center !important;' : ''}
    ${alignment === 'right' ? 'justify-content: flex-end !important;' : ''}
  }
  .ui.menu .item {
    color: ${theme.tabs.tab.color} !important;
  }
  .ui.menu .item:hover {
    color: ${theme.tabs.tab.hover.color} !important;
  }
  .ui.menu .active.item {
    color: ${theme.tabs.tab.selected.color} !important;
    font-weight: ${theme.tabs.tab.selected.fontWeight} !important;
  }
  .ui.menu .item:hover:not(.active) {
    font-weight: ${theme.tabs.tab.hover.fontWeight} !important;
  }
`

const StyledOuterTabCSS = ({theme}: StyledTabProps) => css`
  .ui.segment {
    background-color: ${theme.tabs.outer.content.backgroundColor} !important;
    border-color: ${theme.tabs.outer.content.borderColor} !important;
    border-radius: ${theme.tabs.outer.content.borderRadius} !important;
  }
  .ui.menu .item:hover {
    box-shadow: ${theme.tabs.outer.tab.hover.boxShadow} !important;
    background-color: ${theme.tabs.outer.tab.hover.backgroundColor} !important;
  }
  .ui.menu .active.item {
    box-shadow: ${theme.tabs.outer.tab.active.boxShadow} !important;
    background-color: ${theme.tabs.outer.tab.active.backgroundColor} !important;
  }
  .ui.menu .active.item:hover {
    box-shadow: ${theme.tabs.outer.tab.active.hover.boxShadow} !important;
  }
`

const StyledInnerTabCSS = ({theme}: StyledTabProps) => css`
  .ui.segment {
    border: ${theme.tabs.inner.content.border} !important;
    background-color: ${theme.tabs.inner.content.backgroundColor} !important;
    padding: ${theme.tabs.inner.content.padding} !important;
  }
  .ui.menu {
    border-bottom-width: ${theme.tabs.inner.tab.borderWidth} !important;
  }
  .ui.menu .item {
    background-color: ${theme.tabs.inner.tab.backgroundColor} !important;
  }
  .ui.menu .active.item {
    border-color: ${theme.tabs.inner.tab.active.borderColor} !important;
    border-width: ${theme.tabs.inner.tab.active.borderWidth} !important;
  }
  .ui.menu a.item:active:not(.active) {
    border-color: ${theme.tabs.inner.tab.active.borderColorBottom} !important;
  }
`

const StyledSmallTabCSS = ({theme}: StyledTabProps) => css`
  .ui.segment {
    border: ${theme.tabs.small.content.border} !important;
  }
  .ui.menu {
    border-right: ${theme.tabs.small.tab.borderRight} !important;
  }
  .ui.menu .active.item {
    background-color: ${theme.tabs.small.tab.active.backgroundColor} !important;
  }
  .ui.menu .item:hover:not(.active) {
    background-color: ${theme.tabs.small.tab.hover.backgroundColor} !important;
  }
`

// eslint-disable-next-line react/prop-types
const StyledBaseTab = ({overflowY, ...props}: StyledTabProps) => <Tab {...props} />

const StyledTab = styled(StyledBaseTab)`
  &&&&& {
    ${props => StyledTabCSS(props)}
  }
`

const StyledOuterTab = styled(StyledTab)`
  &&&&& {
    ${props => StyledOuterTabCSS(props)}
  }
`

const StyledInnerTab = styled(StyledTab)`
  &&&&& {
    ${props => StyledInnerTabCSS(props)}
  }
`

const StyledSmallTab = styled(StyledTab)`
  &&&&& {
    ${props => StyledSmallTabCSS(props)}
  }
`

const getIndex = (tabKey: string, tabs: any[]) => tabs.findIndex(pane => pane.menuItem.key === tabKey)

const getKey = (tabIndex: number, tabs: any[]) => tabs[tabIndex].menuItem.key

interface SeelkTabsProps {
  onChange: (key: string | number) => void
  panes: any[]
  active?: string | number
  alignment?: 'left' | 'center' | 'right'
  className?: string
  overflowY?: string
  type?: 'outer' | 'inner' | 'small'
  width?: 'full' | 'min'
}

const SeelkTabs = ({
  onChange,
  panes,
  active = 0,
  alignment = 'left',
  overflowY = 'visible',
  type = 'small',
  width = 'min',
  ...props
}: SeelkTabsProps) => {
  const [currentActive, setCurrentActive] = useState<TabProps['activeIndex']>(
    typeof active === 'string' ? getIndex(active, panes) : active
  )

  useEffect(() => {
    if (active) setCurrentActive(typeof active === 'string' ? getIndex(active, panes) : active)
  }, [active, panes])

  const handleClick = (e: React.MouseEvent<HTMLDivElement>, data: TabProps) => {
    setCurrentActive(data.activeIndex)
    if (onChange) onChange(typeof data.activeIndex === 'number' ? getKey(data.activeIndex, panes) : data.activeIndex)
  }

  const panesContent = panes.map((pane, i) =>
    pane.content
      ? {
          ...pane,
          render: () => <Tab.Pane key={pane.menuItem.key + i.toString()}>{pane.content}</Tab.Pane>,
        }
      : {
          ...pane,
          render: () => <Tab.Pane key={pane.menuItem.key + i.toString()} style={{display: 'none'}} />,
        }
  )

  if (type === 'outer') {
    return (
      <StyledOuterTab
        menu={{secondary: true}}
        grid={null}
        panes={panesContent}
        activeIndex={currentActive}
        onTabChange={handleClick}
        overflowY={overflowY}
        width={width}
        {...props}
      />
    )
  }

  if (type === 'inner') {
    return (
      <StyledInnerTab
        menu={{secondary: true, pointing: true}}
        panes={panesContent}
        grid={null}
        activeIndex={currentActive}
        onTabChange={handleClick}
        alignment={alignment}
        overflowY={overflowY}
        width={width}
        {...props}
      />
    )
  }

  return (
    <StyledSmallTab
      menu={{attached: false}}
      panes={panesContent}
      grid={null}
      activeIndex={currentActive}
      onTabChange={handleClick}
      overflowY={overflowY}
      width={width}
      {...props}
    />
  )
}

export default SeelkTabs
