import React from 'react'

import {css, StyleSheet} from 'aphrodite'
import PropTypes from 'prop-types'
import Keypress from 'react-keypress'
import RelieverRegistry, {connect} from 'react-redux-reliever'
import {Link} from 'react-router-dom'
import {Button, Grid, Image, Input, Message} from 'semantic-ui-react'

import {validateEmail} from '../../utils'
import colors from '../../utils/colors'
import logo from '../../utils/common-ui/images/logo.png'
import i18n from '../../utils/i18n'
import LoaderWrapper from '../../utils/requesting/containers/LoaderWrapper'
import {requestWrapperBuilder, withRequests} from '../../utils/requesting/RequestWrapper'

const styles = StyleSheet.create({
  page: {
    backgroundColor: colors.primaryDark.rgba,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '10vh',
  },
  container: {
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '-1em',
    marginLeft: '-1em',
    marginBottom: 10,
  },
  input: {
    flex: 1,
  },
  resetPasswordButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 200,
    color: colors.white.rgba,
    flex: 1,
    textAlign: 'center',
  },
  vertical: {
    flexDirection: 'column !important',
  },
  mailSentContainer: {
    color: colors.white.rgba,
    textAlign: 'center',
  },
})

const Logo = () => (
  <Grid.Row centered stretched>
    <Link to="/">
      <Image src={logo} centered height={100} />
    </Link>
  </Grid.Row>
)

const Title = () => (
  <Grid.Row stretched textAlign="center">
    <h2 className={css(styles.title)}>
      SEELK <span style={{marginLeft: 5, fontWeight: 500}}>E-COMMERCE STUDIO</span>
    </h2>
  </Grid.Row>
)

class ForgotPassword extends React.Component {
  static propTypes = {
    requestPassword: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      renderEmailSent: false,
    }
  }

  renderSendEmail() {
    const requestPassword = () =>
      this.props.requestPassword.fetch({email: this.state.email}, {}, () => this.setState({renderEmailSent: true}))
    return (
      <div className={css(styles.page)}>
        <div className={css(styles.container)}>
          <Grid>
            <Logo />
            <Title />
            {this.props.requestPassword.hasFailed() && (
              <Grid.Row>
                <Message className={css(styles.input)} negative>
                  <Message.Header>{i18n.t('placeholders.errors.changePassword')}</Message.Header>
                  <p>{this.props.requestPassword.error}</p>
                </Message>
              </Grid.Row>
            )}
            <Grid.Row className={css(styles.vertical)}>
              <Input
                onKeyDown={Keypress('enter', requestPassword)}
                onChange={(_, event) => this.setState({email: event.value})}
                icon="user"
                className={css(styles.input)}
                placeholder={i18n.t('misc.global.email')}
              />
            </Grid.Row>
            <Grid.Row className={css(styles.resetPasswordButtonContainer, styles.vertical)}>
              <LoaderWrapper proxy requesting="forgot-password.resetPassword">
                <Button disabled={!validateEmail(this.state.email)} color="primary" onClick={requestPassword}>
                  {i18n.t('actions.global.requestPassword')}
                </Button>
              </LoaderWrapper>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    )
  }

  renderEmailSent() {
    return (
      <div className={css(styles.page)}>
        <div className={css(styles.container)}>
          <Grid>
            <Logo />
            <Title />
            <Grid.Row>
              <div className={css(styles.mailSentContainer)}>
                <span>{i18n.t('misc.global.emailSent', {email: this.state.email})}</span>
              </div>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    )
  }

  render() {
    if (!this.state.renderEmailSent) return this.renderSendEmail()
    return this.renderEmailSent()
  }
}

export default withRequests({
  requestPassword: requestWrapperBuilder({
    fetchAction: 'FORGOT-PASSWORD_SEND_RESET_PASSWORD_MAIL',
    requestingPath: 'forgot-password.resetPassword',
  }),
})(
  connect({
    props: (state, ownProps) => ({
      ...RelieverRegistry.moduleState('forgot-password', state).asMutable({deep: true}),
      ...ownProps,
    }),
  })(ForgotPassword)
)
