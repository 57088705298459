import React from 'react'

import {Box, Col, Row} from 'jsxstyle'
import _ from 'lodash'
import memoizeOne from 'memoize-one'
import {Divider, Dropdown, Icon, InputOnChangeData} from 'semantic-ui-react'
import styled from 'styled-components'

import {fuzzyWordsSearch} from '../..'
import colors from '../../colors'
import i18n from '../../i18n'
import {Flag as FlagCell, VirtualTable} from '../../visualization'
import DebouncedInput from './inputs/DebouncedInput'
import PoppersPopup from './PoppersPopup'

const StyledDropdown = styled(Dropdown)`
  &&&&& {
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    > i {
      padding: 0;
      margin: 0;
    }
  }
`

const StyledDropdownMenu = styled(Dropdown.Menu)`
  &&&&& {
    overflow-y: hidden;
    width: inherit;
    height: 270px;
    min-width: 350px;
    border-radius: 0 0.28571429rem 0.28571429rem 0.28571429rem;
    border-top-width: 1px !important;
  }
`

const UIHeaderRow = styled.div`
  font-size: 12px;
  color: ${colors.darkLight.rgba};
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
`

const UIDivider = styled.span`
  width: 1px;
  background-color: ${colors.lightDark.rgba};
`

const StyledDivider = styled(Divider)`
  &&&&& {
    margin: 0 !important;
  }
`

const UIDisplay = styled.div`
  font-size: 12px;
  :hover {
    cursor: pointer;
  }
`

const StyledDropdownHeader = styled(Dropdown.Header)`
  &&&&& {
    margin: 0;
    padding: 1em;
  }
`

const StyledIcon = styled(Icon)`
  &&&&& {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: ${colors.darkLight.rgba};
    cursor: pointer;
    :hover {
      color: ${colors.primary.rgba};
      font-size: 14px;
    }
  }
`

const StyledTriggerIcon = styled(Icon)`
  &&&&& {
    font-size: 0.9em;
    color: ${colors.darkAccent.rgba};
    margin-right: 10px;
  }
`

const UITriggerText = styled.span`
  color: ${colors.dark.rgba};
`

interface DisplayComponentProps {
  item: {display: string}
}

const DisplayComponent = ({item}: DisplayComponentProps) => <UIDisplay>{item.display}</UIDisplay>

const searchRegex = /[,;|\s/]+/

type OptionType = {
  value: string
  display?: string
  country?: string
}

interface MultiSelectProps {
  limit?: number | null
  onRowClick: (value: string[], remove?: boolean) => void
  optionComponent?: (item: DisplayComponentProps) => React.ReactNode
  options?: OptionType[]
  searchPlaceholder?: string
  selectedOptions?: string[]
  triggerIcon?: string | null
  triggerText: string
}

interface MulitSelectState {
  search: string
}

class MultiSelect extends React.Component<MultiSelectProps, MulitSelectState> {
  constructor(props: MultiSelectProps) {
    super(props)
    this.state = {
      search: '',
    }
  }

  countryColumn = {
    label: '',
    key: 'country',
    width: 40,
    component: FlagCell,
    style: {
      justifyContent: 'center',
    },
  }

  scheme = [
    {
      label: ``,
      key: 'key',
      width: 130,
      canGrow: true,
      canShrink: true,
      component: this.props.optionComponent || DisplayComponent,
      style: {fontWeight: 500},
    },
  ]

  optionsWithCountry = (options: OptionType[]) => _.some(options, ({country}) => country)

  onSearch = (search: string) => {
    this.setState({search})
  }

  onRowClick = (rowData: string[], remove?: boolean) => {
    const {selectedOptions = [], options = []} = this.props
    const limit = (this.props.limit ?? options.length) - selectedOptions.length
    if (!remove) {
      if (limit) {
        this.props.onRowClick(_.take(([] as string[]).concat(rowData), limit))
      }
    } else {
      this.props.onRowClick(rowData, remove)
    }
  }

  isOptionSelected = ({value}: OptionType, selectedOptions: string[]) => selectedOptions.includes(value)

  getOptionItems = memoizeOne((options: OptionType[], selectedOptions: string[], search: string[]) =>
    options.filter(
      item =>
        !this.isOptionSelected(item, selectedOptions) &&
        _.some(search, searchItem => fuzzyWordsSearch(searchItem, item?.display ?? ''))
    )
  )

  getSearch = () => this.state.search.split(searchRegex)

  getSelectedItems = memoizeOne((options: OptionType[], selectedOptions: string[], search: string[]) =>
    options.filter(
      item =>
        selectedOptions.includes(item.value) &&
        _.some(search, searchItem => fuzzyWordsSearch(searchItem, item?.display ?? ''))
    )
  )

  getSelectedItem = () => {
    const {triggerIcon = null, triggerText, selectedOptions = []} = this.props
    return (
      <>
        {triggerIcon && <StyledTriggerIcon name={triggerIcon} />}
        <UITriggerText>
          {triggerText} ({selectedOptions.length})
        </UITriggerText>
      </>
    )
  }

  render() {
    const {options = [], selectedOptions = [], searchPlaceholder = i18n.t('actions.search.search')} = this.props
    const optionItems = this.getOptionItems(options, selectedOptions, this.getSearch())
    const selectedOptionItems = this.getSelectedItems(options, selectedOptions, this.getSearch())
    const scheme = this.optionsWithCountry(options) ? [this.countryColumn, ...this.scheme] : this.scheme
    return (
      <Box flex="1 0 auto">
        <StyledDropdown basic multiple className="selection" text={this.getSelectedItem()}>
          <StyledDropdownMenu onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}>
            <Col flex={1} height="100%">
              <StyledDropdownHeader>
                <DebouncedInput
                  fluid
                  size="small"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, {value}: InputOnChangeData) => {
                    this.onSearch(value)
                  }}
                  transparent={false}
                  icon="search"
                  placeholder={searchPlaceholder}
                  value={this.state.search}
                />
              </StyledDropdownHeader>
              <StyledDivider fitted />
              <Row flex={1}>
                <Col flex="1 50%">
                  <UIHeaderRow>
                    {i18n.t('visualization.grouping.allCount', {count: optionItems?.length})}
                    <PoppersPopup
                      content={i18n.t('actions.global.selectResult')}
                      position="bottom right"
                      trigger={
                        <div>
                          {optionItems?.length ? (
                            <StyledIcon
                              name="arrow right"
                              onClick={() => this.onRowClick(optionItems.map(o => o.value))}
                            />
                          ) : null}
                        </div>
                      }
                    />
                  </UIHeaderRow>
                  <Divider fitted />
                  <Col flex={1}>
                    {optionItems && (
                      <VirtualTable
                        headerHeight={0}
                        items={optionItems}
                        columns={scheme}
                        rowHeight={30}
                        overflowX
                        disableHeader
                        shouldSplitColor={false}
                        onRowClick={({event, rowData: {value}}: {event: React.MouseEvent<any>; rowData: any}) => {
                          event.stopPropagation()
                          this.onRowClick([value])
                        }}
                      />
                    )}
                  </Col>
                </Col>
                <UIDivider />
                <Col flex="1 50%">
                  <UIHeaderRow>
                    <PoppersPopup
                      content={i18n.t('actions.global.removeResult')}
                      position="bottom left"
                      trigger={
                        <div>
                          {selectedOptionItems?.length ? (
                            <StyledIcon
                              name="arrow left"
                              onClick={() =>
                                this.onRowClick(
                                  selectedOptionItems.map(so => so.value),
                                  true
                                )
                              }
                            />
                          ) : null}
                        </div>
                      }
                    />
                    {i18n.t('visualization.grouping.selectedCount', {count: selectedOptionItems?.length})}
                  </UIHeaderRow>
                  <Divider fitted />
                  <Col flex={1}>
                    {selectedOptionItems && (
                      <VirtualTable
                        headerHeight={0}
                        items={selectedOptionItems}
                        columns={scheme}
                        rowHeight={30}
                        // overflowX
                        disableHeader
                        shouldSplitColor={false}
                        onRowClick={({event, rowData: {value}}: {event: React.MouseEvent<any>; rowData: any}) => [
                          event.stopPropagation(),
                          this.onRowClick([value], true),
                        ]}
                      />
                    )}
                  </Col>
                </Col>
              </Row>
            </Col>
          </StyledDropdownMenu>
        </StyledDropdown>
      </Box>
    )
  }
}

export default MultiSelect
