import _ from 'lodash'

// polyfill for old chrome versions
Object.values = Object.values ? Object.values : obj => Object.keys(obj).map(k => obj[k])
Object.entries = Object.entries ? Object.entries : obj => Object.keys(obj).map(k => [k, obj[k]])
// eslint-disable-next-line no-extend-native
Array.prototype.flat = Array.prototype.flat
  ? Array.prototype.flat
  : function flat(depth = 1) {
      return _(this).flatten(depth).value()
    }

// eslint-disable-next-line no-extend-native
Array.prototype.lodash = function lodash() {
  return _(this)
}
