import React from 'react'

import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'

import colors from './colors'
import {GOKU_FEEDBACK_FORM_URL} from './constants'
import i18n from './i18n'

const defaultContent = (
  // eslint-disable-next-line jsx-a11y/accessible-emoji
  <a style={{color: colors.negative}} target="_blank" href={GOKU_FEEDBACK_FORM_URL} rel="noreferrer noopener">
    😵&nbsp;&nbsp;
    <b style={{textDecoration: 'underline', cursor: 'pointer'}}>{i18n.t('placeholders.errors.report')}</b>
  </a>
)

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    style: PropTypes.object,
    errorContent: PropTypes.node,
  }

  static defaultProps = {
    className: '',
    style: {},
    errorContent: defaultContent,
  }

  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true})
    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key])
      })
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.hasError) {
      const {className, style, errorContent} = this.props
      return (
        <div className={className} style={style}>
          {errorContent}
        </div>
      )
    }
    return this.props.children
  }
}
