import React from 'react'

import {css, StyleSheet} from 'aphrodite'
import {Box, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import {Dropdown, Icon, Menu} from 'semantic-ui-react'
import styled from 'styled-components'

import {capitalize} from '../../..'
import colors from '../../../colors'
import PoppersPopup from '../PoppersPopup'

const styles = StyleSheet.create({
  menuItem: {
    padding: '0em 1em',
  },
  dropdown: {
    padding: '0em 1em',
  },
})

const StyledSpan = styled.span`
  &&&&& {
    color: ${props => props.active && colors.primary.rgba};
    :hover {
      color: ${props => (props.disabled ? '' : colors.primary.rgba)};
    }
  }
`

const sizes = {
  tiny: 14,
  mini: 16,
  medium: 18,
  large: 20,
  huge: 22,
}

class Choose extends React.Component {
  static propTypes = {
    active: PropTypes.string,
    availableChoices: PropTypes.arrayOf(PropTypes.string).isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    disabledChoices: PropTypes.object,
    dropdown: PropTypes.bool,
    explicitTitle: PropTypes.bool,
    formatTitle: PropTypes.func,
    icons: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    size: PropTypes.string,
    title: PropTypes.node,
    tooltips: PropTypes.object,
    type: PropTypes.string,
    vertical: PropTypes.bool,
  }

  static defaultProps = {
    active: undefined,
    className: undefined,
    disabled: false,
    disabledChoices: {},
    dropdown: false,
    explicitTitle: false,
    formatTitle: capitalize,
    icons: [],
    size: undefined,
    title: undefined,
    tooltips: {},
    type: 'default',
    vertical: false,
  }

  handleItemClick = (e, {name}) => {
    this.props.onChange(name)
  }

  getTooltip = choice => {
    const {disabledChoices, formatTitle, tooltips} = this.props
    return disabledChoices?.[choice] ?? tooltips?.[choice] ?? formatTitle(choice)
  }

  isDisabled = choice => {
    const {disabled, disabledChoices} = this.props
    return disabled || !!disabledChoices?.[choice]
  }

  render() {
    if (this.props.availableChoices.length === 0) return null
    if (this.props.dropdown) {
      return (
        <Menu compact size={this.props.size} className={this.props.className}>
          <Dropdown
            text={
              this.props.explicitTitle ? (
                <span>
                  {this.props.title} <b style={{fontSize: '0.95em'}}>{capitalize(this.props.active)}</b>
                </span>
              ) : (
                this.props.title
              )
            }
            item
            pointing={this.props.vertical || 'left'}
            className={`link item ${css(styles.dropdown)}`}
            style={{width: '100%'}}
          >
            <Dropdown.Menu>
              {this.props.availableChoices.map(v => (
                <Dropdown.Item
                  key={v}
                  className={!this.props.vertical ? css(styles.menuItem) : undefined}
                  name={v}
                  disabled={this.props.disabled}
                  active={this.props.active === v}
                  onClick={this.handleItemClick}
                >
                  {this.props.formatTitle(v)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      )
    }

    switch (this.props.type) {
      case 'labels':
        return (
          <Row userSelect="none" className={this.props.className}>
            {this.props.title && (
              <Box fontWeight="500" fontSize={sizes[this.props.size || 'medium']} color={colors.primaryDark.rgba}>
                {this.props.title}
              </Box>
            )}
            {this.props.availableChoices.map((v, index) => {
              const isActive = this.props.active === v
              const props = {
                marginLeft: index === 0 && this.props.title ? 8 : 0,
                fontSize: sizes[this.props.size || 'medium'],
                height: 25,
                padding: '0px 12px 0px 12px',
                borderBottom: isActive ? `solid 2px ${colors.primary.rgba}` : `solid 1px ${colors.border.rgba}`,
                color: isActive ? colors.primary.rgba : colors.darkLight.rgba,
                fontWeight: isActive ? '500' : 'regular',
                cursor: 'pointer',
                props: {
                  onClick: () => this.handleItemClick(null, {name: v}),
                },
              }
              return (
                <Box key={v} {...props}>
                  {this.props.formatTitle(v)}
                </Box>
              )
            })}
          </Row>
        )
      case 'text': // rename this later
        return (
          <Row className={this.props.className}>
            {this.props.availableChoices.map((v, index) => (
              <Box
                key={v}
                cursor="pointer"
                color={this.props.active === v.toLowerCase() ? colors.primary.rgba : colors.lightDark.rgba}
                borderBottom={this.props.active === v.toLowerCase() && `3px solid ${colors.primary.rgba}`}
                paddingBottom={this.props.active === v.toLowerCase() && 2}
                marginRight={index < this.props.availableChoices.length - 1 && '1em'}
                fontSize="1.05em"
                fontWeight={400}
                props={{
                  onClick: () => this.handleItemClick(null, {name: v.toLowerCase()}),
                }}
              >
                {this.props.icons[index] && <Icon name={this.props.icons[index]} />}
                {v}
              </Box>
            ))}
          </Row>
        )
      default:
        return (
          <Menu compact size={this.props.size} vertical={this.props.vertical} className={this.props.className}>
            {this.props.title && <Menu.Item header>{this.props.title}</Menu.Item>}
            {this.props.availableChoices.map(v => (
              <PoppersPopup
                key={v}
                position="top center"
                content={this.getTooltip(v)}
                trigger={
                  <Menu.Item
                    key={v}
                    className={!this.props.vertical ? css(styles.menuItem) : undefined}
                    name={v}
                    disabled={this.isDisabled(v)}
                    active={this.props.active === v}
                    onClick={this.handleItemClick}
                    style={{
                      backgroundColor: this.props.active === v && colors.backgroundTab.rgba,
                    }}
                  >
                    <StyledSpan disabled={this.isDisabled(v)} active={this.props.active === v}>
                      {this.props.formatTitle(v)}
                    </StyledSpan>
                  </Menu.Item>
                }
              />
            ))}
          </Menu>
        )
    }
  }
}

export default Choose
