import React from 'react'

import {toast} from 'react-toastify'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'
import {PoppersPopup} from '../../common-ui'
import i18n from '../../i18n'

const StyledCopyIcon = styled(Icon)`
  &&&&& {
    display: flex;
    align-self: center;
    justify-self: center;
    margin: 0 auto;
    cursor: pointer;
    color: ${colors.lightDark.rgba};
    :hover {
      color: ${colors.primary.rgba};
    }
  }
`

const copyAsin = (asin: string) => {
  const textField = document.createElement('textarea')
  textField.value = asin
  textField.style.height = '0'
  document.body.appendChild(textField)
  textField.select()
  try {
    const success = document.execCommand('copy')
    if (success) {
      textField.remove()
    }
  } catch (err) {
    textField.remove()
  }
}

type ItemType = {
  asin: string
}

interface CopyAsinProps {
  item?: ItemType | null
}

const CopyAsin = ({item = null}: CopyAsinProps) => {
  if (!item) return null
  return (
    <PoppersPopup
      content={i18n.t('actions.global.copyAsin')}
      position="left center"
      trigger={
        <div className="catalog_copy_asin">
          <StyledCopyIcon
            name="copy"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.stopPropagation()
              copyAsin(item.asin)
              toast.success(i18n.t('actions.select.copyAsin', {asin: item.asin}), {
                autoClose: 5000,
                style: {padding: '10px', borderLeft: `5px solid var(--toastify-icon-color-success)`},
              })
            }}
          />
        </div>
      }
    />
  )
}

export default CopyAsin
