import TimeSeries from './TimeSeries'

class SovRankingSeries extends TimeSeries {
  createDimensions() {
    super.createDimensions()
    this.cf.dimensions.keywords = this.cf.data.dimension(v => v.keyword)
  }

  updateFilters(values = {}, lockId = 'default') {
    super.updateFilters(values, lockId)
    const {keywords = null} = this.filters[lockId]
    this.filterDimension(this.cf.dimensions.keywords, keywords)
  }

  getUnitAttribute(unit) {
    return obj => {
      switch (unit) {
        case unit:
          return obj[unit]
        default:
          return super.getUnitAttribute(unit)(obj)
      }
    }
  }
}

export default SovRankingSeries
