import React from 'react'

import {Label} from 'semantic-ui-react'

import {clamp} from '../..'
import colors from '../../colors'
import i18n from '../../i18n'

const sectionMap = {
  MAIN: {
    text: i18n.t('visualization.grouping.sections.MAIN'),
    color: colors.secondaryDark.rgba,
  },
  EXTR: {
    text: i18n.t('visualization.grouping.sections.EXTR'),
    color: colors.warning.rgba,
  },
  DETA: {
    text: i18n.t('visualization.grouping.sections.DETA'),
    color: colors.darkAccent.rgba,
  },
  COMP: {
    text: i18n.t('visualization.grouping.sections.COMP'),
    color: colors.negative.rgba,
  },
} as const

interface SeelkSectionLabelProps {
  color?: string | null
  maxLength?: number | null
  section: keyof typeof sectionMap
}

class SeelkSectionLabel extends React.Component<SeelkSectionLabelProps> {
  render() {
    const {section, color = null, maxLength = null} = this.props
    const name = sectionMap[section]?.text
    return (
      <Label
        key={section}
        value={section}
        basic
        style={{
          color: color ?? sectionMap[section]?.color,
          borderColor: color ?? sectionMap[section]?.color,
          padding: '4px 6px',
          margin: '4px 0 4px 2px',
          whiteSpace: 'nowrap',
        }}
      >
        {name ? clamp(name, maxLength ?? undefined) : ''}
      </Label>
    )
  }
}

export default SeelkSectionLabel
