import React from 'react'

import PropTypes from 'prop-types'
import {Button, Dropdown, Flag, Icon, Label, Message, Progress, Segment} from 'semantic-ui-react'
/* eslint-disable */
Button.propTypes.color = PropTypes.string
Icon.propTypes.color = PropTypes.string
Icon.propTypes.name = PropTypes.string
Label.propTypes.color = PropTypes.string
Progress.propTypes.color = PropTypes.string
Segment.propTypes.color = PropTypes.string
Dropdown.propTypes.text = PropTypes.oneOfType([PropTypes.string, PropTypes.node])
Flag.propTypes.name = PropTypes.string
Message.propTypes.color = PropTypes.string
/* eslint-enable */

/**
 * Intermediate component to update props for Icon to work with FontAwesome-Pro */

const defaultIconRenderMethod = Icon.prototype.render
class DefaultIcon extends Icon {
  render = defaultIconRenderMethod
}

function render() {
  const getClassName = () => {
    return this.props.className ? `${this.props.className} ${this.props.name}` : this.props.name
  }

  const isFAIcon = () => this.props.name?.includes('fa-')

  return isFAIcon() ? (
    <DefaultIcon {...{...this.props, name: null, className: getClassName()}} />
  ) : (
    <DefaultIcon {...this.props} />
  )
}

Icon.prototype.render = render
