import {Moment, unitOfTime} from 'moment'

type PrevNextOffsetPeriodType = {
  relativeStartDate: Moment
  relativeEndDate: Moment
  mode: unitOfTime.DurationConstructor
}

type PrevNextPeriodType = {
  relativeStartDate: Moment
  relativeEndDate: Moment
}
export const DAY_PRESETS = {
  MTD: 'mtd',
  QTD: 'qtd',
  YTD: 'ytd',
  YESTERDAY: 'yesterday',
  LAST_7_DAYS: 'last7Days',
  LAST_30_DAYS: 'last30Days',
  LAST_90_DAYS: 'last90Days',
  CURRENT_WEEK: 'currentWeek',
  LAST_WEEK: 'lastWeek',
  CURRENT_MONTH: 'currentMonth',
  LAST_MONTH: 'lastWeek',
}

export const WEEK_PRESETS = {
  QTD: 'qtd',
  YTD: 'ytd',
  CURRENT_WEEK: 'currentWeek',
  LAST_WEEK: 'lastWeek',
  LAST_4_WEEKS: 'last4Weeks',
  LAST_12_WEEKS: 'last12Weeks',
}

export const MONTH_PRESETS = {
  QTD: 'qtd',
  YTD: 'ytd',
  CURRENT_MONTH: 'currentMonth',
  LAST_MONTH: 'lastWeek',
  LAST_3_MONTHS: 'last4Weeks',
}

export const PERIOD_PRESETS = {
  ...DAY_PRESETS,
  ...WEEK_PRESETS,
  ...MONTH_PRESETS,
}

export const ALL_PRESETS = {
  now: ({currentDate}: {currentDate: Moment}) => [currentDate.clone(), currentDate.clone()],
  wtd: ({currentDate}: {currentDate: Moment}) => [currentDate.clone().startOf('week'), currentDate.clone()],
  mtd: ({currentDate}: {currentDate: Moment}) => [currentDate.clone().startOf('month'), currentDate.clone()],
  qtd: ({currentDate}: {currentDate: Moment}) => [currentDate.clone().startOf('quarter'), currentDate.clone()],
  ytd: ({currentDate}: {currentDate: Moment}) => [currentDate.clone().startOf('year'), currentDate.clone()],
  ytdMonth: ({currentDate}: {currentDate: Moment}) => [
    currentDate.clone().startOf('year'),
    currentDate
      .clone()
      .subtract(1, 'month')
      .endOf('month'),
  ],
  qtdAmazonWeek: ({currentDate}: {currentDate: Moment}) => [
    currentDate
      .clone()
      .startOf('quarter')
      .startOf('week'),
    currentDate.clone(),
  ],
  ytdAmazonWeek: ({currentDate}: {currentDate: Moment}) => [
    currentDate
      .clone()
      .startOf('year')
      .startOf('week'),
    currentDate
      .clone()
      .subtract(1, 'week')
      .endOf('week'),
  ],
  yesterday: ({currentDate}: {currentDate: Moment}) => [
    currentDate.clone().subtract(1, 'days'),
    currentDate.clone().subtract(1, 'days'),
  ],
  last7Days: ({currentDate}: {currentDate: Moment}) => [currentDate.clone().subtract(7, 'days'), currentDate.clone()],
  last30Days: ({currentDate}: {currentDate: Moment}) => [currentDate.clone().subtract(30, 'days'), currentDate.clone()],
  last90Days: ({currentDate}: {currentDate: Moment}) => [currentDate.clone().subtract(90, 'days'), currentDate.clone()],
  last180Days: ({currentDate}: {currentDate: Moment}) => [
    currentDate.clone().subtract(180, 'days'),
    currentDate.clone(),
  ],
  last365Days: ({currentDate}: {currentDate: Moment}) => [
    currentDate.clone().subtract(365, 'days'),
    currentDate.clone(),
  ],
  currentWeek: ({currentDate}: {currentDate: Moment}) => [
    currentDate.clone().startOf('week'),
    currentDate.clone().endOf('week'),
  ],
  lastWeek: ({currentDate}: {currentDate: Moment}) => [
    currentDate
      .clone()
      .subtract(1, 'week')
      .startOf('week'),
    currentDate
      .clone()
      .subtract(1, 'week')
      .endOf('week'),
  ],
  last4Weeks: ({currentDate}: {currentDate: Moment}) => [
    currentDate
      .clone()
      .subtract(4, 'week')
      .startOf('week'),
    currentDate
      .clone()
      .subtract(1, 'week')
      .endOf('week'),
  ],
  last12Weeks: ({currentDate}: {currentDate: Moment}) => [
    currentDate
      .clone()
      .subtract(12, 'week')
      .startOf('week'),
    currentDate
      .clone()
      .subtract(1, 'week')
      .endOf('week'),
  ],
  last26Weeks: ({currentDate}: {currentDate: Moment}) => [
    currentDate
      .clone()
      .subtract(26, 'week')
      .startOf('week'),
    currentDate
      .clone()
      .subtract(1, 'week')
      .endOf('week'),
  ],
  last52Weeks: ({currentDate}: {currentDate: Moment}) => [
    currentDate
      .clone()
      .subtract(52, 'week')
      .startOf('week'),
    currentDate
      .clone()
      .subtract(1, 'week')
      .endOf('week'),
  ],
  currentMonth: ({currentDate}: {currentDate: Moment}) => [
    currentDate.clone().startOf('month'),
    currentDate.clone().endOf('month'),
  ],
  lastMonth: ({currentDate}: {currentDate: Moment}) => [
    currentDate
      .clone()
      .subtract(1, 'month')
      .startOf('month'),
    currentDate
      .clone()
      .subtract(1, 'month')
      .endOf('month'),
  ],
  last3Months: ({currentDate}: {currentDate: Moment}) => [
    currentDate
      .clone()
      .subtract(3, 'month')
      .startOf('month'),
    currentDate
      .clone()
      .subtract(1, 'month')
      .endOf('month'),
  ],
  last6Months: ({currentDate}: {currentDate: Moment}) => [
    currentDate
      .clone()
      .subtract(6, 'month')
      .startOf('month'),
    currentDate
      .clone()
      .subtract(1, 'month')
      .endOf('month'),
  ],
  last12Months: ({currentDate}: {currentDate: Moment}) => [
    currentDate
      .clone()
      .subtract(12, 'month')
      .startOf('month'),
    currentDate
      .clone()
      .subtract(1, 'month')
      .endOf('month'),
  ],
  prevPeriod: ({relativeStartDate, relativeEndDate, mode}: PrevNextOffsetPeriodType, offset = 1) => {
    const endDate = relativeStartDate
      .clone()
      .subtract(1, mode)
      .endOf(mode)
    const startDate = endDate
      .clone()
      .subtract(Math.max(relativeEndDate.diff(relativeStartDate, mode) - offset, 0), mode)
      .startOf(mode)
    return [startDate, endDate]
  },
  nextPeriod: ({relativeStartDate, relativeEndDate, mode}: PrevNextOffsetPeriodType, offset = 1) => {
    const startDate = relativeEndDate
      .clone()
      .add(1, mode)
      .startOf(mode)
    const endDate = startDate
      .clone()
      .add(relativeEndDate.diff(relativeStartDate, mode) - offset, mode)
      .endOf(mode)
    return [startDate, endDate]
  },
  prevMonth: ({relativeStartDate, relativeEndDate}: PrevNextPeriodType) => [
    relativeStartDate.clone().subtract(1, 'month'),
    relativeEndDate.clone().subtract(1, 'month'),
  ],
  nextMonth: ({relativeStartDate, relativeEndDate}: PrevNextPeriodType) => [
    relativeStartDate.clone().add(1, 'month'),
    relativeEndDate.clone().add(1, 'month'),
  ],
  prevYear: ({relativeStartDate, relativeEndDate}: PrevNextPeriodType) => [
    relativeStartDate.clone().subtract(1, 'year'),
    relativeEndDate.clone().subtract(1, 'year'),
  ],
  nextYear: ({relativeStartDate, relativeEndDate}: PrevNextPeriodType) => [
    relativeStartDate.clone().add(1, 'year'),
    relativeEndDate.clone().add(1, 'year'),
  ],
  weekPrevYear: ({relativeStartDate, relativeEndDate}: PrevNextPeriodType) => [
    relativeStartDate
      .clone()
      .endOf('week')
      .subtract(1, 'year')
      .week(relativeStartDate.week())
      .startOf('week'),
    relativeEndDate
      .clone()
      .endOf('week')
      .subtract(1, 'year')
      .week(relativeEndDate.week())
      .endOf('week'),
  ],
  weekNextYear: ({relativeStartDate, relativeEndDate}: PrevNextPeriodType) => {
    return [
      relativeStartDate
        .clone()
        .startOf('week')
        .add(1, 'year')
        .week(relativeStartDate.week())
        .startOf('week'),
      relativeEndDate
        .clone()
        .startOf('week')
        .add(1, 'year')
        .week(relativeEndDate.week())
        .endOf('week'),
    ]
  },
}

export const DEFAULT_PRESETS = {
  day: {
    // wtd: ALL_PRESETS.wtd,
    mtd: ALL_PRESETS.mtd,
    qtd: ALL_PRESETS.qtd,
    ytd: ALL_PRESETS.ytd,
    // yesterday: ALL_PRESETS.yesterday,
    // last7Days: ALL_PRESETS.last7Days,
    last30Days: ALL_PRESETS.last30Days,
    last90Days: ALL_PRESETS.last90Days,
    last180Days: ALL_PRESETS.last180Days,
    last365Days: ALL_PRESETS.last365Days,
  },
  week: {
    // currentWeek: ALL_PRESETS.currentWeek,
    lastWeek: ALL_PRESETS.lastWeek,
    mtd: ALL_PRESETS.mtd,
    qtd: ALL_PRESETS.qtdAmazonWeek,
    ytdAmazonWeek: ALL_PRESETS.ytdAmazonWeek,
    last4Weeks: ALL_PRESETS.last4Weeks,
    last12Weeks: ALL_PRESETS.last12Weeks,
    last26Weeks: ALL_PRESETS.last26Weeks,
    last52Weeks: ALL_PRESETS.last52Weeks,
  },
  month: {
    // currentMonth: ALL_PRESETS.currentMonth,
    lastMonth: ALL_PRESETS.lastMonth,
    qtd: ALL_PRESETS.qtd,
    ytdMonth: ALL_PRESETS.ytdMonth,
    last3Months: ALL_PRESETS.last3Months,
    last6Months: ALL_PRESETS.last6Months,
    last12Months: ALL_PRESETS.last12Months,
  },
  quarter: {now: ALL_PRESETS.now, qtd: ALL_PRESETS.qtd},
  year: {now: ALL_PRESETS.now},
}

export const COMPARISON_PRESETS = {
  day: {
    now: ALL_PRESETS.now,
    prevPeriod: ALL_PRESETS.prevPeriod,
    nextPeriod: ALL_PRESETS.nextPeriod,
    prevMonth: ALL_PRESETS.prevMonth,
    nextMonth: ALL_PRESETS.nextMonth,
    prevYear: ALL_PRESETS.prevYear,
    nextYear: ALL_PRESETS.nextYear,
  },
  week: {
    now: ALL_PRESETS.now,
    prevPeriod: ALL_PRESETS.prevPeriod,
    nextPeriod: ALL_PRESETS.nextPeriod,
    prevYear: ALL_PRESETS.weekPrevYear,
    nextYear: ALL_PRESETS.weekNextYear,
  },
  month: {
    now: ALL_PRESETS.now,
    prevPeriod: ALL_PRESETS.prevPeriod,
    nextPeriod: ALL_PRESETS.nextPeriod,
    prevYear: ALL_PRESETS.prevYear,
    nextYear: ALL_PRESETS.nextYear,
  },
  year: {
    now: ALL_PRESETS.now,
    prevPeriod: ALL_PRESETS.prevPeriod,
    nextPeriod: ALL_PRESETS.nextPeriod,
  },
}

export const MODES = ['day', 'week', 'month', 'quarter', 'year'] as const

export const CALENDAR_LAYOUT = {
  day: {rows: 6, cols: 7},
  week: {rows: 6, cols: 7},
  month: {rows: 4, cols: 3},
  quarter: {rows: 2, cols: 2},
  year: {rows: 4, cols: 3},
}

export const DEFAULT_FORMATS = {
  year: (date: Moment) => date.year(),
  quarter: (date: Moment) => date.format('[Q]Q'),
  month: (date: Moment) => date.format('MMM'),
  week: (date: Moment) => date.date(),
  day: (date: Moment) => date.date(),
} as const

export type FormatType = keyof typeof FORMATS

export const FORMATS = {
  year: 'YYYY',
  quarter: '[Q]Q/YYYY',
  month: 'MM/YYYY',
  week: '[w]ww/gggg',
  day: '',
} as const

export const format = (mode: keyof typeof FORMATS) => {
  return FORMATS[mode]
}

export const REGEXPS = {
  year: /^\d{4}$/,
  quarter: /^\d{1}$/,
  month: /^\d{2}\/\d{4}$/,
  week: /^w\d{2}\/\d{4}$/,
  day: /^\d{2}\/\d{2}\/\d{4}$/,
} as const
