import React from 'react'

import {Col, Row} from 'jsxstyle'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'
import theme from '../../theme'
import Spacer from './Spacer'

const StyledRow = styled(Row)`
  &&& {
    position: absolute;
    bottom: 12px;
    left: 25vw;
    height: 90px;
    width: 50vw;
    align-items: center;
    padding: 20px;
    background-color: ${colors.primaryDark.rgba};
    border-radius: 5px;
    box-shadow: 0px 0px 4px ${colors.shadow.rgba};
    z-index: 10;
  }
`

interface AbsoluteMessageProps {
  closeMessage: (event?: React.MouseEvent<HTMLButtonElement>) => void
  message?: string
}

const AbsoluteMessage = ({message = '', closeMessage}: AbsoluteMessageProps) => (
  <StyledRow>
    <Icon name="bolt" size="big" inverted />
    <Spacer size="12px" />
    <Col flex={1}>
      <p style={{fontSize: 14, color: colors.white.rgba, margin: 0, marginBottom: 8, fontWeight: 400}}>{message}</p>
      <p style={{fontSize: 12, color: colors.white.rgba, opacity: 0.75}}>
        To learn more, please contact our
        <span
          className={theme.interactiveUnderline}
          style={{color: colors.secondary.rgba}}
          onClick={() => window.Intercom('showMessages')}
        >
          {` customer support team.`}
        </span>
      </p>
    </Col>
    <Col height="100%" alignItems="flex-end">
      <Icon name="close" onClick={closeMessage} style={{cursor: 'pointer', margin: 0}} inverted />
    </Col>
  </StyledRow>
)

export default AbsoluteMessage
