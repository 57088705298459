import React, {useState} from 'react'

import {Col, Row} from 'jsxstyle'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {AutoSizer, List as VirtualList} from 'react-virtualized'
import {Checkbox, List, ListItem} from 'semantic-ui-react'
import styled from 'styled-components'

import {fuzzysearch} from '../../..'
import colors from '../../../colors'
import i18n from '../../../i18n'
import theme from '../../../theme'
import PopOver from '../PopOver'
import DebouncedInput from './DebouncedInput'

const UIContentPane = styled.div`
  display: flex;
  height: calc(100% - 30px);
`

const StyledRow = styled(Row)`
  padding: 10px 10px 0 10px;
`

const StyledList = styled(List)`
  &&&&& {
    height: 300px;
    width: 100%;
    overflow: scroll;
  }
`

const StyledListItem = styled(ListItem)`
  &&&&& {
    height: 30px;
    font-weight: ${props => (props.selected ? '500' : props.color)};
    > div > label {
      color: ${props => (props.selected ? colors.primary.rgba : props.color)};
      :focus {
        color: ${props => (props.selected ? colors.primary.rgba : props.color)};
      }
      &::after {
        color: ${props => (props.selected ? colors.primary.rgba : props.color)};
        :focus {
          color: ${props => (props.selected ? colors.primary.rgba : props.color)};
        }
      }
    }
  }
`

const StyledDebouncedInput = styled(DebouncedInput)`
  &&&&& {
    width: 100%;
  }
`

function VirtualFilterSearch({choices, data, disabled, onChange, placeholder, trigger}) {
  const [name, setName] = useState('')
  const [filters, setFilters] = useState([])
  const displayName = name.replace(/\n/g, ' ')
  const filteredChoices = filters.length ? filters : choices.filter(item => fuzzysearch(name, item || ''))

  const isSelected = choice => data.indexOf(choice) > -1

  const allSelected = () =>
    _.difference(filters.length ? filters : choices.filter(item => fuzzysearch(name, item || '')), data).length === 0

  const anySelected = () => data.length > 0 && data.length < choices.length

  const handleChange = value => {
    const valueChecked = data.indexOf(value) > -1
    onChange(valueChecked ? data.filter(item => item !== value) : [...data, value])
  }

  const handleCheckAll = () => {
    onChange(filters.length ? filters : choices.filter(item => fuzzysearch(name, item || '')))
  }

  const handleUncheckAll = () => {
    onChange(data.filter(value => choices.indexOf(value) === -1))
  }

  const handleGlobalCheck = () => {
    if (allSelected()) handleUncheckAll()
    else handleCheckAll()
  }

  const handleUpdate = value => {
    const regex = placeholder === i18n.t('actions.search.asin') ? /[,;|\s/]+/ : /[\n,;|/]+/

    const _value = value && value.split(regex)

    const filter = _value ? _value.filter(item => choices.includes(item)) : []

    setName(value)
    setFilters([...new Set(filter)])
  }

  const rowRenderer = ({key, index, style}) => {
    const choice = filteredChoices[index]
    return (
      <StyledListItem key={key} selected={isSelected(choice)} style={style}>
        <Checkbox
          label={<label style={{whiteSpace: 'nowrap'}}>{choice}</label>}
          checked={isSelected(choice)}
          onChange={() => handleChange(choice)}
        />
      </StyledListItem>
    )
  }

  return (
    <PopOver containerProps={{width: '100%'}} height="40px" marginRight="8px" trigger={trigger} disabled={disabled}>
      <Col
        justifyContent="center"
        className={theme.using(theme.bordered, theme.shadowed)}
        marginTop="8px"
        padding={0}
        backgroundColor={colors.white.rgba}
      >
        <StyledRow>
          <StyledDebouncedInput
            fluid
            icon="search"
            disabled={disabled}
            iconPosition="left"
            transparent={false}
            placeholder={placeholder}
            resetOnPaste={false}
            onChange={(e, {value}) => {
              handleUpdate(value)
            }}
            onPaste={value => {
              handleUpdate(value)
            }}
            value={displayName}
          />
        </StyledRow>
        <StyledRow alignItems="center">
          <StyledList>
            <StyledListItem selected={allSelected()} color={anySelected() ? colors.black.rgba : colors.lightDark.rgba}>
              <Checkbox
                style={{whiteSpace: 'nowrap'}}
                checked={allSelected()}
                indeterminate={anySelected()}
                onChange={handleGlobalCheck}
                label={i18n.t('actions.select.all')}
              />
            </StyledListItem>
            <UIContentPane>
              <AutoSizer disableHeight>
                {({width}) => (
                  <VirtualList
                    width={width}
                    height={265}
                    rowCount={filteredChoices.length}
                    rowHeight={30}
                    rowRenderer={rowRenderer}
                  />
                )}
              </AutoSizer>
            </UIContentPane>
          </StyledList>
        </StyledRow>
      </Col>
    </PopOver>
  )
}
VirtualFilterSearch.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  trigger: PropTypes.node.isRequired,
}

VirtualFilterSearch.defaultProps = {
  disabled: false,
  placeholder: '',
}

export default VirtualFilterSearch
