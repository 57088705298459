import React from 'react'

import {Icon, Label} from 'semantic-ui-react'
import styled from 'styled-components'

import {PoppersPopup} from '../../common-ui'
import {clamp} from '../../index'

type TagType = {
  [slug: string]: {
    slug: string
    name: string
    color: string
    dynamic: boolean
  }
}

type ExtraType = {
  tags: TagType
  onClick?: ({event, value}: {event: React.MouseEvent<HTMLElement>; value: string}) => void
}

interface TagsProps {
  value: string
  extra: ExtraType
}

const StyledPopup = styled(props => <PoppersPopup {...props} />)`
  &&& {
    padding: 5px 8px;
    font-size: 12px;
  }
`

const Tags = ({value, extra}: TagsProps) => {
  const {tags} = extra
  if (value === 'undefined') return <p>{value}</p>
  return (
    <StyledPopup
      content={tags[value] ? tags[value].name : value}
      position="top left"
      basic
      trigger={
        <Label
          onClick={event => {
            if (extra.onClick) extra.onClick({event, value})
          }}
          key={value}
          value={value}
          style={{
            cursor: 'pointer',
            backgroundColor: tags[value] ? tags[value].color : '#aaa',
            color: 'white',
            padding: '4px 6px',
            margin: '4px 0 4px 2px',
          }}
        >
          {tags[value]?.dynamic ? <Icon name="bolt" /> : null}
          {clamp(tags[value] ? tags[value].name : value, 33)}
        </Label>
      }
    />
  )
}

export default Tags
