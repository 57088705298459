import React from 'react'

import {Row} from 'jsxstyle'

import colors from '../../../colors'
import {PoppersPopup, SortIcon} from '../../../common-ui'

type SortDirectionType = 'asc' | 'desc'
interface RotatingHeaderProps {
  label: string
  sortActive: boolean
  sortDirection: SortDirectionType
  columnData?: any
  sort?: ((sortKind: SortDirectionType) => void) | null
}

const RotatingHeader = ({sortActive, sortDirection, label, columnData = {}, sort = null}: RotatingHeaderProps) => {
  const sortKind = (() => {
    switch (sortDirection) {
      case 'asc':
        return 'desc'
      default:
        return 'asc'
    }
  })()
  return (
    <PoppersPopup
      content={columnData && columnData.headerTooltip ? columnData.headerTooltip : label}
      basic
      trigger={
        <div
          className={`ReactVirtualized__Table__headerTruncatedText ${
            columnData.rotatedHeader ? 'ReactVirtualized__Table__headerRotated' : ''
          } ${sort ? 'ReactVirtualized__Table__sort' : ''}`}
          title={label}
        >
          <Row
            textDecoration="underline"
            color={sortActive ? colors.primary.rgba : colors.darkLight.rgba}
            cursor={sort ? 'pointer' : 'inherit'}
            marginBottom={columnData.rotatedHeader ? '6px' : '4px'}
            marginLeft={columnData.rotatedHeader ? '12px' : '0px'}
            userSelect="none"
            props={{onClick: sort && (() => sort(sortKind))}}
          >
            {label}
            {sort && <SortIcon onSort={sort} status={sortActive ? sortDirection : undefined} />}
          </Row>
        </div>
      }
    />
  )
}

export default RotatingHeader
