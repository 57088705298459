import React from 'react'

import {Block} from 'jsxstyle'
import styled from 'styled-components'

import colors from '../../colors'

const StyledSpan = styled.span`
  height: 20px;
  width: 15px !important;
  margin: 0 auto;
`

type ExtraType = {
  amazon?: boolean
}

interface CellFlagProps {
  value?: string | null
  placehold?: boolean
  extra?: ExtraType
}

const CellFlag = ({value = null, placehold, extra}: CellFlagProps) => {
  if (placehold || !value)
    return <Block style={{width: 16, height: 11, display: 'inline-block', background: colors.lightShadow.rgba}} />
  if (extra?.amazon) value = value.replace('amazon_', '') // eslint-disable-line no-param-reassign
  return <StyledSpan className={`flag-icon flag-icon-${value.toLowerCase() === 'uk' ? 'gb' : value}`} />
}

export default CellFlag
