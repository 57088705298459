import React from 'react'

import PropTypes from 'prop-types'

import {mergeDeep} from '../..'
import Chart from './Chart'

const sparkLineConfig = {
  chart: {
    margin: [0, 0, 0, 0],
    style: {
      overflow: 'visible',
    },
    skipClone: true,
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    labels: {
      enabled: false,
    },
    tickLength: 0,
    startOnTick: false,
    endOnTick: false,
    tickPositions: [],
  },
  yAxis: {
    title: {
      text: null,
    },
    maxPadding: 0,
    minPadding: 0,
    min: 0,
    tickPositions: [],
    gridLineWidth: 0,
    ticks: false,
    startOnTick: false,
    endOnTick: false,
    labels: {
      enabled: false,
    },
  },
  plotOptions: {
    series: {
      lineWidth: 1,
      shadow: false,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
    },
  },
  exporting: {
    enabled: false,
  },
}

class Sparkline extends React.PureComponent {
  static propTypes = {
    extraConfig: PropTypes.object,
  }

  static defaultProps = {
    extraConfig: {},
  }

  render() {
    const {extraConfig, ...rest} = this.props
    return <Chart extraConfig={mergeDeep(sparkLineConfig, extraConfig)} {...rest} />
  }
}

export default Sparkline
