import React from 'react'
import ReactDOM from 'react-dom'

import OutsideClickHandler from '../../OutsideClickHandler'

interface Position {
  x: number
  y: number
}

interface AbsolutePopupProps {
  children: React.ReactNode
  client: Position
  handleOnOutsideClick: () => void
  visible: boolean
}

interface AbsolutePopupState {
  pos: Position
}

class AbsolutePopup extends React.Component<AbsolutePopupProps, AbsolutePopupState> {
  private menu = React.createRef<HTMLDivElement>()

  constructor(props: AbsolutePopupProps) {
    super(props)
    this.state = {
      pos: {
        x: 0,
        y: 0,
      },
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: AbsolutePopupProps) {
    if (nextProps.visible) this.setMenuPosition()
  }

  setMenuPosition() {
    const {innerWidth: windowWidth, innerHeight: windowHeight} = window
    const {clientWidth: menuWidth, clientHeight: menuHeight} = this.menu?.current ?? {clientWidth: 0, clientHeight: 0}
    let {x, y} = this.handlePos(this.props.client)

    if (x + menuWidth > windowWidth) {
      x -= x + menuWidth - windowWidth
    }

    if (y + menuHeight > windowHeight) {
      y -= y + menuHeight - windowHeight
    }

    this.setState({
      pos: {
        x,
        y,
      },
    })
  }

  handlePos(client: Position) {
    if (client.x === null || client.x < 0) {
      client.x = 0 // eslint-disable-line no-param-reassign
    }

    if (client.y === null || client.y < 0) {
      client.y = 0 // eslint-disable-line no-param-reassign
    }

    return client
  }

  render() {
    return ReactDOM.createPortal(
      <div
        style={{
          visibility: this.props.visible ? 'visible' : 'hidden',
          position: 'absolute',
          zIndex: 42,
          left: this.state.pos.x,
          top: this.state.pos.y,
          maxHeight: '100vh',
          overflowY: 'scroll',
        }}
        ref={this.menu}
      >
        <OutsideClickHandler
          onOutsideClick={() => {
            if (this.props.visible) {
              this.props.handleOnOutsideClick()
            }
          }}
        >
          {this.props.children}
        </OutsideClickHandler>
      </div>,
      document.body
    )
  }
}

export default AbsolutePopup
