import currency from 'currency-formatter'
import i18n from 'i18n-js'

const supportedLanguages = ['en', 'fr']

/* eslint-disable */
i18n.translations = supportedLanguages.reduce((parent, child) => {
  parent[child] = require(`./${child}`).default
  return parent
}, {})
/* eslint-enable */
i18n.missingTranslation = () => {
  return undefined
}

i18n.fallbacks = true
i18n.locales.no = 'en' // falls back to english by default

i18n.t = (scope, options) => {
  const opts = options || {count: 0}
  const locale = (() => {
    if (i18n.store) {
      // const language = i18n.store.getState().settings.getIn(["preferences", "language"]);
      // return language && language.split ? language : "en";
      return 'en'
    }
    return 'en'
  })()
  return i18n.translate(scope, {locale, ...opts})
}

i18n.toCurrency = (number, options) =>
  currency.format(number, {
    ...options,
    decimal: '.',
    thousand: ',',
  })

i18n.toReducedNumber = (number, options) => {
  if (number / 10000000 >= 1 || number / 10000000 <= -1) return `${i18n.toNumber(number / 1000000, options)}M`
  else if (number / 1000 >= 1 || number / 1000 <= -1) return `${i18n.toNumber(number / 1000, options)}K`
  return i18n.toNumber(number, options)
}

i18n.toReducedCurrency = (number, options = {}) => {
  if (number / 10000000 >= 1 || number / 10000000 <= -1)
    return `${i18n.toCurrency(number / 1000000, {...options, locale: 'en-US'})}M`
  else if (number / 1000 >= 1 || number / 1000 <= -1)
    return `${i18n.toCurrency(number / 1000, {...options, locale: 'en-US'})}K`
  return i18n.toCurrency(number, {...options})
}

i18n.toReducedPercentage = (number, options) => {
  if (number / 10000000 >= 1 || number / 10000000 <= -1) return `${i18n.toNumber(number / 1000000, options)}M%`
  else if (number / 1000 >= 1 || number / 1000 <= -1) return `${i18n.toNumber(number / 1000, options)}K%`
  return i18n.toPercentage(number, options)
}

i18n.createProvider = (prefix, suffix) => (key, params) =>
  i18n.t(`${prefix}.${key}${suffix ? `.${suffix}` : ''}`, params)

export default i18n
