import misc from './misc'
import onboarding from './onboarding'
import placeholders from './placeholders'
import settings from './settings'

export default {
  onboarding,
  misc,
  placeholders,
  settings,
}
