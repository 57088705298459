import React from 'react'

import {Label} from 'semantic-ui-react'
import styled from 'styled-components'

import {capitalize} from '../..'
import colors from '../../colors'
import i18n from '../../i18n'

const StyledFilterContainer = styled.div`
  display: inline-block;
  border-radius: 3px;
  background-color: ${colors.light.rgba};
  margin: 3px;
  padding: 5px;
`

const StyledFilterName = styled.span`
  font-size: 12px;
  color: ${colors.darkLight.rgba};
`

const StyledLabel = styled(Label)`
  &&&&& {
    padding: 3px 5px;
    background-color: ${colors.darkLight.rgba};
    color: ${colors.white.rgba};
  }
`

const StyledFilterValue = styled.b`
  font-size: 11px;
  font-weight: 500;
`

interface StaticArrayFiltersProps {
  filters: Record<string, string[]>
}

const StaticArrayFilters = ({filters = {}}: StaticArrayFiltersProps) => {
  return (
    <div>
      {Object.entries(filters).map(([key, value]) =>
        Array.isArray(value) ? (
          <StyledFilterContainer key={key}>
            <StyledFilterName>{`${i18n.t(`filters.${key}`) || capitalize(key)}: `}</StyledFilterName>
            {value.map(v => (
              <StyledLabel key={v}>
                <StyledFilterValue>{v}</StyledFilterValue>
              </StyledLabel>
            ))}
          </StyledFilterContainer>
        ) : null
      )}
    </div>
  )
}

export default StaticArrayFilters
