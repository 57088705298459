import React, {useState} from 'react'

import {Col, Row} from 'jsxstyle'
import {DragDropContext, Draggable, DraggableLocation, Droppable, DropResult} from 'react-beautiful-dnd'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../../colors'
import {PopOver, PoppersPopup, SeelkButton, Spacer} from '../../../common-ui'
import i18n from '../../../i18n'
import {SchemeType} from './Types'

const StyledIconContainer = styled.div`
  display: flex;
  height: 100%;
  width: 10px;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: ${colors.shadow.alpha(0.2).rgba};
  margin-right: 8px;
  opacity: 0;
  :hover {
    background-color: ${colors.primary.alpha(0.75).rgba};
  }
`

type StyledDraggableType = {
  readonly isDragging: boolean
}

const StyledDraggable = styled.div<StyledDraggableType>`
  display: flex;
  align-items: center;
  user-select: none;
  height: 30px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: ${props => (props.isDragging ? colors.primaryLightAlpha.rgba : colors.white.rgba)};

  &:hover > ${StyledIconContainer} {
    opacity: 1;
  }
  & > ${StyledIconContainer} {
    opacity: ${props => (props.isDragging ? 1 : 0)};
  }
`

const StyledIcon = styled(Icon)`
  &&&& {
    padding: 0;
    margin: 0;
    line-height: 1;
  }
`

const StyledSpan = styled.span`
  font-weight: 500;
  font-size: 12px;
  padding: 4px 12px 4px 12px;
  color: ${colors.darkLight.rgba};
  text-transform: uppercase;
`

type StyledListType = {
  readonly isDraggingOver: boolean
}

const StyledList = styled.div<StyledListType>`
  border: 1px ${props => (props.isDraggingOver ? `dashed ${colors.lightDark.alpha(0.5).rgba}` : null)};
  position: relative;
  padding: 8px;
  width: 100%;
  border-radius: 4px;
`

const reorder = <T extends unknown>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * Moves an item from one list to another list.
 */
const move = <T extends unknown>(
  source: T[],
  destination: T[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result: Record<string, T[]> = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

type SchemesTogglesProps = {
  schemeList: SchemeType[]
  updateScheme?: (orderedScheme: Record<string, string>) => void
}

const SchemesToggles = ({schemeList, updateScheme}: SchemesTogglesProps) => {
  const [open, setOpen] = useState(false)

  const getList = (status: string) => schemeList.filter(scheme => scheme.status === status)

  const onDragEnd = (result: DropResult) => {
    const {source, destination} = result

    // dropped outside the list
    if (!destination) return

    // dropped before a locked item (which can't be dragged)
    const lockedIndexesCount = getList(destination.droppableId).filter(item => item.locked).length
    if (lockedIndexesCount > 0 && destination.index < lockedIndexesCount) return

    // we are moving an element in the same list
    if (source.droppableId === destination.droppableId) {
      const items = reorder<SchemeType>(getList(source.droppableId), source.index, destination.index)

      // function to recreate an ordered scheme with the correct status applied
      const getOrderedList = (id: string, isId: boolean) => {
        if (isId)
          return items.reduce<Record<string, string>>((parent, child) => {
            parent[child.statusKey] = id // eslint-disable-line no-param-reassign

            return parent
          }, {})
        return getList(id).reduce<Record<string, string>>((parent, child) => {
          parent[child.statusKey] = id // eslint-disable-line no-param-reassign

          return parent
        }, {})
      }

      const orderedScheme = {
        ...getOrderedList('visibleAndSticky', source.droppableId === 'visibleAndSticky'),
        ...getOrderedList('visibleAndNotSticky', source.droppableId === 'visibleAndNotSticky'),
        ...getOrderedList('hidden', source.droppableId === 'hidden'),
      }

      if (updateScheme) updateScheme(orderedScheme)
    }
    // we are moving an element from a list to another list
    else {
      const items = move(getList(source.droppableId), getList(destination.droppableId), source, destination)

      // function to recreate an ordered scheme with the correct status applied
      const getOrderedList = (id: string, isId: boolean) => {
        if (isId)
          return items[id].reduce<Record<string, string>>((parent, child) => {
            parent[child.statusKey || child.key] = id // eslint-disable-line no-param-reassign

            return parent
          }, {})
        return getList(id).reduce<Record<string, string>>((parent, child) => {
          parent[child.statusKey || child.key] = id // eslint-disable-line no-param-reassign

          return parent
        }, {})
      }

      const orderedScheme = {
        ...getOrderedList(
          'visibleAndSticky',
          source.droppableId === 'visibleAndSticky' || destination.droppableId === 'visibleAndSticky'
        ),
        ...getOrderedList(
          'visibleAndNotSticky',
          source.droppableId === 'visibleAndNotSticky' || destination.droppableId === 'visibleAndNotSticky'
        ),
        ...getOrderedList('hidden', source.droppableId === 'hidden' || destination.droppableId === 'hidden'),
      }

      if (updateScheme) updateScheme(orderedScheme)
    }
  }

  // hide/show an element and recreate an ordered scheme with the correct status applied
  const handleOnEyeIconClick = (item: SchemeType, source: string) => {
    const getOrderedList = (list: SchemeType[], status: string) =>
      list.reduce((parent, child) => {
        parent[child.statusKey || child.key] = status // eslint-disable-line no-param-reassign

        return parent
      }, {} as Record<string, string>)

    const visibleAndSticky = getList('visibleAndSticky')
    const visibleAndNotSticky = getList('visibleAndNotSticky')
    const hidden = getList('hidden')

    if (source === 'hidden') {
      visibleAndNotSticky.push({...item, status: 'visibleAndNotSticky'})
      hidden.splice(
        getList('hidden').findIndex(elem => (elem.statusKey || elem.key) === (item.statusKey || item.key)),
        1
      )
    } else if (source === 'visibleAndNotSticky') {
      visibleAndNotSticky.splice(
        getList('visibleAndNotSticky').findIndex(elem => (elem.statusKey || elem.key) === (item.statusKey || item.key)),
        1
      )
      hidden.push({...item, status: 'hidden'})
    } else {
      visibleAndSticky.splice(
        getList('visibleAndSticky').findIndex(elem => (elem.statusKey || elem.key) === (item.statusKey || item.key)),
        1
      )
      hidden.push({...item, status: 'hidden'})
    }

    const orderedScheme = {
      ...getOrderedList(visibleAndSticky, 'visibleAndSticky'),
      ...getOrderedList(visibleAndNotSticky, 'visibleAndNotSticky'),
      ...getOrderedList(hidden, 'hidden'),
    }

    if (updateScheme) updateScheme(orderedScheme)
  }

  const getIconsForAnItem = (item: SchemeType, eyeIcon: string, eyeColor: string) => (
    <React.Fragment>
      {item.locked ? (
        <Spacer size="18px" />
      ) : (
        <StyledIconContainer>
          <StyledIcon name="ellipsis vertical" color="white" />
        </StyledIconContainer>
      )}
      {item.locked ? (
        <StyledIcon name="lock" color="shadow" style={{marginRight: 8}} />
      ) : (
        <StyledIcon
          name={eyeIcon}
          color={eyeColor}
          style={{marginRight: 8, cursor: 'pointer'}}
          onClick={() => handleOnEyeIconClick(item, item.status)}
        />
      )}
    </React.Fragment>
  )

  const getLabelForAnItem = (item: SchemeType) =>
    `${item.label} ${
      item.props && item.props.subLabel && item.props.subLabel !== 'TO_IGNORE' ? item.props.subLabel : ''
    }`

  return (
    <React.Fragment>
      <PopOver
        triggered={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        containerProps={{
          transform: 'translateX(calc(-100% + 38px))',
        }}
        trigger={
          <PoppersPopup
            content={i18n.t('placeholders.global.columnsDisplay')}
            position="top right"
            trigger={<SeelkButton onClick={() => {}} type="tool" icon="columns" displaySelect />}
          />
        }
      >
        <Col
          width="300px"
          maxHeight="500px"
          boxShadow={`0px 0px 4px ${colors.shadow}`}
          borderRadius="4px"
          border={`solid 1px ${colors.primaryAccent.rgba}`}
          backgroundColor={colors.white.rgba}
        >
          <Row
            alignItems="center"
            padding={8}
            backgroundColor={colors.lightAccent.rgba}
            width="100%"
            borderTopLeftRadius={4}
            borderTopRightRadius={4}
          >
            <StyledIcon name="columns" />
            <Spacer size="8px" />
            <span style={{fontWeight: 500, fontSize: 12}}>{i18n.t('visualization.global.tableColumns')}</span>
          </Row>
          <DragDropContext onDragEnd={onDragEnd}>
            <Col overflowY="scroll" paddingLeft={4} paddingRight={4} paddingBottom={4}>
              {[
                {
                  key: 'visibleAndSticky',
                  eyeIcon: 'eye',
                  eyeIconColor: 'primary',
                },
                {
                  key: 'visibleAndNotSticky',
                  eyeIcon: 'eye',
                  eyeIconColor: 'secondary',
                },
                {
                  key: 'hidden',
                  eyeIcon: 'eye slash',
                  eyeIconColor: 'lightDark',
                },
              ].map(droppableItem => (
                <React.Fragment key={droppableItem.key}>
                  <StyledSpan>{i18n.t(`visualization.global.${droppableItem.key}`)}</StyledSpan>
                  <Droppable
                    droppableId={droppableItem.key}
                    renderClone={(provided, snapshot, rubric) => (
                      <StyledDraggable
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                        style={provided.draggableProps.style}
                      >
                        {getIconsForAnItem(
                          getList(droppableItem.key)[rubric.source.index],
                          droppableItem.eyeIcon,
                          droppableItem.eyeIconColor
                        )}
                        {getLabelForAnItem(getList(droppableItem.key)[rubric.source.index])}
                      </StyledDraggable>
                    )}
                  >
                    {(droppableProvided, droppableSnapshot) => (
                      <StyledList
                        ref={droppableProvided.innerRef}
                        isDraggingOver={droppableSnapshot.isDraggingOver}
                        {...droppableProvided.droppableProps}
                      >
                        {getList(droppableItem.key).map((item, index) => (
                          <Draggable isDragDisabled={item.locked} key={item.key} draggableId={item.key} index={index}>
                            {(draggableProvided, draggableSnapshot) => (
                              <StyledDraggable
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                isDragging={draggableSnapshot.isDragging}
                                style={draggableProvided.draggableProps.style}
                              >
                                {getIconsForAnItem(item, droppableItem.eyeIcon, droppableItem.eyeIconColor)}
                                {getLabelForAnItem(item)}
                              </StyledDraggable>
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </StyledList>
                    )}
                  </Droppable>
                </React.Fragment>
              ))}
            </Col>
          </DragDropContext>
        </Col>
      </PopOver>
      <Spacer size="8px" />
    </React.Fragment>
  )
}

export default SchemesToggles
