import React from 'react'

import PropTypes from 'prop-types'
import {Icon} from 'semantic-ui-react'

import colors from '../../colors'
import i18n from '../../i18n'

interface PercentageComparisonProps {
  percentage: number
}

const PercentageComparison = ({percentage}: PercentageComparisonProps) => {
  if (percentage === 0 || percentage === i18n.t('placeholders.empty.notAvailable'))
    return (
      <span
        style={{
          height: 28,
          fontWeight: '500',
          fontSize: 12,
          padding: 5,
          color: colors.darkLight.rgba,
          backgroundColor: colors.light.rgba,
          borderRadius: 5,
          paddingLeft: 10,
          paddingRight: 10,
          display: 'inline-flex',
        }}
      >
        {i18n.t('placeholders.empty.notAvailable')}
      </span>
    )
  return (
    <span
      style={{
        height: 28,
        fontWeight: '500',
        fontSize: 12,
        padding: 5,
        color: percentage < 0 ? colors.negative.rgba : colors.positive.rgba,
        backgroundColor: percentage < 0 ? colors.negativeAlpha.rgba : colors.positiveAlpha.rgba,
        borderRadius: 5,
        paddingLeft: 5,
        paddingRight: 5,
        display: 'inline-flex',
      }}
    >
      <Icon name={percentage < 0 ? 'caret down' : 'caret up'} />
      {percentage > 0 && '+'}
      {i18n.toReducedPercentage(percentage * 100, {precision: 0})}
    </span>
  )
}

PercentageComparison.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

PercentageComparison.defaultProps = {
  percentage: 0,
}

export default PercentageComparison
