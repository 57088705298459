import React from 'react'

import {css, StyleSheet} from 'aphrodite'
import {Box} from 'jsxstyle'

import GokuLoader from '../../../easter-eggs/GokuLoader/index'
import PermissionControl from '../../../PermissionControl'
import jokes from './jokes.json'
import ReactLoaderQuotes from './ReactLoaderQuotes'

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
})

class AnimatedLoader extends React.PureComponent {
  render() {
    return (
      <Box display="flex" flex={1} justifyContent="center" alignItems="center" className={css(styles.container)}>
        <PermissionControl showIfOne={['dev', 'brandmanager']}>
          <GokuLoader />
        </PermissionControl>
        <PermissionControl hideIfOne={['dev', 'brandmanager']}>
          <ReactLoaderQuotes random speed={5} quotes={jokes} />
        </PermissionControl>
      </Box>
    )
  }
}

export default AnimatedLoader
