import {Reliever} from 'react-redux-reliever'
import {ofType} from 'redux-observable'
import {combineLatest, of} from 'rxjs'
import {catchError, map, mergeMap, tap} from 'rxjs/operators'

import {KaioApi, SenzuApi} from '../utils/apis'

class AccountSettingsReliever extends Reliever {
  ACTION_PREFIX = 'ACCOUNT-SETTINGS'

  getInitialState() {
    return {
      tenant: null,
      account: null,
      isVendor: false,
    }
  }

  checkIsVendorAccountEpic(action$) {
    return action$.pipe(
      ofType('APP_SET_USER'),
      mergeMap(action =>
        KaioApi('getVendorCredentials', action.payload.user.client).pipe(
          map(
            vendorAccount => ({
              type: 'ACCOUNT-SETTINGS_SET_VENDOR_ACCOUNT',
              payload: {
                client: action.payload.user.client,
                isSeelkFree: action.payload.user.client !== 'seelk',
                isVendor: !!vendorAccount?.length,
              },
            }),
            tap(() => action._reqWrapper.end()),
            catchError(err => {
              if (err.code === 404)
                return of({
                  type: 'ACCOUNT-SETTINGS_SET_VENDOR_ACCOUNT',
                  payload: {
                    client: action.payload.user.client,
                    isSeelkFree: action.payload.user.client !== 'seelk',
                    isVendor: false,
                  },
                })
              return action._reqWrapper.fail({error: err})
            })
          )
        )
      )
    )
  }

  fetchSettingsEpic(action$) {
    return action$.pipe(
      ofType('ACCOUNT-SETTINGS_FETCH_SETTINGS'),
      mergeMap(action =>
        combineLatest([SenzuApi('getTenant'), KaioApi('fetchSettings')]).pipe(
          map(([tenant, account]) => ({
            type: 'ACCOUNT-SETTINGS_FETCH_SETTINGS_SUCCESS',
            payload: {tenant, account},
          })),
          tap(() => action._reqWrapper.end()),
          catchError(err => action._reqWrapper.fail({error: err}))
        )
      )
    )
  }

  updateSettingsEpic(action$) {
    return action$.pipe(
      ofType('ACCOUNT-SETTINGS_UPDATE_SETTINGS'),
      mergeMap(action => {
        const {currency, settings} = action
        const updateCurrency$ = currency ? SenzuApi('setCurrencyTenant', {currency}) : of(null)
        const updateSettings$ = settings ? KaioApi('updateSetting', {...settings}) : of(null)
        return combineLatest([updateCurrency$, updateSettings$]).pipe(
          map(() => {
            return {
              type: 'ACCOUNT-SETTINGS_UPDATE_SETTINGS_SUCCESS',
              payload: {
                ...(currency ? {tenant: {currency}} : {}),
                account: {
                  ...settings,
                },
              },
            }
          }),
          tap(() => action._reqWrapper.end()),
          catchError(err => action._reqWrapper.fail({error: err}))
        )
      })
    )
  }
}

export default AccountSettingsReliever
