import React from 'react'

import {Col, Row} from 'jsxstyle'
import _ from 'lodash'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import {lerp3Colors} from '../..'
import colors from '../../colors'
import Color from '../../colors/Color'

const StyledCell = styled(Col)`
  &&&&& {
    flex: 1;
    height: 100%;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: ${props => (props.lerp ? Color.hex(props.lerp, 0.5).rgba : colors.lightDark.alpha(0.5).rgba)};
  }
`

type ExtraType = {
  lerpLimits?: {min: number; max: number}
}

type ItemType = {
  [key: string]: {
    value: number | null
    display: string
    delta?: number | string | null
    deltaDisplay: string
  }
}

interface LerpCellProps {
  extra: ExtraType
  item: ItemType
  schemeKey: string
}

const LerpCell = (props: LerpCellProps) => {
  const {schemeKey, item, extra} = props
  const {value, display, delta, deltaDisplay} = item[schemeKey] || {}

  let icon = null

  const {lerpLimits = {}} = extra

  const lerpValue = (v: number, {min = 0, max = 100}) =>
    v <= min ? 0 : v >= max ? 100 : ((v - min) / (max - min)) * 100

  const getIcon = (v: number) => {
    if (v > 0) return 'triangle up'
    if (v < 0) return 'triangle down'
    return null
  }

  if (!_.isNil(delta) && typeof delta === 'number') {
    icon = getIcon(delta)
  }

  return (
    <StyledCell
      lerp={
        value === null
          ? null
          : lerp3Colors(
              colors.negative.hex,
              colors.warning.hex,
              colors.positive.hex,
              lerpValue(value, lerpLimits) / 100
            )
      }
    >
      {!_.isNil(delta) && value !== null && (
        <Row fontSize={12} justifyContent="flex-end">
          {icon && <Icon className={icon} />}
          {deltaDisplay}
        </Row>
      )}
      <Row flex={1} justifyContent="center" alignItems="center">
        {value === null ? '-' : display}
      </Row>
    </StyledCell>
  )
}

export default LerpCell
