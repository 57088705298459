import {connectRouter} from 'connected-react-router'
import {plugins} from 'react-redux-reliever'
import {applyMiddleware, createStore} from 'redux'
import {from, Observable, queueScheduler} from 'rxjs'
import {observable as SymbolObservable} from 'rxjs/internal/symbol/observable'

import compose from './compose'
import middlewares, {history} from './middlewares'
import ReduxRelieverRegistry from './registry'

const initialState = undefined
const rootReducer = ReduxRelieverRegistry.buildRootReducer({
  router: connectRouter(history),
})

const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middlewares)))
ReduxRelieverRegistry.setupStore(store)

const extensions = plugins.RxRelieverPlugin.extensions()

Observable.getStore = () => {
  const patchedObservable = extensions.getStore()
  // It sucks but compatibility between old rx and new rx comes at a price
  patchedObservable[SymbolObservable] = () => patchedObservable
  patchedObservable['@@observable'] = () => patchedObservable
  return from(patchedObservable, queueScheduler)
}
Observable.getState = module => {
  const patchedObservable = extensions.getState(module)
  // It sucks but compatibility between old rx and new rx comes at a price
  patchedObservable[SymbolObservable] = () => patchedObservable
  patchedObservable['@@observable'] = () => patchedObservable
  return from(patchedObservable, queueScheduler)
}

Observable.reduxActionStream = extensions.reduxActionStream
Observable.observeState = module => from(extensions.observeState(module), queueScheduler)

export default store
export {history}
