import React, {useEffect, useState} from 'react'

import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'
import PoppersPopup from './PoppersPopup'

const StyledPopup = styled(PoppersPopup)`
  &&& {
    padding: 5px 8px;
    font-size: 12px;
  }
`

interface StyledDropdownTriggerProps {
  readonly open: boolean
  readonly disabled: boolean
}

const StyledDropdownTrigger = styled.span<StyledDropdownTriggerProps>`
  cursor: pointer;
  color: ${props => (props.open ? colors.primary.rgba : colors.darkLight.rgba)};
  background-color: ${props => (props.open ? colors.backgroundTab.rgba : 'none')};
  display: inline-block;
  padding: 0.3em 0.3em 0.3em 0.6em;
  border-radius: 3px;
  ${props =>
    !props.disabled &&
    `:hover {
    background-color: ${colors.backgroundTab.rgba};
    color: ${colors.primary.rgba};
  }`}
  ${props => props.disabled && `opacity: 0.4;`}
`

interface DropdownToolProps {
  disabled: boolean
  content: string
  icon: string
  options: React.ReactNode
}

const DropdownTool = ({content, icon, options, disabled = false}: DropdownToolProps) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (disabled) setOpen(false)
  }, [disabled])

  return (
    <PoppersPopup
      style={{border: `1px solid ${colors.secondary.rgba}`, padding: 0}}
      basic
      position="bottom right"
      on="click"
      offset={[0, -5]}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disabled={disabled}
      trigger={
        <div>
          <StyledPopup
            position="top left"
            basic
            content={content}
            trigger={
              <div>
                <StyledDropdownTrigger open={open} disabled={disabled}>
                  <Icon className={icon} />
                  <Icon className="fas fa-caret-down" />
                </StyledDropdownTrigger>
              </div>
            }
          />
        </div>
      }
    >
      {options}
    </PoppersPopup>
  )
}

export default DropdownTool
