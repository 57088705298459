import moment from 'moment'

import {DATE_FORMATS} from '../constants'
import BaseSeries from './BaseSeries'

const availableDimensions = {
  date: v => moment.utc(v.date),
  days: v => moment.utc(v.date).format(DATE_FORMATS.days),
  weeks: v => moment.utc(v.date).format(DATE_FORMATS.weeks),
  months: v => moment.utc(v.date).format(DATE_FORMATS.months),
  years: v => moment.utc(v.date).year(),
}

const defaultTimeDimensions = ['date', 'days', 'weeks', 'months', 'years']

class TimeSeries extends BaseSeries {
  createDimensions(timeDimensions) {
    this.availableDimensions =
      timeDimensions && timeDimensions.length ? ['date', ...timeDimensions] : defaultTimeDimensions
    super.createDimensions()
    this.availableDimensions.forEach(dimension => {
      this.cf.dimensions[dimension] = this.cf.data.dimension(availableDimensions[dimension])
    })
  }

  loadTimeDimensions(timeDimensions) {
    Object.keys(this.cf.dimensions).forEach(dimension => {
      if (!timeDimensions.includes(dimension) && defaultTimeDimensions.includes(dimension) && dimension !== 'date')
        delete this.cf.dimensions[dimension]
    })
    timeDimensions.forEach(dimension => {
      if (!this.cf.dimensions[dimension])
        this.cf.dimensions[dimension] = this.cf.data.dimension(availableDimensions[dimension])
    })
  }

  updateFilters(values = {}, lockId = 'default') {
    super.updateFilters(values, lockId)
    if (!this.cf.dimensions.date) return
    const {dateRange = {}} = this.filters[lockId]
    const {from = null, to = null, startDate = null, endDate = null} = dateRange
    const start = from != null ? from : startDate != null ? startDate : null // eslint-disable-line no-nested-ternary
    const end = to != null ? to : endDate != null ? endDate : null // eslint-disable-line no-nested-ternary
    this.filterDimension(
      this.cf.dimensions.date,
      moment.utc(start).isValid() ? start : null,
      moment.utc(end).isValid() ? end : null
    )
  }

  getAvailableGroupings() {
    return [...super.getAvailableGroupings(), ...this.availableDimensions]
  }
}

export default TimeSeries
