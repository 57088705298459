import React from 'react'

import {Row} from 'jsxstyle'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import COUNTRIES from '../../../../config/countries.json'
import colors from '../../colors'
import SeelkElevatedPanel from './SeelkUIKit/SeelkElevatedPanel'

const StyledSeelkElevatedPanel = styled(SeelkElevatedPanel)`
  &&&& {
    ${props => (props.borderColor ? `border-left: 5px solid ${props.borderColor};` : '')}
    overflow: visible;
    overflow-wrap: break-word;
    width: 100%;
    height: auto;
    padding: 20px;
    margin: 10px 0;
    box-shadow: none;
    .arrow.right {
      opacity: 0;
      margin-left: 5px;
    }
    :hover {
      box-shadow: 0em 0em 10px 0 ${colors.primary.alpha(0.25).rgba};
      .country {
        color: ${colors.primary.rgba};
      }
      .arrow.right {
        opacity: 1;
      }
    }
  }
`

const StyledCountryName = styled.span`
  font-size: 1.2em;
  font-weight: 500;
  color: ${colors.primaryDark.rgba};
  margin-left: 10px;
  :hover {
    color: ${colors.primary.rgba};
    text-decoration: underline;
  }
`

interface DashboardRowProps {
  content: React.ReactNode
  country: string
  height?: string
  onClick?: (value: string | null) => void
  value?: string | null
}

function DashboardRow({country, content, height = 'auto', onClick, value = null}: DashboardRowProps) {
  return (
    <Row>
      <StyledSeelkElevatedPanel>
        <Row justifyContent="space-between">
          <Row
            justifyContent="flex-start"
            alignItems="center"
            height={height}
            width="100%"
            props={{onClick: () => (onClick ? onClick(value) : {})}}
            position="relative"
            cursor="pointer"
          >
            <span
              style={{height: 35, width: 50, marginBottom: 5}}
              className={`flag-icon flag-icon-${country.toLowerCase() === 'uk' ? 'gb' : country}`}
            />
            <StyledCountryName className="country">
              {COUNTRIES[country.toUpperCase() as keyof typeof COUNTRIES]}
              {onClick && <Icon className="icon" name="arrow right" />}
            </StyledCountryName>
          </Row>
          <Row>{content}</Row>
        </Row>
      </StyledSeelkElevatedPanel>
    </Row>
  )
}

export default DashboardRow
