import React from 'react'

import {plugins} from 'react-redux-reliever'
import {combineLatest, defer, empty, from, queueScheduler} from 'rxjs'
import {observable as SymbolObservable} from 'rxjs/internal/symbol/observable'
import {delay, map, retryWhen, take} from 'rxjs/operators'

import colors from '../../colors'
import {GOKU_FEEDBACK_FORM_URL} from '../../constants'
import i18n from '../../i18n'
import {prettyErrors} from '../../index'
import requireFetched from './fetching'
import Variable from './variable'

const extensions = plugins.RxRelieverPlugin.extensions()

export {requireFetched}

export const getStore = () => {
  const patchedObservable = extensions.getStore()
  // It sucks but compatibility between old rx and new rx comes at a price
  patchedObservable[SymbolObservable] = () => patchedObservable
  patchedObservable['@@observable'] = () => patchedObservable
  return from(patchedObservable, queueScheduler)
}

export const getState = module => {
  const patchedObservable = extensions.getState(module)
  // It sucks but compatibility between old rx and new rx comes at a price
  patchedObservable[SymbolObservable] = () => patchedObservable
  patchedObservable['@@observable'] = () => patchedObservable
  return from(patchedObservable, queueScheduler)
}

export const dispatch = payload =>
  getStore().pipe(
    map(store => {
      store.dispatch(payload)
      return payload
    })
  )

export const reduxActionStream = extensions.reduxActionStream

export const observeState = module => from(extensions.observeState(module), queueScheduler)

export const mapAsync = factory => {
  return defer(async () => {
    const result = await factory
    return result
  })
}

export const combineToObject = obj =>
  combineLatest(
    Object.keys(obj).map(k => obj[k]),
    (...args) =>
      Object.keys(obj).reduce((parent, key, index) => {
        // eslint-disable-next-line no-param-reassign
        parent[key.replace(/\$$/g, '')] = args[index]
        return parent
      }, {})
  )

export const toast = (message, options = {type: 'error', autoClose: false, closeOnClick: false}) => {
  toast(
    <p>
      <b>{i18n.t('placeholders.errors.anErrorOccured')}</b>
      {prettyErrors(message)}
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={GOKU_FEEDBACK_FORM_URL}
        style={{
          float: 'right',
          color: colors.white.rgba,
          fontWeight: '500',
          paddingRight: 10,
          textDecoration: 'underline',
        }}
      >
        {i18n.t('actions.global.submitBug')}
      </a>
    </p>,
    options
  )
  return empty()
}

export const retryRequest = (req$, attempts) => {
  return req$.pipe(retryWhen(errors => errors.pipe(delay(1000), take(attempts))))
}

export const variable = (value = null) => new Variable(value)
