import React from 'react'

import {Box, Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import {connect} from 'react-redux-reliever'
import {Header, Icon, Loader} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'
import AnimatedLoader from '../components/AnimatedLoader'
import {isRequestingSelector, requestingModuleState} from '../selectors'

const StyledHeader = styled(Header)`
  &&&&&& {
    color: ${colors.dark.rgba};
    margin: 0 !important;
    margin-bottom: 12px !important;
  }
`

class LoaderWrapper extends React.Component {
  static propTypes = {
    big: PropTypes.bool,
    blur: PropTypes.bool,
    children: PropTypes.node,
    container: PropTypes.bool,
    header: PropTypes.string,
    isRequesting: PropTypes.func.isRequired,
    message: PropTypes.string,
    module: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
    multi: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
    proxy: PropTypes.bool,
    render: PropTypes.func,
    requesting: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string])),
    ]),
    loaderSize: PropTypes.string,
  }

  static defaultProps = {
    big: false,
    blur: false,
    children: null,
    container: false,
    header: '',
    loaderSize: 'massive',
    message: '',
    multi: null,
    proxy: false,
    render: null,
    requesting: false,
  }

  isRequesting = arg => {
    let toCheck = null
    if (typeof arg === 'boolean') return arg
    if (typeof arg === 'object') toCheck = Object.keys(arg)
    if (typeof arg === 'string') toCheck = [arg]
    if (Array.isArray(arg)) toCheck = arg
    return toCheck.some(k => {
      if (typeof k === 'string') return this.props.isRequesting(k)
      return !!k
    })
  }

  render() {
    const children = <React.Fragment key="children">{this.props.children}</React.Fragment>

    if (this.props.render) return this.props.render(this.isRequesting(this.props.multi || this.props.requesting))

    if (this.props.proxy)
      return React.cloneElement(children, {
        loading: this.isRequesting(this.props.multi || this.props.requesting),
        disabled: this.isRequesting(this.props.multi || this.props.requesting),
      })

    if (!this.isRequesting(this.props.multi || this.props.requesting))
      return (
        <div style={{display: 'contents', width: 'max-content', height: 'max-content'}} key="wrapper">
          {children}
        </div>
      )

    if (this.props.multi) {
      return [
        <div
          style={{
            height: '100%',
            width: '100%',
            filter: 'blur(2px)',
            opacity: this.props.invisible ? 0 : 1,
          }}
          key="wrapper"
        >
          {children},
        </div>,
        <Col
          display="flex"
          height={this.props.container ? 'calc(100% - 55px)' : '100%'}
          width={this.props.container ? 'calc(100% - 75px)' : '100%'}
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top={this.props.container ? 55 : 0}
          left={this.props.container ? 75 : 0}
          backgroundColor="rgba(255, 255, 255, 0.90)"
          key="loader"
        >
          <Loader inline active size={this.props.loaderSize} style={{marginBottom: 10}} />
          {this.props.header && <StyledHeader>{this.props.header}</StyledHeader>}
          {Object.entries(this.props.multi)
            // eslint-disable-next-line no-unused-vars
            .filter(([requestingPath, message]) => message)
            .map(([requestingPath, message]) => (
              <Row key={requestingPath} marginTop={6} marginBottom={6}>
                <Box>{message}</Box>
                <Box marginLeft="8px">
                  {this.isRequesting(requestingPath) ? (
                    <Loader inline active size="mini" />
                  ) : (
                    <Icon size="small" color="positive" name="check" />
                  )}
                </Box>
              </Row>
            ))}
        </Col>,
      ]
    }

    if (this.props.blur) {
      return [
        <div style={{height: '100%', width: '100%', filter: 'blur(2px)'}} key="wrapper">
          {children}
        </div>,
        <Box
          display="flex"
          height={this.props.container ? 'calc(100% - 55px)' : '100%'}
          width={this.props.container ? 'calc(100% - 75px)' : '100%'}
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top={this.props.container ? 55 : 0}
          left={this.props.container ? 75 : 0}
          backgroundColor="rgba(255, 255, 255, 0.80)"
          key="loader"
        >
          <Loader active size={this.props.loaderSize} />
        </Box>,
      ]
    }

    if (this.props.big)
      return (
        <Box>
          {this.props.message}
          <AnimatedLoader />
        </Box>
      )

    return (
      <Col
        flex={1}
        alignSelf="stretch"
        alignItems="center"
        justifyContent="center"
        marginLeft="8px"
        fontWeight={400}
        color={colors.lightDark}
      >
        <Box marginLeft={4} position="relative" bottom={2} display="inline">
          <Loader inline active size={this.props.loaderSize} />
        </Box>
        {this.props.message && (
          <Box color={colors.dark} marginTop={10}>
            {this.props.message}
          </Box>
        )}
      </Col>
    )
  }
}

export default connect({
  props: (state, ownProps) => ({
    isRequesting: isRequestingSelector(state),
    module: requestingModuleState(state),
    location: state.router.location.pathname,
    ...ownProps,
  }),
})(LoaderWrapper)
