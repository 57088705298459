import React from 'react'
import ReactDOM from 'react-dom'

import {ReplaySubject} from 'rxjs'

import MixinBuilder from '../mixin-builder'

MixinBuilder.prototype.addMonitoring = function withMonitoring() {
  return this.patch('componentDidMount', (instance, fn, ...args) => {
    fn(...args)
    if (instance.startMonitoring) {
      instance.startMonitoring()
    }
  })
}

MixinBuilder.prototype.addUnmountedSubject = function withUnmountedSubject() {
  return this.add(instance => {
    // eslint-disable-next-line no-param-reassign
    instance.isUnmounted$ = new ReplaySubject()
  }).patch('componentWillUnmount', (instance, fn, ...args) => {
    fn(...args)
    instance.isUnmounted$.next(true)
  })
}

React.mixins = (component = React.Component) => new MixinBuilder(component)

React.bindTo =
  (variable, property = 'value') =>
  (_, event) => {
    variable.value = event[property] // eslint-disable-line no-param-reassign
  }
