import React from 'react'

import {Row} from 'jsxstyle'
import {Checkbox, Flag, FlagNameValues} from 'semantic-ui-react'

import colors from '../../../../../colors'
import i18n from '../../../../../i18n'
import VerticalList from './VerticalList'

interface MarketplacesProps {
  data: string[]
  choices: string[]
  onChange: () => void
  onRemove: () => void
  onApply: () => void
  onClose: () => void
  disabled?: boolean
}

const Marketplaces = ({disabled = false, ...props}: MarketplacesProps) => (
  <VerticalList
    {...props}
    renderItem={({data, item, onChange, choices}) => {
      if (!item) {
        return (
          <Row alignItems="center" marginLeft={8}>
            {data.length > 0 &&
              data.map(marketplace => (
                <Flag key={marketplace} name={marketplace.split('_')[1].toLowerCase() as FlagNameValues} />
              ))}
            {data.length === 0 &&
              choices.map(marketplace => (
                <Flag key={marketplace} name={marketplace.split('_')[1].toLowerCase() as FlagNameValues} />
              ))}
          </Row>
        )
      }
      return (
        <Checkbox
          key={item}
          onClick={event => onChange(item)}
          checked={data && data.indexOf(item) !== -1}
          label={{
            children: (
              <p>
                <Flag name={item.split('_')[1].toLowerCase() as FlagNameValues} />
                <span style={{color: colors.primary.rgba}}>
                  {i18n.t(`countries.${item.split('_')[1].toLowerCase()}.name`)}
                </span>
              </p>
            ),
            className: 'checkbox-element-label',
          }}
          className="checkbox-element"
        />
      )
    }}
  />
)

export default Marketplaces
