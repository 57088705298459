export default {
  index: {
    estimatedTotalCosts: 'Estimated total costs (per year)',
    trackingActivity: 'Tracking Activity',
  },
  table: {
    all: 'All',
    products: 'Products',
    keywords: 'Keywords',
    categories: 'Categories',
  },
}
