import React, {useEffect, useState} from 'react'

import {Box, Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import {Checkbox} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../utils/colors'
import {SeelkButton, SeelkRadio} from '../../utils/common-ui'
import i18n from '../../utils/i18n'
import theme from '../../utils/theme'
import {ScrollableGrid} from '../../utils/visualization'

const StyledBox = styled(Box)`
  &&&& {
    margin: 0 10px 0 10px;
  }
`

const StyledToggle = styled(SeelkRadio)`
  &&&&&& {
    label::before {
      background-color: red;
    }
  }
`

const Table = ({areActiveUsers, bulkSetGroups, exportUsers, fetchUsers, orgGroups, orgUsers, updateAreActiveUsers}) => {
  const [users, setUsers] = useState(orgUsers)
  const [usersUpdateList, setUsersUpdateList] = useState({})
  const [formattedData, setFormattedData] = useState([])

  const ToggleCell = ({value, item}) => {
    const toggleValue = value.active ? value.group : null
    const userGroups = users.filter(user => user.id === item.id)[0]?.groups

    const updateUserGroups = (id, groups) => {
      const usersUpdateListTmp = {...usersUpdateList}

      usersUpdateListTmp[id] = groups
      setUsersUpdateList(usersUpdateListTmp)

      setUsers(users.map(user => (user.id === id ? {...user, groups} : user)))
    }

    const handleChange = () => {
      const groupsTmp = userGroups.length !== 0 ? [...userGroups] : []

      if (!toggleValue) {
        groupsTmp.push(value.group)
        updateUserGroups(item.id, groupsTmp)
      } else {
        updateUserGroups(
          item.id,
          groupsTmp.filter(group => group !== value.group)
        )
      }
    }

    return <SeelkRadio toggle option={{value: value.group, label: ''}} value={toggleValue} onChange={handleChange} />
  }

  ToggleCell.propTypes = {
    value: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
  }

  const isToggledAll = group => {
    let count = 0

    users.map(user => {
      if (user.groups.includes(group)) count += 1
      return true
    })
    return count === users.length
  }

  const ToggleAllCell = ({schemeData}) => {
    const {active, value} = schemeData
    const [toggleValue, setToggleValue] = useState(active ? value : null)

    const toggleGroupAllUsers = (group, toggle) => {
      const newUsersUpdateList = {...usersUpdateList}
      const newUsers = users.reduce((newusers, user) => {
        const newGroups = user.groups.length !== 0 ? [...user.groups] : []

        if (toggle) {
          if (!newGroups.includes(group)) {
            newGroups.push(group)
            newUsersUpdateList[user.id] = newGroups
          }
        } else {
          newGroups.splice(
            newGroups.findIndex(g => g === group),
            1
          )
          newUsersUpdateList[user.id] = newGroups
        }

        const newUser = users.find(u => u.id === user.id)
        newusers.push({...newUser, groups: newGroups})

        return newusers
      }, [])

      setUsers(newUsers)
      setUsersUpdateList(newUsersUpdateList)
    }

    const handleChange = () => {
      setToggleValue(toggleValue ? null : value)
      toggleGroupAllUsers(value, toggleValue ? null : value)
    }

    return (
      <StyledBox textAlign="center">
        <StyledToggle toggle option={{value, label: ''}} value={toggleValue} onChange={handleChange} />
      </StyledBox>
    )
  }

  ToggleAllCell.propTypes = {
    schemeData: PropTypes.object.isRequired,
  }

  const TitleCell = ({label}) => {
    return <StyledBox textAlign="center">{label}</StyledBox>
  }

  TitleCell.propTypes = {
    label: PropTypes.string.isRequired,
  }

  const SCHEME = {
    email: {
      canGrow: true,
      key: 'email',
      label: i18n.t('misc.clientPermissions.email'),
      sort: true,
      size: 280,
      props: {
        value: '',
        component: ToggleAllCell,
      },
    },
    firstname: {
      canGrow: true,
      key: 'firstname',
      label: i18n.t('misc.clientPermissions.firstname'),
      sort: true,
      size: 100,
      props: {
        value: '',
      },
    },
    lastname: {
      canGrow: true,
      key: 'lastname',
      label: i18n.t('misc.clientPermissions.lastname'),
      sort: true,
      size: 100,
      props: {
        value: '',
      },
    },
  }

  const groupsColumns = orgGroups.map(group => ({
    canGrow: true,
    key: group.toLowerCase(),
    label: group,
    size: 120,
    component: ToggleCell,
    aggregationComponent: ToggleAllCell,
    props: {
      value: group,
      active: isToggledAll(group),
    },
  }))

  groupsColumns.forEach(group => {
    SCHEME[group.key] = group
    return true
  })

  useEffect(() => {
    setFormattedData(
      users.map(user => {
        const data = {
          key: user.id,
          email: user.email,
          firstname: user.first_name,
          lastname: user.last_name,
          id: user.id,
        }

        orgGroups.forEach(group => {
          data[group.toLowerCase()] = {group, active: !!user.groups.includes(group)}
          return true
        })

        return data
      })
    )
  }, [orgGroups, users])

  useEffect(() => {
    if (users.length !== orgUsers.length) setUsers(orgUsers)
  }, [orgUsers, users])

  const handleClick = () => {
    bulkSetGroups.fetch({users: usersUpdateList}, {}, () => {
      setUsersUpdateList([])
      if (fetchUsers.canFetch()) fetchUsers.fetch()
    })
  }

  return (
    <>
      <Col flex={1} backgroundColor={colors.white.rgba} margin={12} padding={12} className={theme.bordered}>
        <Row justifyContent="space-between" alignItems="center" height={50}>
          <Row alignItems="center">
            <p style={{fontWeight: 400, fontSize: 20, margin: 0, marginRight: 12}}>
              {i18n.t('misc.subModules.usersPermissions')} ({users.length})
            </p>
          </Row>
          <Checkbox
            toggle
            checked={areActiveUsers}
            label={i18n.t('visualization.grouping.active')}
            onChange={() => updateAreActiveUsers(!areActiveUsers)}
          />
        </Row>
        <Col marginTop={12} maxHeight="calc(100vh - 50px - 72px - 3.929em)" height={formattedData.length * 50 + 120}>
          <ScrollableGrid
            aggregationScheme
            aggregationSize={45}
            cellSize={40}
            cellStyle={{textAlign: 'center'}}
            fixedRowCount={1}
            headerSize={40}
            highlight
            items={formattedData}
            fixedColumnCount={3}
            orientation="horizontal"
            overscanColumnCount={0}
            overscanRowCount={10}
            placehold
            scheme={Object.values(SCHEME)}
          />
        </Col>
      </Col>
      <Row justifyContent="center">
        <SeelkButton style={{marginRight: '10px'}} onClick={handleClick} type="action" content="Update groups" />
        <SeelkButton
          onClick={exportUsers}
          type="normal"
          icon="fas fa-file-excel"
          iconPosition="left"
          content={i18n.t('actions.download.export')}
        />
      </Row>
    </>
  )
}

Table.propTypes = {
  areActiveUsers: PropTypes.bool.isRequired,
  bulkSetGroups: PropTypes.object.isRequired,
  exportUsers: PropTypes.func.isRequired,
  fetchUsers: PropTypes.object.isRequired,
  orgGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  orgUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateAreActiveUsers: PropTypes.func.isRequired,
}

export default Table
