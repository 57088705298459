import React, {useRef, useState} from 'react'
import ReactDOM from 'react-dom'

import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'
import OutsideClickHandler from '../../OutsideClickHandler'
import PermissionControl from '../../PermissionControl'

const StyledIcon = styled(Icon)`
  &&& {
    color: ${colors.darkLight.rgba};
    cursor: pointer;
    :hover {
      color: ${colors.primary.rgba};
    }
  }
`

const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  color: ${colors.dark.rgba};
  min-width: 200px;
  height: 40px;
  padding: 5px 12px;
  cursor: pointer;
  align-items: center;
  i {
    color: ${colors.darkLight.rgba};
    margin-right: 8px;
  }
  :hover {
    background-color: ${colors.primaryLightAlpha.rgba};
    color: ${colors.primary.rgba};
    i {
      color: ${colors.primary.rgba};
    }
  }
`

const StyledContent = styled.div`
  background-color: ${colors.white.rgba};
  border-radius: 4px;
  border: 1px solid ${colors.border.rgba};
  overflow: hidden;
`

type Position = {x: number; y: number}

interface PortalProps {
  position: Position
  children: React.ReactNode
  refContent: React.MutableRefObject<HTMLDivElement | null>
  open: boolean
}

type ItemType = {
  id: string
  owner_id: string
}

type ReportOptionType = {
  icon: string
  key: string
  onClick: () => void
  onlyOwner: boolean
  permissions: {[key: string]: string[]}
  text: string
}

type ExtraType = {
  exportOptions: (id: string) => ReportOptionType[]
  userId?: any
}

interface DropdownActionsCellProps {
  extra: ExtraType
  item: ItemType
}

const Portal = ({position, children, refContent, open}: PortalProps) => {
  const posContent = refContent?.current?.getBoundingClientRect() ?? {width: 0, height: 0}
  const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  const top = position.y + 30 + posContent.height > windowHeight ? position.y - 10 - posContent.height : position.y + 20
  const left = position.x - posContent.width + 20
  const style = open ? {top, left} : {top: -1000, left: -1000}
  return ReactDOM.createPortal(<div style={{...style, position: 'absolute', zIndex: 1}}>{children}</div>, document.body)
}

const DropdownActionsCell = ({item, extra, ...rest}: DropdownActionsCellProps) => {
  const refTrigger = useRef<HTMLDivElement>(null)
  const refContent = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState({x: 0, y: 0})
  const {exportOptions, userId} = extra
  const {id, owner_id} = item

  const openAction = () => {
    const posTrigger = refTrigger?.current?.getBoundingClientRect() ?? {x: 0, y: 0}
    setOpen(true)
    setPosition({x: posTrigger.x, y: posTrigger.y})
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div ref={refTrigger}>
        <StyledIcon name="ellipsis horizontal" onClick={openAction} />
      </div>
      <Portal position={position} refContent={refContent} open={open}>
        <div ref={refContent}>
          <StyledContent>
            {exportOptions(id).map(option => {
              let {permissions} = option
              if (option.onlyOwner) {
                permissions = owner_id === userId ? {hideIfOne: ['none']} : {showIfOne: ['dev']}
              }

              return (
                <PermissionControl key={option.key} {...permissions}>
                  <StyledItem onClick={option.onClick}>
                    <i className={option.icon} />
                    <span>{option.text}</span>
                  </StyledItem>
                </PermissionControl>
              )
            })}
          </StyledContent>
        </div>
      </Portal>
    </OutsideClickHandler>
  )
}

export default DropdownActionsCell
