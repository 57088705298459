import crossfilter from 'crossfilter2'
import _ from 'lodash'
import {Observable, ReplaySubject} from 'rxjs'

import Thread from '../thread'

/* eslint-disable */
const REDUCERS = {
  toArray: () => ({
    add: (p, v) => {
      p.set(v, null)
      return p
    },
    init: () => new Map(),
    remove: (p, v) => {
      p.delete(v)
      return p
    },
    map: g => {
      if (g.value) {
        g.value = [...g.value.keys()]
        return g
      }
      return [...g.keys()]
    },
  }),
  avg: getAttr => ({
    add: (p, v) => {
      p.count += 1
      p.sum += getAttr(v)
      return p
    },
    remove: (p, v) => {
      p.count -= 1
      p.sum -= getAttr(v)
      return p
    },
    init: () => ({count: 0, sum: 0}),
    map: g => {
      if (g.value) {
        if (g.value.count === 0) return 0
        g.value = Math.round((g.value.sum / g.value.count) * 100) / 100
        return g
      }
      if (g.count === 0) return 0
      return Math.round((g.sum / g.count) * 100) / 100
    },
  }),
  avgToPercentage: getAttr => ({
    add: (p, v) => {
      p.count += 1
      p.sum += getAttr(v)
      return p
    },
    remove: (p, v) => {
      p.count -= 1
      p.sum -= getAttr(v)
      return p
    },
    init: () => ({count: 0, sum: 0}),
    map: g => {
      if (g.value) {
        if (g.value.count === 0) return 0
        g.value = Math.round((g.value.sum / g.value.count) * 100)
        return g
      }
      if (g.count === 0) return 0
      return Math.round((g.sum / g.count) * 100)
    },
  }),
  strictAvg: getAttr => ({
    add: (p, v) => {
      const value = getAttr(v)
      if (value === null) return p
      p.count += 1
      p.sum += value
      return p
    },
    remove: (p, v) => {
      const value = getAttr(v)
      if (value === null) return p
      p.count -= 1
      p.sum -= value
      return p
    },
    init: () => ({count: 0, sum: 0}),
    map: g => {
      if (g.value) {
        if (g.value.count === 0) return 0
        g.value = Math.round((g.value.sum / g.value.count) * 100) / 100
        return g
      }
      if (g.count === 0) return 0
      return Math.round((g.sum / g.count) * 100) / 100
    },
  }),
  sum: getAttr => ({
    add: (p, v) => p + getAttr(v),
    remove: (p, v) => p - getAttr(v),
    init: () => 0,
    map: g => {
      if (g.value) {
        g.value = Math.round(g.value * 100) / 100
        return g
      }
      return Math.round(g * 100) / 100
    },
  }),
  count: getAttr => ({
    add: (p, v) => p + 1,
    remove: (p, v) => p - 1,
    init: () => 0,
    map: g => {
      if (g.value) {
        g.value = Math.round(g.value * 100) / 100
        return g
      }
      return Math.round(g * 100) / 100
    },
  }),
}
/* eslint-enable */

class BaseSeries {
  constructor(args = {}) {
    this.data = args.data || []
    this.filters = {}
    this.lockId = 'default'
    this.initialized$ = new ReplaySubject()
    this.initialized = false
    this.initialized$.subscribe(value => {
      this.initialized = value
    })
  }

  init({async = false} = {}) {
    this.cf = {
      data: crossfilter([]),
      dimensions: {},
    }
    this.createDimensions()
    if (async) {
      this.addDataAsync()
    } else {
      _(this.data)
        .chunk(500)
        .forEach(chunk => this.cf.data.add(chunk))
      this.updateFilters()
      this.initialized$.next(true)
    }
    return this
  }

  addDataAsync() {
    const chunkify = ({data, chunkSize}) => {
      const chunks = []
      let currentChunk = []
      data.forEach(item => {
        currentChunk.push(item)
        if (currentChunk.length === chunkSize) {
          chunks.push(currentChunk)
          currentChunk = []
        }
      })
      if (currentChunk.length) {
        chunks.push(currentChunk)
      }
      return chunks
    }

    const timeout = action => {
      const time = 15
      return new Promise(fulfill => {
        setTimeout(() => {
          fulfill(action())
        }, time)
      })
    }

    Thread.use(chunkify)
      .run({data: this.data, chunkSize: 10})
      .flatMap(chunks =>
        Observable.from(chunks)
          .mapAsync(async chunk => timeout(() => this.cf.data.add(chunk)))
          .toArray()
      )
      .asap()
      .subscribe(() => {
        this.updateFilters()
        this.initialized$.next(true)
      })
  }

  reset(data, lockId = 'default') {
    this.updateFilters({}, lockId)
    this.cf.data.remove()
    this.data = data
    _(this.data)
      .chunk(500)
      .forEach(chunk => this.cf.data.add(chunk))
  }

  createDimensions() {}

  // eslint-disable-next-line no-unused-vars
  remodelGroup(group, unit, overall, reduce, includeZeros) {
    group.dispose() // fdp helping us to empty the group so the values are reset and there's no crashes
    const reducer = REDUCERS[reduce](this.getUnitAttribute(unit))
    if (overall) return reducer.map(group.reduce(reducer.add, reducer.remove, reducer.init).value())
    let group_ = group.reduce(reducer.add, reducer.remove, reducer.init).all()
    if (reducer.map)
      group_ = group_.map(reducer.map).filter(m => (includeZeros ? m.value !== null && m.value !== undefined : m.value))
    return group_.reduce((res, m) => {
      res[m.key] = m.value
      return res
    }, {})
  }

  getData({group, unit, overall = false, reduce = 'sum', lockId = 'default', includeZeros = false} = {}) {
    if (this.lockId !== lockId) this.updateFilters(this.filters[lockId], lockId)
    let groupDimension
    if (!overall) groupDimension = this.cf.dimensions[group].group()
    else groupDimension = this.cf.dimensions[group].groupAll()
    return this.remodelGroup(groupDimension, unit, overall, reduce, includeZeros)
  }

  // Use with only one argument specified : equality filter or include filter (if v is array)
  // Use with only one argument being an array : or equality filter
  // Use with only one argument being a function : function is the filter
  // Use with two arguments specified : range filter
  // To use range with no "to" value, pass null.
  filterDimension(dim, eqOrFrom, to) {
    if (
      (eqOrFrom === null && to === null) ||
      (eqOrFrom === null && to === undefined) ||
      (Array.isArray(eqOrFrom) && eqOrFrom.length === 0)
    ) {
      dim.filterAll()
    } else if (eqOrFrom === null) {
      dim.filter(v => v <= to)
    } else if (to === undefined && typeof eqOrFrom === 'function') {
      dim.filter(eqOrFrom)
    } else if (to === undefined && Array.isArray(eqOrFrom)) {
      dim.filter(v => eqOrFrom.indexOf(v) !== -1)
    } else if (to === undefined) {
      dim.filter(v => (Array.isArray(v) ? v.includes(eqOrFrom) : v === eqOrFrom))
    } else if (to === null) {
      dim.filter(v => v >= eqOrFrom)
    } else {
      dim.filter(v => v >= eqOrFrom && v <= to)
    }
  }

  updateFilters(values = {}, lockId = 'default') {
    this.lockId = lockId
    this.filters[lockId] = values
  }

  getOrderedData(dimension, asc = true, value = Infinity, lockId = 'default') {
    if (this.lockId !== lockId) this.updateFilters(this.filters[lockId], lockId)
    if (asc) return this.cf.dimensions[dimension].top(value)
    return this.cf.dimensions[dimension].bottom(value)
  }

  getAvailableUnits() {
    return {
      Quantity: 'nb',
    }
  }

  getUnitAttribute(unit) {
    // eslint-disable-next-line no-unused-vars
    return obj => {
      switch (unit) {
        default:
          return 1
      }
    }
  }

  getAvailableGroupings() {
    return []
  }
}

export default BaseSeries
