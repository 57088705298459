import React from 'react'

import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'

const StyledIcon = styled(Icon)`
  margin: ${props => (props.detailed ? '0 auto' : '0')};
`

type ExtraType = {
  boolean: boolean
  detailed: boolean
}

interface TickProps {
  value: boolean | null
  placehold?: boolean
  extra?: ExtraType
}

const Tick = ({value, extra, placehold = false}: TickProps) => {
  if (placehold) return <div style={{width: 10, height: 10, borderRadius: 10, background: colors.lightShadow.rgba}} />
  if (value) return <StyledIcon detailed={extra?.detailed} name="check circle" style={{color: colors.positive}} />
  return (
    <StyledIcon
      name={`${value == null && !extra?.boolean ? 'minus' : 'remove'} circle`}
      style={{color: value == null && !extra?.boolean ? colors.light.rgba : colors.negative.rgba}}
    />
  )
}

export default Tick
