import React from 'react'

import {Row} from 'jsxstyle'
import styled from 'styled-components'

import SeelkElevatedPanel from '../../SeelkElevatedPanel'
import SeelkRadio from '../../SeelkRadio'

const StyledRow = styled(Row)`
  align-items: ${props => props.theme.radioPanel.rows.alignItems};
  background-color: ${props => props.theme.radioPanel.rows.backgroundColor};

  /* This property is necessary to allow the row highlight not to break the border-radius of the background */
  border-radius: ${props => {
    if (props.position === 0) {
      return props.theme.radioPanel.rows.firstRowBorderRadius
    } else if (props.position === -1) {
      return props.theme.radioPanel.rows.lastRowBorderRadius
    } else {
      return props.theme.radioPanel.rows.borderRadius
    }
  }};

  cursor: ${props => props.theme.radioPanel.rows.cursor};
  height: ${props => props.theme.radioPanel.rows.height};
  padding-left: ${props => props.theme.radioPanel.rows.paddingLeft};
  :hover {
    background-color: ${props => props.theme.radioPanel.rows.hoverBackgroundColor};
  }
  /* This one is necessary to apply the hover style of the Radio on the hover of the row */
  &&&&&&:hover > div > {
    label {
      color: ${props => props.theme.radio.label.hoverColor};
      text-decoration: ${props => props.theme.radio.label.hoverTextDecoration};
    }
    label::before {
      border-color: ${props => props.theme.radio.label.beforeHoverBorderColor};
    }
  }
`

interface SeelkRadioPanelProps {
  onChange: (value?: string | number) => void
  options: {
    label: string
    value: number | string
  }[]
  value?: number | string
}

const SeelkRadioPanel = ({onChange, options, value: selectedValue}: SeelkRadioPanelProps) => {
  const handleClick = (value: string | number) => {
    if (selectedValue !== value) onChange(value)
  }

  return (
    <SeelkElevatedPanel>
      {options.map((option, index) => (
        <StyledRow
          props={{onClick: () => handleClick(option.value)}}
          key={option.value}
          width={100}
          position={(() => {
            if (options.length - 1 === index) return -1
            return index
          })()}
        >
          <SeelkRadio option={option} value={selectedValue} onChange={onChange} />
        </StyledRow>
      ))}
    </SeelkElevatedPanel>
  )
}

export default SeelkRadioPanel
