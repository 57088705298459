import React from 'react'

import styled from 'styled-components'

import colors from '../../../colors'
import {PoppersPopup, SortIcon} from '../../../common-ui'

const StyledHeader = styled.div<{ellipsis?: boolean}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  width: 100%;
`

const StyledName = styled.span<{ellipsis?: boolean}>`
  ${props => props.ellipsis && 'overflow: hidden; white-space: nowrap; text-overflow: ellipsis;'}
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`

const StyleDetails = styled.span<{ellipsis?: boolean}>`
  ${props => props.ellipsis && 'overflow: hidden; white-space: nowrap; text-overflow: ellipsis;'}
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: ${colors.darkLight.rgba};
`

interface MetricHeaderProps {
  sortActive: boolean
  sortDirection: 'asc' | 'desc'
  sort?: ((dir: 'asc' | 'desc') => void) | null
  name: string
  details: string
}

const MetricHeader = ({name, details, sort, sortDirection, sortActive}: MetricHeaderProps) => {
  return (
    <PoppersPopup
      content={
        <StyledHeader>
          <StyledName>{name}</StyledName>
          <StyleDetails>{details}</StyleDetails>
        </StyledHeader>
      }
      trigger={
        <StyledHeader onClick={() => sort && sort(sortDirection === 'asc' ? 'desc' : 'asc')}>
          <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
            <StyledName ellipsis>{name}</StyledName>
            {sort && <SortIcon onSort={sort} status={sortActive ? sortDirection : undefined} />}
          </div>
          <StyleDetails ellipsis>{details}</StyleDetails>
        </StyledHeader>
      }
    />
  )
}

export default MetricHeader
