import React from 'react'

import {Col, Row} from 'jsxstyle'
import styled from 'styled-components'

import {fromStringToMoment} from '../..'
import colors from '../../colors'
import {PoppersPopup} from '../../common-ui'
import {DATE_FORMATS} from '../../constants'
import i18n from '../../i18n'

const StyledTitle = styled.span`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
`

const StyledMetricName = styled.span`
  font-size: 12px;
  color: ${colors.darkLight.rgba};
`

const StyledMetricValue = styled.span`
  font-size: 12px;
  font-weight: 500;
`

type ItemType = {
  [key: string]: any
}

type ExtraType = {
  date: string
  period: string
  priceStatus: string
}

type DisplayType = {props: {color: string; name: string; as: string}} | string

interface FillableValueProps {
  extra: ExtraType
  item: ItemType
  value: {value: string | number; display: DisplayType}
}

const FillableValue = (props: FillableValueProps) => {
  const backgroundColor = colors.lightDark.alpha(0.5).rgba

  const {value} = props

  const {date, period} = props.extra

  const {
    bbActive,
    bbOwnership,
    bbPricing,
    hasStock,
    ppm,
    netPPM,
    metricValue,
    metricTitle,
    nbSellers,
    vendorPrice,
  } = props.item[date]

  const placeholder =
    typeof value.display !== 'string' && value.display?.props?.name === 'ban'
      ? i18n.t('placeholders.errors.buyBoxData')
      : null

  return (
    <PoppersPopup
      basic
      hoverable
      flowing
      content={
        placeholder ?? (
          <Col width={200}>
            <StyledTitle>
              {fromStringToMoment(date)
                .format(`${DATE_FORMATS[`${period}WoYears` as keyof typeof DATE_FORMATS]} - YYYY`)
                .toUpperCase()}
            </StyledTitle>
            {[
              {mValue: bbPricing, mTitle: 'avgBBPrice'},
              {mValue: vendorPrice, mTitle: 'vendorPrice'},
              {mValue: bbOwnership, mTitle: 'avgBBOwnership'},
              {mValue: bbActive, mTitle: 'avgBBActive'},
              {mValue: hasStock, mTitle: 'avgHasStock'},
              {mValue: ppm, mTitle: 'ppm'},
              {mValue: netPPM, mTitle: 'netPpm'},
              {mValue: nbSellers, mTitle: 'avgNbSellers'},
              {mValue: metricValue, mTitle: metricTitle},
            ].map(({mValue, mTitle}) => (
              <Row justifyContent="space-between" key={mTitle}>
                <StyledMetricName>{i18n.t(`pricing.index.${mTitle}`)}</StyledMetricName>
                <StyledMetricValue>{mValue}</StyledMetricValue>
              </Row>
            ))}
          </Col>
        )
      }
      trigger={
        <div
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor,
          }}
        >
          {value.display}
        </div>
      }
    />
  )
}

export default FillableValue
