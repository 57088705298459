import moment from 'moment'

import i18n from './i18n'

export const AMAZON_ID = 'amazon'

export const GOKU_FEEDBACK_FORM_URL = 'https://seelk-studio.typeform.com/to/nKFmSO'

export const BOO_FEEDBACK_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSe-SbhqVH8GXum0HARAnyFbjTAUW3tL_cSrNHqI4aWTHAeSUg/viewform'

export const SEELK_EMAIL = 'dev@seelk.co'

export const AMAZON_MARKETPLACES = [
  {name: 'amazon_ae', domain: 'amazon.ae', used: true, code: 'AE', inEurope: false},
  {name: 'amazon_au', domain: 'amazon.com.au', used: true, code: 'AU', inEurope: false},
  {name: 'amazon_br', domain: 'amazon.com.br', used: true, code: 'BR', inEurope: false},
  {name: 'amazon_ca', domain: 'amazon.ca', used: true, code: 'CA', inEurope: false},
  {name: 'amazon_cn', domain: 'amazon.cn', used: true, code: 'CN', inEurope: false},
  {name: 'amazon_de', domain: 'amazon.de', used: true, code: 'DE', inEurope: true},
  {name: 'amazon_es', domain: 'amazon.es', used: true, code: 'ES', inEurope: true},
  {name: 'amazon_fr', domain: 'amazon.fr', used: true, code: 'FR', inEurope: true},
  {name: 'amazon_in', domain: 'amazon.in', used: true, code: 'IN', inEurope: false},
  {name: 'amazon_it', domain: 'amazon.it', used: true, code: 'IT', inEurope: true},
  {name: 'amazon_jp', domain: 'amazon.co.jp', used: true, code: 'JP', inEurope: false},
  {name: 'amazon_mx', domain: 'amazon.com.mx', used: true, code: 'MX', inEurope: false},
  {name: 'amazon_nl', domain: 'amazon.nl', used: true, code: 'NL', inEurope: true},
  {name: 'amazon_pl', domain: 'amazon.pl', used: true, code: 'PL', inEurope: true},
  {name: 'amazon_sa', domain: 'amazon.sa', used: true, code: 'SA', inEurope: false},
  {name: 'amazon_se', domain: 'amazon.se', used: true, code: 'SE', inEurope: true},
  {name: 'amazon_tr', domain: 'amazon.com.tr', used: true, code: 'TR', inEurope: true},
  {name: 'amazon_uk', domain: 'amazon.co.uk', used: true, code: 'GB', inEurope: true},
  {name: 'amazon_us', domain: 'amazon.com', used: true, code: 'US', inEurope: false},
]

export const AVAILABLE_AMZ_MARKETPLACES = AMAZON_MARKETPLACES.filter(v => v.used).map(v => v.name)
export const AVAILABLE_AMZ_COUNTRIES = AVAILABLE_AMZ_MARKETPLACES.map(v => v.split('_')[1])
export const AVAILABLE_AMZ_DOMAINS = AMAZON_MARKETPLACES.filter(v => v.used).map(v => v.domain)
export const MARKETPLACE_REGEXP = /^(?:amazon_)?(\w{2})$/i

export const TRUTHY = ['true', '1', 't', 'y', 'yes']

export const AVAILABLE_CURRENCIES = [
  {
    key: 'EUR',
    text: '€ EUR',
    value: 'EUR',
    flag: 'eu',
    marketplaces: ['de', 'es', 'fr', 'it', 'nl'],
  },
  {
    key: 'GBP',
    text: '£ GBP',
    value: 'GBP',
    flag: 'gb',
    marketplaces: ['uk'],
  },
  {
    key: 'USD',
    text: '$ USD',
    value: 'USD',
    flag: 'us',
    marketplaces: ['us'],
  },
  {
    key: 'CAD',
    text: '$ CAD',
    value: 'CAD',
    flag: 'ca',
    marketplaces: ['ca'],
  },
  {
    key: 'AUD',
    text: '$ AUD',
    value: 'AUD',
    flag: 'au',
    marketplaces: ['au'],
  },
  {
    key: 'BRL',
    text: 'R$ BRL',
    value: 'BRL',
    flag: 'br',
    marketplaces: ['br'],
  },
  {
    key: 'MXN',
    text: '$ MXN',
    value: 'MXN',
    flag: 'mx',
    marketplaces: ['mx'],
  },
  {
    key: 'AED',
    text: 'د.إ AED',
    value: 'AED',
    flag: 'ae',
    marketplaces: ['ae'],
  },
  {
    key: 'JPY',
    text: '¥ JPY',
    value: 'JPY',
    flag: 'jp',
    marketplaces: ['jp'],
  },
  {
    key: 'TRY',
    text: '₺ TRY',
    value: 'TRY',
    flag: 'tr',
    marketplaces: ['tr'],
  },
  {
    key: 'INR',
    text: '₹ INR',
    value: 'INR',
    flag: 'in',
    marketplaces: ['in'],
  },
  {
    key: 'SGD',
    text: '$ SGD',
    value: 'SGD',
    flag: 'sg',
    marketplaces: ['sg'],
  },
  {
    key: 'SAR',
    text: 'ر.س SAR',
    value: 'SAR',
    flag: 'sa',
    marketplaces: ['sa'],
  },
  {
    key: 'SEK',
    text: 'kr SEK',
    value: 'SEK',
    flag: 'se',
    marketplaces: ['se'],
  },
  {
    key: 'PLN',
    text: 'zł PLN',
    value: 'PLN',
    flag: 'pl',
    marketplaces: ['pl'],
  },
]

export const DATE_FORMATS = {
  days: 'YYYY/MM/DD',
  weeks: 'gggg-[w]ww',
  months: 'YYYY/MM',
  years: 'YYYY',
  daysWoYears: 'MM/DD',
  weeksWoYears: '[w]ww',
  monthsWoYears: 'MMM',
} as const

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY'

export const DEFAULT_CURRENCY = 'EUR'

export const DEFAULT_IMG = 'http://static.leboncoin.fr/img/no-picture.png'

export const ASIN_REGEXP = /^[A-Z0-9]{10}$/

export const BINARY_MASKS = {
  ACTIVE: 0b0001,
  ADD_ON: 0b0010,
  PANTRY: 0b0100,
  PRIME_ONLY: 0b1000,
  VENDOR_OFFER: 0b00010000,
  BUY_BOX_OWNER: 0b00100000,
  INVALID_PRODUCT: 0b01000000,
  HAS_A_PLUS: 0b10000000,
  HAS_STOCK: 0b000100000000,
}

export const SCRAPING_PRICE = 0.001

export const SCRAPING_PRICES = {
  categories: 0.0017992516384902,
  keywords: 0.0023013289968,
  products: 0.0027081955704,
}

export const SECONDS_IN_YEAR = 31556952

export const AVAILABLE_SCRAPING_FREQUENCIES = {
  HOURLY: 3600,
  FOUR_HOURS: 14400,
  DAILY: 86400,
  TWO_DAYS: 172800,
  WEEKLY: 604800,
  MONTHLY: 2592000,
  ONCE: 86400000000,
  NEVER: 'null',
}

export const SCRAPING_FREQUENCY_VALUES = {
  3600: 'HOURLY',
  14400: 'FOUR_HOURS',
  86400: 'DAILY',
  172800: 'TWO_DAYS',
  604800: 'WEEKLY',
  2592000: 'MONTHLY',
  86400000000: 'ONCE',
  null: 'NEVER',
}

export const AVAILABLE_SECTIONS = ['MAIN', 'EXTR', 'DETA', 'COMP']

export const AVAILABLE_STATUS = ['active', 'archived', 'processing', 'notFound']

export const SECTIONS_DATA = {
  MAIN: {
    offersData: true,
    // scrapingsData: true,
  },
  EXTR: {
    extraOffersData: true,
    // extraScrapingsData: true,
  },
  DETA: {
    detachedOffersData: true,
    // detachedScrapingsData: true,
  },
  COMP: {
    competitorsOffersData: true,
    // competitorsScrapingsData: true,
  },
}

export const OPERATORS = {
  eq: {key: 'eq', icon: 'far fa-equals'},
  lt: {key: 'lt', icon: 'far fa-less-than'},
  gt: {key: 'gt', icon: 'far fa-greater-than'},
  lte: {key: 'lte', icon: 'far fa-less-than-equal'},
  gte: {key: 'gte', icon: 'far fa-greater-than-equal'},
  bw: {key: 'bw', icon: 'fas fa-arrows-h'},
}

export const DIMENSION_TYPE = {
  AD_GROUP: 'AD_GROUP',
  AD_GROUP_ATTRIBUTES: 'AD_GROUP_ATTRIBUTES',
  AMAZON: 'AMAZON',
  ASIN: 'ASIN',
  CAMPAIGN: 'CAMPAIGN',
  CAMPAIGN_ATTRIBUTES: 'CAMPAIGN_ATTRIBUTES',
  CAMPAIGN_TYPES: 'CAMPAIGN_TYPES',
  KEYWORD: 'KEYWORD',
  MARKETPLACE: 'MARKETPLACE',
  OFFER: 'OFFER',
  OFFER_ATTRIBUTES: 'OFFER_ATTRIBUTES',
  PROFILE: 'PROFILE',
  PROFILE_ATTRIBUTES: 'PROFILE_ATTRIBUTES',
  VENDOR: 'VENDOR',
  ADVERTISER_COUNTRY: 'ADVERTISER_COUNTRY',
  ADVERTISER: 'ADVERTISER',
  ADVERTISER_ATTRIBUTES: 'ADVERTISER_ATTRIBUTES',
  CREATIVE: 'CREATIVE',
  CREATIVE_ATTRIBUTES: 'CREATIVE_ATTRIBUTES',
  CREATIVE_SIZE: 'CREATIVE_SIZE',
  CREATIVE_TYPE: 'CREATIVE_TYPE',
  DSP_PROFILE: 'DSP_PROFILE',
  LINE_ITEM: 'LINE_ITEM',
  LINE_ITEM_ATTRIBUTES: 'LINE_ITEM_ATTRIBUTES',
  ORDER: 'ORDER',
  ORDER_ATTRIBUTES: 'ORDER_ATTRIBUTES',
  SEARCH_TERM: 'SEARCH_TERM',
  SELLING_PARTNER_ID: 'SELLING_PARTNER_ID',
} as const

export const DIMENSION_OPTIONS = {
  [DIMENSION_TYPE.MARKETPLACE]: {
    key: DIMENSION_TYPE.MARKETPLACE,
    text: i18n.t(`dashboards.dimensions.${DIMENSION_TYPE.MARKETPLACE}`),
    value: DIMENSION_TYPE.MARKETPLACE,
    icon: 'far fa-globe-stand',
  },
  [DIMENSION_TYPE.OFFER]: {
    key: DIMENSION_TYPE.OFFER,
    text: i18n.t(`dashboards.dimensions.${DIMENSION_TYPE.OFFER}`),
    value: DIMENSION_TYPE.OFFER,
    icon: 'fas fa-shopping-cart',
  },
  // [DIMENSION_TYPE.PROFILE]: {
  //   key: DIMENSION_TYPE.PROFILE,
  //   text: i18n.t(`dashboards.dimensions.${DIMENSION_TYPE.PROFILE}`),
  //   value: DIMENSION_TYPE.PROFILE,
  //   icon: 'fas fa-user',
  // },
  [DIMENSION_TYPE.CAMPAIGN]: {
    key: DIMENSION_TYPE.CAMPAIGN,
    text: i18n.t(`dashboards.dimensions.${DIMENSION_TYPE.CAMPAIGN}`),
    value: DIMENSION_TYPE.CAMPAIGN,
    icon: 'fas fa-bullhorn',
  },
  [DIMENSION_TYPE.AD_GROUP]: {
    key: DIMENSION_TYPE.AD_GROUP,
    text: i18n.t(`dashboards.dimensions.${DIMENSION_TYPE.AD_GROUP}`),
    value: DIMENSION_TYPE.AD_GROUP,
    icon: 'fas fa-ad',
  },
  [DIMENSION_TYPE.ADVERTISER]: {
    key: DIMENSION_TYPE.ADVERTISER,
    text: i18n.t(`dashboards.dimensions.${DIMENSION_TYPE.ADVERTISER}`),
    value: DIMENSION_TYPE.ADVERTISER,
    icon: 'fas fa-bullhorn',
  },
  [DIMENSION_TYPE.ORDER]: {
    key: DIMENSION_TYPE.ORDER,
    text: i18n.t(`dashboards.dimensions.${DIMENSION_TYPE.ORDER}`),
    value: DIMENSION_TYPE.ORDER,
    icon: 'fas fa-bullhorn',
  },
  [DIMENSION_TYPE.LINE_ITEM]: {
    key: DIMENSION_TYPE.LINE_ITEM,
    text: i18n.t(`dashboards.dimensions.${DIMENSION_TYPE.LINE_ITEM}`),
    value: DIMENSION_TYPE.LINE_ITEM,
    icon: 'fas fa-bullhorn',
  },
  [DIMENSION_TYPE.CREATIVE]: {
    key: DIMENSION_TYPE.CREATIVE,
    text: i18n.t(`dashboards.dimensions.${DIMENSION_TYPE.CREATIVE}`),
    value: DIMENSION_TYPE.CREATIVE,
    icon: 'fas fa-bullhorn',
  },
  [DIMENSION_TYPE.VENDOR]: {
    key: DIMENSION_TYPE.VENDOR,
    text: i18n.t(`dashboards.dimensions.${DIMENSION_TYPE.VENDOR}`),
    value: DIMENSION_TYPE.VENDOR,
    icon: 'fas fa-inventory',
  },
  [DIMENSION_TYPE.SEARCH_TERM]: {
    key: DIMENSION_TYPE.SEARCH_TERM,
    text: i18n.t(`dashboards.dimensions.${DIMENSION_TYPE.SEARCH_TERM}`),
    value: DIMENSION_TYPE.SEARCH_TERM,
    icon: 'fas fa-search',
  },
}

export const AVAILABLE_SCHEDULE_WEEKLY_DELIVERY_DATE = new Array(7).fill(0).map((elem, index) => ({
  key: (elem + index).toString(),
  text: moment()
    .day(elem + index)
    .format('dddd'),
  value: (elem + index).toString(),
}))

export const AVAILABLE_SCHEDULE_MONTHLY_DELIVERY_DATE = [0, 14].map(numberOfDays => ({
  key: (numberOfDays + 1).toString(),
  text: moment()
    .startOf('month')
    .add(numberOfDays, 'days')
    .format('Do'),
  value: (numberOfDays + 1).toString(),
}))

const DSP_FILTERS = [
  'marketplaces',
  'advertiserCountries',
  'advertiserCustomAttributes',
  'advertiserNames',
  'advertiserTags',
  'creativeCustomAttributes',
  'creativeNames',
  'creativeSizes',
  'creativeTags',
  'creativeTypes',
  'dspProfiles',
  'lineItemCustomAttributes',
  'lineItemNames',
  'lineItemTags',
  'orderCustomAttributes',
  'orderNames',
  'orderTags',
]

export const DIMENSION_FILTERS = {
  ASIN: ['asins', 'marketplaces'],
  OFFER: [
    'offerTags',
    'offerCustomAttributes',
    'asins',
    'adGroups',
    'adGroupCustomAttributes',
    'adGroupTags',
    'campaigns',
    'campaignCustomAttributes',
    'campaignStates',
    'campaignTags',
    'campaignTypes',
    'profiles',
    'marketplaces',
    'vendors',
    'offerScrapingFrequencies',
    'offerSections',
    'offerStatus',
  ],
  OFFER_ATTRIBUTES: [
    'offerTags',
    'offerCustomAttributes',
    'asins',
    'adGroups',
    'adGroupCustomAttributes',
    'adGroupTags',
    'campaigns',
    'campaignCustomAttributes',
    'campaignStates',
    'campaignTags',
    'campaignTypes',
    'profiles',
    'marketplaces',
    'vendors',
    'offerScrapingFrequencies',
    'offerSections',
    'offerStatus',
  ],
  MARKETPLACE: ['marketplaces'],
  CAMPAIGN: [
    'campaigns',
    'campaignCustomAttributes',
    'campaignStates',
    'campaignTags',
    'campaignTypes',
    'profiles',
    'marketplaces',
  ],
  CAMPAIGN_ATTRIBUTES: [
    'campaigns',
    'campaignCustomAttributes',
    'campaignStates',
    'campaignTags',
    'campaignTypes',
    'profiles',
    'marketplaces',
  ],
  AD_GROUP: [
    'adGroups',
    'campaigns',
    'campaignCustomAttributes',
    'campaignStates',
    'campaignTags',
    'campaignTypes',
    'profiles',
    'marketplaces',
  ],
  AD_GROUP_ATTRIBUTES: [
    'adGroups',
    'adGroupCustomAttributes',
    'adGroupTags',
    'campaigns',
    'campaignCustomAttributes',
    'campaignStates',
    'campaignTags',
    'campaignTypes',
    'profiles',
    'marketplaces',
  ],
  CAMPAIGN_TYPES: [
    'adGroups',
    'adGroupCustomAttributes',
    'adGroupTags',
    'campaigns',
    'campaignCustomAttributes',
    'campaignStates',
    'campaignTags',
    'campaignTypes',
    'profiles',
    'marketplaces',
  ],
  PROFILE: ['marketplaces', 'profiles'],
  ADVERTISER_COUNTRY: [...DSP_FILTERS],
  ADVERTISER: [...DSP_FILTERS],
  CREATIVE: [...DSP_FILTERS],
  CREATIVE_SIZE: [...DSP_FILTERS],
  CREATIVE_TYPE: [...DSP_FILTERS],
  DSP_PROFILE: [...DSP_FILTERS],
  LINE_ITEM: [...DSP_FILTERS],
  ORDER: [...DSP_FILTERS],
  VENDOR: ['marketplaces', 'vendors'],
  CAMPAIGN_SETTINGS: ['campaigns', 'campaignCustomAttributes', 'campaignTags', 'campaignTypes', 'profiles'],
  AD_GROUP_SETTINGS: ['adGroups', 'adGroupCustomAttributes', 'adGroupTags', 'campaigns', 'campaignTypes', 'profiles'],
  ADVERTISER_SETTINGS: ['advertiserNames', 'advertiserCustomAttributes', 'advertiserTags'],
  ORDER_SETTINGS: ['advertiserNames', 'orderNames', 'orderCustomAttributes', 'orderTags'],
  LINE_ITEM_SETTINGS: ['advertiserNames', 'orderNames', 'lineItemNames', 'lineItemCustomAttributes', 'lineItemTags'],
  CREATIVE_SETTINGS: ['advertiserNames', 'creativeNames', 'creativeCustomAttributes', 'creativeTags'],
  SEARCH_TERM: ['searchTerms', 'departments'],
  SELLING_PARTNER_ID: ['amazonAccounts'],
}

export const PANEL_COLORS = [
  '#D51C00',
  '#E67C73',
  '#F6BF27',
  '#33B679',
  '#0E8042',
  '#329BE5',
  '#3F51B5',
  '#7986CB',
  '#8D24A9',
  '#616161',
]

export const TAG_COLORS = [
  '#004680',
  '#FA7268',
  '#654EA3',
  '#83b14d',
  '#964F4C',
  '#b669a3',
  '#059d7e',
  '#e04a27',
  '#dd5a7b',
  '#4dbcb2',
  '#EFC050',
]

export const MARKETPLACE_COLORS = {
  amazon_ae: '#84817A',
  amazon_au: '#C0392B',
  amazon_br: '#778BEB',
  amazon_ca: '#27AE60',
  amazon_cn: '#F1C40F',
  amazon_de: '#D35400',
  amazon_es: '#FFB142',
  amazon_fr: '#3498DB',
  amazon_in: '#95A5A6',
  amazon_it: '#CF6A87',
  amazon_jp: '#5F27CD',
  amazon_mx: '#8E44AD',
  amazon_nl: '#2ECC71',
  amazon_pl: '#DC143C',
  amazon_sa: '#006C35',
  amazon_se: '#004B87',
  amazon_tr: '#B33939',
  amazon_uk: '#574B90',
  amazon_us: '#2980B9',
}

export const CURRENCIES = {
  AD: 'EUR',
  AE: 'AED',
  AF: 'AFN',
  AG: 'XCD',
  AI: 'XCD',
  AL: 'ALL',
  AM: 'AMD',
  AO: 'AOA',
  AQ: '',
  AR: 'ARS',
  AS: 'USD',
  AT: 'EUR',
  AU: 'AUD',
  AW: 'AWG',
  AX: 'EUR',
  AZ: 'AZN',
  BA: 'BAM',
  BB: 'BBD',
  BD: 'BDT',
  BE: 'EUR',
  BF: 'XOF',
  BG: 'BGN',
  BH: 'BHD',
  BI: 'BIF',
  BJ: 'XOF',
  BL: 'EUR',
  BM: 'BMD',
  BN: 'BND',
  BO: 'BOB',
  BQ: 'USD',
  BR: 'BRL',
  BS: 'BSD',
  BT: 'BTN',
  BV: 'NOK',
  BW: 'BWP',
  BY: 'BYR',
  BZ: 'BZD',
  CA: 'CAD',
  CC: 'AUD',
  CD: 'CDF',
  CF: 'XAF',
  CG: 'XAF',
  CH: 'CHF',
  CI: 'XOF',
  CK: 'NZD',
  CL: 'CLP',
  CM: 'XAF',
  CN: 'CNY',
  CO: 'COP',
  CR: 'CRC',
  CU: 'CUP',
  CV: 'CVE',
  CW: 'ANG',
  CX: 'AUD',
  CY: 'EUR',
  CZ: 'CZK',
  DE: 'EUR',
  DJ: 'DJF',
  DK: 'DKK',
  DM: 'XCD',
  DO: 'DOP',
  DZ: 'DZD',
  EC: 'USD',
  EE: 'EUR',
  EG: 'EGP',
  EH: 'MAD',
  ER: 'ERN',
  ES: 'EUR',
  ET: 'ETB',
  FI: 'EUR',
  FJ: 'FJD',
  FK: 'FKP',
  FM: 'USD',
  FO: 'DKK',
  FR: 'EUR',
  GA: 'XAF',
  GB: 'GBP',
  GD: 'XCD',
  GE: 'GEL',
  GF: 'EUR',
  GG: 'GBP',
  GH: 'GHS',
  GI: 'GIP',
  GL: 'DKK',
  GM: 'GMD',
  GN: 'GNF',
  GP: 'EUR',
  GQ: 'XAF',
  GR: 'EUR',
  GS: 'GBP',
  GT: 'GTQ',
  GU: 'USD',
  GW: 'XOF',
  GY: 'GYD',
  HK: 'HKD',
  HM: 'AUD',
  HN: 'HNL',
  HR: 'HRK',
  HT: 'HTG',
  HU: 'HUF',
  ID: 'IDR',
  IE: 'EUR',
  IL: 'ILS',
  IM: 'GBP',
  IN: 'INR',
  IO: 'USD',
  IQ: 'IQD',
  IR: 'IRR',
  IS: 'ISK',
  IT: 'EUR',
  JE: 'GBP',
  JM: 'JMD',
  JO: 'JOD',
  JP: 'JPY',
  KE: 'KES',
  KG: 'KGS',
  KH: 'KHR',
  KI: 'AUD',
  KM: 'KMF',
  KN: 'XCD',
  KP: 'KPW',
  KR: 'KRW',
  KW: 'KWD',
  KY: 'KYD',
  KZ: 'KZT',
  LA: 'LAK',
  LB: 'LBP',
  LC: 'XCD',
  LI: 'CHF',
  LK: 'LKR',
  LR: 'LRD',
  LS: 'LSL',
  LT: 'LTL',
  LU: 'EUR',
  LV: 'EUR',
  LY: 'LYD',
  MA: 'MAD',
  MC: 'EUR',
  MD: 'MDL',
  ME: 'EUR',
  MF: 'EUR',
  MG: 'MGA',
  MH: 'USD',
  MK: 'MKD',
  ML: 'XOF',
  MM: 'MMK',
  MN: 'MNT',
  MO: 'MOP',
  MP: 'USD',
  MQ: 'EUR',
  MR: 'MRO',
  MS: 'XCD',
  MT: 'EUR',
  MU: 'MUR',
  MV: 'MVR',
  MW: 'MWK',
  MX: 'MXN',
  MY: 'MYR',
  MZ: 'MZN',
  NA: 'NAD',
  NC: 'XPF',
  NE: 'XOF',
  NF: 'AUD',
  NG: 'NGN',
  NI: 'NIO',
  NL: 'EUR',
  NO: 'NOK',
  NP: 'NPR',
  NR: 'AUD',
  NU: 'NZD',
  NZ: 'NZD',
  OM: 'OMR',
  PA: 'PAB',
  PE: 'PEN',
  PF: 'XPF',
  PG: 'PGK',
  PH: 'PHP',
  PK: 'PKR',
  PL: 'PLN',
  PM: 'EUR',
  PN: 'NZD',
  PR: 'USD',
  PS: 'ILS',
  PT: 'EUR',
  PW: 'USD',
  PY: 'PYG',
  QA: 'QAR',
  RE: 'EUR',
  RO: 'RON',
  RS: 'RSD',
  RU: 'RUB',
  RW: 'RWF',
  SA: 'SAR',
  SB: 'SBD',
  SC: 'SCR',
  SD: 'SDG',
  SE: 'SEK',
  SG: 'SGD',
  SH: 'SHP',
  SI: 'EUR',
  SJ: 'NOK',
  SK: 'EUR',
  SL: 'SLL',
  SM: 'EUR',
  SN: 'XOF',
  SO: 'SOS',
  SR: 'SRD',
  SS: 'SSP',
  ST: 'STD',
  SV: 'USD',
  SX: 'ANG',
  SY: 'SYP',
  SZ: 'SZL',
  TC: 'USD',
  TD: 'XAF',
  TF: 'EUR',
  TG: 'XOF',
  TH: 'THB',
  TJ: 'TJS',
  TK: 'NZD',
  TL: 'USD',
  TM: 'TMT',
  TN: 'TND',
  TO: 'TOP',
  TR: 'TRY',
  TT: 'TTD',
  TV: 'AUD',
  TW: 'TWD',
  TZ: 'TZS',
  UA: 'UAH',
  UG: 'UGX',
  UK: 'GBP',
  UM: 'USD',
  US: 'USD',
  UY: 'UYU',
  UZ: 'UZS',
  VA: 'EUR',
  VC: 'XCD',
  VE: 'VEF',
  VG: 'USD',
  VI: 'USD',
  VN: 'VND',
  VU: 'VUV',
  WF: 'XPF',
  WS: 'WST',
  XK: 'EUR',
  YE: 'YER',
  YT: 'EUR',
  ZA: 'ZAR',
  ZM: 'ZMK',
  ZW: 'ZWL',
} as const
