export default {
  attributes: {
    addAttribute: 'Add Value',
    createAttribute: 'Create an Attribute',
    importAttributesInBulk: 'Import Attributes in bulk',
    textAttributesInBulk: `Here you can import an excel file to add attributes in bulk :`,
    bulletAttributesInBulk: [
      `• First row (header) must the name of attributes`,
      `• Each value below the header are considered as a group which belong to the attribute over the top`,
      `• Each value is case sensitive so be careful with capital letters which could create doublons`,
      `• Not possible to create doublon. There is an unique attribute name within the same attribute type & unique group name within the same attribute)`,
    ],
    importError: 'There are duplicated data, please double check the file content',
  },
  download: {
    budget: 'Export Budget',
    buildCustomExport: 'Build Custom Export',
    clientsExport: 'Clients Extract',
    contentTemplate: 'Download Template Master Content',
    complianceScore: 'Download Filtered Data Scores',
    currentContentTemplate: 'Download template (with current Master Content)',
    currentPriceReferenceTemplate: 'Download template (with current Price Reference)',
    download: 'Download',
    downloadAll: 'Download all data',
    downloadExcel: 'Download to Excel',
    downloadExport: 'Your export is being processed and will be directly downloaded once it’s ready.',
    downloadExportPopUp: 'This download may take a while',
    downloadTemplate: 'Download Excel Template',
    export: 'Export',
    exportAdGroupsTags: 'Export Ad Group Tags',
    exportAllData: 'Export all data',
    exportAsinLevelData: 'Export data at ASIN Level',
    exportAttributes: 'Export Attribute(s)',
    exportAttributesDisabled: 'Select an attribute in order to activate export',
    exportCampaignsTags: 'Export Campaign Tags',
    exportCustomerReviews: 'Export Customer Reviews',
    exportData: 'Export data',
    exportFilters: 'Export filters',
    exportKeywordsRankings: 'Export keyword rankings',
    exportKeywordsTags: 'Export Keywords Tags',
    exportOffersTags: 'Export Offers Tags',
    exportPriceHistory: 'Export filtered products price history',
    exportProducts: 'Export filtered products data',
    exportReviews: 'Export filtered reviews',
    exportTags: 'Export Tags',
    exportItemsHelp: 'Select the type of {{items}} you would like to export and click export!',
    exportVisibleData: 'Export All Visible Data',
    exportXls: 'Export XLS',
    extractSimulation: 'Extract simulation',
    filteredData: 'Export filtered data',
    filteredPriceHistory: 'Download filtered products Price History',
    filteredProductsPriceHistories: 'Download filtered products Price Histories',
    getGoogleChrome: 'Get Google Chrome',
    masterContent: 'Download Master Content',
    mixpanelExport: 'Mixpanel Extract',
    onboardingTemplates: "Download the templates for the Amazon store you're selling on",
    priceHistory: 'Download Price History',
    rawData: 'Download Raw Data',
    saveFilters: 'Save Filters',
    suggestedKeywords: 'Load suggested keywords',
    template: 'Download template',
  },
  global: {
    activate: 'Activate',
    add: 'Add',
    addAdvancedFilter: '+ Add advanced filter',
    addAttribute: 'Add Attribute',
    addClient: 'Add Client',
    addCondition: 'Add Condition',
    addDynamicTag: 'Add Dynamic Tag',
    addFilter: '+ Add filter',
    addItems: 'Add items',
    addMetric: 'Add Metric',
    addPhoto: 'Add profile photo',
    addProspect: 'Add Prospect',
    addRange: 'Add range',
    addToList: 'Add to list',
    addValue: 'Add Value',
    addValues: 'Add Value(s)',
    analyseBuyBoxActivity: 'Analyse Buy Box Activity',
    apply: 'Apply',
    archive: 'Archive',
    back: 'Back',
    buildCustomChart: 'Build Custom Chart',
    buildExport: 'Build custom export',
    bulkActions: 'Bulk Actions',
    cancel: 'Cancel',
    catchphrase: 'Click and change the image',
    categoryAudit: 'Audit Category',
    changeMyPhoto: 'Change my profile photo',
    changeEmail: 'Change Email',
    changeIcon: 'Change icon',
    changePassword: 'Change Password',
    changeTrackingFrequency: 'Change Tracking Frequency',
    clear: 'Clear',
    clearAll: 'Clear All',
    clearFilters: 'Clear Filters',
    close: 'Close',
    closeDetails: 'Close details',
    collapse: 'Collapse',
    comment: 'Comment',
    compare: 'Compare',
    compareCompetitors: 'Compare Competitors',
    compareVS: 'Compare VS',
    confirm: 'Confirm',
    connect: 'Connect',
    connectAttributes: 'Connect attributes',
    connectSellerCentral: 'Login to Seller Central',
    copyFilteredAsin: 'Copy Filtered ASIN',
    copyAsin: 'Copy Asin',
    copyLink: 'Copy Link',
    create: 'Create',
    createAction: 'Create Action',
    createAttribute: 'Create new attribute',
    createAlert: 'Create new alert',
    createExport: 'Create new export',
    createMember: 'Create Member',
    curateKeywords: 'Approve',
    displaySelectedCategories: {
      one: 'Display selected category',
      other: 'Display selected categories ({{count}})',
    },
    confirmDelete: 'Delete {{item}}',
    confirmDeleteMsg: 'Are you sure you want to delete this {{item}}?',
    duplicate: 'Duplicate',
    detailedView: 'Detailed View',
    delete: 'Delete',
    displayAllColumns: 'Display all columns',
    displayTotal: 'Display Total',
    drag: 'Drag',
    edit: 'Edit',
    editRename: 'Edit / Rename',
    editAttribute: 'Edit attribute {{definition}}',
    editAttributeValue: 'Edit value {{attribute}}',
    editOffer: 'Edit Offer',
    editPeriodA: 'Edit Period A',
    expand: 'Expand',
    fillOutCsv: 'Fill out the .CSV template with your references',
    fromAmazon: 'From amazon',
    getInTouch: 'Get in touch',
    getReviews: 'Get Reviews',
    getSPAPILink: 'Get SPAPI registration link',
    goToArchives: 'Go to Archives',
    help: 'Help',
    helpLink: 'Click here for help',
    ignoreKeywords: 'Ignore',
    inviteTeammate: 'Invite teammate',
    knowledge: 'Knowledge base',
    keywordsCuration: 'Keywords Curation',
    launchScrapings: 'Launch Scrapings',
    learnMore: 'learn more.',
    loadFilters: 'Load Filters',
    loginSellerCentral: 'Login to Seller Central',
    manageCategory: 'Manage Categories',
    manageKeywords: 'Manage keywords',
    manageValues: 'Manage Attribute & Values',
    no: 'No',
    off: 'Off',
    on: 'On',
    open: 'Open',
    openFilters: 'Open Filters',
    productsWithoutMasterContent: 'Products without Master Content',
    quickAdd: 'Quick Add',
    refresh: 'Refresh',
    restore: 'Restore',
    remove: 'Remove',
    removeAll: 'Remove all',
    removePhoto: 'Remove photo',
    requestPassword: 'REQUEST PASSWORD RESET LINK',
    reset: 'Reset',
    resetPassword: 'Reset Password',
    removeResult: 'Remove Result',
    retry: 'Retry',
    save: 'Save',
    saveAsNew: 'Save as new',
    saveTemplate: 'Save as Template',
    selectResult: 'Select Result',
    settings: 'Settings',
    scrape: 'Scrape now',
    scrapeBS: 'Scrape Best Sellers',
    scrapeKW: 'Scrape Keywords',
    seeMore: 'Click to see more',
    sendToEmail: 'Email me this report',
    set: 'Set',
    setCredentials: {
      sellerSubmitReady: 'Unlock the Seelk Road to Amazon',
    },
    setDefault: 'Pending',
    setTracking: 'Set Tracking',
    showFilters: 'Show Filters',
    showParents: 'Show Parents',
    skip: 'Skip',
    splitBudget: 'Split Budget',
    submitAndReset: 'Submit and Reset',
    submitBug: 'Submit bug',
    submitDataFeedback: 'Submit a data related feedback',
    submitGeneralFeedback: 'Submit a general feedback',
    support: 'Support',
    switchPeriods: 'Switch A and B Periods',
    test: 'Test',
    toggle: {
      auto: 'AUTO',
      manual: 'MANUAL',
    },
    toggleAnalyticsDashboard: 'See analytics dashboard',
    unset: 'Unset',
    update: 'Update',
    updateComment: 'Update Comment',
    view: 'View',
    viewAccount: 'View Account',
    viewKeywords: 'View Keywords',
    viewOfferPage: 'View Offer Page',
    showMyBrands: 'Show My Brands',
    vs: 'vs',
    walkthrough: 'Take the walkthrough',
    yes: 'Yes',
  },
  login: {
    advertising: 'Login to Amazon Advertising',
    sellerCentral: 'Login to Seller Central',
  },
  navigation: {
    asin: 'View Asin page',
    back: 'Back',
    backToMarketplaces: 'Back to Marketplaces',
    backToProducts: 'Go Back to products',
    finish: 'Finish',
    goToBrands: 'Go to Brands',
    goToAttributeManagement: 'Go to Attribute Management',
    goToMetricLexicon: 'Go to Metric Lexicon',
    goToProductsManagement: 'Go to product management',
    goToSettings: 'Go to Settings',
    goToTagManagement: 'Go to Tag Management',
    goToWorkstation: 'Go to WorkStation',
    highlight: 'View Product Highlight & Preview',
    imageCompliance: 'View Image Compliance',
    keyword: 'View Keyword page',
    next: 'Next',
    priceHistory: 'View Price History',
    productPrice: 'View Product Price History',
    productRankings: 'View Product Rankings',
    products: 'View Products',
    rankingHistory: 'View Ranking History',
    seo: 'Go to SEO Tracker',
    up: 'Up',
  },
  search: {
    asin: 'Search ASIN',
    attributes: 'Search for an attribute',
    brands: 'Search brands',
    categories: 'Search categories',
    keywords: 'Search keywords',
    search: 'Search',
    searchAndSelect: 'Search and select...',
    tags: 'Search Tag',
    groups: 'Search groups',
    text: 'Text search...',
    title: 'Search by title...',
    productTitleAsin: 'Search by product title or ASIN...',
  },
  select: {
    account: 'Please select one of the accounts listed below',
    accountType: 'Select an Account Type',
    action: 'Choose an action',
    all: 'Select All',
    allAsins: 'All Asins',
    asin: 'ASIN {{asin}} copied!',
    attributes: 'Select value(s)',
    attributesTemplate: 'Select which attributes you want to update',
    bbActive: 'Active Buy Box',
    bbOwnership: 'Buy Box Ownership',
    bbPrice: 'Buy Box Price',
    brandSelect: 'Select brand',
    copyAsin: 'ASIN {{asin}} copied!',
    copyKeywords: 'Keywords copied',
    copyItems: '{{items}} copied',
    countrySelector: 'Select a country',
    custom: 'Custom',
    dateRange: 'Select your date range',
    dateRanges: 'Select up to two date ranges',
    defaultCountries: 'Select default countries',
    definition: 'Select Attribute',
    displayInCurrency: 'Display in {{currency}}',
    filters: 'Select the filters you want to apply',
    format: 'Select a format',
    fullScreenMode: 'Full Screen Mode',
    grouping: 'Select a group',
    hasStock: 'Has Stock',
    hideMissedSellIn: 'Hide Refused PO',
    keyword: 'Select a keyword to preview the results',
    language: 'Choose language',
    marketplace: 'Select your marketplace below',
    netPpm: 'Net PPM',
    onlyCatalog: 'Only Catalog',
    previousPeriod: 'Previous Period',
    previousYear: 'Previous Year',
    pricing: 'Pricing',
    problem: 'Select a problem',
    ppm: 'PPM',
    regions: 'Choose your regions',
    sectionSelect: 'Select section',
    seeDetails: 'See details',
    select: 'Select',
    selected: 'Selected',
    setKeywordStatus: 'Set Keyword Status',
    selectSoVKind: 'Select SOV type',
    shortAccount: 'Select an account',
    showGroups: 'Show groups',
    showFullYear: 'Show full year',
    showMissedSellIn: 'Show Refused PO',
    sourcingVSManufacturing: 'Sourcing VS Manufacturing share',
    trackingFrequency: 'Select tracking frequency',
    yearOverYear: 'Year over year',
  },
  upload: {
    addActions: {
      one: 'Add an action on {{problem}}',
      other: 'Add {{count}} actions on {{problem}}',
      zero: 'No action(s) selected',
    },
    addEditMasterContent: 'Add/Edit Master Content',
    addKeywords: {
      one: 'Add one keyword',
      other: 'Add {{count}} keywords',
      zero: 'No keyword to add',
    },
    addMasterContent: 'Add Master Content',
    chooseFile: 'Choose a file',
    competitors: 'Add Competitors',
    deleteActions: {
      one: 'Delete an action',
      other: 'Delete {{count}} actions',
      zero: 'No action(s) to delete',
    },
    dragOrClickImageUpload: 'Drag or click to upload an image',
    dragOrClickLogoUpload: 'Drag and drop an image or import one from your computer',
    dropCsvFile: 'Drop your file (.csv) here or click to select a file',
    dropFile: 'Drop your file (.csv, .xls, .xlsx) here or click to select a file',
    dropImages: 'Drop your images here (jpeg, png)',
    dropLogo: 'Drag & drop .png or .jpg',
    dropOnboardingTemplates: 'Upload your filled templates back here',
    excel: 'Upload excel',
    globalBudget: 'Add Global Budget',
    images: 'Upload images',
    image: 'Choose an image',
    imagesDesc: 'Upload images description',
    import: 'Import',
    importAttributes: 'Import Attributes',
    importFilters: 'Import filters',
    importItemsHelp: 'Select the type of {{items}} you would like to import!',
    importTags: 'Import Tags',
    keywords: 'Add keywords',
    linkValuesToAttributes: 'Link values to products',
    masterContent: 'Upload Master Content',
    priceReference: 'Upload Price Reference',
    products: 'Add products',
    recommendedDimensions: 'Recommended dimensions 500px x 500px',
    relaunchActions: {
      one: 'Relaunch an action',
      other: 'Relaunch {{count}} actions',
      zero: 'No action(s) to relaunch',
    },
    removeKeywords: {
      one: 'Remove one keyword',
      other: 'Remove {{count}} keywords',
      zero: 'No keyword to remove',
    },
    takeActions: {
      one: 'Take an action',
      other: 'Take {{count}} actions',
      zero: 'No action(s) selected',
    },
    uploadExcel: 'Upload an Excel',
    updateActions: {
      one: 'Update an action',
      other: 'Update {{count}} actions',
      zero: 'No action(s) to update',
    },
    upload: 'Upload',
  },
}
