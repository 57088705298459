import React from 'react'

import {Col} from 'jsxstyle'
import PropTypes from 'prop-types'
import {Progress} from 'semantic-ui-react'

import {SeelkMetricChange} from '../../../common-ui'
import i18n from '../../../i18n'

import './ProgressBar.css'

const ProgressBar = ({value, item, placehold, extra}) => {
  const diff = extra && extra.keyDiff && item[extra.keyDiff]
  const val = value?.value ?? value
  if (val === null) return <b>{i18n.t('placeholders.empty.notAvailable')}</b>
  return (
    <Col justifyContent="center" alignItems="center" width="100%">
      <Progress
        style={{width: '100%', margin: '0 0 5px 0'}}
        progress
        className={!placehold ? 'indicating' : ''}
        disabled={placehold}
        percent={Math.round(val || 0)}
      />
      {diff !== null && typeof diff !== 'undefined' && (
        <SeelkMetricChange value={diff || 0} size="small" style={{width: '100%'}} />
      )}
    </Col>
  )
}

ProgressBar.propTypes = {
  extra: PropTypes.object,
  item: PropTypes.object,
  placehold: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
}

ProgressBar.defaultProps = {
  extra: {},
  item: null,
  placehold: false,
  value: 0,
}

export default ProgressBar
