import React from 'react'

import {css, StyleSheet} from 'aphrodite'
import {Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import {combineLatest, empty} from 'rxjs'
import {catchError, map, mergeMap} from 'rxjs/operators'
import {Button, Grid, Icon, Image, Loader} from 'semantic-ui-react'

import {BooApi, KaioApi} from '../utils/apis'
import colors from '../utils/colors'
import logo from '../utils/common-ui/images/logo.png'
import {getState} from '../utils/extensions/rx'
import i18n from '../utils/i18n'
import LoaderWrapper from '../utils/requesting/containers/LoaderWrapper'
import gokuHealing from './goku_healing.mp4'

const styles = StyleSheet.create({
  gradient: {
    background: 'rgb(12, 47, 96) linear-gradient(0deg, rgba(12,47,96,1) 0%, rgba(0,0,0,1) 100%)',
    opacity: 0.9,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    color: 'white',
  },
})

class Maintenance extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  constructor() {
    super()
    this.state = {
      fetching: true,
      isMobile: false,
      isIE: false,
      isOolongMode: false,
      isUnderMaintenance: false,
      maintenanceMessage: null,
    }
  }

  UNSAFE_componentWillMount() {
    getState('router').subscribe(router => {
      // :hihihi: :pedro: :squirrel:
      if (/mode=oolong/i.test(router.location.search)) {
        this.setState({
          isOolongMode: true,
        })
      }
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        this.setState({
          isMobile: true,
        })

      // const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
      // const isFirefox = typeof InstallTrigger !== 'undefined'
      // const isSafari =
      //   /constructor/i.test(window.HTMLElement) ||
      //   // eslint-disable-next-line func-names
      //   (function(p) {
      //     return p.toString() === '[object SafariRemoteNotification]'
      //   })(!window.safari || (typeof window.safari !== 'undefined' && window.safari.pushNotification))

      // if (!isChrome && !isSafari && !isFirefox)
      //   this.setState({
      //     isNotChromeNorFirefoxNorSafari: true,
      //   })

      const isIE = !!document.documentMode

      if (isIE)
        this.setState({
          isIE: true,
        })

      combineLatest([BooApi('checkLive'), KaioApi('checkLive')])
        .pipe(
          mergeMap(response => {
            if (response.includes(false)) {
              this.setState({fetching: false, isUnderMaintenance: true})
              return empty()
            }
            return KaioApi('checkServiceStatus').pipe(
              map(rspns => {
                if (rspns.maintenance)
                  return this.setState({
                    fetching: false,
                    isUnderMaintenance: true,
                    maintenanceMessage: rspns.message,
                  })
                return this.setState({
                  fetching: false,
                  isUnderMaintenance: false,
                })
              }),
              catchError(error => {
                if (error.code === 404) {
                  this.setState({
                    fetching: false,
                    isUnderMaintenance: false,
                  })
                  return empty()
                }
                this.setState({
                  fetching: false,
                  isUnderMaintenance: true,
                })
                return empty()
              })
            )
          })
        )
        .subscribe()
    })
  }

  render() {
    if (this.state.isMobile && !this.state.isOolongMode)
      return (
        <Col
          justifyContent="center"
          alignItems="center"
          flex={1}
          height="100vh"
          width="100vw"
          background="rgba(249, 251, 253, 1)"
        >
          <Grid
            centered
            style={{
              padding: 25,
              paddingLeft: 50,
              paddingRight: 50,
              borderRadius: 10,
              backgroundColor: colors.white.rgba,
              boxShadow: `0px 0px 4px ${colors.shadow.rgba}`,
            }}
          >
            <Grid.Column verticalAlign="middle">
              <Grid.Row>
                <Image src={logo} centered height={75} />
              </Grid.Row>
              <Grid.Row style={{marginTop: 6}}>
                <h2 style={{color: colors.primary.rgba}}>
                  SEELK<span style={{fontWeight: 400}}>STUDIO</span>
                </h2>
              </Grid.Row>
              <Grid.Row style={{marginTop: 24}}>
                <h3>{i18n.t('placeholders.global.switchToDesktopTitle')}</h3>
              </Grid.Row>
              <Grid.Row style={{marginTop: 12}}>
                <p style={{color: colors.darkLight.rgba}}>{i18n.t('placeholders.global.switchToDesktopSubtitle')}</p>
              </Grid.Row>
              <Grid.Row style={{marginTop: 24}}>
                <Button icon labelPosition="left" color="primary" onClick={() => global.open('https://help.seelk.io/')}>
                  <Icon name="life ring" />
                  {i18n.t('placeholders.global.helpCenter')}
                </Button>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Col>
      )
    if (this.state.isIE && !this.state.isOolongMode) {
      return (
        <Col
          justifyContent="center"
          alignItems="center"
          flex={1}
          height="100vh"
          width="100vw"
          background="rgba(249, 251, 253, 1)"
        >
          <Grid
            centered
            style={{
              padding: 25,
              paddingLeft: 50,
              paddingRight: 50,
              borderRadius: 10,
              backgroundColor: colors.white.rgba,
              boxShadow: `0px 0px 4px ${colors.shadow.rgba}`,
            }}
          >
            <Grid.Column verticalAlign="middle">
              <Grid.Row>
                <Image src={logo} centered height={75} />
              </Grid.Row>
              <Grid.Row style={{marginTop: 6}}>
                <h2 style={{color: colors.primary.rgba}}>
                  SEELK<span style={{fontWeight: 400}}>STUDIO</span>
                </h2>
              </Grid.Row>
              <Grid.Row style={{marginTop: 24}}>
                <h3>{i18n.t('placeholders.global.switchToChromeTitle')}</h3>
              </Grid.Row>
              <Grid.Row style={{marginTop: 12}}>
                <p style={{color: colors.darkLight.rgba}}>{i18n.t('placeholders.global.switchToChromeSubtitle')}</p>
              </Grid.Row>
              <Grid.Row style={{marginTop: 24}}>
                <Row justifyContent="space-around" alignItems="center">
                  <Button
                    icon
                    labelPosition="left"
                    color="primary"
                    onClick={() => global.open('https://www.google.com/chrome/')}
                  >
                    <Icon name="google" />
                    {i18n.t('actions.download.getGoogleChrome')}
                  </Button>
                  <Button
                    icon
                    labelPosition="left"
                    color="primary"
                    onClick={() => global.open('https://help.seelk.io/')}
                  >
                    <Icon name="life ring" />
                    {i18n.t('placeholders.global.helpCenter')}
                  </Button>
                </Row>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Col>
      )
    }
    if (this.state.isUnderMaintenance)
      return (
        <Col
          justifyContent="center"
          alignItems="center"
          flex={1}
          height="100vh"
          width="100vw"
          className={css(styles.gradient)}
          padding={20}
        >
          <Grid centered>
            <Grid.Column style={{padding: 20}}>
              <Grid.Row>
                <video
                  id="video-background"
                  autoPlay
                  loop
                  muted
                  src={gokuHealing}
                  style={{borderRadius: 30}}
                  height="500"
                />
              </Grid.Row>
              <Grid.Row style={{marginTop: 24}}>
                <h1 style={{color: 'white', textAlign: 'center'}}>{i18n.t('placeholders.global.underMaintenance')}</h1>
              </Grid.Row>
              <Grid.Row style={{marginTop: 12}}>
                <h2 style={{color: 'white', textAlign: 'center'}}>{i18n.t('placeholders.global.softwareStronger')}</h2>
              </Grid.Row>
              <Grid.Row style={{marginTop: 12}}>
                <p style={{color: 'white', textAlign: 'center'}}>{this.state.maintenanceMessage}</p>
              </Grid.Row>
              <Grid.Row style={{marginTop: 24}}>
                <Row justifyContent="space-around" alignItems="center">
                  <a href="http://dragonball.wikia.com/wiki/Saiyan_Power" target="_blank" rel="noopener noreferrer">
                    <p style={{textAlign: 'center'}}>[{i18n.t('placeholders.global.sayian')}]</p>
                  </a>
                  <Button
                    icon
                    labelPosition="left"
                    color="primary"
                    onClick={() => global.open('https://help.seelk.io/')}
                  >
                    <Icon name="life ring" />
                    {i18n.t('placeholders.global.helpCenter')}
                  </Button>
                </Row>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Col>
      )
    return (
      <LoaderWrapper
        requesting={this.state.fetching}
        render={requesting => {
          if (requesting) return <Loader active />
          return this.props.children
        }}
      />
    )
  }
}

export default Maintenance
