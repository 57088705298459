import React from 'react'

import Dropzone, {DropEvent, FileRejection} from 'react-dropzone'
import {Header, Icon} from 'semantic-ui-react'
import styled, {css} from 'styled-components'

import colors from '../../../colors'
import i18n from '../../../i18n'

interface StyledDropzoneCSSProps {
  readonly theme: any
  readonly width: string
  readonly height: string
}

const StyledDropzoneCSS = ({theme, width, height}: StyledDropzoneCSSProps) => css`
  display: ${theme.dropzone.display} !important;
  align-items: ${theme.dropzone.alignItems} !important;
  justify-content: ${theme.dropzone.justifyContent} !important;
  height: ${height} !important;
  width: ${width} !important;
  border: ${theme.dropzone.border} !important;
  border-radius: ${theme.dropzone.borderRadius} !important;
  padding: ${theme.dropzone.padding} !important;
  color: ${theme.dropzone.color} !important;
  background-color: ${theme.dropzone.backgroundColor} !important;
  cursor: ${theme.dropzone.cursor} !important;
`

const StyledFileInfoCSS = ({theme}: StyledDropzoneCSSProps['theme']) => css`
  color: ${theme.dropzone.info.color};
  font-size: ${theme.dropzone.info.fontSize};
`

const StyledDefaultDropzone = styled.section<StyledDropzoneCSSProps>`
  &&&&& {
    ${props => StyledDropzoneCSS(props)}
  }
`

const StyledFileInfo = styled.span`
  &&&&& {
    ${props => StyledFileInfoCSS(props)}
  }
`

const StyledMessage = styled.p`
  margin-top: 10px;
  font-size: 0.9em;
  font-weight: 400;
  color: ${colors.lightDark.rgba};
`

interface SeelkDropzoneProps {
  accept: string
  onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
  file?: File | null
  height?: string
  message?: string | null
  width?: string
}

const SeelkDropzone = ({
  accept,
  onDrop,
  file = null,
  height = '100%',
  message = null,
  width = '100%',
  ...props
}: SeelkDropzoneProps) => {
  const filePreview = (
    <StyledFileInfo>
      <Icon name="check" color="green" />
      {file ? file.name : ''}
    </StyledFileInfo>
  )
  const noFilePreview = (
    <Header as="h5" icon style={{padding: '20px'}}>
      <Icon name="upload" style={{color: colors.light.rgba}} />
      <Header.Subheader style={{color: colors.lightDark.rgba}}>{i18n.t('actions.upload.dropFile')}</Header.Subheader>
      {message ? <StyledMessage>{message}</StyledMessage> : null}
    </Header>
  )

  return (
    <Dropzone onDrop={onDrop} accept={accept}>
      {({getRootProps, getInputProps}) => (
        <StyledDefaultDropzone width={width} height={height} {...props}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {file ? filePreview : noFilePreview}
          </div>
        </StyledDefaultDropzone>
      )}
    </Dropzone>
  )
}

export default SeelkDropzone
