import React from 'react'

import PropTypes from 'prop-types'

import {push} from '../metrics'

class Tracking extends React.PureComponent {
  static propTypes = {
    as: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object, PropTypes.string]),
    trackingEvent: PropTypes.string.isRequired,
    hookOn: PropTypes.arrayOf(PropTypes.string),
    actionType: PropTypes.string.isRequired,
  }

  static defaultProps = {
    as: null,
    hookOn: ['onClick', 'onChange'],
  }

  handleHookOn =
    hook =>
    (...args) => {
      push({tracking: this.props.trackingEvent, action_type: this.props.actionType})
      this.props[hook](...args)
    }

  render() {
    // eslint-disable-next-line no-unused-vars
    const {as: AsElement, actionType, trackingEvent, hookOn, ...rest} = this.props
    const hookFn = {}
    hookOn.forEach(h => {
      if (this.props[h]) {
        delete rest[h]
        hookFn[h] = this.handleHookOn(h)
      }
    })
    return AsElement ? <AsElement {...rest} {...hookFn} /> : <div {...rest} {...hookFn} />
  }
}

export default Tracking
