import React from 'react'

import PropTypes from 'prop-types'
import {Icon, Input, Label} from 'semantic-ui-react'
import styled, {css} from 'styled-components'

import {requiredIf} from '../../..'
import i18n from '../../../i18n'
import ColorSelection from '../ColorSelection'

const StyledBaseInputCSS = ({theme, hasError, icon, width, padding}) => css`
  ${hasError ? `box-shadow: ${theme.input.error.boxShadow} !important;` : ''}
  input {
    ${hasError ? `border-color: ${theme.input.error.borderColor} !important;` : ''}
    ${icon ? '' : `padding-left: ${theme.input.paddingLeft} !important;`}
    ${width ? `width: ${width} !important;` : ''}
    ${padding ? `padding: ${padding} !important;` : ''}
  }
  :focus-within {
    border-radius: ${theme.input.borderRadius};
    box-shadow: ${theme.input.focused.boxShadow};
    input {
      border-color: ${theme.input.focused.borderColor};
    }
    .label {
      border-color: ${theme.input.focused.borderColor};
    }
    .icon {
      color: ${theme.input.focused.icon.color};
    }
  }
  .icon {
    margin: ${theme.input.icon.margin};
    color: ${theme.input.icon.color};
    opacity: ${theme.input.icon.opacity};
  }
  i {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const StyledIsolatedInputCSS = ({theme}) => css`
  :focus-within {
    input {
      border-left: ${theme.input.borderLeft} !important;
    }
  }
  input {
    border-left: ${theme.input.borderLeft} !important;
  }
  .icon {
    width: ${theme.input.icon.width};
  }
`

const StyledHeaderInputCSS = ({theme}) => css`
  font-size: ${theme.input.header.fontSize};
  font-weight: ${theme.input.header.fontWeight};
  color: ${theme.input.header.color};
  text-transform: ${theme.input.header.textTransform};
  margin-bottom: ${theme.input.header.marginBottom};
`

// eslint-disable-next-line react/prop-types
const StyledErrorBaseInput = ({hasError, ...props}) => <Input {...props} />

const StyledBaseInput = styled(StyledErrorBaseInput)`
  &&&&& {
    ${props => StyledBaseInputCSS(props)}
  }
`

const StyledIsolatedInput = styled(StyledBaseInput)`
  &&&&& {
    ${props => StyledIsolatedInputCSS(props)}
  }
`

const StyledHeaderInput = styled.p`
  &&&&& {
    ${props => StyledHeaderInputCSS(props)}
  }
`

// eslint-disable-next-line react/prop-types
const IsolatedIconInput = ({onChange, value, icon, iconPosition, placeholder, error, ...props}) => (
  <StyledIsolatedInput
    onChange={onChange}
    value={value}
    label={{
      basic: true,
      content: <Icon name={icon} />,
    }}
    labelPosition={iconPosition !== 'left' ? null : 'left'}
    placeholder={placeholder}
    hasError={!!error}
    {...props}
  />
)

// eslint-disable-next-line react/prop-types
const IconInput = ({onChange, value, placeholder, icon, iconPosition, error, ...props}) => (
  <StyledBaseInput
    onChange={onChange}
    value={value}
    placeholder={placeholder}
    icon={icon}
    iconPosition={iconPosition !== 'left' ? null : 'left'}
    hasError={!!error}
    {...props}
  />
)

// eslint-disable-next-line react/prop-types
const HeaderInput = ({onChange, value, placeholder, header, error, ...props}) => (
  <div style={{textAlign: 'left'}}>
    <StyledHeaderInput>{header}</StyledHeaderInput>
    <StyledBaseInput onChange={onChange} value={value} placeholder={placeholder} hasError={!!error} {...props} />
  </div>
)

// eslint-disable-next-line react/prop-types
const BasicInput = ({onChange, value, placeholder, error, ...props}) => (
  <StyledBaseInput onChange={onChange} value={value} placeholder={placeholder} hasError={!!error} {...props} />
)

const OptionsInput = ({onChange, value, placeholder, error, colorpicker, ...props}) => (
  <StyledBaseInput
    onChange={onChange}
    onClick={e => e.stopPropagation()}
    value={value}
    placeholder={placeholder}
    hasError={!!error}
    action={
      <ColorSelection
        selectedColor={colorpicker.value}
        colorOptions={colorpicker.options}
        handleColorSelect={colorpicker.onChange}
        lightContent
      />
    }
    {...props}
  />
)

OptionsInput.propTypes = {
  colorpicker: PropTypes.object.isRequired,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}

OptionsInput.defaultProps = {
  error: false,
  placeholder: i18n.t('placeholders.global.input'),
  value: '',
}

const SeelkInput = ({errorMessage, colorPicker, ...props}) => {
  let FinalInput

  if (colorPicker) {
    FinalInput = OptionsInput
  } else if (props.icon) {
    if (props.type === 'money') {
      FinalInput = IsolatedIconInput
    } else {
      FinalInput = IconInput
    }
  } else if (props.header) {
    FinalInput = HeaderInput
  } else {
    FinalInput = BasicInput
  }

  return props.error ? (
    <>
      <FinalInput colorpicker={colorPicker} {...props} />
      <Label basic color="red" pointing>
        {errorMessage}
      </Label>
    </>
  ) : (
    <FinalInput colorpicker={colorPicker} {...props} />
  )
}

SeelkInput.propTypes = {
  colorPicker: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: requiredIf(PropTypes.string, props => props.error),
  header: PropTypes.string,
  icon: requiredIf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    props => props.type && props.type !== 'text'
  ),
  iconPosition: requiredIf(PropTypes.oneOf(['left', 'right']), props => props.icon),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

SeelkInput.defaultProps = {
  colorPicker: null,
  error: false,
  errorMessage: i18n.t('placeholders.errors.error'),
  header: '',
  icon: null,
  iconPosition: 'left',
  placeholder: i18n.t('placeholders.global.input'),
  type: 'text',
  value: '',
}

export default SeelkInput
