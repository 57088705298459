import {BINARY_MASKS} from '../constants'
import i18n from '../i18n'
import {fuzzyWordsSearch} from '..'
import BaseSeries from './BaseSeries'
import ExtraDimensionSeries from './ExtraDimensionSeries'

class AlertsReviewsSeries extends ExtraDimensionSeries(BaseSeries) {
  createDimensions() {
    super.createDimensions()
    this.cf.dimensions.marketplaces = this.cf.data.dimension(v => v.marketplace.split('_')[0])
    this.cf.dimensions.countries = this.cf.data.dimension(v => {
      const country = v.marketplace.split('_')[1]
      return country
    })
    this.cf.dimensions.asins = this.cf.data.dimension(v => v.asin)
    this.cf.dimensions.tags = this.cf.data.dimension(v => v.tags || [])
    this.cf.dimensions.amazonBrands = this.cf.data.dimension(v => v.brand || i18n.t('placeholders.empty.unclassified'))
    this.cf.dimensions.title = this.cf.data.dimension(v =>
      v.title ? v.title.toLowerCase() : i18n.t('placeholders.empty.untitled')
    )
    this.cf.dimensions.contentScore = this.cf.data.dimension(v => parseFloat(v.catalogue_health_score) || 0)
    this.cf.dimensions.offersScore = this.cf.data.dimension(v => parseFloat(v.buy_box_health_score) || 0)
    this.cf.dimensions.reviewsScore = this.cf.data.dimension(v => parseFloat(v.review_health_score) || 0)
    this.cf.dimensions.ratingScore = this.cf.data.dimension(
      v =>
        ((parseFloat(v.catalogue_health_score) || 0) +
          (parseFloat(v.buy_box_health_score) || 0) +
          (parseFloat(v.review_health_score) || 0)) /
        3
    )
    this.cf.dimensions.buyBox = this.cf.data.dimension(
      v =>
        // eslint-disable-next-line no-bitwise
        (v.has_active_buy_box ? BINARY_MASKS.ACTIVE : 0) |
        (v.is_prime_only ? BINARY_MASKS.PRIME_ONLY : 0) |
        (v.is_add_on_item ? BINARY_MASKS.ADD_ON : 0) |
        (v.is_pantry ? BINARY_MASKS.PANTRY : 0)
    )
    this.cf.dimensions.booleans = this.cf.data.dimension(
      v =>
        // eslint-disable-next-line no-bitwise
        (v.has_vendor_offer ? BINARY_MASKS.VENDOR_OFFER : 0) |
        (v.is_buy_box_owner ? BINARY_MASKS.BUY_BOX_OWNER : 0) |
        (v.is_404 ? BINARY_MASKS.INVALID_PRODUCT : 0) |
        (v.has_a_plus ? BINARY_MASKS.HAS_A_PLUS : 0) |
        (v.has_stock ? BINARY_MASKS.HAS_STOCK : 0)
    )
    this.cf.dimensions.fbaSellers = this.cf.data.dimension(v => parseFloat(v.nb_fba_sellers) || 0)
    this.cf.dimensions.mfnSellers = this.cf.data.dimension(v => parseFloat(v.nb_mfn_sellers) || 0)
    this.cf.dimensions.bulletPoints = this.cf.data.dimension(v => (v.bullet_points ? v.bullet_points.length : 0))
    this.cf.dimensions.images = this.cf.data.dimension(v => parseFloat(v.nb_images) || 0)
    this.cf.dimensions.videos = this.cf.data.dimension(v => parseFloat(v.nb_videos) || 0)
    this.cf.dimensions.averageRating = this.cf.data.dimension(v => parseFloat(v.weighted_average_rating) || 0)
    this.cf.dimensions.dayDelta = this.cf.data.dimension(v => v.nb_reviews.day_delta || v.average_rating.day_delta)
    this.cf.dimensions.prevReviews = this.cf.data.dimension(v => parseFloat(v.nb_reviews.previous_value) || 0)
    this.cf.dimensions.newReviews = this.cf.data.dimension(v => parseFloat(v.nb_reviews.newReviews) || 0)
    this.cf.dimensions.totalReviews = this.cf.data.dimension(v => parseFloat(v.nb_reviews.new_value) || 0)
    this.cf.dimensions.prevRating = this.cf.data.dimension(v => parseFloat(v.average_rating.previous_value) || 0)
    this.cf.dimensions.newRating = this.cf.data.dimension(v => parseFloat(v.average_rating.new_value) || 0)
    this.cf.dimensions.ratingDiff = this.cf.data.dimension(v => parseFloat(v.average_rating.ratingDiff) || 0)
    this.cf.dimensions.scrapingFrequency = this.cf.data.dimension(v => v.scraping_frequency)
  }

  updateFilters(values = {}, lockId = 'default') {
    super.updateFilters(values, lockId)
    const {
      amazonBrands = null,
      asins = null,
      averageRating = {},
      bulletPoints = {},
      buyBox = null,
      buyBoxOwner = null,
      contentScore = {},
      countries = null,
      dayDelta = null,
      fbaSellers = {},
      hasAPlus = null,
      hasStockBool = null,
      images = {},
      invalidProduct = null,
      marketplaces = null,
      mfnSellers = {},
      newRating = null,
      newReviews = null,
      offersScore = {},
      prevRating = null,
      prevReviews = null,
      ratingDiff = null,
      ratingScore = {},
      reviewsScore = {},
      title = null,
      totalReviews = null,
      vendorOffer = null,
      videos = {},
      offerTags = {slugs: []},
      scrapingFrequencies = null,
    } = this.filters[lockId]

    const buyBoxMask =
      // eslint-disable-next-line no-bitwise
      (buyBox === 'has_active_buy_box' ? BINARY_MASKS.ACTIVE : 0) |
      (buyBox === 'is_prime_only' ? BINARY_MASKS.PRIME_ONLY : 0) |
      (buyBox === 'is_add_on_item' ? BINARY_MASKS.ADD_ON : 0) |
      (buyBox === 'is_pantry' ? BINARY_MASKS.PANTRY : 0)

    const positiveBooleansMask =
      // eslint-disable-next-line no-bitwise
      (vendorOffer ? BINARY_MASKS.VENDOR_OFFER : 0) |
      (buyBoxOwner ? BINARY_MASKS.BUY_BOX_OWNER : 0) |
      (invalidProduct ? BINARY_MASKS.INVALID_PRODUCT : 0) |
      (hasAPlus ? BINARY_MASKS.HAS_A_PLUS : 0) |
      (hasStockBool ? BINARY_MASKS.HAS_STOCK : 0)

    const negativeBooleansMask =
      // eslint-disable-next-line no-bitwise
      (vendorOffer === false ? BINARY_MASKS.VENDOR_OFFER : 0) |
      (buyBoxOwner === false ? BINARY_MASKS.BUY_BOX_OWNER : 0) |
      (invalidProduct === false ? BINARY_MASKS.INVALID_PRODUCT : 0) |
      (hasAPlus === false ? BINARY_MASKS.HAS_A_PLUS : 0) |
      (hasStockBool === false ? BINARY_MASKS.HAS_STOCK : 0)

    this.filterDimension(this.cf.dimensions.marketplaces, marketplaces)
    this.filterDimension(this.cf.dimensions.countries, countries)
    this.filterDimension(this.cf.dimensions.asins, asins)
    this.filterDimension(this.cf.dimensions.amazonBrands, amazonBrands)
    this.filterDimension(
      this.cf.dimensions.title,
      title && typeof title === 'string' ? v => fuzzyWordsSearch(title, v) : null
    )
    this.filterDimension(this.cf.dimensions.contentScore, contentScore.from || null, contentScore.to || null)
    this.filterDimension(this.cf.dimensions.offersScore, offersScore.from || null, offersScore.to || null)
    this.filterDimension(this.cf.dimensions.reviewsScore, reviewsScore.from || null, reviewsScore.to || null)
    this.filterDimension(this.cf.dimensions.ratingScore, ratingScore.from || null, ratingScore.to || null)
    this.filterDimension(
      this.cf.dimensions.buyBox,
      // eslint-disable-next-line no-bitwise
      v => (buyBox === 'inactive' ? v === 0 : (buyBoxMask & v) === buyBoxMask)
    )
    this.filterDimension(
      this.cf.dimensions.booleans,
      v =>
        // eslint-disable-next-line no-bitwise
        (positiveBooleansMask & v) === positiveBooleansMask &&
        // eslint-disable-next-line no-bitwise
        ((v ^ negativeBooleansMask) & negativeBooleansMask) === negativeBooleansMask
    )
    this.filterDimension(this.cf.dimensions.fbaSellers, fbaSellers.from || null, fbaSellers.to || null)
    this.filterDimension(this.cf.dimensions.mfnSellers, mfnSellers.from || null, mfnSellers.to || null)
    this.filterDimension(this.cf.dimensions.bulletPoints, bulletPoints.from || null, bulletPoints.to || null)
    this.filterDimension(this.cf.dimensions.images, images.from || null, images.to || null)
    this.filterDimension(this.cf.dimensions.videos, videos.from || null, videos.to || null)
    this.filterDimension(this.cf.dimensions.averageRating, averageRating.from || null, averageRating.to || null)
    this.filterDimension(this.cf.dimensions.dayDelta, dayDelta)
    this.filterDimension(
      this.cf.dimensions.prevReviews,
      (prevReviews && prevReviews.from) || null,
      (prevReviews && prevReviews.to) || null
    )
    this.filterDimension(
      this.cf.dimensions.newReviews,
      (newReviews && newReviews.from) || null,
      (newReviews && newReviews.to) || null
    )
    this.filterDimension(
      this.cf.dimensions.totalReviews,
      (totalReviews && totalReviews.from) || null,
      (totalReviews && totalReviews.to) || null
    )
    this.filterDimension(
      this.cf.dimensions.prevRating,
      (prevRating && prevRating.from) || null,
      (prevRating && prevRating.to) || null
    )
    this.filterDimension(
      this.cf.dimensions.newRating,
      (newRating && newRating.from) || null,
      (newRating && newRating.to) || null
    )
    this.filterDimension(
      this.cf.dimensions.ratingDiff,
      (ratingDiff && ratingDiff.from) || null,
      (ratingDiff && ratingDiff.to) || null
    )
    this.filterDimension(this.cf.dimensions.tags, v => {
      if (!offerTags?.slugs?.length) {
        return true
      } else if (offerTags.matchingType === 'AND') {
        return !offerTags.slugs.some(t => !v.includes(t))
      } else if (offerTags.matchingType === 'OR') {
        return v.some(t => offerTags.slugs.includes(t))
      }
      return false
    })
    this.filterDimension(this.cf.dimensions.scrapingFrequency, scrapingFrequencies)
  }

  getAvailableGroupings() {
    return [...super.getAvailableGroupings(), 'amazonBrands', 'asins', 'countries']
  }
}

export default AlertsReviewsSeries
