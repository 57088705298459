import {easyFetch, jsonFetch} from 'easyfetch'
import JSONbig from 'json-bigint'
import Cookies from 'universal-cookie'

import config from '../../../../config'
import KaioApiClient from '../KaioApiClient/KaioApiClient'

const BARDOCK = `https://${config.bardockDomain}`
const BARDOCK_API = `https://${config.bardockDomain}/api/v1`
const BARDOCK_API_V3 = `https://${config.bardockDomain}/api/v3`

export default class BardockApiClient {
  constructor() {
    if (!BardockApiClient.instance) {
      BardockApiClient.instance = this
    }
    return BardockApiClient.instance
  }

  async request(rType, url, useTenant = true, apiUrl = BARDOCK_API) {
    const token = await new KaioApiClient().getToken()
    const user = await new KaioApiClient().getUserFromToken()
    let tenant = user.client
    if (user.account.toLowerCase() === 'seelk') tenant = new Cookies().get('account')
    const req = rType(`${apiUrl}/${url}`)
    const headers = {Authorization: `Bearer ${token}`, 'X-Seelk-Tenant': useTenant ? tenant : 'public'}
    req.setHeaders(headers)
    return req
  }

  async JSONBigRequest(rType, url, useTenant = true, apiUrl = BARDOCK_API) {
    const token = await new KaioApiClient().getToken()
    const user = await new KaioApiClient().getUserFromToken()
    let tenant = user.client
    if (user.account.toLowerCase() === 'seelk') tenant = new Cookies().get('account')
    const req = rType(`${apiUrl}/${url}`)
    const headers = {
      Authorization: `Bearer ${token}`,
      'X-Seelk-Tenant': useTenant ? tenant : 'public',
      'Content-Type': 'application/json',
    }
    req.setHeaders(headers)
    return req
  }

  async checkLive() {
    try {
      await jsonFetch(`${BARDOCK}/health/`).get()
      return true
    } catch (error) {
      return error.code !== 502
    }
  }

  async getSchema() {
    return (await this.request(jsonFetch, 'chewee/amazon/schema/', true, BARDOCK_API_V3)).get()
  }

  async getMetricsV3() {
    // eslint-disable-next-line global-require
    return (await this.request(jsonFetch, 'metrics/', true, BARDOCK_API_V3)).get()
  }

  async uploadMetricsV3(metricsFile) {
    // keep scrolling
    const data = new FormData()
    data.append('csv_file', metricsFile)
    return (await this.request(easyFetch, 'metrics/', true, BARDOCK_API_V3)).put(data)
  }

  async getAmazonMetricsSeries({
    granularity,
    period,
    field = [],
    dimensionFilters = {},
    dimensionAttributes,
    dimensionAttributeFilters,
    aggregateFilters,
    customAttributes,
    customAttributeFilters,
    tagFilters,
    tags,
    orderBy,
    limit,
  } = {}) {
    return (await this.JSONBigRequest(easyFetch, 'chewee/amazon/timeseries/', true, BARDOCK_API_V3))
      .post(
        JSONbig.stringify({
          granularity,
          period,
          metrics: field,
          dimension_attributes: dimensionAttributes,
          dimension_filters: dimensionFilters,
          dimension_attribute_filters: dimensionAttributeFilters,
          aggregate_filters: aggregateFilters,
          custom_attributes: customAttributes,
          custom_attribute_filters: customAttributeFilters,
          tag_filters: tagFilters,
          tags,
          order_by: orderBy,
          limit,
        })
      )
      .then(resp => resp.text())
      .then(body => JSONbig({storeAsString: true}).parse(body))
  }

  async getQueriesAmazonMetricsSeries({queries, renderer} = {}) {
    return (await this.JSONBigRequest(easyFetch, 'chewee/amazon/timeseries/', true, BARDOCK_API_V3))
      .post(
        JSONbig.stringify({
          queries: queries.map(
            ({
              granularity,
              period,
              field,
              dimensionAttributes,
              dimensionFilters,
              dimensionAttributeFilters,
              aggregateFilters,
              customAttributes,
              customAttributeFilters,
              tagFilters,
              tags,
              orderBy,
              limit,
            }) => ({
              granularity,
              period,
              metrics: field,
              dimension_attributes: dimensionAttributes,
              dimension_filters: dimensionFilters,
              dimension_attribute_filters: dimensionAttributeFilters,
              aggregate_filters: aggregateFilters,
              custom_attributes: customAttributes,
              custom_attribute_filters: customAttributeFilters,
              tag_filters: tagFilters,
              tags,
              order_by: orderBy,
              limit,
              return_custom_attributes_pretty_name: true,
            })
          ),
          renderer,
        })
      )
      .then(resp => resp.blob())
  }

  async getSovRankings(params) {
    return (await this.request(jsonFetch, 'competitions/keyword_history/')).post(params)
  }

  async getSovByBrand(params) {
    return (await this.request(jsonFetch, 'competitions/keyword_sov_by_brand/')).post(params)
  }

  async getSovByAsin(params) {
    return (await this.request(jsonFetch, 'competitions/keyword_sov_by_asin/', true)).post(params)
  }

  async getKeywordRankingByBrandChunk(params) {
    return (await this.request(jsonFetch, 'competitions/keyword_ranking_by_brand_by_chunk/')).post(params)
  }

  async getKeywordRankingByBrandKeyword(params) {
    return (await this.request(jsonFetch, 'competitions/keyword_sov_by_brand_by_keyword/')).post(params)
  }
}
