export default {
  ae: {
    name: 'United Arab Emirates',
    language: 'Arabic',
  },
  au: {
    name: 'Australia',
    language: 'English',
  },
  br: {
    name: 'Brazil',
    language: 'Portuguese',
  },
  ca: {
    name: 'Canada',
    language: 'English',
  },
  cn: {
    name: 'China',
    language: 'Chinese',
  },
  de: {
    name: 'Germany',
    language: 'German',
  },
  en: {
    name: 'United Kingdom',
    language: 'English',
  },
  es: {
    name: 'Spain',
    language: 'Spanish',
  },
  fr: {
    name: 'France',
    language: 'French',
  },
  gb: {
    name: 'United Kingdom',
    language: 'English',
  },
  in: {
    name: 'India',
    language: 'English',
  },
  it: {
    name: 'Italy',
    language: 'Italian',
  },
  jp: {
    name: 'Japan',
    language: 'Japanese',
  },
  mx: {
    name: 'Mexico',
    language: 'Spanish',
  },
  nl: {
    name: 'Netherlands',
    language: 'Dutch',
  },
  pl: {
    name: 'Poland',
    language: 'Polish',
  },
  sa: {
    name: 'Saudi Arabia',
    language: 'Arabic',
  },
  se: {
    name: 'Sweden',
    language: 'Sami',
  },
  tr: {
    name: 'Turkey',
    language: 'Arabic',
  },
  uk: {
    name: 'United Kingdom',
    language: 'English',
  },
  us: {
    name: 'United States',
    language: 'English',
  },
}
