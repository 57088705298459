import PropTypes from 'prop-types'

const asinExp = new RegExp(/^[a-zA-Z0-9]{10}$/)
const marketplaceExp = new RegExp(/^amazon_[a-zA-Z]{2}$/i)

const requiredAsinProp = (props, propName, componentName) => {
  if (!props[propName]) {
    const err = new TypeError(
      `The prop '${propName}' is required in '${componentName}' but its value is '${props[propName]}'`
    )
    return err
  } else if (!asinExp.test(props[propName])) {
    const err = new TypeError(`'${props[propName]}' is not valid '${propName}' format in '${componentName}'`)
    return err
  } else return null
}

const asinProp = (props, propName) => {
  if (!props[propName]) return null
  return requiredAsinProp(props, propName)
}

asinProp.isRequired = requiredAsinProp

const requiredMarketplaceProp = (props, propName, componentName) => {
  if (!props[propName]) {
    const err = new TypeError(
      `The prop '${propName}' is required in '${componentName}' but its value is '${props[propName]}'`
    )
    return err
  } else if (!marketplaceExp.test(props[propName])) {
    const err = new TypeError(`'${props[propName]}' is not valid '${propName}' format in '${componentName}'`)
    return err
  } else return null
}

const marketplaceProp = (props, propName) => {
  if (!props[propName]) return null
  return requiredMarketplaceProp(props, propName)
}

marketplaceProp.isRequired = requiredMarketplaceProp

const requiredOfferProp = (props, propName, componentName) => {
  const [asin, marketplace] = props[propName]?.split(':')
  if (!props[propName]) {
    const err = new TypeError(
      `The prop '${propName}' is required in '${componentName}' but its value is '${props[propName]}'`
    )
    return err
  } else if (!asinExp.test(asin) || !marketplaceExp.test(marketplace)) {
    const err = new TypeError(`'${props[propName]}' is not valid '${propName}' format in '${componentName}'`)
    return err
  } else return null
}

const offerProp = (props, propName) => {
  if (!props[propName]) return null
  return requiredOfferProp(props, propName)
}

offerProp.isRequired = requiredOfferProp

const requiredNumberRange = (from, to, props, propName, componentName) => {
  if (!props[propName]) {
    const err = new TypeError(
      `The prop '${propName}' is required in '${componentName}' but its value is '${props[propName]}'`
    )
    return err
  } else if (props[propName] < from || props[propName] > to) {
    const err = new TypeError(`${propName} should be in range [${from} - ${to}] in ${componentName}`)
    return err
  } else return null
}

const numberRange = (from, to) => (props, propName, componentName) => {
  if (props[propName] === null) return null
  return requiredNumberRange(from, to, props, propName, componentName)
}

numberRange.isRequired = (from, to) => (props, propName, componentName) =>
  requiredNumberRange(from, to, props, propName, componentName)

PropTypes.asin = asinProp
PropTypes.marketplace = marketplaceProp
PropTypes.offer = offerProp
PropTypes.numberRange = numberRange
PropTypes.metric = PropTypes.shape({
  aggregate: PropTypes.oneOf(['sum', 'avg']).isRequired,
  metric: PropTypes.string.isRequired,
  name: PropTypes.string,
  extra: PropTypes.object,
})
PropTypes.tab = PropTypes.shape({
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  widgets: PropTypes.arrayOf(PropTypes.object).isRequired,
})
PropTypes.layout = PropTypes.shape({
  x: PropTypes.numberRange(0, 12),
  y: PropTypes.number,
  w: PropTypes.numberRange(1, 12),
  h: PropTypes.number,
})
