import React, {Component} from 'react'

import SearchableMultiSelect from '../../inputs/SearchableMultiSelect'
import PopOver from '../../PopOver'
import SeelkCheckboxPanel from './Panels/SeelkCheckboxPanel'
import SeelkColorPanel from './Panels/SeelkColorPanel'
import SeelkMenuPanel from './Panels/SeelkMenuPanel'
import SeelkRadioPanel from './Panels/SeelkRadioPanel'

const DROPDOWN_PANEL_TYPES = {
  menu: SeelkMenuPanel,
  color: SeelkColorPanel,
  checkbox: SeelkCheckboxPanel, // Never used
  multiLevel: SearchableMultiSelect, // Never used
  radio: SeelkRadioPanel, // Never used
}

type OptionType = {
  key: string
  value: string
  text: string
  onClick: (value: string) => void
}

type PanelProps = {
  options: OptionType[] | (string | null)[]
  [key: string]: any
}

interface SeelkDropdownProps {
  disabled?: boolean
  onClose?: () => void
  onOpen?: () => void
  panelProps: PanelProps
  panelType: keyof typeof DROPDOWN_PANEL_TYPES
  popOverPosition?: 'left' | 'right'
  trigger: React.ReactNode
  triggered?: boolean | null
  outsideWrapper?: Record<string, string> | null
  marginBottom?: number
  alignItems?: string
  display?: string
  containerProps?: Record<string, string>
}

interface SeelkDropdownState {
  triggered: boolean
}

class SeelkDropdown extends Component<SeelkDropdownProps, SeelkDropdownState> {
  constructor(props: SeelkDropdownProps) {
    super(props)
    this.state = {
      triggered: false,
    }
  }

  close = () => {
    this.setState({triggered: false})
  }

  open = () => {
    this.setState({triggered: true})
  }

  render() {
    const {
      disabled = false,
      onClose = null,
      onOpen = null,
      panelProps,
      panelType,
      popOverPosition,
      trigger,
      triggered = null,
      outsideWrapper = null,
      ...additionalProps
    } = this.props

    const DropdownPanel: any = DROPDOWN_PANEL_TYPES[panelType]
    const closeFunction = onClose || this.close
    const openFunction = onOpen || this.open

    return (
      <PopOver
        disabled={disabled}
        onOpen={openFunction}
        onClose={closeFunction}
        trigger={trigger}
        triggered={triggered !== null ? triggered : this.state.triggered}
        marginBottom="0.714em"
        {...additionalProps}
        outsideWrapper={outsideWrapper}
        popOverPosition={popOverPosition}
      >
        <DropdownPanel {...panelProps} onSelect={closeFunction} />
      </PopOver>
    )
  }
}

export default SeelkDropdown
