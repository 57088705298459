import React, {Component} from 'react'

import {Box, Col, Row} from 'jsxstyle'
import PropTypes from 'prop-types'
import {Icon, Input} from 'semantic-ui-react'

import colors from '../../utils/colors'
import {SeelkButton} from '../../utils/common-ui'
import i18n from '../../utils/i18n'
import {ClickableCell, sortName, VirtualTable} from '../../utils/visualization'

const EditButton = ({extra, item}) => {
  const {handleClick} = extra
  return <SeelkButton type="confirm" content={i18n.t('actions.global.edit')} onClick={() => handleClick(item)} />
}

EditButton.propTypes = {
  extra: PropTypes.shape({
    handleClick: PropTypes.func.isRequired,
  }),
  item: PropTypes.object.isRequired,
}

EditButton.defaultProps = {
  extra: {
    handleClick: () => {},
  },
}

class AdvertisersTable extends Component {
  static propTypes = {
    advertisers: PropTypes.arrayOf(PropTypes.object),
    selectedAdvertisers: PropTypes.object.isRequired,
    updateSelectedAdvertisers: PropTypes.func.isRequired,
    editAdvertiser: PropTypes.func.isRequired,
  }

  static defaultProps = {
    advertisers: [],
  }

  columnsProvider = i18n.createProvider('visualization.columns')

  constructor(props) {
    super(props)

    this.COLUMNS = {
      name: {
        label: this.columnsProvider('name'),
        key: 'advertiser_name',
        width: 400,
        component: ClickableCell,
        style: {fontWeight: 500},
        canGrow: true,
        canShrink: true,
        sort: sortName,
        props: {
          handleClick: props.editAdvertiser,
          hoverStyle: {
            color: colors.primary.rgba,
          },
        },
      },
      id: {
        label: this.columnsProvider('id'),
        key: 'advertiser_id',
        width: 100,
        canGrow: true,
        canShrink: true,
        sort: sortName,
      },
      edit: {
        label: '',
        key: 'id',
        width: 100,
        canGrow: true,
        canShrink: true,
        component: EditButton,
        props: {
          handleClick: props.editAdvertiser,
        },
      },
    }
    this.state = {
      searchQuery: '',
    }
  }

  handleSearchChange = ({target}) => {
    this.setState({searchQuery: target.value})
  }

  render() {
    const {advertisers} = this.props

    return (
      <Box height="96%" padding="16px 8px 8px 8px">
        <Row alignItems="center">
          <Input
            icon="search"
            iconPosition="left"
            placeholder={i18n.t('actions.search.search')}
            onChange={this.handleSearchChange}
          />
        </Row>
        {advertisers.length > 0 ? (
          <VirtualTable
            items={advertisers.filter(
              advertiser =>
                advertiser.advertiser_id.toLowerCase().includes(this.state.searchQuery.toLowerCase()) ||
                advertiser.advertiser_name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
            )}
            columns={Object.values(this.COLUMNS)}
            headerHeight={50}
            rowHeight={60}
            overscanRowCount={1}
            shouldSplitColor={false}
            highlightSelection
            overflowX
            updateSelectedItems={this.props.updateSelectedAdvertisers}
            selectedItems={this.props.selectedAdvertisers}
            rowClassName="ReactVirtualized__Table__rowColumn__actionable"
          />
        ) : (
          <Col justifyContent="center" alignItems="center" height={300} margin="auto">
            <Icon name="box" size="massive" style={{color: colors.border.rgba}} />
          </Col>
        )}
      </Box>
    )
  }
}

export default AdvertisersTable
