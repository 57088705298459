import React from 'react'

import moment, {Moment} from 'moment'

import BodyWidget from './BodyWidget'
import {MODES} from './constants'
import HeaderWidget from './HeaderWidget'
import {modeIsLowerThan} from './utils'

interface DatePickerPanelProps {
  onChange: (value: Moment) => void
  dateRender?: (...arg: any) => any
  disabledDate?: (current: Moment, value?: Moment) => boolean
  enableNext?: boolean
  enablePrev?: boolean
  endDate?: Moment | null
  hoverValue?: Moment | null
  mode?: typeof MODES[number]
  offset?: number
  onHoverChange?: (value: Moment | null) => void
  prefixCls?: string
  selectMode?: 'start' | 'end' | 'single'
  showWeekNumber?: boolean
  startDate?: Moment | null
  value?: Moment
}

interface DatePickerPanelState {
  offset: number
  mode: string | undefined
}

export default class extends React.Component<DatePickerPanelProps, DatePickerPanelState> {
  static defaultProps = {
    prefixCls: 'datePicker',
    dateRender: undefined,
    disabledDate: undefined,
    showWeekNumber: true,
    enablePrev: undefined,
    enableNext: undefined,
    offset: undefined,
    value: moment.utc(),
    hoverValue: null,
    onHoverChange: undefined,
    mode: 'day',
    selectMode: 'single',
    startDate: null,
    endDate: null,
  }

  state = {
    offset: 0,
    mode: undefined,
  }

  UNSAFE_componentWillMount() {
    this.setState({mode: this.props.mode})
    if (this.props.offset !== undefined) this.setState({offset: this.props.offset})
  }

  UNSAFE_componentWillReceiveProps(nextProps: DatePickerPanelProps) {
    if (this.props.mode !== nextProps.mode) this.setState({mode: nextProps.mode, offset: nextProps.offset || 0})
    if (
      this.props.value !== nextProps.value ||
      this.props.startDate !== nextProps.startDate ||
      this.props.endDate !== nextProps.endDate
    )
      this.setState({offset: nextProps.offset || 0})
    if (this.props.offset !== nextProps.offset && nextProps.offset !== undefined)
      this.setState({offset: nextProps.offset})
  }

  handleModeChange = (mode: typeof MODES[number]) => {
    this.setState({mode, offset: 0})
  }

  handleOffsetChange = (offset: number) => {
    this.setState({offset})
  }

  handleChange = (change: Moment) => {
    const {mode = 'day', value = moment.utc()} = this.props
    if (modeIsLowerThan(mode, this.state.mode || 'day')) {
      const lowerMode =
        // eslint-disable-next-line no-nested-ternary
        this.state.mode === 'year' && mode === 'quarter' ? 'quarter' : this.state.mode === 'year' ? 'month' : mode
      const offset = change.diff(value.clone().startOf(this.state.mode || 'day'), this.state.mode)
      this.setState({mode: lowerMode, offset})
    } else {
      this.props.onChange(change)
    }
  }

  render() {
    const {
      prefixCls = 'datePicker',
      dateRender = undefined,
      disabledDate = undefined,
      showWeekNumber = true,
      enablePrev = undefined,
      enableNext = undefined,
      value = moment.utc(),
      hoverValue = null,
      onHoverChange = undefined,
      mode = 'day',
      selectMode = 'single',
      startDate = null,
      endDate = null,
    } = this.props
    return (
      <div className={`${prefixCls}Panel`}>
        <HeaderWidget
          prefixCls={prefixCls}
          onChangeMode={this.handleModeChange}
          onChangeOffset={this.handleOffsetChange}
          enablePrev={enablePrev}
          enableNext={enableNext}
          mode={this.state.mode || 'day'}
          offset={this.state.offset}
          value={value}
        />
        <BodyWidget
          prefixCls={prefixCls}
          onChange={this.handleChange}
          selectMode={this.state.mode === mode ? selectMode : 'single'}
          offset={this.state.offset}
          mode={this.state.mode}
          value={value}
          hoverValue={hoverValue}
          onHoverChange={onHoverChange}
          startValue={startDate}
          endValue={endDate}
          showWeekNumber={showWeekNumber}
          disabledDate={disabledDate}
          dateRender={dateRender}
        />
      </div>
    )
  }
}
