export default {
  index: {
    average: {
      days: 'Daily AVG.',
      months: 'Monthly AVG.',
      weeks: 'Weekly AVG.',
    },
    chartName: 'Chart Name',
    chartType: 'Chart Type',
    ctg: 'CTG',
    currentYear: 'Current Year',
    datamart: 'Datamart',
    dateRange: 'Date Range',
    dateRanges: 'Date Ranges',
    diff: 'Diff.(%)',
    displayWidget: 'Display Widget',
    exportName: 'Export File Name',
    filters: 'Filters',
    grouping: 'Grouping',
    groupingCount: '{{grouping}} ({{count}})',
    horizontalAxis: 'Horizontal Axis',
    last: {
      days: 'Last Day',
      months: 'Last Month',
      weeks: 'Last Week',
    },
    lastYear: 'Last Year',
    metrics: 'Metrics',
    negativeGrowth: 'Negative Growth',
    positiveGrowth: 'Positive Growth',
    templateName: 'Template Name',
    type: 'Type',
    verticalAxis: 'Vertical Axis',
  },
  seller: {
    index: {
      avgBasket: 'Average Basket',
      avgUnitsPerOrders: 'AVG. Units / Order',
      nbOrders: 'Nb. Orders',
      orders: 'Orders',
      sales: 'Sales',
      salesMetrics: 'Sales metrics',
      sellerDataFile: 'Seller-Data-BI',
      shipping: 'Shipping Costs',
      stockPrediction: 'Stock Prediction',
      topGraphTitle: 'Units Sold & Sales',
      total: 'Total',
      totalPrice: 'Total Sales',
      totals: 'Totals',
      units: 'Units',
      unitsSold: 'Units Sold',
    },
    stockPredictions: {
      popover: 'Get Stock Recommandations',
      title: 'Stock Recommandations',
      placeholder: 'Select your warehouse country',
      button: 'Export as Excel',
      panEurope: 'Pan Europe',
    },
  },
  vendor: {
    index: {
      acc: 'Acc.',
      accepted: 'Accepted',
      man: 'Man.',
      manufacturing: 'Manufacturing',
      orderedRevenue: 'Ordered',
      pcogs: 'PCoGS',
      rec: 'Rec.',
      received: 'Received',
      sellIn: 'Sell-In',
      sellOut: 'Sell-Out',
      shippedRevenue: 'Shipped',
      sourcing: 'Sourcing',
      sourc: 'Sourc.',
      sourcingManufacturingShare: 'Sell-Out Sourcing VS Sell-Out Manufacturing',
      sub: 'Ord.',
      submitted: 'Submitted',
      total: 'Total',
      totals: 'Totals',
      vendorDataFile: 'Vendor-Data-BI',
    },
    reports: {
      available: 'Available',
      goTo: 'Go to data tracker',
      inventory_health: 'Inventory Health',
      lastUpdated: 'Last Updated',
      nbAsins: 'asins',
      shipped_cogs_manufacturing: 'Shipped COGS (Manufacturing)',
      shipped_cogs_sourcing: 'Shipped COGS (Sourcing)',
      status: 'Report Status',
      title: 'Data Integrity Tracker',
      totalUnits: 'Total Units',
      unavailable: 'Unavailable',
    },
  },
  tooltips: {
    A: 'Period A',
    B: 'Period B',
    bps: 'Basis Points',
    ctg: 'Contribution to Growth',
    ctgPercent: 'Contribution to Growth (%)',
    diff: 'Absolute Difference',
    prevYear: 'Previous Year',
    yoy: 'Year over Year',
    yoyDiff: 'Year over Year (Absolute Difference)',
    yoyPercent: 'Year over Year (%)',
    yoyPts: 'Year over Year (points)',
    ytd: 'Year to Date',
  },
}
