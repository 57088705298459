import {jsonFetch} from 'easyfetch'
import Cookies from 'universal-cookie'

import config from '../../../../config'
import KaioApiClient from '../KaioApiClient/KaioApiClient'

const BULMA = `https://${config.bulmaDomain}`
const BULMA_API = `https://${config.bulmaDomain}/api/v1`

export default class BulmaApiClient {
  constructor() {
    if (!BulmaApiClient.instance) {
      BulmaApiClient.instance = this
    }
    return BulmaApiClient.instance
  }

  async request(rType, url, useTenant = true) {
    const token = await new KaioApiClient().getToken()
    const user = await new KaioApiClient().getUserFromToken()
    let tenant = user.client
    if (user.account.toLowerCase() === 'seelk') tenant = new Cookies().get('account')
    const req = rType(`${BULMA_API}/${url}`)
    const headers = {Authorization: `Bearer ${token}`, 'X-Seelk-Tenant': useTenant ? tenant : 'public'}
    req.setHeaders(headers)
    return req
  }

  async checkLive() {
    try {
      await jsonFetch(`${BULMA}/health/`).get()
      return true
    } catch (error) {
      if (error.code === 502) return false
      return true
    }
  }

  async userPipeline(params) {
    return (await this.request(jsonFetch, `user_pipeline/`)).post(params)
  }
}
