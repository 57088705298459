export default {
  actions: {
    addBackground: 'Add Background',
    addDashboard: 'Add a dashboard',
    addDashboardTo: 'Add a dashboard to ',
    addFavorite: 'Add to Favorites',
    addNewFolder: 'Add New Folder',
    addMetricPanel: 'Add Metric Panel',
    addProducts: 'Add Products',
    addSection: 'Add Section',
    addTab: 'Add tab',
    addToChart: 'Add to Chart',
    addToFolder: 'Add to folder',
    addWidget: 'Add Widget',
    changeFolder: 'Change folder',
    clearClipboard: 'Clear clipboard',
    confirmRemove: 'Are you sure you want to delete this {{item}}?',
    createGraph: 'Create Graph',
    createNewFolder: 'Create New Folder',
    createTable: 'Create Table',
    copyAsins: 'Copy Asins',
    copyAsDashboard: 'Copy as Dashboard',
    copyOrView: 'Copy or view ',
    copySection: 'Copy Section',
    copyWidget: 'Copy Widget',
    copyToClipboard: 'Copy to clipboard',
    deleteFolder: 'Delete Folder',
    deleteMetric: 'Delete Metric',
    deltaDisplay: 'Select delta display',
    displayHeader: 'Display Column Header Value',
    displayMode: 'Select display mode',
    drag: 'Drag',
    editBackground: 'Edit Background',
    editFolder: 'Edit Folder',
    editGraph: 'Edit Graph',
    hideHeader: 'Hide header',
    hideSectionTitle: 'Hide section title',
    new: 'New {{type}}',
    newFolderName: 'New Folder Name',
    pasteSection: 'Paste Section',
    pasteWidget: 'Paste Widget',
    preview: 'Preview',
    remove: 'Remove {{item}}',
    removeFromChart: 'Remove from Chart',
    removeFromFolder: 'Remove from the folder',
    removePanel: 'Remove Panel',
    removeSection: 'Remove Section',
    removeFavorite: 'Remove from Favorites',
    removeTab: 'Remove Tab',
    selectDashboard: 'Select a dashboard',
    selectFolder: 'Select a folder',
    selectMetric: 'Select metric',
    saveAsDashboard: 'Save as Dashboard',
    saveAsTemplate: 'Save as Template',
    show: 'Show',
    showHeader: 'Show header',
    showSectionTitle: 'Show section title',
    sort: 'Sort',
    sortGraph: 'Sort Graph',
    sortTable: 'Sort Table',
    splitByCountries: 'Split by Countries',
    stickyColumn: 'Sticky Column',
    successDashboardCreate: 'Your Dashboard was successfully created',
    successTemplateCreate: 'Your Template was successfully created',
    toggleComparison: 'Toggle Comparison',
    toggleCtg: 'Toggle CTG',
    toggleDelta: 'Toggle Delta',
    toggleLatest: 'Toggle Latest Snapshot',
    toggleSticky: 'Toggle Sticky',
    toggleVisibility: 'Toggle Visibility',
    view: 'View',
  },
  createdBy: 'Created on {{date}} at {{time}}',
  comparison: {
    prevYear: 'YOY',
    prevPeriod: 'PP',
    custom: '[{{value}}]',
  },
  dashboard: 'Dashboard',
  dashboards: 'Dashboards',
  dimensionAttributeNames: {
    ads_ad_group_name: 'Ad Group Name',
    dsp_advertiser_name: 'Advertiser Name',
    ads_campaign_name: 'Campaign Name',
    dsp_creative_name: 'Creative Name',
    dsp_line_item_name: 'Line Item Name',
    ads_profile_name: 'Profile Name',
    dsp_order_name: 'Order Name',
    offer_latest_title: 'Title',
  },
  dimensions: {
    ADVERTISER: 'Advertisers',
    AD_GROUP: 'Ad Groups',
    AMAZON: 'Amazon',
    CAMPAIGN: 'Campaigns',
    CREATIVE: 'Creatives',
    KEYWORD: 'Keywords',
    LINE_ITEM: 'Line Items',
    MARKETPLACE: 'Marketplaces',
    OFFER: 'Products',
    ORDER: 'Orders',
    PROFILE: 'Profiles',
    SEARCH_TERM: 'Search Terms',
    VENDOR: 'Vendors',
    all: 'All',
  },
  dimensionsNumber: {
    ADVERTISER: {one: 'One Advertiser', other: '{{count}} Advertisers', zero: 'No Advertiser'},
    AD_GROUP: {one: 'One Ad Group', other: '{{count}} Ad Groups', zero: 'No Ad Group'},
    CAMPAIGN: {one: 'One Campaign', other: '{{count}} Campaigns', zero: 'No Campaign'},
    CREATIVE: {one: 'One Creative', other: '{{count}} Creatives', zero: 'No Creative'},
    LINE_ITEM: {one: 'One Line Item', other: '{{count}} Line Items', zero: 'No Line Item'},
    OFFER: {one: 'One Product', other: '{{count}} Products', zero: 'No Product'},
    ORDER: {one: 'One Order', other: '{{count}} Orders', zero: 'No Order'},
  },
  dimensionAttributes: {
    ads_profile_model: 'Profile model',
    ads_profile_name: 'Profile name',
    offer_image_check_frequency: 'Image check frequency',
    offer_is_archived: 'Is archived',
    offer_latest_average_rating: 'Latest average rating',
    offer_latest_brand: 'Latest brand',
    offer_latest_bullet_points: 'Latest bullet points',
    offer_latest_buy_box_currency: 'Latest buy box currency',
    offer_latest_buy_box_health_score: 'Latest buy box health score',
    offer_latest_buy_box_merchant_id: 'Latest buy box merchant id',
    offer_latest_buy_box_owner: 'Latest buy box owner',
    offer_latest_buy_box_price: 'Latest buy box price',
    offer_latest_catalogue_health_score: 'Latest catalogue health score',
    offer_latest_description: 'Latest description',
    offer_latest_finest_breadcrumbs_category__category_id: 'Latest finest breadcrumbs category category id',
    offer_latest_finest_breadcrumbs_category__name: 'Latest finest breadcrumbs category name',
    offer_latest_finest_sales_category__category_id: 'Latest finest sales category category id',
    offer_latest_finest_sales_category__name: 'Latest finest sales category name',
    offer_latest_finest_sales_category__ranking: 'Latest finest sales category ranking',
    offer_latest_has_a_plus: 'Latest has a plus',
    offer_latest_has_active_buy_box: 'Latest has active buy box',
    offer_latest_has_stock: 'Latest has stock',
    offer_latest_has_vendor_offer: 'Latest has vendor offer',
    offer_latest_image_urls: 'Latest image urls',
    offer_latest_is_404: 'Latest is 404',
    offer_latest_is_buy_box_owner: 'Latest is buy box owner',
    offer_latest_is_fast_track: 'Latest is fast track',
    offer_latest_is_fresh: 'Latest is fresh',
    offer_latest_is_pantry: 'Latest is pantry',
    offer_latest_is_premium_beauty: 'Latest is premium beauty',
    offer_latest_is_prime: 'Latest is prime',
    offer_latest_is_prime_only: 'Latest is prime only',
    offer_latest_is_professional_beauty: 'Latest is professional beauty',
    offer_latest_is_sns: 'Latest is sns',
    offer_latest_max_price: 'Latest max price',
    offer_latest_menu_category: 'Latest menu category',
    offer_latest_min_price: 'Latest min price',
    offer_latest_nb_1_star: 'Latest nb 1 star',
    offer_latest_nb_2_star: 'Latest nb 2 star',
    offer_latest_nb_3_star: 'Latest nb 3 star',
    offer_latest_nb_4_star: 'Latest nb 4 star',
    offer_latest_nb_5_star: 'Latest nb 5 star',
    offer_latest_nb_answered_questions: 'Latest nb answered questions',
    offer_latest_nb_fba_sellers: 'Latest nb fba sellers',
    offer_latest_nb_images: 'Latest nb images',
    offer_latest_nb_mfn_sellers: 'Latest nb mfn sellers',
    offer_latest_nb_ratings: 'Latest nb ratings',
    offer_latest_nb_sellers: 'Latest nb sellers',
    offer_latest_nb_videos: 'Latest nb videos',
    offer_latest_parent_asin: 'Latest parent asin',
    offer_latest_preorder_available: 'Latest preorder available',
    offer_latest_recomputed_average_rating: 'Latest recomputed average rating',
    offer_latest_review_health_score: 'Latest review health score',
    offer_latest_scraping_date: 'Latest scraping date',
    offer_latest_title: 'Latest title',
    offer_latest_url: 'Latest url',
    offer_latest_variation_dimensions: 'Latest variation dimensions',
    offer_latest_variation_values: 'Latest variation values',
    offer_latest_vendor_price: 'Latest vendor price',
    offer_latest_video_urls: 'Latest video urls',
    offer_scraping_frequency: 'Scraping frequency',
    offer_section: 'Section',
  },
  errors: {
    dashboardLocked: 'Dashboard Locked',
    disabledByFilters: 'Oops! Widget filters are not working.',
    editLocked: 'This Dashboard is currently being edited by another user!',
    editTemplateLocked: 'This Template is currently being edited by another user!',
    loadData: 'This data cannot be loaded. The selected metrics are not compatible with your section filters.',
    oops: 'OOPS!',
    outOfMemory:
      'The amount of data you are trying to load on this dashboard is not supported by your computer.\nMake sure to use a filter to fluidify the navigation',
    somethingWentWrong: 'Something went wrong!',
    templateLocked: 'Templated Locked',
    widgetFiltersError: 'Check your {{filters}} filters at widget and at section levels',
  },
  filterDimensions: {
    ads: 'Ads',
    dsp: 'DSP',
    product: 'Product',
    searchTerms: 'Search Terms',
  },
  groups: {
    ascending: 'Ascending',
    descending: 'Descending',
    custom: 'Custom',
  },
  headers: {
    budget: 'Add Advetising Budget',
    button: 'Button',
    comparisonScorecard: 'Add Comparison Scorecard',
    classicGraph: 'Add Classic Graph',
    classicTable: 'Add Classic Table',
    dashboardCard: 'Add Dashboard Card',
    distributionScorecard: 'Add Distribution Scorecard',
    editClassicGraph: 'Edit Classic Graph',
    editClassicTable: 'Edit Classic Table',
    editWidget: 'Edit {{widget}}',
    editComparisonScorecard: 'Edit Comparison Scorecard',
    editDashboardCard: 'Edit Card',
    editDistributionScorecard: 'Edit Distribution Scorecard',
    editLink: 'Edit Link',
    editMetricsGroup: 'Edit Multi Metrics Scorecard',
    editMultiMetricsTable: 'Edit Multi Metrics Table',
    editMultiScorecard: 'Edit Scorecards + Graph',
    editOfferCard: 'Edit Product Scorecard',
    editPieChart: 'Edit Pie Chart',
    editScorecard: 'Edit Classic Scorecard',
    editSingleMetricTable: 'Edit Single Metric Table',
    editSpacer: 'Edit Spacer',
    editSov: 'Edit Share of Voice',
    editSovChart: 'Edit Share of Voice Chart',
    editSovTable: 'Edit Share of Voice Keyword Table',
    editSpiderGraph: 'Edit Spider Graph',
    editTargetScorecard: 'Edit Target Scorecard',
    editText: 'Edit Text',
    editTimelineScorecard: 'Edit Timeline Scorecard',
    link: 'Add Link',
    metricsGroup: 'Add Multi Metrics Scorecard',
    multiMetricsTable: 'Add Multi Metrics Table',
    multiScorecard: 'Add Multi-Scorecards + Graph',
    offerCard: 'Add Product Scorecard',
    pieChart: 'Add Pie Chart',
    scorecard: 'Add Classic Scorecard',
    selectElement: 'Add Widget',
    singleMetricTable: 'Add Single Metric Table',
    spiderGraph: 'Spider Graph',
    sov: 'Add Share of Voice',
    sovChart: 'Add Share of Voice Chart',
    sovTable: 'Add Share of Voice Keyword Table',
    spacer: 'Add Spacer',
    targetScorecard: 'Add Target Scorecard',
    text: 'Add Text',
    timelineScorecard: 'Add Timeline Scorecard',
  },
  helper: {
    addAttributes:
      'Attribute columns are available only when selected breakdown is either Products, Campaigns, AdGroups, Advertisers, Orders, Line Items or Creatives.',
    amazonMetricsLexicon: 'Metrics Lexicon',
    breakdownHelper:
      'The available breakdowns are the ones with less than 10 choices available - If you have more than 10, filter in your Section first.',
    checkOut: 'Check out',
    checkOutOur: 'Check out our',
    confirmDelete: 'Are you sure you want to delete',
    confirmRemoveSection: 'Are you sure you want to remove this section from the dashboard?',
    confirmRemoveTab: 'Are you sure you want to remove this tab from the section?',
    copyTemplate: 'Do you want to copy this template as a new dashboard or simply view it?',
    dimensionHelper: 'The dimension will alter the breakdown choices and available metrics.',
    help: 'Help Center',
    helpCenter: 'from our Help Center to learn more!',
    latest: 'Not available with Latest aggregation',
    limitedTo: 'You only see the {{operator}} {{limit}} based on {{metric}}',
    locked: {
      dataLabels: 'Enable the Data Labels to unlock this action',
      datamarts: 'Choose different metrics to unlock this metric',
      filters: 'Choose different filters to unlock this metric',
      grouping: 'Choose a different grouping to unlock this metric',
      period: 'Choose a different period to unlock this metric',
    },
    lockedDimension: 'Choose different filters to unlock this dimension',
    lockedSPSD: 'Choose a SP / SD metric to unlock Products',
    newDashboard: 'You have not created any {{type}}s',
    newDashboardSub: 'Do you want to read a quick how-to on Goten {{type}}s?',
    newSection: 'Add a section to get started!',
    newSectionSub: 'Sections are the structure inside which you can add your data visualisation widgets!',
    newWidget: 'Add a widget to get started!',
    newWidgetSub: 'Widgets are the building blocks for your data visualisation! ',
    noFolder: 'No folder existing, create a new one',
    noFilters: "Section's filters do not apply to this widget",
    noFiltersNoDateRange: "Section's filters and date range do not apply to this widget",
    percentage: 'For percentage metrics only',
    selectProducts: 'Select products from the list to copy ASINs',
    selectProductsToAddToCatalog: 'Select products from the list to add to Catalog',
    shareOfTotal: 'Percentage of total compared to other products or groups in a list',
    startYourself: 'Click below to start yourself on an Amazon analytics adventure!',
    tableItemsCount: 'Total # of table items',
    totalSelectedTableItems: 'Total # selected table items',
    toLearnMore: 'to learn more!',
    weeklyWarning:
      'Be aware that for some metrics the report frequency is weekly. This can lead to an offset for comparison with the previous year with the "Month to Date" period.',
  },
  labels: {
    advanced: 'Advanced',
    absolute: 'Abs',
    addAttributes: 'Select one or more attributes',
    all: 'all',
    allDashboards: 'All Dashboards',
    allowOtherEditors: 'Allow other Editors',
    backgroundImage: 'Background Image',
    bar: 'Bar',
    breakdown: 'Breakdown',
    breakdownCompetition: 'Breakdown vs Competition',
    buttonPreview: 'Button Preview',
    circular: 'Circular',
    classicGraphName: 'Graph Name',
    color: 'Color',
    comparison: 'Comparison',
    comparisonPeriod: 'Add one or more comparison periods',
    count: 'Count',
    country: 'Country',
    currency: 'Currency',
    dashboards: 'Dashboards',
    dashboardCard: 'Select a dashboard to link to',
    dashboardCardFilters: 'Select filters',
    dataVisualization: 'Data Visualization',
    dataLabelToggle: 'Data Labels On/Off',
    dateRange: 'Date Range',
    description: 'Description',
    dimension: 'Dimension',
    dimensions: 'Dimensions',
    displayMetricProportion: 'Display metric proportion',
    folder: 'Folder',
    folders: 'Folders',
    fullWidth: 'Full width',
    halfWidth: 'Half width',
    horizontalAxis: 'Horizontal Axis',
    filters: 'Filters',
    format: 'Currency',
    graphSettings: 'Graph Settings',
    groups: 'Groups',
    icon: 'Icon',
    label: 'Label',
    legendToggle: 'Legend On/Off',
    limitedTo: 'Limited to {{operator}} {{limit}} items',
    linkText: 'Text',
    linkUrl: 'Link to dashboard or paste a URL',
    metrics: 'Metrics',
    metricsGroupName: 'Metrics Group Name',
    metricName: 'Custom Metric Name',
    name: 'Dashboard Name',
    none: 'None',
    percentage: 'Percentage',
    disabledUnitToggleMetric: 'Percentage is disabled in this case: Select just one metric',
    disabledUnitToggleType: 'Percentage is disabled in this case: Choose a column or bar chart type',
    disabledUnitToggleAxis: 'Percentage is disabled in this case: Choose time for the horizontal axis',
    disabledUnitToggleGrouping: 'Percentage is disabled in this case: Select any other grouping than "None"',
    disabledUnitToggleUnit: 'Percentage is disabled in this case: Select another metric (Currently not compatible)',
    disabledUnitToggleStacked: 'Percentage is disabled in this case: Choose stacking option',
    pieChartName: 'Pie Chart name',
    points: 'Absolute',
    productTitleToggle: 'Product Title On/Off',
    publicEdit: 'Public Edit',
    publicView: 'Public View',
    exportName: 'Export Name',
    rowDisplay: 'Row Display',
    scorecardName: 'Scorecard name',
    searchKeywordOrTags: 'Search Keyword or tags',
    selectAggregation: 'Aggregation',
    selectCountry: 'Select a country',
    selectDelta: 'Delta',
    selectDisplay: 'Display',
    selectMetric: 'Select a metric',
    selectMetrics: 'Select Metrics',
    selectMoreMetrics: 'Select one or more metrics',
    selectOffer: 'Select Offer',
    selectXAxis: 'Select x-Axis',
    settings: 'Dashboard Settings',
    sovChartName: 'Chart Name',
    sovType: 'Share of Voice Type',
    stacking: 'Stacking',
    tableName: 'Table Name',
    tableSettings: 'Table Settings',
    tags: 'Tags',
    target: 'Set Target',
    targetName: 'Target Name',
    targetSettings: 'Target Settings',
    text: 'Enter your text',
    textAlignment: 'Text alignment',
    textSize: 'Text size',
    timePeriod: 'Time Period',
    totalHeight: 'Total page height',
    updateColor: 'Update color of ',
    value: 'Value',
    widgetSize: 'Widget Size (width in browser)',
    type: 'Type',
  },
  lastUpdate: 'Last update on {{date}} at {{time}}',
  loaders: {
    loadingDasboard: 'Loading Dashboard',
    loadingTemplate: 'Loading Template',
  },
  menus: {
    myDashboards: 'My Dashboards',
    myFavorites: 'My Favorites',
    templates: 'Templates',
  },
  metrics: {
    in_stock_units_seller: 'In Stock Seller Units',
    in_stock_units_vendor: 'In Stock Vendor Units',
    in_transit_units_seller: 'In Transit Seller Units',
    in_transit_units_vendor: 'In Transit Vendor Units',
    pcogs_manufacturing_units: 'Manufacturing (Shipped / Ordered Units)',
    pcogs_manufacturing_value: 'PCOGS Manufacturing Sales',
    pcogs_sourcing_units: 'Sourcing Units',
    pcogs_sourcing_value: 'PCOGS Sourcing Sales',
    pcogs_outsourcing_value: 'Sourcing vs Manufacturing',
    po_units_opened: 'Sell-In Units',
    seller_sales_ht: 'Seller Sales',
    seller_sales_ttc: 'Seller Sales',
    seller_shipping_costs: 'Shipping Costs',
    seller_sold_units: 'Seller Units',
    sell_in_sales: 'Sell-In Sales',
    average_rating: 'Product Review Rating',
    avg_price: 'Avg. BuyBox Price',
    buy_box_health_score: 'Product Offer Health Score',
    buy_box_price: 'Buy Box Price',
    catalogue_health_score: 'Product Content Score',
    has_a_plus: 'Active A+ content',
    has_active_buy_box: 'Buy Box Active',
    has_stock: 'Offers In Stock',
    has_vendor_offer: 'Vendor Offer Active',
    is_404: 'Inactive Product',
    is_add_on_item: 'Add-on Offer',
    is_buy_box_owner: 'Buy Box Owner',
    is_fast_track: 'Fast Track',
    is_fresh: 'Amazon Fresh',
    is_pantry: 'Amazon Pantry',
    is_prestige: 'Amazon Prestige',
    is_prime: 'Prime Offer',
    is_prime_only: 'Prime Only',
    is_sns: 'Subscribe & Save',
    max_price: 'Maximum Price',
    min_price: 'Minimum Price',
    nb_1_star: '# 1 Star Reviews',
    nb_2_star: '# 2 Star Reviews',
    nb_3_star: '# 3 Star Reviews',
    nb_4_star: '# 4 Star Reviews',
    nb_5_star: '# 5 Star Reviews',
    nb_answered_questions: '# Q&A',
    nb_color_variations: '# Color Variations',
    nb_fba_sellers: '# 2P Sellers',
    nb_images: '# Images',
    nb_mfn_sellers: '# 3P Sellers',
    nb_reviews: '# Reviews',
    nb_sellers: 'Total # Sellers',
    nb_size_variations: '# Sizes',
    nb_style_variations: '# Styles',
    nb_videos: '# Videos',
    overall_score: 'Overall Score',
    preorder_available: 'Preorder Available',
    review_health_score: 'Reviews Score',
    smallest_category_ranking: 'Avg. Category Ranking',
    vendor_price: 'Vendor Price',
    organic: 'Organic Share of Voice',
    sponsoredBrands: 'Sponsored Brands Share of Voice',
    sponsoredProducts: 'Sponsored Products Share of Voice',
    po_closed_acceptance_rate: 'Acceptance Rate Closed POs',
    po_confirmed_acceptance_rate: 'Acceptance Rate Open POs',
    po_both_acceptance_rate: 'Total Acceptance Rate',
    po_both_fill_in_rate: 'Total Fill-In Rate',
    po_closed_fill_in_rate: 'Fill-In Rate Closed POs',
    po_confirmed_fill_in_rate: 'Fill-In Rate Open POs',
    po_closed_submitted_units: 'Submitted Units Closed POs',
    po_confirmed_submitted_units: 'Submitted Units Open POs',
    po_both_submitted_units: 'Total Submitted Units',
    po_closed_received_units: 'Received Units Closed POs',
    po_confirmed_received_units: 'Received Units Open POs',
    po_both_received_units: 'Total Received Units',
    po_closed_total_cost: 'Sell-In Sales Closed POs',
    po_confirmed_total_cost: 'Sell-In Sales Open POs',
    po_both_total_cost: 'Total Sell-In Sales',
    po_closed_missed_sales: 'Refused Closed POs Value',
    po_confirmed_missed_sales: 'Refused Opened POs Value',
    po_both_missed_sales: 'Refused POs Value',
    po_closed_accepted_units: 'Accepted Units Closed POs',
    po_confirmed_accepted_units: 'Accepted Units Open POs',
    po_both_accepted_units: 'Total Accepted Units',
    po_closed_outstanding_units: 'Outstanding Units Closed POs',
    po_confirmed_outstanding_units: 'Outstanding Unites Open POs',
    po_both_outstanding_units: 'Total Outstanding Units',
    po_closed_unit_cost: 'Unit Cost Closed POs',
    po_closed_submitted_cases_cost: 'Submitted Cases Cost Closed POs',
    po_closed_accepted_cases_cost: 'Accepted Cases Cost Closed POs',
    po_closed_received_cases_cost: 'Received Cases Cost Closed POs',
    po_closed_outstanding_cases_cost: 'Outstanding Cases Cost Closed POs',
    po_confirmed_unit_cost: 'Unit Cost Open POs',
    po_confirmed_submitted_cases_cost: 'Submitted Cases Cost Open POs',
    po_confirmed_accepted_cases_cost: 'Accepted Cases Cost Open POs',
    po_confirmed_received_cases_cost: 'Received Cases Cost Open POs',
    po_confirmed_outstanding_cases_cost: 'Outstanding Cases Cost Open POs',
    po_both_outstanding_cases_cost: 'Total Outstanding Cases Cost',
    po_both_received_cases_cost: 'Total Received Cases Cost',
    po_both_accepted_cases_cost: 'Total Accepted Cases Cost',
    po_both_submitted_cases_cost: 'Total Submitted Cases Cost',
    po_both_unit_cost: 'Total Unit Cost',
    po_confirmed_missed_units: 'Refused Opened POs Units',
    po_both_missed_units: 'Refused POs Units',
    po_closed_missed_units: 'Refused Closed POs Units',
    clicks: 'Clicks',
    conversions: 'Advertising Conversions',
    impressions: 'Impressions',
    sales: 'Advertising Sales',
    spend: 'Advertising Spends',
    units: 'Advertising Units',
    orders: 'Advertising Orders',
    cpo: 'Cost Per Order [CPO]',
    conv_rate: 'Conversion Rate [CVR]',
    avg_cpc: 'Avg. Cost Per Click [CPC]',
    ctr: 'Click-Through Rate (CTR)',
    profile_budget: 'Profile Budget',
    roas: 'ROAS',
    tacos: 'TACOS',
    marketing_dependency: 'Marketing Dependency',
    sellInSales: 'Sell-In Sales',
    sellInUnits: 'Sell-In Units',
    ordered: 'Ordered',
    accepted: 'Accepted',
    missed: 'Refused',
    orderedClosed: 'Ordered Closed',
    orderedOpened: 'Ordered Opened',
    acceptanceRate: 'Acceptance Rate',
    fillInRate: 'Fill-In Rate',
  },
  metricCard: {
    acronym: 'Acronym',
    aggregationType: 'Aggregation Types',
    availableAggregationTypes: 'Available Aggregation Types',
    availableReports: 'Available Reports',
    calculation: 'Calculation',
    description: 'Description',
    defaultAggregation: 'Default Aggregation',
    grade: 'Grade',
    higherIsBetter: 'Higher is Better',
    lowerIsBetter: 'Lower is Better',
    metricType: 'Metric Type',
    notAvailable: 'Metric not available !',
    notAvailableMessage:
      'Data for this metric is only available in weeks or months. Please select a different period aggregation in your sections date range picker.',
    relatedMetrics: 'Related Metrics',
    source: 'Source',
    subsetMetrics: 'Subset Metrics',
    theme: 'Theme',
    themes: 'Themes',
    tiedTo: 'Tied to',
    timeBreakdowns: 'Time Breakdowns',
    unitType: 'Unit Type',
  },
  offerView: {
    tabs: {
      conversion: 'Conversion',
      logistics: 'Logistics',
      pricing: 'Pricing',
      sales: 'Sales',
      visibility: 'Visibility',
    },
    sections: {
      buyBoxVsPcogsManufacturing: 'Avg. Buy Box Price vs. PCOGS Manufacturing',
      buyability: 'Buyability',
      clicksSpends: 'Clicks & Spends',
      content: 'Content',
      listingQuality: 'Listing Quality & Category Ranking',
      manufacturingAdvertising: 'Manufacturing & Advertising',
      performance: 'Performance',
      reviews: 'Reviews',
      roasAdsSales: 'ROAS & Advertising Sales',
      roasRevenue: 'ROAS & Revenue',
      spendsTraffic: 'Spends & Traffic',
      tacosDependency: 'TACOS & Advertising Dependency',
      vendorSales: 'Vendor Sales',
    },
  },
  panels: {
    GraphBuilder: 'Custom Graph',
    basicGraph: 'Graph',
    basicMetric: 'Classic Scorecard',
    basicTable: 'Table',
    button: 'Button',
    distributionMetric: 'Distribution Scorecard',
    link: 'Link',
    metricsGroup: 'Metrics Group',
    comparisonMetric: 'Comparison Scorecard',
    offerCard: 'Offer Card',
    repartition: {
      one: 'One product with a rate of {{score}}',
      other: '{{count}} products with a rate of {{score}}',
    },
    repartitionPrice: {
      one: 'One product with a price between {{score}}{{currency}}',
      other: '{{count}} products with a price between {{score}}{{currency}}',
    },
    multiMetricsTable: 'Multi Metrics Table',
    multiScorecard: 'Scorecards + Graph',
    pieChart: 'Pie Chart',
    singleMetricTable: 'Single Metric Table',
    spacer: 'Spacer',
    sov: 'Share of Voice',
    spiderGraph: 'Spider Graph',
    target: 'Target',
    text: 'Text',
    timelineMetric: 'Timeline Scorecard',
  },
  periods: {
    day: 'Days',
    last90Days: 'L90D',
    l30d: 'L30D',
    l90d: 'L90D',
    month: 'Months',
    pp: 'PP',
    prev: 'PREV',
    week: 'Weeks',
    week7: 'Weeks',
    yoy: 'YOY',
    ytd: 'YTD',
    prevYear: 'YOY',
    prevPeriod: 'PP',
    custom: '[{{value}}]',
  },
  placeholders: {
    buttonName: 'Display name for your button...',
    classicGraphName: 'Give your graph a name...',
    columnMetrics: 'Select metrics as columns...',
    comparisonPlaceholder: 'Select a period...',
    dashboardCard: 'Search and select a dashboard...',
    dashboardTitle: 'Your Dashboard title...',
    dataMetric: 'Data Metric',
    description: "Enter your dashboard's description",
    dimensions: 'Select a dimension...',
    dimensionAttributes: 'Add dimension attributes...',
    enterUrl: 'Enter URL...',
    groupName: 'Give your metrics group a name...',
    limitMetric: 'Display {{order}} items based on: {{metricName}}',
    linkText: 'Give your link a name...',
    linkUrls: 'Select a dashboard or insert URL...',
    metricName: 'Rename the metric you selected...',
    name: "Enter your dashboard's name",
    noMetrics: 'No available metrics',
    operator: 'Select an operator...',
    pieChartName: 'Give your pie chart a name...',
    exportName: 'Give your export a name...',
    selectAction: 'Select action...',
    selectDashboard: 'Select Dashboard...',
    scorecardName: 'Give your scorecard a name...',
    scrollWidgetFocus: 'Your data is loading and will be available shortly',
    searchGroups: 'Search Groups',
    searchIcon: 'Search icon by name...',
    searchOrSelectMetric: ' Search or select a metric...',
    sectionTitle: 'Section title...',
    selectBreakdown: 'Select a breakdown...',
    selectCountry: 'Select a country...',
    selectDimension: 'Select a dimension...',
    suggestedImageResolution: 'Suggested Resolution {{width}}px X {{height}}px',
    tableName: 'Give your table a name...',
    tabTitle: 'Tab title...',
    target: 'Set target amount...',
    targetName: 'Give your target a name...',
    textInput: 'Add your text here...',
    timePeriod: 'Select time period',
  },
  template: 'Template',
  toasters: {
    successCopyDashboard: 'Your Dashboard was successfully saved as a Template',
    successCopyTemplate: 'Your Template was successfully copied as a Dashboard',
    successCopySection: 'Your Section was successfully copied',
    successCopyWidget: 'Your Widget was successfully copied',
    successDeleteDashboard: 'Your Dashboard was successfully deleted',
    successDeleteTemplate: 'Your Template was successfully deleted',
    successSaveDashboard: 'Your Dashboard was successfully saved',
    successSaveTemplate: 'Your Template was successfully saved',
  },
  untitledDashboard: 'Untitled Dashboard',
  untitledSection: 'Untitled Section',
  untitledTab: 'Untitled Tab',
  untitledTemplate: 'Untitled Template',
  viewers: {
    more: 'More ({{count}})',
  },
  widgetNames: {
    SoVChart: 'Share of Voice Chart',
    SoVTable: 'Share of Voice Keyword Table',
    averageScorecard: 'Average Scorecard',
    button: 'Button',
    card: 'Card',
    classicGraph: 'Classic Graph',
    classicScorecard: 'Classic Scorecard',
    classicTable: 'Classic Table',
    comparisonScorecard: 'Comparison Scorecard',
    distributionScorecard: 'Distribution Scorecard',
    mapChart: 'Map Chart',
    multiMetricsScorecard: 'Multi Metrics Scorecard',
    multiMetricsTable: 'Multi Metrics Table',
    pieChart: 'Pie Chart',
    productScorecard: 'Product Scorecard',
    scorecardsAndGraph: 'Scorecards + Graph',
    singleMetricTable: 'Single Metric Table',
    spacer: 'Spacer',
    spiderGraph: 'Spider Graph',
    targetScorecard: 'Target Scorecard',
    text: 'Text',
    timelineScorecard: 'Timeline Scorecard',
    widget: 'Widget',
  },
  widgets: {
    button: {
      actions: {
        addAlert: 'Add Alert',
        addAmzAccount: 'Add Amazon Account',
        addAttribute: 'Add Attribute',
        addKeywords: 'Add Keywords',
        addProducts: 'Add Products',
        addTags: 'Add Tags',
        createExport: 'Create Export',
        inviteUser: 'Invite User',
      },
      types: {
        action: 'Action',
        dashboardLink: 'Dashboard',
        externalLink: 'External Link',
      },
    },
    graph: {
      axis: {
        xAxis: 'xAxis',
        yAxis: 'yAxis',
      },
      horizontalAxis: 'Horizontal Axis',
      settings: {
        advancedSettings: 'Advanced Settings',
        stackedSeries: 'Stacked series',
        comparePast: 'Compare to past',
        breakdown: 'Breakdown',
      },
      stacking: 'Stacking',
      types: {
        line: 'Line',
        bar: 'Bar',
        area: 'Area',
        column: 'Column',
      },
      xAxis: {
        breakdown: 'Breakdown',
        countries: 'Countries',
        date: 'Date',
        time: 'Time',
      },
    },
    sov: {
      breakdown: {
        catalog: 'My Catalog',
        brands: 'Brands',
      },
      byFilter: {
        keywords: 'Keywords',
        tags: 'Tags',
      },
      kind: {
        natural: 'Organic',
        hsa: 'Sponsored Brands',
        sp: 'Sponsored Products',
      },
      keywordsSelected: {
        one: 'Keyword Selected ({{count}})',
        other: 'Keywords Selected ({{count}})',
        zero: 'Select Keywords',
      },
      selectKeywords: 'Search & select keywords',
      selectTags: 'Search & select tags',
      tags: {
        matchingType: {
          and: 'AND',
          or: 'OR',
        },
      },
      types: {
        donutChart: 'Donut Chart',
        timelineChart: 'Timeline Chart',
      },
      settings: {
        displaySettings: 'Display Settings',
        toggleAll: 'All',
        toggleChartLegend: 'Chart Legend',
        toggleCountryName: 'Country Name',
        toggleSoVType: 'Share of Voice Type',
        toggleKeywords: 'Keywords',
        toggleTags: 'Tags',
      },
    },
    table: {
      selectAttributes: 'Search & select attributes',
      settings: {
        advancedSettings: 'Advanced Settings',
        comparePast: 'Compare to past',
        deltaColumn: 'Delta Column',
        deltaType: 'Delta Type',
        displayLatestSnapshot: 'Display Latest Snapshot',
        moreOptions: 'More Options',
        showAsin: 'Show ASIN',
        showColors: 'Show conditional coloring',
        showCountryFlagColumn: 'Show country flag column',
        showDelta: 'Show delta',
        showDeltaColor: 'Show period delta coloring',
        showLatestSnapshot: 'Show Latest Snapshot',
        showOverPeriod: 'Period over period delta',
        showProductImage: 'Show product image',
        showTags: 'Show Tags',
        showTotalShare: 'Show share of total',
      },
    },
    target: {
      achieved: 'Achieved',
      anticipated: 'Anticipated',
      expected: 'Expected',
      position: 'Position',
      remaining: 'Remaining',
      status: 'Status',
      target: 'Target',
      timeElapsed: 'Time Elapsed',
      today: 'Today',
      settings: {
        advancedSettings: 'Advanced Settings',
        positiveIfMore: 'Positive if over-achievement',
        shape: 'Shape',
        statusDelta: 'Status delta',
      },
    },
  },
  visualization: {
    num: 'Number',
    bool: 'Yes/No',
    line: 'Timeline',
    progress: 'Progress',
  },
}
