import React from 'react'

import {Box, Col, Row} from 'jsxstyle'
import {Icon} from 'semantic-ui-react'
import styled from 'styled-components'

import {searchInTable} from '../../..'
import colors from '../../../colors'
import {PoppersPopup, SeelkButton, Spacer} from '../../../common-ui'
import DebouncedInput from '../../../common-ui/components/inputs/DebouncedInput'
import i18n from '../../../i18n'
import LoaderWrapper from '../../../requesting/containers/LoaderWrapper'
import Tracking from '../../../Tracking'
import DeepDivePopup from './DeepDivePopup'
import ScrollableGrid from './index'
import SchemesToggles from './SchemesToggles'
import {SchemeType} from './Types'

const StyledCol = styled(Col)`
  &&&&& {
    flex: 1;
    max-height: unset;
    padding: 12px;
    overflow-x: scroll;
    ${props =>
      props.withoutBackground
        ? ''
        : `
        background-color: ${colors.white.rgba};
        border: solid 1px ${colors.border.rgba};
        border-radius: 4px;
        `}
    ${props => (props.borderColor ? `border-left: 5px solid ${props.borderColor};` : '')}
  }
`

const StyledDebouncedInput = styled(DebouncedInput)`
  &&&&& {
    & > input {
      transition: width 0.3s ease, opacity 0.3s ease 0.3s;
      border: unset;
      width: 0px !important;
      padding: 0;
      opacity: 0;
    }
    &&&& > input:focus {
      width: 200px !important;
      padding: 0.67857143em 1em !important;
      opacity: 1;
    }
  }
`

const StyledInputContainer = styled(Row)`
  & {
    align-items: center;
    padding-left: 1em;
    margin-right: 16px;
    :hover {
      border: 1px solid ${colors.shadow.rgba};
      border-radius: 4px;
      &&&& > ${StyledDebouncedInput} > input {
        width: 200px !important;
        padding: 0.67857143em 1em !important;
        opacity: 1;
      }
    }
    &&&& > ${StyledDebouncedInput} > input {
      width: ${props => props.hasValue && '200px !important'};
      padding: ${props => props.hasValue && '0.67857143em 1em !important'};
      opacity: ${props => props.hasValue && 1};
    }
  }
`

const StyledIcon = styled(Icon)`
  &&&& {
    padding: 0;
    margin: 0;
    line-height: 1;
  }
`

interface Position {
  x: number
  y: number
}

interface CommonProps {
  items: any[]
  onCellClickAvailable?: boolean
  scheme: SchemeType[]
  displayDeepDive?: boolean
  updateScheme?: (orderedScheme: Record<string, string>) => void
  displaySearch?: boolean
  indexedCollectionsSlug?: Record<string, any>
  loading?: string[] | string | boolean
  widgetError?: string | null
  grouping: string

  // Grid
  cellSize: number
  headerSize: number
  aggregationScheme?: boolean
  aggregationSize?: number
  autoSize?: boolean
  borderColor?: string
  cellStyle?: Record<string, any>
  defaultSort?: string | null
  defaultSortDir?: 'asc' | 'desc'
  fixedColumnCount?: number
  fixedRowCount?: number
  height?: number
  highlight?: boolean
  highlightedRow?: {key: string; scheme: 'metric'} | null
  orientation?: 'vertical' | 'horizontal'
  overscanColumnCount?: number
  overscanRowCount?: number
  placehold?: boolean
  width?: number

  // Extra Props
  displayTopBar?: boolean
  extraItemsTopBar?: React.ReactNode
  extraSettings?: React.ReactNode
  toolbar?: React.ReactNode
  maxHeightWrapper?: string
  withoutBackground?: boolean

  // Full Width
  displayFullWidthMode?: boolean

  // Deep dive
  attributes?: Record<string, any>
  dimension?: string
  updateConfig?: (
    nextDimension: string,
    dimension: string | undefined,
    group: string,
    grouping: string,
    filter: string
  ) => void
  deepDiveConditionnalAction?: ((key: string) => void) | null
  deepDiveCondition?: string | null
  availableGroupings?: any[]
  updateFilters?: (filters: Record<string, string[]> | {attributes: Record<string, string[]>}) => void
  updateGrouping?: (group: string) => void

  // Tags
  displayTags?: boolean
}

interface WrappedGridState {
  client: Position
  filter: string | null
  nameSearch: string
  visible: boolean
}

type FullWidthModeRequired =
  | {
      displayFullWidthMode?: false
      fullWidthMode?: never
      prefixTrackingFullWidthMode?: never
      updateFullWidthMode?: never
    }
  | {
      displayFullWidthMode: true
      fullWidthMode: boolean
      prefixTrackingFullWidthMode: string
      updateFullWidthMode: (mode: boolean) => void
    }

type SchemeTogglesRequired =
  | {
      displaySchemesToggles: true
      updateScheme: (orderedScheme: Record<string, string>) => void
    }
  | {
      displaySchemesToggles?: false | undefined
    }

type TagsRequired =
  | {
      checkboxStyle?: string | null
      displayTags?: false
      selectedItems?: Record<string, any>
      selectedItemKey?: string
      tagsComponent?: never
      updateSelectedItems?: (items: Record<string, any>) => void
    }
  | {
      checkboxStyle?: string | null
      displayTags: true
      selectedItems: Record<string, any>
      selectedItemKey: string
      tagsComponent: React.ReactNode
      updateSelectedItems: (items: Record<string, any>) => void
    }

type WrappedGridProps = CommonProps & FullWidthModeRequired & SchemeTogglesRequired & TagsRequired

class WrappedGrid extends React.PureComponent<WrappedGridProps, WrappedGridState> {
  constructor(props: WrappedGridProps) {
    super(props)

    this.state = {
      client: {
        // event.clientX/Y
        x: 0,
        y: 0,
      },
      filter: null,
      nameSearch: '',
      visible: false,
    }
  }

  renderSearch() {
    const {grouping, displaySearch = true, indexedCollectionsSlug = {}} = this.props

    return displaySearch ? (
      <StyledInputContainer hasValue={this.state.nameSearch !== ''}>
        <StyledIcon name="search" color="darkLight" width={28} />
        <StyledDebouncedInput
          fluid
          placeholder={
            ['amazonBrands', 'asins', 'asins_marketplaces', 'countries'].includes(grouping)
              ? i18n.t(`visualization.grouping.${grouping}`)
              : indexedCollectionsSlug[grouping] || grouping
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>, {value}: {value: string}) => {
            this.setState({nameSearch: value})
          }}
          value={this.state.nameSearch}
        />
      </StyledInputContainer>
    ) : null
  }

  renderFullWidthMode() {
    const {
      displayFullWidthMode = true,
      fullWidthMode = false,
      prefixTrackingFullWidthMode = '',
      updateFullWidthMode = () => {},
    } = this.props

    return displayFullWidthMode ? (
      <PoppersPopup
        content={i18n.t(`placeholders.global.${fullWidthMode ? 'minimizeView' : 'maximizeView'}`)}
        position="top right"
        trigger={
          <Tracking
            as={SeelkButton}
            trackingEvent={`${prefixTrackingFullWidthMode}/${!fullWidthMode}`}
            actionType="Switcher"
            onClick={() => updateFullWidthMode(!fullWidthMode)}
            type="tool"
            icon={fullWidthMode ? 'compress' : 'expand'}
          />
        }
      />
    ) : null
  }

  onGroupClicked = (group: string, filter: string, nextDimension: string) => {
    const {
      attributes = {},
      dimension,
      grouping,
      updateFilters = () => {},
      updateGrouping = () => {},
      updateConfig,
    } = this.props

    if (updateConfig) updateConfig(nextDimension, dimension, group, grouping, filter)
    else if (Object.keys(attributes).includes(grouping)) updateFilters({attributes: {[grouping]: [filter]}})
    else updateFilters({[grouping]: [filter]})

    updateGrouping(group)

    this.setState({nameSearch: ''})
  }

  itemSelected(event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, cellData: any) {
    const {
      grouping,
      updateFilters = () => {},
      attributes = {},
      deepDiveCondition = null,
      deepDiveConditionnalAction = null,
    } = this.props

    if (grouping === deepDiveCondition) {
      if (deepDiveConditionnalAction) deepDiveConditionnalAction(cellData.key)
      else
        updateFilters({
          [deepDiveCondition]: [cellData.key],
        })
    } else
      this.setState(
        {
          client: {
            x: event.clientX,
            y: event.clientY,
          },
          filter:
            Object.values(attributes)
              ?.flat()
              ?.find(value => value.name === cellData.key)?.slug ?? cellData.key,
        },
        () =>
          this.setState({
            visible: true,
          })
      )
  }

  renderDeepDive() {
    const {availableGroupings, displayDeepDive, grouping} = this.props
    const {client, filter, visible} = this.state

    return displayDeepDive && filter !== null ? (
      <DeepDivePopup
        availableGroupings={availableGroupings}
        grouping={grouping}
        client={client}
        filter={filter}
        onGroupClicked={this.onGroupClicked}
        updateVisible={v => this.setState({visible: v})}
        visible={visible}
      />
    ) : null
  }

  renderTags() {
    const {displayTags = false, tagsComponent} = this.props
    return displayTags ? tagsComponent : null
  }

  getHeight = (filteredItems: any[]) => {
    const {autoSize = false, headerSize, cellSize, orientation} = this.props
    if (!autoSize) {
      return orientation === 'vertical'
        ? filteredItems.length * headerSize + headerSize + cellSize
        : filteredItems.length * cellSize + cellSize + headerSize
    }
    return 0
  }

  render() {
    const {
      aggregationScheme = false,
      aggregationSize,
      autoSize = false,
      borderColor,
      cellSize,
      cellStyle = {},
      defaultSort = null,
      defaultSortDir = 'asc',
      fixedColumnCount = 1,
      fixedRowCount = 0,
      headerSize,
      height = 0,
      highlight = false,
      highlightedRow = null,
      items,
      loading = false,
      onCellClickAvailable = false,
      orientation = 'vertical',
      overscanColumnCount = 0,
      overscanRowCount = 0,
      placehold = false,
      scheme,
      selectedItemKey,
      selectedItems,
      toolbar = null,
      updateSelectedItems,
      widgetError = null,
      width = 0,
      displaySearch = true,
      extraItemsTopBar = null,
      extraSettings = null,
      displayTopBar = true,
      maxHeightWrapper = '100vh',
      withoutBackground = false,
      displaySchemesToggles = true,
    } = this.props
    const {nameSearch} = this.state

    const filteredItems = displaySearch
      ? items.filter(item => searchInTable(item, this.props.grouping, nameSearch))
      : items

    const filteredSchemes = scheme.filter(elem => elem.status !== 'hidden')

    const topBar = toolbar ?? (
      <React.Fragment>
        <Row alignItems="center" justifyContent="space-between" height={38}>
          {extraItemsTopBar}
          <Row flex={1} alignItems="center" justifyContent="flex-end">
            {this.renderSearch()}
            {extraSettings}
            {displaySchemesToggles ? (
              <SchemesToggles schemeList={scheme} updateScheme={this.props.updateScheme} />
            ) : null}
            {this.renderTags()}
          </Row>
        </Row>
        <Spacer size="12px" />
      </React.Fragment>
    )
    return (
      <StyledCol borderColor={borderColor} withoutBackground={withoutBackground}>
        {displayTopBar ? topBar : null}
        <Box
          display="flex"
          flex="1 0 auto"
          justifyContent="center"
          alignItems="center"
          maxHeight={autoSize ? 'unset' : maxHeightWrapper}
          height={this.getHeight(filteredItems)}
        >
          {widgetError ? (
            <p style={{textAlign: 'center', whiteSpace: 'pre-line', color: colors.lightDark.rgba}}>{widgetError}</p>
          ) : (
            <LoaderWrapper requesting={loading}>
              {!filteredItems.length ? (
                i18n.t('placeholders.empty.data')
              ) : (
                <ScrollableGrid
                  aggregationScheme={aggregationScheme}
                  aggregationSize={aggregationSize}
                  autoSize={autoSize}
                  cellSize={cellSize}
                  cellStyle={cellStyle}
                  defaultSort={defaultSort}
                  defaultSortDir={defaultSortDir}
                  fixedColumnCount={fixedColumnCount}
                  fixedRowCount={fixedRowCount}
                  headerSize={headerSize}
                  height={height}
                  highlight={highlight}
                  highlightedRow={highlightedRow}
                  items={filteredItems}
                  onCellClick={
                    onCellClickAvailable
                      ? ({event, index, cellData}) => this.itemSelected(event, index, cellData)
                      : undefined
                  }
                  orientation={orientation}
                  overscanColumnCount={overscanColumnCount}
                  overscanRowCount={overscanRowCount}
                  placehold={placehold}
                  scheme={filteredSchemes}
                  width={width}
                  selectedItems={selectedItems}
                  selectedItemKey={selectedItemKey}
                  updateSelectedItems={updateSelectedItems}
                />
              )}
            </LoaderWrapper>
          )}
        </Box>
        {this.renderDeepDive()}
      </StyledCol>
    )
  }
}

export default WrappedGrid
