import {startTracking} from './modules/metrics'
import i18n from './modules/utils/i18n'
// import config from './config'

// const initThirdPartyLibraries = () => {
//   global.mixpanel.init(config.mixpanel.key)
// }

export default function setup(store) {
  // initThirdPartyLibraries()

  i18n.store = store // add store to i18n so it's aware of settings.preferences.language
  require('./modules/utils/easter-eggs').load(store) // eslint-disable-line global-require
  startTracking()
}
