export default {
  actions: {
    goToAmazon: 'Go To Amazon',
  },
  addKeywords: {
    actionButtonText: 'Add keywords',
    currentKeywords: 'Current keywords',
    enterKeywords: 'Enter your keywords manually',
    fromSuggestions: 'From suggestions',
    keywordsEntered: {
      one: 'One keyword entered',
      other: '{{count}} keywords entered',
      zero: 'No keyword entered',
    },
    keywordsToAdd: 'Keywords to add',
    selectNewKeywords: 'Select new keywords',
    textAreaPlaceholder: 'your meaningful keyword',
  },
  campaignBuilder: {
    header: 'Download the template then import the file to generate your keywords',
    importName: 'Import name',
    keywordLimit: 'Keyword Limit',
    sd: 'Sponsored Display',
    sp: 'Sponsored Product',
    spKeyword: 'SP Keyword',
    spProductPage: 'SP Product Page',
    sbKeyword: ' Sponsored Brand (Keyword)',
  },
  competition: {
    ads: '# Ads',
    and: ' and an ',
    averageRank: 'Average Rank',
    averageRankNb: 'Average Rank {{nb}}',
    avgRank: 'Avg. Rank',
    brandSelect: 'Select brand',
    browseNodes: '{{nb}} Browse Nodes',
    categories: 'Categories',
    category: 'Category',
    clickToAnalyse: 'Click to Analyse ➜',
    comp: 'Composition',
    competitor: 'Brand',
    core: 'Core',
    countrySelector: 'Select your country',
    days: '{{nb}} days',
    decreased: 'decreased score',
    delete: 'Delete selected keywords',
    edit: 'Edit Mode',
    export: 'Export Data',
    exportAsinLevel: {
      asin: 'ASIN',
      brand: 'Brand',
      is_competitor: 'Competitor',
      global_avg_position: 'Global Average Position',
      global_share_of_voice: 'Global SoV',
      organic_avg_position: 'Organic Average Position',
      organic_share_of_voice: 'Organic SoV',
      sb_avg_position: 'SB Average Position',
      sb_share_of_voice: 'SB SoV',
      sp_avg_position: 'SP Average Position',
      sp_share_of_voice: 'SP SoV',
    },
    increased: 'increased score',
    keyword: 'Keyword',
    keywords: 'keywords',
    lastPeriod: 'Last period',
    ofAds: '# of Ads',
    ofProducts: '# of Products',
    orgComp: 'Org. Comp.',
    orgVis: 'Org. Vis.',
    orgVisSpread: 'Org. Vis. Spread',
    organic: 'Organic Visibility',
    organicVisibility: 'Organic Visibility (%)',
    ov: '∆ OV',
    paid: 'Paid Visibility',
    paidComp: 'Paid Comp.',
    paidVis: 'Paid Vis.',
    paidVisSpread: 'Paid Vis. Spread',
    paidVisibility: 'Paid Visibility (%)',
    paidVsOrganic: 'Paid {{nb1}} vs. Organic {{nb2}}',
    prods: '# Prods',
    product: 'Product',
    products: '# Products',
    productsVsCompetition: 'Our Products vs. Competition',
    pv: '∆ PV',
    sponAds: '# Spon. Ads',
    spread: 'Spread',
    tooltipsOrgSpread: ' with an organic visibilty of ',
    tooltipsPaidSpread: ' with an paid visibilty of ',
    validate: 'Validate selected keywords',
    sovCompetitor: 'Competitor',
    sovTimelineTitle: 'Timeline (Share of Voice %)',
    sovCompetitorsTitle: 'Competitors (Share of Voice %)',
    sovProducts: 'Share of Voice by Products',
  },
  index: {
    exportKeywords: 'Export Keywords',
    exportKeywordsRankingFile: 'Keywords-Ranking',
    exportSOVDataFile: 'share_of_voice_export',
    exportKeywordsTags: 'Export Keywords Tags',
    graphTooltip: {
      one: 'One result on page {{x}}',
      other: '{{count}} results on page {{x}}',
    },
    keywordRanking: 'Keyword Ranking',
    keywordsTags: 'Keywords Tags',
    recommendedKeywords: 'Increase your visibility by using the keyword recommendations',
    recommendedKeywordsStep1: 'Select the appropriate level of analysis : ASIN level OR Marketplace level',
    recommendedKeywordsStep2: 'Set the number of keywords needed',
    recommendedKeywordsStep3: 'Confirm to receive by email your keyword suggestions with bidding recommendations',
    recommendedKeywordsChoices: 'Select your keyword recommendations scope :',
    recommendedKeywordsConfirmationContent:
      'You will get an email from studio@seelk.co, it contains an Excel with your recommended keywords with bidding recommendations.',
    recommendedKeywordsConfirmationTitle:
      'Your keywords are being processed ! You will receive an email within 24 hours.',
    recommendedKeywordsContent: 'Click below to confirm and receive a spreadsheet containing your recommended keywords',
    recommendedKeywordsTitle: 'You have requested recommended keywords for ',
    recommendedKeywordsLimit: 'Set the keyword limit for the scope selected above ',
    searchTooltip: 'Text written here will be matched against keywords',
    splitPerMarketplace:
      'Marketplace level : A list of suggested keywords will be generated for each marketplace selected.',
    splitPerOffer: 'ASIN level : A list of suggested keywords will be generated for each ASIN selected.',
    template: 'add_keywords_template',
    warningOffer: 'Note that split per offer can take up to several minutes',
  },
  keywordsNumber: {
    one: 'One Keyword',
    other: '{{count}} Keywords',
    zero: 'No Keyword',
  },
  keywordsRankings: {
    acpcTooltip: 'Avg. Daily ACpC',
    addKeywordsDescription: 'Use this form to add keywords to your offers',
    addUpdateKeywords: 'Add/Update keywords',
    clicksTooltip: 'Avg. Daily Clicks',
    impressionsTooltip: 'Avg. Daily Impressions',
    salesTooltip: 'Avg. Daily Sales',
  },
  management: {
    noKeywords: 'You currently have no keywords',
    noKeywordsInstruction: 'Enter the keywords you want to watch on Amazon.',
    noMatchingKeywords: 'No keywords match your filters',
  },
  metrics: {
    adsPage1: 'Ads. Page 1',
    global: 'Global',
    hsa: 'Sponsored Brands',
    natural: 'Organic',
    nbOfProducts: '# of products',
    numberOfOrganicOffers: '# Organic offers',
    numberOfPaidOffers: '# Paid offers',
    organic: 'Organic',
    organicVisibility: 'Organic visibility',
    page1Products: 'Pg.1 Products',
    pageRankings: 'Organic Page Ranking',
    paidVisibility: 'Paid visibility',
    prodsPage1: 'PRODs Page 1',
    sb: 'Sponsored Brands',
    sov: 'SOV (%)',
    sp: 'Sponsored Products',
    title: 'Share of Voice (%)',
    visibility: 'Visibility',
  },
  offerKeywords: {
    keywordKeyMetrics: {
      numberOfKeywords: '# Keywords',
      pageResults: 'Page Results',
      ranksGained: 'Ranks Gained',
      visibility: 'Visibility %',
    },
    keywordRankingsTable: {
      graphTitle: 'Ranking history',
    },
    productDetails: {
      asin: 'ASIN',
      lastUpdate: 'Last Update',
    },
    productHighlight: {
      clientsComments: {
        one: '1 customer review',
        other: '{{count}} customer reviews',
        zero: 'No customer review',
      },
      keywordPreview: {
        highlights: 'Product highlight',
        hoverKeywordToHighlight: 'Hover on a keyword on the left to highlight its position on the product page',
        preview: 'Preview',
      },
      manageKeywords: {
        appliedKeywords: 'Applied keywords',
      },
      newArticles: {
        one: '1 new from {{price}}',
        other: '{{count}} new from {{price}}',
        zero: 'No article',
      },
    },
  },
  rankings: {
    allKeywords: '# All Keywords',
    averagePosition: 'Average Position',
    averagePositionShort: 'AVGP',
    brand: 'Brand',
    keywords: '# Keywords',
    productSection: 'Product Section',
    showMarketingMetrics: 'Show Marketing Metrics',
    sov: 'Share of Voice',
    sovType: 'Share of Voice Type',
  },
  searchTermsAudit: {
    header: 'Choose a marketplace and import your search terms audit',
    reportDate: 'Report Date',
  },
  visibility: {
    organic: 'Organic visibility',
    paid: 'Paid visibility',
  },
}
