import config from './config.json'

config.shenronDomain = process.env.REACT_APP_SHENRON_DOMAIN
config.booDomain = process.env.REACT_APP_BOO_DOMAIN
config.kaioDomain = process.env.REACT_APP_KAIO_DOMAIN
config.mrsatanDomain = process.env.REACT_APP_MRSATAN_DOMAIN
config.senzuDomain = process.env.REACT_APP_SENZU_DOMAIN
config.trunksDomain = process.env.REACT_APP_TRUNKS_DOMAIN
config.zunoDomain = process.env.REACT_APP_ZUNO_DOMAIN
config.bardockDomain = process.env.REACT_APP_BARDOCK_DOMAIN
config.bulmaDomain = process.env.REACT_APP_BULMA_DOMAIN
config.environment = process.env.REACT_APP_ENVIRONMENT || 'dev'

// config.mixpanel = {
//   key: config.environment === 'production' ? 'f547e8ff725cb49ff90d94ab92be6a1e' : '9975efd04592ca71c31e27d9631b6594',
// }

export default config
