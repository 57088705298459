export default {
  errors: {
    credentials_not_provided: 'User / password missing from payload',
    email: 'Email field may not be blank.',
    invalid_credentials: 'We were not able to authenticate using provided credentials.',
    password: 'Password field may not be blank.',
    user_account_disabled: 'User account is disabled',
    user_not_found: 'User doesn’t exist.',
    viewer: 'You are not authorized to view this page !',
    wantToKnowMore: 'If you want to know more about Seelk Studio',
  },
  logIn: 'LOG IN',
  forgotPassword: 'Forgot Password ?',
  signUp: 'SIGN UP',
}
