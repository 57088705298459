import {Col} from 'jsxstyle'
import styled from 'styled-components'

const SeelkElevatedPanel = styled(Col)`
  background-color: ${props => props.theme.elevatedPanel.backgroundColor};
  border: ${props => props.theme.elevatedPanel.border};
  border-color: ${props => props.theme.elevatedPanel.borderColor};
  border-radius: ${props => props.theme.elevatedPanel.borderRadius};
  box-shadow: ${props => props.theme.elevatedPanel.boxShadow};
  overflow-y: ${props => props.theme.elevatedPanel.overflowY};
  :hover {
    border-color: ${props => props.theme.elevatedPanel.hoverBorderColor};
  }
`

export default SeelkElevatedPanel
