import React from 'react'

import {Box} from 'jsxstyle'

import i18n from '../../../../../i18n'
import {FilterButton, VirtualFilterSearch} from '../../../..'

interface KeywordsCheckboxesProps {
  choices: string[]
  data: string[]
  disabled?: boolean
  icon?: string | null
  onChange: (choices: string[]) => void
  onApply: () => void
  onRemove: () => void
  title: string
}

class KeywordsCheckboxes extends React.Component<KeywordsCheckboxesProps> {
  static count = (data: string[]) => data.length

  render() {
    const {data, onRemove, onApply, title, choices, icon = null, disabled = false, ...barItemProps} = this.props
    const keywordsCount = data.length
    const trigger = (
      <FilterButton
        title={data.length ? title : i18n.t('filters.keywordsTitle')}
        icon={icon}
        disabled={disabled}
        onRemove={onRemove}
        onApply={onApply}
        dropdown={undefined}
      >
        <Box width={data.length ? 100 : 50} marginLeft="8px">
          {keywordsCount ? `(${keywordsCount})` : null}
        </Box>
      </FilterButton>
    )

    return (
      <VirtualFilterSearch
        data={data}
        onRemove={onRemove}
        onApply={onApply}
        title={title}
        choices={choices}
        disabled={disabled}
        placeholder={i18n.t('actions.search.keywords')}
        trigger={trigger}
        {...barItemProps}
      />
    )
  }
}

export default KeywordsCheckboxes
