import React from 'react'

import _ from 'lodash'

import {AVAILABLE_CURRENCIES} from '../../constants'
import DropdownSelector from './DropdownSelector'

interface CurrencySelectorProps {
  currency?: string
  availableMarketplaces?: string[]
  disabled?: boolean
  updateCurrency: (value: string) => void
}

const CurrencySelector = ({
  currency = AVAILABLE_CURRENCIES[0].value,
  availableMarketplaces,
  disabled = false,
  updateCurrency,
}: CurrencySelectorProps) => {
  const getAvailableCurrencies = () => {
    return AVAILABLE_CURRENCIES.filter(({marketplaces, value}) => {
      if (value === 'EUR' || value === 'USD') return true
      else if (availableMarketplaces && availableMarketplaces.length > 0)
        return _.intersection(availableMarketplaces, marketplaces).length > 0
      else return true
    }).map(currency => ({
      key: currency.key,
      text: currency.text,
      value: currency.value,
      // disabled: currency.disabled,
    }))
  }

  return (
    <DropdownSelector
      options={getAvailableCurrencies()}
      value={currency}
      onChange={(c: string) => updateCurrency(c)}
      title=""
      pointing="top left"
      whiteTheme
      icon="globe"
      disabled={disabled}
      loading={undefined}
      onlyIcon={undefined}
      placeholder={undefined}
    />
  )
}

export default CurrencySelector
