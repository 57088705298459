import React from 'react'

import PropTypes from 'prop-types'
import {Popup} from 'semantic-ui-react'

interface PoppersPopupProps {
  [key: string]: any
}

class PoppersPopup extends React.Component<PoppersPopupProps> {
  static propTypes = {
    popperModifiers: PropTypes.arrayOf(PropTypes.object),
  }

  static defaultProps = {
    popperModifiers: [],
  }

  render() {
    const {popperModifiers = [], ...additionalProps} = this.props

    return (
      <Popup
        popperModifiers={[
          {
            name: 'zIndex',
            enabled: true,
            phase: 'write',
            fn: ({state}: any) => {
              state.styles.popper.zIndex = 4200 // eslint-disable-line no-param-reassign
            },
          },
          ...popperModifiers,
        ]}
        {...additionalProps}
      />
    )
  }
}

export default PoppersPopup
