import React, {useState} from 'react'

import {GridColumn, Icon, Label} from 'semantic-ui-react'
import styled from 'styled-components'

import colors from '../../colors'
import {PANEL_COLORS} from '../../constants'
import SeelkDropdown from './SeelkUIKit/SeelkDropdown'

const ColorCircle = styled(Label)`
  &&& {
    font-size: 12px;
  }
  i {
    color: white;
  }
`

const ColorIcon = styled(Icon)`
  &&&&& {
    margin: auto;
    padding: 0;
    fontsize: 12;
  }
`

const StyledGridColumn = styled(GridColumn)`
  &&&&& {
    justify-content: flex-end;
    align-items: center;
    padding: 6px;
    border: 1px solid lightgray;
    border-left: ${props => (props.light ? 'none' : '1px solid lightgray')};
    border-right: ${props => (props.light ? 'none' : '1px solid lightgray')};
    border-radius: ${props => (props.light ? '0' : '5px')};
    display: flex;
    max-width: 105px;
    ${props => (props.light ? 'width: 105px;' : '')}
  }
`

const UILabelText = styled('span')`
  margin-right: 10px;
`

const ColorNone = styled(Icon)`
  &&& {
    display: flex;
    align-items: center;
    color: ${colors.light.rgba};
    font-size: 24px;
  }
`

interface ColorSelectionProps {
  colorOptions?: string[]
  handleColorSelect: (color: string) => void
  label?: string | null
  lightContent?: boolean
  selectedColor?: string | null
}

function ColorSelection({
  selectedColor = null,
  colorOptions,
  handleColorSelect,
  label = null,
  lightContent = false,
}: ColorSelectionProps) {
  const [triggered, setTriggered] = useState(false)

  const handleColorClick = (value: string) => {
    handleColorSelect(value)
    setTriggered(false)
  }

  return (
    <SeelkDropdown
      panelProps={{
        metricColor: selectedColor,
        options: colorOptions || [null, ...PANEL_COLORS],
        onChange: (value: string) => handleColorClick(value),
      }}
      panelType="color"
      marginBottom={0}
      alignItems="center"
      display="flex"
      outsideWrapper={{
        display: 'flex',
        flexDirection: 'row',
      }}
      triggered={triggered}
      trigger={
        <StyledGridColumn light={lightContent ? 1 : 0} onClick={() => setTriggered(!triggered)}>
          {label && <UILabelText>{label}</UILabelText>}
          {selectedColor ? (
            <ColorCircle circular style={{backgroundColor: selectedColor}}>
              {lightContent ? null : <ColorIcon name="checkmark" />}
            </ColorCircle>
          ) : (
            <ColorNone name="ban" />
          )}
          <Icon name="triangle down" />
        </StyledGridColumn>
      }
    />
  )
}

export default ColorSelection
