import Color from './Color'

const colors = {
  hex: Color.hex,
  named: name => name,
  rgba: Color.rgba,
  fromRGBA: Color.fromRGBA,

  periodA: Color.hex('#446CB3').alpha(0.4),
  periodAAccent: Color.hex('#446CB3'),
  periodABackground: Color.hex('#446CB3').alpha(0.2),

  periodB: Color.hex('#23385C').alpha(0.4),
  periodBAccent: Color.hex('#23385C'),
  periodBBackground: Color.hex('#23385C').alpha(0.2),

  primary: Color.hex('#446cb3'),
  primaryHover: Color.hex('#335288'),
  primaryLight: Color.hex('#ebf3fa'),
  primaryLightAlpha: Color.hex('#F5F9FC'),
  primaryDark: Color.hex('#314059'),
  primaryAccent: Color.hex('#19b5fe'),

  secondary: Color.hex('#6bb9f0'),
  secondaryLight: Color.hex('#b4dbf7'),
  secondaryDark: Color.hex('#2c82c9'),
  secondaryAccent: Color.hex('#89c4f4'),

  accent: Color.hex('#E87E04'),
  accentLight: Color.hex('#F3BD80'),
  accentDark: Color.hex('#D35400'),
  accentAccent: Color.hex('#F9690E'),

  amberAlpha: Color.hex('#FDF2E5'),

  dark: Color.hex('#333333'),
  darkLight: Color.hex('#818181'),
  darkAccent: Color.hex('#b7b7b7'),

  greyBackground: Color.hex('#EAEAEA'),
  greyLines: Color.hex('#E6E6E6'),

  light: Color.hex('#e6e7e8'),
  lightDark: Color.hex('#cbd2d6'),
  lightAccent: Color.hex('#fafafa'),

  lightBackground: Color.hex('#ebf3fa'),
  lighterBackground: Color.hex('#e8f6f7'),

  prospect: Color.hex('#9AEDAD'),
  competitor: Color.hex('#FFE17D'),

  sellIn: Color.hex('#a5aad9'),
  sellOut: Color.hex('#8108c8'),

  analysisX: Color.hex('#446cb3'),
  analysisY: Color.hex('#314059'),
  analysisZ: Color.hex('#0aa20a'),
  analysisXVS: Color.hex('#b3c3e0'),
  analysisYVS: Color.hex('#acb2bc'),
  analysisZVS: Color.hex('#94ed94'),

  shadow: Color.hex('#000000').alpha(0.3),
  lightShadow: Color.hex('#000000').alpha(0.1),
  border: Color.hex('#d3d3d3'),
  success: Color.hex('#e5f7f0'),
  positive: Color.hex('#00b16a'),
  positiveAlpha: Color.hex('#00b16a').alpha(0.2),
  positiveDark: Color.hex('#008e55'),
  negative: Color.hex('#d91e18'),
  negativeAlpha: Color.hex('#d91e18').alpha(0.2),
  info: Color.hex('#0e566c'),
  warning: Color.hex('#f7ca18'),
  white: Color.hex('#ffffff'),
  black: Color.hex('#000000'),
  manny: Color.hex('#cd5c5c'),

  daily: Color.hex('#F1C40F').alpha(0.25),
  weekly: Color.hex('#CF6A87').alpha(0.25),
  monthly: Color.hex('#33D9B2').alpha(0.25),
  sum: Color.hex('#574B90').alpha(0.25),
  avg: Color.hex('#3498DB').alpha(0.25),

  bic: Color.hex('#f09220'),

  backgroundTab: Color.hex('#ebf3fa'),

  dataVizColors: [
    '#C0392B',
    '#778BEB',
    '#27AE60',
    '#F1C40F',
    '#D35400',
    '#3498DB',
    '#95A5A6',
    '#CF6A87',
    '#5F27CD',
    '#8E44AD',
    '#2ECC71',
    '#FFB142',
    '#B33939',
    '#84817A',
    '#574B90',
    '#2980B9',
    '#33D9B2',
    '#CC8E35',
    // there there
    '#87CEFA',
    '#F08080',
    '#F4A460',
    '#4169E1',
    '#48D1CC',
    '#CD5C5C',
    '#DDA0DD',
    '#008080',
    '#BA55D3',
    '#D2691E',
    '#C71585',
    '#FFA500',
    '#8B4513',
    '#20B2AA',
    '#4682B4',
    '#FFD700',
    '#6495ED',
    '#6A5ACD',
    '#FF8C00',
    '#B0C4DE',
    '#483D8B',
    '#00BFFF',
    '#EE82EE',
    '#9370DB',
    '#1E90FF',
    '#800080',
    '#4B0082',
    '#FF69B4',
    '#8B0000',
    '#FFB6C1',
    '#7B68EE',
    '#BC8F8F',
    '#FF6347',
    '#000000',
  ].map(c => Color.hex(c)),

  variousColors: [
    '#87CEFA',
    '#F08080',
    '#F4A460',
    '#4169E1',
    '#48D1CC',
    '#CD5C5C',
    '#DDA0DD',
    '#008080',
    '#BA55D3',
    '#D2691E',
    '#C71585',
    '#FFA500',
    '#8B4513',
    '#20B2AA',
    '#4682B4',
    '#FFD700',
    '#6495ED',
    '#6A5ACD',
    '#FF8C00',
    '#B0C4DE',
    '#483D8B',
    '#00BFFF',
    '#EE82EE',
    '#9370DB',
    '#1E90FF',
    '#800080',
    '#4B0082',
    '#FF69B4',
    '#8B0000',
    '#FFB6C1',
    '#7B68EE',
    '#BC8F8F',
    '#FF6347',
  ].map(c => Color.hex(c)),

  editor: ['#000000', '#d91e18', '#00b16a', '#446cb3', '#FFD700', '#FF8C00', '#800080', '#8B4513']
    .map(c => {
      const variants = []
      const alpha = [1, 0.8, 0.6, 0.4]
      for (let i = 0; i < 4; i += 1) variants.push(Color.hex(c).alpha(alpha[i]))
      return variants
    })
    .reduce((acc, c) => [...acc, ...c], []),
}

colors.primaryTranslucent = colors.primary.alpha(0.4)
colors.primaryLightTranslucent = colors.primaryLight.alpha(0.4)
colors.primaryDarkTranslucent = colors.primaryDark.alpha(0.4)
colors.primaryAccentTranslucent = colors.primaryAccent.alpha(0.4)
colors.primaryShadow = colors.primary.alpha(0.5)

colors.white.css.color = 'black'
colors.black.css.color = 'white'

export default colors
